import React from 'react';
import Modal from 'react-modal';
import '../../styles/css/disclaimers.css';
import '../../styles/css/emailModal.css';


const modalStyles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.75)',
  },

  content: {
    padding: '0px',
    borderColor: '#eceded',
    position: 'absolute',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    background: '#eceded',
    transform: 'translate(-50%, -50%)',

    // Allow scrolling when window is too small
    maxWidth: '100vw',
    maxHeight: '100vh',
    overflowY: 'auto'
  },
};

export function EmailModalTemplate(component) {
  return (
        <div>
            <Modal
                isOpen={component.state.modalIsOpen}
                style={modalStyles}
                className="emailModal"
                contentLabel="Email Modal">
                <div id="statusMessageBox">
                    <div className="messageText">Sending email...</div>
                </div>
                <div id="successMessageBox">
                    <div className="messageText">Email was successfully sent!</div>
                </div>
                <div id="failureMessageBox">
                    <div className="messageText">Error - email was not able to send.</div>
                </div>
                <div className="headingText">
                    <h3>Share with a Customer</h3>
                </div>
                <div className="emailInput">
                    <label>To: (separate multiple email addresses with commas)</label>
                    <div className="errorMessage" id="emailError">Please add one or more valid email addresses</div>
                    <input type="text" id="emailField" value={component.state.emailField} onChange={event => component.updateEmailField(event.target.value)} onBlur={component.validateEmail} />
                    <label>Reply To Email:</label>
                    {!component.state.replyToEmailValid
                        && <div className="replyToMessage" id="replyToError">Please add a valid email address</div>
                    }
                    <input type="text" id="replyToField" value={component.state.replyToField} onChange={event => component.updateReplyToField(event.target.value)} onBlur={component.validateReplyTo} />
                    {component.state.replyToField && component.state.replyToEmailChanged && component.state.replyToEmailValid
                        && <div className="dealerEmailCheckboxDiv">
                            <input type="checkbox" className="dealerEmail" ref="checkboxField" name="dealerEmail" onClick={event => component.updateDealerEmail(event.target.checked)} />
                            <div className="termsText">
                                <label htmlFor="termsBox">Update the email on my Engage profile to {component.state.replyToField}</label>
                            </div>
                        </div>
                    }
                    <label>Subject (optional):</label>
                    <input type="text" id="subjectField" value={component.state.subjectField} onChange={event => component.updateSubjectField(event.target.value)} />
                    <label>Message: </label>
                    <div className="errorMessage" id="messageError">Please add a message</div>
                    <textarea id="messageBox" value={component.state.messageField} onChange={event => component.updateMessageField(event.target.value)} onBlur={component.validateMessage} />
                    <div>
                        <div className="errorMessage" id="checkboxError">Please agree to the Terms of Use</div>
                        <input type="checkbox" className="terms" ref="checkboxField" name="terms" onClick={event => component.updateCheckboxField(event.target.checked)} />
                        <div className="termsText">
                            <label htmlFor="termsBox">This email was requested by a customer and conforms to Toyota Product & Sales Engagement's <a href="/termsconditions">Terms of Use.</a></label>
                        </div>
                    </div>
                    <div>

                        <button className="button" id="shareButton" onClick={component.sendEmailClick}> Share</button>
                        <button className="button" id="cancelButton" onClick={() => component.closeModal(0)}>Cancel</button>
                    </div>
                </div>
            </Modal>
        </div>
  );
}
