import React from "react";
import AppSettings from "../../../appsettings";
import RoundedButton from "../../../components/molecules/roundedButton";
import getTarget from "../../utils/getTarget";
import { dashboardImageLinkClick } from "../engageXPMissions.analytics";
import { capitalizeTitle } from "../../utils/capitalize";

const DashboardImageCard = (props) => {
    const tabletBreakpoint = 1595;
    const mobileBreakpoint = props.page === 'stats' ? 950 : 905;

    const handleLinkClick = () => {
        window.open(props?.imageLink, getTarget(props?.imageLink));
        const clickAnalytics = props?.analyticsData?.clickAnalytics;
        const dashboardLabel = props?.imageLinkText;        

        if (clickAnalytics){
            if (props.page === "stats") {
                const analyticsCategory = "My Stats";
                dashboardImageLinkClick(clickAnalytics, analyticsCategory, dashboardLabel);
            } else {
                const analyticsCategory = "My Dashboard";
                dashboardImageLinkClick(clickAnalytics, analyticsCategory, dashboardLabel);
            }
        }
    };

    const getBackgroundImage = () => {
        const screenWidth = window.innerWidth;
        let imageName;
        if (screenWidth > tabletBreakpoint) {
            imageName = props?.desktopImageName;
        } else if (screenWidth > mobileBreakpoint && screenWidth <= tabletBreakpoint) {
            imageName = props?.tabletImageName;
        } else if (screenWidth <= mobileBreakpoint) {
            imageName = props?.mobileImageName;
        }
        const imageUrl = `${AppSettings.AWSImgRepo.engageXP}${imageName}`;
        return `url(${encodeURI(imageUrl)})`;
      };


    return (
        <div className="dashboard-image-card" style={{backgroundImage: getBackgroundImage()}}>
            <RoundedButton 
                className="image-link-text"
                onClick={() => handleLinkClick()}
                title={props?.imageLinkText}
                color='transparent'
                activeColor='transparent'
                borderColor='white'
                textColor='white'
                height={45}
            />
        </div>
    );
};

export default DashboardImageCard;
