import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();

// Video Analytics
export function sendVideoEventAnalytics(item) {
  analytics.fireOnClick({
    tag: '40.5',
    section: 'Articles',
    subsection: 'Video Article',
    page: this.state.title,
    category: item.category,
    contentTitle: this.state.title,
    mediaTitle: this.state.title,
    modelName: ' ',
    module: 'video player',
    label: this.state.title,
    events: item.event,
  });
}

export function sendActionAnalytics(mod, action) {
  analytics.fireOnClick({
    tag: '36.3',
    modelName: ' ',
    section: 'Articles',
    category: this.state.article.category,
    contentTitle: this.state.title,
    mediaTitle: this.state.title,
    module: mod,
    label: action,
  });
}

export function sendClickAnalytics(mod, title) {
  analytics.fireOnClick({
    tag: '36.2',
    section: 'Articles',
    modelName: ' ',
    page: this.state.title,
    category: this.state.article.category,
    contentTitle: this.state.title,
    mediaTitle: this.state.title,
    module: mod,
    label: title,
  });
}

export function sendArticleContentLinkClickAnalytics(label) {
  analytics.fireOnClick({
    tag: '28.3',
    section: 'Articles',
    modelName: ' ',
    container: ' ',
    page: this.state.title,
    category: 'Article Link',
    module: 'Articles',
    contentTitle: this.state.title,
    mediaTitle: this.state.title,
    label,
  });
}

export function sendRelatedClickAnalytics(tag, mod, title, category) {
  analytics.fireOnClick({
    tag,
    section: 'Articles',
    modelName: ' ',
    page: this.state.title,
    category,
    contentTitle: this.state.title,
    mediaTitle: this.state.title,
    module: mod,
    container: ' ',
    label: title,
    tilePosition: ' ',
  });
}

export function sendLoadAnayltics() {
  analytics.firePageLoad({
    tag: '36.1',
    modelName: ' ',
    section: 'Articles',
    page: this.state.title,
    contentTitle: this.state.title,
    mediaTitle: this.state.title,
    articleType: this.state.article.category,
  });
}
