import React from "react";
import PropTypes from "prop-types";
import { SubheaderTemplate } from "./subheader.tpl";
import { GlobalAnalytics } from "../../analytics";
import { getLocale } from "../../services/languageTranslationService.js";
import { getVehicleYears } from "../../services/vehicleService";
import { translateText } from "../utils/translateText";
import { getUrlModelName } from "../utils/vehicleName";
import AppSettings from "../../appsettings";

const globalAnalytics = new GlobalAnalytics();

// The following block of consts were made due to Sonarqube quiality gate issues.
// I advise not deleting them unless Sonarqube policies are changed or else
// it could lead to build failures.
const subheaderResources = "subheader.resources";
const subheaderFeaturesSpecs = "subheader.featuresAndSpecs";
const featuresAndSpecs = "Features & Specs";
const subheaderTCOP = "subheader.tcop";
const subheaderAccessories = "subheader.accessories";
const subheaderCompetitiveAdvantages = "subheader.competitiveAdvantages";
const featuredTechnology = "Featured Technology";
const productNav = "Product Nav";
const modelYearDropdown = "Model Year Dropdown";

export default class SubheaderComponent extends React.Component {
  constructor(props) {
    super(props);
    const activeYear = this.props.params.year
      ? this.props.params.year
      : this.props.route.year;
    this.state = {
      subLinkExpand: true,
      yearLinkExpand: true,
      vehicleModel: this.props.route.model,
      activeYear,
      yearSubheaderItems: [],
      shouldDisplayPrintButton: false,
      shouldDisplayBookmarkIcon: false,
      bookmarkTitle: "",
      bookmarkUrl: "",
    };
    const { params } = this.props.match;
    const { year } = params;
    const { model } = params;

    const modelYear = `/${getUrlModelName(model)}/${year}`;

    this.setYear = this.setYear.bind(this);
    this.modelYearMap = {
      // includes the years for each model that are not in trims table in the database
      86: [{ year: "2017" }],
      avalon: [
        { year: "2017" },
        { year: "2016" },
        { year: "2015" },
        { year: "2014" },
        { year: "2013" },
      ],
      bz4x: [],
      'gr corolla': [],
      camry: [{ year: '2017' }, { year: '2016' }, { year: '2015' }, { year: '2014.5' }, { year: '2014' }, { year: '2013' }, { year: '2012' }],
      'gr supra': [{ year: '2021' }, { year: '2020' }],
      corolla: [{ year: '2017' }, { year: '2016' }, { year: '2015' }, { year: '2014' }, { year: '2013' }],
      'corolla im': [{ year: '2017' }],
      'corolla cross': [],
      'corolla hatchback': [],
      'toyota crown': [],
      'grand highlander': [],
      mirai: [{ year: '2017' }, { year: '2016' }],
      prius: [{ year: '2017' }, { year: '2016' }, { year: '2015' }, { year: '2014' }, { year: '2013' }, { year: '2012' }],
      'prius c': [{ year: '2017' }, { year: '2016' }, { year: '2015' }, { year: '2014' }, { year: '2013' }, { year: '2012' }],
      'prius prime': [{ year: '2017' }],
      'prius v': [{ year: '2017' }, { year: '2016' }, { year: '2015' }, { year: '2014' }, { year: '2013' }, { year: '2012' }],
      sienna: [{ year: '2017' }, { year: '2016' }, { year: '2015' }, { year: '2014' }, { year: '2013' }],
      yaris: [{ year: '2017' }, { year: '2016' }, { year: '2015' }, { year: '2014' }, { year: '2013' }],
      'yaris ia': [{ year: '2017' }],
      'yaris sedan': [],
      '4runner': [{ year: '2017' }, { year: '2016' }, { year: '2015' }, { year: '2014' }, { year: '2013' }],
      'c-hr': [],
      highlander: [{ year: '2017' }, { year: '2016' }, { year: '2015' }, { year: '2014' }, { year: '2013' }],
      'land cruiser': [{ year: '2017' }, { year: '2016' }, { year: '2015' }, { year: '2014' }, { year: '2013' }],
      rav4: [{ year: '2017' }, { year: '2016' }, { year: '2015' }, { year: '2014' }, { year: '2013' }, { year: '2012' }],
      'rav4 prime': [{ year: '2021' }],
      sequoia: [{ year: '2017' }, { year: '2016' }, { year: '2015' }, { year: '2014' }, { year: '2013' }],
      tacoma: [{ year: '2017' }, { year: '2016' }, { year: '2015' }, { year: '2014' }, { year: '2013' }],
      tundra: [{ year: '2017' }, { year: '2016' }, { year: '2015' }, { year: '2014' }, { year: '2013' }],
      'prius plug-in': [{ year: '2015' }, { year: '2014' }, { year: '2013' }, { year: '2012' }],
      venza: [{ year: '2021' }, { year: '2015' }, { year: '2014' }, { year: '2013' }],
      'fr-s': [{ year: '2016' }, { year: '2015' }, { year: '2014' }],
      ia: [{ year: '2016' }],
      im: [{ year: '2016' }],
      iq: [{ year: '2015' }, { year: '2014' }],
      tc: [{ year: '2016' }, { year: '2015' }, { year: '2014' }],
      xb: [{ year: '2015' }, { year: '2014' }],
      xd: [{ year: '2014' }],
    };

    const resourcesTab = {
      id: subheaderResources,
      linkTo: `${AppSettings.BasePath}/product${modelYear}/resources`,
      subcategory: "Resources",
    };
    const overviewTab =
      parseInt(year, 10) > 2020
        ? {
            id: "subheader.modelOverview",
            linkTo: `${AppSettings.BasePath}/product${modelYear}/modelOverview`,
            subcategory: "Model Overview",
          }
        : {
            id: "subheader.vehicleOverview",
            linkTo: `${AppSettings.BasePath}/product${modelYear}/overview`,
            subcategory: "Overview",
          };

    const baseProductSubheadItems = [
      {
        id: subheaderFeaturesSpecs,
        linkTo: `${AppSettings.BasePath}/product${modelYear}/featuresAndSpecs`,
        subcategory: featuresAndSpecs,
      },
      {
        id: subheaderTCOP,
        linkTo: `${AppSettings.BasePath}/product${modelYear}/tcop`,
        subcategory: "TCOP",
      },
      {
        id: "subheader.trimWalk",
        linkTo: `${AppSettings.BasePath}/product${modelYear}/trimWalk`,
        subcategory: "Trim Walk",
      },
      {
        id: subheaderAccessories,
        linkTo: `${AppSettings.BasePath}/product${modelYear}/accessories`,
        subcategory: "Accessories",
      },
      {
        id: subheaderCompetitiveAdvantages,
        linkTo: `${AppSettings.BasePath}/product${modelYear}/competitiveAdvantages/headToHead`,
        subcategory: "Competitive Advantages - Head-to-Head",
      },
    ];

    // set base Vehicle Tab items based on 2020 model year
    this.productSubheaderItems = baseProductSubheadItems.map((item) => item);

    // add model overview as the first tab for MY21+
    if (parseInt(year, 10) > 2020) {
      this.productSubheaderItems.unshift(overviewTab);
      this.productSubheaderItems.push(resourcesTab);
    } else if (parseInt(year, 10) >= 2019 && parseInt(year, 10) < 2021) {
      this.productSubheaderItems.push(overviewTab);
      this.productSubheaderItems.push(resourcesTab);
    } else if (parseInt(year, 10) === 2018) {
      this.productSubheaderItems = [
        {
          id: subheaderFeaturesSpecs,
          linkTo: `${AppSettings.BasePath}/product${modelYear}/featuresAndSpecs`,
          subcategory: featuresAndSpecs,
        },
        {
          id: subheaderTCOP,
          linkTo: `${AppSettings.BasePath}/product${modelYear}/tcop`,
          subcategory: "TCOP",
        },
        {
          id: subheaderAccessories,
          linkTo: `${AppSettings.BasePath}/product${modelYear}/accessories`,
          subcategory: "Accessories",
        },
        {
          id: subheaderCompetitiveAdvantages,
          linkTo: `${AppSettings.BasePath}/product${modelYear}/competitiveAdvantages/overview`,
          subcategory: "Competitive Advantages",
        },
        {
          id: "subheader.vehicleOverview",
          linkTo: `${AppSettings.BasePath}/product${modelYear}/overview`,
          subcategory: "Overview",
        },
      ];
      this.productSubheaderItems.push(resourcesTab);
    } else if (parseInt(year, 10) === 2017) {
      this.productSubheaderItems = [
        {
          id: subheaderFeaturesSpecs,
          linkTo: `${AppSettings.BasePath}/product${modelYear}/featuresAndSpecs`,
          subcategory: featuresAndSpecs,
        },
        {
          id: subheaderTCOP,
          linkTo: `${AppSettings.BasePath}/product${modelYear}/tcop`,
          subcategory: "TCOP",
        },
        {
          id: subheaderAccessories,
          linkTo: `${AppSettings.BasePath}/product${modelYear}/accessories`,
          subcategory: "Accessories",
        },
        {
          id: subheaderCompetitiveAdvantages,
          linkTo: `${AppSettings.BasePath}/product${modelYear}/competitiveAdvantages/overview`,
          subcategory: "Overview",
        },
      ];
      this.productSubheaderItems.push(resourcesTab);
    } else {
      this.productSubheaderItems = [
        {
          id: subheaderResources,
          linkTo: `${AppSettings.BasePath}/product${modelYear}/resources`,
          subcategory: "Resources",
        },
      ];
    }

    if (AppSettings.FeatureFlags.showAppPage) {
      this.appSubheaderItems = [
        { id: 'appPage.overview', linkTo: `${AppSettings.BasePath}/app/overview`, subcategory: "Overview" },
        { id: 'appPage.getstarted', linkTo: `${AppSettings.BasePath}/app/getstarted`, subcategory: "Get Started" },
        { id: 'appPage.faqs', linkTo: `${AppSettings.BasePath}/app/faqs`, subcategory: "FAQs" },
        { id: 'appPage.howtovideos', linkTo: `${AppSettings.BasePath}/app/videos`, subcategory: "How-To Videos" },
        { id: 'appPage.resources', linkTo: `${AppSettings.BasePath}/app/resources`, subcategory: "Resources" },
      ];
    }

    this.entuneSubheaderItems = [
      { id: 'subheader.overview', linkTo: `${AppSettings.BasePath}/audioMultimedia/overview`, subcategory: "Overview" },
      { id: 'subheader.connectedServicesRegistration', linkTo: `${AppSettings.BasePath}/audioMultimedia/registration`, subcategory: "Registration" },
      { id: 'subheader.audioFaqs', linkTo: `${AppSettings.BasePath}/audioMultimedia/faqs`, subcategory: "FAQs" },
      { id: 'subheader.audioResources', linkTo: `${AppSettings.BasePath}/audioMultimedia/resources`, subcategory: "Resources" },
    ];

    this.serviceConnectSubheaderItems = [
      { id: 'subheader.serviceOverview', linkTo: `${AppSettings.BasePath}/serviceConnect/overview`, subcategory: "Overview" },
      { id: subheaderResources, linkTo: `${AppSettings.BasePath}/serviceConnect/resources`, subcategory: "Resources" },
    ];

    if (AppSettings.FeatureFlags.showRecallsAndSafetyCampaign) {
      this.recallSafetyCampaignSubheaderItems = [
        {
          id: "subheader.recallsOverview",
          linkTo: `${AppSettings.BasePath}/recallsAndSafety/overview`,
          subcategory: "Overview",
        },
        {
          id: "subheader.takata",
          linkTo: `${AppSettings.BasePath}/recallsAndSafety/takata`,
          subcategory: "Takata",
        },
        {
          id: "subheader.otherRecalls",
          linkTo: `${AppSettings.BasePath}/recallsAndSafety/otherRecalls`,
          subcategory: "Other Recalls",
        },
        {
          id: "subheader.quickReferenceGuides",
          linkTo: `${AppSettings.BasePath}/recallsAndSafety/quickReferenceGuides`,
          subcategory: "Quick Reference Guides",
        },
      ];
    }

    this.toyotaSafetySenseSubheaderItems = [
      {
        id: "subheader.safetyOverview",
        linkTo: `${AppSettings.BasePath}/toyotaSafetySense/overview`,
        subcategory: "Overview",
      },
      {
        id: "subheader.tssLineup",
        linkTo: `${AppSettings.BasePath}/toyotaSafetySense/lineup`,
        subcategory: "Lineup",
      },
      {
        id: "subheader.features",
        linkTo: `${AppSettings.BasePath}/toyotaSafetySense/features`,
        subcategory: "Features",
      },
      {
        id: "subheader.videos",
        linkTo: `${AppSettings.BasePath}/toyotaSafetySense/videos`,
        subcategory: "Videos",
      },
      {
        id: subheaderResources,
        linkTo: `${AppSettings.BasePath}/toyotaSafetySense/resources`,
        subcategory: "Resources",
      },
    ];

    this.starSafetySystemSubheaderItems = [
      {
        id: "subheader.safetyOverview",
        linkTo: `${AppSettings.BasePath}/starSafetySystem/overview`,
        subcategory: "Overview",
      },
      {
        id: subheaderResources,
        linkTo: `${AppSettings.BasePath}/starSafetySystem/resources`,
        subcategory: "Resources",
      },
    ];

    this.tcuvSubheaderItems = [
      {
        id: "dropdown.tcuv.certificationProgram",
        linkTo: `${AppSettings.BasePath}/tcuv/certificationProgram`,
        subcategory: "Certification Program",
      },
      {
        id: "dropdown.tcuv.warranty",
        linkTo: `${AppSettings.BasePath}/tcuv/warranty`,
        subcategory: "Warranty",
      },
      {
        id: "dropdown.tcuv.160PointInspection",
        linkTo: `${AppSettings.BasePath}/tcuv/160PointInspection`,
        subcategory: "160-Point Inspection",
      },
      {
        id: "dropdown.tcuv.roadsideAssistance",
        linkTo: `${AppSettings.BasePath}/tcuv/roadsideAssistance`,
        subcategory: "Roadside Assistance",
      },
      {
        id: "dropdown.tcuv.vehicleHistoryReport",
        linkTo: `${AppSettings.BasePath}/tcuv/vehicleHistoryReport`,
        subcategory: "Vehicle History Report",
      },
      {
        id: "dropdown.tcuv.tcuvSalesRecognition",
        linkTo: `${AppSettings.BasePath}/tcuv/salesRecognition`,
        subcategory: "Sales Recognition",
      },
      {
        id: "global.resources",
        linkTo: `${AppSettings.BasePath}/tcuv/resources`,
        subcategory: "Resources",
      },
    ];

    this.smartPathItems = [
      {
        id: "smartPath.overview",
        linkTo: `${AppSettings.BasePath}/smartPath/overview`,
        subcategory: "Overview",
      },
      {
        id: "smartPath.training",
        linkTo: `${AppSettings.BasePath}/smartPath/training`,
        subcategory: "Training",
      },
      {
        id: "smartPath.testimonials",
        linkTo: `${AppSettings.BasePath}/smartPath/testimonials`,
        subcategory: "Testimonials",
      },
      {
        id: "smartPath.faqs",
        linkTo: `${AppSettings.BasePath}/smartPath/faqs`,
        subcategory: "FAQs",
      },
    ];

    this.engageLiveItems = [
      { id: 'engageLive.liveVideo', linkTo: `${AppSettings.BasePath}/engageLive/liveVideo`, subcategory: "Live Video" },
      { id: 'engageLive.archivedVideos', linkTo: `${AppSettings.BasePath}/engageLive/archivedVideos`, subcategory: "Archived Videos" },
    ];

    this.newSalesAssociateSubheaderItems = [
      {
        id: "subheader.overview",
        linkTo: `${AppSettings.BasePath}/newSalesAssociate/overview`,
        subcategory: "Overview",
      },
      {
        id: subheaderResources,
        linkTo: `${AppSettings.BasePath}/newSalesAssociate/resources`,
        subcategory: "Resources",
      },
    ];

    this.certificationSubheaderItems = [
      {
        id: "certification.overview",
        linkTo: `${AppSettings.BasePath}/certification/overview`,
        subcategory: "Overview",
      },
      {
        id: "certification.requirements",
        linkTo: `${AppSettings.BasePath}/certification/requirements`,
        subcategory: "Requirements",
      },
      // TABS ARE COMMENTED OUT DUE TO MISSING CONTENT
      // { id: 'certification.projectBlueprint', linkTo: `${AppSettings.BasePath}/certification/projectBlueprint` },
      {
        id: "certification.resources",
        linkTo: `${AppSettings.BasePath}/certification/resources`,
        subcategory: "Resources",
      },
    ];

    this.resourceOverviewPages = [
      "starSafetySystem",
      "toyotaSafetySense",
      "audioMultimedia",
      "tcuv",
      // 'smartPath/about',
      // 'smartPath/training',
      "smartPath",
      "engageLive",
      "serviceConnect",
      "recallsAndSafety",
      "certification",
      "app",
    ];
    this.getResourceOverviewPageData =
      this.getResourceOverviewPageData.bind(this);
    this.isResourceOverviewPage = this.isResourceOverviewPage.bind(this);
    this.fireResourceOverviewSubHeaderClickEvent =
      this.fireResourceOverviewSubHeaderClickEvent.bind(this);
    this.fireEntuneClickEvent = this.fireEntuneClickEvent.bind(this);
    this.fireServiceConnectClickEvent =
      this.fireServiceConnectClickEvent.bind(this);
    this.fireTSSMenuTabClickEvent = this.fireTSSMenuTabClickEvent.bind(this);
    this.fireSmartpathSubHeaderClickEvent =
      this.fireSmartpathSubHeaderClickEvent.bind(this);
    this.fireEngageLiveSubHeaderClickEvent =
      this.fireEngageLiveSubHeaderClickEvent.bind(this);
    this.printDiv = this.printDiv.bind(this);
    this.toggleSubLinks = this.toggleSubLinks.bind(this);
    this.toggleYearLinks = this.toggleYearLinks.bind(this);
    this.shouldDisplayPrintButton = this.shouldDisplayPrintButton.bind(this);
  }

  componentWillMount() {
    this.setYear(this.modelYearMap);
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    const displayPrintButton = this.shouldDisplayPrintButton();
    if (displayPrintButton !== this.state.shouldDisplayPrintButton) {
      this.setState({
        shouldDisplayPrintButton: displayPrintButton,
      });
    }
  }

  isTruck() {
    const model = this.props.params.model
      ? this.props.params.model.toLowerCase()
      : "";
    return model === "tacoma" || model === "tundra";
  }

  componentWillReceiveProps() {
    this.setYear(this.modelYearMap);
  }

  isResourceOverviewPage() {
    let result = false;
    this.resourceOverviewPages.forEach((item) => {
      if (this.props.match.url.indexOf(item) >= 0) {
        result = true;
      }
    });
    return result;
  }

  shouldDisplayPrintButton() {
    const splitURL = window.location.href.split("/");
    if (splitURL.indexOf("competitiveAdvantages") > -1) {
      return true;
    }
    if (splitURL.indexOf("toyotaSafetySense") > -1) {
      if (splitURL.indexOf("overview") > -1) {
        return true;
      }
      if (splitURL.indexOf("lineup") > -1) {
        return true;
      }
      if (splitURL.indexOf("features") > -1) {
        return true;
      }
    } else if (splitURL.indexOf("starSafetySystem") > -1) {
      if (splitURL.indexOf("overview") > -1) {
        return true;
      }
    } else if (splitURL.indexOf("serviceConnect") > -1) {
      if (splitURL.indexOf("overview") > -1) {
        return true;
      }
    }
    else if (
      splitURL.indexOf("tcuv") > -1 &&
      splitURL.indexOf("resources") === -1
    ) {
      return true;
    } else if (splitURL.indexOf("modelOverview" || "modeloverview") > -1) {
      return true;
    }
    return false;
  }

  getResourceOverviewPageData() {
    let page = "";
    this.resourceOverviewPages.forEach((item) => {
      if (this.props.match.url.indexOf(item) >= 0 && page === "") {
        page = item;
      }
    });

    const pageData = {
      title: "",
      mainComponent: "",
      subheaderItems: [],
      bookmarkUrl: this.getCurrentPath(),
    };

    switch (page) {
      case "starSafetySystem":
        pageData.title = this.getPageTitle(this.starSafetySystemSubheaderItems);
        pageData.mainComponent = translateText(
          "dropdown.safety.starSafetySystem",
          getLocale()
        );
        pageData.subheaderItems = this.starSafetySystemSubheaderItems;
        pageData.onClickFunction = this.fireResourceOverviewSubHeaderClickEvent;
        pageData.bookmarkTitle = translateText(
          "dropdown.safety.starSafetySystem"
        );
        break;
      case "toyotaSafetySense":
        pageData.title = this.getPageTitle(
          this.toyotaSafetySenseSubheaderItems
        );
        pageData.mainComponent = translateText(
          "dropdown.safety.toyotaSafetySense",
          getLocale()
        );
        pageData.subheaderItems = this.toyotaSafetySenseSubheaderItems;
        pageData.onClickFunction = this.fireTSSMenuTabClickEvent;
        pageData.bookmarkTitle = translateText(
          "dropdown.safety.toyotaSafetySense"
        );
        break;
      case "audioMultimedia":
        pageData.title = this.getPageTitle(this.entuneSubheaderItems);
        pageData.mainComponent = translateText(
          "dropdown.audioMultimedia.audioMultimedia",
          getLocale()
        );
        pageData.subheaderItems = this.entuneSubheaderItems;
        pageData.onClickFunction = this.fireEntuneClickEvent;
        pageData.bookmarkTitle = translateText(
          "dropdown.audioMultimedia.audioMultimedia"
        );
        break;
      case "serviceConnect":
        pageData.title = this.getPageTitle(this.serviceConnectSubheaderItems);
        pageData.mainComponent = translateText(
          "dropdown.audioMultimedia.serviceConnect"
        );
        pageData.subheaderItems = this.serviceConnectSubheaderItems;
        pageData.onClickFunction = this.fireServiceConnectClickEvent;
        pageData.bookmarkTitle = translateText(
          "dropdown.audioMultimedia.serviceConnect"
        );
        break;
      case "tcuv":
        pageData.title = this.getPageTitle(this.tcuvSubheaderItems);
        pageData.mainComponent = translateText("tcuv", getLocale());
        pageData.subheaderItems = this.tcuvSubheaderItems;
        pageData.onClickFunction = this.fireTCUVSubHeaderClickEvent;
        pageData.bookmarkTitle = translateText("tcuv");
        break;
      case "smartPath":
        pageData.title = this.getPageTitle(this.smartPathItems);
        pageData.mainComponent = translateText(
          "dropdown.smartPath",
          getLocale()
        );
        pageData.subheaderItems = this.smartPathItems;
        pageData.onClickFunction = this.fireSmartpathSubHeaderClickEvent;
        pageData.bookmarkTitle = translateText("dropdown.smartPath");
        break;
      case "app":
        pageData.title = this.getPageTitle(this.appSubheaderItems);
        pageData.mainComponent = translateText("dropdown.app", getLocale());
        pageData.subheaderItems = this.appSubheaderItems;
        //pageData.onClickFunction = this.fireSmartpathSubHeaderClickEvent;
        pageData.bookmarkTitle = translateText("dropdown.app");
        break;
      case "recallsAndSafety":
        pageData.title = this.getPageTitle(
          this.recallSafetyCampaignSubheaderItems
        );
        pageData.mainComponent = translateText(
          "dropdown.safety.recallsAndSafetyCampaign",
          getLocale()
        );
        pageData.subheaderItems = this.recallSafetyCampaignSubheaderItems;
        pageData.onClickFunction = this.fireRecallSafetyClickEvent;
        pageData.bookmarkTitle = translateText(
          "dropdown.safety.recallsAndSafetyCampaign",
          getLocale()
        );
        break;
      case "engageLive":
        pageData.title = this.getPageTitle(this.engageLiveItems);
        pageData.mainComponent = translateText(
          "dropdown.toyotaEngageLive",
          getLocale()
        );
        pageData.subheaderItems = this.engageLiveItems;
        pageData.onClickFunction = this.fireEngageLiveSubHeaderClickEvent;
        pageData.bookmarkTitle = translateText("dropdown.toyotaEngageLive");
        break;
      case "certification":
        pageData.title = this.getPageTitle(this.certificationSubheaderItems);
        pageData.mainComponent = translateText(
          "dropdown.certification",
          getLocale()
        );
        pageData.subheaderItems = this.certificationSubheaderItems;
        pageData.onClickFunction = this.fireCertificationSubHeaderClickEvent;
        pageData.bookmarkTitle = translateText("dropdown.certification");
        break;
      default:
        console.log("error: Resource/Overview page not found");
        return null;
    }
    return pageData;
  }

  setYear(modelYearMap) {
    if (this.props.route.model) {
      const currentModel = this.props.route.model;
      getVehicleYears(currentModel)
        .then((years) => {
          const yearArray = [];
          years.forEach((year) => {
            yearArray.push({ year: year.toString() });
          });

          modelYearMap[currentModel].forEach((yearToAdd) => {
            if (!this.isDuplicateYear(yearArray, yearToAdd.year)) {
              yearArray.push(yearToAdd);
            }
          });
          this.setState({
            yearSubheaderItems: yearArray,
          });
        })
        .catch((error) => {
          console.log(`error: ${error}`);
        });
    }
  }

  isDuplicateYear(yearList, yearToAdd) {
    let duplicateYear = false;
    yearList.forEach((year) => {
      if (yearToAdd === year.year) {
        duplicateYear = true;
      }
    });
    return duplicateYear;
  }

  printDiv() {
    globalAnalytics.fireOnClick({
      tag: "29.2",
      section: "Product",
      subsection: featuredTechnology,
      container: productNav,
      category: "Nav Link",
      label: "Print",
      contentTitle: " ",
      mediaTitle: " ",
      page: this.props.match.url,
      modelName: this.props.route.model,
    });
    window.print();
  }

  getCurrentPath() {
    const currentURL = window.location.pathname;
    const splitURL = currentURL.split("/");
    let path = splitURL[splitURL.length - 1];

    // below code is needed becuase IE doesn't support String.prototype.includes()
    if (!String.prototype.includes) {
      // eslint-disable-next-line no-extend-native
      String.prototype.includes = function (search, start) {
        if (typeof start !== "number") {
          // eslint-disable-next-line no-param-reassign
          start = 0;
        }
        if (start + search.length > this.length) {
          return false;
        }
        return this.indexOf(search, start) !== -1;
      };
    }

    for (let i = splitURL.length - 2; i > 0; i--) {
      if (
        (path.includes("product") && !currentURL.includes("certification")) ||
        path.includes("audioMultimedia/") ||
        path === "audioMultimedia" ||
        path.includes("recallsAndSafety") ||
        path.includes("engageLive") ||
        path.includes("toyotaSafetySense") ||
        path.includes("starSafetySystem")
      ) {
        i = -1;
      } else {
        path = `${splitURL[i]}/${path}`;
      }
    }

    return `${AppSettings.BasePath}/${path}`;
  }

  getProductTitle() {
    const currentURL = this.getCurrentPath();
    if (currentURL.indexOf("competitiveAdvantages") > -1) {
      return translateText(subheaderCompetitiveAdvantages);
    }
    const subheaderItem = this.productSubheaderItems.filter(
      (item) => item.linkTo === currentURL
    );
    return subheaderItem[0] ? translateText(subheaderItem[0].id) : "";
  }

  getPageTitle(subheadItems) {
    let currentURL = this.getCurrentPath();
    const subheaderItem = subheadItems.filter(
      (item) => item.linkTo === currentURL
    );
    return subheaderItem[0].id ? translateText(subheaderItem[0].id) : "";
  }

  toggleSubLinks() {
    if (this.state.subLinkExpand === false) {
      this.setState({ subLinkExpand: true });
    } else {
      this.setState({ subLinkExpand: false });
    }
  }

  toggleYearLinks() {
    if (this.state.yearLinkExpand === false) {
      this.setState({ yearLinkExpand: true });
    } else {
      this.setState({ yearLinkExpand: false });
    }
    this.yearDropdownClick();
  }

  fireEntuneClickEvent(event) {
    globalAnalytics.fireOnClick({
      tag: "35.2",
      section: "Product",
      subsection: featuredTechnology,
      technologyName: "Entune",
      page: event.target.textContent,
      category: "Video View",
      contentTitle: event.target.textContent,
      mediaTitle: event.target.textContent,
    });
  }

  fireServiceConnectClickEvent(event) {
    globalAnalytics.fireOnClick({
      tag: "35.2",
      section: "Product",
      subsection: featuredTechnology,
      technologyName: "Service Connect",
      page: event.target.textContent,
      category: "Service Connect",
      contentTitle: event.target.textContent,
      mediaTitle: event.target.textContent,
    });
  }

  fireRecallSafetyClickEvent(event) {
    globalAnalytics.fireOnClick({
      tag: "35.2",
      section: "Product",
      subsection: featuredTechnology,
      technologyName: "Recalls and Safety Campaign",
      page: event.target.textContent,
      category: "Recalls and Safety Campaign",
      contentTitle: event.target.textContent,
      mediaTitle: event.target.textContent,
    });
  }

  fireTSSMenuTabClickEvent(event) {
    globalAnalytics.fireOnClick({
      tag: "35.2",
      section: "Product",
      subsection: featuredTechnology,
      technologyName: "Toyota Safety Sense",
      page: event.target.textContent,
      category: "Toyota Safety Sense",
      contentTitle: event.target.textContent,
      mediaTitle: event.target.textContent,
      module: "Tabs",
    });
  }

  fireSmartpathSubHeaderClickEvent(event) {
    globalAnalytics.fireOnClick({
      tag: "35.2",
      section: "Product",
      subsection: featuredTechnology,
      technologyName: "Smart Path",
      page: event.target.textContent,
      category: "Smart Path",
      contentTitle: event.target.textContent,
      mediaTitle: event.target.textContent,
      module: "Tabs",
    });
  }

  fireEngageLiveSubHeaderClickEvent(event) {
    globalAnalytics.fireOnClick({
      tag: "35.2",
      section: "Product",
      subsection: featuredTechnology,
      technologyName: "Engage Live",
      page: event.target.textContent,
      category: "Engage Live",
      contentTitle: event.target.textContent,
      mediaTitle: event.target.textContent,
      module: "Tabs",
    });
  }

  fireCertificationSubHeaderClickEvent(event) {
    globalAnalytics.fireOnClick({
      tag: "38.2",
      section: "Certification",
      subsection: "Overview",
      page: "Main",
      category: event.target.textContent,
      mediaTitle: event.target.textContent,
      action: "Nav Click",
    });
  }

  fireResourceOverviewSubHeaderClickEvent(event, pageTitle) {
    globalAnalytics.fireOnClick({
      tag: "35.2",
      section: "Product",
      subsection: featuredTechnology,
      technologyName: pageTitle,
      page: event.target.innerText,
      category: pageTitle,
      contentTitle: pageTitle,
      mediaTitle: pageTitle,
    });
  }

  fireTCUVSubHeaderClickEvent(event) {
    globalAnalytics.fireOnClick({
      tag: "37.2",
      section: "Product",
      page: "TCUV",
      modelName: " ",
      module: "Tabs",
      label: event.target.innerText,
      trim: " ",
    });
  }

  yearDropdownClick() {
    globalAnalytics.fireOnClick({
      tag: "29.2",
      container: productNav,
      category: "Nav Link",
      label: modelYearDropdown,
      section: modelYearDropdown,
      modelName: this.props.route.model,
      modelYear: this.props.route.year,
      page: "TRIMS / COLORS / OPTIONS / PACKAGES",
    });
  }

  productSubheaderNavClick(event) {
    globalAnalytics.fireOnClick({
      tag: "29.3",
      container: productNav,
      category: "Nav Link",
      label: event.target.textContent,
      section: modelYearDropdown,
      modelName: this.props.route.model,
      modelYear: this.props.route.year,
      page: event.target.textContent,
      contentTitle: " ",
      mediaTitle: " ",
      module: " ",
    });
  }

  nonProductSubheaderNavClick(event) {
    if (this.props.subheaderNavClickEvent) {
      this.props.subheaderNavClickEvent(event.target.textContent);
    }
  }

  render() {
    return SubheaderTemplate(this);
  }
}

SubheaderComponent.propTypes = {
  params: PropTypes.object,
  route: PropTypes.object,
  match: PropTypes.object,
  printDisabled: PropTypes.bool,
  bookmarkNavClickEvent: PropTypes.func,
  subheaderNavClickEvent: PropTypes.func,
};
