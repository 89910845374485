import React from "react";
import RoundedButton from "../../../../components/molecules/roundedButton";
import S from "../../../../styles/scss/beyondZero/resultsHeader.module.scss";

const ResultsHeader = ({ buttonText, onButtonClick, title }) => {
  return (
    <div className={S.container}>
      <RoundedButton className={S["button-style"]} title={buttonText} onClick={onButtonClick}/>
      <span>{title}</span>
    </div>
  );
}

export default ResultsHeader;
