import { Component } from 'react';
import { CAOverviewTemplate } from './overview.tpl';
import getCompetitiveAdvantagesOverview from '../../../services/caOverview.service';
import AppSettings from '../../../appsettings.js';
import { GlobalAnalytics } from '../../../analytics';
import { getLocale } from '../../../services/languageTranslationService.js';
import { getS3PathName } from '../../utils/vehicleName';

const analytics = new GlobalAnalytics();
const userHistory = require('../../../services/userActivityService');

export default class CaOverviewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
      carousel: [{}],
      hasData: false,
      isInMobileView: window.innerWidth < AppSettings.ScreenWidthBreakpoints.small,
      languageOverride: false,
      displayOverrideText: false,
    };

    this.checkWindowDimensions = this.checkWindowDimensions.bind(this);
    this.composeImagePath = this.composeImagePath.bind(this);
    this.checkLocaleAgainstLanguageOverride = this.checkLocaleAgainstLanguageOverride.bind(this);
  }

  loadCAOverview() {
    return new Promise((resolve, reject) => {
      getCompetitiveAdvantagesOverview(this.props.model, this.props.year, this.state.languageOverride ? 'en' : undefined)
        .then((payload) => {
          resolve(payload);
        }).catch((error) => {
          reject(error);
        });
    });
  }

  checkLocaleAgainstLanguageOverride() {
    if (getLocale() === 'es' && !this.state.languageOverride) {
      return 'es';
    }
    return 'en';
  }

  async composeImagePath() {
    const s3PathName = await getS3PathName(this.props.model);
    const localeWithOverride = this.checkLocaleAgainstLanguageOverride();
    const s3Path = `${this.props.year}/${s3PathName}/${localeWithOverride}/`;
    this.setState({
      s3Path,
    });
  }

  getPageData() {
    this.loadCAOverview().then((values) => {
      if (!values.modelReview && values.constructor === Object) {
        this.setState({
          displayOverrideText: true,
          languageOverride: true,
        });
      } else {
        this.setState({
          hasData: true,
          carousel: values.carousel,
          modelReview: values.modelReview,
        });
      }
      this.composeImagePath();
    }).catch((error) => {
      console.log(`error : ${error}`);
    });
  }

  componentWillReceiveProps() {
    this.getPageData();
  }

  componentWillMount() {
    window.addEventListener('resize', this.checkWindowDimensions);
    this.getPageData();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkWindowDimensions);
  }

  componentDidMount() {
    userHistory.postUserActivityRecord();
    this.getPageData();
    analytics.firePageLoad({
      tag: '29.1',
      modelName: this.props.model,
      modelYear: this.props.year,
      section: 'Product',
      page: 'Competitive Advantage:Overview',
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.languageOverride && !prevState.languageOverride) {
      this.getPageData();
    } else if (this.state.displayOverrideText && getLocale() === 'en') {
      this.setState({ displayOverrideText: false });
    }
  }

  checkWindowDimensions() {
    this.setState({
      isInMobileView: window.innerWidth < AppSettings.ScreenWidthBreakpoints.small,
    });
  }

  render() {
    return CAOverviewTemplate(this);
  }
}
