import React, { useEffect, useState } from 'react';
import { TowingPayloadCalculator } from './towingPayload-calculator';

export const BeginnerCalculatorContainer = (props) => {
    const [resetTowingPayloadValues,setResetTowingPayloadValues] = useState(false);
    //objects to hold individual variable values
    const [payloadValues, setPayloadValues] = useState({});
    const [towingValues, setTowingValues] = useState({});

    //total towing and payload values
    const [payload, setPayload] = useState(0);
    const [towing, setTowing] = useState(0);

    const payloadVariables = (props.vehicleType == "truck") ? ["passengerWeight", "cabWeight", "bedWeight"] : ["passengerWeight", "cabinWeight", "trunkWeight", "roofRackWeight"];
    const towingVariables = ["trailerTowWeight", "trailerWeight"];
    const iconsToDisplay = {payload: payloadVariables, towing: towingVariables};

    useEffect(()=>{
        //initialize payload and towing variable objects based on truck or suv variables
        let initPayloadValues = Object.assign({}, ...(
            payloadVariables.map((i) => ({[i]: 0}))
        ));
        let initTowingValues = Object.assign({}, ...(
            towingVariables.map((i) => ({[i]: 0}))
        ));
        setPayloadValues(initPayloadValues);
        setTowingValues(initTowingValues);
    },[resetTowingPayloadValues])

    useEffect(()=>{
        //set reset state to true
        setResetTowingPayloadValues(false)

        //sum up payload variables and set total
        if(payloadValues && Object.keys(payloadValues).length > 0){
            const payloadSum = Object.values(payloadValues).reduce((a, b) => a + b, 0);
            setPayload(payloadSum);
        }
    },[payloadValues])

    useEffect(()=>{
        //set reset state to true
        setResetTowingPayloadValues(false)
        
        //sum up towing variables and set total
        if(towingValues && Object.keys(towingValues).length > 0){
            const towingSum = Object.values(towingValues).reduce((a, b) => a + b, 0);
            setTowing(towingSum);
        }
    },[towingValues])

    return(
        <TowingPayloadCalculator 
            mode="beginner"
            iconsToDisplay={iconsToDisplay}
            setPayloadValues={setPayloadValues}
            payloadValues={payloadValues}
            setTowingValues={setTowingValues}
            towingValues={towingValues}
            payload={payload}
            towing={towing}
            setResetTowingPayloadValues = {setResetTowingPayloadValues}
            {...props}
        />
    );
};