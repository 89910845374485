import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();

export const pageLoad = pageLoadAnalytics => {
    pageLoadAnalytics && analytics.firePageLoad({
        tag: pageLoadAnalytics.tagId,
        section: pageLoadAnalytics.section,
        page: pageLoadAnalytics.page,
    });
};

export const buttonClick = (clickAnalytics, specificAnalytics, phoneNumber) => {
    const label = specificAnalytics.label + (phoneNumber || '');
    analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        section: clickAnalytics.section,
        page: specificAnalytics.page,
        container: specificAnalytics.container,
        category: specificAnalytics.category,
        label
    });
};