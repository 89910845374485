import React, { useState, useEffect, useContext } from 'react';
import { StickyContainer, Sticky } from 'react-sticky';
import getGlossary from '../../services/glossaryService'
import '../../styles/css/featureComparison.css';
import ComparisonSection from './comparisonSection';
import Skeleton from "react-loading-skeleton";
import getAnalytics from '../../services/analyticsService';

const VehicleComparison = (props) => {
    const [glossaryTerms, setGlossaryTerms] = useState(null);
    const [glossaryData, setGlossaryData] = useState(null);
    const [stickyHeaderItems, setStickyHeaderItems] = useState([]);
    const [glossaryAnalytics, setGlossaryAnalytics] = useState(null)

    useEffect(async()=>{
        populateGlossary();
        getStickyHeaderItems();
        await getAnalytics('glossary')
            .then(tooltipAnalytics => {
                setGlossaryAnalytics(tooltipAnalytics);
            })
            .catch(e => 
                console.log(`Failed to get Glossary Analytics, ${e}`)
            )
    },[]);

    const populateGlossary = () => {
        getGlossary()
            .then((data) => {
                let filteredTerms = data.Items.map(item=>item.term);
                setGlossaryTerms(filteredTerms);
                setGlossaryData(data.Items);
            })
            .catch((error) => {
                console.log(`ERROR: failed to load glossary terms. ${JSON.stringify(error.statusText)}`);
            });
    }

    const getStickyHeaderItems = () => {
        if (props.selection) {
            let items = props.selection.map((veh, index) => (
                        <div key={`header-${index}`} className=" trim-banner columns">
                            {veh.trim
                                && `${veh.year} ${veh.trim.fullName}`}
                        </div>
            ));
            setStickyHeaderItems(items);
          }
    }

    return (
        props.features.length ? <StickyContainer>
            <div className="features-specs-lists">
                <Sticky>
                    {
                        ({ isSticky, style }) => {
                          if (isSticky) {
                            return <div style={{ ...style, zIndex: 1 }}>
                                            <div className="row sticky-header">
                                                <div className="small-12 medium-6 large-6"></div>
                                                {stickyHeaderItems}
                                            </div>
                                        </div>;
                          }
                          return <div></div>;
                        }
                    }
                </Sticky>
                <ComparisonSection title={'featuresAndSpecs.featuresListViewTitle'}
                                   sections={props.features}
                                   selection={props.selection}
                                   isFeatureSection={true}
                                   featSpec={props.featSpec}
                                   disclaimerCallback={props.disclaimerCallback}
                                   glossaryTerms={glossaryTerms}
                                   glossaryData={glossaryData}
                                   glossaryAnalytics={glossaryAnalytics} />
                <ComparisonSection title={'featuresAndSpecs.specsListViewTitle'}
                                   sections={props.specs}
                                   selection={props.selection}
                                   isFeatureSection={false}
                                   featSpec={props.featSpec}
                                   disclaimerCallback={props.disclaimerCallback}
                                   glossaryTerms={glossaryTerms}
                                   glossaryData={glossaryData}
                                   glossaryAnalytics={glossaryAnalytics} />
            </div>
        </StickyContainer>
        :
        <div className='accordion-skeleton-container'>
            <Skeleton width={100} height={25}/>
            <Skeleton height={35} count={3}/>
            <Skeleton width={150} height={25}/>
            <Skeleton height={35} count={10}/>
        </div>
    );
} 

export default VehicleComparison;