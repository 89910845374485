import React, { useContext, useEffect, useState } from 'react';
import { NitroContext } from '../../../components/contexts/nitroContext';
import RoundedButton from '../../../components/molecules/roundedButton';
import '../../../styles/scss/engageXP/triviaModal.scss';
import MultipleChoice from './multipleChoice';
import { nextClick, submitAnswersClick, submitQuizClick, triviaCorrectAnswerPageLoad, triviaIncorrectAnswerPageLoad, triviaQuestionPageLoad } from './triviaModal.analytics';
import { postViewPageAction } from '../../../services/nitroService';

const QuestionStates = {
    Unanswered: "unanswered",
    Correct: "correct",
    Incorrect: "incorrect"
}

const TriviaQuestion = ({
    correctCount,
    question,
    index,
    totalQuestions,
    nextQuestion,
    submitQuiz,
    answers,
    submitAnswers,
    isSurvey,
    analyticsData, 
    quizName,
    answersDetails,
    handleTournamentPoints,
    quizId,
}) => {
    const [remainingAnswers, setRemainingAnswers] = useState();
    const [selectedAnswers, setSelectedAnswers] = useState([])
    const [questionResult, setQuestionResult] = useState();
    const [questionState, setQuestionState] = useState();
    const [submittingLastQuestion, setSubmittingLastQuestion] = useState(false);
    const [registerSubmitQuiz, setRegisterSubmitQuiz] = useState({ shouldSubmit: false });
    const { nitroLoadingState } = useContext(NitroContext);
    const clickAnalytics = analyticsData?.clickAnalytics;
    const analyticsPage = "Engage XP";
    const analyticsCategory = "Trivia";


    useEffect(() => {
        setQuestionState(QuestionStates.Unanswered);
        setQuestionResult(null);
        setSelectedAnswers([]);
        setRemainingAnswers(correctCount || 1);
    }, [index]);

    useEffect(() => {
        if (question && analyticsData) {
            const questionPageName = quizName + "-" + question;
            triviaQuestionPageLoad(analyticsData?.pageLoad.triviaQuestion, questionPageName);
        }

    }, [question, analyticsData]);

    useEffect(() => {
        if(!submittingLastQuestion && registerSubmitQuiz.shouldSubmit) {
            submitQuizHandler(registerSubmitQuiz.title);
            setRegisterSubmitQuiz({ shouldSubmit: false });
        }
    }, [submittingLastQuestion, registerSubmitQuiz.shouldSubmit]);

    useEffect(() => {
        if (questionResult && analyticsData) {
            postViewPageAction(window.location.href);
            if (questionResult.correct) {
                const pageNameCorrect = quizName + " - " + question + " - Correct Answer";
                triviaCorrectAnswerPageLoad(analyticsData?.pageLoad.triviaCorrectAnswer, pageNameCorrect);
            } else {
                const pageNameIncorrect = quizName + " - " + question + " - Incorrect Answer";
                triviaIncorrectAnswerPageLoad(analyticsData?.pageLoad?.triviaIncorrectAnswer, pageNameIncorrect);
            }
        }
    }, [analyticsData, questionResult]);

    const submitQuizHandler = (title) => {
        if (title) {
            submitQuizClick(clickAnalytics, analyticsPage, analyticsCategory, title);
        }
        submitQuiz();
    }

    const getButtonType = () => {
        const title = questionState === QuestionStates.Unanswered || isSurvey ? "Submit" : "Next";
        const shouldSubmitAnswers = isSurvey || ((questionState === QuestionStates.Unanswered) && !!index);
        const shouldGoToNextQuestion = !shouldSubmitAnswers && index < totalQuestions;
        const shouldSubmitQuiz = !shouldSubmitAnswers && !shouldGoToNextQuestion;

        if (shouldSubmitQuiz && remainingAnswers !== 0) {
            setRemainingAnswers(0);
        }

        const buttonAction = () => {
            if (shouldSubmitAnswers) {
                submitAnswersClick(clickAnalytics, analyticsPage, analyticsCategory, title);
                handleSubmitAnswers();
            } else if (shouldGoToNextQuestion) {
                nextClick(clickAnalytics, analyticsPage, analyticsCategory, title);
                nextQuestion();
            } else if (shouldSubmitQuiz) {
                setRegisterSubmitQuiz({ shouldSubmit: true, title });
            }
        }

        const isButtonDisabled =
            registerSubmitQuiz.shouldSubmit ||
            nitroLoadingState ||
            !(
                shouldSubmitQuiz ||
                shouldGoToNextQuestion ||
                (shouldSubmitAnswers &&
                ((isSurvey && selectedAnswers.length > 0) ||
                    remainingAnswers === 0))
            );

        return (
            <RoundedButton
                title={title}
                className="customColor bold"
                color="#000"
                textColor="#fff"
                height="44px"
                onClick={buttonAction}
                disabled={isButtonDisabled}
            />
        )
    }

    const handleSubmitAnswers = () => {
        const selectedAnswersIds = answers
            .filter((a) => selectedAnswers.includes(a.text))
            .map((a) => a.id);
        const questionId = answers[0].questionId;
        const isCorrect = selectedAnswersIds.every((id) => answersDetails.answers.find((a) => a.id === id).isCorrect);
        const state = isCorrect
            ? QuestionStates.Correct
            : QuestionStates.Incorrect;
        const result = {correctAnswers: answersDetails.answers.filter(a => a.isCorrect).map(a => a.id)}
        setSubmittingLastQuestion(true);
        surveyAfterSubmitAction();
        submitAnswers(questionId, selectedAnswersIds).then(() => {
            setSubmittingLastQuestion(false);
        });
        if (!isSurvey) {
            setQuestionState(state);
            setQuestionResult(result);
            handleTournamentPoints(isCorrect, quizId, questionId, totalQuestions);
        }
    };

    const surveyAfterSubmitAction = () => {
        if (isSurvey) {
            if (index === totalQuestions) {
                return setRegisterSubmitQuiz({ shouldSubmit: true });
            }
            if (index < totalQuestions) { 
                return nextQuestion();
            }
        }
    }

    return (<>
        <div className="trivia-content trivia-question-content modalScrollArea">
            <div className="innerScrollArea">
                <div className="trivia-question">{question}</div>
                {!isSurvey && <div className="trivia-answers-remaining">{remainingAnswers ? `${remainingAnswers} answer${remainingAnswers !== 1 ? 's' : ''} remaining` : ''}</div>}
                <MultipleChoice
                    options={answers}
                    selectedAnswers={selectedAnswers}
                    setSelectedAnswers={setSelectedAnswers}
                    remainingAnswers={isSurvey ? Infinity : remainingAnswers}
                    setRemainingAnswers={setRemainingAnswers}
                    result={questionResult}
                    analyticsData={analyticsData}
                />
                <div className="trivia-button">{getButtonType()}</div>
            </div>
        </div>
        <div className={`trivia-footer ${questionState}`}>
            {questionState !== QuestionStates.Unanswered && <div className="questionResult">{questionState === QuestionStates.Correct ? "Correct!" : "Incorrect"}</div>}
            {index && `Question ${index}/${totalQuestions}`}
        </div>
    </>);
}

export default TriviaQuestion;
