import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { translateText } from '../utils/translateText';
import { postUserActivityRecord } from '../../services/userActivityService';
import { GlobalAnalytics } from '../../analytics';
import '../../styles/css/engageLive.css';
import EngageVideoWidget from './widgetVideoComponent';
import AppSettings from '../../appsettings';

const analytics = new GlobalAnalytics();

export class EngageLiveArchivedComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      languageOverride: this.props.languageOverride,
      quid: AppSettings.EngageLiveArchiveGuid,
      type: 'vertical',
      numberOfVideos: 2,
    };
  }

  componentDidMount() {
    postUserActivityRecord();
    analytics.firePageLoad({
      tag: '35.1',
      section: 'Product',
      subsection: 'Featured Technology',
      technologyName: 'Engage Live',
      page: translateText(this.props.tab),
    });
  }

  render() {
    return (
      <div className="engage-live">
        <div className="broadcast-title">Archived Live Videocasts</div>
        {this.state.languageOverride
          ? <div className="language-unavailable" >
            <FormattedMessage id="engageLive.languageOverride" default="Language is unavailable" />
          </div>
          : null}
        <div className="message-text">Missed the Toyota Engage Live GR Supra event?</div>
        <div className="info-text">You can watch it On-Demand below.</div>
        <div className="column small-12 large-8 large-offset-3">
          <iframe
            id="ls_embed_1589383265"
            src="https://livestream.com/accounts/1607005/events/8977220/player?width=358&height=202&enableInfoAndActivity=false&defaultDrawer=&autoPlay=true&mute=false"
            width="358"
            height="202"
            frameBorder="0"
            scrolling="no"
            allowFullScreen>
          </iframe>
        </div>
        <div className="row">
          {AppSettings.FeatureFlags.useQumuForEngageLive
            ? <EngageVideoWidget
              alias='5194615091001'
              className="column small-12 large-8 large-offset-3"
              carousel
              hideDownloadButton
              hideEmbedButton
              height={500}
              width={500}
            />
            : <EngageVideoWidget
              guid={this.state.quid}
              numberOfVideos={this.state.numberOfVideos}
              type={this.state.type}
              className="column small-12 large-8 large-offset-3"
              carousel
              hideDownloadButton
              hideEmbedButton
              height={500}
              width={500}
            />
          }
        </div>
      </div>
    );
  }
}

EngageLiveArchivedComponent.propTypes = {
  languageOverride: PropTypes.bool,
  tab: PropTypes.string,
};

export default injectIntl(EngageLiveArchivedComponent);
