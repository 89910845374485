import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import SubheaderComponent from '../../subheader/subheader.component';
import Display404NotFound from '../../../components/atoms/404NotFound';
import getMisc from '../../../services/miscService';
import { getLocale } from '../../../services/languageTranslationService';
import { TCUVCertificationProgramComponent } from '../certificationProgram/tcuvCertificationProgram.component';
import { TCUVGeneralComponent } from '../tcuvGeneral/tcuvGeneral.component';
import TCUVResourcesComponent from '../resources/tcuvResources.component';

const TCUVRouterComponent = (props) => {
  const [pageData, setPageData] = useState({});
  const [languageOverride, setLanguageOverride] = useState(false);
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    const language = getLocale();
    getMisc('TCUV', language)
      .then((sptPageData) => {
        if (Object.keys(sptPageData).length === 0 && sptPageData.constructor === Object) {
          setLanguageOverride(true);
        } else {
          setPageData(sptPageData);
        }
      })
      .catch((error) => {
        console.error(`ERROR: failed to load TCUV page data. ${error}`);
      });
    if (languageOverride && !pageData.items) {
      getMisc('SPT', 'en')
        .then((sptPageData) => {
          setPageData(sptPageData);
        })
        .catch((error) => {
          console.log(`ERROR: failed to load TCUV page data. ${error}`);
        });
    }
  }, [props]);

  const getSubheaderChildComponent = (path) => {
    switch (path ? path.toLowerCase() : '') {
      case 'certificationprogram':
        return <TCUVCertificationProgramComponent pageData={pageData.items.certificationProgram} chartData={pageData.items.comparisonChart} languageOverride={languageOverride} />;
      case 'warranty':
        return <TCUVGeneralComponent pageData={pageData.items.warranty} tab="dropdown.tcuv.warranty" languageOverride={languageOverride} />;
      case '160pointinspection':
        return <TCUVGeneralComponent pageData={pageData.items['160PointInspection']} tab="dropdown.tcuv.160PointInspection" languageOverride={languageOverride} />;
      case 'roadsideassistance':
        return <TCUVGeneralComponent pageData={pageData.items.roadsideAssistance} tab="dropdown.tcuv.roadsideAssistance" languageOverride={languageOverride} />;
      case 'vehiclehistoryreport':
        return <TCUVGeneralComponent pageData={pageData.items.vehicleHistoryReport} tab="dropdown.tcuv.vehicleHistoryReport" languageOverride={languageOverride} />;
      case 'salesrecognition':
        return <TCUVGeneralComponent pageData={pageData.items.salesRecognition} tab="dropdown.tcuv.tcuvSalesRecognition" languageOverride={languageOverride} />;
      case 'resources':
        return <TCUVResourcesComponent tab="global.resources" />;
      default:
        break;
    }
  };

  const isValidTabName = () => ['certificationProgram', 'warranty', '160PointInspection', 'roadsideAssistance', 'vehicleHistoryReport', 'salesRecognition', 'resources'].filter(x => x === params.page).length > 0;
  const getPageDisplay = () => {
    if (isValidTabName()) {
      return (
        <div>
        {pageData.item
          && <SubheaderComponent {...props} params={params} route={params}>
            {getSubheaderChildComponent(params.page)}
          </SubheaderComponent>
        }
      </div>
      );
    }
    return <Display404NotFound display />;
  };

  return (
  <div>{getPageDisplay()}</div>
  );
};

export default injectIntl(TCUVRouterComponent);
