import * as _ from "lodash";
import "swiper/css";
import "swiper/css/navigation";
import React, { useEffect, useState } from "react";
import "../../../styles/css/homepage/datesCarousel.css";
import CarouselV2 from "../../../components/molecules/CarouselV2";
import DatesCarouselItem from "./datesCarouselItem";
import AppSettings from "../../../appsettings";

const DatesCarouselComponent = (props) => {
  const moreButton =
    AppSettings.AWSImgRepo.resources + "en/Down_Arrow_Black_v2.png";
  const [displayedDates, setDisplayedDates] = useState([]);
  const [remainingDates, setRemainingDates] = useState([]);
  const [carouselWidth, setCarouselWidth] = useState([]);
  const [screenWidth, setScreenWidth] = useState([]);

  const sortDateList = (dateList) => {
    return dateList.sort(function (a,b) {
        return new Date(a.date) - new Date(b.date);
    });
}

  const sortedDateList = sortDateList(props.dateList);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setCarouselWidth(
      (Math.floor(props.screenSize / 375) * 370) / props.screenSize
    );
  }, [screenWidth]);

  useEffect(() => {
    if (sortedDateList && props.device === "mobile") {
      loadMoreDates(3);
      setRemainingDates(sortedDateList.length);
    }
  }, [sortedDateList, props.device]);

  const loadMoreDates = (numToDisplay) => {
    const numDatesToDisplay = numToDisplay || displayedDates.length + 3;
    setDisplayedDates(sortedDateList.slice(0, numDatesToDisplay));
    setRemainingDates(sortedDateList.length - displayedDates.length);
  };

  const loadLessDates = () => {
    setDisplayedDates(sortedDateList.slice(0, 3));
    setRemainingDates(sortedDateList.length);
  };


  return (
    <div className="datesCarouselContainer">
      <div className="datesCarouselTitle">{props.title}</div>
      {props.device != "mobile" && sortedDateList.length > 0 && (
        <CarouselV2
          navigation
          width={carouselWidth}
          spaceBetween={0}
          prevArrow={
            <div className="datesArrow prev">
              <img src={moreButton} />
            </div>
          }
          nextArrow={
            <div className="datesArrow next">
              <img src={moreButton} />
            </div>
          }
          slidesCount={
            Math.floor(props.screenSize / 375) >= 5
              ? 5
              : Math.floor(props.screenSize / 375)
          }
          items={sortedDateList.map((date) => {
            return (
              <div className="datesCarouselItemContainer">
                <DatesCarouselItem
                  monthDay={date.date}
                  description={date.description}
                  ctaText={date.ctaText}
                  ctaLink={date.ctaLink}
                />
              </div>
            );
          })}
        />
      )}
      {props.device === "mobile" && (
        <div>
          <div className="datesMobileCarouselContainer">
            {displayedDates.map((date) => {
              return (
                <div className="datesCarouselItemContainer">
                  <DatesCarouselItem
                    monthDay={date.date}
                    description={date.description}
                    ctaText={date.ctaText}
                    ctaLink={date.ctaLink}
                  />
                </div>
              );
            })}
          </div>
          {sortedDateList.length <= 3 ? (
            <></>
          ) : remainingDates > 3 ? (
            <div className="showMoreLessButton" onClick={() => loadMoreDates()}>
              <img src={moreButton} className={"moreButton"} />
            </div>
          ) : (
            <div className="showMoreLessButton" onClick={() => loadLessDates()}>
              <img src={moreButton} className={"lessButton"} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DatesCarouselComponent;
