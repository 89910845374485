import httpOption from './httpOption';
import AppSettings from '../appsettings';

export function getFeaturesAndSpecs(modelName, modelYear, languageOverride) {
  return new Promise(
    (resolve, reject) => {
      const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/vehicle/featuresAndSpecs/${modelName}/${modelYear}`;
      const options = httpOption.GET();
      options.headers['accept-language'] = languageOverride || options.headers['accept-language'];

      return fetch(url, options)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return Promise.resolve(response.text());
          }
          return Promise.reject(response);
        })
        .then((responseText) => {
          const payload = JSON.parse(responseText);
          resolve(payload);
        })
        .catch((error) => {
          reject(error);
        });
    },
  );
}
