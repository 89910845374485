import React, { useEffect, useState, useRef } from "react";
import "../../../styles/css/profilePage/profile-page-header.css";
import AppSettings from "../../../appsettings";
import { getProfileImageUrl } from "../../utils/profileImageUrl";
import {
  postUserProfileImage,
  postUserData,
} from "../../../services/profileService";
import { Buffer } from "buffer";

const ProfilePicture = (props) => {
  const [profilePicture, setProfilePicture] = useState(null);
  let uploaderRef = useRef(null);

  useEffect(() => {
    getProfileImageUrl().then((response) => {
      setProfilePicture(response);
    });
  }, []);

  async function compressImage(file, maxImgSize) {
    const image = new Image();
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    return new Promise((resolve, reject) => {
      image.onload = () => {
        const width = image.width;
        const height = image.height;
        canvas.width = width;
        canvas.height = height;

        // Draw image onto canvas
        context.drawImage(image, 0, 0, width, height);

        // Convert canvas to a data URL with specified quality
        let quality = 0.9; // Starting quality
        let dataURL = canvas.toDataURL("image/jpeg", quality);

        // Check if data URL is below maximum size, and adjust quality as needed
        while (dataURL.length > maxImgSize && quality > 0.1) {
          quality -= 0.1;
          dataURL = canvas.toDataURL("image/jpeg", quality);
        }

        // Convert data URL back to a file object and resolve the promise
        const blob = dataURLToBlob(dataURL);
        const compressedFile = new File([blob], file.name, {
          type: "image/jpeg",
        });
        resolve(compressedFile);
      };

      image.onerror = (error) => {
        reject(error);
      };

      // Load image file into image object
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        image.src = event.target.result;
      };
    });
  }
  
  function dataURLToBlob(dataURL) {
    const parts = dataURL.split(",");
    const mimeType = parts[0].match(/:(.*?);/)[1];
    const base64 = parts[1];
    const buffer = Buffer.from(base64, "base64");
    return new Blob([buffer], { type: mimeType });
  }

  const uploadPhoto = (file) => {
    try {
      postUserProfileImage(file).then((data) => {
        const pictureUrl = `/profile/${data.fileName}`;
        const userData = {
          ...props.userData,
          pictureUrl: pictureUrl,
        };
        postUserData(userData);
        localStorage.setItem("pictureUrl", pictureUrl);
        window.location.reload();
      });
    } catch (e) {
      console.log("Error: " + e);
    }
  };

  const changeProfileImage = async (event) => {
    const maxImgSize = 999999; // Maximum size in bytes
    const file = event.target.files[event.target.files.length - 1];
    if (file && file.name) {
      if (file.size > maxImgSize) {
        try {
          const compressedFile = await compressImage(file, maxImgSize);
          if (compressedFile.size > maxImgSize) {
            uploaderRef.current.value = "";
            window.alert("Please select an image less than 1 MB");
          } else {
            uploadPhoto(compressedFile);
          }
        } catch (error) {
          console.log(`Error: ${error}`);
        }
      } else {
        uploadPhoto(file);
      }
    }
  };

  const getLocalFile = () => {
    if(props?.editProfilePicClickAnalytics) {
      props.editProfilePicClickAnalytics();
    }
    uploaderRef.current.click();
  };

  return (
    <div className="profile-pic-container">
      <div className="profile-pic-image-container">
        {profilePicture ? (
          <img
            className="headerProfilePictureImage"
            src={`data:image;base64, ${profilePicture}`}
            alt="profile"
          />
        ) : (
          <img
            className="headerProfilePictureImage"
            src={AppSettings.AWSImgRepo.resources + `en/default.svg`}
            alt="profile"
          />
        )}
        {props?.showEditPictureButton && (
        <div className="edit-pic-container" onClick={getLocalFile}>
          <img
            className="edit-icon"
            src={
              AppSettings.AWSImgRepo.resources + "en/editProfilePictureIcon.png"
            }
            alt="edit"
          />
          <input
            type="file"
            accept="image/*"
            ref={uploaderRef}
            onChange={changeProfileImage}
            style={{ display: "none" }}
          />
        </div>
        )}
      </div>
    </div>
  );
};

export default ProfilePicture;
