import React, { PureComponent } from "react";
import Swipe from "react-easy-swipe";
import { FormattedMessage } from "react-intl";
import { Sticky, StickyContainer } from "react-sticky";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import AppSettings from "../../appsettings.js";
import SectionHeader from "../../components/atoms/SectionHeader";
import "../../styles/css/TCOP.css";
import CarouselComponent from "../carousel/carousel.component";
import DisclaimersComponent from "../disclaimers/disclaimers.component";
import applyMarkdown from "../utils/applyMarkdown";
import formatMSRP from "../utils/formatMSRP";
import {
  formatModelListItemName,
  getFormattedTrim,
} from "../utils/vehicleName";

export function TCOPTemplate(component) {
  const trimCheckboxes = component.state.trims.map((trim, index) => (
    <div key={index} className="checkbox columns large-4 medium-6">
      <input
        type="checkbox"
        checked={trim.checked}
        id={`trim-checkbox-${index}`}
        onClick={component.checkBoxClicked.bind(null, trim.name)}
        onChange={component.boxChanged.bind(component, trim.name, index)}
      />
      <label htmlFor={`trim-checkbox-${index}`}>
        {applyMarkdown(getFormattedTrim(trim.name))}
      </label>
    </div>
  ));

  // Got rid of MSRP data until real data is acquired
  function TrimCarousel() {
    function numberWithCommas(x) {
      if (x === "TBD") {
        return "TBD";
      }
      if (x.toLowerCase() === "not available") {
        return "Not Available";
      }
      return `$${x.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }

    const carouselItems = component.state.trims
      .map((trim, index) => {
        if (trim.checked) {
          const modelCodeList = component.state.allTrims.filter(
            (t) => t.name.toLowerCase() === trim.name.toLowerCase()
          );

          const keyFeaturesList = trim.keyFeatures
            ? trim.keyFeatures.map((feature, index) => (
                <li key={index}>
                  {applyMarkdown(feature, component.disclaimer)}
                </li>
              ))
            : [];
          let sanitizedUrl = "";
          if (component.props.params.year > 2017) {
            sanitizedUrl = encodeURI(
              (
                AppSettings.AWSImgRepo.vehicle +
                component.state.s3Path +
                trim.trimImageFilename
              ).toString()
            );
          } else {
            sanitizedUrl = encodeURI(
              (
                AppSettings.AWSImgRepo.static + trim.trimImageFilename
              ).toString()
            );
          }

          return (
            <div key={index} className="trim-carousel-item">
              <img
                alt=""
                src={sanitizedUrl}
                onLoad={() => component.handleImageLoad("carousel")}
                onError={() => component.handleImageLoad("carousel")}
              />
              <div className="trim-name">
                {applyMarkdown(getFormattedTrim(trim.name))}
              </div>
              <span className="msrpHeader">
                Base MSRP
                <sup className="disclaimer-link" onClick={() => component.disclaimer.onOpenModal()}>&dagger;</sup>
              </span>
              <div className="modelListSection">
                {modelCodeList?.map((trimListItem) => {
                  return (
                    <div className="modelListItem">
                      <span className="modelListItemCode">
                        {trimListItem.modelCode}
                      </span>
                      <span className="modelListItemName">
                        {formatModelListItemName(
                          trimListItem.fullName,
                          trim.name
                        )}
                      </span>
                      <div className="msrpSection">
                      <span className="modelListItemMSRP">
                        {trimListItem.msrp
                          ? numberWithCommas(trimListItem.msrp)
                          : "MSRP Coming Soon"}
                      </span>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="key-features">
                <div className="key-features-title">
                  {trim.leadIn ? (
                    trim.leadIn
                  ) : (
                    <FormattedMessage
                      id="tcop.keyFeatures"
                      defaultMessage="Key Features"
                    />
                  )}
                </div>
                <ul>{keyFeaturesList}</ul>
              </div>
              {!trim.trimStory ? null : (
                <div className="trim-story">
                  <div className="trim-story-title">
                    <FormattedMessage
                      id="tcop.trimStory"
                      defaultMessage="Trim Story"
                    />
                  </div>
                  {applyMarkdown(trim.trimStory, component.disclaimer)}
                </div>
              )}
            </div>
          );
        }
        return null;
      })
      .filter((item) => item);

    if (carouselItems.length > 0) {
      return (
        <div className="trims-carousel">
          <div className="carousel-title">
            <FormattedMessage
              id="tcop.trimsCarouselHeader"
              defaultMessage="Trims"
            />
          </div>
          <div className="row column">
            <CarouselComponent
              {...{
                settings: {
                  arrows: carouselItems.length > 3, // Only show arrows when there are more than one page worth of items
                  dots: true,
                  infinite: carouselItems.length > 3, // Only turn on infinite when more than a page of items
                  responsive: [
                    {
                      breakpoint: 640,
                      settings: {
                        arrows: false,
                        infinite: true, // Since we are showing only 1 per page, we can turn on inifinite
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      },
                    },
                  ],
                },
              }}
            >
              {carouselItems}
            </CarouselComponent>
          </div>
        </div>
      );
    }
    return (
      <div className="empty-trims-carousel">
        <FormattedMessage
          id="tcop.noTrimsSelectedMsg"
          defaultMessage="You have not selected any trims to view."
        />
      </div>
    );
  }

  class COPSelectTrim extends PureComponent {
    render() {
      const copTrimButtons = (component.state.trims || []).map(
        (trim, index) => {
          const isSelectedTrim =
            trim.modelCode === component.state.selectedTrim.modelCode;

          return (
            <div key={index} className="cop-select-trim">
              <span
                className={
                  isSelectedTrim
                    ? "cop-select-trim-name selected"
                    : "cop-select-trim-name"
                }
                onClick={component.handleTrimChange.bind(component, index)}
              >
                {applyMarkdown(
                  getFormattedTrim(trim.name),
                  component.disclaimer
                )}
              </span>
              {index !== +component.state.trims.length - 1 && (
                <span className="delimiter">|</span>
              )}
            </div>
          );
        }
      );

      if (this.props.component.state.isInMobileView) {
        return (
          <Sticky>
            {({ isSticky, style }) => {
              if (isSticky) {
                return (
                  <div style={{ ...style, zIndex: 1 }}>
                    <div className="COP-trim-selector mobile">
                      Show <strong>Color / Options / Packages</strong> for:
                      <div className="swipeable-container">
                        <div className="swipeable-content">
                          <Swipe>{copTrimButtons}</Swipe>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              return (
                <div className="COP-trim-selector mobile">
                  Show <strong>Color / Options / Packages</strong> for:
                  <div className="swipeable-container">
                    <div className="swipeable-content">
                      <Swipe>{copTrimButtons}</Swipe>
                    </div>
                  </div>
                </div>
              );
            }}
          </Sticky>
        );
      }

      const scrollToColors = (ref) => {
        if (
          this.props.component.state.readyForScroll &&
          this.props.component.state.scrollToColor &&
          ref
        ) {
          const colors = document.getElementById("colors");
          const topNav =
            document.getElementsByClassName("top-nav-container")[0];
          const topNavHeight = topNav
            ? topNav.getBoundingClientRect().bottom
            : 80;
          const y =
            colors.getBoundingClientRect().top +
            window.pageYOffset -
            topNavHeight;
          window.scrollTo({ top: y, behavior: "smooth" });
          this.props.component.setState({
            scrollToColor: false,
          });
        }
      };

      const trimSelectorWithoutAnchor = (
        <div className="COP-trim-selector">
          Show{" "}
          <strong>
            <FormattedMessage
              id="tcop.COP"
              defaultMessage="Color / Options / Packages"
            />
          </strong>{" "}
          for: {copTrimButtons}
        </div>
      );

      const trimSelectorWithAnchor = (
        <div ref={scrollToColors} id="colors">
          {trimSelectorWithoutAnchor}
        </div>
      );

      return this.props.component.state.readyForScroll
        ? trimSelectorWithAnchor
        : trimSelectorWithoutAnchor;
    }
  }

  function ExteriorColors() {
    const sortedColors = function (exteriorColors) {
      const sortedColorObject = exteriorColors.sort((color1, color2) =>
        color1.paintCodeName.localeCompare(color2.paintCodeName)
      );
      return sortedColorObject;
    };

    const extColorSwatches = sortedColors(component.extColors).map((color) => {
      const isAvailable = function () {
        let _isAvailable = false;
        if (component.state.selectedTrim.colorsAvailable) {
          component.state.selectedTrim.colorsAvailable.forEach((e) => {
            if (e.paintCode === color.paintCode) {
              _isAvailable = true;
            }
          });
        }
        return _isAvailable;
      };
      const hexCode =
        color.hexCode || AppSettings.PaintHexCodes[color.paintCode];
      const style = {
        backgroundColor: hexCode,
      };
      // This code determines where or not a swatch should have a darker checkmark or 'x' overlay
      // For example, Super White needs these darker accents
      let useDarkAccents = false;
      if (
        hexCode === undefined ||
        parseInt(hexCode.replace("#", "0x"), 16) >= 0xe3e9e9
      ) {
        useDarkAccents = true;
      }

      if (isAvailable()) {
        return (
          <div
            className={
              useDarkAccents
                ? "swatch color-available dark-accents"
                : "swatch color-available"
            }
            style={style}
            key={color.paintCode}
            onClick={component.handleColorChange.bind(
              component,
              color.paintCode
            )}
          >
            <div
              className={
                component.state.selectedExtColor.paintCode === color.paintCode
                  ? "color-selected"
                  : ""
              }
            ></div>
          </div>
        );
      }
      return (
        <div
          className={useDarkAccents ? "swatch dark-accents" : "swatch"}
          style={style}
          key={color.paintCode}
        >
          <div className="color-unavailable"></div>
        </div>
      );
    });

    let paintColorName = component.state.selectedExtColor.paintCodeName
      ? component.state.selectedExtColor.paintCodeName
      : " ";
    paintColorName += " ";
    paintColorName += component.state.selectedExtColor.paintCode
      ? component.state.selectedExtColor.paintCode
      : " ";
    paintColorName += component.state.selectedExtColor.paintCodeCharge
      ? ` - PC ${component.state.selectedExtColor.paintCodeCharge}`
      : " ";

    return (
      <div className="exterior">
        <div className="int-ext-title">
          <FormattedMessage
            id="tcop.extColorsHeader"
            defaultMessage="Exterior"
          />
        </div>
        <div className="ext-color-name">
          {applyMarkdown(paintColorName, component.disclaimer)}
        </div>
        <img
          alt=""
          width="700px"
          height="300px"
          src={component.state.extColorImgFilename}
          onLoad={() => component.handleImageLoad("color")}
          onError={() => component.handleImageLoad("color")}
        />
        <div className="color-swatches">{extColorSwatches}</div>
      </div>
    );
  }

  function InteriorColors() {
    const sortedColors = function (interiorColors) {
      const sortedColorObject = Object.keys(interiorColors).sort(
        (color1, color2) =>
          interiorColors[color1].name.localeCompare(interiorColors[color2].name)
      );
      return sortedColorObject;
    };

    const intColors = sortedColors(component.intColors).map((colorCode) => {
      let isAvailable = false;
      if (component.state.selectedExtColor.intColors) {
        component.state.selectedExtColor.intColors.forEach((e) => {
          if (e === colorCode) {
            isAvailable = true;
          }
        });
      }
      if (component.props.params.year < 2018) {
        return (
          <div key={colorCode} className={isAvailable ? "" : "unavailable"}>
            {applyMarkdown(colorCode, null)}
          </div>
        );
      }
      return (
        <div key={colorCode} className={isAvailable ? "" : "unavailable"}>
          {applyMarkdown(component.intColors[colorCode].name, null)} (
          {colorCode})
        </div>
      );
    });

    return (
      <div className="interior">
        <div className="int-ext-title">
          <FormattedMessage
            id="tcop.intColorsHeader"
            defaultMessage="Interior"
          />
        </div>
        {intColors}
      </div>
    );
  }

  function FactoryOptions() {
    // const hasFactoryOption = true;
    let factoryOptionsList = component.state.selectedTrim.availableOptions.map(
      (option, index) => (
        <div className="list-item" key={index}>
          {!option.imageFilename ? null : (
            <img
              alt=""
              src={encodeURI(
                (
                  AppSettings.AWSImgRepo.vehicle +
                  component.state.s3Path +
                  option.imageFilename
                ).toString()
              )}
              className="item-image"
              onLoad={() => component.handleImageLoad("options")}
              onError={() => component.handleImageLoad("options")}
            />
          )}
          <div className="item-info">
            <div>
              <b>{applyMarkdown(option.title, component.disclaimer)}</b>
            </div>
            <div>
              <FormattedMessage
                id="tcop.factoryOptionsCode"
                defaultMessage="Code: "
              />
              <b>{option.code}</b>
            </div>
            <div>
              <FormattedMessage
                id="tcop.factoryOptionsMsrp"
                defaultMessage="MSRP: "
              />
              <b>{formatMSRP(option.msrp)}</b>
            </div>
            <p>{applyMarkdown(option.description, component.disclaimer)}</p>
          </div>
        </div>
      )
    );

    if (factoryOptionsList.length === 0) {
      factoryOptionsList = (
        <div className="list-item">
          <FormattedMessage
            id="tcop.noFactoryOptions"
            defaultMessage="No factory options available at this time"
          />
        </div>
      );
    }

    return (
      <div className="options-packages-list">
        <div className="section-title">
          <FormattedMessage
            id="tcop.selectedTrimFactoryOptionsHeader"
            defaultMessage="{trim} Factory Options"
            values={{
              trim: (
                <span className="emphasize">
                  {component.state.selectedTrim.name
                    ? applyMarkdown(
                        getFormattedTrim(component.state.selectedTrim.name)
                      )
                    : null}
                </span>
              ),
            }}
          />
        </div>
        <a target="_parent">{factoryOptionsList}</a>
      </div>
    );
  }

  function Packages(props) {
    let packagesList = component.state.selectedTrim.availablePackages.map(
      (_package, index) => {
        const sanitizedImageUrl = encodeURI(
          (
            AppSettings.AWSImgRepo.vehicle +
            component.state.s3Path +
            _package.imageFilename
          ).toString()
        );

        return (
          <div className="list-item" key={index}>
            {!_package.imageFilename ? null : (
              <img
                alt=""
                src={sanitizedImageUrl}
                className="item-image"
                onLoad={() => component.handleImageLoad("packages")}
                onError={() => component.handleImageLoad("packages")}
              />
            )}
            <div className="item-info">
              <div>
                <b>{applyMarkdown(_package.title, component.disclaimer)}</b>
              </div>
              <div>
                <FormattedMessage
                  id="tcop.packagesCode"
                  defaultMessage="Code: "
                />
                <b>{_package.code}</b>
              </div>
              <div>
                <FormattedMessage
                  id="tcop.packagesMsrp"
                  defaultMessage="MSRP: "
                />
                <b>{formatMSRP(_package.msrp)}</b>
              </div>
              <p>{applyMarkdown(_package.description, component.disclaimer)}</p>
            </div>
          </div>
        );
      }
    );

    if (packagesList.length === 0) {
      packagesList = (
        <div>
          <FormattedMessage
            id="tcop.noPackages"
            defaultMessage="No packages available at this time"
          />
        </div>
      );
    }

    const packagesElementWithoutAnchor = (
      <div className="options-packages-list">
        <div className="section-title">
          <FormattedMessage
            id="tcop.selectedTrimPackagesHeader"
            defaultMessage="{trim} Packages"
            values={{
              trim: (
                <span className="emphasize">
                  {component.state.selectedTrim.name
                    ? applyMarkdown(
                        getFormattedTrim(component.state.selectedTrim.name)
                      )
                    : null}
                </span>
              ),
            }}
          />
        </div>
        {packagesList}
      </div>
    );

    const scrollToPackages = (ref) => {
      if (
        props.component.state.readyForScroll &&
        props.component.state.scrollToPackage &&
        ref
      ) {
        const packages = document.getElementById("packages");
        const topNav = document.getElementsByClassName("top-nav-container")[0];
        const topNavHeight = topNav
          ? topNav.getBoundingClientRect().bottom
          : 80;
        const y =
          packages.getBoundingClientRect().top +
          window.pageYOffset -
          topNavHeight;
        window.scrollTo({ top: y, behavior: "smooth" });
        props.component.setState({
          scrollToPackage: false,
        });
      }
    };

    const packagesElementWithAnchor = (
      <div ref={scrollToPackages} id={"packages"}>
        {packagesElementWithoutAnchor}
      </div>
    );

    return props.component.state.readyForScroll
      ? packagesElementWithAnchor
      : packagesElementWithoutAnchor;
  }

  return (
    <div className="TCOP">
      <div className="margin-container">
        {component.state.displayOverrideText ? (
          <div className="languageOverride">
            <b>
              <FormattedMessage
                id="dataUnavailable"
                defaultMessage="El contenido en Español está pendiente"
              />
            </b>
          </div>
        ) : (
          <div />
        )}
        <SectionHeader id="tcop.pageTitle" />
        <div className="trim-selector-checkboxes row">
          <div className="trims-available">
            <div className="verticle-center-container">
              <div className="number-of-trims">
                {component.state.trims.length}
              </div>
              <div className="trims-available-words">
                <div>
                  <FormattedMessage
                    id="tcop.trimsAvailable1"
                    defaultMessage="Trims"
                  />
                </div>
                <div>
                  <FormattedMessage
                    id="tcop.trimsAvailable2"
                    defaultMessage="Available"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="trim-checkboxes">
            <div className="compare-trims-words">
              <FormattedMessage
                id="tcop.compareTrims"
                defaultMessage="Compare Trims"
              />
            </div>
            <div className="row">{trimCheckboxes}</div>
          </div>
          <div className="toggle-all-trims">
            <div className="toggle-all-buttons">
              <div
                className="deselect"
                onClick={component.deselectAll.bind(component)}
              >
                <FormattedMessage
                  id="tcop.deselectAllBtn"
                  defaultMessage="Deselect All"
                />
              </div>
              <div
                className="select-all"
                onClick={component.selectAll.bind(component, true)}
              >
                <FormattedMessage
                  id="tcop.selectAllBtn"
                  defaultMessage="Select All"
                />
              </div>
            </div>
          </div>
        </div>
        <TrimCarousel />
      </div>
      <StickyContainer>
        <COPSelectTrim component={component} />
        <div className="margin-container">
          <div className="section-title">
            <FormattedMessage
              id="tcop.selectedTrimColorsHeader"
              defaultMessage="{trim} Colors"
              values={{
                trim: (
                  <span className="emphasize">
                    {component.state.selectedTrim.name
                      ? applyMarkdown(
                          getFormattedTrim(component.state.selectedTrim.name)
                        )
                      : null}
                  </span>
                ),
              }}
            />
          </div>
          <div className="color-section">
            <ExteriorColors />
            <InteriorColors />
          </div>
          <FactoryOptions />
          <Packages component={component} />
          <DisclaimersComponent
            template={`${component.props.params.year}_${component.props.route.model}`}
            ref={(instance) => {
              component.disclaimer = instance;
            }}
            addBaseMSRPDisclaimer={true}
          />
        </div>
      </StickyContainer>
    </div>
  );
}
