import React from 'react';

export function StickyVehiclesTemplate(component) {
  let header = <div></div>;

  if (component.props.show && component.props.vehicles) {
    header = (
      <div style={component.props.style} className="sticky-vehicles">
        {
          component.props.vehicles.map((vehicle, index) => {
            let vehicleClass = `vehicle vehicle-span-${component.props.vehicles.length}`;
            if (index > 1) {
              vehicleClass += ' hide-for-small-only';
            }

            return (
              <div key={index} className={vehicleClass}>
                <span className="show-for-large">{vehicle.year} {vehicle.make} </span>
                <span>{vehicle.modelValue}</span>
              </div>
            );
          })
        }
      </div>
    );
  }
  return header;
}
