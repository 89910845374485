import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();

export function sendPageLoadAnalytics() {
  analytics.firePageLoad({
    tag: '29.1',
    section: 'Product',
    page: 'Trims',
    modelName: this.props.route.model,
    modelYear: this.props.params.year,
  });
}

export function sendClickAnalytics(container, category, label) {
  analytics.fireOnClick({
    tag: '29.2',
    section: 'Product',
    page: 'Trims',
    modelName: this.props.route.model,
    modelYear: this.props.params.year,
    container,
    category,
    label,
  });
}
