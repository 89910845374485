import React from 'react';
import PropTypes from 'prop-types';
import { translateText } from '../utils/translateText';
import { getLocale } from '../../services/languageTranslationService';

export default function EngageLiveCountdown(props) {
  const {
    days,
    hours,
    minutes,
    seconds,
    completed,
  } = props;

  if (completed) {
    return null;
  }
  return <div className="message-text">{days} {translateText('engageLive.countdown.days', getLocale())}, {hours} {translateText('engageLive.countdown.hours', getLocale())}, {minutes} {translateText('engageLive.countdown.minutes', getLocale())}, {seconds} {translateText('engageLive.countdown.seconds', getLocale())} {translateText('engageLive.countdown.message', getLocale())}</div>;
}

EngageLiveCountdown.propTypes = {
  days: PropTypes.any,
  hours: PropTypes.any,
  minutes: PropTypes.any,
  seconds: PropTypes.any,
  completed: PropTypes.any,
};
