module.exports = {

  getArticleCategories(modelYear) {
    const newVehicleYearCategories = [
      { label: 'global.button.all', id: 'All' },
      { label: 'global.articleCategory.overview', id: 'Overview' },
      { label: 'global.articleCategory.ffbAbbreviation', id: 'Feature Function Benefits' },
      { label: 'global.articleCategory.howTo', id: 'How To' },
      { label: 'global.articleCategory.headToHead', id: 'Head to Head' },
      { label: 'global.articleCategory.3rdParty', id: '3rd Party' },
    ];
    const oldVehicleYearCategories = [
      { label: 'global.button.all', id: 'All' },
      { label: 'global.articleCategory.video', id: 'Video' },
      { label: 'global.articleCategory.headToHead', id: 'Head to Head' },
      { label: 'global.articleCategory.km$m', id: 'KMSM' },
      { label: 'global.articleCategory.cfa', id: 'CFA' },
      { label: 'global.articleCategory.hotsheet', id: 'Hot Sheet' },
    ];
    if (modelYear) {
      if (parseInt(modelYear, 10) > 2020) {
        return newVehicleYearCategories;
      }
    }
    return oldVehicleYearCategories;
  },

  getRedlineDisplay(articleCategoryArray, articleFormat) {
    // Ensure that categories array is valid JSON
    const formattedCategories = (articleCategoryArray && typeof articleCategoryArray === 'string') ? JSON.parse(articleCategoryArray) : articleCategoryArray;

    // If only 1 category in array, return that category automatically
    let typeToDisplay = formattedCategories.length === 1 ? formattedCategories.toString() : null;

    if (!typeToDisplay) {
      const oldCategory = formattedCategories.filter(item => ['article', 'edge', 'video', 'kmsm', 'cfa', 'hot sheet'].includes(item.toLowerCase()));
      if (oldCategory && oldCategory.length === 1) {
        typeToDisplay = oldCategory.toString();
      } else {
        const newCategory = formattedCategories.filter(item => ['overview', 'how to', 'feature function benefits', 'head to head', '3rd party'].includes(item.toLowerCase()));
        if (newCategory && newCategory.length === 1) {
          typeToDisplay = newCategory.toString();
        } else {
          typeToDisplay = articleFormat;
        }
      }
    }
    return typeToDisplay.toLowerCase();
  },

  articleSortOptions() {
    return [
      { value: '', id: 'global.sortBy' },
      { value: 'date', id: 'global.mostRecent' },
      { value: 'a-z', id: 'global.forwardAlphaSort' },
      { value: 'z-a', id: 'global.backwardAlphaSort' },
    ];
  },

  getCategoryLocalId(category) {
    if (!category) {
      return 'global.articleCategory.article';
    }
    switch (category.toLowerCase()) {
      case 'kmsm':
        return 'global.button.kmsm';
      case 'cfa':
        return 'global.button.cfa';
      case 'video':
        return 'global.articleCategory.video';
      case 'hot sheet':
        return 'global.articleCategory.hotsheet';
      case 'edge':
      case 'head to head':
        return 'global.articleCategory.headToHead';
      case 'file':
        return 'global.articleCategory.file';
      case 'how to':
        return 'global.articleCategory.howTo';
      case 'overview':
        return 'global.articleCategory.overview';
      case 'ffb':
      case 'feature function benefits':
        return 'global.articleCategory.ffbAbbreviation';
      case '3rd party':
        return 'global.articleCategory.3rdParty';
      default:
        return 'global.articleCategory.article';
    }
  },
};
