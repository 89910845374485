import React from "react";
import S from "../../../../styles/scss/beyondZero/resultCard.module.scss";

const ResultCard = ({ imagePath, title, content, onClick }) => {
  return (
    <div className={S.container}>
      <img src={imagePath} alt={title} />
      <section>
        <h4>{title}</h4>
        <p>{content}</p>
        <a onClick={onClick}>Vehicle Overview</a>
      </section>
    </div>
  )
}

export default ResultCard;
