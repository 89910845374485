import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { ClipLoader } from 'react-spinners';
import AppSettings from '../../appsettings';
import EmailModalComponent from '../emailModal/emailModal.component';
import { PdfModal } from './pdfModal';

const VinContent = props => <div className='vinContentDiv'>
    {props.loading
        && <div className='spinner'>
            <ClipLoader color={'#EB0A1E'} size={50} />
        </div>
    }
    {props.vinUrl
        && <div className="vinContentGrid">
            <div className="vinVehicleName">
                <div className='vinNameDiv'>
                    <span className="vinYearText">{props.vehicleYear} </span>
                    <span className="vinNameText">{props.vehicleName}</span>
                </div>
            </div>
            <div className="vinButtons" >
                <div className="vinDownload">
                    <a onClick={() => props.handleDownloadClick(() => {
                        window.open(props.vinUrl, '_blank')
                    }, 'download')} >
                        <img alt="download" src={AppSettings.AWSImgRepo.resources + 'en/download_icon.svg'} className="vinDownloadIcon" />
                        <span className="vinDownloadText">Download</span>
                    </a>
                </div>
                <div className="vinShare" onClick={() => props.handleDownloadClick(() => props.openEmailModal(), 'share')}>
                    <img alt="share" src={AppSettings.AWSImgRepo.resources + 'en/email_icon.svg'} className="vinShareIcon" />
                    <span className="vinShareText">Share</span>
                </div>
            </div>
            {props.emailModalOpen
                && <EmailModalComponent
                    open={props.emailModalOpen}
                    vinUrl={props.vinUrl}
                    subject={`${props.vehicleYear} ${props.vehicleName} Info Sheet`}
                    closeModal={props.closeEmailModal}
                />
            }
        </div>
    }
</div >;

export default injectIntl(VinContent);

VinContent.propTypes = {
  loading: PropTypes.bool,
  vehicleName: PropTypes.string,
  vehicleYear: PropTypes.string,
  vin: PropTypes.string,
  vinUrl: PropTypes.string,
  openEmailModal: PropTypes.func,
  closeEmailModal: PropTypes.func,
  handleDownloadClick: PropTypes.func,
  emailModalOpen: PropTypes.bool,
};
