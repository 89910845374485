import React from 'react';
import boldMatchingText from '../utils/boldMatchingText';
import appsettings from '../../appsettings';
import "../../styles/css/featureLookupFeatureList.css";
import applyMarkdownRemoveDisclaimers from '../utils/applyMarkdownRemoveDisclaimers';
import { featureLookupOverviewAddFeatureClick, featureLookupRemoveFeatureClick } from './featureLookup.analytics';
import { ClipLoader } from 'react-spinners';

function FeatureList(props) {
    const plusIconSelected = appsettings.AWSImgRepo.resources + 'en/plus_icon_filled.png';
    const plusIconInactive = appsettings.AWSImgRepo.resources + 'en/plus_icon_grey.png';
    const plusIconActive = appsettings.AWSImgRepo.resources + 'en/plus_icon_black.png';

    function mouseOver(event, isSelected) {
        if (isSelected) return;
        event.target.src = plusIconActive;
    }

    function mouseOut(event, isSelected) {
        if (isSelected) return;
        event.target.src = plusIconInactive;
    }

    function toggleSelect(feature, isSelected, featureIndex) {
        if (isSelected) {
            props.analyticsData && featureLookupRemoveFeatureClick(props.analyticsData.clickAnalytics, props.analyticsData.clickAnalytics?.overview.category, feature.featureName, props.tab);
            props.removeFeature(feature.featureName, featureIndex);
        } else {
            props.analyticsData && featureLookupOverviewAddFeatureClick(props.analyticsData.clickAnalytics, props.tab, feature.featureName);
            props.addFeature(feature);
        }
    }

    return (
        <div className='feature-list'>
            <div className='feature-list-header'>{props.headerText}</div>
            <div className='divider-line' />
            <div className='features-container'>
                {!props.listFeaturesLoaded ?
                    <div className="featureSpinner">
                        <ClipLoader color={"#EB0A1E"} loading={true} size={50} />
                        <div className='p'>Loading</div>
                    </div> :
                    props.listFeatures.map(feature => {
                        const isSelected = props.isSelected(feature.featureName);
                        const indexOfFeature = props.indexOfFeature(feature.featureName);
                        return (
                            <div className='feature' key={feature.featureName} onClick={() => props.device != "desktop" && toggleSelect(feature, isSelected, indexOfFeature)}>
                                <img
                                    className='add-icon'
                                    src={isSelected ? plusIconSelected : plusIconInactive}
                                    alt="Add Feature"
                                    onMouseOver={(event) => mouseOver(event, isSelected)}
                                    onMouseOut={(event) => mouseOut(event, isSelected)}
                                    onClick={() => props.device == "desktop" && toggleSelect(feature, isSelected, indexOfFeature)}
                                />
                                <div className='name'>
                                    {applyMarkdownRemoveDisclaimers(boldMatchingText(feature.featureName, props.searchText))}
                                    {feature.modelYears && <span className='modelYearsBadge' >{props.countCompatibleModels(feature.modelYears)} Vehicle{feature.modelYears.length === 1 ? '' : 's'}</span>}
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

export default FeatureList;