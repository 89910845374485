import { useEffect, useState, useMemo } from "react";
import AnnouncementCardV2 from "./announcementCardV2";
import DropdownV2 from "../../../components/molecules/DropdownV2";
import "../../../styles/css/profilePage/profile-page-announcements.css";
import { readAnnouncements } from "../../../services/notificationService";
import Skeleton from "react-loading-skeleton";
import { announcementPagePageLoad, sortDropdownClick, categoryDropdownClick, markAllAsReadClick } from "./notificationsPageAnalytics";


const AnnouncementsPageV2 = (props) => {
    const [announcements, setAnnouncements] = useState(null);
    const [displayedAnnouncements, setDisplayedAnnouncements] = useState(null);
    const [categories, setCategories] = useState(null);
    const sortOptions = ['Most Recent', 'Oldest to Newest'];
    const [dropdownFilter, setDropdownFilter] = useState('All');
    const [dropdownSort, setDropdownSort] = useState('Most Recent');
    const allAnnouncementsRead = useMemo(() => {
        return displayedAnnouncements ? displayedAnnouncements.every(a => a.isRead) : false
    }, [displayedAnnouncements])

    const sortFilterAnnouncements = () => {
        let filteredAnnouncements = (dropdownFilter === 'All' ? [...announcements] : announcements.filter(announcement => announcement.category === dropdownFilter))

        if (dropdownSort === 'Oldest to Newest') {
            const sorted = filteredAnnouncements.sort((a, b) => {
                const dateA = new Date(a.lastModifiedDate);
                const dateB = new Date(b.lastModifiedDate);
                return (dateA - dateB);
            })
            setDisplayedAnnouncements(sorted);
        }
        else if (dropdownSort === 'Most Recent') {
            const sorted = filteredAnnouncements.sort((a, b) => {
                const dateA = new Date(a.lastModifiedDate);
                const dateB = new Date(b.lastModifiedDate);
                return (dateB - dateA);
            })
            setDisplayedAnnouncements(sorted)
        } else {
            setDisplayedAnnouncements(filteredAnnouncements)
        }
    }

    const markAllAsRead = async () => {
        props?.setNotificationCount({ ...props?.notificationCount, unreadAnnouncements: 0 });
        setDisplayedAnnouncements(displayedAnnouncements.map(i => ({...i, isRead: true})));
        const announcementIds = announcements.filter(i => !i.isRead).map(i => i.id);
        markAllAsReadClick(props?.analyticsData?.clickAnalytics);
        await readAnnouncements(announcementIds);
    }


    useEffect(() => {
        setAnnouncements(props?.announcements);
        setDisplayedAnnouncements(props?.announcements);
        let extractedCategories = ['All', ...new Set(props?.announcements?.map(a => a.category))];
        setCategories(extractedCategories);
    },[props?.announcements])

    useEffect(() => {
        announcements && sortFilterAnnouncements();
    }, [dropdownSort, dropdownFilter, announcements])

    useEffect(() => {
        if(props?.analyticsData) {
            announcementPagePageLoad(props?.analyticsData?.pageLoad?.notifications);
        }
    }, [props?.analyticsData]);

    const handleSortChange = (value) => {
        setDropdownSort(value);
        sortDropdownClick(props?.analyticsData?.clickAnalytics, value);
    };

    const handleFilterChange = (value) => {
        setDropdownFilter(value);
        categoryDropdownClick(props?.analyticsData?.clickAnalytics, value);
    };

    return (
        <div className="announcements-container">
            <div className='announcements-filters'>
                <div className="dropdown-layout">
                    <DropdownV2
                        rootClassName="announcementsDropdown"
                        style={"white"}
                        onValueChange={(value) => handleSortChange(value.label)}
                        options={sortOptions}
                        disabled={false}
                        defaultValue={sortOptions[0]}
                    />
                    <DropdownV2
                        rootClassName="announcementsDropdown"
                        style={"white"}
                        onValueChange={(value) => handleFilterChange(value.label)}
                        options={categories}
                        disabled={false}
                        defaultValue={'Category'}
                    />
                </div>
                {!allAnnouncementsRead &&
                    <div className={`mark-all-read-button`}>
                        <span onClick={() => markAllAsRead()}>Mark all as read</span>
                    </div>
                }
            </div>
            {!props?.announcementsLoading ?
                displayedAnnouncements?.length > 0 ?
                    <div className="announcementCardV2-container">
                        {displayedAnnouncements?.map((announcement) => {
                            return (
                                <AnnouncementCardV2
                                    announcementData={announcement}
                                    notificationCount={props?.notificationCount}
                                    setNotificationCount={props?.setNotificationCount}
                                    analyticsData={props?.analyticsData}
                                />
                            );
                        })
                        }
                    </div>
                :
                    <div className='no-results'>
                        No Notifications Found
                    </div>
            :
            <div className="announcements-skeleton-loading">
                <Skeleton count={3} height={130} />
            </div>
            }
        </div>
    )
}
export default AnnouncementsPageV2;