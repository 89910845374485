import React from 'react';
import { getLocale } from '../../services/languageTranslationService.js';

const englishMonths = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'];

const spanishMonths = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
  'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

export function formatDate(date) {
  const dateObject = new Date(date);
  let formattedDate;

  if (getLocale() === 'en') {
    formattedDate = dateObject ? (`${englishMonths[dateObject.getMonth()]} ${dateObject.getDate()}, ${dateObject.getFullYear()}`) : date;
  } else {
    formattedDate = dateObject ? (`${dateObject.getDate()} de ${spanishMonths[dateObject.getMonth()]} ${dateObject.getFullYear()}`) : date;
  }

  return (
        <span>{formattedDate}</span>
  );
}

export function formatDateTime(endDate) {
  const date = new Date(endDate);
  
  const options = {
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  
  const formattedDate = date.toLocaleString('en-US', options);
  const [datePart, timePart] = formattedDate.split('at');
  
  return `${datePart} ${timePart.trim()}`;
}

export function formatDateAbbreviated(rawDate){
  let dateStr = '';
    if (rawDate) {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const date = new Date(rawDate);
      dateStr = `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
    }
    return dateStr;
}

// module.exports = formatDate;
