import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { TCUVCertificationProgramTemplate } from './tcuvCertificationProgram.tpl';
import { postUserActivityRecord } from '../../../services/userActivityService.js';
import { GlobalAnalytics } from '../../../analytics';

const analytics = new GlobalAnalytics();
export class TCUVCertificationProgramComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      languageOverride: this.props.languageOverride,
    };
  }

  componentDidMount() {
    postUserActivityRecord();
    this.setState({ languageOverride: this.props.languageOverride });
    analytics.firePageLoad({
      tag: '35.1',
      section: 'Product',
      subsection: 'Featured Technology',
      technologyName: 'TCUV',
      page: 'The Certified Program',
    });
  }

  render() {
    return TCUVCertificationProgramTemplate(this);
  }
}

export default injectIntl(TCUVCertificationProgramComponent);
