import { GlobalAnalytics } from "../../../analytics";

const analytics = new GlobalAnalytics();

// Page Load
export const announcementPagePageLoad = (pageLoad) => {
  if(pageLoad) {
    analytics.firePageLoad({
      app: pageLoad.appId,
      tag: pageLoad.tagId,
      section: pageLoad.section,
      page: pageLoad.page,
      subsection: pageLoad.subsection,
      technologyName: pageLoad.technology_name,
    });
  }
};

// Mark all as read action
export const markAllAsReadClick = (clickAnalytics) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: "Profile",
      category: "Notifications",
      label: "Mark All as Read Click"
    });
  }
};


// Sort dropdown action
export const sortDropdownClick = (clickAnalytics, label) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: "Profile",
      category: "Notifications",
      label: "Sort Dropdown Click",
      contentTitle: label
    });
  }
};

// Category dropdown action
export const categoryDropdownClick = (clickAnalytics, label) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: "Profile",
      category: "Notifications",
      label: "Category Dropdown Click",
      contentTitle: label
    });
  }
};


// Notificaiton card click action
export const notificationCardClick = (clickAnalytics, label) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: "Profile",
      category: "Notifications",
      label: "Notification Card Click",
      contentTitle: label
    });
  }
};


// Learn more notification card click action
export const learnMoreCardClick = (clickAnalytics, label) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: "Profile",
      category: "Notifications",
      label: "Notification Card Learn More Button Click",
      contentTitle: label
    });
  }
};