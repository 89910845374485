import React from 'react';
import { FormattedMessage } from 'react-intl';
import '../../styles/css/vehicleResource.css';
import DisclaimersComponent from '../disclaimers/disclaimers.component';
import AppSettings from '../../appsettings.js';
import { getLocale } from '../../services/languageTranslationService.js';
import ArticleListComponent from '../articleList/articleList.component';
import eBrochureIcon from '../../resources/images/eBrochure_Gray.png';
import dcIcon from '../../resources/images/Delivery_Checklist_Gray.png';
import sourceBookIcon from '../../resources/images/SourceBook_Gray.png';


if (!String.prototype.startsWith) {
  // eslint-disable-next-line no-extend-native
  String.prototype.startsWith = (searchString, position) => this.substr(position || 0, searchString.length) === searchString;
}

const cleanEbrochureName = (eBrochure) => {
  if (!eBrochure) {
    return null;
  }
  return eBrochure.replace(/\*/g, '');
};

const getSourceBook = (year, model, sourcebook) => {
  let url;
  if (year < 2016) {
    url = `/static/sourcebooks/${year}_${model.toLowerCase().replace(' ', '_')}_en_SourceBook.pdf`;
  } else {
    url = `/static/sourcebooks/${year}_${model.toLowerCase().replace(' ', '_')}_${getLocale()}_SourceBook.pdf`;
  }
  return (parseInt(year, 10) > 2017 || sourcebook !== '') ? url : null;
};

const eBrochureButton = component => <div className={component.state.eBrochure ? 'button' : 'button button-disabled'}>
  <FormattedMessage id="resources.eBrochure" defaultMessage="eBrochure" />
</div>;

const deliveryChecklistButton = component => <div className={component.state.deliveryChecklistPath ? 'button delivery-checklist' : 'button button-disabled delivery-checklist'}>
  <FormattedMessage id="resources.deliveryChecklist" defaultMessage="Delivery Checklist" />
</div>;
export function VehicleResourceTemplate(component) {
  function LinksBox() {
    const sourceBook = getSourceBook(component.state.year, component.state.model, component.state.sourceBook);
    return (
      <div className="button-group">
        <div>
          {component.state.eBrochure
            ? <a target="_blank" href={component.state.eBrochure ? AppSettings.AWSFileRepo.brochures + cleanEbrochureName(component.state.eBrochure) : null} onClick={component.eBrochureClick.bind()}>
              {eBrochureButton(component)}
            </a>
            : <React.Fragment>{eBrochureButton(component)}
              <div className="coming-soon"><FormattedMessage id="resources.comingSoon" defaultMessage="coming Soon" /></div>
            </React.Fragment>
          }
        </div>
        <div>
          {component.state.deliveryChecklistPath
            ? <a target="_blank" href={(component.state.deliveryChecklistPath && component.state.year >= 2017) ? component.state.deliveryChecklistPath : null} onClick={component.deliveryClick.bind()}>
              {deliveryChecklistButton(component)}
            </a>
            : <React.Fragment>
              {deliveryChecklistButton(component)}
              {(!component.state.deliveryChecklistPath && component.state.year > 2019)
                && <div className="coming-soon"><FormattedMessage id="resources.comingSoon" defaultMessage="Coming Soon" /></div>}
              {(!component.state.deliveryChecklistPath && component.state.year < 2019)
                && <div className="coming-soon"><FormattedMessage id="resources.notavailable" defaultMessage="Not Available" /></div>}
            </React.Fragment>
          }
        </div>
        <a target="_blank" href={encodeURI(sourceBook)} onClick={component.sourceBookClick.bind()}>
          <div className={sourceBook ? 'button' : 'button button-disabled'}>
            <FormattedMessage id="resources.sourceBook" defaultMessage="Source Book" />
          </div>
        </a>
      </div >
    );
  }

  const shouldDisplayOldCategories = year => year && parseInt(year, 10) < 2020;

  return (
    <div className="resources">
      <div className="header-section">
        <div className="resources-title"><FormattedMessage id="resources.pageTitle" /></div>
        <LinksBox />
      </div>
        <ArticleListComponent
          recordType={`vehicle-${component.state.model}-${component.state.year}-${getLocale()}`}
          shouldDisplayOldCategories={shouldDisplayOldCategories(component.props.route.year)}
          resourceClick={component.resourceClick}
          filterMenuClick={component.filterMenuClick}
          sortClick={component.sortAnalytics}
        />
        <DisclaimersComponent template={`${component.props.route.year}_${component.props.route.model}`} ref={(instance) => { component.disclaimer = instance; }} />
    </div>
  );
}
