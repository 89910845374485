import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import Display404NotFound from "../../components/atoms/404NotFound";
import { NitroContext } from "../../components/contexts/nitroContext";
import ScrollToTop from "../../components/molecules/scrollToTop";
import getAnalytics from "../../services/analyticsService";
import getMisc from "../../services/miscService";
import {
  getQuizOverview,
  getQuizQuestions,
  getQuizDetails,
  getUpdatedMission,
  getXPLeaderboardConfig,
  getXPLeaderboardData,
  getXPLeadersDealerships,
  getXPMissionLabels,
  getXPMissions,
  postTournamentTransaction,
} from "../../services/nitroService";
import SubheaderV2 from "../subheaderV2/subheaderV2";
import { isDesktopView, isMobileView, isTabletView } from "../utils/getScreenSize";
import EngageXPFAQs from "./engageXPFAQs";
import EngageHowDo from "./engageXPHowDo";
import EngageXPMissionDetail from "./engageXPMissionDetail";
import EngageXPMissions from "./engageXPMissions";
import EngageXPOverview from "./engageXPOverview";
import EngageXpStats from "./engageXPStats";
import TriviaModal from './triviaModal/triviaModal';
import EngageXPTournaments from "./engageXPTournaments";
import { extractTournamentMissionIds, getTournamentPointsForMission, checkIfTournamentIsActive } from "./utils";

const engageXPItems = [
  { id: "Missions", urlPath: "missions", subcategory: "Missions" },
  { id: "What is XP?", urlPath: "overview", subcategory: "What is XP" },
  { id: "FAQs", urlPath: "faqs", subcategory: "FAQs" },
];

const EngageXPRouterComponent = () => {
  const history = useHistory();
  const { loadNitro, setNitroLoadingState } = useContext(NitroContext);
  const { page, missionId, quizId } = useParams();
  const [analyticsData, setAnalyticsData] = useState(null);
  const [currentTab, setCurrentTab] = useState(engageXPItems[0]);
  const [device, setDevice] = useState("desktop");
  const [showQuiz, setShowQuiz] = useState(false);
  const [triviaData, setTriviaData] = useState({});
  const [shouldFetchMissionDetails, setShouldFetchMissionDetails] = useState(false);
  const [pageData, setPageData] = useState(null);
  const [missions, setMissions] = useState([]);
  const [leaderboardConfig, setLeaderboardConfig] = useState([]);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [isLoadingMissions, setIsLoadingMissions] = useState(true);
  const [isLoadingLeaderboard, setIsLoadingLeaderboard] = useState(true);
  const [isLoadingLeaderboardDealerships, setIsLoadingLeaderboardDealerships] = useState(true);
  const [leaderboardSelection, setLeaderboardSelection] = useState();
  const [waitForMissionsLabels, setWaitForMissionsLabels] = useState(true);
  const [isUserTournamentEligible, setIsUserTournamentEligible] = useState(false);
  const [userEligibilityUpdated, setUserEligibilityUpdated] = useState(false);
  const prevMissionIdRef = useRef();
  const tournamentsData = JSON.parse(sessionStorage.getItem("tournamentsData"));

  const isTournamentActive = checkIfTournamentIsActive(tournamentsData);
  const hasXPTournament = engageXPItems.some(item => item.id === "XP Tournament");
  if (isTournamentActive && !hasXPTournament) {
    engageXPItems.unshift({ id: "XP Tournament", urlPath: "tournaments", subcategory: "XP Tournament" });
  }

  const closeModal = (shouldFetchData = false) => {
    const newPath = history.location.pathname.replace(quizId, '');
    setShowQuiz(false);
    history.replace(newPath);
    if (shouldFetchData) {
      setShouldFetchMissionDetails(true);
      setNitroLoadingState(true);
    }
  }

  const fetchQuizOverviewAndQuestions = useCallback(
    async (quizId) => {
      setNitroLoadingState(true);
      Promise.all([getQuizOverview(quizId), getQuizQuestions(quizId), getQuizDetails(quizId)])
        .then(([responseOverview, responseQuestions, responseDetails]) => {
          const overview = responseOverview.response[0];
          const questions = responseQuestions.response.sort((a, b) => a.index - b.index);
          const details = {
            ...responseDetails.response,
            questions: responseDetails.response.questions.sort((a, b) => a.index - b.index)
          }
          setTriviaData({ overview, questions, details });
          setShowQuiz(true);
        })
        .catch((e) => {
          console.log("Error requesting Quiz data", e);
          setNitroLoadingState(false);
          const newPath = history.location.pathname.replace(quizId, '');
          history.replace(newPath);
        });
    },
    [quizId]
  );

  useEffect(() => {
    if (quizId) {
      fetchQuizOverviewAndQuestions(quizId);
    }
  }, [quizId, fetchQuizOverviewAndQuestions]);

  useEffect(() => {
    // If the user was on a mission details page and is now on the main missions page
    if (!!missions.length && !isNaN(Number(prevMissionIdRef.current)) && !missionId) {
      updateMissionPage(prevMissionIdRef.current);
    }

    prevMissionIdRef.current = missionId;
  }, [missionId]);

  useEffect(() => {
    fetchPageData();
    fetchMissions();
    fetchLeaderboardConfig();
  }, []);

  useEffect(() => {
    if (leaderboardConfig.length) {
      setLeaderboardSelection(leaderboardConfig[0]);
    }
  }, [leaderboardConfig]);

  useEffect(() => {
    if (leaderboardSelection) {
      setIsLoadingLeaderboard(true);
      fetchLeaderboardData(leaderboardSelection);
    }
  }, [leaderboardSelection]);

  useEffect(() => {
    if (!isLoadingLeaderboard && leaderboardData.length && leaderboardData[0].user) {
      fetchLeadersDealerships(leaderboardData);
    }
  }, [isLoadingLeaderboard]);

  const updateMissionPage = async (prevMissionId) => {
      const updatedMissions = JSON.parse(JSON.stringify(missions));

      fetchLeaderboardConfig();
      const forceNitroReload = true;
      loadNitro(forceNitroReload);

      const updatedPrevMission = await updateMission(prevMissionId);
      const updatedPrevMissionIndex = updatedMissions.findIndex((mission) => mission.id == updatedPrevMission.id);
      if (updatedPrevMissionIndex !== -1) {
        updatedMissions[updatedPrevMissionIndex] = updatedPrevMission;
      }

      if (updatedPrevMission && updatedPrevMission.completionCount > 0) {
        const dependentMissions = missions.filter(mission => {
          return mission.locked.status &&
            mission.locked.reasons &&
            mission.locked.reasons.some(reason => reason.challenges && reason.challenges.length > 0);
        });

        for (const dependentMission of dependentMissions) {
          const updatedDependentMission = await updateMission(dependentMission.id);

          const updatedDependentMissionIndex = updatedMissions.findIndex((mission) => mission.id == updatedDependentMission.id);
          if (updatedDependentMissionIndex !== -1) {
            updatedMissions[updatedDependentMissionIndex] = updatedDependentMission;
          }
        }
      }
      setMissions(updatedMissions);
  };

  const fetchPageData = async () => {
    try {
      const data = await getMisc("engageXP");
      setPageData(data?.items);
    } catch (error) {
      console.error(`Failed to get EngageXP page data: ${error}`);
    }
  };

  async function updateMission(missionId) {
    try {
      const updatedMission = await getUpdatedMission(missionId);
      const missionIndex = missions.findIndex((mission) => mission.id == missionId);

      if (missionIndex !== -1) {
        updatedMission.labels = missions[missionIndex].labels;
        return updatedMission;
      } else {
        throw new Error(`Mission not found in the array: ${missionId}`);
      }
    } catch (error) {
      console.error(`Error updating mission: ${error}`);
    }
  }

  async function fetchMissions() {
    try {
      const [toDoMissions, completedMissions] = await Promise.all([getXPMissions("eligible"), getXPMissions("completed")]);
      const allMissions = [...toDoMissions, ...completedMissions];

      const uniqueMissionIds = new Set();
      const filteredMissions = allMissions.filter((mission) => {
        if (!mission.hidden) {
          if (!uniqueMissionIds.has(mission.id)) {
            uniqueMissionIds.add(mission.id);
            return true;
          }
        }
        return false;
      });

      setMissions(filteredMissions);
      setIsLoadingMissions(false);
  
      await fetchAndAddLabelsToMissions(filteredMissions);
    } catch (error) {
      console.error(error);
      setIsLoadingMissions(false);
    }
  }

  function getCachedMissionLabels() {
    const cachedLabels = localStorage.getItem("missionLabels");
    return cachedLabels ? JSON.parse(cachedLabels) : {};
  }

  function saveMissionLabelsToCache(labels) {
    localStorage.setItem("missionLabels", JSON.stringify(labels));
  }

  async function fetchAndAddLabelsToMissions(missions) {
    try {
      const cachedLabels = getCachedMissionLabels();
      const missionsWithLabels = missions.map((mission) => {
        const cachedLabel = cachedLabels[mission.id];
        if (cachedLabel) {
          return { ...mission, ...cachedLabel };
        } else {
          return mission;
        }
      });

      const missionsToUpdate = missions.filter(
        (mission) => !cachedLabels[mission.id]
      );

      if (missionsToUpdate.length > 0) {
        const missionIdsToUpdate = missionsToUpdate.map((mission) => ({
          id: mission.id,
        }));
        const missionLabels = await getXPMissionLabels(missionIdsToUpdate);
        missionLabels.forEach((labelInfo) => {
          cachedLabels[labelInfo.id] = labelInfo;
        });
        saveMissionLabelsToCache(cachedLabels);
        missionsWithLabels.forEach((mission, index) => {
          const cachedLabel = cachedLabels[mission.id];
          if (cachedLabel) {
            missionsWithLabels[index] = { ...mission, ...cachedLabel };
          }
        });
      }

      setMissions(missionsWithLabels);
      setWaitForMissionsLabels(false);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchLeaderboardConfig() {
    const leaderbaordConfigResult = await getXPLeaderboardConfig();
    setLeaderboardConfig(leaderbaordConfigResult);
  }

  async function fetchLeaderboardData(leaderboard) {
    const { leaderboardId, groupClassIds } = leaderboard;
    const leaderboardDataResult = await getXPLeaderboardData(leaderboardId, groupClassIds);
    setLeaderboardData(leaderboardDataResult);
    setIsLoadingLeaderboard(false);
  }

  async function fetchLeadersDealerships(leaderboardData) {
    setIsLoadingLeaderboardDealerships(true);
    const leaderboardDataWithDealerships = await getXPLeadersDealerships(leaderboardData);
    setLeaderboardData(leaderboardDataWithDealerships);
    setIsLoadingLeaderboardDealerships(false);
  }

  const getBlurredBadgeText = (labels) => {
    const blurredValue = pageData?.missions?.imageChipValues?.find(value =>
      labels?.some(label => label.name.toLowerCase() === value.toLowerCase())
    );
    return blurredValue || null;
  };

  const getTournamentBlurredBadgeText = (labels, completionCount) => {
    return completionCount >=1 ? 'Completed' : null;
  };
   
  const getColoredBadgeInfo = (labels) => {
    const matchingLabel = labels?.find(label => pageData?.missions?.colorChipOptions?.some(option => option.title.toLowerCase() === label.name.toLowerCase()));

    if (matchingLabel) {
      const option = pageData?.missions?.colorChipOptions?.find(option => option.title.toLowerCase() === matchingLabel.name.toLowerCase());
      return option ? { color: option.color, name: option.title } : null;
    }
  };

  useEffect(() => {
    updateDevice();
    window.addEventListener("resize", updateDevice);
    return () => window.removeEventListener("resize", updateDevice);
  }, []);

  useEffect(() => {
    setCurrentTab(engageXPItems?.find(t => t.urlPath === page));
  }, [page]);

  useEffect(() => {
    getAnalytics("engageXP")
      .then((data) => {
        setAnalyticsData(data);
      })
      .catch((error) => {
        console.log(`ERROR: failed to load XP Intro analytics data. ${error}`);
      });
  }, []);

  const handleTournamentPoints = (isCorrect, quizId, questionId, totalQuestions) => {
    if (!isCorrect) return;

    if (!tournamentsData) return;

    if (!isUserTournamentEligible) return;

    const tournamentMissionIds = extractTournamentMissionIds(tournamentsData);
    const missionIdNumber = parseInt(missionId, 10);
    const isTournamentMission = tournamentMissionIds.has(missionIdNumber);
    if (!isTournamentMission) return;

    const tournamentPointsPerQuestion = getTournamentPointsForMission(tournamentsData, missionIdNumber) / totalQuestions;
    const transactionDetails = {
      tournamentId: tournamentsData.id,
      missionId: missionIdNumber,
      quizId,
      questionId,
      totalQuestions,
    };
    
    postTournamentTransaction(tournamentPointsPerQuestion, transactionDetails);
  };

  const pageComponents = {
    tournaments: 
          <EngageXPTournaments 
            tournamentsData={tournamentsData}
            isUserEligible={isUserTournamentEligible}
            setIsUserEligible={setIsUserTournamentEligible}
            userEligibilityUpdated={userEligibilityUpdated}
            setUserEligibilityUpdated={setUserEligibilityUpdated}
            getColoredBadgeInfo={getColoredBadgeInfo}
            getBlurredBadgeText={getTournamentBlurredBadgeText}
            pageData={pageData}
            shouldFetchMissionDetails={shouldFetchMissionDetails}
            setShouldFetchMissionDetails={setShouldFetchMissionDetails}
            missionId={missionId}
            missions={missions}
            analyticsData={analyticsData}
            visible={showQuiz}
            closeModal={closeModal}
            triviaData={triviaData}
            reloadData={fetchQuizOverviewAndQuestions}
            quizId={quizId}
          />,
    missions: !missionId && !quizId ? (
        <div>
          <EngageXPMissions
            tournamentsData={tournamentsData}
            pageData={pageData}
            missions={missions}
            leaderboardConfig={leaderboardConfig}
            leaderboardData={leaderboardData}
            leaderboardSelection={leaderboardSelection}
            setLeaderboardSelection={setLeaderboardSelection}
            isLoadingMissions={isLoadingMissions}
            isLoadingLeaderboard={isLoadingLeaderboard}
            isLoadingLeaderboardDealerships={isLoadingLeaderboardDealerships}
            getBlurredBadgeText={getBlurredBadgeText}
            getColoredBadgeInfo={getColoredBadgeInfo}
            analyticsData={analyticsData}
            waitForMissionsLabels={waitForMissionsLabels}
          />
        </div>
      ) : missionId === 'stats' ? (
        <EngageXpStats
          missions={missions}
          leaderboardConfig={leaderboardConfig}
          leaderboardData={leaderboardData}
          isLoadingLeaderboard={isLoadingLeaderboard}
          pageData={pageData}
          isLoadingLeaderboardDealerships={isLoadingLeaderboardDealerships}
          leaderboardSelection={leaderboardSelection}
          setLeaderboardSelection={setLeaderboardSelection}
          analyticsData={analyticsData}
        />
      ) : missionId === 'how-do-points-work' ? (
        <EngageHowDo pageData={pageData?.howTo}
        analyticsData={analyticsData}/>
      ) : (
        <EngageXPMissionDetail
          pageData={pageData}
          shouldFetchMissionDetails={shouldFetchMissionDetails}
          setShouldFetchMissionDetails={setShouldFetchMissionDetails}
          missionId={missionId}
          missions={missions}
          getBlurredBadgeText={getBlurredBadgeText}
          getColoredBadgeInfo={getColoredBadgeInfo}
          analyticsData={analyticsData}
        />
      ),
    overview: <EngageXPOverview pageData={pageData} analyticsData={analyticsData}/>,
    faqs: <EngageXPFAQs data={pageData} analyticsData={analyticsData}/>,
  };

  const getSubheaderChildComponent = (path) => {
    const page = path?.toLowerCase() ?? "";
    return pageComponents[page] || <Display404NotFound display />;
  };

  const isValidTabName = () =>
    ["tournaments", "missions", "overview", "faqs"].filter((tab) => tab === page).length > 0;

  const updateDevice = () => {
    if (isDesktopView(window.innerWidth)) {
      setDevice("desktop");
    } else if (isTabletView(window.innerWidth)) {
      setDevice("tablet");
    } else if (isMobileView(window.innerWidth)) {
      setDevice("mobile");
    }
  };

  const getPageDisplay = () => {
    if (page === undefined) {
      history.replace("/engage-xp/missions");
    } else if (isValidTabName()) {
      return (
        <div>
          {pageData && (
            <>
              <SubheaderV2
                title="ENGAGE **XP**"
                baseUrl="/engage-xp"
                device={device}
                tabs={engageXPItems}
                currentTab={currentTab}
                mobileTabs={engageXPItems}
                mobileVerticalMenu
                // analyticsPageData={{
                //   ...any custom analytics properties to append to default analytics data
                // }}
              />
              {getSubheaderChildComponent(page)}
            </>
          )}
        </div>
      );
    } else {
      return <Display404NotFound display />;
    }
  };

  return (
    <div>
      {getPageDisplay()}
      <TriviaModal
        disableAutoScrolling
        visible={showQuiz}
        closeModal={closeModal}
        triviaData={triviaData}
        analyticsData={analyticsData}
        reloadData={fetchQuizOverviewAndQuestions}
        handleTournamentPoints={handleTournamentPoints}
      />
      <ScrollToTop />
    </div>
  );
};

export default EngageXPRouterComponent;
