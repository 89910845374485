import React from 'react';
import '../../styles/css/vehicleOverview.css';
import PropTypes from 'prop-types';

export function Award(props) {
  const sanitizedImageUrl = encodeURI(props.imageFilename);

  return (
        <div className="award">
            <div className="awardImage">
                <img alt="" src={sanitizedImageUrl} />
            </div>
            <div className="awardSubHeadline">
                {props.children}
            </div>
        </div>
  );
}

Award.propTypes = {
  imageFilename: PropTypes.string.isRequired,
  children: PropTypes.object,
};
