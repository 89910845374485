import React, { useEffect, useRef, useState } from 'react';
import SectionHeaderComponentV2 from './components/sectionHeaderV2.component';
import QuickSpecs from './components/quickSpecsV2';
import CostToOwnV2 from './components/costToOwnV2';
import KeyFeatures from './components/keyFeaturesV2';
import StuckButton from './components/stuckButton';
import VideoPlayerComponent from '../../../videoPlayer/videoPlayer.component';
import AppSettings from '../../../../appsettings.js';
import CAVSImage from '../../../../resources/images/caHeadToHeadVS.png';
import leftArrowIcon from '../../../../resources/images/white_arrow_left.png';
import DisclaimersComponent from '../../../disclaimers/disclaimers.component';
import { hasVideoId } from '../../../utils/helpers';
import * as _ from 'lodash';
import { getFormattedName } from '../../../utils/vehicleName';

export const HeadToHeadDetailedComparisonV2 = (props) => {
  const selectedToyotaTrimObject = props.selectedCompetitorModel !== '' && props.fullData.find(fd => fd.gradeAndTrim === props.selectedToyotaTrim);
  const selectedCompetitor = selectedToyotaTrimObject && selectedToyotaTrimObject.competitors.find(comp => comp.competitorVehicle.trim === props.selectedCompetitorTrim && comp.competitorVehicle.model === props.selectedCompetitorModel && comp.competitorVehicle.year === props.selectedCompetitorYear);
  const [isOffScreen, setIsOffScreen] = useState(false);
  const disclaimerTemplate = `${encodeURIComponent(selectedCompetitor.toyotaVehicle.year)}_${encodeURIComponent(selectedCompetitor.toyotaVehicle.model.toLowerCase().replace(/\//g, '_'))}_${selectedCompetitor.competitorVehicle.year}%20${encodeURIComponent(selectedCompetitor.competitorVehicle.model.toLowerCase().replace(/\//g, '_'))}`;
  const [disclaimer, setDisclaimer] = useState(null);
  const disclaimerRef = useRef();

  useEffect(() => {
    setDisclaimer(disclaimerRef.current);
  }, [disclaimerRef]);

  const hasCostToOwnData = () => {
    return !!(selectedCompetitor.costToOwn.specs[0] && !_.isEmpty(selectedCompetitor.costToOwn.specs[0].data[0]));
  };

  return props.selectedCompetitorModel !== '' && (
    <div className="ca-edge">
      <button className={`floating-back-button ${isOffScreen ? 'button-visible' : 'button-hidden'}`} onClick={() => { props.backButtonOnClick(); }}>
        <span className='back-button-wrapper'>
          <img src={leftArrowIcon} />
          <span className='hover back-text'>  Browse Comparisons</span><span className='mobile back-text'>  Back</span>
        </span>
      </button>
      <div className="head-to-head-header-new">
        { hasVideoId(selectedCompetitor.headToHeadHeader.brightcoveId, selectedCompetitor.headToHeadHeader.qumuId)
          ? <div className="video-box columns small-12 large-12">
          <VideoPlayerComponent
            item={
              {
                alias: selectedCompetitor.headToHeadHeader.brightcoveId,
                qumuId: selectedCompetitor.headToHeadHeader.qumuId,
                videoTitle: 'HEAD-TO-HEAD Video',
                pageTitle: 'Head-to-Head',
              }}
          />
        </div> : <div className="video-box video-box-hidden columns large-12" />}
        <StuckButton onClick={() => { props.backButtonOnClick(); }} setIsOffScreen={setIsOffScreen} />
        <div className="toyota-vehicle-container columns large-5">
          <img src={AppSettings.AWSImgRepo.edge + props.s3Path + selectedCompetitor.headToHeadHeader.toyotaImage} className="toyota-image-header" />
          <div className="text-holder">
            <span className="vehicle-year-model-trim">{selectedCompetitor.toyotaVehicle.year}<b>{` ${getFormattedName(selectedCompetitor.toyotaVehicle.model)} ${selectedCompetitor.toyotaVehicle.trim}`}</b></span>
          </div>
        </div>
        <img src={CAVSImage} className="vs-image-component columns large-2" />
        <div className="competitor-vehicle-container columns large-5">
          <img src={AppSettings.AWSImgRepo.edge + props.s3Path + selectedCompetitor.headToHeadHeader.competitorImage} className="competitor-image-header" />
          <div className="text-holder">
            <span className="vehicle-year-model-trim">{selectedCompetitor.competitorVehicle.year}<b>{` ${selectedCompetitor.competitorVehicle.model} ${selectedCompetitor.competitorVehicle.trim}`}</b></span>
          </div>
        </div>
      </div>
      {selectedCompetitor.keyFeatures.features[0]
        && <div className="key-features">
          <SectionHeaderComponentV2 boldName={'caHeadToHead.keyFeaturesBoldHeader'} nonBoldName={'caHeadToHead.keyFeaturesHeader'} toyotaVehicle={selectedCompetitor.toyotaVehicle} competitorVehicle={selectedCompetitor.competitorVehicle} />
          <KeyFeatures content={selectedCompetitor} s3Path={props.s3Path} disclaimer={disclaimer}/>
        </div>
      }
      {selectedCompetitor.quickSpecs.specs[0]
        && <div className="quick-specs-v2">
          <SectionHeaderComponentV2 boldName={'caHeadToHead.quickSpecsBoldHeader'} nonBoldName={'caHeadToHead.quickSpecsHeader'} toyotaVehicle={selectedCompetitor.toyotaVehicle} competitorVehicle={selectedCompetitor.competitorVehicle}/>
          <QuickSpecs content={selectedCompetitor} disclaimer={disclaimer}/>
        </div>
      }
      {hasCostToOwnData()
        && <div className="cto">
        <SectionHeaderComponentV2 boldName={'caHeadToHead.costToOwnBoldHeader'} nonBoldName={'caHeadToHead.costToOwnHeader'} year={selectedCompetitor.toyotaVehicle.year - 1} toyotaVehicle={selectedCompetitor.toyotaVehicle} competitorVehicle={selectedCompetitor.competitorVehicle} />
        <CostToOwnV2 content={selectedCompetitor} disclaimer={disclaimer}/>
        </div>
      }
      <DisclaimersComponent template={disclaimerTemplate} ref={(instance) => { disclaimerRef.current = instance; }} />
    </div>
  );
};
