import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';

// App Settings, Analytics & Utils
import { isTabletView, isMobileView, isDesktopView } from '../../utils/getScreenSize';
import AppSettings from '../../../appsettings';
import * as analytics from '../audioMultimediaOverview.analytics';
import getAnalytics from '../../../services/analyticsService';

// Engage Components
import DynamicLanding from '../../../components/pages/dynamicLanding';
import CarDisplay from '../audioMultimediaComponents/audioMultimediaCarDisplay';
import VehicleApplicabilityDropdown from '../../../components/molecules/VehicleApplicabilityDropdown.js';
import DropdownSelectorCard from '../../../components/molecules/dropdownSelectorCard';
import CardGroupSection from '../../../components/molecules/marketingPage/cardGroupSection';
import ScrollingModal from '../../../components/molecules/scrollingModal';
import FPOContent from '../audioMultimediaComponents/audioMultimediaFPOContent';
import AudioMultimediaSystemInfo from '../audioMultimediaComponents/audioMultimediaSystemInfo';
import CompareFeaturesContent from '../audioMultimediaComponents/audioMultimediaCompareFeaturesContent';
import DisclaimersComponent from '../../disclaimers/disclaimers.component';

// Style
import '../../../styles/css/ammOverview.css';

const AudioMultimediaOverview = (props) => {
  const ammImagePath = AppSettings.AWSImgRepo.amm;

  const searchParams = useLocation().search;
  const urlParams = queryString.parse(searchParams);
  const [modalTarget, setModalTarget] = useState(decodeURIComponent(urlParams.target));
  const refs = {
    systemInfo: useRef(null),
    featureDetails: useRef(null),
    vehicleApplicability: useRef(null),
  }

  const [modalContent, setModalContent] = useState(null);
  const [modalVisible, setModalVisible] = useState(null);
  const [modalClass, setModalClass] = useState(null);
  const [overviewAnalytics, setOverviewAnalytics] = useState(null);
  const [modalCloseAnalytics, setModalCloseAnalytics] = useState(() => () => null);
  const [disclaimer, setDisclaimer] = useState(null);

  useEffect(() => {
    getOverviewAnalytics();
  }, []);

  useEffect(() => {
    overviewAnalytics && analytics.overviewPageLoad(overviewAnalytics.pageLoadAnalytics)
  }, [overviewAnalytics]);

  useEffect(() => {
    // Decode the target from the url, and pull out the system info & feature detail data
    setModalTarget(decodeURIComponent(urlParams.target));
    const systemInfo = props.pageData?.items?.systemInformation;
    const featureDetails = props.pageData?.items?.featureDetails;
    if (!modalTarget || !(systemInfo || featureDetails)) return;

    if(modalTarget === "Vehicle Applicability"){
      setTimeout(() => refs.vehicleApplicability.current?.scrollIntoView({ behavior: "smooth" }), 400)
      return;
    }

    // Check if the title matches one of the "System Information" modals
    // If so, scroll that section into view & open the matching modal
    if (systemInfo.some(modal => modal.title === modalTarget)) {
      setTimeout(() => refs.systemInfo.current?.scrollIntoView({ behavior: "smooth" }), 400)
      setTimeout(() => sysInfoModalClick(modalTarget), 1100);
      return;
    }else if(modalTarget === "System Information"){
      setTimeout(() => refs.systemInfo.current?.scrollIntoView({ behavior: "smooth" }), 400)
      return;
    }

    // Otherwise, check if the title matches one of the "Feature Details" modals
    // If so, scroll that section into view & open the matching modal
    const index = featureDetails.findIndex(modal => applySymbols(modal.text) === modalTarget)
    if (index >= 0) {
      setTimeout(() => refs.featureDetails.current?.scrollIntoView({ behavior: "smooth" }), 400)
      setTimeout(() => featureCardModalClick(featureDetails[index]), 1300);
    }else if(modalTarget ===  "Feature Details"){
      setTimeout(() => refs.featureDetails.current?.scrollIntoView({ behavior: "smooth" }), 400)
    }
  }, [urlParams.target, props.pageData])

  const externalResources = externalResources => (
    <div className="external-resources-container">
      <div className="external-resources">
        <div className="external-resources-title">External Links</div>
        {externalResources.map(resource => (
          <div className="external-resources-link" key={resource.title}>
            <a
              onClick={() => overviewAnalytics && analytics.externalLinkClick(overviewAnalytics.clickAnalytics, resource.title)}
              href={resource.url}
              target={isDesktopView(window.innerWidth) && resource.target}
            >
              {resource.title}
            </a>
          </div>
        ))}
      </div>
    </div>
  );

  const getDeviceType = () => isMobileView(window.innerWidth) ? 'mobile' : isTabletView(window.innerWidth) ? 'tablet' : 'desktop';

  const getOverviewAnalytics = () => new Promise((resolve, reject) => {
    getAnalytics('audioMultimediaLanding')
      .then((analyticsData) => {
        resolve(analyticsData);
        setOverviewAnalytics({ ...analyticsData });
      }).catch((error) => {
        reject(error);
      });
  });

  const featureCardModalClick = (data) => {
    overviewAnalytics && analytics.featureDetailsTileClick(overviewAnalytics.clickAnalytics, data.text)
    setModalVisible(true);
    setModalClass('minicardFeatureModal');
    setModalContent(
      <FPOContent
        modalContent={data}
        imagePath={ammImagePath}
        disclaimer={disclaimer}
        setDisclaimer={setDisclaimer}
      />
    );
    overviewAnalytics && analytics.featureDetailsOverlayPageLoad(overviewAnalytics.pageLoadAnalytics, data.text);
    overviewAnalytics && setModalCloseAnalytics(() => () => analytics.featureDetailsOverlayCloseClick(overviewAnalytics.clickAnalytics, data.text));
  }

  const sysInfoModalClick = (cardTitle) => {
    overviewAnalytics && analytics.systemInfoTileClick(overviewAnalytics.clickAnalytics, cardTitle);
    const sysData = props.pageData.items.systemInformation;
    const index = sysData.findIndex((element) => element.title === cardTitle);

    if (sysData) {
      setModalVisible(true);
      setModalClass('system-info-modal');
      setModalContent(
        <AudioMultimediaSystemInfo
          data={sysData[index]}
          closeModal={() => setModalVisible(false)}
          setModalCloseFunction={(activeTab) => setModalCloseAnalytics(() => () => overviewAnalytics && analytics.systemInfoOverlayCloseClick(overviewAnalytics.clickAnalytics, cardTitle, activeTab))}
          analyticsData={overviewAnalytics}
          disclaimer={disclaimer}
          setDisclaimer={setDisclaimer}
          setResourcesFilter={props.setResourcesFilter}
        />
      );
      overviewAnalytics && analytics.systemInfoOverlayPageLoad(overviewAnalytics.pageLoadAnalytics, cardTitle);
    }
  }

  const compareFeaturesModalClick = (year, model, trim) => {
    setModalVisible(true);
    setModalClass('compare-features-modal');
    overviewAnalytics && analytics.compareFeaturesOverlayPageLoad(overviewAnalytics.pageLoadAnalytics, year, model, trim)
    overviewAnalytics && setModalCloseAnalytics(() => () => analytics.compareFeaturesOverlayCloseClick(overviewAnalytics.clickAnalytics, year, model, trim));
  }

  const closeModal = () => {
    setModalVisible(false);
    modalCloseAnalytics();
  }

  function applySymbols(text) {
    if (!text) return null;

    text = text.replace(/\^(\d+)\^/g, '');
    text = text.replace(/\^\\(\*\d+)\^/g, '');
    text = text.replace(/\^\(?SM\)?\^/g, '℠');
    text = text.replace(/\^\(?TM\)?\^/g, '™');
    text = text.replace(/\^\(?R\)?\^/g, '®');

    return text;
  }

  return (
    <div className="ammOverview">
      <div>
        {props.pageData && <DynamicLanding
          hideInnerBackButton={true}
          clickAnalytics={overviewAnalytics && overviewAnalytics.clickAnalytics}
          iconLinks={props.pageData.items.iconLinks}
          pageStyle='alternative'
          imagePath={ammImagePath}
          pageLoadAnalytics={overviewAnalytics && overviewAnalytics.pageLoadAnalytics}
          pageTitle={props.pageData.items.header.title}
          subPageHeader={{
            device: getDeviceType(),
            columns: props.pageData.items.header.columns
          }}
          tileClickFunction={undefined}
        />}
      </div>
      <div className="ammContent">
        <div className='ammApplicabilityCard' ref={refs.vehicleApplicability}>
          {props.lineupData && <DropdownSelectorCard
            title={props.entuneData.title}
            description={props.entuneData.description}
            contentDisplay={
              <CarDisplay
                compareFeaturesAnalytics={(year, model, grade) => overviewAnalytics && analytics.vehicleApplicabilityCompareFeaturesClick(overviewAnalytics.clickAnalytics, year, model, grade)}
                closeCompareFeaturesAnalytics={(year, model, grade) => overviewAnalytics && analytics.vehicleApplicabilityCloseClick(overviewAnalytics.clickAnalytics, year, model, grade)}
              />}
            modalContentComponent={<CompareFeaturesContent disclaimer={disclaimer} setDisclaimer={setDisclaimer} />}
            setModalContent={setModalContent}
            clickFunctions={compareFeaturesModalClick}
            dropdown={
              <VehicleApplicabilityDropdown
                yearModelTrimData={props.lineupData}
                entuneData={props.entuneData}
                yearSelectAnalytics={(year) => overviewAnalytics && analytics.vehicleApplicabilityModelYearSelectClick(overviewAnalytics.clickAnalytics, year)}
                modelSelectAnalytics={(model) => overviewAnalytics && analytics.vehicleApplicabilityModelNameSelectClick(overviewAnalytics.clickAnalytics, model)}
                trimSelectAnalytics={(grade) => overviewAnalytics && analytics.vehicleApplicabilityModelGradeSelectClick(overviewAnalytics.clickAnalytics, grade)}
                goClickAnalytics={(year, model, grade) => overviewAnalytics && analytics.vehicleApplicabilityGoClick(overviewAnalytics.clickAnalytics, year, model, grade)}
              />}
            pdfs={props.entuneData.pdfs}
            pdfMessage="Looking for a complete applicability chart for the whole lineup? Just select a model year and download the PDF:"
            lineupSelectAnalytics={(year) => overviewAnalytics && analytics.vehicleApplicabilityLineupYearSelectClick(overviewAnalytics.clickAnalytics, year)}
            chartDownloadAnalytics={(year) => overviewAnalytics && analytics.vehicleApplicabilityChartDownloadClick(overviewAnalytics.clickAnalytics, year)}
          />}
        </div>
        <div className='ammSystemInfoCards' ref={refs.systemInfo} >
          <CardGroupSection
            titleType="middleRegular"
            clickFunctions={sysInfoModalClick}
            imagePath={ammImagePath}
            cardGroups={[
              {
                title: "System Information",
                cards: props.pageData.items.systemInformation,
                cardGroupType: "SmallPhotoCard",
              }
            ]}
          />
        </div>
        <div className="ammFeatureCards" ref={refs.featureDetails} >
          <CardGroupSection
            titleType="middleRegular"
            clickFunctions={featureCardModalClick}
            imagePath={ammImagePath}
            loadMore={true}
            loadMoreAnalytics={() => overviewAnalytics && analytics.featureDetailsViewMoreClick(overviewAnalytics.clickAnalytics)}
            cardGroups={[
              {
                title: "Feature Details",
                cards: props.pageData.items.featureDetails,
                cardGroupType: "MiniPhotoCard"
              }
            ]}
          />
        </div>
        <div className="ammExternalResources">
          {props.pageData.items.externalLinks && externalResources(props.pageData.items.externalLinks)}
        </div>
        <DisclaimersComponent template={"amm"} ref={(instance) => { setDisclaimer(instance) }} />
      </div>
      <ScrollingModal visible={modalVisible} closeModal={() => closeModal()} modalClassName={modalClass}>
        {modalContent}
      </ScrollingModal>
    </div>
  );

}

export default AudioMultimediaOverview;