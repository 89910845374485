import React from 'react';
import { ClipLoader } from 'react-spinners';

class LoadSpinner extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false
        }

        this.startSpinner = this.startSpinner.bind(this);
        this.stopSpinner = this.stopSpinner.bind(this);
    }

    startSpinner = () => {
        this.setState({ loading: true });
    }

    stopSpinner = () => {
        this.setState({ loading: false });
    }

    startSpinner = this.startSpinner.bind(this);
    stopSpinner = this.stopSpinner.bind(this);

    render() {
        return (
            <ClipLoader color={'#EB0A1E'} loading={this.state.loading} size={this.props.spinnerSize} />
        );
    }
}

export default LoadSpinner;