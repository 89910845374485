import React from 'react';
import ProfileListComponent from '../profileList/profileList.component';
import '../../styles/css/profileList.css';
import PinIcon from '../../resources/images/icon_location.png';
import AppSettings from '../../appsettings.js';
import LoadSpinner from '../loadspinner/loadSpinner.component';


export function DealershipListTemplate(component) {
  return (
        <div>
            <div className="profile-list">
                <div className="profile-list-header">
                    <div>{component.state.dealershipName}</div>
                    <div className="location">
                        <img className="icon" alt="" src={PinIcon} />
                        {AppSettings.Regions[component.state.regionCode]}
                    </div>
                </div>
                <ProfileListComponent profiles={component.state.profiles}></ProfileListComponent>
                <div className="spinner-container">
                    <LoadSpinner spinnerSize={50} ref={c => (component.loadSpinner = c)} />
                </div>
            </div>
        </div>
  );
}
