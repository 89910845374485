import { Component } from 'react';
import AppSettings from '../../appsettings.js';
import { BookmarkPageTemplate } from './bookmarkPage.tpl';
import { getBookmarks, addBookmark, deleteBookmark } from '../../services/bookmarksService.js';
import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();


export default class BookmarkPageComponent extends Component {
  constructor(props) {
    super(props);
    const spinId = localStorage.getItem('spin_id');
    this.state = {
      pageIsBookmarked: false,
      userId: spinId,
    };
    this.getUserBookmarks = this.getUserBookmarks.bind(this);
    this.toggleBookmark = this.toggleBookmark.bind(this);
    this.setBookmarksInStorage = this.setBookmarksInStorage.bind(this);
  }

  getUserBookmarks() {
    getBookmarks()
      .then((bookmarks) => {
        const pageIsBookmarked = !!bookmarks.find(x => x.title === this.props.title
          && x.url === this.props.url);
        this.setState({
          bookmarks,
          pageIsBookmarked,
        });
      })
      .catch(this.logError);
  }

  toggleBookmark() {
    if (this.props.bookmarkNavClickEvent) {
      this.props.bookmarkNavClickEvent(this.state.pageIsBookmarked);
    }
    const isBookmarked = !this.state.pageIsBookmarked;
    this.setState({
      pageIsBookmarked: isBookmarked,
    });
    const bookmark = {
      title: this.props.title,
      subcategory: this.props.subcategory,
      url: this.props.url,
    };
    if (isBookmarked) {
      addBookmark(bookmark)
        .then(response => this.updateBookmarks('add', response))
        .catch(this.logError);
    } else {
      deleteBookmark(bookmark)
        .then(response => this.updateBookmarks('delete', response))
        .catch(this.logError);
    }
  }

  setBookmarksInStorage(typeOfBookmarkEvent, bookmarksToSave) {
    this.setState({
      bookmarks: bookmarksToSave,
    });
    this.fireAnalyticsEvent(this.props, typeOfBookmarkEvent);
  }

  logError(error) {
    console.log(error);
  }

  fireAnalyticsEvent(bookmark, typeOfBookmarkEvent) {
    const urlArray = bookmark.url.split('/');
    const section = urlArray[1];
    const subSection = urlArray[2] ? urlArray[2] : ' ';
    const page = urlArray.length === 4 ? urlArray[3] : ' ';
    analytics.fireOnClick({
      tag: '35.2',
      mediaTitle: bookmark.title,
      section,
      subsection: subSection,
      technologyName: 'Bookmark',
      page,
      category: typeOfBookmarkEvent,
    });
  }

  componentDidMount() {
    this.getUserBookmarks();
  }

  componentDidUpdate(prevProps) {
    if (this.props.title !== prevProps.title || this.props.url !== prevProps.url) {
      this.getUserBookmarks();
    }
  }

  render() {
    return BookmarkPageTemplate(this);
  }
}
