import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { TCUVGeneralTemplate } from './tcuvGeneral.tpl';
import { translateText } from '../../utils/translateText';
import { GlobalAnalytics } from '../../../analytics';

const analytics = new GlobalAnalytics();
export class TCUVGeneralComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      languageOverride: this.props.languageOverride,
    };

    this.readMoreClick = this.readMoreClick.bind(this);
  }

  linkClick(event) {
    console.log('Analytics link click event');
  }

  readMoreClick(category, title) {
    analytics.fireOnClick({
      tag: '35.2',
      section: 'Product',
      subsection: 'Featured Technology',
      technologyName: 'TCUV',
      page: translateText(this.props.tab),
      category,
      contentTitle: title.replace(/(<([^>]+)>)/g, ''),
      mediaTitle: title.replace(/(<([^>]+)>)/g, ''),
    });
  }

  componentDidMount() {
    this.setState({ languageOverride: this.props.languageOverride });
    analytics.firePageLoad({
      tag: '35.1',
      section: 'Product',
      subsection: 'Featured Technology',
      technologyName: 'TCUV',
      page: translateText(this.props.tab),
    });
  }

  render() {
    return TCUVGeneralTemplate(this);
  }
}

export default injectIntl(TCUVGeneralComponent);
