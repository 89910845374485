import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();

export const certificationPageLoaded = (pageLoadAnalytics, currentPage) => {
  pageLoadAnalytics && analytics.firePageLoad({
    tag: pageLoadAnalytics.tagId,
    section: pageLoadAnalytics.section,
    subsection: pageLoadAnalytics.subSection,
    page: currentPage,
    technologyName: pageLoadAnalytics.technology_name
  });
};

const contentLinkClick = (tag, section, certificationRoute, technologyName,  page, category, action, positionTitle, contentTitle) => {
  // console.log(`${tag}`);
  // console.log(`${section}`);
  // console.log(`${certificationRoute}`);
  // console.log(`${technology_name}`);
  // console.log(`${page}`);
  // console.log(`${category}`);
  // console.log(`${action}`);
  // console.log(`${position_title}`);
  // console.log(`${content_title}`);


  analytics.fireOnClick({
    tag,
    section,
    subsection: certificationRoute,
    technologyName,
    page,
    category,
    action,
    positionTitle,
    contentTitle
  });
};
export const scrollButtonClick = (clickAnalytics, contentTitle) => {
  clickAnalytics && contentLinkClick(
    clickAnalytics.overviewPage.tagId,
    clickAnalytics.overviewPage.section,
    clickAnalytics.overviewPage.subsection,
    clickAnalytics.overviewPage.technology_name,
    clickAnalytics.overviewPage.page,
    clickAnalytics.overviewPage.scrollButtons.category,
    undefined,
    clickAnalytics.overviewPage.scrollButtons.position_title,
    contentTitle    
  )
}

export const whyCertTileClick = (clickAnalytics, contentTitle) => {
  clickAnalytics && contentLinkClick(
    clickAnalytics.overviewPage.tagId,
    clickAnalytics.overviewPage.section,
    clickAnalytics.overviewPage.subsection,
    clickAnalytics.overviewPage.technology_name,
    clickAnalytics.overviewPage.page,
    clickAnalytics.overviewPage.whyCertificationTile.category,
    undefined,
    clickAnalytics.overviewPage.whyCertificationTile.position_title,
    contentTitle
  )
}

export const cardTileClick = (clickAnalytics, contentTitle) => {
  clickAnalytics && contentLinkClick(
    clickAnalytics.overviewPage.tagId,
    clickAnalytics.overviewPage.section,
    clickAnalytics.overviewPage.subsection,
    clickAnalytics.overviewPage.technology_name,
    clickAnalytics.overviewPage.page,
    clickAnalytics.overviewPage.cardTile.category,
    undefined,
    clickAnalytics.overviewPage.cardTile.position_title,
    contentTitle
  )
}
export const testimonialLink = (clickAnalytics, category) =>{
  clickAnalytics && contentLinkClick(
    clickAnalytics.overviewPage.tagId,
    clickAnalytics.overviewPage.section,
    clickAnalytics.overviewPage.subsection,
    clickAnalytics.overviewPage.technology_name,
    clickAnalytics.overviewPage.page,
    category,
    undefined,
    clickAnalytics.overviewPage.moreTestimonials.position_title,
    undefined
  )
}
export const scrollToTopClick = (clickAnalytics) => {
  clickAnalytics && contentLinkClick(
    clickAnalytics.overviewPage.tagId,
    clickAnalytics.overviewPage.section,
    clickAnalytics.overviewPage.subsection,
    clickAnalytics.overviewPage.technology_name,
    clickAnalytics.overviewPage.page,
    clickAnalytics.overviewPage.backToTop.category,
    undefined, 
    undefined,
    undefined
  )
}
export const bookmarkClick = (clickAnalytics, certificationRoute, isBookmarked) => {
  const bookmarkAction = isBookmarked ? clickAnalytics.unbookmarkAction : clickAnalytics.bookmarkAction;
  clickAnalytics && contentLinkClick(
    clickAnalytics.overviewPage.tagId,
    clickAnalytics.overviewPage.section,
    clickAnalytics.overviewPage.subsection,
    clickAnalytics.overviewPage.technology_name,
    clickAnalytics.overviewPage.page,
    clickAnalytics.overviewPage.bookmark.category,
    bookmarkAction,
    clickAnalytics.overviewPage.bookmark.position_title,
    undefined, 
  );
};

export const overviewLinkClick = (clickAnalytics, certificationRoute, category, action) => {
  clickAnalytics && contentLinkClick(
    clickAnalytics.tagId,
    clickAnalytics.section,
    certificationRoute,
    undefined,
    clickAnalytics.page,
    category,
    action,
    undefined,
    undefined
  );
};

export const resourcesFilterClick = (clickAnalytics, certificationRoute, currentFilter, destinationFilter) => {
  clickAnalytics && contentLinkClick(
    clickAnalytics.tagId,
    clickAnalytics.section,
    certificationRoute,
    undefined,
    currentFilter,
    clickAnalytics.resourcesFilterCategory,
    destinationFilter,
    undefined,
    undefined
  );
};

export const requirementsRulesSheetsClick = (clickAnalytics, certificationRoute, rulesSheetsCategory, action) => {
  clickAnalytics && contentLinkClick(
    clickAnalytics.tagId,
    clickAnalytics.section,
    certificationRoute,
    undefined,
    clickAnalytics.page,
    rulesSheetsCategory,
    action,
    undefined,
    undefined
  );
};

export const cornerstoneLinkClick = (clickAnalytics, certificationRoute, currentFilter, category, action) => {
  clickAnalytics && contentLinkClick(
    clickAnalytics.tagId,
    clickAnalytics.section,
    certificationRoute,
    undefined,
    currentFilter,
    category,
    action,
    undefined,
    undefined
  );
};

export const subheaderClick = (clickAnalytics, currentPage, destinationPage) => {
  clickAnalytics && contentLinkClick(
    clickAnalytics.overviewPage.tagId,
    clickAnalytics.overviewPage.section,
    clickAnalytics.overviewPage.subsection,
    clickAnalytics.overviewPage.technology_name,
    currentPage,
    clickAnalytics.overviewPage.subHeader.category,
    undefined,
    clickAnalytics.overviewPage.subHeader.position_title,
    destinationPage,

  );
};
