import { useRef, useEffect } from 'react';
import { throttle } from 'lodash';

export function useUserActivity() {
  const lastActivityRef = useRef(Date.now());

  useEffect(() => {
    const activityHandler = throttle(() => {
      lastActivityRef.current = Date.now();
    }, 60000); // Throttle to update at most once per minute

    window.addEventListener('keydown', activityHandler, { passive: true });
    window.addEventListener('mousemove', activityHandler, { passive: true });
    window.addEventListener('mousedown', activityHandler, { passive: true });
    window.addEventListener('scroll', activityHandler, { passive: true });
    window.addEventListener('touchstart', activityHandler, { passive: true });
    window.addEventListener('touchmove', activityHandler, { passive: true });

    return () => {
      window.removeEventListener('keydown', activityHandler);
      window.removeEventListener('mousemove', activityHandler);
      window.removeEventListener('mousedown', activityHandler);
      window.removeEventListener('scroll', activityHandler);
      window.removeEventListener('touchstart', activityHandler);
      window.removeEventListener('touchmove', activityHandler);
    };
  }, []);

  return lastActivityRef;
}
