import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import '../../../styles/css/searchBar.css';
import '../../../styles/css/searchFeatures.css';
import { generateLearnMoreButton, checkTruncated, HeaderTooltip } from '../resultHelperFunctions.js';
import applyMarkdown from '../../../screens/utils/applyMarkdown';

export class FeatureSearchItemComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipVisibility: false,
    };
    this.ref = React.createRef();
  }

  generateFeatureDetails(title, items) {
    const itemsList = items.map((item, index) => (
      <div className="content-feature-list-item" key={index}>{item}</div>
    ));

    return (
      <div className="content-feature">
        <div className="content-feature-title">{title}</div>
        <div className="content-feature-list">{itemsList}</div>
      </div>
    );
  }

  toggleTooltipVisibility(toggle) {
    if (checkTruncated(this.ref) && this.state.tooltipVisibility !== toggle) {
      this.setState({ tooltipVisibility: toggle });
    }
  }

  createFeatureSearchResult() {
    const { item } = this.props;
    const availableOn = item.availableFor.length > 0 ? this.generateFeatureDetails('Available On:', item.availableFor) : null;
    const standardOn = item.standardOn.length > 0 ? this.generateFeatureDetails('Standard On:', item.standardOn) : null;
    const learnMoreButton = generateLearnMoreButton(item, this.state, this.props.parentComponent.state.analyticsData.clickAnalytics, this.props.parentComponent.state.searchParam, this.props.parentComponent.state.totalResults);
    
    return (
        <div className="feature-content">
          <div ref={this.ref} className="content-header" onMouseEnter={() => this.toggleTooltipVisibility(true)}>{applyMarkdown(item.descriptionToShow)}</div>
          {this.state.tooltipVisibility && <HeaderTooltip desc={applyMarkdown(item.descriptionToShow)} leaveFunction={() => this.toggleTooltipVisibility(false)} />}
          <div className="content-output">
            {standardOn}
            {availableOn}
            <div className="quickAnswersSeeMore">
              {learnMoreButton}
            </div>
          </div>
        </div>
    );
  }

  render() {
    return (this.createFeatureSearchResult());
  }
}

FeatureSearchItemComponent.propTypes = {
  item: PropTypes.object,
  parentComponent: PropTypes.object,
  disclaimerCallback: PropTypes.func,
  index: PropTypes.number,
};

export default injectIntl(FeatureSearchItemComponent);
