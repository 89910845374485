import React from 'react';
import PropTypes from 'prop-types';
import { GlobalAnalytics } from '../../analytics';
import RedLine from '../atoms/RedLine';
import applyMarkdown from '../../screens/utils/applyMarkdown';
import { hasVideoId } from '../../screens/utils/helpers';
import '../../styles/css/vertical-media-text-tile.css';
import VideoPlayerComponent from '../../screens/videoPlayer/videoPlayer.component';

const analytics = new GlobalAnalytics();

const VerticalMediaTextTile = (props) => {
  const readMoreLink = props.link || (props.fileName && encodeURI(`${props.internalFilePath}${props.fileName}`));
  const onReadMoreLinkClick = (buttonText) => {
    if (props.sendButtonClickAnalytics) {
      props.sendButtonClickAnalytics(props.tabPageName, props.title || props.description, buttonText);
    } else {
      analytics.fireOnClick({
        category: 'Tile Click',
        mediaTitle: props.title || props.description, // description is for pages like sales Cert fixed/variable ops
        page: props.tabPageName, // should be a value like 'Overview,' 'Lineup,' or 'Resources'
        section: 'Product',
        subsection: 'Featured Technology', // all static safety/audio pages have a common section and subsection
        tag: '35.2',
        technologyName: props.parentPageName, // should be a value like 'Service Connect,' 'Hybrid,' or 'Certification'
      });
    }
  };

  const getCustomColumnClassName = () => {
    if (props.numberOfColumns) {
      switch (parseInt(props.numberOfColumns, 10)) {
        case 2:
          return 'two-column-layout';
        case 3:
          return 'three-column-layout';
        case 4:
          return 'four-column-layout';
        case 5:
          return 'four-column-layout-wrap';
        default:
          return 'four-column-layout';
      }
    }
    return 'four-column-layout'; // default layout
  };

  const getItemMedia = () => {
    if (hasVideoId(props.brightcoveId, props.qumuId)) {
      return <VideoPlayerComponent
        item={{
          alias: props.brightcoveId,
          analyticsSection: props.parentPageName,
          pageTitle: props.tabPageName,
          qumuId: props.qumuId,
          videoTitle: props.title,
        }}
        sendVideoEventAnalytics={props.sendVideoEventAnalytics}
      />;
    }
    if (props.image) {
      return <img className="item-img" src={`${props.imagePath}${props.image}`} />;
    }
  };

  const tileColumnWithLinkOverlay = () => (
    <React.Fragment>
      <div className="vertical-media-text-space-between">
        <a className="vertical image-link-overlay" href={readMoreLink} target="_blank" onClick={() => onReadMoreLinkClick(props.buttonText)}>
          <div className="image-wrapper">
            {getItemMedia()}
          </div>
          {props.contentType
            && <div className="vertical-media-text-contentType">
              {applyMarkdown(props.contentType, props.disclaimer)}
            </div>
          }
          {props.title
            && <div className="vertical-media-text-title">
              {applyMarkdown(props.title, props.disclaimer)}
            </div>
          }
        </a>
        {!props.hideRedLine
          && <RedLine className="vertical-media-text-tile-redline" />
        }
        <p>{applyMarkdown(props.description, props.disclaimer)}</p>
        <p className="vertical-media-text-subDescription">{applyMarkdown(props.subDescription, props.disclaimer)}</p>
      </div>
      <a href={readMoreLink} target="_blank" onClick={() => onReadMoreLinkClick(props.buttonText)}>
        <div className="red-button-link">{props.buttonText}</div>
      </a>
    </React.Fragment>
  );

  const tileColumn = () => (
    <React.Fragment>
      <div className="vertical-media-text-space-between">
        <div className="image-wrapper">
          {getItemMedia()}
        </div>
        {props.contentType
          && <div className="vertical-media-text-contentType">
            {applyMarkdown(props.contentType, props.disclaimer)}
          </div>
        }
        {props.title
          && <div className="vertical-media-text-title">
            {applyMarkdown(props.title, props.disclaimer)}
          </div>
        }
        {!props.hideRedLine
          && <RedLine className="vertical-media-text-tile-redline" />
        }
        <p>{applyMarkdown(props.description, props.disclaimer)}</p>
        <p className="vertical-media-text-subDescription">{applyMarkdown(props.subDescription, props.disclaimer)}</p>
      </div>
      {props.links && props.links.map(button => <a href={button.url} target="_blank" onClick={() => onReadMoreLinkClick(button.link)}>
        <div className="red-button-link">{button.link}</div>
      </a>)}
    </React.Fragment>
  );


  return (
    <React.Fragment>
      <div className={`vertical-media-text-tile-column ${getCustomColumnClassName()}`}>
        {props.buttonText ? tileColumnWithLinkOverlay() : tileColumn()}
      </div>
    </React.Fragment>
  );
};

VerticalMediaTextTile.propTypes = {
  numberOfColumns: PropTypes.number,
  hideRedLine: PropTypes.bool,
  imagePath: PropTypes.string,
  image: PropTypes.string,
  contentType: PropTypes.string,
  description: PropTypes.string,
  subDescription: PropTypes.string,
  disclaimer: PropTypes.object,
  buttonText: PropTypes.string,
  title: PropTypes.string,
  internalFilePath: PropTypes.string.isRequired,
  fileName: PropTypes.string,
  link: PropTypes.string,
  tabPageName: PropTypes.string.isRequired,
  parentPageName: PropTypes.string.isRequired,
  brightcoveId: PropTypes.string,
  qumuId: PropTypes.string,
  sendVideoEventAnalytics: PropTypes.func,
  links: PropTypes.array,
};

export default VerticalMediaTextTile;
