import React, { Component } from 'react';

class MobilityLinkOutComponent extends Component {
  isSpinIdValid(spinId) {
    return (spinId.length === 12);
  }

  isDealerCodeValid(dealerCode) {
    return (dealerCode.length === 5);
  }

  componentDidMount() {
    if (this.isSpinIdValid(this.props.spinId) && this.isDealerCodeValid(this.props.dealerCode)) {
      this.refs.mobiltiyCenterLogin.submit();
    } else {
      this.refs.mobiltiyCenterLink.click();
    }
  }

  render() {
    return (
            <div>
                <form
                    ref="mobiltiyCenterLogin"
                    action="https://toyotamobility.toyota.com/"
                    method="post">
                    <input type="hidden" name="dealercode" value={this.props.dealerCode} />
                    <input type="hidden" name="login" value={this.props.spinId} />
                </form>
                <a ref="mobiltiyCenterLink" href="https://toyotamobility.toyota.com/" />
            </div>
    );
  }
}

export default MobilityLinkOutComponent;
