import React, { useEffect, useState } from 'react';
import applyCustomMarkdown from '../utils/applyCustomMarkdown';
import '../../styles/css/glossaryAccordion.css';
import Modal from 'react-responsive-modal';
import GlossaryImageCarousel from './glossaryImageCarousel.js';
import closeButton from '../../resources/images/cancel.png';
import AppSettings from '../../appsettings';
import { modalLoaded, accordionLinkClick, termLinkClick, modalLinkClick } from './glossary.analytics';
import { isMobileView } from '../utils/getScreenSize';
import { Accordion } from '../../components/molecules/Accordion';

export const GlossaryAccordion = (props) => {
  const [device, setDevice] = useState('mobile');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [clickedImage, setClickedImage] = useState(0);

  useEffect(() => {
    updateDevice();
    window.addEventListener("resize", updateDevice);
    return () => window.removeEventListener("resize", updateDevice);
  }, []);

  const updateDevice = () => {
    if (window.innerWidth >= 1320) {
      setDevice('desktop');
    } else if (window.innerWidth >= 768) {
      setDevice('tablet');
    } else if (window.innerWidth < 768) {
      setDevice('mobile');
    }
  }

  const closeModal = () => {
    setModalIsOpen(false);
    props.analytics && modalLinkClick(props.analytics.clickAnalytics, modalData.term, props.analytics.clickAnalytics.imageModal.closeLabel);
  }
  
  const openImageModal = (e, term, image) => {
    e.preventDefault();
    setModalIsOpen(true);
    setModalData(term);
    setClickedImage(term.images.indexOf(image));
    props.analytics && modalLoaded(props.analytics.pageLoad);
  }

  const getModalImageArray = (modalImages, clickedImage) => {
    let modalArray = JSON.parse(JSON.stringify(modalImages));;
    modalArray.unshift(modalArray.splice(clickedImage, 1)[0]);
    return modalArray;
  }

  const getGlossaryImagePath = (folder) => `${AppSettings.AWSImgRepo.glossary}${folder}/`;

  const formatLink = (link) => {
    if (link.substring(0, 1) === '/') {
      return link;
    }
    if (link.substring(0, 4) !== 'http') {
      return `http://${link}`;
    }
    return link;
  }

  const getGlossaryDefinitionContent = (term) => {
    const filteredRelatedContent = term.relatedContent && term.relatedContent.filter(e => e.name && e.name != '' && e.url && e.url != '');
    const filteredImages = term.images && term.images.filter(image => image && image != '');

    const imageContent = filteredImages && filteredImages.length > 0 && (
      <div className="glossary-accordion-content-image-container" style={filteredImages.length > 1 || device != 'desktop' ? { justifyContent: "flex-start" } : { justifyContent: "flex-end" }}>
        {filteredImages.map((image, index) =>
          <div className="glossary-accordion-image" key={image + index}>
            <a onClick={(e) => openImageModal(e, term, image)}> <img src={getGlossaryImagePath(term.stagingId) + image} /></a>
          </div>)}
      </div>
    );

    const featureAvailabilityLink = term.featureAvailability && term.featureAvailability !== '' && (
      <div className="glossary-accordion-content-feature-link">
        <a href={formatLink(term.featureAvailability)} target={term.featureAvailability?.includes('engage.toyota.com') ? "" : "_blank"}
          onClick={() => { props.analytics && termLinkClick(props.analytics.clickAnalytics, props.category, term.term, "See Feature Availability") }}>
          See Feature Availability
        </a>
      </div>
    );
    const relatedContentLinks = filteredRelatedContent && filteredRelatedContent.length > 0 && (
      <div className="glossary-accordion-content-external-links">
        <div className="linksTitle">Related Content</div>
        {filteredRelatedContent.map((link) =>
          <div key={link.name} className="glossary-accordion-content-related-link">
            <a href={formatLink(link.url)} target={link.url?.includes('engage.toyota.com') ? "" : "_blank"}
              onClick={() => props.analytics && termLinkClick(props.analytics.clickAnalytics, props.category, term.term, link.name)}>
              {link.name}
            </a>
          </div>)}
      </div>
    );

    const disclaimer = term.disclaimer && (
      <div className="glossary-accordion-content-disclaimers">
        <span className="disclaimerTitle">Disclaimer: </span>
        {term.disclaimer}
      </div>
    );

    return <>
      <div className="glossary-accordion-content-container">
        <div className="glossary-accordion-content-definition-text">
          {term.description && applyCustomMarkdown(term.description, "bold")}
          {relatedContentLinks}
          {featureAvailabilityLink}
          {disclaimer}
        </div>
        {imageContent}
      </div>
    </>
  }

  const buildGlossaryRowItems = (rows) => (
    rows.map((row, i) => (
      {
        title: row.term,
        body: getGlossaryDefinitionContent(row),
        key: `${row.stagingId}.${i}`,
        scrollExpand: props.activeTerm?.toLowerCase() == row.term?.toLowerCase(),
      }
    ))
  );

  const expandAllAnalytics = () => {
    if (props.analytics && props.search) {
      accordionLinkClick(props.analytics.clickAnalytics, props.analytics.clickAnalytics.searchBar.resultsLabel, props.analytics.clickAnalytics.accordion.expandAllLabel, props.search);
    } else if (props.analytics) {
      accordionLinkClick(props.analytics.clickAnalytics, props.category, props.analytics.clickAnalytics.accordion.expandAllLabel);
    }
  }

  const collapseAllAnalytics = () => {
    if (props.analytics && props.search) {
      accordionLinkClick(props.analytics.clickAnalytics, props.analytics.clickAnalytics.searchBar.resultsLabel, props.analytics.clickAnalytics.accordion.collapseAllLabel, props.search);
    } else if (props.analytics) {
      accordionLinkClick(props.analytics.clickAnalytics, props.category, props.analytics.clickAnalytics.accordion.collapseAllLabel);
    }
  }

  const termAnalyticsClick = (term, isExpand) => {
    if (props.analytics && isExpand) {
      termLinkClick(props.analytics.clickAnalytics, props.category, term, props.analytics.clickAnalytics.accordion.expandLabel);
    } else if (props.analytics) {
      termLinkClick(props.analytics.clickAnalytics, props.category, term, props.analytics.clickAnalytics.accordion.collapseLabel);
    }
  }

  const customStyles = {
    content: {
      outerWidth: '1040px'
    },
    modal: {
      maxWidth: '1034px'
    },
    closeButton: {
      top: isMobileView(window.innerWidth) ? "40px" : "30px",
      right: isMobileView(window.innerWidth) ? "50px" : "35px",
    }
  };

  return (
    <>
      <Accordion
        headerTitle={props.title}
        rows={buildGlossaryRowItems(props.terms)}
        expandCollapseAll
        className="glossary-accordion"
        expandAllAnalytics={expandAllAnalytics}
        collapseAllAnalytics={collapseAllAnalytics}
        termAnalyticsClick={termAnalyticsClick}
      />
      {modalData.stagingId && <Modal center open={modalIsOpen} onClose={closeModal} styles={customStyles} closeIcon={<img src={closeButton} />} closeIconId='closeIcon'>
        <GlossaryImageCarousel
          images={modalData.images}
          imagePath={getGlossaryImagePath(modalData.stagingId)}
          modalData={modalData}
          clickedImage={clickedImage}
          modalImages={getModalImageArray(modalData.images, clickedImage)}
          analytics={props.analytics}
        />
      </Modal>}
    </>

  )
}