import React, { useState } from 'react';
import CarouselComponent from '../carousel/carousel.component';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '../../styles/css/carousel.css';
import AppSettings from "../../appsettings";
import '../../styles/css/glossaryImageCarousel.css';
import downloadIcon from '../../resources/images/download.png';
import applyCustomMarkdown from '../utils/applyCustomMarkdown';
import RedLine from '../../components/atoms/RedLine.js';
import { modalLinkClick } from './glossary.analytics.js';

const GlossaryImageCarousel = (props) => {
    const [currentImage, setCurrentImage] = useState(0);

    const carouselContent = (images) => (
        images.map((image, index) => (
            <div key={index} className='imageCarouselContent'>
                <img className='carouselImage' src={props.imagePath + image} />
            </div>
        ))
    );

    const getCurrentImage = (index, direction, length) => {
        if (direction === 'next') {
            if (index != length - 1) {
                setCurrentImage(index + 1);
            } else {
                setCurrentImage(0);
            }
        } else {
            if (index != 0) {
                setCurrentImage(index - 1);
            } else {
                setCurrentImage(length - 1);
            }
        }
    }

    function NextArrow(Items) {
        const {
            onClick, currentSlide, items
        } = Items;
        return (
            <div className={'slick-next'}><img src={AppSettings.AWSImgRepo.resources + 'en/grey_circle_arrow.png'} onClick={() => { props.analytics && modalLinkClick(props.analytics.clickAnalytics, props.modalData.term, props.analytics.clickAnalytics.imageModal.rightLabel); getCurrentImage(currentSlide, 'next', items.length); onClick(); }} /></div>
        );
    }

    function PrevArrow(Items) {
        const {
            onClick, currentSlide, items
        } = Items;
        return (
            <div className={'slick-prev'}><img src={AppSettings.AWSImgRepo.resources + 'en/grey_circle_arrow.png'} onClick={() => { props.analytics && modalLinkClick(props.analytics.clickAnalytics, props.modalData.term, props.analytics.clickAnalytics.imageModal.leftLabel); getCurrentImage(currentSlide, 'back', items.length); onClick(); }} /></div>
        );
    }

    const settings = {
        adaptiveHeight: true,
        respondTo: 'window',
        zIndex: 1,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 0,
        nextArrow: <NextArrow items={props.modalImages} />,
        prevArrow: <PrevArrow items={props.modalImages} />,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className="glossary-modalContainer">
            <div className="modalHeader">
                <div className="modalTitle">
                    <div className="title">
                        {applyCustomMarkdown(props.modalData.term, "bold")}
                    </div>
                    <RedLine />
                </div>
            </div>
            <div className='imageCarouselContainer'>
                <div className='imageCarousel'>
                    <CarouselComponent settings={settings} >
                        {props.modalImages && carouselContent(props.modalImages)}
                    </CarouselComponent>
                </div>
            </div>
        </div>
    );
};

export default GlossaryImageCarousel;