import React, { useRef, useState } from 'react';
import '../../../styles/css/smartpath-overview-roadmap.css';
import applyMarkdown from '../../utils/applyMarkdown';
import { isDesktopView, isMobileView, isTabletView } from '../../utils/getScreenSize';
import * as roadMapAnalytics from '../overviewAnalytics/smartpath-overview-roadMap.analytics';
import useOnScreen from '../useIntersection';

export const SmartPathOverviewRoadmap = (props) => {
    const emptyExpendedArray = new Array(props.roadmap.content.length).fill(false);
    const roadMapRef = useRef();
    const [isClicked, setIsClicked] = useState(emptyExpendedArray);

    if (useOnScreen(roadMapRef) && props.pageLoadAnalytics) {
        roadMapAnalytics.roadMapSubLoad(props.pageLoadAnalytics);
    }

    const getItemclass = () => ({
        description: "raceTrack-item-body-content-description",
        descriptionIcon: "raceTrack-item-body-content-icon",
        descriptionIconInactive: "raceTrack-item-body-content-inactiveIcon",
    });

    const Hasdescription = (description) => description != null;

    const handleClick = function (title, description, item) {
        const isVisible = toggleVisibility(item, description);
        if (props.clickAnalytics && title && description) {
            roadMapAnalytics.tileClick(props.clickAnalytics, title, isVisible);
        }
    };

    const toggleVisibility = (item, hasDescription) => {
        if (!hasDescription) return false;
        const newValue = !isClicked[item];
        let updatedIsClicked = [...isClicked];
        if (!isDesktopView(window.innerWidth)) {
            updatedIsClicked = emptyExpendedArray;
        }
        updatedIsClicked[item] = newValue;
        setIsClicked(updatedIsClicked);
        return newValue;
    };

    const roadmapKey = props.roadmap.key.map(key => (
        <div className="roadmap-key-item">
            <div className="roadmap-key-item-icon"><img src={props.imagePath + key.image} /></div>
            <div className="roadmap-key-item-description">{key.description}</div>
        </div>
    ));

    const roadmapcontent = props.roadmap.content.map((content, item) => {
        const itemIsVisible = isClicked[item];
        const itemHasDescription = Hasdescription(content.description);
        return (
            <div className="raceTrack-item">
                <div className="raceTrack-item-image"><img src={props.imagePath + content.image} /></div>
                <div className={`raceTrack-item-body ${itemHasDescription ? 'pointer-cursor' : ''} ${itemIsVisible ? 'item-is-visible' : 'item-is-not-visible'}`}
                    style={{
                        zIndex: 1000 - item
                    }}
                    onClick={() => handleClick(content.title, itemHasDescription, item)}
                >
                    <div className="raceTrack-item-body-content">
                        <div className="raceTrack-item-body-content-title">{applyMarkdown(content.title)}</div>
                        {itemHasDescription && (
                            <div className="raceTrack-item-body-icon-container">
                                <div className={itemIsVisible ? getItemclass().descriptionIconInactive : getItemclass().descriptionIcon} />
                            </div>
                        )}
                    </div>
                    {itemHasDescription && (
                        <div
                            className={getItemclass().description}
                            style={{ display: itemIsVisible ? 'block' : 'none' }}
                        >
                            {applyMarkdown(content.description)}
                        </div>
                    )}
                </div>
            </div>
        )
    });

    const header = (
        <div>
            <div className="roadmap-header" >{applyMarkdown(props.roadmap.header)}</div>
            <div className="roadmap-key">{roadmapKey}</div>
        </div>
    );

    const getImage = (section) => {
        if (isDesktopView(window.innerWidth)) {
            return section.image;
        }
        if (isTabletView(window.innerWidth)) {
            return section.tabletImage;
        }
        if (isMobileView(window.innerWidth)) {
            return section.mobileImage;
        }
    };

    return (
        <div className="smartpath-roadmap" ref={roadMapRef}>
            {header}
            <div className='roadmap-raceTrack' style={{ 'background-image': `url(${props.imagePath + getImage(props.roadmap)})` }}>
                <div className="roadmap-raceTrack-items">{roadmapcontent}</div>
            </div>
        </div>
    );
}
