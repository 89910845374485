import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import applyMarkdown from '../../utils/applyMarkdown';
import '../../../styles/css/smartpath-overview-pricing.css';
import * as pricingAnalytics from '../overviewAnalytics/smartpath-overview-pricing.analytics';
import useOnScreen from '../useIntersection';

export const SmartPathOverviewPricing = (props) => {
    const pricingRef = useRef();
    if (useOnScreen(pricingRef) && props.pageLoadAnalytics) {
        pricingAnalytics.pricingSubLoad(props.pageLoadAnalytics);
    }

    const pricingTitles = (titles) => (
        titles.map((titleItem, index) => (
            <div key={index}>
                <div className='smartpath-overview-pricing-plus'><img src={props.imagePath + `plus.png`} /></div>
                <div className='smartpath-overview-pricing-title'>
                    <div className='smartpath-overview-pricing-image'><img src={props.imagePath + titleItem.image} /></div>
                    <div className='smartpath-overview-pricing-title-text'>{titleItem.title}</div>
                </div>
            </div>
        ))
    );

    const pricingDetails = (details) => (
        details.map((detailItem, index) => (
            <div className='smartpath-overview-pricing-detail' key={index}>
                {detailItem.detail}
            </div>
        ))
    );

    const pricingLinkClickAnalytics = (linkText) => {
        if (props.clickAnalytics && linkText) {
            pricingAnalytics.linkClick(props.clickAnalytics, linkText);
        }
    };

    const pricingCards = (cards) => (
        cards.map((card, index) => (
            <div className='smartpath-overview-pricing-card' key={index}>
                <div className='smartpath-overview-pricing-titles'>
                    {pricingTitles(card.titles)}
                </div>
                <div className='smartpath-overview-pricing-info'>
                    <div className='smartpath-overview-pricing-description'>{applyMarkdown(card.description)}</div>
                    <div className='smartpath-overview-pricing-details'>{pricingDetails(card.details)}</div>
                    {card.link ? <div className='smartpath-overview-pricing-link'><a target='_blank' href={`${card.url}`} onClick={() => pricingLinkClickAnalytics(card.link)}>{`${card.link}`}</a></div> : null}
                </div>
                <div className='smartpath-overview-pricing-prices'>
                    <div className='smartpath-overview-pricing-price-item'>
                        <div className='smartpath-overview-pricing-price-title'>Small Dealer</div>
                        <div className='smartpath-overview-pricing-price-description'>(0-499 annual vehicle sales)</div>
                        <div className='smartpath-overview-pricing-price'>{card.small}</div>
                    </div>
                    <div className='smartpath-overview-pricing-price-item'>
                        <div className='smartpath-overview-pricing-price-title'>Medium Dealer</div>
                        <div className='smartpath-overview-pricing-price-description'>(500-1,000 annual vehicle sales)</div>
                        <div className='smartpath-overview-pricing-price'>{card.medium}</div>
                    </div>
                    <div className='smartpath-overview-pricing-price-item'>
                        <div className='smartpath-overview-pricing-price-title'>Large Dealer</div>
                        <div className='smartpath-overview-pricing-price-description'>(1,001+ annual vehicle sales)</div>
                        <div className='smartpath-overview-pricing-price'>{card.large}</div>
                    </div>
                </div>
            </div>
        ))
    );

    return <div className='smartpath-overview-pricing' ref={pricingRef}>
        <div className='smartpath-overview-pricing-header'>{applyMarkdown(props.data.header)}</div>
        <div className='smartpath-overview-pricing-content'>{pricingCards(props.data.cards)}</div>
    </div>;

};