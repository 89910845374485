export const availableFilters = [
  {
    label: 'recallsAndSafety.publicationFilter.awareness',
    id: 'awareness',
    queryString: 'filter_videos',
  },
  {
    label: 'recallsAndSafety.publicationFilter.dealershipToolkits',
    id: 'dealershipToolkits',
    queryString: 'filter_dealershipToolkits',
  },
  {
    label: 'recallsAndSafety.publicationFilter.eventPlaybook',
    id: 'eventPlaybook',
    queryString: 'filter_eventPlaybook',
  },
  {
    label: 'recallsAndSafety.publicationFilter.outreach',
    id: 'outreach',
    queryString: 'filter_outreach',
  },
  {
    label: 'recallsAndSafety.publicationFilter.referenceGuides',
    id: 'referenceGuides',
    queryString: 'filter_referenceGuides',
  },
  {
    label: 'recallsAndSafety.publicationFilter.videos',
    id: 'videos',
    queryString: 'filter_videos',
  },
];

export const getActiveFilterObjectFromId = filterId => availableFilters.find(x => x.id === filterId);
