import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();


export const appPageLoad = (pageLoadAnalytics, currentPage) => {
    pageLoadAnalytics && analytics.firePageLoad({
        tag: pageLoadAnalytics.tagId,
        section: pageLoadAnalytics.section,
        page: currentPage
    });
};


const contentLinkClick = (tag, container, category, label, positionTitle, contentTitle) => {
    analytics.fireOnClick({
        tag,
        container,
        category,
        label,
        positionTitle,
        contentTitle
    });
};

export const appBookmarkClick = (clickAnalytics, currentPage) => {
    clickAnalytics && contentLinkClick(
        clickAnalytics.tagId,
        clickAnalytics.container,
        currentPage,
        clickAnalytics.bookmarkLabel
    );
}

export const appSubheaderClick = (clickAnalytics, currentPage, desitinationPage) => {
    clickAnalytics && contentLinkClick(
        clickAnalytics.tagId,
        clickAnalytics.container,
        currentPage.toLowerCase()==='getstarted'?clickAnalytics.ctaCard.getStartedCategory:currentPage,
        clickAnalytics.subheaderClick.label,
        clickAnalytics.subheaderClick.positionTitle,
        desitinationPage


    )
}

export const whatIsTheApp = (clickAnalytics, groupTitle) => {
    clickAnalytics && contentLinkClick(
        clickAnalytics.tagId,
        clickAnalytics.container,
        clickAnalytics.overviewCategory,
        clickAnalytics.whatIsTheApp.label,
        clickAnalytics.whatIsTheApp.positionTitle,
        groupTitle
    )
}
export const appleIconClick = (clickAnalytics, category) => {
    clickAnalytics && contentLinkClick(
        clickAnalytics.tagId,
        clickAnalytics.container,
        category.toLowerCase()==='getstarted'?clickAnalytics.ctaCard.getStartedCategory:clickAnalytics.overviewCategory,
        clickAnalytics.ctaCard.appStoreIconLabel,
        category.toLowerCase() === 'overview' ? clickAnalytics.ctaCard.overviewPositionTitle : clickAnalytics.ctaCard.getStartedPosTitle
    )
}
export const googleIconClick = (clickAnalytics, category) => {
    clickAnalytics && contentLinkClick(
        clickAnalytics.tagId,
        clickAnalytics.container,
        category.toLowerCase()==='getstarted'?clickAnalytics.ctaCard.getStartedCategory:clickAnalytics.overviewCategory,
        clickAnalytics.ctaCard.googleIconLabel,
        category.toLowerCase() === 'overview' ? clickAnalytics.ctaCard.overviewPositionTitle : clickAnalytics.ctaCard.getStartedPosTitle
    )
}
export const appleDownloadClick = (clickAnalytics, category) => {
    clickAnalytics && contentLinkClick(
        clickAnalytics.tagId,
        clickAnalytics.container,
        category.toLowerCase()==='getstarted'?clickAnalytics.ctaCard.getStartedCategory:clickAnalytics.overviewCategory,
        clickAnalytics.ctaCard.appleDownload,
        category.toLowerCase() === 'overview' ? clickAnalytics.ctaCard.overviewPositionTitle : clickAnalytics.ctaCard.getStartedPosTitle
    )
}
export const googleDownloadClick = (clickAnalytics, category) => {
    clickAnalytics && contentLinkClick(
        clickAnalytics.tagId,
        clickAnalytics.container,
        category.toLowerCase()==='getstarted'?clickAnalytics.ctaCard.getStartedCategory:clickAnalytics.overviewCategory,
        clickAnalytics.ctaCard.googleDownload,
        category.toLowerCase() === 'overview' ? clickAnalytics.ctaCard.overviewPositionTitle : clickAnalytics.ctaCard.getStartedPosTitle
    )
}
export const formClicked = (clickAnalytics)=>{
    clickAnalytics && contentLinkClick(
        clickAnalytics.tagId,
        clickAnalytics.container,
        clickAnalytics.faqForm.category,
        clickAnalytics.faqForm.label
    )
}
export const submitForm = (clickAnalytics,positionTitle,contentTitle)=>{
    clickAnalytics && contentLinkClick(
        clickAnalytics.tagId,
        clickAnalytics.container,
        clickAnalytics.faqForm.category,
        clickAnalytics.faqForm.submitLabel,
        positionTitle,
        contentTitle
    )
}
export const scrollButtonClick = (clickAnalytics, contentTitle) => {
    clickAnalytics && contentLinkClick(
        clickAnalytics.tagId,
        clickAnalytics.container,
        clickAnalytics.overviewCategory,
        clickAnalytics.scrollButtons.label,
        clickAnalytics.scrollButtons.positionTitle,
        contentTitle

    )
}