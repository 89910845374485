import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();

export const homepageLoaded = (pageLoadAnalytics) => {
    pageLoadAnalytics && analytics.firePageLoad({
      tag: pageLoadAnalytics.tagId,
      section: pageLoadAnalytics.section,
      page: pageLoadAnalytics.page
    });
  };

  export const heroButtonClicked = (pageLoadAnalytics, label, title) => {
    pageLoadAnalytics && analytics.fireOnClick({
      tag: pageLoadAnalytics.tagId,
      container: pageLoadAnalytics.container,
      category: pageLoadAnalytics.category,
      label: label,
      contentTitle: title,
    });
  };

  export const generalButtonClicked = (pageLoadAnalytics, label) => {
    pageLoadAnalytics && analytics.fireOnClick({
      tag: pageLoadAnalytics.tagId,
      container: pageLoadAnalytics.container,
      category: pageLoadAnalytics.category,
      label: label,
    });
  };

  export const carouselArrowClicked = (pageLoadAnalytics, label) => {
    pageLoadAnalytics && analytics.fireOnClick({
      tag: pageLoadAnalytics.tagId,
      container: pageLoadAnalytics.container,
      category: pageLoadAnalytics.category,
      label: label,
    });
  };

  export const carouselContentClicked = (pageLoadAnalytics, title) => {
    pageLoadAnalytics && analytics.fireOnClick({
      tag: pageLoadAnalytics.tagId,
      container: pageLoadAnalytics.container,
      category: pageLoadAnalytics.category,
      label: pageLoadAnalytics.label,
      contentTitle: title,
    });
  };

  export const featuredVehilceButtonClicked = (pageLoadAnalytics, label, modelYear, modelName) => {
    pageLoadAnalytics && analytics.fireOnClick({
      tag: pageLoadAnalytics.tagId,
      container: pageLoadAnalytics.container,
      category: pageLoadAnalytics.category,
      label: label,
      modelName: modelName,
      modelYear: modelYear
    });
  };