import { GlobalAnalytics } from "../../analytics";

const analytics = new GlobalAnalytics();

// Page Load
export const HowDoPageLoad = (pageLoad) => {
  pageLoad &&
    analytics.firePageLoad({
      app: pageLoad.appId,
      tag: pageLoad.tagId,
      section: pageLoad.section,
      page: pageLoad.page,
      subsection: pageLoad.subsection,
      technologyName: pageLoad.technology_name,
    });
};

// Tracking click for a back button click
export const backButtonClick = (clickAnalytics, analyticsPage, analyticsCategory) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: analyticsPage,
      category: analyticsCategory,
      label: "Back Button Click"
    });
  }
};

// Tracking click for a click on Check My Balance
export const checkBalanceClick = (clickAnalytics, analyticsPage, analyticsCategory) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: analyticsPage,
      category: analyticsCategory,
      label: "Check My Balance Button",
      contentTitle: "Check My Balance"
    });
  }
};