import { Component } from 'react';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import { VehicleResourceTemplate } from './vehicleResource.tpl';
import getSpotlightGallery from '../../services/spotlightService.js';
import { GlobalAnalytics } from '../../analytics';
import { getLocale } from '../../services/languageTranslationService.js';
import { postUserActivityRecord } from '../../services/userActivityService.js';
import { getS3PathName } from '../utils/vehicleName';

const analytics = new GlobalAnalytics();

class VehicleResourceComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      spotlightHero: {},
      spotlightGrid: [],
      model: this.props.route.model.toUpperCase(),
      year: this.props.params.year,
      eBrochure: '',
      s3Path: '',
      languageOverride: false,
      displayOverrideText: false,
    };

    this.resourceClick = this.resourceClick.bind(this);
    this.filterMenuClick = this.filterMenuClick.bind(this);
    this.sortAnalytics = this.sortAnalytics.bind(this);
    this.heroImageClick = this.heroImageClick.bind(this);
    this.eBrochureClick = this.eBrochureClick.bind(this);
    this.deliveryClick = this.deliveryClick.bind(this);
    this.sourceBookClick = this.sourceBookClick.bind(this);
    this.pocketSourceAppClick = this.pocketSourceAppClick.bind(this);
    this.getS3Path = this.getS3Path.bind(this);
  }

  async getS3Path () {
    if (this.props.routes && this.props.params.year) {
      const s3PathName = await getS3PathName(this.props.routes[0].model);
      const year = this.props.params.year ? this.props.params.year : this.props.routes[0].year;
      const language = 'en';
      this.setState({
        s3Path: `${year}/${s3PathName}/${language}/`,
      });
    }
  }

  getGalleryResources(modelName, modelYear, mockedResponse, language) {
    return new Promise((resolve, reject) => {
      getSpotlightGallery(modelName, modelYear, mockedResponse, language)
        .then((payload) => {
          if (Object.keys(payload).length === 0 && payload.constructor === Object) {
            this.setState({
              languageOverride: true,
              displayOverrideText: true,
            });
            resolve(false);
          } else {
            try {
              this.setState({
                spotlightHero: payload.hero ? payload.hero : '',
                spotlightGrid: payload.spotlightGrid ? payload.spotlightGrid : '',
                eBrochure: payload.eBrochure ? payload.eBrochure : '',
                sourceBook: payload.sourceBook ? payload.sourceBook : '',
                deliveryChecklistPath: payload ? payload.deliveryChecklistPath : '',
                languageOverride: false,
              });
              resolve(true);
            } catch (error) {
              reject(error);
            }
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  heroImageClick() {
    analytics.fireOnClick({
      tag: 29.2,
      page: 'Vehicle Resources',
      section: 'Product',
      modelYear: this.props.params.year,
      modelName: this.props.route.model,
      container: 'Module',
      module: 'Feed',
      category: this.state.spotlightHero.resourceType,
      label: this.state.spotlightHero.title ? this.state.spotlightHero.title : 'No title provided',
    });
  }

  filterMenuClick(event) {
    analytics.fireOnClick({
      tag: 29.2,
      page: 'Vehicle Resources',
      section: 'Product',
      modelYear: this.props.params.year,
      modelName: this.props.route.model,
      container: 'Product Nav',
      module: 'Vehicle Resources Filter',
      category: 'Nav Link',
      label: event.target.textContent,
    });
  }

  resultTypeClick(resourceTag) {
    if (resourceTag) {
      analytics.fireOnClick({
        tag: 29.2,
        page: 'Vehicle Resources',
        section: 'Product',
        modelYear: this.props.params.year,
        modelName: this.props.route.model,
        container: 'Module',
        module: 'Feed',
        category: resourceTag,
        label: 'Content Link',
      });
    }
  }

  resourceClick(resource) {
    analytics.fireOnClick({
      tag: 29.2,
      page: 'Vehicle Resources',
      section: 'Product',
      modelYear: this.props.params.year,
      modelName: this.props.route.model,
      container: 'Module',
      module: 'Feed',
      category: resource.recordType ? resource.recordType : resource.resourceType,
      label: resource.title ? resource.title : 'No title provided',
    });
  }

  sortAnalytics(event) {
    analytics.fireOnClick({
      tag: 29.2,
      page: 'Vehicle Resources',
      section: 'Product',
      modelYear: this.props.params.year,
      modelName: this.props.route.model,
      container: 'Product Nav',
      module: 'Vehicle Resources Filter',
      category: 'Nav Link',
      label: event.target.value,
    });
  }

  eBrochureClick() {
    console.log('ebrochure');
    analytics.fireOnClick({
      tag: 29.2,
      page: 'Vehicle Resources',
      section: 'Product',
      modelYear: this.props.params.year,
      modelName: this.props.route.model,
      container: 'Module',
      module: 'Feed',
      category: 'Article',
      label: 'eBrochure download',
    });
  }

  deliveryClick() {
    console.log('delivery');
    analytics.fireOnClick({
      tag: 29.2,
      page: 'Vehicle Resources',
      section: 'Product',
      modelYear: this.props.params.year,
      modelName: this.props.route.model,
      container: 'Module',
      module: 'Feed',
      category: 'PDF Download',
      label: 'Delivery Checklist Download',
    });
  }

  sourceBookClick() {
    console.log('sourcebook');
    analytics.fireOnClick({
      tag: 29.2,
      page: 'Vehicle Resources',
      section: 'Product',
      modelYear: this.props.params.year,
      modelName: this.props.route.model,
      container: 'Module',
      module: 'Feed',
      category: 'PDF Download',
      label: 'Source Book Download',
    });
  }

  pocketSourceAppClick() {
    analytics.fireOnClick({
      tag: 29.2,
      page: 'Vehicle Resources',
      section: 'Product',
      modelYear: this.props.params.year,
      modelName: this.props.route.model,
      container: 'Module',
      module: 'Feed',
      category: 'Article',
      label: 'Pocket Source App',
    });
  }

  componentDidMount() {
    this.getS3Path();
    postUserActivityRecord();
    this.getPageData();
    analytics.firePageLoad({
      tag: 29.1,
      page: 'Vehicle Resources',
      section: 'Product',
      modelYear: this.props.params.year,
      modelName: this.props.route.model,
    });
  }

  componentDidUpdate() {
    if (this.state.displayOverrideText && getLocale() === 'en') {
      this.setState({
        displayOverrideText: false,
      });
    }
  }

  getPageData() {
    this.getGalleryResources(this.props.route.model, this.props.params.year, false, 'en') // Initial attempt to get data
      .then((data) => {
        Promise.resolve(data);
      }).catch((error) => {
        Promise.reject(error);
        throw new Error(error);
      });
  }

  render() {
    return VehicleResourceTemplate(this);
  }
}

VehicleResourceComponent.propTypes = {
  params: PropTypes.object.isRequired,
  routes: PropTypes.array.isRequired,
  route: PropTypes.object.isRequired,
};

export default injectIntl(VehicleResourceComponent);
