import "../../../../styles/scss/beyondZero/header.scss";
import AppSettings from "../../../../appsettings";
import applyMarkdown from "../../../utils/applyMarkdown";
import { isDesktopView, isTabletView } from "../../../utils/getScreenSize";

const Header = ({ data }) => {

    const getImagePath = () => {
        if (isDesktopView(window.innerWidth)) {
            return data?.desktopImage;
        } else if (isTabletView(window.innerWidth)) {
            return data?.tabletImage;
        } else {
            return data?.mobileImage;
        }
    }

    return <div className="beyond-zero-wrapper">
       <div className="beyond-zero-container">
            <div>
                <img src={`${AppSettings.AWSImgRepo.beyondZero}${data?.smallImage}`} alt="banner"/>
                <h1>{applyMarkdown(data?.title)}</h1>
                <p>{applyMarkdown(data?.description)}</p>
            </div>
       </div>
        <img 
            className="beyond-zero-banner"
            src={`${AppSettings.AWSImgRepo.beyondZero}${getImagePath()}`}
            alt="banner"
        ></img>
    </div>
}

export default Header;