import React, { useEffect } from 'react';
import SubPageHeader from '../../../components/molecules/marketingPage/subPageHeader';
import CardGroupSection from '../../../components/molecules/marketingPage/cardGroupSection';
import { appPageLoad, appleIconClick, appleDownloadClick, googleDownloadClick, googleIconClick } from '../appPage-analytics';

function AppPageGetStarted(props) {
    // Pull the sub page header header, cards, and call-to-action data out of getStartedData
    const header = props.getStartedData?.hero;
    const cards = props.getStartedData?.getStartedCards;
    const ready = props.getStartedData?.readyCard;
    const pageLoadAnalytics = props.analyticsData && props.analyticsData.pageLoad;

    const componentProps = {
        device: props.device,
        imagePath: props.imagePath
    }

    useEffect(()=>{
        appPageLoad(pageLoadAnalytics, "Get Started");
    }, []);

    return (
        <>
            <SubPageHeader
                {...componentProps}
                title={header?.title}
                redline={header?.redline}
                description={header?.description}
            />
            <CardGroupSection
                {...componentProps}
                title={cards.title}
                cardGroups={cards.cardGroups}
                footnote={cards.footnote}
                titleType = "middleRegular"
            />
            <CardGroupSection
                {...componentProps}
                title={ready.title}
                cardGroups={ready.cardGroups}
                clickAnalytics={props.analyticsData.clickAnalytics}
                clickFunctions={{appleIconClick, googleIconClick, appleDownloadClick, googleDownloadClick}}
                pageName = {props.pageName}
            />
        </>
    );
}

export default AppPageGetStarted;