import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import SkillsTemplate from './skills.tpl';
import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();

export class SkillsComponent extends Component {
  constructor(props) {
    super(props);
    const { params } = this.props.match;
    this.state = {
      recordType: params.recordType,
      resourceType: params.resourceType,
    };
  }

  resourceClick(resource) {
    analytics.fireOnClick({
      tag: '19.5',
      technologyName: 'Skills',
      mediaTitle: resource.title,
      categoryTopic: this.state.recordType,
      articleType: resource.category,
    });
  }

  filterClick(event) {
    analytics.fireOnClick({
      tag: '19.5',
      technologyName: 'Skills',
      mediaTitle: event.target.innerText,
      categoryTopic: this.state.recordType,
      articleType: ' ',
    });
  }

  sortClick(event) {
    analytics.fireOnClick({
      tag: '19.5',
      technologyName: 'Skills',
      mediaTitle: event.target.value,
      categoryTopic: this.state.recordType,
      articleType: ' ',
    });
  }

  componentDidMount() {
    analytics.firePageLoad({
      tag: '19.3',
      technologyName: 'Skills',
      categoryTopic: this.state.recordType,
    });
  }

  render() {
    return SkillsTemplate(this);
  }
}

SkillsComponent.propTypes = {
  params: PropTypes.object,
};

export default injectIntl(SkillsComponent);
