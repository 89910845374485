import '../../../styles/scss/content-card.scss';
import getTarget from '../../../screens/utils/getTarget';
import Skeleton from "react-loading-skeleton";
import ReactFreezeframe from 'react-freezeframe';

// This component was originally created for the Explore Carousel on HomepageV3.
// Shortly after similar designs started to be used elsewhere on Engage and the 
// decision was made to convert this into a reusable component. Please ensure
// all future changes to this component are done in such a way that allows for 
// reusability. 

const ContentCard = (props) => {

    const className = `contentCard ${props.style}`;
    const isGif = !!props?.image?.endsWith('.gif');

    if (props.loading) {
        return (
            <div className={`skeleton-card ${className}`}>
                <Skeleton className="image" />
                <Skeleton count={1.5} className="text" />
            </div>
        )
    }

    return (
        <div className={className} onClick={props?.analytics}>
            <a href={props.link} target={getTarget(props.link)} >
                <div className='contentCardImageContainer'>
                    {props.overlayImage && <div className='contentCardOverlayImage'>
                        <img src={props.overlayImage} />
                    </div>}
                    <div className='contentCardImage'>
                        {isGif ? (
                            <ReactFreezeframe key={props.image} className={props.imageClass} src={props.image} />
                        ) : (
                            <img src={props.image} className={props.imageClass} />
                        )}
                    </div>
                    {!props.hideCornerIcon && <div className='contentCardCornerIcon'>
                        <img src={props.cornerIcon} />
                    </div>}
                </div>
                <div className='contentCardTitle'>{props.title}</div>
            </a>
        </div>
    );
}

export default ContentCard;