import React, { useState, useEffect, useRef } from "react";
import applyMarkdown from "../../utils/applyMarkdown";
import appsettings from "../../../appsettings";

const AccessoriesAccordionContent = ((props)=> {
    const [accessoryImg, setAccessoryImg] = useState(props?.item?.imageUrl)

    return(
        <div class="accessories-v2-accordion-container">
            <div class="img-container">
                <div class="img-background m-b-16">
                    <img src={accessoryImg || appsettings.AWSImgRepo.resources + 'en/image_coming_soon_rectangle_placeholder.png'}
                        class="img" 
                        onError={() => 
                            setAccessoryImg(appsettings.AWSImgRepo.resources + 'en/image_coming_soon_rectangle_placeholder.png')
                        }
                    />
                </div>
            </div>
            <div class="text-container">
                <div class="m-b-16">{applyMarkdown(props?.paragraphDescription, null, true)}</div>
                {
                    props?.item?.installationPoint ? 
                        <div>
                            <div class="font-bold">Installation</div>
                            <div class="m-b-16">{props?.item?.installationPoint}</div>
                        </div> : 
                        <div></div>
                }
                {
                    props?.item?.ppoCode ? 
                        <div>
                            <div class="font-bold">PPO Code</div>
                            <div class="m-b-16">{props?.item?.ppoCode}</div>
                        </div> : 
                        <div></div>
                }
                {
                    props?.item?.partsOnlyMsrp ? 
                        <div>
                            <div class="font-bold">MSRP</div>
                            <div class="m-b-16">${props?.item?.partsOnlyMsrp}</div>
                        </div> : 
                        <div></div>
                }
                {
                    props?.item?.partNumber ? 
                        <div>
                            <div class="font-bold">Part #</div>
                            <div class="m-b-16">{props?.item?.partNumber}</div>
                        </div> : 
                        <div></div>
                }
                {
                    props?.listDescription ? 
                        <div>
                           <div class="slim-border m-b-16"></div>
                           <div class="m-b-16 li-padding">{applyMarkdown(props?.listDescription, null, null, true)}</div>
                        </div> : 
                        <div></div>
                }
                <div class="m-b-16">{applyMarkdown(props?.disclaimer, null, null, true)}</div>
            </div>
        </div>
    )
})

export default AccessoriesAccordionContent;