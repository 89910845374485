import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import applyMarkdown from '../../screens/utils/applyMarkdown';
import { FilterMenu } from '../../screens/filterMenu/filterMenu.component';
import HorizontalMediaTextTile from './horizontal-media-text-tile';
import DisclaimersComponent from '../../screens/disclaimers/disclaimers.component';
import '../../styles/css/dynamic-row-item-list.css';
import SectionHeader from '../atoms/SectionHeader';

export const DynamicRowItemList = (props) => {
  const [disclaimer, setDisclaimer] = useState(null);
  const [activeFilterId, setactiveFilterId] = useState(props.defaultFilterId);
  const disclaimerRef = useRef();
  const onFilterClick = (e, filter) => {
    const newActiveFilterId = filter.id;
    setactiveFilterId(newActiveFilterId);
  };

  useEffect(() => {
    props.pageLoadAnalytics('Overview');
    setactiveFilterId(props.defaultFilterId);
    setDisclaimer(disclaimerRef.current);
  }, [props, disclaimerRef]);

  const renderOverviewTiles = () => props.items[activeFilterId] && props.items[activeFilterId].map((item, i) => <HorizontalMediaTextTile
    buttonText={item.buttonText}
    key={`${item.image}-${i}`}
    disclaimer={disclaimer}
    {...props}
    {...item}
  />);

  const renderFilterMenu = () => <FilterMenu
    active={activeFilterId}
    filters={props.availableFilters}
    filterClick={onFilterClick}
    displayArrow={false}
  />;

  return <div>
    <div className="filter-menu-wrapper show-for-small-only">{renderFilterMenu()}</div>
    <div className="dynamic-row-item-list">
      <SectionHeader>{applyMarkdown(props.headline, disclaimer)}</SectionHeader>
      <div className="hide-for-small-only">{renderFilterMenu()}</div>
      {
        props.description
        && <React.Fragment>
          <div className="dynamic-row-list-headline-description">{applyMarkdown(props.description, disclaimer)}</div>
          <div className="dynamic-row-list-grey-line header show-for-small-only" />
        </React.Fragment>
      }
      <div className="media-text-row-container">
        {renderOverviewTiles()}
      </div>
    </div>
    <DisclaimersComponent template={props.disclaimerName} ref={(instance) => { disclaimerRef.current = instance; }} />
  </div >;
};

DynamicRowItemList.propTypes = {
  availableFilters: PropTypes.array,
  defaultFilterId: PropTypes.string.isRequired,
  description: PropTypes.string,
  disclaimerName: PropTypes.string,
  headline: PropTypes.string,
  internalFilePath: PropTypes.string,
  imagePath: PropTypes.string,
  pageLoadAnalytics: PropTypes.func,
  parentPageName: PropTypes.string,
  sections: PropTypes.array,
};
