import React from "react";
import ProfileField from './profileField';

const ProfileSection = ({ fields }) => {

  return (
    <div className='profile-info-column'>
      {fields.map((field) => (
        <ProfileField
          label={field?.label}
          value={field.value}
        />
      ))}
    </div>
  );
};

export default ProfileSection;
