import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useParams, useHistory } from 'react-router';
import { DynamicColumnItemList } from '../../components/molecules/dynamic-column-item-list';
import SubheaderComponent from '../subheader/subheader.component';
import Display404NotFound from '../../components/atoms/404NotFound';
import { StarSafetySystemResources } from './components/star-safety-system-resources';
import { overviewAnalytics } from './star-safety-system-analytics';
import AppSettings from '../../appsettings';
import getMisc from '../../services/miscService';
import MobileBackButton from '../../components/molecules/MobileBackButton';

const StarSafetySystemRouterComponent = (props) => {
  const [pageData, setPageData] = useState(null);
  const history = useHistory();

  useEffect(() => {
    getMisc('SSS', 'en')
      .then((data) => {
        setPageData(data);
      })
      .catch((error) => {
        console.log(`ERROR: failed to load Star Safety System page data. ${JSON.stringify(error.statusText)}`);
      });
  }, []);

  const overviewPageProps = {
    disclaimerName: 'SSS',
    internalFilePath: AppSettings.AWSImgRepo.sss,
    imagePath: AppSettings.AWSImgRepo.sss,
    parentPageName: 'Star Safety System',
  };

  const getSubheaderChildComponent = (path) => {
    const page = path ? path.toLowerCase() : '';
    switch (page) {
      case 'overview':
        return pageData.items && <DynamicColumnItemList
          {...pageData.items.overview}
          {...overviewPageProps}
          printHeadline="overviewpage.printHeadline.starSafetySystem"
          pageLoadAnalytics={overviewAnalytics.pageLoadAnalytics} />;
      case 'resources':
        return <StarSafetySystemResources />;
      default:
        history.push('/starSafetySystem/overview');
        break;
    }
  };

  const params = useParams();
  const pageName = params && params.page;
  const route = {
    engine: '',
    featSpec: undefined,
    model: '',
    path: pageName || 'overview',
    trim: '',
    year: params.year,
  };
  const isValidTabName = () => ['overview', 'resources'].filter(x => x === pageName).length > 0;
  const getPageDisplay = () => {
    if (isValidTabName()) {
      return (
        <div>
          {pageData
            && <SubheaderComponent {...props} route={route} params={params}>
              {getSubheaderChildComponent(pageName)}
            </SubheaderComponent>
          }
        </div>
      );
    }
    return <Display404NotFound display />;
  };
  return (
    <div>{getPageDisplay()}</div>
  );
};

export default injectIntl(StarSafetySystemRouterComponent);
