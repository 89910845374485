import { capitalizeFirstLetter } from "./string";

/**
 * Gets the formatted representation of the date, displaying the month and day,
 * with an option to include the year if the provided date is not from the current year.
 * 
 * @param {Date|string|number} date - The date to be formatted (Date object, string, or timestamp).
 * @returns {string} - The formatted representation of the date (month and day, with or without the year).
 */
export const getFormattedMonthDayOrMonthDayYear = (date) => {
  const dateObject = new Date(date);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const dateYear = dateObject.getFullYear();
  const month = capitalizeFirstLetter(dateObject.toLocaleString("en-US", { month: "long" }))
  const day = dateObject.getDate();
  return currentYear === dateYear
    ? `${month} ${day}`
    : `${month} ${day}, ${dateYear}`;
}