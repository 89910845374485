import React, { useState, useEffect, useContext } from 'react';
import RedLine from '../../components/atoms/RedLine.js';
import applyCustomMarkdown from '../utils/applyCustomMarkdown.js';
import { getFormattedName } from '../utils/vehicleName.js';
import AppSettings from '../../appsettings.js';
import ChipGroup from '../../components/molecules/ChipGroup';
import NoResults from './featureLookupNoResults';
import SearchFeedback from '../../components/molecules/search-feedback';
import ScrollButton from '../../components/molecules/scrollButton.js';
import '../../styles/css/featureLookupResults.css';
import { Accordion } from '../../components/molecules/Accordion';
import ModelResultCard from './featureLookupModelResultCard';
import { FilterMenu } from '../filterMenu/filterMenu.component';
import getNavigation from '../../services/navigationService';
import { ClipLoader } from 'react-spinners';
import {
    featureLookupResultsPageLoad,
    featureLookupRemoveFeatureClick,
    featureLookupResultsCategorySelect,
    featureLookupResultsVehicleExpandCollapseClick,
    featureLookupResultsAddFilterFeatureClick,
    featureLookupResultsBackClick
} from './featureLookup.analytics';
import { PopupTooltipContext } from '../../components/contexts/popupTooltipContext';
import MobileBackButton from '../../components/molecules/MobileBackButton';
import { getVehicleCategories } from '../VPRouter/VPUtils.js';

const FeatureLookupResults = (props) => {
    const [modelList, setModelList] = useState([]);
    const [title, setTitle] = useState('Feature Lookup');
    const [description, setDescription] = useState('');
    const [noResultsMessage, setNoResultsMessage] = useState('');
    const [filters, setFilters] = useState([]);
    const [visible, setVisible] = useState(false);

    const filePath = AppSettings.AWSImgRepo.resources + 'en/';

    const [activeFilter, setActiveFilter] = useState([]);
    const [modelYearFeatureData, setModelYearFeatureData] = useState([]);
    const [filteredModelFeatureData, setFilteredModelFeatureData] = useState([]);
    const [resultsLoaded, setResultsLoaded] = useState(false);
    
    const { visiblePopupTooltip, setVisiblePopupTooltip, setCurrentPopupTooltip, checkCurrentTooltipMatch } = useContext(PopupTooltipContext);

    useEffect(async ()=>{
        let filters = await getVehicleCategories();
        setFilters(filters);
        setActiveFilter(filters[0]);
    },[]);

    useEffect(() => {
        props.analyticsData && featureLookupResultsPageLoad(props.analyticsData.pageLoadAnalytics, props.analyticsKeyword);
    }, [props.analyticsData]);

    useEffect(() => {
        setTitle(props.resultsPageData?.title);
        setDescription(props.resultsPageData?.description);
        setNoResultsMessage(props.resultsPageData?.noResultsMessage);
    }, [props.resultsPageData]);

    useEffect(() => {
        if (activeFilter.filter && modelYearFeatureData) {
            const filteredData = modelYearFeatureData.filter(i => activeFilter.filter.label == "All" || activeFilter.models.includes(i.model));
            setFilteredModelFeatureData(filteredData);
        }
    }, [activeFilter, modelYearFeatureData]);

    useEffect(() => {
        if(props.selectedFeatures?.length == 0) {
            props.setPage('Overview')
        }
        if (filters.length && props.selectedFeatures) {
            const formattedFeatureData = getFeatureAvailabilityData();

            setModelYearFeatureData(formattedFeatureData);
            setTimeout(() => {  setResultsLoaded(true); }, 500);

            let modelListMap = formattedFeatureData.map(i => (
                {
                    model: i.model,
                    year: Math.max(...i.yearFeatures.map(y => y.year))
                }
            ));
            setModelList(modelListMap);
        }

    }, [props.selectedFeatures, filters]);
    
    const getFeatureAvailabilityData = () => {
        const uniqueModelNames = [...new Set(props.compatibleModels.map(m => m.model))];

        const rearrangedData = uniqueModelNames.map(m => {      
            //for each year
            let yearFeatures = props.compatibleModels
            .filter(i => i.model.toLowerCase() == m.toLowerCase())
            .map(i => i.year)
            .map(y => {
                //go thru all features
                let matches = new Set();

                props.selectedFeatures.forEach(f => f.modelYears
                    .filter(i => i.model.toLowerCase() == m.toLowerCase() && i.year == y)
                    .map(i => matches.add(
                        {
                            featureName: f.featureName,
                            trims: i.grades
                        }
                    )));

                const allFeaturesAvailable = filterAvailability([...matches]);

                //see if year and model found
                //return feature and trims
                return {
                    year: y,
                    features: allFeaturesAvailable
                }})
            .filter(y => y.features.length > 0);

            const modelCapitalization = filters.map(i => i.models).flat().find(i => i.toLowerCase() == m);

            return {
                model: modelCapitalization ? modelCapitalization : m,
                yearFeatures: yearFeatures
            }
        }).filter(m => m.yearFeatures.length > 0);

        return [...rearrangedData];
    }

    function onBackButtonClick() {
        props.setPage('Overview');
        featureLookupResultsBackClick(props.analyticsData.clickAnalytics);
    }

    const updateModelYearSelection = (model, yearSelection) => {
        setModelList(modelList.map(i => i.model == model ? {...i, year: yearSelection} : i));
    }

    const filterAvailability = (yearFeatures) => {
        // group by order key
        // sub object of feature and availability
        let trimsList = [];
        let uniqueData = {}
        yearFeatures.forEach(f => {
            f.trims.forEach(t => {
                const { availability, ...keep } = {...t}

                !uniqueData[keep.order] && ( uniqueData[keep.order] = [] );

                uniqueData[keep.order].push(
                    {
                        order: keep.order,
                        featureName: f.featureName,
                        availability: availability,
                    }
                )
                !trimsList.find(i => i.order == t.order) && trimsList.push({ ...keep })
            })
        });
        let combinedList = trimsList.map(t => (
            {
                ...t,
                features: uniqueData[t.order]
            }
        )).filter(t => t.features.every(f => f.availability == "S" || f.availability == "O" || f.availability == "P" ));
        return combinedList;
    }

    const buildFeatureResultRows = () => {
        return filteredModelFeatureData
            .map(i => {
                let activeYear = modelList.find(e => e.model == i.model)?.year;
                let modelData = i.yearFeatures.find(y => y.year == activeYear)?.features;
                let yearOptions = i.yearFeatures.map(y => y.year);
                let featureNames = props.selectedFeatures.map(f => f.featureName);
                return (
                    {
                        title: `${activeYear} ${getFormattedName(i.model)}`,
                        body: <ModelResultCard 
                                vehicleModelNameYear = {`${activeYear} ${i.model}`}
                                activeYear = {activeYear}
                                modelData = {modelData}
                                yearOptions = {yearOptions}
                                modelName = {i.model}
                                features = {featureNames}
                                updateActiveYear = {yearSelection => updateModelYearSelection(i.model, yearSelection)}
                                setPage = {props.setPage}
                                visiblePopupTooltip = {visiblePopupTooltip}
                                setVisiblePopupTooltip = {setVisiblePopupTooltip}
                                setCurrentPopupTooltip = {setCurrentPopupTooltip}
                                checkCurrentTooltipMatch = {checkCurrentTooltipMatch}
                                analyticsData = {props.analyticsData}
                                visible = {visible}
                            />,
                        key: i.model,
                    }
                )
            });
    };

    const vehicleExpandCollapseClick = (vehicle, isExpand) => {
        const label = isExpand ? props.analyticsData.clickAnalytics?.results.vehicleExpandLabel : props.analyticsData.clickAnalytics?.results.vehicleCollapseLabel;
        featureLookupResultsVehicleExpandCollapseClick(props.analyticsData.clickAnalytics, vehicle, label);
    }

    const renderBackButton = () => {
        if (window.ReactNativeWebView) {
            return <MobileBackButton containerStyle={{
                position: 'initial',
                height: '20px'
            }} onClick={onBackButtonClick} />;
        }
        return <a onClick={onBackButtonClick}><img style={{ height: '11px' }} src={filePath + 'red_left_arrow.png'} />Back</a>;
    }

    return (
        <div className='featureLookupResults'>
            <div className='featureContainer'>
                <div className="backButton">
                    {renderBackButton()}
                </div>
                <div className='resultsHeader'>
                    <div className='title'>{applyCustomMarkdown(title, 'bold')}</div>
                    <RedLine />
                    <div className='description'>{applyCustomMarkdown(description, 'bold')}</div>
                </div>
                <div className='featureDisplay'>
                    <div className='featureTitle'>
                        Feature Results
                    </div>
                    <ChipGroup
                        activeChips={props.selectedFeatures.map(f => f.featureName)}
                        deleteChip={props.deleteSelectedFeature}
                        totalPlaceholderChips={props.maxSelections}
                        placeholderText={"Add Feature"}
                        placeholderClick={() => props.setPage('Overview')}
                        groupClassName={"featureLookupResults-chipGroup"}
                        deleteChipAnalyticsClick={feature => featureLookupRemoveFeatureClick(props.analyticsData.clickAnalytics, props.analyticsData.clickAnalytics?.results.category, feature)}
                        placeholderClickAnalytics={() => featureLookupResultsAddFilterFeatureClick(props.analyticsData.clickAnalytics)}
                    />
                </div>
                {(activeFilter && filters) ?
                    <FilterMenu
                        active={activeFilter.filter.id}
                        filters={filters.map(f => f.filter)}
                        filterClick={(e, i) => {
                            featureLookupResultsCategorySelect(props.analyticsData.clickAnalytics, i.label);
                            setActiveFilter(filters.find(f => f.filter.label == i.label));
                        }}
                        className="featureLookupResultsFilter"
                    />
                    :
                    <div className='filter-menu-placeholder'>
                        <span className="loading-message">LOADING VEHICLES...</span>
                    </div>
                }
                {!resultsLoaded ? 
                    <div className="resultsSpinner">
                        <ClipLoader color={"#EB0A1E"} loading={true} size={50} />
                        <div className='p'>Loading</div>
                    </div>
                : filteredModelFeatureData.length == 0 ? // Placeholder variable until results working/merged in.
                    <>
                        <NoResults
                            message={noResultsMessage}
                        />
                        <SearchFeedback
                            location={'Feature Lookup > Feature Results'}
                            featureOne={props.selectedFeatures[0]?.featureName}
                            featureTwo={props.selectedFeatures[1]?.featureName}
                            featureThree={props.selectedFeatures[2]?.featureName}
                        />
                    </>
                    :
                    <Accordion
                        rows={buildFeatureResultRows()}
                        className="featureLookupResults-accordion"
                        termAnalyticsClick={(v, expand) => vehicleExpandCollapseClick(v, expand)}
                        expandCollapseCallback={()=>setVisible(!visible)}
                    />
                }

            </div>
            <div className="featureLookupScroll"><ScrollButton imagePath={AppSettings.AWSImgRepo.resources + "en/"} /></div>
        </div>
    );
}


export default FeatureLookupResults