import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import '../../../styles/css/searchBar.css';
import '../../../styles/css/searchTSS.css';
import { generateResultHeader } from '../resultHelperFunctions.js';
export class TssSearchItemComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipVisibility: false,
    };
    this.ref = React.createRef();
  }
  
  static generateFeaturesList(value) {
    const featuresList = value.features.map((feature, index) => (
        <div className="content-QA-tss-list-item"key={index} > {feature.title}</div>
    ));

    return featuresList;
  }

  generateTssItem() {
    const { item } = this.props;
    const tssView = item.values.map((value, index) => {
      const newindex = `${index}0`;
      const features = TssSearchItemComponent.generateFeaturesList(value);
      const standardOn = value.standardOn && value.standardOn.length > 0 ? (<div style = {{paddingBottom: "10px"}}><b>Standard on:</b> {value.standardOn.join(', ')}</div>) : null;
      return (
        <div key={newindex}>
            <div className="QA-tsstss-content">
                <ul className="content-QA-tss-title">{features}</ul>
                <div className="content-QA-tss-list">{standardOn}</div>
            </div>
        </div>
      );
    });

    const header = item.values[0] ? <div className="QA-tss-header">Toyota Safety Sense {item.values[0].packageName}</div> : null;
    const resultHeader = generateResultHeader(item, this.state);
    
    return (
        <div className="QA-tss-content">
        {header}
        <div className="QA-tss-output">
          {tssView}
        </div>
      </div>
    );
  }

  render() {
    return (this.generateTssItem());
  }
}

TssSearchItemComponent.propTypes = {
  item: PropTypes.object,
};

export default injectIntl(TssSearchItemComponent);
