import { useEffect, useRef, useState } from "react";
import * as _ from "lodash";
import { isMobileView } from "../../utils/getScreenSize";
import applyMarkdown from "../../utils/applyMarkdown";

import * as analyticFunctions from "./modelOverviewV2Sections.analytics";

import ArrowDownBlack from "../../../resources/images/arrow-down-black.svg";
import "../../../styles/css/model-overview-v2/technology.css";
import DisclaimersComponent from "../../disclaimers/disclaimers.component";

const Technology = (props) => {
  const mobileView = isMobileView(window.innerWidth);
  const [expandedItems, setExpandedItems] = useState([]);
  const disclaimerComponentRef = useRef();
  const titlesRef = useRef([]);

  const toggleShowMore = (index) => {
    if (expandedItems.includes(index)) {
      setExpandedItems((prevState) => {
        const newState = [...prevState];
        const indexToRemove = newState.findIndex((item) => item === index);
        newState.splice(indexToRemove, 1);
        return newState;
      });
    } else {
      setExpandedItems((prevState) => {
        const newState = [...prevState];
        newState.push(index);
        return newState;
      });
    }
  };

  const getMaxRows = () => {
    return Math.max(...props.features.map((f) => f.length));
  };

  const getGridView = () => {
    return (
      <div className="tech-columns" style={{ "--rows": getMaxRows() + 2 }}>
        {props.features.map((f, i) => (
          <>
            <div className="tech-column-image">
              {f[0].columnIcon && props.imagePath && (
                <img src={encodeURI(props.imagePath + f[0].columnIcon)} />
              )}
            </div>
            <div className="tech-column-title">
              {applyMarkdown(f[0].columnName, disclaimerComponentRef.current)}
            </div>
            {f.map((item, j) => (
              <div className={`tech-item index${j}`}>
                {item.iconLink ? (
                  <a
                    href={item.iconLink}
                    onClick={() => {
                      // ANALYTICS
                      analyticFunctions.technologyLinkClick({
                        sectionName: item?.columnName,
                        linkName: item?.iconName,
                        modelName: props.displayModelName,
                        modelYear: props.year,
                      });
                    }}
                  >
                    {applyMarkdown(
                      item.iconName,
                      disclaimerComponentRef.current
                    )}
                  </a>
                ) : (
                  applyMarkdown(item.iconName, disclaimerComponentRef.current)
                )}
              </div>
            ))}
            {f.length < getMaxRows() &&
              [...Array(getMaxRows() - f.length).keys()].map((i) => (
                <div className="empty-cell"></div>
              ))}
          </>
        ))}
      </div>
    );
  };

  const getMobileView = () => {
    return (
      <div className="technology-wrapper">
        <div className="technology-container">
          {props.features.map((feature, index) => {
            const shouldShowMoreButton =
              props.features[index].length > 3 && mobileView;
            const expanded = expandedItems.includes(index);
            return (
              <div className="technology-column" key={feature[0].columnName}>
                {feature[0].columnIcon && props.imagePath && (
                  <img src={encodeURI(props.imagePath + feature[0].columnIcon)} />
                )}
                <div
                  ref={(el) => (titlesRef.current[index] = el)}
                  className="technology-column-title"
                  style={{height: "auto"}}
                >
                  {applyMarkdown(
                    feature[0].columnName,
                    disclaimerComponentRef.current
                  )}
                </div>
                <div className={`technology-items`}>
                  {feature.map((item, i) => {
                    return (
                      <div
                        key={item.iconName}
                        className={`technology-item${
                          expanded ? " expanded" : ""
                        }`}
                        style={{height: "auto"}}
                      >
                        {item.iconLink ? (
                          <a
                            href={item.iconLink}
                            onClick={() => {
                              // ANALYTICS
                              analyticFunctions.technologyLinkClick({
                                sectionName: item?.columnName,
                                linkName: item?.iconName,
                                modelName: props.displayModelName,
                                modelYear: props.year
                              });
                            }}
                          >
                            {applyMarkdown(
                              item.iconName,
                              disclaimerComponentRef.current
                            )}
                          </a>
                        ) : (
                          applyMarkdown(
                            item.iconName,
                            disclaimerComponentRef.current
                          )
                        )}
                      </div>
                    );
                  })}
                </div>
                {shouldShowMoreButton && (
                  <img
                    className={`show-more-${expanded ? "up" : "down"}`}
                    src={ArrowDownBlack}
                    onClick={() => toggleShowMore(index)}
                  />
                )}
              </div>
            );
          })}
        </div>
    </div>
    )
  };

  return (
    <>
      {mobileView ? getMobileView() : getGridView()}
      <DisclaimersComponent
        template={`${props.year}_${props.model}_overview`}
        ref={disclaimerComponentRef}
        addBaseMSRPDisclaimer={true}
      />
    </>
  );
};

export default Technology;
