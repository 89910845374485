import "../../../styles/css/engageXP/tournaments/tournamentPrizes.css";
import { useState, useEffect } from "react";

const Prizes = ({ tournamentsData, device, imgPath }) => {
    const [showTabletImg, setShowTabletImg] = useState();
    const { desktopImage, tabletImage, mobileImage } = tournamentsData.prizesSection || {};
    const prizesImage = `${imgPath}${showTabletImg ? tabletImage : device === "mobile" ? mobileImage : desktopImage}`;

    const handleResize = () => {
        setShowTabletImg(window.innerWidth <= 1315 && window.innerWidth > 700);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div id="prizes" className="tournament-prizes">
            <div style={{ backgroundImage: "url(" + encodeURI(`${prizesImage})`) }} className="prizes-image">
                <div className="prizes-title">Prizes</div>
            </div>
        </div>
    );
};

export default Prizes;
