import PropTypes from 'prop-types';
import React from 'react';
import AppSettings from '../../../appsettings';
import '../../../styles/css/contactUs/contactUsForm/contactUsForm.css';
import applyMarkdown from '../../utils/applyMarkdown';
import ContactUsFormValues from './contactUsFormValues';

const ContactUsForm = (props) => {
    return (
        <React.Fragment>
            <div className='contactUsFormDiv' style={{ backgroundImage: `url("${AppSettings.AWSImgRepo.contactUs + props.image}")` }} >
                <div className='contactUsForm'>
                    <div className='contactUsFormHeader'>{props.header}</div>
                    <div className='contactUsFormDescription'>{applyMarkdown(props.description)}</div>
                    <ContactUsFormValues
                        analytics={props.analytics}
                    />
                </div>
            </div>
        </React.Fragment>
    )
};

ContactUsForm.propTypes = {
    analytics: PropTypes.object,
    header: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string
};

export default ContactUsForm;