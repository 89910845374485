import 'whatwg-fetch';
import httpOption from './httpOption';
import AppSettings from '../appsettings';

export function getComments(id, startKey) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/articles/${id}/comments`;
  const options = httpOption.GET();
  if (startKey) {
    options.headers.Timestamp = startKey.createdTimestamp;
  }

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return resolve(response.json());
        }

        return Promise.reject(response);
      });
  });
}

export function getComment(id, timestamp) {
  const encodedId = encodeURI(id);
  const encodedTimestamp = encodeURI(timestamp);

  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/articles/${encodedId}/comments/${encodedTimestamp}`;
  const options = httpOption.GET();

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return resolve(response.json());
        }

        return Promise.reject(response);
      });
  });
}

export function getCommentsByUser() {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/articles/getUserComments`;
  const options = httpOption.GET();

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return resolve(response.json());
        }

        return Promise.reject(response);
      });
  });
}

export function postComment(id, comment) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/articles/${id}/comments/postComment`;
  const options = httpOption.POST();
  options.body = JSON.stringify({
    comment,
  });

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return resolve(response.json());
        }
        return Promise.reject(response);
      });
  });
}

export function putComment(id, comment) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/articles/${id}/comments/putComment`;
  const options = httpOption.PUT();
  options.body = JSON.stringify({
    comment,
  });

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return resolve(response.json());
        }
        return Promise.reject(response);
      });
  });
}

export function postReply(id, timestamp, reply) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/articles/${id}/comments/replies`;
  const options = httpOption.POST();
  options.body = JSON.stringify({
    articleId: id,
    timestamp,
    reply,
  });

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return resolve(response.json());
        }
        return Promise.reject(response);
      });
  });
}

export function markRepliesRead(comment) {
  const id = comment.articleId;
  const replies = [...comment.reply];

  if (replies && replies.length > 0) {
    replies.forEach(reply => {
      reply.isUnread = false;
    });
  }

  const updatedComment = {
    ...comment,
    reply: replies,
  };

  return putComment(id, updatedComment);
}

export function deleteComment(id, timestamp) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/articles/${id}/comments/delete`;
  const options = httpOption.POST();
  options.body = JSON.stringify({
    articleId: id,
    createdTimestamp: timestamp,
  });

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return resolve(response.json());
        }
        return Promise.reject(response);
      });
  });
}
