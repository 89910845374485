import PropTypes from 'prop-types';
import React, { useState, cloneElement } from 'react';
import PdfDownloader from './pdfDownloader';
import '../../styles/css/dropdownSelectorCard.css';
import AppSettings from '../../appsettings';

const dropdownSelectorCard = props => {

    const [selectedContent, setSelectedContent] = useState(null);

    const getReturnedContent = (dropdownData) => {
        setSelectedContent(dropdownData);
    }

    const clearSelectedContent = () => {
        setSelectedContent(null);
    }

    return (
        <div className="wide-card">
            <div className="cardTitle">
                {props.title}
            </div>

            <div className="cardDescription">
                {props.description}
            </div>

            {cloneElement(props.dropdown, { parentCallback: getReturnedContent })}

            <div className="bottomDivider" />

            <div className="bottomContent">
                {selectedContent ?
                    cloneElement(props.contentDisplay, { contentSelected: selectedContent, setModalContent: props.setModalContent, modalContentComponent: props.modalContentComponent, clickFunctions: props.clickFunctions, openModal: props.openModal, parentCallback: clearSelectedContent })
                    :
                    <PdfDownloader pdfs={props.pdfs} message={props.pdfMessage} lineupSelectAnalytics={props.lineupSelectAnalytics} chartDownloadAnalytics={props.chartDownloadAnalytics} />
                }
            </div>
        </div>
    );
};

dropdownSelectorCard.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    dropdownLoad: PropTypes.func,
    contentLoad: PropTypes.func,
    contentDisplay: PropTypes.element,
    noContentDisplay: PropTypes.element
};

export default dropdownSelectorCard;
