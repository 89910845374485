import React from 'react';
import { FormattedMessage } from 'react-intl';
import MediaQuery from 'react-responsive';
import AppSettings from '../../appsettings.js';
import '../../styles/css/vehicleOverview.css';
import ImageScale from '../imageScale/imageScale.component';

export function Header(component) {
  const columns = component.state.header.textPlacement && component.state.header.textPlacement.columns;
  const alignment = component.state.header.textPlacement && component.state.header.textPlacement.textAlignment;
  const backgroundImage = AppSettings.AWSImgRepo.vehicle + component.state.s3Path + component.state.header.imageFilename;
  const mobileImage = AppSettings.AWSImgRepo.vehicle + component.state.s3Path + component.state.header.mobileImageFilename;

  const breakpoints = {
    desktop: '(min-width: 1316px)',
    mobile: '(max-width: 1315px)',
  };

  const headerTitleStyle = {
    textAlign: alignment,
    position: 'absolute',
    top: '15px',
  };

  return (
        <div>
            <MediaQuery query={breakpoints.desktop}>
                <div className="small-12 large-12 columns row">
                <ImageScale cName={'header-image'} url={backgroundImage}>
                    <div className="header-content" style={headerTitleStyle}>
                        <div className={columns}>
                            <div className="header-title">
                                {component.state.header.headline}
                            </div>
                            <div className="redline" />
                            <div className="subheadline">
                                {component.state.header.subheadline}
                            </div>
                            <div className="starting-msrp">
                                <div className="msrp-rate">{component.state.header.msrp ? `$${component.state.header.msrp}` : ''}</div>
                                <FormattedMessage className="msrp-text" id={component.state.header.msrp ? 'vehicleOverview.startingMSRP' : 'vehicleOverview.msrp-text'}/>
                                <sup className="disclaimer-link" onClick={() => component.disclaimer.onOpenModal()}>&dagger;</sup>
                            </div>
                        </div>
                    </div>
                    </ImageScale>
                </div>
            </MediaQuery>

            <MediaQuery query={breakpoints.mobile}>
                <div className="small-12 large-12 columns row">
                <ImageScale cName={'header-image'} url={mobileImage}>
                    <div className="header-content" style={headerTitleStyle}>
                        <div className={columns}>
                            <div className="header-title">
                                {component.state.header.headline}
                            </div>
                            <div className="redline" />
                            <div className="subheadline">
                                {component.state.header.subheadline}
                            </div>
                            <div className="starting-msrp">
                                <div className="msrp-rate">{component.state.header.msrp ? `$${component.state.header.msrp}` : ''}</div>
                                <FormattedMessage className="msrp-text" id={component.state.header.msrp ? 'vehicleOverview.startingMSRP' : 'vehicleOverview.msrp-text'}/>
                                <sup className="disclaimer-link" onClick={() => component.disclaimer.onOpenModal()}>&dagger;</sup>
                            </div>
                        </div>
                    </div>
                </ImageScale>
                </div>
            </MediaQuery>
        </div>
  );
}
