import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import EngageXPTournamentPage from "./engageXPTournamentPage";
import { checkIfTournamentIsActive, checkIfUserIsEligible, getTournamentPointsForMission } from "./utils";
import { xpTournamentClick } from "./engageXPTournamentPage.analytics";
import EngageXPMissionDetail from "./engageXPMissionDetail";
import { getTournamentTransactionsByMissions } from "../../services/nitroService";

const EngageXPTournaments = ({
    tournamentsData,
    isUserEligible,
    setIsUserEligible,
    userEligibilityUpdated,
    setUserEligibilityUpdated,
    getColoredBadgeInfo,
    getBlurredBadgeText,
    pageData,
    shouldFetchMissionDetails,
    setShouldFetchMissionDetails,
    missionId,
    missions,
    analyticsData,
}) => {
    const history = useHistory();
    const isTournamentActive = checkIfTournamentIsActive(tournamentsData);
    const [selectedRound, setSelectedRound] = useState(null);
    const [tournamentTransactionsFetched, setTournamentTransactionsFetched] = useState(false);
    const [userAverageScore, setUserAverageScore] = useState(0);
    const [userTournamentPoints, setUserTournamentPoints] = useState(0);

    const handleRoundClick = (round) => {
        xpTournamentClick({
            category: "Tournament",
            label: round.title,
            content_title: round.title,
        });
        setSelectedRound(round);
    };

    const handleBackClick = () => {
        xpTournamentClick({
            category: "Tournament",
            label: "Back button​",
        });
        setSelectedRound(null);
    };

    const updateUserStats = async () => {
        const rounds = tournamentsData?.tournamentSection?.rounds;
        if (!rounds?.length) return;

        setTournamentTransactionsFetched(true);

        const uniqueMissionIDs = [...new Set(rounds.flatMap((round) => round.missions?.map((mission) => mission.id)))];
        try {
            const updatedStats = await getTournamentTransactionsByMissions(uniqueMissionIDs, tournamentsData);
            setUserAverageScore(updatedStats?.averageScore ?? 0);
            setUserTournamentPoints(updatedStats?.tournamentPoints ?? 0);
            setIsUserEligible(checkIfUserIsEligible(tournamentsData, updatedStats?.missionScores));
            setUserEligibilityUpdated(true);
        } catch (error) {
            console.error("Error fetching tournament transactions:", error);
        }
    };

    useEffect(() => {
        if (!tournamentTransactionsFetched) {
            updateUserStats();
        }
    }, []);

    if (isTournamentActive) {
        if (missionId) {
            const tournamentPointReward = getTournamentPointsForMission(tournamentsData, missionId);
            return (
                <EngageXPMissionDetail
                    pageData={pageData}
                    shouldFetchMissionDetails={shouldFetchMissionDetails}
                    setShouldFetchMissionDetails={setShouldFetchMissionDetails}
                    missionId={missionId}
                    missions={missions}
                    getBlurredBadgeText={getBlurredBadgeText}
                    getColoredBadgeInfo={getColoredBadgeInfo}
                    analyticsData={analyticsData}
                    proPointReward={tournamentPointReward}
                    tournament
                    isUserEligible={isUserEligible}
                    updateUserStats={updateUserStats}
                />
            );
        } else {
            return (
                <EngageXPTournamentPage
                    tournamentsData={tournamentsData}
                    isUserEligible={isUserEligible}
                    setIsUserEligible={setIsUserEligible}
                    userEligibilityUpdated={userEligibilityUpdated}
                    setUserEligibilityUpdated={setUserEligibilityUpdated}
                    getColoredBadgeInfo={getColoredBadgeInfo}
                    getBlurredBadgeText={getBlurredBadgeText}
                    missions={missions}
                    selectedRound={selectedRound}
                    handleRoundClick={handleRoundClick}
                    handleBackClick={handleBackClick}
                    userAverageScore={userAverageScore}
                    userTournamentPoints={userTournamentPoints}
                />
            );
        }
    } else {
        history.push("/engage-xp");
        return null;
    }
};

export default EngageXPTournaments;
