import 'whatwg-fetch';
import httpOption from './httpOption';
import { postViewPageAction } from './nitroService';
import AppSettings from '../appsettings';


export function postUserActivityRecord(link, isVideoClick, articleTitle) {
  if (!isVideoClick) {
    postViewPageAction(window.location.href);
  }
  const params = {};
  const spinId = localStorage.getItem('spin_id');
  params.userId = spinId;
  params.isVideoClick = isVideoClick;
  params.articleTitle = articleTitle;
  if (!link) {
    const path = window.location.pathname; // for internal links
    params.activityId = path;
  } else {
    params.activityId = link; // for external links
  }

  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/userActivity/postUserActivityRecord`;
  const options = httpOption.POST();
  options.body = JSON.stringify(params);
  return fetch(url, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(response);
    });
}

export function postUserActivityToRDS(link) {
  const spinId = localStorage.getItem('spin_id');
  const params = {
    user_id: spinId,
    url: encodeURI(link),
  };
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/userActivity/postUserActivityV2`;
  const options = httpOption.POST();
  options.body = JSON.stringify(params);
  return fetch(url, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(response);
    });
};

export function getUserActivityRecord(activityData) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/userActivity/getUserActivityRecord`;
  const options = httpOption.GET();
  options.body = activityData;

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          resolve(response.json());
        } else {
          reject(reject);
        }
      });
  });
}

export function getUserActivityRecords(limit, lastEvaluatedKey) {

  let url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/userActivity`;

  if (limit) {
    url = `${url}&limit=${limit}`;
  }

  const options = httpOption.GET();

  if (lastEvaluatedKey) {
    options.headers.LastEvaluatedKey = JSON.stringify(lastEvaluatedKey);
  }

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return resolve(response.json());
        }
        return reject(response);
      });
  });
}
