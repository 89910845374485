import React, { useState, useEffect, useRef } from "react";
import "../../../styles/css/model-overview-v2/new-section.css";
import applyMarkdown from "../../utils/applyMarkdown";
import DisclaimersComponent from "../../disclaimers/disclaimers.component";
import Skeleton from "react-loading-skeleton";

const NewSection = (props) => {
  const [title, setTitle] = useState(null);
  const [summary, setSummary] = useState(null);
  const disclaimerComponentRef = useRef();

  useEffect(() => {
    props.overviewData?.quickFacts && setTitle(props.overviewData.quickFacts[0].title);
    props.overviewData?.quickFacts && setSummary(props.overviewData.quickFacts[0].text);
  }, [props.overviewData]);

  return (
    <div className="new-section-wrapper">
      <div className="new-section">
        <div className="new-section-title">
          {title ? (
            applyMarkdown(title, disclaimerComponentRef.current)
          ) : (
            <Skeleton width={200} />
          )}{" "}
        </div>
        <div className="new-section-summary">
          {summary ? (
            applyMarkdown(summary, disclaimerComponentRef.current)
          ) : (
            <Skeleton width={245} count={2.5} />
          )}
        </div>
      </div>
      <DisclaimersComponent
        template={`${props.year}_${props.model}_overview`}
        ref={disclaimerComponentRef}
        addBaseMSRPDisclaimer={true}
      />
    </div>
  );
};

export default NewSection;
