import React from 'react';
import { FormattedMessage } from 'react-intl';
import thumbsUp from '../../../../resources/images/value-story.png';
import AppSettings from '../../../../appsettings.js';
import applyMarkdown from '../../../utils/applyMarkdown';


export default function ValueStory(props) {
  return (
        <div className="content">
            <div className="image-container">
                <img style={{ maxHeight: 400}} alt={props.sampleImage} className="vs-image" data-object-fit="cover" src={AppSettings.AWSImgRepo.edge + props.s3Path + props.currentEdge.edgeContent.valueStory.image} />
            </div>
            <div className="vs-textbox">
                <div className="vs-box">
                    <div className="vs-firstline">
                        <img alt={props.sampleImage} className="vs-icon" src={thumbsUp} />
                        <div className="vs-title">
                            |&nbsp;<span className="value">
                                <FormattedMessage id="caHeadToHead.valueStoryBoldHeader" defaultMessage="Value " />
                            </span>
                            <FormattedMessage id="caHeadToHead.valueStoryHeader" defaultMessage="Story" />
                        </div>
                    </div>
                </div>
                <div className="vs-text">
                    <div>{applyMarkdown(props.currentEdge.edgeContent.valueStory.valueStory, props.disclaimer)}</div>
                </div>
            </div>
        </div>
  );
}
