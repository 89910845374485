import React, { useContext, useEffect, useRef } from 'react';
import AppSettings from "../../../appsettings";
import { NitroContext } from '../../../components/contexts/nitroContext';
import { answerSelectionClick } from './triviaModal.analytics';
import '../../../styles/css/engageXP/triviaModal.css';

const imagePath = AppSettings.AWSImgRepo.nitroOverview;

const MultipleChoice = ({
    result,
    setSelectedAnswers,
    setRemainingAnswers,
    selectedAnswers,
    remainingAnswers,
    options,
    analyticsData
}) => {
    const activeRef = useRef();
    const { nitroLoadingState } = useContext(NitroContext);

    useEffect(() => {
        activeRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
    }, [result])


    const clickOption = (option, selected) => {
        if (selected) {
            // if option is already selected, unselect option
            setSelectedAnswers(selectedAnswers.filter(a => a !== option))
            setRemainingAnswers(remainingAnswers + 1)
        } else {
            if (remainingAnswers > 0) {
                // if there are remaining answers to be selected, select option
                setSelectedAnswers([...selectedAnswers, option])
                setRemainingAnswers(remainingAnswers - 1)
            } else {
                // if there are no remaining answers to be selected,
                // remove earliest selected option and add newly selected option
                const newSelections = [...selectedAnswers];
                newSelections.shift();
                setSelectedAnswers([...newSelections, option])
            }
        }
    }

    const handleOptionClick = (optionText, isSelected) => {
        if (nitroLoadingState) return; // Return early if loading
    
        clickOption(optionText, isSelected);
    
        if (analyticsData) {
            const clickAnalytics = analyticsData.clickAnalytics;
            answerSelectionClick(clickAnalytics, optionText); 
        }
    };
    

    const getMultipleChoiceOptions = () => {
        return options?.map(option => {
            const selected = selectedAnswers.includes(option.text);
            return (
                <div 
                    key={option.text} 
                    className={`option ${selected ? "selected" : ""} ${nitroLoadingState ? 'not-clickable' : ''}`}
                    onClick={() => !nitroLoadingState && handleOptionClick(option.text, selected)}
                >
                    <div className="option-text">{option.text}</div>
                </div>
            );
        });
    };
    

    const getMultipleChoiceResult = () => {
        const firstResult = selectedAnswers?.[0];
        return (
            options?.map(option => {
                const selected = selectedAnswers?.includes(option.text);
                const correct = result?.correctAnswers?.includes(option.id);
                return (
                    <div key={option.text} className={`option ${selected && "selected"} ${correct ? "correct" : "incorrect"} ${selected && (correct ? "selected-correct" : "selected-incorrect")}`}
                        ref={firstResult === option.text ? activeRef : null}
                    >
                        <div className="option-text">{option.text}</div>
                        {(selected || correct) && (<img className="option-icon" src={imagePath + (correct ? "correct.svg" : "incorrect.svg")} />)}
                    </div>
                )
            })
        )
    }

    return (
        <div className={`multiple-choice ${result && "result"}`}>
            {result ? getMultipleChoiceResult() : getMultipleChoiceOptions()}
        </div>
    )
}

export default MultipleChoice;
