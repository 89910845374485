import React, { useEffect } from "react";
import TournamentRounds from "./tournamentRounds";
import TournamentRoundDetails from "../tournamentSections/tournamentRoundDetails";
import TournamentDashboard from "./dashboard";
import "../../../styles/scss/engageXP/tournaments/tournament.scss";

const Tournament = ({
    tournamentsData,
    currentRound,
    deviceType,
    imgPath,
    getColoredBadgeInfo,
    getBlurredBadgeText,
    missions,
    myRank,
    userAverageScore,
    userTournamentPoints,
    userTournamentXp,
    setUserTournamentXp,
    selectedRound,
    handleRoundClick,
    handleBackClick,
    isUserEligible,
    userEligibilityUpdated,
}) => {

    const scrollToTournament = () => {
        const element = document.getElementById("tournament");
        if (element) {
            const menuHeight = document.querySelector(".notification-nav-container").offsetHeight;
            const elementPosition = element.offsetTop - menuHeight;
            setTimeout(() => {
                window.scrollTo({
                    behavior: "smooth",
                    top: elementPosition,
                });
            }, 500);
        }
    };

    useEffect(() => {
        if (selectedRound) {
            scrollToTournament();
        }
    }, []);

    return (
        <div id="tournament" className="tournament-container">
            <div className="tournament-content">
                <span className="tournament-title">Tournament</span>
                {selectedRound ? (
                    <TournamentRoundDetails
                        deviceType={deviceType}
                        selectedRound={selectedRound}
                        imgPath={imgPath}
                        handleBackClick={handleBackClick}
                        getColoredBadgeInfo={getColoredBadgeInfo}
                        getBlurredBadgeText={getBlurredBadgeText}
                        missions={missions}
                        isUserEligible={isUserEligible}
                        userEligibilityUpdated={userEligibilityUpdated}
                    />
                ) : (
                    <TournamentRounds
                        tournamentsData={tournamentsData}
                        rounds={tournamentsData?.tournamentSection?.rounds}
                        deviceType={deviceType}
                        imgPath={imgPath}
                        handleRoundClick={handleRoundClick}
                        currentRound={currentRound}
                    />
                )}
                <TournamentDashboard
                    rounds={tournamentsData?.tournamentSection?.rounds}
                    currentRound={currentRound}
                    imgPath={imgPath}
                    myRank={myRank}
                    userAverageScore={userAverageScore}
                    userTournamentPoints={userTournamentPoints}
                    userTournamentXp={userTournamentXp}
                    setUserTournamentXp={setUserTournamentXp}
                />
            </div>
        </div>
    );
};

export default Tournament;
