import { GlobalAnalytics } from "../../analytics";

const analytics = new GlobalAnalytics();

export function subNavItemClick(event) {
  analytics.fireOnClick({
    tag: "35.2",
    section: "Product",
    subsection: event?.modelName,
    technologyName: "Sub Nav",
    page: event.page ? `Vehicle ${event?.page[0].toUpperCase()}${event?.page.slice(1)}` : null,
    category : event?.category,
    positionTitle: event?.positionTitle,
    modelName: event?.modelName,
    modelYear: event?.modelYear,
  });
}

export function subNavMobileOpen(event) {
  analytics.fireOnClick({
    tag: "29.2",
    section: "Product",
    page: event?.modelName,
    category : "Sub Nav",
    label: "Open", 
    modelName: event?.modelName,
    modelYear: event?.modelYear,
  });
}

export function subNavMobileClose(event) {
  analytics.fireOnClick({
    tag: "29.2",
    section: "Product",
    page: event?.modelName,
    category : "Sub Nav",
    label: "Close", 
    modelName: event?.modelName,
    modelYear: event?.modelYear,
  });
}