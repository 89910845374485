import React from 'react';
import { ProfileTile } from './profileTile.js';
import '../../styles/css/profileList.css';
import { translateText } from '../utils/translateText';
import { getLocale } from '../../services/languageTranslationService.js';

export function ProfileListTemplate(component) {
  const profiles = component.state.filteredUsers.length > 0 ? component.state.filteredUsers.map(user => <ProfileTile user={user} />) : null;

  return (
        <div>
            <div className="profile-list-subheader">
                {translateText('global.users', getLocale())}
            </div>
            <div className="profile-list-search-bar">
                <input
                    className="search-field"
                    placeholder={translateText('accessories.search', getLocale())}
                    onChange={component.filterUsers}
                // autoFocus
                />
            </div>
            {profiles}
        </div>
  );
}
