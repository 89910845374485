import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import AppSettings from '../../../appsettings';
import getDealership from '../../../services/dealershipsService';
import { postEmail } from '../../../services/emailService';
import '../../../styles/css/appPage-askQuestionForm.css';
// import { buttonClick, pageLoad } from '../contactUsAnalytics';
import ContactUsFormButtons from '../../contactUs/contactUsForm/contactUsFormButtons';
import ContactUsFormField from '../../contactUs/contactUsForm/contactUsFormField';
import ContactUsFormModal from '../../contactUs/contactUsForm/contactUsFormModal';
import { submitForm } from '../appPage-analytics';

const AskQuestionForm = (props) => {
    const [dealership, setDealership] = useState('');
    const [associateName, setAssociateName] = useState('');
    const [email, setEmail] = useState(localStorage.email);
    const [comment, setComment] = useState('');
    const [sending, setSending] = useState(false);
    const [showValidations, setShowValidations] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [formClicked, setFormClicked] = useState(false);
    // const clickAnalytics = props.analytics.clickAnalytics;
    const fullName = `${localStorage.firstName ? localStorage.firstName : ''} ${localStorage.lastName ? localStorage.lastName : ''}`

    useEffect(() => {
        getInitialDealership();
    }, []);

    const formComplete = !!(associateName && email);

    const getInitialDealership = async () => {
        const dealershipData = await getDealership(localStorage.dealer_code);
        if (dealershipData) {
            setDealership(dealershipData.dealerName);
        }
    }

    const handleReset = () => {
        setAssociateName('');
        setEmail(localStorage.email);
        setComment('');
    }

    const openModal = () => {
        // pageLoad(props.analytics.pageLoad.modalLoad);
        setModalOpen(true);
    }

    const handleSave = () => {
        if (formComplete) {
            setSending(true);
            const emailObject = {
                toFields: AppSettings.ContactEmail,
                message: getFormattedMessage(),
                subject: getFormattedSubject(),
                replyTo: email,
                senderFirstName: localStorage.firstName ? localStorage.firstName : '',
                senderLastName: localStorage.lastName ? localStorage.lastName : '',
            };
            submitForm(props.clickAnalytics, associateName , email)
            console.log(emailObject);
            sendEmail(emailObject);
        } else {
            setShowValidations(true);
            // buttonClick(clickAnalytics, clickAnalytics.submitError);
        }
    }

    const sendEmail = async (emailObject) => {
        try {
            await postEmail(emailObject);
            // buttonClick(clickAnalytics, clickAnalytics.submit);
            setModalMessage("We got your message. We'll get back to you soon!")
            openModal();
            setSending(false);
            setShowValidations(false);
            handleReset();
        } catch (error) {
            setModalMessage("Error sending message, please try again!")
            openModal();
            setSending(false);
            setShowValidations(false);
        };
    };

    const getFormattedSubject = () => {
        return `Question from ${associateName} (${fullName}) at ${dealership}`
    }

    const getFormattedMessage = () => {
        return `From: ${associateName} (${fullName}) \n\n
        Dealership: ${dealership} \n\n
        Email Address: ${email} \n\n
        Comment: ${comment}`;
    }

    const handleCloseModal = () => {
        // buttonClick(clickAnalytics, clickAnalytics.closeModal);
        setModalMessage('');
        setModalOpen(false)
    }

    return (
        <div className="appPage-askQuestionForm">
            <div className="form-intro">
                {/* <div className="form-intro-title">{props.header}</div>
                <div className="form-intro-description">{props.description}</div> */}
                <div className="form-intro-title">Ask a Question</div>
                <div className="form-intro-description">Have a question that isn't answered above? Or simply want to share your thoughts or ideas? We're standing by - reach out by filling out the feedback form below.</div>
            </div>
            <div className="ask-question-form">                
                <div className="form-fields">
                    <ContactUsFormField
                        name='ASSOCIATE NAME: *'
                        value={associateName}
                        updateValue={setAssociateName}
                        sending={sending}
                        showValidations={showValidations}
                        singlecolumn
                        setFormClicked = {setFormClicked}
                        formClicked = {formClicked}
                        clickAnalytics = {props.clickAnalytics}
                        clickFunction = {props.clickFunction}
                    />
                    <ContactUsFormField
                        name='EMAIL ADDRESS: *'
                        value={email}
                        updateValue={setEmail}
                        sending={sending}
                        showValidations={showValidations}
                        singlecolumn
                        setFormClicked = {setFormClicked}
                        formClicked = {formClicked}
                        clickAnalytics = {props.clickAnalytics}
                        clickFunction = {props.clickFunction}

                    />
                    <ContactUsFormField
                        name='COMMENT:'
                        value={comment}
                        updateValue={setComment}
                        sending={sending}
                        showValidations={showValidations}
                        multiline
                        setFormClicked = {setFormClicked}
                        formClicked = {formClicked}
                        clickAnalytics = {props.clickAnalytics}
                        clickFunction = {props.clickFunction}
                    />
                </div>
                <div className="form-helper-text">
                    * Required field
                </div>
                <ContactUsFormButtons
                    complete={formComplete}
                    handleSave={handleSave}
                    sending={sending}
                    hideReset
                />
                {modalOpen &&
                    <ContactUsFormModal
                        open={modalOpen}
                        message={modalMessage}
                        onClose={handleCloseModal}
                    />
                }
            </div>
        </div>
    )
};

AskQuestionForm.propTypes = {
    analytics: PropTypes.object,
    header: PropTypes.string,
    description: PropTypes.string,
};

export default AskQuestionForm;
