import queryString from 'query-string';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { GlobalAnalytics } from '../../../analytics';
import { postViewPageAction } from '../../../services/nitroService';
import history from '../../app/history';
import { TSSVideosTemplate } from './tssVideos.tpl';

const analytics = new GlobalAnalytics();

const formatFilterID = (filter) => {
  const id = filter.replace('%20', ' ');
  return id;
};
export class TSSVideosComponent extends Component {
  constructor(props) {
    super(props);

    const query = queryString.parse(location.search);
    const setActiveFilter = query.tab ? query.tab : formatFilterID(props.filters[0].id);

    this.state = {
      fullVideoList: this.props.videoData,
      activeFilter: setActiveFilter,
      languageOverride: this.props.languageOverride,
    };

    this.switchFilter = this.switchFilter.bind(this);
    this.sendFilterAnalytics = this.sendFilterAnalytics.bind(this);
    this.sendVideoEventAnalytics = this.sendVideoEventAnalytics.bind(this);
  }

  switchFilter(event, filter) {
    const selectedFilter = filter.id;
    this.sendFilterAnalytics(event);
    this.setState({
      activeFilter: selectedFilter,
    });
    history.replace(`/toyotaSafetySense/videos?tab=${filter.id}`);
  }

  componentDidMount() {
    postViewPageAction(window.location.href);
    analytics.firePageLoad({
      tag: '35.1',
      section: 'Product',
      subsection: 'Featured Technology',
      technologyName: 'Toyota Safety Sense™',
      page: 'Videos',
    });
  }

  sendVideoEventAnalytics(item) {
    analytics.fireOnClick({
      tag: '40.5',
      section: 'Product',
      subsection: 'Featured Technology',
      page: 'Toyota Safety Sense™',
      category: item.category,
      contentTitle: 'Overview',
      mediaTitle: item.videoTitle.replace(/(<([^>]+)>)/g, ''),
      modelName: ' ',
      module: 'video player',
      label: item.videoTitle.replace(/(<([^>]+)>)/g, ''),
      events: item.event,
    });
  }

  sendFilterAnalytics(event) {
    analytics.fireOnClick({
      tag: '35.2',
      section: 'Product',
      subsection: ' Featured Technology',
      technologyName: 'Toyota Safety Sense™',
      page: 'Videos',
      category: 'Nav Link',
      contentTitle: event.target.textContent,
      mediaTitle: event.target.textContent,
    });
  }

  render() {
    return TSSVideosTemplate(this);
  }
}

export default injectIntl(TSSVideosComponent);
