import React, { useEffect, useState } from "react";
import S from "../../../../styles/scss/engageXP/tournaments/banner.module.scss";
import Countdown from "./countdown";
import { checkIfTournamentIsActive } from "../../utils";

const Banner = ({ tournamentsData, currentRound, imgPath, deviceType, isUserEligible, userEligibilityUpdated }) => {
    const eligibleUserImagePath = imgPath + (deviceType === "mobile" ? tournamentsData?.mobileImage : tournamentsData?.desktopImage);
    const ineligibleUserImagePath = imgPath + (deviceType === "mobile" ? tournamentsData?.ineligibleUserMobileImage : tournamentsData?.ineligibleUserDesktopImage);
    const imageToShow = (userEligibilityUpdated && !isUserEligible && !!ineligibleUserImagePath) ? ineligibleUserImagePath : eligibleUserImagePath;
    const isActive = checkIfTournamentIsActive(tournamentsData);
    const [countdownText, setCountdownText] = useState();
    const [countdownDate, setCountdownDate] = useState();

    const updateCountdownData = () => {
        const isActive = checkIfTournamentIsActive(tournamentsData);
        if (!isActive) {
            return;
        }

        if (currentRound) {
            setCountdownText(`${currentRound.title.toUpperCase()} ENDS IN`);
            setCountdownDate(currentRound.endDate);
            return;
        }

        const currentDate = new Date();
        const firstRound = tournamentsData.tournamentSection.rounds[0];
        const firstRoundStartDate = new Date(firstRound.startDate);
        if (currentDate < firstRoundStartDate) {
            setCountdownText(`${firstRound.title.toUpperCase()} STARTS IN`);
            setCountdownDate(firstRoundStartDate);
            return;
        }
    };

    useEffect(() => {
        updateCountdownData();
    }, [tournamentsData, currentRound]);

    return (
        <div className={S["banner-container"]}>
            <img src={imageToShow} alt="banner" />
            {isActive && countdownText && countdownDate && (
                <Countdown countdownTo={countdownDate} round={countdownText} />
            )}
        </div>
    );
};

export default Banner;
