import React from "react";
import AppSettings from "../../../appsettings";
import XPProgressBar from "./progressBar";

const DashboardInfoCard = (props) => {

    const getPercentToNextLevel = () => {
        const totalPointsInCurrentLevel = props?.currentLevelPoints + props?.pointsNeeded;
        const currentLevelPercentComplete = (props?.currentLevelPoints / totalPointsInCurrentLevel) * 100;
        return currentLevelPercentComplete;
    };

    return (
        <div className="dashboard-info-card">
            <div className="bar-section">
                <XPProgressBar
                    percent={getPercentToNextLevel()}
                    leftLabel={props?.currentLevel}
                    rightLabel={<div className="xp-container">
                        <span className="xp-text">{`${props?.pointsNeeded ?? '0'}XP to`}</span>
                        <span className="level-text">{props?.nextLevel ?? 'Level 0'}</span>
                    </div>}
                    variant={'dashboard'}
                />
            </div>
            <div className="points-section">
                <div className="xp-points">
                    {`${props?.userPoints} XP Points`}
                </div>
                <div className="points-link">
                    <img className="link-icon" src={`${AppSettings.AWSImgRepo.resources}en/xpInfoIcon.png`} alt="info"/>
                    <div className="link-text" onClick={props.handleMoreInfoClick}>
                        {props?.moreInfoText}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardInfoCard;
