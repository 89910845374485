import React from 'react';
import MediaQuery from 'react-responsive';
import MarkdownIt from 'markdown-it';
import dompurify from 'dompurify';
import AppSettings from '../../appsettings.js';
import applyMarkdown from '../utils/applyMarkdown';
import ImageScale from '../imageScale/imageScale.component';

export function Sections(component) {
  let threeColumnSection = [];
  let twoColumnSection = [];
  const collapseGroups = [];
  let trigger;
  let collapseTextTrigger;
  let tempCollapseGroups = [];
  let collapseGroupCounter = 0;
  let threeColumnKey = 0;
  let twoColumnKey = 0;
  let sectionKey = 0;

  const breakpoints = {
    desktop: '(min-width: 1316px)',
    mobile: '(max-width: 1315px)',
  };

  for (let i = 0; i < component.state.sections.length; i++) {
    const item = component.state.sections[i];
    let convertedRowLayout;
    let titleStyle;
    let textStyle;
    const largeStyleText = { color: item.textColor, fontSize: item.textFontSize };
    const largeStyleTitle = { color: item.textColor, fontSize: item.titleFontSize };
    const mediumStyleTitle = { color: item.textColor };
    const mediumStyleText = { color: item.textColor };
    const smallStyleTitle = { color: item.textColor };
    const smallStyleText = { color: item.textColor };
    if (component.state.width > 1024) { titleStyle = largeStyleTitle; textStyle = largeStyleText; } else if (component.state.width > 640) { titleStyle = mediumStyleTitle; textStyle = mediumStyleText; } else { titleStyle = smallStyleTitle; textStyle = smallStyleText; }

    const rowLayout = item.rowLayout.toLowerCase();
    // Convert row layout to HTML
    switch (rowLayout) {
      case '1/3 text (right)':
      case 'small-12 large-5 large-offset-7 columns':
        convertedRowLayout = 'small-12 large-5 large-offset-7 columns';
        break;
      case '1/3 text (left)':
      case 'small-12 large-5 columns':
        convertedRowLayout = 'small-12 large-5 columns';
        break;
      case '1/2 text (right)':
      case 'small-12 large-6 large-offset-6 columns':
        convertedRowLayout = 'small-12 large-6 large-offset-6 columns';
        break;
      case '1/2 text (left)':
      case 'small-12 large-6 columns':
        convertedRowLayout = 'small-12 large-6 columns';
        break;
      case '3-column':
        // if row layout is 3-Column, push data into threeColumnSection array
        if (threeColumnSection.length < 3) {
          if (item.imageFilename) {
            threeColumnSection.push(
                            <div key={threeColumnKey} className="small-12 large-4 columns three-column">
                                <ImageScale cName={'section'} url={AppSettings.AWSImgRepo.vehicle + component.state.s3Path + item.imageFilename} className={'section'}>
                                </ImageScale>
                                <div className="column-title" style={titleStyle}>{applyMarkdown(item.title, component.disclaimer)}</div>
                                <div className="column-text" style={textStyle} >{applyMarkdown(item.text, component.disclaimer)}</div>
                            </div>,
            );
          } else {
            threeColumnSection.push(
                            <div key={threeColumnKey} className="small-12 large-4 columns three-column">
                                <div className="column-title" style={titleStyle}>{applyMarkdown(item.title, component.disclaimer)}</div>
                                <div className="column-text" style={textStyle} >{applyMarkdown(item.text, component.disclaimer)}</div>
                            </div>,
            );
          }
          threeColumnKey++;
        }
        convertedRowLayout = '3-Column';
        break;
      case '2-column':
        if (twoColumnSection.length < 2) {
          if (item.imageFilename) {
            const sanitizedImageUrl = encodeURI(AppSettings.AWSImgRepo.vehicle + component.state.s3Path + item.imageFilename);
            twoColumnSection.push(
                            <div key={twoColumnKey} className="small-12 large-6 columns two-column">
                                <div className="image-container">
                                    <img alt="" src={sanitizedImageUrl} />
                                </div>
                                <div className="column-title" style={titleStyle}>{applyMarkdown(item.title, component.disclaimer)}</div>
                                <div className="column-text" style={textStyle} >{applyMarkdown(item.text, component.disclaimer)}</div>
                            </div>,
            );
          } else {
            twoColumnSection.push(
                            <div key={twoColumnKey} className="small-12 large-6 columns two-column">
                                <div className="column-title" style={titleStyle}>{applyMarkdown(item.title, component.disclaimer)} </div>
                                <div className="column-text" style={textStyle} >{applyMarkdown(item.text, component.disclaimer)}</div>
                            </div>,
            );
          }
          twoColumnKey++;
        }
        convertedRowLayout = '2-Column';
        break;

      case 'full-width':
      case 'burned-in':
      case 'small-12 large-12 columns':
        convertedRowLayout = 'small-12 large-12 columns';
        break;
      default:
        break;
    }

    item.rowLayout = convertedRowLayout;

    let textStylePlacement;

    if (!item.textPlacement) {
      textStylePlacement = {
        top: '5%',
        transformation: 'translateY(0%)',
        color: item.textColor ? item.textColor : 'Black',
        textAlign: 'left',
      };
    } else {
      // set vertical alignment and transformation
      if (item.textPlacement.indexOf('Top') >= 0) {
        if (item.textPlacement.indexOf('Left') >= 0) {
          textStylePlacement = {
            top: '5%',
            transformation: 'translateY(0%)',
            color: item.textColor ? item.textColor : 'Black',
            textAlign: 'left',
          };
        } else if (item.textPlacement.indexOf('Center') >= 0) {
          textStylePlacement = {
            top: '5%',
            transformation: 'translateY(0%)',
            color: item.textColor ? item.textColor : 'Black',
            textAlign: 'center',
          };
        } else {
          textStylePlacement = {
            top: '5%',
            transformation: 'translateY(0%)',
            color: item.textColor ? item.textColor : 'Black',
            textAlign: 'right',
          };
        }
      } else if (item.textPlacement.indexOf('Middle') >= 0) {
        if (item.textPlacement.indexOf('Left') >= 0) {
          textStylePlacement = {
            top: '50%',
            transform: 'translateY(-50%)',
            color: item.textColor ? item.textColor : 'Black',
            textAlign: 'left',
          };
        } else if (item.textPlacement.indexOf('Center') >= 0) {
          textStylePlacement = {
            top: '50%',
            transform: 'translateY(-50%)',
            color: item.textColor ? item.textColor : 'Black',
            textAlign: 'center',
          };
        } else {
          textStylePlacement = {
            top: '50%',
            transform: 'translateY(-50%)',
            color: item.textColor ? item.textColor : 'Black',
            textAlign: 'right',
          };
        }
      } else if (item.textPlacement.indexOf('Bottom') >= 0) {
        // set bottom offset to 5%
        if (item.textPlacement.indexOf('Left') >= 0) {
          textStylePlacement = {
            transform: 'translateY(0%)',
            color: item.textColor ? item.textColor : 'Black',
            bottom: '5%',
            textAlign: 'left',
          };
        } else if (item.textPlacement.indexOf('Center') >= 0) {
          textStylePlacement = {
            transform: 'translateY(0%)',
            color: item.textColor ? item.textColor : 'Black',
            bottom: '5%',
            textAlign: 'center',
          };
        } else {
          textStylePlacement = {
            transform: 'translateY(0%)',
            color: item.textColor ? item.textColor : 'Black',
            bottom: '5%',
            textAlign: 'center',
          };
        }
      }
    }


    const md = new MarkdownIt();
    const convertedTitle = <div style={titleStyle} dangerouslySetInnerHTML={{ __html: md.renderInline(dompurify.sanitize(item.title)) }} />;

    let divStyleDesktop = {};
    let titleTextStyleDesktop = {};

    // Setting the background and title for desktop windows
    // Setting a smaller height for the section if there's no background image (this is a title for the 3 columns)
    if (item.imageFilename === undefined) {
      divStyleDesktop = {
        // maxHeight: "40px",
        backgroundColor: item.backgroundColor,
      };

      titleTextStyleDesktop = {
        fontSize: '35px',
        paddingLeft: 0,
      };
    }
    // Setting the background image for the section
    else {
      divStyleDesktop = {
        width: '100%',
        // This was to keep veracode from getting on our case
        backgroundImage: decodeURI(encodeURI(AppSettings.AWSImgRepo.vehicle + component.state.s3Path + item.imageFilename)),
      };
    }

    // Setting the background and title for mobile
    let divStyleMobile = {};
    let titleTextStyleMobile = {};

    if (item.mobileImageFilename === undefined) {
      divStyleMobile = {
        // maxHeight: "10px",
        backgroundColor: item.backgroundColor,
      };

      titleTextStyleMobile = {
        fontSize: '25px',
      };
    } else {
      const imageFilename = item.mobileImageFilename ? item.mobileImageFilename : item.imageFileName;
      divStyleMobile = {
        border: item.backgroundColor,
        // This was to keep veracode from getting on our case
        backgroundImage: decodeURI(encodeURI(AppSettings.AWSImgRepo.vehicle + component.state.s3Path + imageFilename)),
      };
    }

    // Creating the section with the full width image background
    const fullWidthImageSection = <div key={sectionKey} className="small-12 large-12 columns row" >
            <MediaQuery query={breakpoints.desktop} >
                <ImageScale cName={'section'} url={divStyleDesktop.backgroundImage} className={'section'} style={divStyleDesktop}>
                    <div className="section-content" style={textStylePlacement}>
                        <div className={item.rowLayout}>
                            <div className="section-title" style={titleTextStyleDesktop}>
                                {convertedTitle}
                            </div>
                            <div className="section-text">
                                {<div style={textStyle}>{applyMarkdown(item.text, component.disclaimer)}</div>}
                            </div>
                        </div>
                    </div>
                </ImageScale>
            </MediaQuery>
            <MediaQuery query={breakpoints.mobile}>
                <div>
                    <ImageScale cName={'section'} url={divStyleMobile.backgroundImage} className={'section'} styling={divStyleMobile}>
                    </ImageScale>
                    <div className="section-content" style={textStylePlacement}>
                        <div className="small-12 large-12 columns" style={{ backgroundColor: item.backgroundColor }}>
                            <div style={{ fontSize: '30px' }}>
                                {convertedTitle}
                            </div>
                            <div className="section-text">
                                {<div style={textStyle}>{applyMarkdown(item.text, component.disclaimer)}</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>;

    // Creating the collapse groups
    if (item.collapseGroup !== collapseGroupCounter) {
      // Checks if trigger exists
      // Will skip on the first iteration because trigger doesn't exist
      if (trigger) {
        collapseGroups.push(
                    <div key={collapseGroupCounter}>
                        <div>{trigger}</div>
                        <div style={{ display: component.state.displaySections[collapseGroupCounter - 1] ? '' : 'none' }}>{tempCollapseGroups}</div>
                    </div>,
        );
        tempCollapseGroups = [];
      }

      const circleExpandedStyle = {
        zIndex: 1,
        display: component.state.displaySections[collapseGroupCounter] ? '' : 'none',
      };

      const circleCollapsedStyle = {
        display: component.state.displaySections[collapseGroupCounter] ? 'none' : '',
      };

      // If item isn't assigned collapse group then don't add collapse button
      if (item.collapseGroup === undefined) {
        if (item.rowLayout === '3-Column' && threeColumnSection.length === 3) {
          trigger = <div className="rowSection">
                        <div key={sectionKey} className="row" style={{ backgroundColor: item.backgroundColor, padding: '0 0.3rem' }}>{threeColumnSection}</div>
                    </div>;
          threeColumnSection = [];
        } else if (item.rowLayout === '3-Column') {
          trigger = null;
        } else if (item.rowLayout === '2-Column' && twoColumnSection.length === 2) {
          trigger = <div className="rowSection">
                        <div key={sectionKey} className="row" style={{ backgroundColor: item.backgroundColor, padding: '0 0.3rem' }}>{twoColumnSection}</div>
                    </div>;
          twoColumnSection = [];
        } else if (item.rowLayout === '2-Column') {
          trigger = null;
        } else if (item.collapseText) {
          collapseTextTrigger = <div className="collapsetext">
                                <div key={sectionKey} className="small-12 large-12 columns row" onClick={component.salesStoryClick.bind()}>
                                    <MediaQuery query={breakpoints.desktop} >
                                        <ImageScale cName={'section'} url={divStyleDesktop.backgroundImage} className={'section'} style={divStyleDesktop}>
                                            <div className="section-content" style={textStylePlacement}>
                                                <div className={item.rowLayout}>
                                                    <div className="section-title" style={titleTextStyleDesktop}>
                                                        {convertedTitle}
                                                    </div>
                                                    <div className="section-text">
                                                        {<div style={textStyle}>{applyMarkdown(item.text, component.disclaimer)}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </ImageScale>
                                    </MediaQuery>
                                    <MediaQuery query={breakpoints.mobile}>
                                        <div>
                                            <ImageScale cName={'section'} url={divStyleMobile.backgroundImage} className={'section'} styling={divStyleMobile}>
                                            </ImageScale>
                                            <div className="section-content" style={textStylePlacement}>
                                                <div className="small-12 large-12 columns" style={{ backgroundColor: item.backgroundColor }}>
                                                    <div className="normalTrigger" />
                                                    <div style={{ fontSize: '30px' }}>
                                                        {convertedTitle}
                                                    </div>
                                                    <div className="section-text">
                                                        {<div style={textStyle}>{applyMarkdown(item.text, component.disclaimer)}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </MediaQuery>
                                </div>
                            </div>;
        } else {
          trigger = <div key={sectionKey} className="small-12 large-12 columns row" onClick={component.salesStoryClick.bind()}>
                                <MediaQuery query={breakpoints.desktop} >
                                    <ImageScale cName={'section'} url={divStyleDesktop.backgroundImage} className={'section'} style={divStyleDesktop}>
                                        <div className="section-content" style={textStylePlacement}>
                                            <div className={item.rowLayout}>
                                                <div className="section-title" style={titleTextStyleDesktop}>
                                                    {convertedTitle}
                                                </div>
                                                <div className="section-text">
                                                    {<div style={textStyle}>{applyMarkdown(item.text, component.disclaimer)}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </ImageScale>
                                </MediaQuery>

                                <MediaQuery query={breakpoints.mobile}>
                                    <div>
                                        <ImageScale cName={'section'} url={divStyleMobile.backgroundImage} className={'section'} styling={divStyleMobile}>
                                            <div className="section-content" style={textStylePlacement}>
                                                <div className="small-12 large-12 columns">
                                                    <div style={{ fontSize: '30px' }}>
                                                        {convertedTitle}
                                                    </div>
                                                    <div className="section-text">
                                                        {<div style={textStyle}>{applyMarkdown(item.text, component.disclaimer)}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </ImageScale>
                                    </div>
                                </MediaQuery>
                            </div>;
        }
      }
      // Add the collapse buttons to the div's
      else {
        // Setting the trigger (assuming that trigger can't be the 3 column section)
        trigger = <div key={sectionKey} className="small-12 large-12 columns row" onClick={component.newForClick}>
                    <MediaQuery query={breakpoints.desktop}>
                        <ImageScale cName={'section'} url={divStyleDesktop.backgroundImage} className={'section'} styling={divStyleDesktop}>
                            <div className="section-content" style={textStylePlacement}>
                                <div className={item.rowLayout}>
                                    <div className="section-title" style={titleTextStyleDesktop}>
                                        {convertedTitle}
                                    </div>
                                    <div className="section-text">
                                        {<div style={textStyle}>{applyMarkdown(item.text, component.disclaimer)}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="circle-collapsed" style={circleCollapsedStyle} onClick={component.toggleCollapsedSections.bind(null, item.title)} id={collapseGroupCounter}></div>
                            <div className="circle-expanded" style={circleExpandedStyle} onClick={component.toggleCollapsedSections.bind(null, item.title)} id={collapseGroupCounter}></div>
                        </ImageScale>
                    </MediaQuery>
                    <MediaQuery query={breakpoints.mobile}>
                        <ImageScale cName={'section'} url={divStyleMobile.backgroundImage} className={'section'} styling={divStyleMobile}>
                            <div className="section-content" style={textStylePlacement}>
                                <div className={item.rowLayout}>
                                    <div className="section-title" style={titleTextStyleMobile}>
                                        {convertedTitle}
                                    </div>
                                    <div className="section-text">
                                        {<div style={textStyle}>{applyMarkdown(item.text, component.disclaimer)}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="circle-collapsed" style={circleCollapsedStyle} onClick={component.toggleCollapsedSections.bind(null, item.title)} id={collapseGroupCounter}></div>
                            <div className="circle-expanded" style={circleExpandedStyle} onClick={component.toggleCollapsedSections.bind(null, item.title)} id={collapseGroupCounter}></div>
                        </ImageScale>
                    </MediaQuery>
                    {/* <DisclaimersComponent template={component.props.route.year + "_entune"} ref={instance => { component.disclaimer = instance; }} /> */}
                </div>;
      }

      // Update collapse group counter
      collapseGroupCounter++;
      sectionKey++;
    }

    // If section is not the first in its collapse group (NOT the trigger)
    else if (item.collapseGroup === collapseGroupCounter || item.collapseGroup === undefined) {
      // Creates a section row once there's enough content for 3-Columm
      if (item.backgroundColor === 'Black') {
        if (threeColumnSection.length === 3) {
          tempCollapseGroups.push(
                        <div className="rowSection">
                            <div key={sectionKey} className="row" style={{ backgroundColor: item.backgroundColor, padding: '0 1.5rem' }}>{threeColumnSection}</div>
                        </div>,
          );

          threeColumnSection = [];
        }
        if (twoColumnSection.length === 2) {
          tempCollapseGroups.push(
                        <div className="rowSection">
                            <div key={sectionKey} className="row" style={{ backgroundColor: item.backgroundColor, padding: '0 0.3rem' }}>{twoColumnSection}</div>
                        </div>,
          );

          twoColumnSection = [];
        }
        if (item.rowLayout !== '3-Column' && item.rowLayout !== '2-Column') {
          tempCollapseGroups.push(
            fullWidthImageSection,
          );
        }
      } else {
        if (threeColumnSection.length === 3) {
          tempCollapseGroups.push(
                        <div className="rowSection">
                            <div key={sectionKey} className="row" style={{ backgroundColor: item.backgroundColor, padding: '0 0.3rem' }}>{threeColumnSection}</div>
                        </div>,
          );

          threeColumnSection = [];
        }
        if (twoColumnSection.length === 2) {
          tempCollapseGroups.push(
                        <div className="rowSection">
                            <div key={sectionKey} className="row" style={{ backgroundColor: item.backgroundColor, padding: '0 0.3rem' }}>{twoColumnSection}</div>
                        </div>,
          );

          twoColumnSection = [];
        }
        if (item.rowLayout !== '3-Column' && item.rowLayout !== '2-Column') {
          tempCollapseGroups.push(
            fullWidthImageSection,
          );
        }
      }
    }

    if (item.collapseText && item.collapseGroup === undefined && item.rowLayout !== '3-Column' && item.rowLayout !== '2-Column') {
      collapseGroups.push(
                <div key={collapseGroupCounter}>
                    <div>{collapseTextTrigger}</div>
                    <div style={{ display: component.state.displaySections[collapseGroupCounter - 1] ? '' : 'none' }}>{tempCollapseGroups}</div>
                </div>,
      );
    }
    // If we are on the last iteration, create collapsible
    else if (i === (component.state.sections.length - 1)) {
      collapseGroups.push(
                <div key={collapseGroupCounter}>
                    <div>{trigger}</div>
                    <div style={{ display: component.state.displaySections[collapseGroupCounter - 1] ? '' : 'none' }}>{tempCollapseGroups}</div>
                </div>,
      );
    }

    sectionKey++;
  }

  return (<div>{collapseGroups}</div>);
}
