import AppSettings from '../../appsettings';

const NON_VEHICLE_SEARCH_DATA = {
  synonymsMapping: {
    // ******* start safety system *******
    star_safety_system: 'star_safety_system',
    star_safety_system_overview: 'star_safety_system',
    star_safety_system_resources: 'star_safety_system',
    star_safety: 'star_safety_system',
    star_system: 'star_safety_system',
    // ******* toyota safety sense *******
    toyota_safety_sense: 'toyota_safety_sense',
    toyota_safety_sense_overview: 'toyota_safety_sense',
    toyota_safety_sense_lineup: 'toyota_safety_sense',
    toyota_safety_sense_features: 'toyota_safety_sense',
    toyota_safety_sense_videos: 'toyota_safety_sense',
    toyota_safety_sense_resources: 'toyota_safety_sense',
    toyota_safety_sense_resources_video: 'toyota_safety_sense',
    toyota_safety_sense_resources_edge: 'toyota_safety_sense',
    toyota_safety_sense_resources_know_more_sell_more: 'toyota_safety_sense',
    toyota_safety_sense_resources_customer_friendly_article: 'toyota_safety_sense',
    toyota_safety_sense_resources_hot_sheet: 'toyota_safety_sense',
    tss: 'toyota_safety_sense',
    tss_overview: 'toyota_safety_sense',
    tss_lineup: 'toyota_safety_sense',
    tss_features: 'toyota_safety_sense',
    tss_videos: 'toyota_safety_sense',
    tss_resources: 'toyota_safety_sense',
    tss_resources_videos: 'toyota_safety_sense',
    tss_resources_edge: 'toyota_safety_sense',
    tss_resources_know_more_sell_more: 'toyota_safety_sense',
    tss_resources_customer_friendly_article: 'toyota_safety_sense',
    tss_resources_hot_sheet: 'toyota_safety_sense',
    'tss_2.5+': 'toyota_safety_sense',
    'tss2.5+': 'toyota_safety_sense',
    'tss_2.5': 'toyota_safety_sense',
    'tss2.5': 'toyota_safety_sense',
    tss_2: 'toyota_safety_sense',
    tss2: 'toyota_safety_sense',
    'tss_2.0': 'toyota_safety_sense',
    tss_20: 'toyota_safety_sense',
    tss_p: 'toyota_safety_sense',
    'tss-p': 'toyota_safety_sense',
    tssp: 'toyota_safety_sense',
    tss_c: 'toyota_safety_sense',
    'tss-c':  'toyota_safety_sense',
    tssc: 'toyota_safety_sense',
    'toyota_safety_sense_features_tss_2.5+': 'toyota_safety_sense',
    'toyota_safety_sense_features_tss_2.5': 'toyota_safety_sense',
    'toyota_safety_sense_features_tss_2.0': 'toyota_safety_sense',
    'toyota_safety_sense_features_tss-c': 'toyota_safety_sense',
    'toyota_safety_sense_features_tss-p': 'toyota_safety_sense',
    'toyota_safety_sense_videos_tss_2.5+': 'toyota_safety_sense',
    'toyota_safety_sense_videos_tss_2.5': 'toyota_safety_sense',
    'toyota_safety_sense_videos_tss_2.0': 'toyota_safety_sense',
    'toyota_safety_sense_videos_tss-c': 'toyota_safety_sense',
    'toyota_safety_sense_videos_tss-p': 'toyota_safety_sense',
    safety: 'toyota_safety_sense',
    // ******* electrified *******
    hybrid: 'electrified',
    hybrid_overview: 'electrified',
    hybrid_resources: 'electrified',
    hybrid_videos: 'electrified',
    hybrid_comparison: 'electrified',
    electrified: 'electrified',
    electrified_overview: 'electrified',
    electrified_resources: 'electrified',
    electrified_videos: 'electrified',
    electrified_comparison: 'electrified',
    beyond_zero: 'electrified',
    // ******* audio *******
    audio_multimedia: 'audio_multimedia',
    audio_multimedia_vehicle_applicability: 'audio_multimedia',
    audio_multimedia_system_information: 'audio_multimedia',
    audio_multimedia_feature_details: 'audio_multimedia',
    audio_multimedia_registration: 'audio_multimedia',
    audio_multimedia_faqs: 'audio_multimedia',
    audio_multimedia_faq: 'audio_multimedia',
    audio_multimedia_resources: 'audio_multimedia',
    audio: 'audio_multimedia',
    audio_lineup: 'audio_multimedia',
    audio_features: 'audio_multimedia',
    audio_registration: 'audio_multimedia',
    audio_faqs: 'audio_multimedia',
    audio_faq: 'audio_multimedia',
    audio_resources: 'audio_multimedia',
    audio_multimedia_features_entune: 'audio_multimedia',
    audio_multimedia_entune: 'audio_multimedia',
    'audio_multimedia_features_entune_3.0': 'audio_multimedia',
    'audio_multimedia_entune_3.0': 'audio_multimedia',
    audio_multimedia_toyota_supra_connect_services: 'audio_multimedia',
    audio_multimedia_features_toyota_supra_connect_services: 'audio_multimedia',
    audio_multimedia_features_connected_services: 'audio_multimedia',
    audio_multimedia_entune_connected_services: 'audio_multimedia',
    audio_multimedia_connected_services_registration: 'audio_multimedia',
    audio_multimedia_registration_supra_connect: 'audio_multimedia',
    audio_multimedia_supra_connect: 'audio_multimedia',
    audio_multimedia_faqs_toyota_app: 'audio_multimedia',
    audio_multimedia_toyota_app: 'audio_multimedia',
    audio_multimedia_faqs_connected_services: 'audio_multimedia',
    audio_multimedia_connected_services: 'audio_multimedia',
    'audio_multimedia_entune_3.0_applicability_chart': 'audio_multimedia',
    amm: 'audio_multimedia',
    alexa: 'audio_multimedia',
    toyota_connected_service: 'audio_multimedia',
    amazon_alexa: 'audio_multimedia',
    android: 'audio_multimedia',
    android_auto: 'audio_multimedia',
    apple: 'audio_multimedia',
    apple_car: 'audio_multimedia',
    apple_car_play: 'audio_multimedia',
    apple_carplay: 'audio_multimedia',
    carplay: 'audio_multimedia',
    car_play: 'audio_multimedia',
    connected: 'audio_multimedia',
    connected_service: 'audio_multimedia',
    connected_services: 'audio_multimedia',
    dynamic_navigation: 'audio_multimedia',
    entune: 'audio_multimedia',
    entune_30: 'audio_multimedia',
    entune_3: 'audio_multimedia',
    'entune_3.0': 'audio_multimedia',
    jbl: 'audio_multimedia',
    remote_connect: 'audio_multimedia',
    remote_start: 'audio_multimedia',
    safety_connect: 'audio_multimedia',
    scout: 'audio_multimedia',
    supra_connect: 'audio_multimedia',
    toyota_connected_services: 'audio_multimedia',
    wifi: 'audio_multimedia',
    wifi_connect: 'audio_multimedia',

    // ******* ebrochures *******
    ebrochures: 'ebrochures',
    ebrochure: 'ebrochures',
    'e-brochures': 'ebrochures',
    'e-brochure': 'ebrochures',
    e_brochures: 'ebrochures',
    e_brochure: 'ebrochures',
    brochures: 'ebrochures',
    brochure: 'ebrochures',

    // ******* news *******
    news: 'news',
    announcements: 'news',
    articles: 'news',

    // ******* towing and payload calculator *******
    towing: 'towing_and_payload_calculator',
    towing_calculator: 'towing_and_payload_calculator',
    payload: 'towing_and_payload_calculator',
    payload_calculator: 'towing_and_payload_calculator',
    towing_and_payload: 'towing_and_payload_calculator',
    towing_and_payload_calculator: 'towing_and_payload_calculator',
    'towing_&_payload': 'towing_and_payload_calculator',
    'towing_&_payload_calculator': 'towing_and_payload_calculator',
    towing_payload: 'towing_and_payload_calculator',
    towing_payload_calculator: 'towing_and_payload_calculator',
    towing_capacity: 'towing_and_payload_calculator',
    payload_capacity: 'towing_and_payload_calculator',

    // ******* certification *******
    certification: 'certification',
    certification_overview: 'certification',
    certification_requirements: 'certification',
    certification_resources: 'certification',
    certification_program: 'certification',
    dealer_education: 'certification',
    toyota_dealer_education: 'certification',

    // ******* delivery checklist *******
    delivery_checklist: 'delivery_checklist',
    delivery_checklists: 'delivery_checklist',
    delivery: 'delivery_checklist',
    checklist: 'delivery_checklist',
    checklists: 'delivery_checklist',

    // ****** smart path *******
    smart: 'smartpath',
    smartpath: 'smartpath',
    smart_path: 'smartpath',
    smartpath_overview: 'smartpath',
    smartpath_training: 'smartpath',
    smartpath_testimonials: 'smartpath',
    smartpath_faq: 'smartpath',
    smartpath_faqs: 'smartpath',
    smartpath_contact: 'smartpath',
    smartpath_contact_us: 'smartpath',
    smart_path_overview: 'smartpath',
    smart_path_training: 'smartpath',
    smart_path_testimonials: 'smartpath',
    smart_path_faq: 'smartpath',
    smart_path_faqs: 'smartpath',
    smart_path_contact: 'smartpath',
    smart_path_contact_us: 'smartpath',

    // ***** source book *******
    source_book: 'source_book',
    source_books: 'source_book',
    book: 'source_book',
    sourcebook: 'source_book',
    sourcebooks: 'source_book',
    vehicle_sourcebooks: 'source_book',
    vehicle_sourcebook: 'source_book',
    vehicle_source_books: 'source_book',
    vehicle_source_book: 'source_book',
    vehicle_book: 'source_book',
    vehicle_books: 'source_book',

    // ***** promo materials *******
    promo_materials: 'promo_materials',
    promotional_materials: 'promo_materials',
    promo: 'promo_materials',
    promotional: 'promo_materials',
    engage_promo_materials: 'promo_materials',
    engage_promotional_materials: 'promo_materials',
    engage_promo: 'promo_materials',
    engage_promotional: 'promo_materials',
  },
  pages: {
    electrified: {
      subTabs: ['overview', 'comparison', 'videos', 'resources'],
      link: null,
      textToDisplay: 'Beyond Zero',
    },
    star_safety_system: {
      subTabs: ['overview', 'resources'],
      link: null,
      textToDisplay: 'Star Safety System',
    },
    toyota_safety_sense: {
      subTabs: ['overview', 'tss_lineup', 'features', 'videos', 'resources'],
      link: null,
      textToDisplay: 'Toyota Safety Sense',
    },
    audio_multimedia: {
      subTabs: ['vehicle_applicability', 'system_information', 'feature_details', 'registration', 'faq', 'resources'],
      link: null,
      textToDisplay: 'Audio Multimedia',
    },
    ebrochures: {
      subTabs: [],
      link: '/eBrochures',
      textToDisplay: 'eBrochures',
    },
    news: {
      subTabs: [],
      link: '/news',
      textToDisplay: 'News'
    },
    towing_and_payload_calculator: {
      subTabs: [],
      link: '/towingPayload',
      textToDisplay: 'Towing & Payload Calculator'
    },
    certification: {
      subTabs: ['overview', 'requirements', 'resources'],
      link: null,
      textToDisplay: 'Certification',
    },
    delivery_checklist: {
      subTabs: [],
      link: '/engageResources/tag/delivery',
      textToDisplay: 'Delivery Checklists',
    },
    smartpath: {
      subTabs: ['overview', 'training', 'testimonials', 'faq'],
      link: null,
      textToDisplay: 'SmartPath',
    },
    source_book: {
      subTabs: [],
      link: '/sourcebooks',
      textToDisplay: 'Source Books',
    },
    promo_materials: {
      subTabs: [],
      link: '/engageResources/tag/Engage%20Promotional%20Materials',
      textToDisplay: 'Engage Promo Materials'
    }
  },
  subTabs: {
    star_safety_system_overview: {
      textToDisplay: 'Overview',
      link: '/starSafetySystem/overview',
      filters: [],
    },
    star_safety_system_resources: {
      textToDisplay: 'Resources',
      link: '/starSafetySystem/resources',
      filters: [],
    },
    audio_multimedia_vehicle_applicability: {
      textToDisplay: 'Vehicle Applicability',
      link: '/audioMultimedia/overview?target=Vehicle%20Applicability',
      filters: [],
    },
    audio_multimedia_system_information: {
      textToDisplay: 'System Information',
      link: '/audioMultimedia/overview?target=System%20Information',
      filters: [],
    },
    audio_multimedia_feature_details: {
      textToDisplay: 'Feature Details',
      link: '/audioMultimedia/overview?target=Feature%20Details',
      filters: [],
    },
    audio_multimedia_registration: {
      textToDisplay: 'Registration',
      link: '/audioMultimedia/registration',
      filters: ['Connected Services Registration', 'Supra Connect'],
    },
    audio_multimedia_faq: {
      textToDisplay: 'FAQs',
      link: '/audioMultimedia/faqs',
      filters: ['Audio Multimedia', 'Connected Services', 'Toyota App'],
    },
    audio_multimedia_resources: {
      textToDisplay: 'Resources',
      link: '/audioMultimedia/resources',
      filters: [],
    },
    toyota_safety_sense_overview: {
      textToDisplay: 'Overview',
      link: '/toyotaSafetySense/overview',
      filters: [],
    },
    toyota_safety_sense_tss_lineup: {
      textToDisplay: 'TSS Lineup',
      link: '/toyotaSafetySense/lineup',
      filters: [],
    },
    toyota_safety_sense_features: {
      textToDisplay: 'Features',
      link: '/toyotaSafetySense/features',
      filters: ['TSS 2.5+', 'TSS 2.5', 'TSS 2.0', 'TSS-P', 'TSS-C'],
    },
    toyota_safety_sense_videos: {
      textToDisplay: 'Videos',
      link: '/toyotaSafetySense/videos',
      filters: ['TSS 2.5+', 'TSS 2.5', 'TSS 2.0', 'TSS-P', 'TSS-C'],
    },
    toyota_safety_sense_resources: {
      textToDisplay: 'Resources',
      link: '/toyotaSafetySense/resources',
      filters: [],
    },
    electrified_overview: {
      textToDisplay: 'Overview',
      link: '/hybrid/overview',
      filters: [],
    },
    electrified_resources: {
      textToDisplay: 'Resources',
      link: '/hybrid/resources',
      filters: [],
    },
    electrified_comparison: {
      textToDisplay: 'Beyond Zero Comparison',
      link: '/hybrid/comparison',
      filters: [],
    },
    electrified_videos: {
      textToDisplay: 'Videos',
      link: '/hybrid/videos',
      filters: [],
    },
    certification_overview: {
      textToDisplay: 'Overview',
      link: '/certification/overview',
      filters: [],
    },
    certification_requirements: {
      textToDisplay: 'Requirements',
      link: '/certification/requirements',
      filters: [],
    },
    certification_resources: {
      textToDisplay: 'Resources',
      link: '/certification/resources',
      filters: ['Announcements', 'Cornerstone', 'Support Contacts'],
    },
    smartpath_overview: {
      textToDisplay: 'Overview',
      link: '/smartPath/overview',
      filters: [],
    },
    smartpath_training: {
      textToDisplay: 'Training',
      link: '/smartPath/training',
      filters: ['RTI-D', 'Online', 'Mobile Sales Tool', 'Management Console', 'Digital F&I', 'Used', 'Signal', 'Loyal Customer Profile (LCP)', 'Other'],
    },
    smartpath_testimonials: {
      textToDisplay: 'Testimonials',
      link: '/smartPath/testimonials',
      filters: [],
    },
    smartpath_faq: {
      textToDisplay: 'FAQs',
      link: '/smartPath/faqs',
      filters: [],
    },
  },
};

export default NON_VEHICLE_SEARCH_DATA;
