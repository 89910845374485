import React, { useState, useEffect } from "react";
import ModelCodeNameTiles from "../modelCodeNameTiles";
import "../../../styles/scss/details-v2/featuresSpecs.scss";
import applyMarkdown from "../../utils/applyMarkdown";
import { isDash } from "../../utils/helpers";

const SpecsAccordionContent = (props) => {
    const displaySpecModels = (specs, specName) => {
        const specModels = specs.filter(s => s.spec === specName)
        if (specModels.length > 0) {
            return (
                <div key={specName} className="specModels">
                    <div className="specName">{applyMarkdown(specName, props.disclaimerRef)}</div>
                    <ModelCodeNameTiles models={specModels} />
                </div>
            )
        }
    }

    const getSpecsResults = (specsData) => {
        const specNames = [...new Set(specsData?.specs?.map(m => m.spec))].filter(i => !isDash(i))
        return <div className={`specResultColumn ${props.comparisonModelSpecs && "comparedResults"}`}>
            {specNames.every(i => isDash(i)) ?
                <div className="notAvailable">Not Available</div>
                : <div className="specsList">
                    {specNames.map(s => displaySpecModels(specsData?.specs, s))}
                </div>
            }
        </div>
    }

    return <div className="specsContentContainer">
        {props.comparisonModelSpecs && <div className="comparedTrimNamesHeader">
            <div className="trimNameContainer"><div className="trimName">{props.modelSpecs.trimName}</div></div>
            <div className="trimNameContainer"><div className="trimName">{props.comparisonModelSpecs.trimName}</div></div>
        </div>}
        <div className="specsAccordionContent">
            {props.modelSpecs && getSpecsResults(props.modelSpecs)}
            {props.comparisonModelSpecs && getSpecsResults(props.comparisonModelSpecs)}
        </div>
    </div>
}

export default SpecsAccordionContent