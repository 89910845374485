import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import AppSettings from '../../appsettings';
import { getLocale } from '../../services/languageTranslationService';
import getAnalytics from '../../services/analyticsService';
import getMisc from '../../services/miscService';
import SubheaderComponent from '../subheader/subheader.component';
import CertificationOverview from './certificationOverview';
import CertificationRequirements from './certificationRequirements';
import CertificationResources from './certificationResources/certificationResources.component';
import Display404NotFound from '../../components/atoms/404NotFound';
import { bookmarkClick, subheaderClick } from './certification.analytics';
import * as _ from 'lodash';
import MobileBackButton from '../../components/molecules/MobileBackButton';


const certificationRouterComponent = (props) => {
  const [pageLoaded, setPageLoaded] = useState(false);
  const [analyticsData, setAnalyticsData] = useState(null);
  const [overviewData, setOverviewData] = useState(null);
  const [requirementsData, setRequirementsData] = useState(null);
  const [resourcesData, setResourcesData] = useState(null);
  const [languageOverride, setLanguageOverride] = useState(false);
  const history = useHistory();
  const params = useParams();

  const getAnalyticsData = async () => {
    const certificationAnalytics = await getAnalytics('certification');
    setAnalyticsData(certificationAnalytics);
  };

  const getPageData = async () => {
    let language = getLocale();
    if (languageOverride && !pageLoaded) {
      language = 'en';
    }
    let certificationPageData = await getMisc('Certification', language);

    if (Object.keys(certificationPageData).length === 0 && certificationPageData.constructor === Object) {
      setLanguageOverride(true);
    } else {
      setOverviewData(certificationPageData.items.overview);
      setRequirementsData(certificationPageData.items.requirements);
      setResourcesData(certificationPageData.items.resources);
      setPageLoaded(true);
    }
  };
  
  useEffect(() => {
    getPageData();
    getAnalyticsData();
  }, []);

  const getSubheaderChildComponent = (path) => {
    let childComponent;
    switch (path ? path.toLowerCase() : '') {
      case 'overview':
        childComponent = overviewData && (
          <CertificationOverview
            clickAnalytics={analyticsData && analyticsData.clickAnalytics}
            disclaimer={'Certification'}
            imagePath={AppSettings.AWSImgRepo.certification}
            overviewData={overviewData}
            pageLoadAnalytics = {analyticsData && analyticsData.pageLoad}
            pageName={_.startCase(path)}
            videoAnalytics = {analyticsData && analyticsData.videoAnalytics}
          />
        );
        break;
      case 'requirements':
        childComponent = requirementsData && (
          <CertificationRequirements
            pageLoadAnalytics = {analyticsData && analyticsData.pageLoad}
            clickAnalytics={analyticsData && analyticsData.clickAnalytics}
            disclaimer={'Certification'}
            filePath={AppSettings.AWSImgRepo.certification}
            imagePath={AppSettings.AWSImgRepo.certification}
            pageName={_.startCase(path)}
            requirementsData={requirementsData}
          />
        );
        break;
      case 'resources':
        childComponent = resourcesData && (
          <CertificationResources
            pageLoadAnalytics = {analyticsData && analyticsData.pageLoadAnalytics}
            clickAnalytics={analyticsData && analyticsData.clickAnalytics}
            disclaimer={'Certification'}
            imagePath={AppSettings.AWSImgRepo.certification}
            parentPageName={'Certification'}
            subheaderPageName={_.startCase(path)}
            resourcesData={resourcesData}
          />
        );
        break;
      default:
        history.push('/certification/overview');
        break;
    }

    return childComponent;
  };

  const isValidTabName = () => ['overview', 'requirements', 'projectBlueprint', 'resources'].filter(tab => tab === params.page).length > 0;

  const getPageDisplay = () => {
    if (params.page === undefined) {
      history.push('/certification/overview');
    } else if (isValidTabName()) {
      return (
        <div>
          {pageLoaded && <SubheaderComponent
            {...props}
            params={params}
            route={params}
            bookmarkNavClickEvent={(isBookmarked) => bookmarkClick(analyticsData && analyticsData.clickAnalytics, _.startCase(params.page), isBookmarked)}
            subheaderNavClickEvent={(destinationPage) => subheaderClick(analyticsData && analyticsData.clickAnalytics, _.startCase(params.page), destinationPage)}
          >
            {getSubheaderChildComponent(params.page)}
          </SubheaderComponent>}
        </div>
      );
    } else {
      return <Display404NotFound display />;
    }
  };

  return (
    <div>{getPageDisplay()}</div>
  );
};

export default injectIntl(certificationRouterComponent);
