import React from 'react';
import { FormattedMessage } from 'react-intl';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '../../../styles/css/overview.css';
import MarkdownIt from 'markdown-it';
import MarkdownItSup from 'markdown-it-sup';
import dompurify from 'dompurify';
import AppSettings from '../../../appsettings.js';
import { GlobalAnalytics } from '../../../analytics';
import applyMarkdown from '../../utils/applyMarkdown';
import DisclaimersComponent from '../../disclaimers/disclaimers.component';

const analytics = new GlobalAnalytics();

export function CAOverviewTemplate(component) {
  const md = new MarkdownIt().use(MarkdownItSup);

  const carousel = component.state.carousel ? component.state.carousel.map((carousel, index) => {
    if (component.state.isInMobileView) {
      return (
        <div key={index} className={(index === component.state.carousel.length - 1) ? 'mobile-slide last-slide' : 'mobile-slide'}>
          <img alt="" src={(AppSettings.AWSImgRepo.compAdvOverview + component.state.s3Path + carousel.imageFilename)} />
          <div className="mobile-carousel-header">{applyMarkdown(carousel.overlayHeadline, component.disclaimer)}</div>
          <div className="mobile-carousel-description">{applyMarkdown(carousel.overlaySubheadline, component.disclaimer)}</div>
        </div>
      );
    }
    return (
        <div key={index}>
          <img alt="" src={(AppSettings.AWSImgRepo.compAdvOverview + component.state.s3Path + carousel.imageFilename)} />
        </div>
    );
  }) : null;

  const settings = {
    accessibility: true,
    arrows: false,
    autoplay: false,
    dots: true,
    draggable: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    slickGoTo: component.state.currentSlide,
    afterChange(index) {
      component.setState({ currentSlide: index });
      analytics.fireOnClick({
        tag: '29.2',
        container: 'Module',
        module: 'Competitive Advantage Carousel',
        category: 'Overview',
        label: component.state.carousel[index].overlayHeadline,
        mediaTitle: component.state.carousel[index].overlayHeadline,
        contentTitle: component.state.carousel[index].overlayHeadline,
        section: 'Product',
        modelName: component.props.model,
        modelYear: component.props.year,
        page: 'Competitive Advantage:Overview',

      });
    },
    responsive: [
      {
        breakpoint: 641,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };

  const models = component.state.modelReview ? component.state.modelReview.map((model, index) => (
      <div className="model-list columns large-3 medium-3 small-7" key={index}>
        <img alt="" src={(AppSettings.AWSImgRepo.compAdvOverview + component.state.s3Path + model.imageFilename)} />
        <div className="model-title">
          <div dangerouslySetInnerHTML={{ __html: md.renderInline(dompurify.sanitize(model.imageTitle)) }} />
        </div>
        <div className="model-redline" />
        <div className="model-description">
          <div>{applyMarkdown(model.overviewText, component.disclaimer)}</div>
        </div>
      </div>
  )) : null;

  const printCarousel = component.state.carousel !== 0 ? component.state.carousel.map((carousel, index) => (
      <div className="print-overlay" key={index}>
        <div className="print-overlay-head"> {applyMarkdown(carousel.overlayHeadline, component.disclaimer)} </div>
        <div> {applyMarkdown(carousel.overlaySubheadline, component.disclaimer)} </div>
      </div>
  )) : null;

  return (
    <div>
      {component.state.hasData
        ? <div>
          {
            (component.state.displayOverrideText)
              ? <div className="languageOverride">
                <b><FormattedMessage id="dataUnavailable" defaultMessage="El contenido en Español está pendiente" /></b>
              </div>
              : <div />
          }
          <div className="ca-overview">
            <div className="slide">
              <Slider className={component.state.isInMobileView ? '' : 'slider-carousel'} {...settings}>
                {carousel}
              </Slider>
              {!component.state.isInMobileView
                && <span>
                  <div className="text-area">
                    <div className="arrow-left" />
                    <div className="center-text">
                      <div className="carousel-header">
                        <div dangerouslySetInnerHTML={{ __html: md.renderInline(dompurify.sanitize(component.state.carousel[component.state.currentSlide].overlayHeadline)) }} />
                      </div>
                      <div className="carousel-description">
                        <div> {applyMarkdown(component.state.carousel[component.state.currentSlide].overlaySubheadline, component.disclaimer)} </div>
                      </div>
                    </div>
                  </div>
                </span>
              }
            </div>
            <div className="carousel-print">{printCarousel}</div>
            {models
              && <div className="model-review">
                <div className="model-header">
                  <FormattedMessage id="caOverview.modelReviewHeader" defaultMessage="Model Review" />
                </div>
                <div className="row">{models}</div>
              </div>
            }
          </div>
          <DisclaimersComponent template={`${component.props.year}_${component.props.model}_caOverview`} ref={(instance) => { component.disclaimer = instance; }} />
        </div>
        : <div className="no-data">
          <FormattedMessage id="caOverview.noData" defaultMessage="Competitive Advantages Overview data not available at this time." />
        </div>}
    </div>
  );
}
