import { GlobalAnalytics } from "../../analytics";

const analytics = new GlobalAnalytics();

export function pageLoad(event) {
  analytics.firePageLoad({
    tag: "29.1",
    section: "Product",
    modelName: event?.modelName,
    page: event?.page,
    modelYear: event?.modelYear,
  });
}

export function accordionClick(event) {
  const nestedLabel = event?.isNested ? "Nested " : "";
  analytics.fireOnClick({
    tag: "29.2",
    section: "Product",
    page: event?.page,
    category : event?.category || 'Trim Results',
    label: event?.label || `${nestedLabel}Result ${event.isExpand ? "Expand" : "Collapse"}`,
    contentTitle: event?.term,
    modelName: event?.modelName,
    modelYear: event?.modelYear,
    trim: event?.trim,
    positionTitle: event?.positionTitle
  })
}

export function selectTrim(event) {
  analytics.fireOnClick({
    tag: "29.2",
    section: "Product",
    page: event?.page,
    category : 'Trim Filter',
    label: "Select Trim",
    contentTitle: event?.contentTitle,
    modelName: event?.modelName,
    modelYear: event?.modelYear
  })
}