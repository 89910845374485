import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import FAQs from '../../../components/pages/FAQs';
import { postViewPageAction } from '../../../services/nitroService';
import * as analyticsFunctions from './audioMultimediaFAQs.analytics';

const audioMultimediaFAQs = (props) => {
  const [availableFilters, setAvailableFilters] = useState([]);
  const [filteredFaqs, setFilteredFaqs] = useState([]);

  useEffect(() => {
    postViewPageAction(window.location.href);
    analyticsFunctions.pageLoad();
  }, []);

  useEffect(() => {
    const availableFiltersFromProps = props.data.faqs.filters;
    const faqsFromProps = props.data.faqs.questions ? props.data.faqs.questions : props.data.faqs;
    setAvailableFilters(availableFiltersFromProps);
    setFilteredFaqs(faqsFromProps);
  }, [props.data.faqs]);

  return (
    <FAQs
      clickEvent={analyticsFunctions.clickEvent}
      disclaimer={'entune'}
      faqs={filteredFaqs}
      filters={availableFilters}
      languageOverride={props.languageOverride}
      urlPath={'/audioMultimedia/faqs'}
    />
  );
};

audioMultimediaFAQs.propTypes = {
  data: PropTypes.object,
  languageOverride: PropTypes.bool,
};

export default audioMultimediaFAQs;
