import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { getFeaturesAndSpecs } from '../../services/featuresAndSpecsService.js';
import { getVehicleYears } from '../../services/vehicleService.js';
import { getLocale } from '../../services/languageTranslationService.js';
import { postFeaturesSpecsAction } from '../../services/nitroService';
import { getAllTrims } from '../../services/trimsService.js';
import { postUserActivityRecord } from '../../services/userActivityService';
import { areStringsEqual, cloneObject } from '../utils/helpers';
import history from '../app/history';
import { sendPageLoadAnalytics, sendUpdateDropDownSelection, sendClickAnalytics } from './featuresAndSpecs.analytics.js';
import { FormattedMessage } from 'react-intl';
import SectionHeader from '../../components/atoms/SectionHeader';
import '../../styles/css/featuresAndSpecs.css';
import DisclaimersComponent from '../disclaimers/disclaimers.component';
import { removeProperty } from '../utils/helpers';
import VehicleComparison from '../vehicleComparison/vehicleComparison';
import MultipleVehicleSelection from '../vehicleSelection/multipleVehicleSelection.component';
import { getDynamoName, getS3PathName } from '../utils/vehicleName.js';
import Skeleton from "react-loading-skeleton";

const FeaturesAndSpecs = (props) => {
    const [s3Path, setS3Path] = useState();
    const [vehicleYears, setVehicleYears] = useState([]);
    const [selectedVehicles, setSelectedVehicles] = useState([]);
    const [selectedFeatures, setSelectedFeatures] = useState([]);
    const [selectedSpecs, setSelectedSpecs] = useState([]);
    const [features, setFeatures] = useState([]);
    const [specs, setSpecs] = useState([]);
    const [trims, setTrims] = useState();
    const [selectedTrimIndex, setSelectedTrimIndex] = useState(0);
    const [disclaimers, setDisclaimers] = useState([]);
    const [disclaimerList, setDisclaimerList] = useState([]);
    const [loading, setLoading] = useState(true);

    const query = queryString.parse(props.location.search);
    const trimQueryParam = query && query.trim;
    const routeFeatSpecs = query.featureSpec;

    useEffect(() => {
        getPageData();
        sendPageLoadAnalytics(props.route.model, props.route.year);
        const path = window.location.pathname;
        postUserActivityRecord(path);
    }, []);

    useEffect(() => {
        const category = 'Features';
        if (selectedVehicles.length === 2) {
            let label = 'Compare Vehicle Competitor';
            sendClickAnalytics(props.route.model, props.route.year, 'Module', 'Filter', category, label);
            postFeaturesSpecsAction(category, label);
        } else if (selectedVehicles.length === 1) {
            let label = 'Compare trim link';
            sendClickAnalytics('Module', 'Filter', category, label);
            postFeaturesSpecsAction(category, label);
        }
        if(selectedVehicles.length > 0){
            let disclaimerRefs = [];
            const disclaimerComponents = selectedVehicles.map((veh, index) => 
                <DisclaimersComponent
                    key={`disclaimer-${index}`}
                    template={`${veh.year}_${props.route.model}`}
                    ref={(instance) => { disclaimerRefs[index] = instance; }}
                    isSearchDisclaimer={false}
                />
            );
            setDisclaimers(disclaimerRefs);
            setDisclaimerList(disclaimerComponents);
        }
    }, [selectedVehicles]);

    const disclaimerCallback = (index, disclaimer) => {
        disclaimers[index].onOpenModal(disclaimer);
    }

    const analyticsOnChange = (newSelection) => {
        const category = 'Update Dropdown';
        const label = newSelection.selectName;
        const year = newSelection.year ? newSelection.year.toString() : newSelection.year;
        postFeaturesSpecsAction(category, label, year, newSelection.trim.fullName, newSelection.engine);
    }

    const updateSelectedVehicles = (selection) => {
        if (selection && features.length) {
            const selectedFeatures = mapVehicleSelectionToFeaturesAndSpecs(selection);
            if (selectedFeatures) {
                setSelectedVehicles(selection);
                setSelectedFeatures(convertFeaturesToArray(selectedFeatures));
                setSelectedSpecs(mapSpecs({ featuresAndSpecs: selectedFeatures }));
            } else {
                history.push('/404');
            }
        }
    }

    const convertFeaturesToArray = (selectedFeatures) => {
        const featuresArray = [];
        if (selectedFeatures.Exterior) {
            featuresArray.push({
                name: 'Exterior',
                items: selectedFeatures.Exterior,
            });
        }
        if (selectedFeatures.Interior) {
            featuresArray.push({
                name: 'Interior',
                items: selectedFeatures.Interior,
            });
        }
        if (selectedFeatures['Safety/Convenience']) {
            featuresArray.push({
                name: 'Safety/Convenience',
                items: selectedFeatures['Safety/Convenience'],
            });
        } else if (selectedFeatures['Seguridad/Conveniencia']) {
            featuresArray.push({
                name: 'Seguridad/Conveniencia',
                items: selectedFeatures['Seguridad/Conveniencia'],
            });
        }
        return featuresArray;
    }

    const loadInitialFeaturesAndSpecs = async () => {
        try {
            const dynamoName = getDynamoName(props.route.model);
            const vehicleYears = await getVehicleYears(dynamoName);
            const featsAndSpecsRequests = vehicleYears.map(year => getFeaturesAndSpecs(dynamoName, year, 'en'));
            const featsAndSpecsData = await Promise.all(featsAndSpecsRequests);
            return featsAndSpecsData;
        } catch (error) {
            console.log(`ERROR loading initial features/specs: ${error}`);
            throw(error);
        }
    }

    const loadTrims = async () => {
        try {
            return await getAllTrims(props.route.model, false ? 'en' : undefined);
        } catch (error) {
            console.log(`ERROR loading trims: ${error}`);
            throw error;
        };
    }

    const getPageData = async () => {
        try {
            const s3PathName = await getS3PathName(props.route.model);
            setS3Path(`${s3PathName}/en/`);

            const trimsData = await loadTrims();
            const trims = {};
            trimsData.forEach((yearTrim) => {
                trims[yearTrim.modelYear] = yearTrim.trims;
            });
            setTrims(trims);

            const initialData = await loadInitialFeaturesAndSpecs();
            const features = initialData.sort((a, b) => b.modelYear - a.modelYear);
            setFeatures(features.map(mapModelCodesToYear));

            // Highlight selected engine and trim if there're passed in the query params
            let trimIndex = selectedTrimIndex;
            if (props.params.year && trimQueryParam) {
                trimIndex = getTrimIndexByFullName(trims[props.params.year], trimQueryParam);
            }

            setVehicleYears(features.map(feat => feat.modelYear));
            setSelectedTrimIndex(trimIndex);
            setSpecs(features.map(mapSpecs));

            let yearFeatures = features.find(f => f.modelYear == props.params.year);
            setSelectedFeatures(convertFeaturesToArray(yearFeatures.featuresAndSpecs));
            setSelectedSpecs(mapSpecs(yearFeatures));
            setLoading(false);
        } catch (error) {
            console.log(`ERROR getting page data: ${error}`);
        }
    }

    const getTrimIndexByFullName = (trims, fullName) => {
        let trimIndex;
        if (trims && trims.length > 0 && fullName) {
            trimIndex = trims.findIndex(element => element.fullName === fullName);
        }
        return trimIndex;
    }

    const mapModelCodesToYear = (yearFeatures) => {
        const { modelYear } = yearFeatures;
        // Loop through the outer feature sets
        const mainGroups = Object.keys(yearFeatures.featuresAndSpecs);
        mainGroups.forEach((mainGroup) => {
            const groupFeatures = yearFeatures.featuresAndSpecs[mainGroup];
            // Loop through the elements in each outer feature set
            groupFeatures.forEach((groupFeature) => {
                // Map each feature (description and model codes) to it's model year
                // eslint-disable-next-line no-param-reassign
                groupFeature.featureSpecs = groupFeature.featureSpecs.map((feat) => {
                    const obj = {};
                    obj[modelYear] = feat;
                    return obj;
                });
            });
        });
        return yearFeatures;
    }

    const mapSpecs = (vehicleFeatureSpecs) => {
        const specs = {};
        vehicleFeatureSpecs.featuresAndSpecs.Specs.forEach((spec) => {
            specs[spec.name] = [{ featureSpecs: spec.featureSpecs }];
        });
        return specs;
    }

    const mapVehicleSelectionToFeaturesAndSpecs = (selectedVehicles) => {
        // Grab the feature set for each car based on it model year
        const yearFeatureSets = selectedVehicles.map(vehicle => features.find(feat => feat.modelYear === vehicle.year));
        if (yearFeatureSets.length > 1) {
            return combineFeaturesSpecs(yearFeatureSets[0], yearFeatureSets[1]);
        } else if (yearFeatureSets[0] && yearFeatureSets[0].featuresAndSpecs) {
            return yearFeatureSets[0].featuresAndSpecs;
        } else {
            return null;
        }
    }

    const combineFeaturesSpecs = (primaryFeatSpecs, secondaryFeatSpecs) => {
        const object = cloneObject(primaryFeatSpecs.featuresAndSpecs); // Cloning the primary features object because otherwise it is passed as a refrence here and is modified.
        const secondaryFeatureSpecs = secondaryFeatSpecs.featuresAndSpecs;
        return {
            Exterior: combineFeature(object.Exterior,
                secondaryFeatureSpecs.Exterior,
                primaryFeatSpecs.modelYear,
                secondaryFeatSpecs.modelYear),
            Interior: combineFeature(object.Interior,
                secondaryFeatureSpecs.Interior,
                primaryFeatSpecs.modelYear,
                secondaryFeatSpecs.modelYear),
            'Safety/Convenience': combineFeature(object['Safety/Convenience'],
                secondaryFeatureSpecs['Safety/Convenience'],
                primaryFeatSpecs.modelYear,
                secondaryFeatSpecs.modelYear),
            Specs: combineFeature(object.Specs,
                secondaryFeatureSpecs.Specs,
                primaryFeatSpecs.modelYear,
                secondaryFeatSpecs.modelYear),
        };
    }

    const combineFeature = (primaryFeatSpec, secondaryFeatSpecs, primaryModelYear, secondaryModelYear) => {
        const finalSection = primaryFeatSpec;
        secondaryFeatSpecs.forEach((featureGroup) => {
            // Check if the feature group exists in the finalSection
            const finalIndex = finalSection.findIndex(feat => areStringsEqual(feat.name, featureGroup.name));
            // If the section exists
            if (finalIndex !== -1) {
                const matchingSection = finalSection[finalIndex];
                // Find the match for each secondary item and update, or append it
                featureGroup.featureSpecs.forEach((secondaryItem) => {
                    // Initially there is not a known match
                    let hasMatch = false;
                    // Check if a match exists in any of the primary feature groups
                    const matchIndex = matchingSection.featureSpecs.findIndex((matchFeat) => {
                        // If this feature doesn't exist for the primary year return false
                        if (!matchFeat || !matchFeat[primaryModelYear]) {
                            return false;
                        }
                        return areStringsEqual(matchFeat[primaryModelYear].Description, secondaryItem[secondaryModelYear].Description);
                    });
                    // If there is a match the index will be >= 0
                    if (matchIndex !== -1) {
                        // Set the flag
                        hasMatch = true;
                        // Assign the values to the model year in the final features
                        matchingSection.featureSpecs[matchIndex][secondaryModelYear] = secondaryItem[secondaryModelYear];
                    }
                    // If there was not a match found we need to add the feature to the final output
                    if (!hasMatch) {
                        // If the feature group that the feature is in already exists in the output add it there
                        // otherwise add the feature group
                        if (finalIndex !== -1) {
                            finalSection[finalIndex].featureSpecs.push(secondaryItem);
                        }
                    }
                });
            } else {
                finalSection.push(featureGroup);
            }
        });
        return finalSection;
    }

    return (
        <div className="features-and-specs-page">
          <SectionHeader id='featuresAndSpecs.pageTitle' />
    
          <div className={`features-and-specs ${selectedVehicles.length > 1 ? '' : 'comparison-border'}`}>
            
            {trims ? 
                <MultipleVehicleSelection
                    modelYears={vehicleYears}
                    engine={props.route.engine}
                    trim={props.route.trim}
                    trims={trims}
                    selectedTrimIndex={selectedTrimIndex}
                    forceCurrentYear={true}
                    year={parseInt(props.params.year, 10)}
                    analyticsOnChange={analyticsOnChange}
                    onSelectionChanged={updateSelectedVehicles}
                    maxSelections={2}
                    s3Path={s3Path} />
            :
                <div className='vehicle-selection-skeleton-container'>
                    <div className='skeleton-blocks'>
                        <Skeleton width={300} height={20}/>
                        <Skeleton width={60} height={25}/>
                        <div className='image-placeholder'></div>
                        <Skeleton width={350} height={35} count={3}/>
                    </div>
                </div>
            }
            <VehicleComparison 
                features={selectedFeatures}
                engine={props.route.engine}
                trim={props.route.trim}
                featSpec={routeFeatSpecs}
                specs={selectedSpecs}
                selection={selectedVehicles}
                disclaimerCallback={disclaimerCallback} />
          </div>
          {disclaimerList}
        </div>
      );
}

export default FeaturesAndSpecs;