import React from 'react';
import Select from '../select/select.component';
import '../../styles/css/vehicleSelection.css';
// import '../../styles/css/featuresAndSpecs.css';
import AppSettings from '../../appsettings.js';

export default function VehicleSelectionTemplate(component) {
  let imageSource = '';
  if (component.state.selection.trim) {
    if (component.state.selection.year > 2017) {
      imageSource = `${AppSettings.AWSImgRepo.vehicle + component.state.selection.year}/${component.props.s3Path}${component.state.selection.trim.trimImageFilename}`;
    } else {
      imageSource = AppSettings.AWSImgRepo.static + component.state.selection.trim.trimImageFilename;
    }
  }

  const trimFullName = component.state.selection.trim ? component.state.selection.trim.fullName : '';
  const trimIndex = component.getIndexForTrimDropdown(component.state.trims, component.state.selection.engine, trimFullName);
  return (
    <div className={component.props.trimWalk ? 'trimWalk-vehicle-selection' : 'vehicle-selection'} >
      <div className='trimFullNameAndCodeContainer'>
        {trimFullName && <span className='trimFullName'>{trimFullName}</span>}
        {component.state.selection?.trim?.modelCode && <span className='trimCode'>{component.state.selection.trim.modelCode}</span>}
      </div>
      {component.state.selection && component.state.selection.trim
        && <div>
          <img 
            width="400" 
            height="171" 
            src={imageSource} 
            onError={({ currentTarget })  => {
              currentTarget.onerror = null;
              currentTarget.src = encodeURI(
                AppSettings.AWSImgRepo.vehicle + 'en/no_image_available.png'
              ).toString();
            }}
          />
        </div>
      }
      {component.props.useCurrentYear
        ? <div className="year">
          <span>{component.props.year}</span>
        </div>
        : <Select
          name="vehicleYear"
          value={component.state.selection.year}
          onChange={component.updateModelYear.bind(component)}
          options={component.props.trimWalk ? component.props.yearOptions : component.state.modelYearOptions}
          customStyle="featSpec-dropdown"
        />
      }
      <div>
        <div>
          <Select
            name="trimEngine"
            value={component.state.selection.engine}
            onChange={component.updateEngineSelection.bind(component)}
            options={component.state.engineOptions}
            customStyle="featSpec-dropdown"
          />
          <Select
            name="fullName"
            value={trimIndex}
            onChange={component.updateTrimSelection.bind(component)}
            customOptions={component.state.trimOptions}
            customStyle="featSpec-dropdown"
          />
        </div>
      </div>
    </div>
  );
}
