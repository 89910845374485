import 'whatwg-fetch';
import httpOption from './httpOption';
import { getLocale } from './languageTranslationService';
import AppSettings from '../appsettings';

export function getArticle(id) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/articles/${id}`;
  const options = httpOption.GET();

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return resolve(response.json());
        }
        return Promise.reject(response);
      });
  });
}

export function getArticlesByIds(ids) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/articles/get-articles-title-by-ids?ids=${ids}`;
  const options = httpOption.GET();

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return resolve(response.json());
        }
        return reject(response);
      });
  });
}

export async function getArticles(recordType, limit, lastEvaluatedKey, date) {
  const formattedRecordType = encodeURIComponent(recordType.toLowerCase());

  let url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/articles/?recordType=${formattedRecordType}`;

  if (limit) {
    url = `${url}&limit=${limit}`;
  }

  if (date) {
    url = `${url}&date=${date}`;
  }

  const options = httpOption.GET();

  if (lastEvaluatedKey) {
    options.headers.LastEvaluatedKey = JSON.stringify(lastEvaluatedKey);
  }
  const responsePromise = await getArticlesRecursively(url, options, lastEvaluatedKey);
  return responsePromise
}

async function getArticlesRecursively(url, options, lastEvaluatedKey, lastResponse) {
  if (lastEvaluatedKey) {
    options.headers.LastEvaluatedKey = JSON.stringify(lastEvaluatedKey);
  }
  const responsePromise = await fetch(url, options);
  const response = await responsePromise.json();
  let finalArticleList = response;
  if (responsePromise.status >= 200 && responsePromise.status < 300) {
    let allReturnedItems = lastResponse && lastResponse.Items ? lastResponse.Items.concat(response.Items) : response.Items;
    const newResponse = response;
    newResponse.Items = allReturnedItems;
    if (response.LastEvaluatedKey && !url.includes('limit')) {
      finalArticleList = await getArticlesRecursively(url, options, response.LastEvaluatedKey, newResponse);
    } else {
      return newResponse;
    }
  }
  return finalArticleList;
}

export function getFilteredArticles(articleType, limit, lastEvaluatedKey) {
  let url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/articles?recordType=${articleType}-${getLocale()}`;
  if (limit) {
    url = `${url}&limit=${limit}`;
  }
  const options = httpOption.GET();
  if (lastEvaluatedKey) {
    options.headers.LastEvaluatedKey = JSON.stringify(lastEvaluatedKey);
  }

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return resolve(response.json());
        }
        return Promise.reject(response);
      });
  });
}

export function putArticle(article) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/articles`;
  const options = httpOption.PUT();
  options.body = JSON.stringify({
    article,
  });

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return resolve(response.json());
        }
        return Promise.reject(response);
      });
  });
}
