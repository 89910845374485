import React, { useState, useEffect, useRef } from "react";
import applyMarkdown from "../../utils/applyMarkdown";
import "../../../styles/scss/details-v2/optionsPackages.scss";
import ModelCodeNameTiles from "../modelCodeNameTiles";
import DisclaimersComponent from "../../disclaimers/disclaimers.component";
import Skeleton from "react-loading-skeleton";
import AppSettings from "../../../appsettings";


const OptionsPackagesAccordionContent = (props) => {
  const [imgUrl, setImgUrl] = useState(encodeURI(props?.s3ImagePath + props?.item?.imageFilename));

  useEffect(() => {
    setImgUrl(props?.s3ImagePath + props?.item?.imageFilename);
  }, [props?.item?.imageFilename, props?.s3ImagePath])

  return (
    <>
      <div className="description-section-options-packages">
        <div className="options-packages-image-container">
          {props.s3ImagePath && props.item.imageFilename ? (
            <img
              src={imgUrl || AppSettings.AWSImgRepo.resources + 'en/image_coming_soon_rectangle_placeholder.png'}
              alt={props.item.title}
              className="options-packages-image"
              onError={() => 
                setImgUrl(AppSettings.AWSImgRepo.resources + 'en/image_coming_soon_rectangle_placeholder.png')
            }
            />
          ) : (
            <div className="skeleton-section">
              <Skeleton width={345} height={193} count={1} inline />
            </div>
          )}
        </div>
        <div className="options-packages-info-wrapper">
          <div className="summary">
            {props.item && applyMarkdown(props.item.description, props.disclaimerComponentRef.current, null, true)}
          </div>
          <div className="options-packages-description-code-wrapper">
            <div className="options-packages-code-title">
              Code
            </div>
            <div className="options-packages-code">
              {props.item && applyMarkdown(props.item.code, props.disclaimerComponentRef.current, null, true)}
            </div>
          </div>
          <div className="options-packages-msrp-wrapper">
            <div className="options-packages-msrp-title">MSRP</div>
            <div className="options-packages-msrp-value">
              {props.item && applyMarkdown(props.item.msrp, props.disclaimerComponentRef.current, null, true)}
            </div>
          </div>
        </div>
      </div>
      <div className="availability-section-options-packages">
        <div className="availability-section-options-packages-title">Availability</div>
        <div className="package-options-model-grid">
          <ModelCodeNameTiles models={props.matchingModels} />
        </div>
      </div>
    </>
  );
}

export default OptionsPackagesAccordionContent;
