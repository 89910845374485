import { Component } from 'react';
import { getAllVehicleModels, getComparisonReport, getModelTrims } from '../../../services/comparisonService.js';
import { ThirdPartyComparisonsTemplate } from './thirdPartyComparison.tpl';
import '../../../styles/css/thirdPartyComparison.css';
import { translateText } from '../../utils/translateText';
import { getLocale } from '../../../services/languageTranslationService.js';
import { GlobalAnalytics } from '../../../analytics';
import getAlternativeVehicleNames from '../../../services/alternativeVehicleNames.js';

const userHistory = require('../../../services/userActivityService');

const analytics = new GlobalAnalytics();

export default class ThirdPartyComparisonsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialModelId: null, // '9846', // TODO remove hardcoded Camry Trim ID after routing is determined
      availableVehicles: [],
      vehicles: null,
      comparisonReport: null,
      loadingMessage: 'Loading comparison...',
      modelYear: this.props.year,
      modelName: this.props.model,
      error: null,
    };

    this.handleVehicleChange = this.handleVehicleChange.bind(this);
    this.setInitialModelID = this.setInitialModelID.bind(this);
    this.displayDataUnavailable = this.displayDataUnavailable.bind(this);
  }

  setAvailableVehicles() {
    return new Promise(
      (resolve, reject) => {
        getAllVehicleModels().then((result) => {
          this.setState({
            availableVehicles: result.makeNameList,
          });
          return resolve();
        })
          .catch((err) => {
            if (err === '408') {
              const dataUnavailableMessage = translateText('global.dataUnavailable', getLocale());
              this.setState({
                availableVehicles: dataUnavailableMessage,
              });
              reject(err);
            }
            reject(err);
          });
      },
    );
  }

  getModelTrims(modelId, error) {
    return new Promise(
      (resolve, reject) => {
        if (error) {
          this.setState({ error });
          resolve(null);
        } else {
          getModelTrims(modelId).then((trimList) => {
            resolve(trimList);
          })
            .catch(err => reject(err));
        }
      },
    );
  }

  setVehicleComparison(selectedVehicles, isPageLoad) {
    if (this.isValidVehicleList(selectedVehicles)) {
      const trimIds = this.getSelectedTrimIds(selectedVehicles);
      this.setState({
        loadingMessage: 'Loading comparison...',
      });
      getComparisonReport(trimIds)
        .then((comparisonReport) => {
          const selectedPrimary = selectedVehicles[0];
          const vehicleList = [this.mapVehicle(selectedPrimary, comparisonReport.primaryVehicle)]
            .concat(this.mapCompetitorVehicles(comparisonReport.competitorVehicles));
          this.setState({
            comparisonReport,
            vehicles: vehicleList,
            loadingMessage: '',
            error: null,
          });

          analytics.firePageLoad({
            tag: '29.1',
            section: 'Product',
            modelName: this.props.model,
            modelYear: this.props.year,
            subsection: 'Competitive Advantage',
            page: 'Competitive Advantage:Third Party Comparison',
            comparisonOne: comparisonReport.competitorVehicles[0] ? comparisonReport.competitorVehicles[0].trimID : '',
            comparisonTwo: comparisonReport.competitorVehicles[1] ? comparisonReport.competitorVehicles[1].trimID : '',
            comparisonThree: comparisonReport.competitorVehicles[2] ? comparisonReport.competitorVehicles[2].trimID : '',
          });
        })
        .catch((error) => {
          this.displayDataUnavailable();
        });
    } else {
      this.setState({
        comparisonReport: null,
        vehicles: selectedVehicles,
        loadingMessage: 'Select vehicles to compare',
        error: null,
      });
    }
  }

  getSelectedTrimIds(selectedVehicles) {
    return selectedVehicles.map(v => v.trimId);
  }

  isValidVehicleList(selectedVehicles) {
    if (!selectedVehicles || selectedVehicles.length === 0) {
      return false;
    }
    for (let i = 0; i < selectedVehicles.length; i++) {
      if (!selectedVehicles[i] || !selectedVehicles[i].trimId) {
        return false;
      }
    }
    return true;
  }

  mapVehicle(selected, report) {
    const details = this.getVehicleDetails(report, selected);
    return {
      year: report.modelYear,
      make: report.makeName,
      modelId: details.modelId,
      modelName: report.modelName,
      modelValue: details.modelValue,
      trimId: report.trimID,
    };
  }

  mapCompetitorVehicles(reportList) {
    return reportList.map(report => this.mapVehicle(null, report));
  }

  getVehicleDetails(report, selected) {
    const id = selected ? selected.modelId : null;
    const make = this.state.availableVehicles.find(v => v.makeName === report.makeName);
    const { makeName } = make;
    const modelList = make.modelNameList;
    let modelValue = null;
    let modelYear = null;
    let modelId = null;
    if (id) {
      for (let i = 0; i < modelList.length; i++) {
        modelValue = modelList[i].modelName;
        const yearList = modelList[i].modelYearList;
        for (let i = 0; i < yearList.length; i++) {
          modelYear = yearList[i].modelYear.toString();
          if (id === yearList[i].modelId.modelId) {
            modelId = yearList[i].modelId.modelId;
            break;
          }
        }
        if (modelId) {
          break;
        }
      }
    } else {
      modelValue = report.modelName;
      modelYear = report.modelYear;
      if (modelList.length > 0) {
        const model = modelList.find(m => m.modelName.toUpperCase() === modelValue.toUpperCase());
        const year = model.modelYearList.find(y => y.modelYear.toString() === report.modelYear.toString());
        modelId = year.modelId.modelId;
      }
    }
    return {
      make: makeName,
      modelValue,
      year: modelYear,
      modelId,
    };
  }

  handleVehicleChange(changed, isPrimary) {
    const selectedVehicles = this.state.vehicles;
    const newVehicle = {
      modelId: changed.state.selectedModelId,
      trimId: changed.state.selectedTrim,
    };
    if (isPrimary) {
      newVehicle.make = changed.props.vehicle.make;
    }
    selectedVehicles[changed.props.index] = newVehicle;
    this.setVehicleComparison(selectedVehicles, false);
  }

  async setInitialModelID() {
    let alternativeVehicleNames = null;
    try {
      alternativeVehicleNames = await getAlternativeVehicleNames();
    } catch (error) {
      console.log(error);
    }

    const jatoName = alternativeVehicleNames?.Items?.find(vehicleObj => 
      vehicleObj.vehicleModelName?.toLowerCase() === this.state.modelName
      )?.jatoName || this.state.modelName;

    const currentModel = this.adjustModelNameForValidModelId(jatoName.toLowerCase(), this.state.modelYear);

    return new Promise(
      (resolve, reject) => {
        let setModelId = false;
        let modelCount = 0;
        this.state.availableVehicles.reverse().forEach((obj) => {
          modelCount++;
          if (obj.makeName.toLowerCase() === 'toyota') {
            obj.modelNameList.forEach((model) => {
              if (model.modelName.toLowerCase() === currentModel) {
                setModelId = true;
                let initialModelId = null;

                // If model year exist for current year set the inital model id else set latest year
                for (let i = 0; i < model.modelYearList.length; i++) {
                  if (model.modelYearList[i].modelYear === this.state.modelYear) {
                    initialModelId = model.modelYearList[i].modelId.modelId;
                  }
                }

                this.setState({
                  initialModelId: initialModelId || model.modelYearList[model.modelYearList.length - 1].modelId.modelId,
                });
                return resolve();
              }
            });
          }
          // check by startsWith if we didn't have a direct match
          if (setModelId === false) {
            obj.modelNameList.forEach((model) => {
              if (model.modelName.toLowerCase().startsWith(this.state.modelName.toLowerCase())) {
                // if(!set)
                this.setState({
                  initialModelId: model.modelYearList[model.modelYearList.length - 1].modelId.modelId,
                });
                return resolve();
              }
            });
          }
        });
        if (modelCount === this.state.availableVehicles.length) {
          // If no model ID then JATO does not have comparison data yet.
          const error = 'JATO Data Pending...';
          return resolve(error);
        }
      },
    ).catch((err) => {
      Promise.reject(err);
    });
  }

  adjustModelNameForValidModelId(modelName, modelYear) {
    let correctModel = modelName;
    if (modelName === 'yaris sedan' && (modelYear === '2019' || modelYear === '2020')) {
      correctModel = 'yaris';
      this.setState({
        modelName: 'yaris',
      });
    }
    return correctModel;
  }

  displayDataUnavailable() {
    this.setState({
      error: true,
    });
  }

  getLegalText() {
    const year = new Date().getFullYear();
    const legalText = `Copyright © JATO \n\n Dynamics Limited, 1990-${year}. All Rights Reserved. JATO has been diligent in providing accurate and complete information. However, JATO does not warrant the accuracy or completeness of the data. Please use care in your use of the information provided.`;
    return legalText;
  }

  componentDidMount() {
    userHistory.postUserActivityRecord();
    this.setAvailableVehicles().then(() => {
      this.setInitialModelID().then((error) => {
        this.getModelTrims(this.state.initialModelId, error)
          .then((trimList) => {
            if (trimList) {
              const vehicles = [{
                make: 'TOYOTA',
                modelId: this.state.initialModelId,
                // trimId: trimList[trimList.length - 1].trimId.toString(), // JATO LOWEST TRIM SELECTED
                trimId: trimList[0].trimId.toString(), // CHROME LOWEST TRIM SELECTED
              }];
              this.setVehicleComparison(vehicles, true);
            }
          })
          .catch((error) => {
            console.log('Error:', error);
            this.displayDataUnavailable();
          });
      })
        .catch((error) => {
          console.log('Error:', error);
          this.displayDataUnavailable();
        });
    })
      .catch((error) => {
        console.log('Error:', error);
        this.displayDataUnavailable();
      });
  }

  render() {
    return ThirdPartyComparisonsTemplate(this);
  }
}
