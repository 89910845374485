import React from 'react';
import { FormattedMessage } from 'react-intl';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '../../../styles/css/overview.css';
import applyMarkdown from '../../utils/applyMarkdown';
import DisclaimersComponent from '../../disclaimers/disclaimers.component';
import { getLocale } from '../../../services/languageTranslationService.js';
import Awards from '../../awards/awards.component';
import Carousel from '../../carousel/carousel.component';

export function LegacyCAOverviewTemplate(component) {
  const keyPoints = component.state.carousel.map((item, index) => (
            <div key={index} className="text-area">
                <h3 className="headline">
                    {applyMarkdown(item.overlayHeadline, component.disclaimer)}
                </h3>
                <div className="sub-headline">
                    {applyMarkdown(item.overlaySubheadline, component.disclaimer)}
                </div>
            </div>
  ));

  return (
        <div className="legacy-ca-overview">
            <div className="row">
                <div className="medium-12 large-6 columns tagline-box">
                    <div className="tagline">
                        {applyMarkdown(component.state.tagLine, component.disclaimer)}
                    </div>
                </div>
                <div className="medium-12 large-6 columns keyPoints">
                    {keyPoints.length > 0 ? <Carousel {...{
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: 'false',
                        dots: true,
                      },
                    }}>{keyPoints}</Carousel> : null}
                </div>
            </div>
            <div>
                <div className="awards">
                    <h1><FormattedMessage id="vehicleOverview.awardsTitle" /></h1>
                    <Awards modelName={component.props.model} modelYear={component.props.year} lang={getLocale()} />
                </div>
            </div>
            <DisclaimersComponent template={`${component.props.year}_${component.props.model}_overview`} ref={(instance) => { component.disclaimer = instance; }} />
        </div>

  );
}
