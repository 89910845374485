import React, { useEffect, useState } from "react";
import AppSettings from "../../appsettings";
import { getArticles } from "../../services/articlesService";
import getAnalytics from '../../services/analyticsService';
import getMisc from '../../services/miscService.js';
import "../../styles/css/news/news.css";
import BookmarkPageComponent from "../bookmarks/bookmarkPage.component";
import NewsCarousel from './newsCarousel/newsCarousel';
import NewsArchive from "./newsArchive/newsArchive";
import NewsHeader from "./newsHeader";
import DropDownV2 from "../../components/molecules/DropdownV2";
import * as analytics from "./news.analytics.js";
import MobileBackButton from "../../components/molecules/MobileBackButton";

const News = () => {
  const [newsData, setNewsData] = useState({
    title: "News & Announcements",
    newsArchive: { title: "News Archive", filters: [] },
  });
  const [articles, setArticles] = useState();
  const [carouselArticles, setCarouselArticles] = useState([])
  const [articleDate, setArticleDate] = useState(
    `${new Date().toLocaleString("en-US", {
      month: "long",
    })} ${new Date().getFullYear()}`
  );
  const [dateOptions, setDateOptions] = useState([""]);
  const [sortValue, setSortValue] = useState("Most Recent");
  const redDownChevron = AppSettings.AWSImgRepo.resources + 'en/chevron_down_red.png';
  const dropdownClosed = <img className="closed" src={redDownChevron} />;
  const dropdownOpen = <img className="open" src={redDownChevron} />;
  const [analyticsData, setAnalyticsData] = useState(null);

  const getNewsAnalytics = async () => {
    const data = await getAnalytics('news');
    setAnalyticsData(data);
    if (data && data.pageLoad) {
      analytics.pageLoad(data.pageLoad, articleDate);
    }
  };

  useEffect(() => {
    getFilteredArticles("Article-en", articleDate, sortValue);
    getMisc('HomepageV2').then((response) => {
      setCarouselArticles(response.items.homepage.content);
    });
    populateOptions();
    getNewsAnalytics();
  }, []);

  const handleDateDropdownValueChange = (dropdownValue) => {
    getFilteredArticles("Article-en", dropdownValue.value, sortValue);
    setArticleDate(dropdownValue.value);
    analytics.changeMonth(analyticsData.clickAnalytics.changeMonth, articleDate, dropdownValue.value.toString());
  };

  const handleSortDropdownValueChange = (dropdownValue) => {
    getFilteredArticles("Article-en", articleDate, dropdownValue.value);
    setSortValue(dropdownValue.value.toString());
    analytics.filterDateDropdownSelect(analyticsData.clickAnalytics.filterDate, articleDate, dropdownValue.value.toString());
  };

  const getFilteredArticles = async (recordType, date, sortValue) => {
    const MONTHS = {
      "January": "01",
      "February": "02",
      "March": "03",
      "April": "04",
      "May": "05",
      "June": "06",
      "July": "07",
      "August": "08",
      "September": "09",
      "October": "10",
      "November": "11",
      "December": "12",
    };
    const dateValues = date.split(' ');
    const month = MONTHS[dateValues[0]];
    const year = dateValues[1]
    const dateString = `${year}-${month}`;

    const curArticles = await getArticles(
      recordType,
      undefined,
      undefined,
      dateString
    );

    if (curArticles) {
      const tempArticles = curArticles.Items;
      const sortedList = tempArticles.sort((a, b) => {
        const titleA = a.title.toUpperCase();
        const titleB = b.title.toUpperCase();

        const dateA = Date.parse(a.createdDate);
        const dateB = Date.parse(b.createdDate);

        if (sortValue.toString().toLowerCase() === "a-z") {
          // eslint-disable-next-line no-nested-ternary
          return titleA < titleB ? -1 : titleA > titleB ? 1 : 0;
        }
        if (sortValue.toString().toLowerCase() === "z-a") {
          // eslint-disable-next-line no-nested-ternary
          return titleA < titleB ? 1 : titleA > titleB ? -1 : 0;
        }
        if (sortValue.toString().toLowerCase() === "oldest to newest") {
          return dateA - dateB;
        }

        return dateB - dateA;
      });

      setArticles(sortedList);
    }
  };

  const populateOptions = async () => {
    const dates = [];
    for (let year = new Date().getFullYear(); year >= 2018; year--) {
      for (let month = 11; month >= 0; month--) {
        if (
          !(year === new Date().getFullYear() && month > new Date().getMonth())
        ) {
          dates.push(
            `${new Date(0, month).toLocaleString("en-US", {
              month: "long",
            })} ${year}`
          );
        }
      }
    }
    setDateOptions(dates);
  };

  return (
    <div className="newsDiv">
      {
        window.ReactNativeWebView &&
        <MobileBackButton containerStyle={{
          top: '30px',
          left: '12px',
          display: 'flex',
          placeContent:'center',
        }} style={{ top: '4px', left:'7px' }}/>
      }
      <NewsHeader title={newsData.title} />
      <div className="newsBookmark" onClick={() => { analytics.bookmarkClick(analyticsData.clickAnalytics.bookmark, articleDate); }}>
        <BookmarkPageComponent title={newsData.title} url={"/news"} isButton />
      </div>
      <NewsCarousel
          articles={carouselArticles}
          imagePath={AppSettings.AWSImgRepo.homepage_v2}
          analytics={analytics}
          analyticsData={analyticsData}
          articleDate={articleDate}
      />
      <div className="newsArchiveHeader">
        <div className="newsArchiveFiltering">
          <div className="newsArchiveTitle">{newsData.newsArchive.title}</div>
          <div className="sortSelector">
          <DropDownV2
              style={"black-red"}
              onValueChange={(value) => handleDateDropdownValueChange(value)}
              options={dateOptions}
              disabled={false}
              defaultValue={"Change Month"}
            />
            <DropDownV2
              style={"black-red"}
              onValueChange={(value) => handleSortDropdownValueChange(value)}
              options={["Most Recent", "Oldest to Newest", "A-Z", "Z-A"]}
              disabled={false}
              defaultValue={"Sort by"}
            />
          </div>
        </div>
        <div className="monthContainer">
          <div className="newsArchiveDate">{articleDate}</div>
        </div>
      </div>
      <NewsArchive
        newsArchive={newsData.newsArchive}
        articles={articles}
        date={articleDate}
        analytics={analytics}
        analyticsData={analyticsData}
        pageDate={articleDate}
      />
    </div>
  );
};

export default News;