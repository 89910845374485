import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import AppSettings from '../../appsettings.js';
import { getLocale } from '../../services/languageTranslationService';
import getMisc from '../../services/miscService';
import SubheaderComponent from '../subheader/subheader.component';
import Display404NotFound from '../../components/atoms/404NotFound.js';
import { handleSubheaderClick, linkClick, pageLoad, readMoreClick } from './smartpath-analytics.js';
import { SmartPathOverview } from './smartpath-overview.js';
import { SmartPathTraining } from './smartpath-training.js';
import { SPFAQComponent } from './spFAQ.component';
import { SPTestimonialsComponent } from './spTestimonials.component';
import * as analytics from './smartpath-overview.analytics';
import getAnalytics from '../../services/analyticsService';
import MobileBackButton from '../../components/molecules/MobileBackButton.js';


export const SmartPathRouterComponent = (props) => {
  const [pageData, setPageData] = useState(null);
  const [languageOverride, setLanguageOverride] = useState(false);
  const history = useHistory();
  const params = useParams();
  const [analyticsData, setAnalyticsData] = useState(null);

  const getSmartPathAnalytics = async () => {
    const data = await getAnalytics('smartPath');
    setAnalyticsData(data);
  };

  useEffect(() => {
    getSmartPathAnalytics();
  }, []);

  useEffect(() => {
    const language = getLocale();
    getMisc('SP', language)
      .then((sptPageData) => {
        if (Object.keys(sptPageData).length === 0 && sptPageData.constructor === Object) {
          setLanguageOverride(true);
        } else {
          setPageData(sptPageData);
        }
      })
      .catch((error) => {
        console.error(`ERROR: failed to load smartPath page data. ${error}`);
      });
    if (languageOverride && !pageData) {
      getMisc('SP', 'en')
        .then((sptPageData) => {
          setPageData(sptPageData);
        })
        .catch((error) => {
          console.log(`ERROR: failed to load smartPath page data. ${error}`);
        });
    }
  }, [props]);

  const getSmartPathImagePath = () => AppSettings.AWSImgRepo.smartPath;

  const getSubheaderChildComponent = (path) => {
    let childComponent;
    switch (path ? path.toLowerCase() : '') {
      case 'about':
        history.push('/smartPath/overview');
        break;
      case 'overview':
        childComponent = <SmartPathOverview
          overviewData={pageData.items.overview}
          disclaimer={'SP'}
          imagePath={getSmartPathImagePath()}
          languageOverride={languageOverride || false}
          analyticsData={analyticsData}
        />;
        break;
      case 'training':
        childComponent = <SmartPathTraining
          trainingData={pageData.items.training}
          disclaimer={'SP'}
          linkClick={linkClick}
          readMoreClick={readMoreClick}
          imagePath={getSmartPathImagePath()}
          languageOverride={languageOverride || false}
        />;
        break;
      case 'testimonials':
        childComponent = <SPTestimonialsComponent
          testimonials={pageData.items.testimonials}
          page={'Testimonials'}
          disclaimer={'SP'}
          imagePath={getSmartPathImagePath()}
          imageArrow={false}
          languageOverride={languageOverride || false}
        />;
        break;
      case 'faqs':
        childComponent = <SPFAQComponent
          faqs={pageData.items.faqs}
          disclaimer={'SP'}
          imagePath={getSmartPathImagePath()}
          languageOverride={languageOverride || false}
          pageLoad={pageLoad}
        />;
        break;
      default:
        history.push('/smartPath/overview');
        break;
    }

    return childComponent;
  };

  const handleSubheaderClick = (link) => {
    if (analyticsData && analyticsData.clickAnalytics && link) {
      analytics.subheaderLinkClick(analyticsData.clickAnalytics, link);
    }
  };

  const isValidTabName = () => ['overview', 'training', 'faqs', 'testimonials', 'about'].filter(x => x === params.page).length > 0;
  const getPageDisplay = () => {
    if (isValidTabName()) {
      return (
        <div>
        {pageData && <SubheaderComponent 
          {...props} 
          params={params} 
          route={params} 
          smartPathAnalytics={(link) => handleSubheaderClick(link)}
          bookmarkNavClickEvent={(isBookmarked) => analytics.bookmarkClick(analyticsData && analyticsData.clickAnalytics, _.startCase(params.page), isBookmarked)}
          subheaderNavClickEvent={(destinationPage) => analytics.subheaderClick(analyticsData && analyticsData.clickAnalytics, _.startCase(params.page), destinationPage)}
        >
            {getSubheaderChildComponent(params.page)}
          </SubheaderComponent>
        }
      </div>
      );
    }
    return <Display404NotFound display />;
  };

  return (
  <div>{getPageDisplay()}</div>
  );
};

export default injectIntl(SmartPathRouterComponent);
