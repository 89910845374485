import React from 'react';
import RoundedButton from '../../components/molecules/roundedButton';
import '../../styles/scss/homepageHero.scss';
import AppSettings from '../../appsettings';
import { isMobileView, isDesktopView } from '../utils/getScreenSize';
import getTarget from "../utils/getTarget";
import applyMarkdown from '../utils/applyMarkdown';
import { heroButtonClicked } from './homepage.analytics';

export const Hero = (props) => {

    const hasTextContent = (props.badgeText || props.smallTitle || props.largeTitle || props.description);

    const getBackground = () => {
        const direction = isMobileView(window.innerWidth) ? 'bottom' : 'right';
        const url = isMobileView(window.innerWidth) ? props.mobileImage : props.desktopTabletImage;
        let imgSource;
        if (hasTextContent) {
            imgSource = `url(${AppSettings.AWSImgRepo.homepage_v3 + url})`;
        } else {
            imgSource = AppSettings.AWSImgRepo.homepage_v3 + url
        }
        if(props.overlayGradient) {
            return `linear-gradient(to ${direction}, ${props.overlayGradient} 0%, #ffffff00 50%), ${imgSource})`;
        } else {
            return imgSource;
        }
        
    }

    const buttonClick = (e) => {
        e.stopPropagation();
        heroButtonClicked(props.analytics, props.buttonText, props.buttonLink.substring(10));
        window.open(props.buttonLink, getTarget(props.buttonLink));
    }

    const heroClick = () => {
        props.heroLink && window.open(props.heroLink, getTarget(props.heroLink));
    }

    const getHoverColor = (contentColor) => {
        const color = (contentColor.charAt(0) === '#') ? contentColor.substring(1, 7) : contentColor;
        const r = parseInt(color.substring(0, 2), 16);
        const g = parseInt(color.substring(2, 4), 16);
        const b = parseInt(color.substring(4, 6), 16);
        return ((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186 ? 'darkHover' : 'lightHover';
    }

  return (
      <div className={`
        homepage-hero-container 
        ${!hasTextContent ? 'homepage-hero-no-text' : ''} 
        ${props.heroLink ? 'homepage-hero-cursor-pointer' : ''}
        `}
          style={hasTextContent && { backgroundImage: getBackground() }}
          onClick={heroClick}>
          {!hasTextContent && <img src={getBackground()} />}

          {props.badgeText && <div className='hero-badge'
              style={{ color: props.badgeTextColor, background: props.badgeColor }}
          >{props.badgeText}</div>}
          {props.smallTitle && <div className='hero-small-title' style={{ color: props.contentColor }}>{props.smallTitle}</div>}
          {props.largeTitle && <div className='hero-large-title' style={{ color: props.contentColor }}>{applyMarkdown(props.largeTitle)}</div>}
          {props.description && <div className='hero-description' style={{ color: props.contentColor }}>{applyMarkdown(props.description)}</div>}
          {props.buttonText && <RoundedButton
              title={props.buttonText}
              className={getHoverColor(props.contentColor)}
              id={!hasTextContent && 'no-text-homepage-button'}
              color={'#00000000'}
              activeColor={getHoverColor(props.contentColor) === 'darkHover' ? 'rgba(0, 0, 0, 0.28)' : 'rgba(255, 255, 255, 0.28)'}
              borderColor={props.contentColor}
              textColor={props.contentColor}
              height={'43px'}
              onClick={buttonClick}
          />}

      </div>
  );
};