import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import RedLine from '../../components/atoms/RedLine';
import applyCustomMarkdown from '../utils/applyCustomMarkdown';

const VinHeader = () => {
  const vinDescription = 'Find and share accurate information on any **in-stock vehicle (new or TCUV).** Vehicle results include:';
  return <React.Fragment >
        <div className='vinHeader'>
            <FormattedMessage id='vinLookup.subHeader' />
            <div className='vinHeaderRedLine'>
                <RedLine/>
            </div>
        </div>
        <div className='vinDescription'>
            {applyCustomMarkdown(vinDescription, 'bold')}
        </div>
        <ul className='vinDescriptionList'>
            <li><span className='vinListItem'><FormattedMessage id='vinLookup.list.msrp' /></span></li>
            <li><span className='vinListItem'><FormattedMessage id='vinLookup.list.featuresAndSpecs' /></span></li>
            <li><span className='vinListItem'><FormattedMessage id='vinLookup.list.options' /></span></li>
        </ul>
    </React.Fragment >;
};

export default injectIntl(VinHeader);
