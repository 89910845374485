import React from 'react';
import PropTypes from 'prop-types';
import ContactInfo from '../../atoms/ContactInfo';
import TitleText from '../../atoms/TitleText';
import '../../../styles/css/flatIconCard.css';


const flatIconCard = (props) => (
  <React.Fragment>
    <div className="flat-icon-card-header">
      <img src={props.imagePath + props.iconFile} alt="icon" className="flat-icon-card-icon"></img>
      <TitleText className="flat-icon-card-title">{props.title}</TitleText>
    </div>
    <div className="flat-icon-card-description">{props.description}</div>
    {props.contact && <div className="contact-info">
      <ContactInfo
        name={props.contact.name}
        phone={props.contact.phone}
        email={props.contact.email}
      />
    </div>}
    {props.footnote && <div className="flat-icon-card-footnote">{props.footnote}</div>}
  </React.Fragment>
);

flatIconCard.propTypes = {
  contact: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
  }),
  description: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  foonote: PropTypes.string,
  iconFile: PropTypes.string,
  imagePath: PropTypes.string,
  title: PropTypes.string,
};

export default flatIconCard;
