import { GlobalAnalytics } from "../../analytics";

const analytics = new GlobalAnalytics();

// Page Load
export const xpOverviewPageLoad = (pageLoad) => {
  pageLoad &&
    analytics.firePageLoad({
      app: pageLoad.appId,
      tag: pageLoad.tagId,
      section: pageLoad.section,
      page: pageLoad.page,
      subsection: pageLoad.subsection,
      technologyName: pageLoad.technology_name,
    });
};

// Tracking click for a tab click
export const tabClick = (clickAnalytics, tabTitle) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: "Engage XP",
      category: "Trivia",
      label: "Tab Selection Click",
      contentTitle: tabTitle
    });
  }
};

// Tracking click for the submitting the dropdown click
export const dropdownChangeClick = (clickAnalytics, contentTitle) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: "Engage XP",
      category: "Trivia",
      label: "Tabs Section Dropdown",
      contentTitle: contentTitle
    });
  }
};

export const playNowButtonClick = (clickAnalytics, label) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: "Engage XP",
      category: "What is XP",
      label: label,
    });
  }
};
