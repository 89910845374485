import React, { useContext, useEffect, useState, useRef } from 'react';
import Modal from 'react-responsive-modal';
import '../../styles/css/scrollingModal.css'
import RedLine from '../atoms/RedLine';
import isAppleMobileDevice from '../../screens/utils/isAppleMobileDevice';
import { isMobileView } from '../../screens/utils/getScreenSize';

/*
required props:
    open flag
    closeModal function
        should include close modal click analytics
    children (nested jsx (display with props.children))

optional props:
    title
        string or JSX
        if you want your title to scroll with the content, include it with the child component
        if you want a static positioned title with a bottom red line, pass as title prop

    width
        width of modal pop up (can also be defined in scss as minWidth and maxWidth)
        if smaller than screen width, sets minWidth and maxWidth as width prop
        if greater than screen width, sets minWidth and maxWidth as window.innerWidth - 40
    minwidth
    maxwidth
        pass in different min and max widths
        applied the same as width prop

    showCloseIcon (default true)
    closeOnOverlayClick (default true)
    center (default true)

    modalClassName
    closeIconClassName
    overlayClassName
        component specific class name to style modal based on content
        string

analytics props:
    openModalPageLoad
        open modal page load anaytics function
    * close modal click analytics should be passed in through closeModal function prop
*/

const ScrollingModal = (props) => {
    const [overflowActive, setOverflowActive] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const textRef = useRef();

    useEffect(() => {
        if(props.visible) {
            //disable background body scrolling and input field zoom
            disableMobileScrolling();
            props.openModalPageLoad && props.openModalPageLoad();
        } else {
            enableMobileScrolling();
        }
    }, [props.visible]);

    const disableMobileScrolling = () => {
        if(isAppleMobileDevice()) {
            const y = window.scrollY;
            setScrollPosition(y);
            document.getElementsByClassName("off-canvas-wrapper")[0].style.top = -y + 'px';

            document.getElementsByTagName("html")[0].classList.add("overlay-disable-body-scroll");
            document.body.classList.add("overlay-disable-body-scroll");
            document.getElementsByName("viewport")[0].setAttribute("content", "width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0");
        }
    }

    const enableMobileScrolling = () => {
        if(isAppleMobileDevice()) {

            document.getElementsByTagName("html")[0].classList.remove("overlay-disable-body-scroll");
            document.body.classList.remove("overlay-disable-body-scroll");
            document.getElementsByName("viewport")[0].setAttribute("content", "width=device-width, initial-scale=1");

            if (!props.disableAutoScrolling) {
                window.scrollTo(0, scrollPosition);
            }

            document.getElementsByClassName("off-canvas-wrapper")[0].style.top = 0;
        }
    }

    const isOverflowActive = () => (textRef.current.offsetHeight > visualViewport.height*.8);

    useEffect(() => {
        textRef.current ? setOverflowActive(isOverflowActive()) : setOverflowActive(null);
    }, [isOverflowActive]);

    useEffect(() => {
        return () => {
            props.visible && closeModal();
            enableMobileScrolling();
        };
    }, []);

    const closeModal = () => {
        props.closeModalClick && props.closeModalClick();
        props.closeModal();
    };

    const getNonScrollModalHeader = () => (
        <>
            <div className="modalTitle">{props.title}</div>
            <RedLine style={{
                margin: 'auto',
                width: '71px',
                borderBottom: '3px solid #EB0A1E',
                marginTop: '12px',
                marginBottom: '35px'}} />
        </>
    );

    const getModalContent = () => ( 
        <div className={`modalScrollArea ${props.title ? "" : "noHeader"}`}>
            <div className="innerScrollArea" ref={textRef}>
                {props.children}
            </div>
        </div>
    );

    const getWidth = (width) => (width < window.innerWidth ? width : window.innerWidth-40);

    return(
        props.visible ? <Modal 
            open={props.visible} 
            onClose={()=>closeModal()}
            blockScroll={!isAppleMobileDevice()}
            styles={{
                modal: {
                    //if not passed as props, falls back onto scss at react-responsive-modal-modal
                    maxWidth: props.width ? getWidth(props.width) : (props.maxWidth ? getWidth(props.maxWidth) : null),
                    minWidth: props.width ? getWidth(props.width) : (props.minWidth ? getWidth(props.minWidth) : null),
                },
            }}
            classNames={{
                modal: `${props.modalClassName} ${overflowActive && "maxScroll"}`,
                close: props.closeIconClassName,
                overlay: props.overlayClassName
            }}
            showCloseIcon={props.showCloseIcon === false ? props.showCloseIcon : true}
            center={props.center === false ? props.center : true}
            closeOnOverlayClick={props.closeOnOverlayClick === false ? props.closeOnOverlayClick : true}
        >
            <div className="modalContent">
                {props.title && getNonScrollModalHeader()} 
                {props.children && getModalContent()}
            </div>
        </Modal> : <></>
    )
}

export default ScrollingModal