import React, {useState} from "react";
import { isMobileView } from "../../utils/getScreenSize";
import "../../../styles/css/beyondZero/vehicleComparisonChart.css";

const VehicleComparisonChart = ({veh1ChartData, veh2ChartData, veh3ChartData, showThirdComp}) => {

    const headers = [
        'Starting Price',
        'Horsepower',
        'MPG',
        'Hybrid Drive Battery',
        'Hybrid Drive Battery Warranty',
        'Hybrid System Warranty',
        'Assembly',
        '5-Year Cost to Own',
    ];

    const formatData = (data) => {
        if (data && data !== '' ) {
            return data;
        } else {
            return <div className="empty-data-dash"/>;
        }
    };

    
    return (
        <div className="bz-vehicle-comparison-chart">
            <div className="chart-vehicle-names-section">
                <div className="information-container">
                    <div className={`name-space ${showThirdComp && 'third-comparison'}`}>
                        <div className="vehicle-name">
                            {veh1ChartData?.vehicleName}
                        </div>

                        <div className="vehicle-name">
                            {veh2ChartData?.vehicleName}
                        </div>

                        {showThirdComp && !isMobileView(window.innerWidth) &&
                            <div className="vehicle-name">
                                {veh3ChartData?.vehicleName}
                            </div>
                        }
                    </div>

                    {!isMobileView(window.innerWidth) &&
                        <div className={`spacer ${showThirdComp && 'third-comparison'}`}/>
                    }
                </div>
            </div>
            <div className="chart-data-section">
                {headers.map((header, index) => {
                    return (
                        <div className="chart-row">
                            <div className="header-space">
                                {header}
                            </div>

                            <div className={`data-space ${showThirdComp && 'third-comparison'}`}>
                                <div className="data-point">
                                    {formatData(veh1ChartData?.vehicleData[index])}
                                </div>

                                <div className="data-point">
                                    {formatData(veh2ChartData?.vehicleData[index])}
                                </div>

                                {showThirdComp && !isMobileView(window.innerWidth) &&
                                    <div className="data-point">
                                        {formatData(veh3ChartData?.vehicleData[index])}
                                    </div>
                                }
                            </div>

                            {!isMobileView(window.innerWidth) &&
                                <div className={`spacer ${showThirdComp && 'third-comparison'}`}/>
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default VehicleComparisonChart;
