import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import applyMarkdown from '../../utils/applyMarkdown';
import { hasVideoId } from '../../utils/helpers';
import TitleText from '../../../components/atoms/TitleText';
import RedLine from '../../../components/atoms/RedLine';
import VideoPlayerComponent from '../../videoPlayer/videoPlayer.component';
import { fireModelOverviewClick } from '../modelOverview.analytics';
import '../../../styles/css/model-overview/title-section.css';

const backgroundImageUrl = (imagePath, backgroundImage) => (`url(${encodeURI(imagePath + backgroundImage)})`);
const mobileImageUrl = (imagePath, mobileImage) => (`url(${encodeURI(imagePath + mobileImage)})`);


const titleSection = (sectionData, imagePath, styles, disclaimer, isMobile) => {
  const videoItem = {
    alias: sectionData.brightcoveId,
    qumuId: sectionData.qumuId,
    videoTitle: sectionData.videoTitle,
    pageTitle: 'Model Overview',
  };

  return (
    <React.Fragment>
      <div className="row hero-image-background" style={{
        backgroundImage: isMobile ? null : backgroundImageUrl(imagePath, sectionData.backgroundImage),
        backgroundColor: styles.backgroundColor ? styles.backgroundColor : 'none',
      }}>
        <div className="hero-image-background-mobile" style={{
          backgroundImage: isMobile ? mobileImageUrl(imagePath, sectionData.mobileImage) : null
        }}>
          <div className="model-overview-title-section small-12 large-6 columns">
            <TitleText className="headline" style={{ color: styles.textColor ? styles.textColor : '#FFFFFF' }}>
              {applyMarkdown(sectionData.headline, disclaimer)}
              <RedLine />
            </TitleText>
            {
              sectionData.startingMsrp
              && <React.Fragment>
                <div className="msrp" style={{ color: styles.textColor ? styles.textColor : '#FFFFFF' }}>
                  {`$${sectionData.startingMsrp}`}
                </div>
                <div className="msrp-caption">&nbsp;STARTING MSRP<sup className="disclaimer-link" onClick={() => disclaimer.onOpenModal()}>&dagger;</sup></div>
              </React.Fragment>
            }
            <React.Fragment>
              <div className="video">
                {
                  hasVideoId(sectionData.brightcoveId, sectionData.qumuId) && <React.Fragment>
                    <VideoPlayerComponent
                      item={videoItem}
                      sendVideoEventAnalytics={() => fireModelOverviewClick('Video Image Click', sectionData.videoTitle, 'Hero')}
                    />
                    <div style={{ color: styles.textColor ? styles.textColor : '#FFFFFF' }} className="overview-video-title">{sectionData.videoTitle}</div>
                  </React.Fragment>
                }
              </div>
            </React.Fragment>
          </div>
        </div>
        <div className="model-overview-title-section small-12 large-6 columns">
          <div className="lower">
            {
              sectionData.subheadline
              && <TitleText className="subheadline" style={{ color: styles.textColor ? styles.textColor : '#FFFFFF' }}>
                {applyMarkdown(sectionData.subheadline, disclaimer)}
              </TitleText>
            }
            {
              sectionData.description
              && <div className="description" style={{ color: styles.textColor ? styles.textColor : '#FFFFFF' }}>
                {applyMarkdown(sectionData.description, disclaimer)}
              </div>
            }
          </div>
        </div>
      </div >
    </React.Fragment>
  );
};

const startingMsrp = msrp => msrp ? `$${msrp.toString()}` : 'COMING SOON';

const titleSectionPrintView = (sectionData, imagePath, modelName, disclaimer) => (
  <div className="row hero-image-background">
    <img src={`${encodeURI(imagePath + sectionData.printImage)}`} />
    <div className="model-overview-title-section small-12 columns">
      <TitleText className="headline">
        {applyMarkdown(sectionData.headline, disclaimer)}
      </TitleText>
      <div className="msrp">
        <div className="msrp-print-caption">{startingMsrp(sectionData.startingMsrp)}&nbsp;&nbsp;STARTING MSRP</div>
        <RedLine style={{
          position: 'absolute', margin: '0px', top: '-85px', left: '25px',
        }} />
      </div>
      <div className="title-section-model-name">{applyMarkdown(modelName, disclaimer)}</div>
    </div>
  </div>
);

export default function TitleSection(props) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => { window.removeEventListener('resize', handleResize); };
  });

  return (
    <React.Fragment>
      <div className="model-overview-web-content">{titleSection(props.titleSection, props.imagePath, props.styles, props.disclaimer, isMobile)}</div>
      <div className="model-overview-print-content">{titleSectionPrintView(props.titleSection, props.imagePath, props.modelName, props.disclaimer)}</div>
    </React.Fragment>
  );
}

TitleSection.propTypes = {
  titleSection: PropTypes.object.isRequired,
  imagePath: PropTypes.string,
  modelName: PropTypes.string,
  disclaimer: PropTypes.node,
  styles: PropTypes.object,
};
