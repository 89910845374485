import 'whatwg-fetch';
import httpOption from './httpOption';
import AppSettings from '../appsettings';

const baseUrl = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/compare/`;

const options = httpOption.GET();

export function getComparisonReport(trimIds) {
  const url = getReportRequestUrl(trimIds);
  const errorText = `Error: getComparisonReport() failed; url = ${url}`;

  return fetch(url, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
      }
      return Promise.reject({ error: errorText });
    })
    .catch(error => Promise.reject({ error: errorText }));
}

function getReportRequestUrl(trimIds) {
  const vehicleTrimId = trimIds[0];
  const competitorTrimIds = trimIds.slice(1, 4).join();
  const queryString = getReportRequestQueryString(competitorTrimIds);

  const url = `${baseUrl}vehicleComparison/${vehicleTrimId}${queryString}`;
  return url;
}

function getReportRequestQueryString(competitorTrimIds) {
  const queries = [];
  if (competitorTrimIds) {
    queries.push(`competitorTrimIds=${competitorTrimIds}`);
  }
  if (window.innerWidth < 640) {
    queries.push(`mobile=${true}`);
  }
  if (queries && queries.length) {
    return `?${queries.join('&')}`;
  }
  return '';
}

export function getAllVehicleModels() {
  const url = `${baseUrl}vehicleModels/`;
  const errorText = 'Error: getAllVehicleModels() failed';
  return fetch(url, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
      }
      if (response.status === 408) {
        return Promise.reject('408');
      }
      return Promise.reject({ error: errorText });
    });
}

export function getModelTrims(modelId) {
  const url = `${baseUrl}modelTrims/${modelId}`;
  const errorText = `Error: getModelTrims() failed; modelId = ${modelId}`;
  return fetch(url, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
      }
      return Promise.reject({ error: errorText });
    });
}
