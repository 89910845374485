import { GlobalAnalytics } from "../../analytics";

const analytics = new GlobalAnalytics();

export function modelTrimCodeDropdownClick(event) {
  analytics.fireOnClick({
    tag: "29.2",
    section: "Product",
    page: event?.modelName,
    category: "Overview",
    label: "Trim Dropdown",
    modelName: event?.modelName,
    modelYear: event?.modelYear,
    contentTitle: event?.trimCode,
    positionTitle: "Banner",
  });
}

export function modelMSRPDropdownClick(event) {
  analytics.fireOnClick({
    tag: "29.2",
    section: "Product",
    page: event?.modelName,
    category: "Overview",
    label: "Model Bed Dropdown",
    modelName: event?.modelName,
    modelYear: event?.modelYear,
    contentTitle: event?.modelBedSelected,
    positionTitle: "Banner",
  });
}
