import React, { useState, useEffect } from "react";
import "../../../styles/scss/vehicleDetails/colorsv2.scss";
import appsettings from "../../../appsettings";
import ViewLargerImages from "../../../components/molecules/viewLargerImages";
import Skeleton from "react-loading-skeleton";
import { postColorAction } from "../../../services/nitroService";
import ColorSelector from "./colorSelectorsV2";
import DisclaimerPopup from "./disclaimerPopup";
import * as _ from 'lodash';

import { toggleLargeImages, colorClick, largeModalButtonClick } from './colors.analytics';

const ColorsV2 = (props) => {
    const [availableExtColorCodes, setAvailableExtColorCodes] = useState(); // use to control which exterior swatches are enabled/disabled
    const [selectedExtColor, setSelectedExtColor] = useState(); // use to display selected exterior color details/image/check mark
    const [availableIntColorCodes, setAvailableIntColorCodes] = useState(); // use to control which interior color swatches are enabled/disabled
    const [availableIntColorNames, setAvailableIntColorNames] = useState();
    const [selectedIntColor, setSelectedIntColor] = useState(); // use to display selected interior color details/image/check mark
    const [exteriorImage, setExteriorImage] = useState({ src: '', error: false }); // use to display exterior image and handle its errors
    const [interiorImage, setInteriorImage] = useState({ src: '', error: false }); // use to display interior image and handle its errors
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
      setIsPopupOpen(!isPopupOpen);
    };

    useEffect(() => {
        if (props.trims && props.trimOptions && props.selectedTrim) {
            // exterior colors available for the selected trim
            const selectedTrimColors = props.trims?.find(t => t.name === props.selectedTrim?.name)?.colorsAvailableV2;

            // list of available exterior color codes
            const trimExtColors = selectedTrimColors?.map(c => c.exteriorColorCode);
            setAvailableExtColorCodes(trimExtColors);

            //set initial external color code
            const initialExtColor = props.trimOptions.exteriorColors?.filter(c => trimExtColors?.includes(c.paintCode))[0];
            setSelectedExtColor(initialExtColor)

            // list of all available internal color codes
            const trimIntColors = _.uniq(selectedTrimColors?.map(c => c.interiorColors?.map(ic => ic.interiorColor)).flat(2));
            setAvailableIntColorCodes(trimIntColors);

            const trimIntColorNames = _.uniq(selectedTrimColors?.map(c => c.interiorColors?.map(ic => ic?.interiorColorName)).flat(2)).filter(el => el !== undefined);
            setAvailableIntColorNames(trimIntColorNames);

            // selected first interior color
            let initialIntColor;
            if (trimIntColorNames.length > 0) {
                initialIntColor = props.trimOptions.interiorColorsV2?.find(c => trimIntColors?.includes(c.identifier) && trimIntColorNames?.includes(c.name));
            } else {
                initialIntColor = props.trimOptions.interiorColorsV2?.find(c => trimIntColors?.includes(c.identifier));
            }
            
            
            console.log(initialIntColor)
            setSelectedIntColor(initialIntColor)
        }
    }, [props.trims, props.trimOptions, props.selectedTrim]);

    useEffect(() => {
        if (selectedExtColor) {
            const selectedExtItem = props.trims
                ?.find(t => t.name === props.selectedTrim?.name)
                ?.colorsAvailableV2
                ?.find(c => c.exteriorColorCode === selectedExtColor.paintCode);

            setExteriorImage({
                src: appsettings.AWSImgRepo.vehicle + props.year + "/" + props.s3PathName + "/en/" + selectedExtItem?.exteriorColorImage,
                error: false,
            });

            const trimIntColors = _.uniq(selectedExtItem?.interiorColors?.map(ic => ic.interiorColor)).flat(2);
            setAvailableIntColorCodes(trimIntColors);

            // if currently selected int color is not avaialble on the newly selected ext color, select first interior color available 
            if (!trimIntColors.includes(selectedIntColor?.identifier)) {
                const initialIntColor = props.trimOptions.interiorColorsV2.filter(c => trimIntColors.includes(c.identifier))[0];
                setSelectedIntColor(initialIntColor)
            }
        }
    }, [selectedExtColor, props.s3PathName, props.selectedTrim]);

    useEffect(() => {
        if (selectedIntColor) {
            const selectedIntItem = props.trims
                ?.find(t => t.name === props.selectedTrim?.name)
                ?.colorsAvailableV2
                ?.find(c => c.exteriorColorCode === selectedExtColor?.paintCode)
                ?.interiorColors
                ?.find(c => c.interiorColor === selectedIntColor.identifier);

            setInteriorImage({
                src: appsettings.AWSImgRepo.vehicle + props.year + "/" + props.s3PathName + "/en/" + selectedIntItem?.interiorColorImage,
                error: false,
            });
        } 
    }, [selectedIntColor, selectedExtColor, props.s3PathName, props.selectedTrim]);

    useEffect(() => {
        const handleResize = () => {
            const selector1 = document.getElementById('exterior-color-identifier');
            const selector2 = document.getElementById('interior-color-identifier');

            if(selector1 && selector2) {
                selector1.style.minHeight = 'unset';
                selector2.style.minHeight = 'unset';
                const maxHeight = Math.max(selector1.offsetHeight, selector2.offsetHeight);
                selector1.style.minHeight = `${maxHeight}px`;
                selector2.style.minHeight = `${maxHeight}px`;
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [selectedExtColor, selectedIntColor, availableExtColorCodes, availableIntColorCodes]);
    
    const onExteriorImageError = () => {
        if (!exteriorImage.error) {
            setExteriorImage({
                src: appsettings.AWSImgRepo.resources + 'en/image_coming_soon_placeholder.png',
                error: true,
            });
        }
    }

    const onInteriorImageError = () => {
        if (!interiorImage.error) {
            setInteriorImage({
                src: appsettings.AWSImgRepo.resources + 'en/image_coming_soon_rectangle_placeholder.png',
                error: true,
            });
        }
    }

    const selectExteriorColor = (color) => {
        setSelectedExtColor(color);
        postColorAction("Exterior Color Swatch", color?.exteriorColorCode);
    }

    const selectInteriorColor = (color) => {
        setSelectedIntColor(color);
        postColorAction("Interior Color Swatch", color?.interiorColor);
    }

    return (
        <div>
            {!props.colorOptionsLoading && props?.trims && props.s3PathName ? (
                <div className="color-viewer-selector-container-v2">
                    <DisclaimerPopup isVisible={isPopupOpen} onClose={togglePopup} disclaimerText={"* Select exterior colors require selecting an optional package or specific trim."}></DisclaimerPopup>
                   
                    <div className="exterior-image">
                        <img src={exteriorImage.src} onError={onExteriorImageError} />
                    </div>
                    <div className="interior-image">
                        <img src={interiorImage.src} onError={onInteriorImageError} />
                    </div>
                    <div className="view-large-image-button">
                        <ViewLargerImages
                            toggleAnalytics={toggleLargeImages}
                            clickAnalytics={largeModalButtonClick}
                            images={[exteriorImage.src, interiorImage.src]}
                            pageTitle={props?.title}
                            model={props?.model}
                            year={props?.year}
                            trim={props.selectedTrim?.name}
                        />
                    </div>
                        <div className="exterior-selector">
                            <ColorSelector
                                s3Path={props.year + "/" + props.s3PathName + "/en/"}
                                options={props?.trimOptions?.exteriorColors}
                                availableOptions={availableExtColorCodes}
                                selectedOption={selectedExtColor}
                                onSelect={selectExteriorColor}
                                clickAnalytics={colorClick}
                                pageTitle={props?.title}
                                model={props?.model}
                                year={props?.year}
                                trim={props.selectedTrim?.name}
                                openDisclaimer={togglePopup}
                            />
                        </div>
                        <div className="interior-selector">
                            <ColorSelector
                                s3Path={props.year + "/" + props.s3PathName + "/en/"}
                                type="interior"
                                options={props?.trimOptions?.interiorColorsV2}
                                availableOptions={availableIntColorCodes}
                                availableOptionNames={availableIntColorNames}
                                selectedOption={selectedIntColor}
                                onSelect={selectInteriorColor}
                                clickAnalytics={colorClick}
                                pageTitle={props?.title}
                                model={props?.model}
                                year={props?.year}
                                trim={props.selectedTrim?.name}
                                openDisclaimer={togglePopup}
                            />
                        </div>
                </div>
            ) : (
                <div className="colors-skeleton">
                    <div className="colors-skeleton-image-section">
                        <Skeleton className="skeleton-item"/>
                        <Skeleton className="skeleton-item"/>
                    </div>

                    <div className="colors-skeleton-selector-section">
                        <Skeleton className="skeleton-item"/>
                        <Skeleton className="skeleton-item"/>
                    </div>
                </div>)}

        </div>
    )
};

export default ColorsV2;