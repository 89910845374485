import { Component } from 'react';
import { postUserActivityRecord } from '../../../services/userActivityService.js';
import '../../../styles/css/audioMultimediaRegistration.css';
import AudioMultimediaRegistrationTemplate from './audioMultimediaRegistration.tpl';

const analyticsFunctions = require('./audioMultimediaRegistration.analytics');

export default class AudioMultimediaRegistrationComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      versions: this.props.registrationData,
      activeVersionTitle: 'Connected Services Registration',
    };

    this.switchVersion = this.switchVersion.bind(this);
    this.registrationClick = this.registrationClick.bind(this);

    // analytics
    this.pageLoad = analyticsFunctions.pageLoad.bind(this);
    this.clickEvent = analyticsFunctions.clickEvent.bind(this);
    this.videoPlayClick = analyticsFunctions.videoPlayClick.bind(this);
  }

  switchVersion(event, filter) {
    const version = filter.id;
    this.setState({
      activeVersionTitle: version,
    });
    this.clickEvent('Nav Link', version);
  }

  registrationClick(featureIndex) {
    this.clickEvent('Read More Link', this.state.versions[this.state.activeVersionTitle].features[featureIndex].title);
  }

  componentDidMount() {
    postUserActivityRecord();
    this.pageLoad();
  }

  render() {
    return AudioMultimediaRegistrationTemplate(this);
  }
}
