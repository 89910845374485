import { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { CompetitiveAdvantagesTemplate } from './competitiveAdvantages.tpl';
import { GlobalAnalytics } from '../../analytics';
import { getUrlModelName } from '../utils/vehicleName';

const analytics = new GlobalAnalytics();

class CompetitiveAdvantagesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: this.getFilters(),
      activeTab: 'Overview',
    };
    if (this.props.params.year < 2020) {
      this.navigationClick = this.navigationClick.bind(this);
    } else {
      this.navigationClick = this.navigation2021Click.bind(this);
    }
  }

  getFilters() {
    if (this.props.params.year < 2020) {
      return [
        { label: 'ca.overviewLink', id: 'overview', linkTo: `/product/${getUrlModelName(this.props.route.model)}/${this.props.params.year}/competitiveAdvantages/overview` },
        { label: 'ca.headToHeadLink', id: 'headToHead', linkTo: `/product/${getUrlModelName(this.props.route.model)}/${this.props.params.year}/competitiveAdvantages/headToHead` },
        { label: 'ca.thirdPartyComparisonsLink', id: 'thirdPartyComparison', linkTo: `/product/${getUrlModelName(this.props.route.model)}/${this.props.params.year}/competitiveAdvantages/thirdPartyComparison` },
      ];
    }

    return [
      { label: 'ca.headToHeadLink', id: 'headToHead', linkTo: `/product/${getUrlModelName(this.props.route.model)}/${this.props.params.year}/competitiveAdvantages/headToHead` },
      { label: 'ca.thirdPartyComparisonsLink', id: 'thirdPartyComparison', linkTo: `/product/${getUrlModelName(this.props.route.model)}/${this.props.params.year}/competitiveAdvantages/thirdPartyComparison` },
    ];
  }

  navigation2021Click(event, item) {
    analytics.fireOnClick({
      tag: '29.3',
      section: 'Product',
      page: 'Competitive Advantages',
      module: 'Nav Link',
      category: 'click',
      label: item && item.label ? this.props.intl.formatMessage({ id: item.label }) : undefined,
    });
    this.setState({
      activeTab: item.id,
    });
  }

  navigationClick(event, item) {
    analytics.fireOnClick({
      tag: '29.3',
      modelName: this.props.route.model,
      page: 'Competitive Advantage',
      modelYear: this.props.params.year,
      section: this.state.activeTab,
      container: 'Competitive Advantage Nav',
      category: 'Nav Link',
      label: item.title,
      contentTitle: item.title,
      mediaTitle: item.title,
    });
    this.setState({
      activeTab: item.id,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      activeTab: nextProps.subPage,
    });
  }

  render() {
    return CompetitiveAdvantagesTemplate(this);
  }
}

CompetitiveAdvantagesComponent.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(CompetitiveAdvantagesComponent);
