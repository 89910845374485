import React, {useState, useEffect} from 'react';
import '../../styles/css/vehicleSelection.css';
import { FormattedMessage } from 'react-intl';
import VehicleSelection from './vehicleSelection.component';
import * as analytics from './multipleVehicleSelection.tpl.analytics';

export function multipleVehicleSelectionTemplate(component) {
  const selections = [];
  let modelName = component?.props?.s3Path?.slice(0,-4);
  for (let i = 0; i < component.state.numSelections; i++) {
    let cls = component.state.numSelections > 1
      ? 'compare-vehicle-preview small-6 medium-4'
      : 'single-vehicle-preview medium-6 medium-offset-6';
    if (i > 0) {
      cls += ' selection-divider';
    }
    if (component.props.trimWalk) {
      cls = 'single-vehicle-preview';
    }
    if (component.props.trims && Object.keys(component.props.trims).length > 0) {
      selections.push(
        <div key={`selection-${i}`} className={cls}>
          {i !== 0
            && <div onClick={component.removeSelection.bind(component, i)} className="exit-comparision-mode-btn">
              <div className="minus-icon" onClick= {() => analytics.handleClick(modelName,"Compare Trim Collapse")}>_</div>
            </div>
          }
          <VehicleSelection modelYears={component.props.modelYears}
            index={i}
            trims={component.props.trims}
            trim={component.props.trim}
            selectedTrimIndex={component.props.selectedTrimIndex}
            populateCouchScreen={component.props.populateCouchScreen}
            trimWalk={component.props.trimWalk}
            yearOptions={component.props.yearOptions}
            trimImageFilename={component.props.trimImageFilename}
            engine={component.props.engine}
            useCurrentYear={component.props.forceCurrentYear ? i === 0 : false}
            year={component.props.year}
            analyticsOnChange={component.props.analyticsOnChange}
            onSelectionChange={component.updateSelected.bind(component, i)}
            s3Path={component.props.s3Path}
            lowerTrim={component.props.lowerTrim}
            trimWalkTier={component.props.trimWalkTier}
          />
        </div>,
      );
    }
  }
  var className = !component.props.trimWalk ? `multiple-vehicle-selection ${component.state.numSelections < 2
    ? 'small-screen-comparison-border' : ''}`
    : className = '';
  return (
    <div className={className}>
      {(component.state.numSelections < component.props.maxSelections && !component.props.trimWalk)
        && <div onClick={component.addSelection} className="position-compare-mode-btn">
          <div className="enter-comparison-mode-btn">
            <span className="compare-trim">
              <div>
                <FormattedMessage id="featuresAndSpecs.compareTrimBtn1" defaultMessage="COMPARE" />
              </div>
              <div>
                <FormattedMessage id="featuresAndSpecs.compareTrimBtn2" defaultMessage="TRIM" />
              </div>
            </span>
            <span className="plus" onClick= {() => analytics.handleClick(modelName,"Compare Trim Expand")} >+</span>
          </div>
        </div>
      }
      {selections}
    </div>
  );
}
