import React, { useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import '../../../styles/css/towingPayload-gaugeSectionModal.css';
import { isDesktopView, isTabletView, isMobileView } from '../../utils/getScreenSize';
import { errorModalButtonClick, errorModalLoaded } from '../towingPayload-analytics';
import { getTowingCalculatorSuggestions } from '../../../services/towingCalculatorService';
import { TrimCard } from '../components/towingPayload-trimCard';
import AppSettings from '../../../appsettings.js';
import { getS3PathName } from '../../utils/vehicleName';
import ScrollingModal from '../../../components/molecules/scrollingModal';

const GaugeSectionModal = (props) => {
    const [modalVisible, setModalVisible] = useState(props.error);
    const [suggestedModels, setSuggestedModels] = useState(null);
    const [visiblePopUp, setVisiblePopUp] = useState({modelCode: null, term: null});
    const buttonText = "Adjust Values";

    useEffect(() => {
        setModalVisible(props.error);
    }, [props.error]);

    useEffect(() => {
        if(modalVisible){
            const getSuggestedModelsSuggestions = async () => {                
                setSuggestedModels(null);
                props.pageLoadAnalytics && errorModalLoaded(props.pageLoadAnalytics, props.vehicleSelection, props.trimSelection);
                const models = props.vehicles.map(model => ({ "modelName" : model.modelName, "modelYear" : model.latestYear }));
                const suggestions = await getTowingCalculatorSuggestions(props.towingValue, props.totalPayload, models);
                const suggestionsWithS3Path = []
                for (let suggestion of suggestions) {
                    const { modelName, modelYear, filterImage } = suggestion;
                    const s3PathName = await getS3PathName(modelName);
                    suggestionsWithS3Path.push({
                        ...suggestion,
                        s3PathName: AppSettings.AWSImgRepo.vehicle + modelYear + '/' + s3PathName + '/en/' + filterImage,
                    });
                }
                setSuggestedModels(suggestionsWithS3Path);
            }
            getSuggestedModelsSuggestions();
        }
    },[modalVisible, props.pageLoadAnalytics, props.totalPayload, props.towingValue, props.trimSelection, props.vehicleSelection, props.vehicles]);

    const closeModal = () => {
        props.clickAnalytics && errorModalButtonClick(props.clickAnalytics, props.vehicleSelection, props.trimSelection, buttonText);
        setModalVisible(false);
    };

    const clickSuggestedModel = (item) => {
        props.handleNavClick(item);
        closeModal();
    };

    const ModalText = () => {
        return (
            <>
                <div className="gauge-section-modal-header">
                    <img src={props.imagePath + "warning-icon.png"} className="gauge-section-modal-image" />
                    <div className="gauge-section-modal-title">{props.modalData.text1}</div>
                </div>
                <div className="gauge-section-modal-text">{props.modalData.text2}</div>
            </>
        );
    };

    const CloseButton = () => {
        return (
            <div className={"gauge-section-modal-close-button"} onClick={() => closeModal()}>{buttonText}</div>
        );
    };

    const getSuggestedModels = () => {
        return (
            <div className={`tp-suggestions ${suggestedModels ? "loaded" : "loading"}`}>
                <div className={`tp-suggestions-cards-opacity ${(suggestedModels && suggestedModels.length > 0) ? "visible" : "hidden"}`}>
                    {suggestedModels && suggestedModels.length > 0  && 
                        <div className="tp-suggestions-cards-container">
                            <div>Or select a vehicle that supports your towing and payload weights.</div>
                            <div className={`tp-suggestions-cards ${suggestedModels.length == 1 ? "center" : ""}`}>
                                {suggestedModels.map((model, index) => (
                                    <TrimCard
                                        item = {model}
                                        index = {index}
                                        vehicleImage = {model.s3PathName}
                                        infoPopUpData={props.infoPopUpData}
                                        vehicleSelection = {props.vehicleSelection}
                                        handleNavClick = {clickSuggestedModel}
                                        setVisiblePopUp = {setVisiblePopUp}
                                        visiblePopUp = {visiblePopUp}
                                        clickAnalytics={props.clickAnalytics}
                                        pageLoadAnalytics={props.pageLoadAnalytics}
                                    />
                                ))}
                            </div>
                        </div>}
                </div>
                {!suggestedModels &&
                    <div className="gauge-section-modal-loading">
                        <img className = "gauge-section-modal-loading-spinner" src = {`${AppSettings.AWSImgRepo.logo}en/loading_icon_cropped.gif`} ></img>
                    </div>
                }
            </div>
        )
    }

    return (
        <ScrollingModal
            visible={modalVisible}
            closeModal={()=>setModalVisible(false)}
            modalClassName={`gauge-section-modal ${(suggestedModels && suggestedModels.length > 0) ? "fullSize" : "smallSize"}`}
            showCloseIcon={false}
            closeOnOverlayClick={false}
        >
            <div className="gauge-section-modal-content">
                <div className="gauge-section-modal-adjust">
                    <ModalText />
                    <CloseButton />
                </div>
                {getSuggestedModels()}
            </div>
        </ScrollingModal>
    );
};

export default GaugeSectionModal;