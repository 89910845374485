import React, { createRef, useEffect, useRef, useState } from 'react';
import RedLine from '../../../components/atoms/RedLine';
import '../../../styles/css/smartpath-overview-sections.css';
import PropTypes from 'prop-types';
import applyMarkdown from '../../utils/applyMarkdown';
import { SmartPathOverviewAdvantages } from './smartpath-overview-advantages'
import { SmartPathOverviewFeatures } from './smartpath-overview-features'
import { SmartPathOverviewQualify } from './smartpath-overview-qualify'
import { SmartPathOverviewTestimonials } from './smartpath-overview-testimonials'
import { SmartPathOverviewPricing } from './smartpath-overview-pricing'
import { SmartPathOverviewSupport } from './smartpath-overview-support'
import { SmartPathOverviewRoadmap } from './smartpath-overview-roadMap'
import { isDesktopView, isMobileView, isTabletView } from '../../utils/getScreenSize';

export const SmartPathOverviewSections = (props) => {
    const [sortedSections, setSortedSections] = useState([]);

    useEffect(() => {
        setSortedSections(_.orderBy(props.overviewData.sections, 'order'))
    }, []);
    
    function populateParentSection(sectionId) {
        switch(sectionId) {
        case "1":
            return <div>
                <SmartPathOverviewAdvantages advantages={props.overviewData.subSections.advantages.sections} imagePath={props.imagePath} clickAnalytics={props.clickAnalytics} pageLoadAnalytics={props.pageLoadAnalytics} />
                <SmartPathOverviewTestimonials data={props.overviewData.subSections.testimonials} imagePath={props.imagePath} clickAnalytics={props.clickAnalytics} pageLoadAnalytics={props.pageLoadAnalytics} />
            </div>
        case "2":
            return <div>
                <SmartPathOverviewFeatures data={props.overviewData.subSections.features} imagePath={props.imagePath} pageLoadAnalytics={props.pageLoadAnalytics} />
                <SmartPathOverviewRoadmap roadmap={props.overviewData.subSections.roadmap} imagePath={props.imagePath} clickAnalytics={props.clickAnalytics} pageLoadAnalytics={props.pageLoadAnalytics} />
            </div>
        case "3":
            return <SmartPathOverviewSupport support={props.overviewData.subSections.support} imagePath={props.imagePath} clickAnalytics={props.clickAnalytics} pageLoadAnalytics={props.pageLoadAnalytics} />
        case "4":
            return <div>
                <SmartPathOverviewPricing data={props.overviewData.subSections.pricing} imagePath={props.imagePath} clickAnalytics={props.clickAnalytics} pageLoadAnalytics={props.pageLoadAnalytics} />
                <SmartPathOverviewQualify qualify={props.overviewData.subSections.qualify} integrations={props.overviewData.subSections.integrations} imagePath={props.imagePath} pageLoadAnalytics={props.pageLoadAnalytics} />
            </div>
        }
    };

    const overlay = (header, description) => {
        return <div>
            <div className="overview-section-header-text">
                {header}
            </div>
            <RedLine />
            <div className="overview-section-header-description">
                {applyMarkdown(description)}
            </div>
        </div>;
    };
    
    const getImage = (section)=>{
        if (isDesktopView(window.innerWidth)){
            return section.image
        }
        if(isTabletView(window.innerWidth)){
            return section.tabletImage
        }
        if(isMobileView(window.innerWidth)){
            return section.mobileImage
        }
    }
    const getImageHeight = (section)=>{
        if (isDesktopView(window.innerWidth)){
            return section.imageHeight
        }
        if(isTabletView(window.innerWidth)){
            return section.tabletImageHeight
        }
        if(isMobileView(window.innerWidth)){
            return section.mobileImageHeight
        }
    }

    const parentSections = sortedSections.map((section,item) => (    
        <div className = "smartpath-section-header" ref = {props.refrence[item]}>
            
            { (section.overlay === 'Yes') ? '' : overlay(section.header, section.description) }
            
            <div className="overview-section-header-image" style={{ 'backgroundImage': `url(${props.imagePath + getImage(section)})`, 'height': `${getImageHeight(section)}` }} >
                { (section.overlay === 'Yes') ? overlay(section.header, section.description) : '' }    
            </div>
            {populateParentSection(section.id)}
        </div>

    ));
    
    return <div className = "smartpath-sections-container">
        {parentSections}
    </div>;
}