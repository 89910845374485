import AppSettings from "../appsettings";
import httpOption from "./httpOption";

export async function getStaticService() {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/static`;
  const options = httpOption.GET();
  try {
    const response = await fetch(url, options);
    if (response.status >= 200 && response.status < 300) {
      return await response.text();
    } else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching image:", error);
    throw error;
  }
}
