import React from 'react';
import applyMarkdown from '../../../../utils/applyMarkdown';
import '../../../../../styles/css/headToHead.css';
import advantageIcon from '../../../../../resources/images/advantage-icon.png';

export default function QuickSpecsV2(props) {
  const QuickSpecsTableRowsMobile = (props) => {
    const specsArray = props.item.quickSpecs.specs && props.item.quickSpecs.specs.map((spec, index) => {
      if (!spec.toyotaHeader && !spec.footnote) {
        return (
                    <tr className="cto" key={index}>
                        <td className="full-width-header columns small-12">{applyMarkdown(spec.featureName, props.disclaimer)}</td>
                        <td className="sm-col columns small-6">
                            {spec.toyotaAdvantage
                              && <span>
                                    <img src={advantageIcon} className="advantage-icon" />

                                </span>}
                            &nbsp;{applyMarkdown(spec.toyotaData, props.disclaimer)}</td>
                        <td className="sm-col columns small-6 col2">
                            {spec.competitorAdvantage
                              && <span>
                                    <img src={advantageIcon} className="advantage-icon"/>
                                    &nbsp;
                            </span>}
                            {applyMarkdown(spec.competitorData, props.disclaimer)}</td>
                        <td className="sm-line columns small-12"></td>
                    </tr>
        );
      }

      return null;
    });
    return <tbody><tr />{specsArray}</tbody>;
  };


  const QuickSpecsTableRowsDesktop = (props) => {
    const specsArray = props.item.quickSpecs.specs && props.item.quickSpecs.specs.map((spec, index) => {
      // don't map the header or footnote as a table row
      if (!spec.toyotaHeader && !spec.footnote) {
        return (
                    <tr className="lg-row" key={index}>
                        <td className="lg-title">
                            {applyMarkdown(spec.featureName, props.disclaimer)}
                        </td>
                        <td className="lg-col">
                            {spec.toyotaAdvantage
                              && <span>
                                    <img src={advantageIcon} className="advantage-icon" />
                                    &nbsp;
                            </span>}
                            {applyMarkdown(spec.toyotaData, props.disclaimer)}
                        </td>
                        <td className="lg-col">
                            {spec.competitorAdvantage
                              && <span>
                                    <img src={advantageIcon} className="advantage-icon" />
                                    &nbsp;
                            </span>}
                            {applyMarkdown(spec.competitorData, props.disclaimer)}
                        </td>
                    </tr >
        );
      }

      return null;
    });
    return <tbody><tr />{specsArray}</tbody>;
  };

  const quickSpecsTableDesktop = [];
  quickSpecsTableDesktop.push(
        <table className="cost-to-own-table-desktop quick-specs-table">
            <tr className="lg-row">
                <td />
            </tr>
            <QuickSpecsTableRowsDesktop disclaimer={props.disclaimer} item={props.content} />
        </table>,
  );

  const quickSpecsTableMobile = [];
  quickSpecsTableMobile.push(
    <table className='cost-to-own-table-mobile'>
        <QuickSpecsTableRowsMobile disclaimer={props.disclaimer} item={props.content} />
    </table>,
  );

  return (
        <div className="new-cost-to-own">
            <div className="cto-body">
                <div className="cto">
                    <div className="row">
                    </div>
                    <div className="show-for-large">{quickSpecsTableDesktop}</div>
                    <div className="show-for-small-only">{quickSpecsTableMobile}</div>
                    <div className="show-for-medium-only">{quickSpecsTableMobile}</div>
                </div>
            </div>
            <div className="advantage-footnote-container">
                <img src={advantageIcon} className="advantage-icon" />
                &nbsp;
                    <span>Advantage</span>
            </div>
        </div>
  );
}
