import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();

const vinBaseObject = {
  tag: '35.2',
  section: 'Product',
  subsection: 'Featured Technology',
  technologyName: 'Vin Lookup',
  page: 'Landing Page',
};

const vinLoadBaseObject = {
  tag: '35.1',
  section: 'Product',
  subsection: 'Featured Technology',
  technologyName: 'Vin Lookup',
  page: 'Landing Page',
};

const pageLoadAnalytics = () => {
  analytics.firePageLoad({
    ...vinLoadBaseObject,
  });
};

const vinSearchedAnalytics = (vin) => {
  analytics.fireOnClick({
    ...vinBaseObject,
    category: 'Submit Vin',
    contentTitle: vin,
  });
};

const vinSearchLoadingAnalytics = (vin) => {
  analytics.fireOnClick({
    ...vinBaseObject,
    category: 'Loading',
    contentTitle: vin,
  });
};

const vinSearchErrorAnalytics = (vin, error) => {
  analytics.fireOnClick({
    ...vinBaseObject,
    category: 'Error',
    mediaTitle: error,
    contentTitle: vin,
  });
};

const vinSearchResultLoadAnalytics = (vin, vehicleInfo) => {
  analytics.fireOnClick({
    ...vinBaseObject,
    category: 'Results',
    mediaTitle: vehicleInfo,
    contentTitle: vin,
  });
};

const vinDownloadClickAnalytics = (vin, vehicleInfo) => {
  analytics.fireOnClick({
    ...vinBaseObject,
    category: 'Download',
    mediaTitle: vehicleInfo,
    contentTitle: vin,
  });
};

const vinShareClickAnalytics = (vin, vehicleInfo) => {
  analytics.fireOnClick({
    ...vinBaseObject,
    category: 'Share',
    mediaTitle: vehicleInfo,
    contentTitle: vin,
  });
};

export const vinAnalytics = {
  pageLoadAnalytics,
  vinSearchedAnalytics,
  vinSearchLoadingAnalytics,
  vinSearchErrorAnalytics,
  vinSearchResultLoadAnalytics,
  vinDownloadClickAnalytics,
  vinShareClickAnalytics,
};
