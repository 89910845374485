import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import applyMarkdown from '../../utils/applyMarkdown';
import '../../../styles/css/smartpath-overview-qualify.css';
import { isDesktopView, isMobileView, isTabletView } from '../../utils/getScreenSize';
import * as qualifyAnalytics from '../overviewAnalytics/smartpath-overview-qualify.analytics';
import useOnScreen from '../useIntersection';

export const SmartPathOverviewQualify = (props) => {
    const qualifyRef = useRef();
    if (useOnScreen(qualifyRef) && props.pageLoadAnalytics) {
        qualifyAnalytics.qualifySubLoad(props.pageLoadAnalytics);
    }

    const integrationTiles = (tiles) => (
        tiles.map((tile, index) => (
            <div className='smartpath-overview-integration-tile' key={index}>
                <div className='smartpath-overview-integration-image'><img src={props.imagePath + tile.image}/></div>
            </div>
        ))
    );
    const hasDescription =  (description) =>{
        if (description){
            return <div className ='smartpath-overview-qualifyContentItems-design'> 
            <div className= 'smartpath-overview-qualifyContentItems-redline'>
                <div className= 'smartpath-overview-qualifyContentItems-redline-top'></div>
                <div className= 'smartpath-overview-qualifyContentItems-redline-bottom'>
                </div>
            </div>
            <div className = 'smartpath-overview-qualifyContentItems-redline-circle'></div>
           
        </div>
        }
        return 
    }
    const qualifyContentItems = (items) => (
        items.map((item) => (
            <div className='smartpath-overview-qualifyContentItems'>
                <div className = 'smartpath-overview-qualifyContentItems-number'>{applyMarkdown(item.number)}</div>
                <div className = 'smartpath-overview-qualifyContentItems-title'>{applyMarkdown(item.title)}</div>
                {hasDescription(item.description)}
                <div className = 'smartpath-overview-qualifyContentItems-description'>{applyMarkdown(item.description)}</div>
            </div>
        ))
    );
    const getImage = (content)=>{
        if (isMobileView(window.innerWidth)){
            return content.mobileImage
        }
        else if (isTabletView(window.innerWidth)){
            return content.tabletImage
        }
        else{
            return content.image
        }
    }
    const qualifyContent = (content) =>(
        <div className='smartpath-overview-qualify-content'>
            <div>
                <img src={props.imagePath + getImage(content)}/>
                <div >
                    <div className='smartpath-overview-qualify-content-icon'><img src={props.imagePath + content.icon}/></div>
                    <div className='smartpath-overview-qualify-content-label'>{applyMarkdown(content.iconLabel)}</div>
                </div>
            </div>
            <div className= 'smartpath-overview-qualifyContent'>{qualifyContentItems(content.content)}</div>
        
        </div>
    );
        
    const integrationColumns = (content) => (
        content.map((column, index) => (
            <div className='smartpath-overview-integration-column' key={index}>
                <div className='smartpath-overview-integration-column-title'>{column.title}</div>
                {integrationTiles(column.tiles)}
            </div>
        ))
    );

    return <div className='smartpath-overview-qualify' ref={qualifyRef}>
        <div className ='smartpath-overview-qualify-header'> {applyMarkdown(props.qualify.header)}</div>
            {qualifyContent(props.qualify)}
        
        <div className='smartpath-overview-integrations-header'>{applyMarkdown(props.integrations.header)}</div>
        
        <div className='smartpath-overview-integrations-content'>
            {integrationColumns(props.integrations.content)}
        </div>
    </div>;


};

SmartPathOverviewQualify.propTypes = {
    header: PropTypes.string,
    integrations: PropTypes.object,
    disclaimer: PropTypes.string,
  };