import React from "react";
import "../../../styles/scss/engageXPDashboardTriviaComponents/engageXPTournamentRounds.scss";
import Round from "../components/round";

const TournamentRounds = ({ tournamentsData, rounds, deviceType, imgPath, handleRoundClick, currentRound }) => (
    <div className="tournament-rounds">
        {rounds?.map((round, index) => (
            <Round
                round={round}
                key={index}
                isTall={index === 4 && rounds.length === 5}
                tournamentsData={tournamentsData}
                deviceType={deviceType}
                imgPath={imgPath}
                handleRoundClick={handleRoundClick}
                currentRound={currentRound}
            />
        ))}
    </div>
);

export default TournamentRounds;
