import React from 'react';
import PropTypes from 'prop-types';
import applyMarkdown from '../../utils/applyMarkdown';
import TitleText from '../../../components/atoms/TitleText';
import RedLine from '../../../components/atoms/RedLine';
import '../../../styles/css/model-overview/featured-icons.css';

const analytics = require('../modelOverview.analytics');

const onIconClick = (iconSectiontitle, iconName) => {
  analytics.fireModelOverviewClick(iconSectiontitle, iconName, 'Featured Icons');
};

const featuredIcons = (sectionTitle, iconItems, imagePath, wordColor, disclaimer, rowLength) => {
  const icons = iconItems.slice();
  const splitIcons = [];
  while (icons.length) {
    splitIcons.push(icons.splice(0, rowLength));
  }
  return splitIcons.map(row => (
    <div key={`${row[0].iconName}_row`} className="row">
      {row.map(item => (
        <div key={item.iconName} className="small-6 large-4 columns item-container">
          <div className="featured-icon-item ">
            <a className="featured-icon-item-link" href={item.iconLink} onClick={() => { onIconClick(sectionTitle, item.iconName); }}>
              <img className="featured-icon-item-image" src={imagePath + item.iconImage} />
              <div className="featured-icon-item-name"><p style={{ color: wordColor }}>{applyMarkdown(item.iconName, disclaimer)}</p></div>
            </a>
          </div>
        </div>
      ))}
    </div>));
};
const printViewIconTitles = items => (
  items.map(item => <li>{applyMarkdown(item.iconName)}</li>)
);

const featuredIconColumns = (categories, imagePath, wordColor, disclaimer) => (
  categories.map((category, index) => (
    <div className='subsection' key={index}>
      <TitleText className='subsection-title'>{applyMarkdown(category.title, disclaimer)}</TitleText>
      <RedLine className={'model-overview-web-content redline'} />
      <RedLine className={'model-overview-print-content redline'} />
      <div className="model-overview-web-content">
        <div className="featured-icons-container desktopOnly">{featuredIcons(category.title, category.icons, imagePath, wordColor, disclaimer, 3)}</div>
        <div className="featured-icons-container mobileOnly">{featuredIcons(category.title, category.icons, imagePath, wordColor, disclaimer, 2)}</div>
      </div>
      <div className="model-overview-print-content">
        <div className="featured-icons-container"><ul>{printViewIconTitles(category.icons)}</ul></div>
      </div>
    </div>
  ))
);

const featuredIconsSection = (featuredIconArrays, imagePath, styles, disclaimer) => {
  const wordColor = styles.textColor ? styles.textColor : '#EB0A1E'; // default color for Engage links is primary red
  const categories = featuredIconArrays.map(array => ({
    title: array[0].columnName,
    icons: array,
    columnWidth: parseInt((12 / featuredIconArrays.length), 10),
  }));

  return (
    featuredIconColumns(categories, imagePath, wordColor, disclaimer)
  );
};

export default function FeaturedIconsSection(props) {
  const styles = props.styles ? props.styles : null;

  return (
    <React.Fragment>
      <div className="featured-icon-section" style={{ backgroundColor: (styles.backgroundColor ? styles.backgroundColor : '#FFFFFF') }}>
        {featuredIconsSection(props.featuredIcons, props.imagePath, styles, props.disclaimer)}
      </div>
      <div className="model-overview-print-content">
        <div className="model-overview-print-divider" />
      </div>
    </React.Fragment>

  );
}

FeaturedIconsSection.propTypes = {
  featuredIcons: PropTypes.array.isRequired,
  imagePath: PropTypes.string,
  disclaimer: PropTypes.node,
  styles: PropTypes.object,
};
