import React from "react";
import "../../styles/scss/details-v2/modelCodeNameTiles.scss";

/*

    models:
        list of objects like:
            {
                modelCode
                fullName
            }
*/

const ModelCodeNameTiles = (props) => {
    return (
        <div className="modelCodeNameTiles">
            {props.models.map(m => <div key={m.modelCode} className="modelTile">
                <div className="modelCode">{m.modelCode}</div>
                <div className="modelName">{m.fullName}</div>
            </div>)}
        </div>
    );
};

export default ModelCodeNameTiles;
    