import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();

export const landingPageLoaded = pageLoadAnalytics => {
  analytics.firePageLoad({
    tag: pageLoadAnalytics.tagId,
    section: pageLoadAnalytics.section,
    subsection: pageLoadAnalytics.subsection,
    technologyName: pageLoadAnalytics.technologyName,
    page: pageLoadAnalytics.page,
  });
};

const contentLinkClick = (category, positionTitle, contentTitle, clickAnalytics, pageLoadAnalytics) => {
  analytics.fireOnClick({
    tag: clickAnalytics.tagId,
    section: pageLoadAnalytics.section,
    subsection: pageLoadAnalytics.subsection,
    technologyName: pageLoadAnalytics.technologyName,
    page: pageLoadAnalytics.page,
    category,
    positionTitle,
    contentTitle,
  });
};

export const externalResourcesClicked = (externalResourceTitle, clickAnalytics, pageLoadAnalytics) => {
  (clickAnalytics && clickAnalytics.externalResources && pageLoadAnalytics) && contentLinkClick(
    clickAnalytics.externalResources.category,
    clickAnalytics.externalResources.positionTitle,
    externalResourceTitle,
    clickAnalytics,
    pageLoadAnalytics,
  );
};
export const iconLinkClicked = (iconLinkTitle, clickAnalytics, pageLoadAnalytics) => {
  (clickAnalytics && clickAnalytics.iconLinks && pageLoadAnalytics) && contentLinkClick(
    clickAnalytics.iconLinks.category,
    clickAnalytics.iconLinks.positionTitle,
    iconLinkTitle,
    clickAnalytics,
    pageLoadAnalytics,
  );
};

export const tileClicked = (positionTitle, tileTitle, clickAnalytics, pageLoadAnalytics) => {
  if(clickAnalytics && clickAnalytics.container && clickAnalytics.container === "Glossary"){
    (clickAnalytics && clickAnalytics.tileSections) && analytics.fireOnClick({
      tag: clickAnalytics.landingPage.tagId,
      section: clickAnalytics.landingPage.section,
      subsection: clickAnalytics.landingPage.subsection,
      page: clickAnalytics.landingPage.page,
      category: clickAnalytics.tileSections.category,
      action: tileTitle,
    });
  }else{
    (clickAnalytics && clickAnalytics.tileSections && pageLoadAnalytics) && contentLinkClick(
      clickAnalytics.tileSections.category,
      positionTitle,
      tileTitle,
      clickAnalytics,
      pageLoadAnalytics,
    );
  }

};