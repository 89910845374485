import PropTypes from 'prop-types';
import React from 'react';
import applyMarkdown from '../../../screens/utils/applyMarkdown';
import '../../../styles/css/miniPhotoCard.css';

const MiniPhotoCard = (props) => (
    <div data-testid="mini-photo-card" className="mini-photo-card" onClick={() => props.clickFunction && props.clickFunction(props.data)} style={{ width: `${props.styles?.cardWidth}px`, height: `${props.styles?.cardHeight}px`, cursor: props.clickFunction ? 'pointer' : 'auto' }}>
        <div className="mini-photo-card-image-container">
            <img className="mini-photo-card-image" src={props.imagePath + props.image} style={{ width: `${props.styles?.imageWidth}px`, height: `${props.styles?.imageHeight}px` }} />
        </div>
        <div className="mini-photo-card-text" style={{ fontSize: `${props.styles?.fontSize}px`, maxWidth: `${props.styles?.textMaxWidth}px` }}>
            {applyMarkdown(props.text)}
        </div>
    </div>
);

MiniPhotoCard.propTypes = {
    content: PropTypes.object,
    clickFunction: PropTypes.func,
    image: PropTypes.string,
    imagePath: PropTypes.string,
    styles: PropTypes.shape({
        cardHeight: PropTypes.number,
        cardWidth: PropTypes.number,
        fontSize: PropTypes.number,
        imageHeight: PropTypes.number,
        imageWidth: PropTypes.number,
        textMaxWidth: PropTypes.number,
    }),
    text: PropTypes.string,
};

export default MiniPhotoCard;