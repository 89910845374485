import React, {useState} from 'react';
import * as analytics from '../searchBar.analytics';
import AppSettings from '../../../appsettings.js';
import { getFormattedName } from '../../utils/vehicleName';
import SearchBodyResultContent from './resultContent'
import ReactFreezeframe from 'react-freezeframe';
import '../../../styles/css/resultCommonFunctions.css';

function updateFilterState(newFilter,component) {
    if (newFilter) {
      component.scroll = window.scrollY;
      component.scrollLeft = document.querySelector(".filter-tabs-mobile").scrollLeft;
      component.setState({ activeFilter: newFilter });
      component.resultsPerPage = 8;
      component.setState({ pageNumber: 0 });
    }
}

const clickFilterAnalytics = (newFilter, component) => {
  if (component.state.analyticsData && newFilter) {
    const positionTitle = component.state.analyticsData.clickAnalytics.searchFilter.positionTitle + newFilter.toUpperCase();
    analytics.filterClicked(
      component.state.analyticsData.clickAnalytics, 
      positionTitle, 
      newFilter.toUpperCase(), 
      component.state.searchParam, 
      component.state.articleResults.length.toString()
    );
  }
};

export function filterResults(component,newFilter) {
    clickFilterAnalytics(newFilter,component);
    updateFilterState(newFilter,component);

    let filteredResults = null;
    switch (component.state.activeFilter) {
      case "All":
        filteredResults = component.state.articleResults;
        break;
      case "Articles":
        filteredResults = component.state.articleResults.filter(
          item => item._source.category !== "Video" && 
          item._source.format !== "Video" && 
          (item._source.categories ? !item._source.categories.includes("Video") : null)
        )
        break;
      case "Glossary":
        filteredResults = component.state.articleResults.filter(item => item._source.category === "Glossary")
        break;
      case "Pages":
        filteredResults = component.state.articleResults.filter(item => item._source.category === "Page")
        break;
      case "Videos":
        filteredResults = component.state.articleResults.filter(
          item => item._source.category === "Video" || 
          item._source.format === "Video" || 
          (item._source.categories ? item._source.categories.includes("Video") : null)
        )
        break;
      default: filteredResults = component.state.articleResults;
        break;
    }
    return filteredResults;
}

const getDateFormatted = function getDateFormatted(time) {
  let dateStr = '';
  if (time) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const t = new Date(time);
    dateStr = `${months[t.getMonth()]} ${t.getDate()}, ${t.getFullYear()}`;
  }
  return dateStr;
};

const SearchResultDate = (props) => {
  return (
    <div className="search-item-date-type">
      {getDateFormatted(Date.parse(props.item.createdDate))}
    </div>
  )
}

const clickResultAnalytics = (title, category, index, component) => {
  if (component.state.analyticsData && title && category && (index + 1)) {
    const positionTitle = component.state.analyticsData.clickAnalytics.resultSelection.positionTitle + (index + 1)
    const label = _.replace(
      component.state.analyticsData.clickAnalytics.resultSelection.labelNeedsReplace,
      component.state.analyticsData.clickAnalytics.resultSelection.toReplace,
      category
    );
    analytics.resultClicked(
      component.state.analyticsData.clickAnalytics,
      positionTitle,
      title,
      label,
      component.state.searchParam,
      component.state.articleResults.length.toString(),
    );
  }
};

const SearchResultTitle = (props) => {
  return (
    <a 
      href={`/articles/${props.item.id}`} 
      target="_blank" 
      onClick={() => { clickResultAnalytics(props.item.title, getSearchResultCategory(props.item), props.index, props.component); }} 
      className="search-item-title"
    >
      {props.item.title}
    </a>
  );
};

function getSearchResultCategory(item) {
  if (item.category !== "Video" && item.format !== "Video" && (item.categories ? !item.categories.includes("Video") : true)) {
    return 'Article';
  } else if (item.category === "Video" || item.format === "Video" || (item.categories ? item.categories.includes("Video") : null)) {
    return 'Video';
  } else {
    return item.category;
  }
}

const SearchResultDescription = (props) => {
  return (
    <div className="description-area">
      <div className="search-item-description">{props.item.description}</div>
      <SearchResultThumbnailMobile item={props.item} index={props.index} component={props.component} />
    </div>
  );
}

const SearchResultThumbnailMobile = (props) => {
  if (getSearchResultCategory(props.item) === "Video") {
    return (
      <div className="search-item-thumbnail-mobile">
        <SearchResultThumbnail item={props.item} index={props.index} component={props.component} />
      </div>
    );
  } else {
    return null;
  }
}

const SearchResultContent = (props) => {
  return (
    <div className="search-item-content-flex">
      <div className="search-item-type">
        {getSearchResultCategory(props.item)}
      </div>
      <SearchResultDate item={props.item} />
      <SearchResultTitle item={props.item} index={props.index} component={props.component} />
      <SearchResultDescription item={props.item} index={props.index} component={props.component} />
    </div>
  );
}

const SearchResultThumbnail = (props) => {
  if(props.item.thumbnail !=null){
  const isGif = props.item.thumbnail.endsWith('.gif');
  const thumbnailImage = isGif ?
  <ReactFreezeframe src={AppSettings.AWSImgRepo.articleImages + props.item.thumbnail} />:
  <img src={AppSettings.AWSImgRepo.articleImages + props.item.thumbnail}/>;
  return (
      <a 
        href={`/articles/${props.item.id}`} 
        target="_blank" 
        onClick={() => { clickResultAnalytics(props.item.title, getSearchResultCategory(props.item), props.index, props.component); }}
      >
        {thumbnailImage}
      </a>
  );
  }
  else{
    return (
      <a 
        href={`/articles/${props.item.id}`} 
        target="_blank" 
        onClick={() => { clickResultAnalytics(props.item.title, getSearchResultCategory(props.item), props.index, props.component); }}
      >
      </a>
  );
  }
}

const SearchResultThumbnailDesktopTablet = (props) => {
  if (getSearchResultCategory(props.item) === "Video") {
    return (
      <div className="search-item-thumbnail-flex">
        <SearchResultThumbnail item={props.item} index={props.index} component={props.component} />
      </div>
    );
  } else {
    return null;
  }
}

export function createSearchResultArticleItem(item, index, component) {
  return (
    <div className="search-item-flex">
      <SearchResultContent item={item} index={index} component={component} />
      <SearchResultThumbnailDesktopTablet item={item} index={index} component={component} />
    </div>
  );
}

export function getArticles(component) {
    const articles = filterResults(component) ? filterResults(component).map((item, index) => (
        <div key={index} className="search-item"> 
          {createSearchResultArticleItem(item._source, index, component)}
        </div>
      )) : null;
 return articles
}

export function getPagecount(component){
    const pageCount = Math.ceil(getArticles(component).length / component.resultsPerPage)
    return pageCount
}