import React, {useState} from 'react';
import '../../../styles/css/resultHeaderInfo.css';
import {getArticles,getPagecount} from './resultCommonFunctions'
import { getFormattedName } from '../../utils/vehicleName';
import { FormattedMessage } from 'react-intl';


const resultHeaderInfo = (props) => {

  function getLastResultOnPage() {
    if ((props.component.state.pageNumber + 1) === getPagecount(props.component) || getArticles(props.component).length === 0) {
      return getArticles(props.component).length;
    } else {
      return getFirstResultOnPage() + (props.component.resultsPerPage - 1);
    }
  }
  
  function getFirstResultOnPage() {
    if (getArticles(props.component).length === 0) {
      return 0;
    } else {
      return (props.component.state.pageNumber * props.component.resultsPerPage) + 1;
    }
  }

  const ResultCount = () => {
    const firstResultOnPage = getFirstResultOnPage();
    const lastResultOnPage = getLastResultOnPage();

    return (
      <div className="column-title-results-right">
        SHOWING {firstResultOnPage}-{lastResultOnPage} of {getArticles(props.component).length}
      </div>
    );
  }
  
  const ResultsForHeader = () => {
    let ResultsForInfo = props.component.state.searchParam;
    let ResultsForHeader = "global.resultsFor";
    if(props.component.state.isAdvancedSearch == true){
     const AdvancedSearchText =  props.component.state.AdvancedSearchParamObject.allTheseWords || props.component.state.AdvancedSearchParamObject.anyOftheseWords || props.component.state.AdvancedSearchParamObject.thisExactWords || "Query";
     ResultsForInfo = AdvancedSearchText;
     ResultsForHeader = "global.advancedResultsFor";
    }
    else{

    }
    return <div className="column-title-results-left"><FormattedMessage id={ResultsForHeader} /> <b>"{ResultsForInfo}"</b></div>;
  }

  function createSuggestedProductItem(item) {
    let message = `${translateText('global.didYouMean')} ${getFormattedName(item.productName)} ${translateText('global.vehiclePage')}?`;
    message = message.replace(/\*/g, '');

    return (<span className="suggestedProduct"><a href={item.productLink}>{message}</a></span>);
  }

  return (
    <div className="column-title-results">
      <ResultsForHeader />
      <ResultCount />
      {props.component.state.suggestedProduct ? createSuggestedProductItem(props.component.state.suggestedProduct) : null}
    </div>
  );
}


export default resultHeaderInfo;