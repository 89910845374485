import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getLocale } from '../../../services/languageTranslationService.js';
import applyMarkdown from '../../utils/applyMarkdown';
import '../../../styles/css/tcuv.css';
import { TCUVComparisonChart } from '../comparisonChart/tcuvComparisonChart.component';

import AppSettings from '../../../appsettings';

export function TCUVCertificationProgramTemplate(component) {
  const header = (
        <div className="tcuv-header">
            <div className="tcuv-header-image">
                <img alt="" src={encodeURI(`${AppSettings.AWSImgRepo.tcuv + getLocale()}/${component.props.pageData.headerImage}`)} />
            </div>
            <div className="tcuv-header-text">
                <div className="tcuv-header-title">
                    {applyMarkdown(component.props.pageData.headline)}
                </div>
                <div className="tcuv-header-description">
                    {applyMarkdown(component.props.pageData.description)}
                </div>
            </div>
        </div>
  );

  const chartText = (
        <div className="tcuv-chart">
            <div className="tcuv-title">
                {applyMarkdown(component.props.pageData.chartTitle)}
            </div>
            <div className="divide" />
            <div className="tcuv-description">
                {applyMarkdown(component.props.pageData.chartDescription)}
            </div>
        </div>
  );

  const benefits = (
        <div className="tcuv-benefits">
            <div className="tcuv-title">
                {applyMarkdown(component.props.pageData.benefitsTitle)}
            </div>
            <div className="divide" />
            <div className="tcuv-description">
                {applyMarkdown(component.props.pageData.benefitsDescription)}
            </div>
            <div className="benefits-image">
                <img alt="" src={encodeURI(`${AppSettings.AWSImgRepo.tcuv + getLocale()}/${component.props.pageData.benefitsImage}`)} />
            </div>
        </div>
  );

  return (
        <div className="tcuv">
            {component.state.languageOverride
              ? <div className="language-unavailable">
                    <FormattedMessage id="dataUnavailable" default="Language is unavailable" />
                </div>
              : null
            }
            <div className="title">
                <div className="print-tcuv-title"><FormattedMessage id="dropdown.tcuv" /></div>
                <FormattedMessage id="dropdown.tcuv.certificationProgram" defaultMessage="The Certification Program" />
                <div className="print-dividing-line" />
            </div>
            {header}
            {chartText}
            <TCUVComparisonChart chartData={component.props.chartData} imagePath={`${AppSettings.AWSImgRepo.tcuv + getLocale()}/`} />
            {benefits}
        </div>
  );
}
