import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import '../../../styles/css/marketingPage/cardGroup.css';
import MarketingBasicCard from '../cards/marketingPageBasicCard';
import CtaCard from '../cards/marketingPageCtaWideCard';
import MarketingMediaCard from '../cards/marketingPageMediaCard';
import WideCard from '../cards/marketingPageWideCard';
import MiniPhotoCard from '../cards/miniPhotoCard';
import SmallPhotoCard from '../cards/smallPhotoCard';

const CardGroup = (props) => {
  const [displayedCards, setDisplayedCards] = useState([]);
  const [remainingCards, setRemainingCards] = useState(0);

  useEffect(() => {
    if (props.cards) {
      loadMoreCards(10);
    }
  }, [props.cards]);


  const loadMoreCards = (totalCards) => {
    const numCardsToDisplay = totalCards || displayedCards.length + 10;
    const newDisplayedCards = props.cards.slice(0, numCardsToDisplay)
    setDisplayedCards(newDisplayedCards);
    setRemainingCards(props.cards.length - newDisplayedCards.length);
    props.loadMoreAnalytics && props.loadMoreAnalytics();
  }

  const cardContent = () => {
    switch (props.cardGroupType) {
      case "IconCard":
        return (props.cards && props.cards.map((card, index) => (
          <MarketingBasicCard
            key={index}
            description={card.description}
            imagePath={props.imagePath}
            icon={card.icon}
            title={card.title}
            ctaHref={card.ctaHref}
            ctaText={card.ctaText}
            clickAnalytics={props.clickAnalytics}
            label={card.label}
          />)));
      case "MediaCard":
        return (props.cards && props.cards.map((card, index) => {
          if (card.gif) {
            return (<MarketingMediaCard
              key={index}
              description={card.description}
              title={card.title}
              ctaHref={card.ctaHref}
              ctaText={card.ctaText}
              gif={card.gif}
              imagePath={props.imagePath}
              clickAnalytics={props.clickAnalytics}
            />);
          } else {
            if (card.videoId) {
              return (<MarketingMediaCard
                key={index}
                description={card.description}
                title={card.title}
                ctaHref={card.ctaHref}
                ctaText={card.ctaText}
                qumuId={card.videoId}
                videoAnalytics={props.videoAnalytics}
              />);
            }
            else {
              return (<MarketingMediaCard
                key={index}
                description={card.description}
                title={card.title}
                ctaHref={card.ctaHref}
                ctaText={card.ctaText}
                image={card.image}
                imagePath={props.imagePath}
                clickAnalytics={props.clickAnalytics}
              />);
            }
          }
        }
        ));
      case "SmallPhotoCard":
        return (props.cards && props.cards.map((card, index) => (
          <SmallPhotoCard
            key={index}
            description={card.description}
            title={card.title}
            image={card.image}
            imagePath={props.imagePath}
            clickAnalytics={props.clickAnalytics}
            clickFunction={props.clickFunctions}
          />
        )));
      case "CtaWideCard":
        return (props.cards && props.cards.map((card, index) => (
          <CtaCard
            key={index}
            clickAnalytics={props.clickAnalytics}
            clickFunctions={props.clickFunctions}
            title={card.title}
            imagePath={props.imagePath}
            image={card.image}
            ctas={card.ctas}
            pageName={props.pageName}
          />
        )));
      case "WideCard":
        return (props.cards && props.cards.map((card, index) => (
          <WideCard
            key={index}
            device={props.device}
            title={card.title}
            imagePath={props.imagePath}
            image={card.image}
            mobileImage={card.mobileImage}
            description={card.description}
          />
        )));
      case "MiniPhotoCard":
        if (props.loadMore) {
          return (displayedCards && displayedCards.map((card, index) => (
            <MiniPhotoCard
              key={index}
              imagePath={props.imagePath}
              clickFunction={props.clickFunctions}
              image={card.image}
              text={card.text}
              data={card}
            />
          )));
        } else {
          return (props.cards && props.cards.map((card, index) => (
            <MiniPhotoCard
              key={index}
              imagePath={props.imagePath}
              clickFunction={props.clickFunctions}
              image={card.image}
              text={card.text}
              data={card}
            />
          )));
        }
    }
  }

  const getTitleType = () => {
    switch (props.titleType) {
      case "middleRegular": return "cardGroupTitle-middleRegular";
      case "leftSemiBold": return "cardGroupTitle-leftSemiBold";
    }
  }

  return (
    <div className="cardGroup">
      <div className="cardGroup-content">
        {props.title && <div data-testid="card-group-title" className={getTitleType()}>{props.title}</div>}
        <div className="cardGroup-cards">
          {cardContent()}
        </div>
      </div>
      <div className="loadMore">
        {props.loadMore && remainingCards > 0 ? (
          <div data-testid="viewMoreButton" className="viewMore" onClick={() => { loadMoreCards() }}>
            <FormattedMessage id="global.viewMore" />
          </div>
        ) : props.loadMore && displayedCards.length === props.cards.length ? (
          <div data-testid="noMoreButton" className="button secondary" id="noMoreButton">
            <FormattedMessage id="global.button.noMoreToDisplay" />
          </div>
        ) : <div />}
      </div>
    </div>
  );
};

export default CardGroup;
