import React, { useState, useEffect } from 'react';
import AppSettings from '../../appsettings.js';
import DropdownV2 from './DropdownV2.js';
import { getTrims } from '../../services/trimsService.js';
import getAlternativeVehicleNames from '../../services/alternativeVehicleNames.js';
import '../../styles/css/carSelectDropdown.css';

const VehicleApplicabilityDropdown = (props) => {
    const downChevron = AppSettings.AWSImgRepo.resources + 'en/Down_Arrow_Black.png';
    const dropdownClosedArrow = <img className="dropdown-arrow"
        src={downChevron}/>;
    const dropdownOpenArrow = <img className="dropdown-arrow-upside-down"
        src={downChevron}/>;

    const [modelYear, setModelYear] = useState(null);
    const [modelName, setModelName] = useState(null);
    const [trim, setTrim] = useState(null);
    const [yearOptions, setYearOptions] = useState(null);
    const [modelOptions, setModelOptions] = useState([]);
    const [trimOptions, setTrimOptions] = useState([]);
    const [trims, setTrims] = useState([]);
    const [modelDisabled, setModelDisabled] = useState(true);
    const [trimDisabled, setTrimDisabled] = useState(true);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [alternativeVehicleNames, setAlternativeVehicleNames] = useState([]);


    useEffect(() => {
        const fetchAlternativeVehicleNames = async () => {
            const names = await getAlternativeVehicleNames();
            setAlternativeVehicleNames(names);
        };
        fetchAlternativeVehicleNames();

        const lineupYearOptions = getAudioMultimediaLineupYears();
        setYearOptions(lineupYearOptions);
    }, []);

    function getAudioMultimediaLineupYears() {
        const years = props.yearModelTrimData.years.map(x => x.year);
        years.sort((a, b) => b - a); // sort years in descending orde
        return years;
    }

    function getModels(year) {
        const yearData = props.yearModelTrimData.years;

        const modelsArr = yearData.find(item => item.year === year);
        if (!modelsArr) return [];

        const models = modelsArr.models.map(model => model.model);

        const modelDisplayNames = models.map(model => {
            const altNameObj = alternativeVehicleNames?.Items?.find(alt =>
                alt.altVehicleName.some(name => name.toLowerCase() === model.toLowerCase())
            );
            return altNameObj?.officialName ?? altNameObj?.displayName ?? model;
        });

        const sortedModelDisplayNames = modelDisplayNames.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

        return sortedModelDisplayNames;
    }

    function getTrimFromAMMData(modelName) {
        const yearData = props.yearModelTrimData.years;

        const modelsArr = yearData.find(item => item.year === modelYear);
        if (!modelsArr) return [];

        const altNameObj = alternativeVehicleNames?.Items?.find(alt =>
            alt.altVehicleName.some(name => name.toLowerCase() === modelName.toLowerCase())
        );

        const modelNamesToCheck = altNameObj ? [altNameObj.officialName, altNameObj.displayName, ...altNameObj.altVehicleName] : [modelName];

        const trimsArr = modelsArr.models.find(m =>
            modelNamesToCheck.some(name => name && name.toLowerCase() === m.model.toLowerCase())
        );

        return trimsArr;
    }

    function getAudioMultimediaTrims(model) {
        const trimsArr = getTrimFromAMMData(model);
        if (!trimsArr) return [];

        const trims = trimsArr.trims.map(x => ({
            trim: x.trim,
            standardMediaId: x.standardMediaId,
            optionalMediaId: x.optionalMediaId,
            standardOverrideImage: x.standardOverrideImage,
            optionalOverrideImage: x.optionalOverrideImage,
            standardMediaAddOns: x.standardMediaAddOns,
            optionalMediaAddOns: x.optionalMediaAddOns
        }));

        return trims;
    }

    function handleYearChange(event) {
        const year = event.value;
        const models = getModels(year);
        setTrimOptions([]);
        setModelOptions(models);
        setModelName('Model');
        setModelYear(year);
        setTrimDisabled(true);
        setTrim(null);
        setButtonDisabled(true);

        if (year) {
            setModelDisabled(false);
        } else {
            setModelDisabled(true);
        }
        props.yearSelectAnalytics && props.yearSelectAnalytics(year);
    }

    function handleModelChange(event) {
        const model = event.value;
        const trims = getAudioMultimediaTrims(model.toLowerCase());
        const trimsList = getUniqueTrims(trims);

        setTrims(trims);
        setTrimOptions(trimsList);
        setModelName(model);
        setTrimDisabled(false);
        setTrim('Grade');

        setButtonDisabled(true);
        props.modelSelectAnalytics && props.modelSelectAnalytics(model)
    }

    function getUniqueTrims(trims) {
        let uniqueTrims = new Set(trims.map(t => t.trim.trim()));
        return [...uniqueTrims];
    }

    const getTrimImage =  async (ammName, trimName) => {
        const data = await getTrims(ammName, modelYear, 'en');
        const uniqueTrims = data?.trims.map(t => ({name: t.name,trimImageFilename: t.trimImageFilename}));
        const selectedTrim = [...uniqueTrims].find(t => t.name === trimName);
        return selectedTrim ? selectedTrim.trimImageFilename : "noneFound.png";
    }

    function handleTrimChange(event, trimName) {
        const trimPicked = trimName || event.value;
        if (trimName) {
            setTrim(null);
        } else {
            setTrim(trimPicked);
            setButtonDisabled(false);
        }


        props.trimSelectAnalytics && props.trimSelectAnalytics(trimPicked)
    }

    const handleButtonClick = async () => {
        const trimArr = getTrimFromAMMData(modelName);
        const ammName = trimArr?.model ?? modelName;
        const selectedTrimImage = await getTrimImage(ammName, trim);

        const trimMediaData = trims.find(t => t.trim === trim);
        const mediaSystems = getByMediaSystemIds(trimMediaData.standardMediaId, trimMediaData.optionalMediaId, trimMediaData.standardMediaAddOns, trimMediaData.optionalMediaAddOns);

        const carData = {
            selectedCar: modelYear + " **" + modelName + " " + trim + "**",
            selectionYear: modelYear,
            selectionModel: ammName,
            selectionTrim: trim,
            standardSystem: mediaSystems.standard,
            optionalSystem: mediaSystems.optional,
            imageUrl: selectedTrimImage,
        }

        props.parentCallback(carData);

        props.goClickAnalytics && props.goClickAnalytics(modelYear, modelName, trim)
    };

    const getByMediaSystemIds = (standardId, optionalId, standardAddOnIds, optionalAddOnIds) => {
        const lineupData = props.entuneData.lineupData;
        const addOnsLibrary = props.entuneData.addOns;
        const notFound = { title: 'Not Available' }

        let standard = lineupData.find(mediaSystem => mediaSystem.ID === standardId);
        let standardAddOns = addOnsLibrary.filter(addOn => standardAddOnIds?.split(",").includes(addOn.ID));

        let newStandard = {};


        if (standard) {
            newStandard = {
                ...standard,
                features: [...standard.features, ...standardAddOns]
            };
        } else if (standardAddOns) {
            newStandard = { title: "", features: standardAddOns };
        } else {
            newStandard = notFound;
        }

        let optional = lineupData.find(mediaSystem => mediaSystem.ID === optionalId);
        let optionalAddOns = addOnsLibrary.filter(addOn => optionalAddOnIds?.split(",").includes(addOn.ID));

        let newOptional = {};

        if (optional) {
            newOptional = {
                ...optional,
                features: [...optional.features, ...optionalAddOns]
            };
        } else if (optionalAddOns) {
            newOptional = { title: "", features: optionalAddOns };
        } else {
            newOptional = notFound;
        }

        return {
            standard: newStandard,
            optional: newOptional
        }
    }

    return (
        <div className="carDropdownGroup">
            <div className="filterDropdown yearDropdown">
                <DropdownV2
                    style="dark-grey"
                    rootClassName="amm-filterDropdown"
                    options={yearOptions}
                    placeholder="Year"
                    value={modelYear?.toString()}
                    onValueChange={handleYearChange}
                /></div>
            <div className="filterDropdown">
                <DropdownV2
                    style={modelDisabled ? 'disabled' : "dark-grey"}
                    rootClassName="amm-filterDropdown"
                    options={modelOptions}
                    placeholder="Model"
                    value={modelName}
                    onValueChange={handleModelChange}
                    disabled={modelDisabled}
                /></div>
            <div className="filterDropdown">
                <DropdownV2
                    style={trimDisabled ? 'disabled' : "dark-grey"}
                    rootClassName="amm-filterDropdown"
                    options={trimOptions}
                    onValueChange={handleTrimChange}
                    placeholder="Grade"
                    disabled={trimDisabled}
                    value={trim}
                /></div>
            <div className={buttonDisabled ? 'vehicleSelectButtonDisabled': 'vehicleSelectButton'}
                onClick={handleButtonClick}>
                <span>Go</span>
            </div>
        </div>
    );
};


export default VehicleApplicabilityDropdown;
