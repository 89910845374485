import React from 'react';
import applyMarkdown from '../utils/applyMarkdown';
import AppSettings from '../../appsettings';

function AlertBanner(props) {
    const dismissIcon = AppSettings.AWSImgRepo.resources + 'en/icon_close_white.png';

    return (
        <div className="alert-banner-container">
            <div className="text">
                <div className="dismiss-icon" onClick={() => props.dismissAlert(props.alert)}>
                    <a><img src={dismissIcon} alt='X' /></a>
                </div>
                <div className="header-text">{props.alert.title}</div>
                <span className="message-text">{applyMarkdown(props.alert.message)}</span>
            </div>
        </div>
    );
}

export default AlertBanner;
