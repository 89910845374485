import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import '../../styles/css/featureComparison.css';
import { isSpecBubble, isDash } from '../utils/helpers';
import applyMarkdown from '../utils/applyMarkdown';
import {PopupTooltipContext} from '../../components/contexts/popupTooltipContext';
import { TooltipV2 } from '../../components/molecules/tooltipV2';
import { getGlossaryTooltips } from '../../services/glossaryService'
import { glossaryOverlayClose, glossaryOverlayOnLoad, glossaryOverlayCtaClick } from '../glossary/glossary.analytics';
import { isDesktopView, isTabletView, isMobileView } from '../../screens/utils/getScreenSize.js';

const VehicleComparisonFeature = (props) => {
  const [description, setDescription] = useState('');
  const [transformedDescription, setTransformedDescription] = useState(null);
  const [disclaimerIndex, setDisclaimerIndex] = useState('');

  const { setVisiblePopupTooltip, setCurrentPopupTooltip, checkCurrentTooltipMatch } = useContext(PopupTooltipContext);

  const history = useHistory();

  useEffect(()=>{
    for (let i = 0; i < props.selection.length; i++) {
      if (props.feature[props.selection[i].year]) {
        setDescription(props.feature[props.selection[i].year].Description);
        setDisclaimerIndex(i == 0 ? 0 : 1);
        break;
      }
    }

  },[]);

  useEffect(()=>{
    for (let i = 0; i < props.selection.length; i++) {
      if (props.feature[props.selection[i].year]) {
        setDescription(props.feature[props.selection[i].year].Description);
        setDisclaimerIndex(i == 0 ? 0 : 1);
        break;
      }
    }
  },[props]);

  useEffect(async()=>{
    for (let i = 0; i < props.selection.length; i++) {
      if (props.feature[props.selection[i].year]) {
        if(props.glossaryTerms?.length > 0){
          setDescription(props.feature[props.selection[i].year].Description);
          const transformedDescription = await getGlossaryTooltips(props.feature[props.selection[i].year].Description, props.glossaryTerms);
          setTransformedDescription(transformedDescription.finalText);
        }
        break;
      }
    }
  },[props.glossaryData, props.selection]);

  let vehiclesItems = [];
  if (props.selection) {
    vehiclesItems = props.selection.map((vehicle, index) => {
      let val = props.feature[vehicle.year]
        ? (!isDash(props.feature[vehicle.year][vehicle.trim.modelCode])
          ? props.feature[vehicle.year][vehicle.trim.modelCode] : '—') : '—';
      if (!val) {
        val = '—';
      }
      return (
        <div key={`vehicle-${index}`} className={'spec-container columns'}>
          <div className={isSpecBubble(val) ? (val === 'O/P' ? 'spec-oval' : 'spec-circle') : 'specification'}>
            {/* Subsequent calls to bind do not overwrite the initial "this" binding, so the null is just a placeholder */}
            <span>{applyMarkdown(val, props.disclaimerCallback.bind(null, index))}</span>
          </div>
        </div>
      );
    });
  }

  const getTooltip = (tooltipData) => (
    <TooltipV2
        closeTooltip={()=>setVisiblePopupTooltip(null)}
        title={tooltipData ? tooltipData.term : null}
        subtitle={tooltipData ? tooltipData.category : null}
        description={tooltipData ? tooltipData.description.slice(0,200)+"..." : null}
        moreLinkFunction={tooltipData ? ()=>{
          setVisiblePopupTooltip(null)
          history.push('/glossary/all?term=' + tooltipData.term)
        } : null}
        analyticsOpenTooltip={()=>glossaryOverlayOnLoad(props.analyticsData?.clickAnalytics, tooltipData.term, 'Features:Features and Specs')}
        analyticsCloseTooltip={()=>glossaryOverlayClose(props.analyticsData?.clickAnalytics, tooltipData.term, 'Features:Features and Specs')}
        analyticsMoreClick={()=>glossaryOverlayCtaClick(props.analyticsData?.clickAnalytics, tooltipData.term, 'MORE', 'Features:Features and Specs')}
    />
  );

  const applyTooltip = (text, tooltipData) => {
    return (
      <>
        <span>
          <span className="inlineTooltipText" onClick={()=>setCurrentPopupTooltip(description+"."+(tooltipData ? tooltipData.term : ""))}>{text}</span>
          {(isDesktopView(window.innerWidth) && checkCurrentTooltipMatch(description+"."+(tooltipData.term ? tooltipData.term : ""))) && getTooltip(tooltipData)}
        </span>
        {(!isDesktopView(window.innerWidth) && checkCurrentTooltipMatch(description+"."+(tooltipData.term ? tooltipData.term : ""))) && getTooltip(tooltipData)}
      </>
    );
  }

  return (
    <>
    <div className={'row section-item'}>
      <div className={'item-description large-6 medium-6 small-12 columns'}>
        {transformedDescription && transformedDescription.includes("<tooltip>") ? 
          applyMarkdown(transformedDescription, props.disclaimerCallback.bind(null, disclaimerIndex), null, true, applyTooltip, props.glossaryData)
          : applyMarkdown(description, props.disclaimerCallback.bind(null, disclaimerIndex), null, true, applyTooltip, props.glossaryData)}
      </div>
      {vehiclesItems}
    </div>
    </>
  );
}

export default VehicleComparisonFeature;