import React from 'react';
import PropTypes from 'prop-types';
import applyMarkdown from '../../../screens/utils/applyMarkdown';
import '../../../styles/css/marketingPageBasicCard.css';
import { cardTileClick } from '../../../screens/certification/certification.analytics';

const MarketingBasicCard = (props) => {
    const cardIcon = () => (
        <div className={`basic-card-cardIcon${props.title ? '' : 'NoTitle'}`}>
            <img src={props.imagePath + props.icon} />
        </div>
    );

    const cardTitle = () => (
        <div className="basic-card-cardTitle">
            {applyMarkdown(props.title)}
        </div>
    );

    const cardContent = () => (
        <div className="basic-card-cardContent">
            {applyMarkdown(props.description)}
        </div>
    );

    const cardLink = () => (
        <div className='basic-card-link'>
            <a
                onClick={() => handleCardLinkClick()}
                href={props.ctaHref}
            >
                {props.ctaText}
            </a>
        </div>
    );

    const getlabelclass = (label) => {
        let labelClass;
        if (label != null) {
            labelClass = "basic-card-cardLabel"
        }
        else {
            labelClass = "basic-card-emptyCardlabel"
        }
        return labelClass
    };

    const handleCardLinkClick = () => props.clickAnalytics && cardTileClick(props.clickAnalytics, props.ctaText);

    return (        
        <div data-testid="marketing-basic-card" className="basic-card">
            <div className="basic-card-non-link">
                <div className="basic-card-label-container">
                    <div className={getlabelclass(props.label)}>{props.label}</div>
                </div>
                {props.icon && cardIcon()}
                {props.title && cardTitle()}
                {props.description && cardContent()}
            </div>
            {props.ctaText && cardLink()}
        </div>
    );
};

MarketingBasicCard.propTypes = {
    imagePath: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    ctaHref: PropTypes.string, 
    ctaText: PropTypes.string,
    label: PropTypes.string,
};

export default MarketingBasicCard;
