import React from "react";
import { BrowseArticles } from "../../../components/molecules/articles/browseArticles";
import { RecommendedArticles } from "../../../components/molecules/articles/recommendedArticles";
import "../../../styles/scss/resources-v2/related-content.scss";
import { articleClick, articleFilterClick, seeMoreClick } from "./relatedContent.analytics";

export const RelatedContent = ({ model, year, recommendedArticles, articles}) => {

    const articleAnalytics = (articleTitle, type) => {
        articleClick({
            modelName: model,
            modelYear: year,
            positionTitle: type,
            contentTitle: articleTitle
        })
    }

    const seeMoreAnalitycs = (type) => {
        seeMoreClick({
            modelName: model,
            modelYear: year,
            positionTitle: type,
        })
    }

    const articleFilterAnalytics = (label) => {
        articleFilterClick({
            modelName: model,
            modelYear: year,
            contentTitle: label,
        })
    }

    return (
        <div className="related-content-container">
            { (!recommendedArticles || recommendedArticles?.length > 0) &&
                <RecommendedArticles
                    articleAnalytics={articleAnalytics}
                    seeMoreAnalitycs={seeMoreAnalitycs}
                    articles={recommendedArticles}
                />
            }
            <BrowseArticles
                articleFilterAnalytics={articleFilterAnalytics}
                seeMoreAnalitycs={seeMoreAnalitycs}
                articleAnalytics={articleAnalytics}
                articles={articles}
            />
        </div>
    );
};