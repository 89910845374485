import React from 'react';
import "../../styles/css/featureLookupNoResults.css";

function NoResults(props) {
    return (
        <div className='feature-lookup-no-results'>
            {props.headerText ? <div className='header'>{props.headerText}</div> : <></>}
            <div className='divider-line' />
            <div className='message'>{props.message}</div>
        </div>
    );
}

export default NoResults; 
