import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';


const contactInfo = (props) => {
  const breakpoints = {
    desktopTablet: '(min-width: 640px)',
    mobile: '(max-width: 639px)',
  };

  const hasName = !!(props.name);
  const hasPhoneAndEmail = !!(props.phone && props.email);

  return (
    <b>
      {props.name}
      {hasName && <br />}
      <MediaQuery query={breakpoints.desktopTablet}>
        {props.phone}{hasPhoneAndEmail && ' | '}{props.email}
      </MediaQuery>
      <MediaQuery query={breakpoints.mobile}>
        {props.phone}
        {hasPhoneAndEmail && <br />}
        {props.email}
      </MediaQuery>
    </b>
  );
};

contactInfo.propTypes = {
  name: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
};

export default contactInfo;
