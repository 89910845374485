import React from 'react';
import PropTypes from 'prop-types';
import SectionHeader from '../../components/atoms/SectionHeader';
import '../../styles/css/heroImage.css';


const heroImage = (props) => (
  <div className="hero-image-section">
    <SectionHeader>{props.headline}</SectionHeader>
    {!props.hideImage && <img className="hero-image-banner" src={props.image} style={props.topDescription ? {order: 1} : {order: 0}}/>}
    <div className="hero-image-description" style={props.topDescription ? {order: 0} : {order: 1}}>{props.description}</div>
  </div>
);

heroImage.propTypes = {
  description: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  headline: PropTypes.string,
  hideImage: PropTypes.bool,
  image: PropTypes.string,
  topDescription: PropTypes.bool
};

export default heroImage;
