export const isCurrentVersionLower = (comparisonVersion) => {
  try {
    const [currentMajor, currentSecondary, currentMinor] = localStorage?.getItem('app_version').split('.').map((val) => parseInt(val));
    const [comparisonMajor, comparisonSecondary, comparisonMinor] = comparisonVersion.split('.').map((val) => parseInt(val));

    if (comparisonMajor > currentMajor) {
      return true;
    } else if (comparisonMajor === currentMajor) {
      if (comparisonSecondary > currentSecondary) {
        return true;
      } else if (comparisonSecondary === currentSecondary) {
        if (comparisonMinor > currentMinor) {
          return true;
        }
      }
    }
    return false;
  } catch (error) {
    return false;
  }
};
