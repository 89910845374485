import PropTypes from 'prop-types';
import React from 'react';
import '../../../styles/css/news/newsArchive/newsArchiveCard.css';
import NewsArchiveCardContent from './newsArchiveCardContent';
import NewsArchiveCardHeader from './newsArchiveCardHeader';

const NewsArchiveCard = (props) => {
    return (
        <div className='newsArchiveCard'>
            <NewsArchiveCardHeader
                article={props.article}
            />
            <NewsArchiveCardContent
                article={props.article}
                analytics={props.analytics}
                analyticsData={props.analyticsData}
                pageDate={props.pageDate}
            />
        </div>
    )
};

NewsArchiveCard.propTypes = {
    article: PropTypes.object.isRequired,
};

export default NewsArchiveCard;