import React, { useEffect, useState } from 'react';
import getMisc from '../../services/miscService';
import { isDesktopView, isTabletView, isMobileView } from '../utils/getScreenSize';
import FeatureLookupResults from './featureLookupResults';
import FeatureLookupOverview from './featureLookupOverview';
import getAnalytics from '../../services/analyticsService';
import { getFeatures } from '../../services/featureLookupService';

const FeatureLookupRouterComponent = (props) => {
  const [device, setDevice] = useState('desktop');
  const [page, setPage] = useState('Overview');
  const [pageData, setPageData] = useState(null);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [tempSelectedFeatures, setTempSelectedFeatures] = useState([]);
  const [compatibleModels, setCompatibleModels] = useState(null);
  const [features, setFeatures] = useState([]);
  const maxSelections = 3;
  const [analyticsData, setAnalyticsData] = useState();
  const [featuresLoaded, setFeaturesLoaded] = useState(false);
  const [analyticsKeyword, setAnalyticsKeyword] = useState('');
  
  useEffect(() => {
    updateDevice();
    window.addEventListener("resize", updateDevice);
    return () =>
      window.removeEventListener("resize", updateDevice);
  }, []);

  useEffect(() => {
    if(features.length > 0 && tempSelectedFeatures.length > 0){
      const tempFeaturesData = tempSelectedFeatures.map(t => features.filter(f => f.featureName == t.featureName));
      if(tempFeaturesData[0].length==0){
        setTempSelectedFeatures([]); 
        return;};
      const combinedTempFeatures = tempFeaturesData.map(t => combineDuplicateFeatures(t));
      setSelectedFeatures(combinedTempFeatures);
      getCompatibleModels(combinedTempFeatures);
      setTempSelectedFeatures([]);
    }
  }, [features]);

  useEffect(() => {
    getMisc('featureLookup')
      .then((data) => {
        if (!pageData) { setPageData(data); }
      })
      .catch((error) => {
        console.log(`ERROR: failed to load Feature Lookup page data. ${error}`);
      });

    getFeatures()
      .then(data => {
          const sortedFeatures = data?.sort((a, b) => (a.featureName.toLowerCase() > b.featureName.toLowerCase()) ? 1 : -1);
          setFeatures(sortedFeatures);
          setFeaturesLoaded(true);
      });

      getAnalytics("featureLookup")
      .then((data) => {
        setAnalyticsData(data);
      })
      .catch((error) => {
        console.log(`ERROR: failed to load Feature Lookup analytics data. ${error}`);
      });
      
  }, []);

  const updateDevice = () => {
    if (isDesktopView(window.innerWidth)) {
      setDevice('desktop');
    } else if (isTabletView(window.innerWidth)) {
      setDevice('tablet');
    } else if (isMobileView(window.innerWidth)) {
      setDevice('mobile');
    }
  }

  const updatePage = (pageName) => {
    setPage(pageName);
  }

  // Usually the matchingFeatures filter will return an array with one feature.
  // In some cases, a feature is listed in multiple categories, and the array will have two entries
  // (e.g. "4 USB ports (1 USB media port, 3 USB charging ports)" appears in both Interior and Safety/Convenience for some vehicles)
  function combineDuplicateFeatures(features) {
    if (features.length === 1) {
        return features[0];
    }
    if (features[0].modelYears.length === features[1].modelYears.length &&
      features[0].modelYears.every((val, index) => val === features[1].modelYears[index])) {
        return features[0];
    }

    const combinedArray = [...features[0].modelYears];
    features[1].modelYears?.forEach(vehicleYear => {
        if (!combinedArray.find(i => i.model == vehicleYear.model && i.year == vehicleYear.year)) {
            combinedArray.push(vehicleYear);
        }
    })
    return {
        featureName: features[0].featureName,
        modelYears: combinedArray
    };
  };

  function selectFeature(feature) {
      if (selectedFeatures.length < maxSelections) {
        if(features.length > 0){
          const matchingFeatures = features.filter(f => f.featureName == feature.featureName)
          const combinedFeature = combineDuplicateFeatures(matchingFeatures);
          setSelectedFeatures([...selectedFeatures, combinedFeature]);
          getCompatibleModels([...selectedFeatures, combinedFeature]);
        } else {
          setTempSelectedFeatures([...tempSelectedFeatures, feature]);
        }
      }

  }

  function deleteSelectedFeature(feature, index) {
    if(features.length > 0){
      const featureDeleted = selectedFeatures.filter((v, i) => v.featureName !== feature || i !== index);
      setSelectedFeatures(featureDeleted);
      getCompatibleModels(featureDeleted);
    } else {
      const tempFeatureDeleted = tempSelectedFeatures.filter((v, i) => v.featureName !== feature || i !== index);
      setTempSelectedFeatures(tempFeatureDeleted);
    }
  }

  function getCompatibleModels(features) {
    const models = features.map(f => f.modelYears.map(y => ({model: y.model, year: y.year})))

    const compatibleModels = models.length > 0 ? models.reduce((listA,listB) => 
        listA.filter(a => listB.some(b => a.model == b.model && a.year == b.year))
    ) : [];

    setCompatibleModels(compatibleModels);
  }

  const getFeatureLookupPage = () => {
    switch (page) {
      case 'Results': return (
        <FeatureLookupResults
          resultsPageData={pageData?.items.results}
          device={device}
          setPage={updatePage}
          selectedFeatures={selectedFeatures}
          deleteSelectedFeature={deleteSelectedFeature}
          compatibleModels={compatibleModels}
          maxSelections={maxSelections}
          analyticsData={analyticsData}
          analyticsKeyword={analyticsKeyword}
        />
      );
      default: return (
        <FeatureLookupOverview
          features={features}
          overviewPageData={pageData?.items.overview}
          device={device}
          setPage={updatePage}
          selectedFeatures={tempSelectedFeatures.length > 0 ? tempSelectedFeatures : selectedFeatures}
          selectFeature={selectFeature}
          deleteSelectedFeature={deleteSelectedFeature}
          compatibleModels={compatibleModels}
          maxSelections={maxSelections}
          analyticsData={analyticsData}
          featuresLoaded={featuresLoaded}
          setAnalyticsKeyword={setAnalyticsKeyword}
        />
      );
    }
  }

  return (
    getFeatureLookupPage()
  );

};

export default FeatureLookupRouterComponent;