import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();

export const generalNavButtonClicked = (pageLoadAnalytics) => {
  pageLoadAnalytics && analytics.fireOnClick({
    tag: pageLoadAnalytics.tagId,
    container: pageLoadAnalytics.container,
    category: pageLoadAnalytics.category,
    label: pageLoadAnalytics.label,
  });
};

export const searchButtonClicked = (pageLoadAnalytics, searchTerm) => {
  pageLoadAnalytics && analytics.fireOnClick({
    tag: pageLoadAnalytics.tagId,
    container: pageLoadAnalytics.container,
    category: pageLoadAnalytics.category,
    label: pageLoadAnalytics.label,
    keyword: searchTerm,
  });
};

export const closeNavClicked = (pageLoadAnalytics) => {
  pageLoadAnalytics && analytics.fireOnClick({
    tag: pageLoadAnalytics.tagId,
    container: pageLoadAnalytics.container,
    category: pageLoadAnalytics.category,
    label: pageLoadAnalytics.close,
  });
};

export const menuItemClicked = (pageLoadAnalytics, label) => {
  pageLoadAnalytics && analytics.fireOnClick({
    tag: pageLoadAnalytics.tagId,
    container: pageLoadAnalytics.container,
    category: pageLoadAnalytics.category,
    label: label,
  });
};

export const vehicleClicked = (pageLoadAnalytics, modelYear, modelName) => {
  pageLoadAnalytics && analytics.fireOnClick({
    tag: pageLoadAnalytics.tagId,
    container: pageLoadAnalytics.container,
    category: pageLoadAnalytics.category,
    label: modelName,
    modelYear: modelYear,
    modelName: modelName,
  });
};