import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Sticky, StickyContainer } from 'react-sticky';
import SectionHeader from '../../components/atoms/SectionHeader';
import '../../styles/css/trimWalk.css';
import DisclaimersComponent from '../disclaimers/disclaimers.component';
import applyMarkdown from '../utils/applyMarkdown';
import MultipleVehicleSelection from '../vehicleSelection/multipleVehicleSelection.component';

export default function TrimWalkTemplate(component) {
  const disclaimers = component.state.selectedVehicles.map((veh, index) => <DisclaimersComponent
                key={`disclaimer-${index}`}
                template={`${veh.year}_${component.props.route.model}`}
                ref={(instance) => { component.disclaimers[index] = instance; }}
                isSearchDisclaimer={false}
                analyticsOnSelection={component.sendDisclaimersAnalytics}
                addBaseMSRPDisclaimer={true}
            />);
  const firstTrimExtFeatures = [];
  const firstTrimIntFeatures = [];
  const firstTrimSafFeatures = [];
  const secondTrimExtFeatures = [];
  const secondTrimIntFeatures = [];
  const secondTrimSafFeatures = [];

  let lowerTrimPackageElemnet = null;
  let higherTrimPackageElemnet = null;

  if (component.state.lowerTrimPackages && component.state.lowerTrimPackages.size !== 0) {
    lowerTrimPackageElemnet = getPackages(component.state.lowerTrimPackages, component.disclaimerCallback);
  } else {
    lowerTrimPackageElemnet = getNotAvailablePackage(component.state.lowerTrimName);
  }
  if (component.state.higherTrimPackages && component.state.higherTrimPackages.size !== 0) {
    higherTrimPackageElemnet = getPackages(component.state.higherTrimPackages, component.disclaimerCallback);
  } else {
    higherTrimPackageElemnet = getNotAvailablePackage(component.state.higherTrimName);
  }

  if (component.state.lowerTrimFeatures) {
    if (component.state.lowerTrimFeatures.Exterior) {
      const sortedlowerTrimFeaturesEx = new Map([...component.state.lowerTrimFeatures.Exterior.entries()].sort((a, b) => b[1].localeCompare(a[1])));
      sortedlowerTrimFeaturesEx.forEach((value, key) => {
        firstTrimExtFeatures.push(getFeaturesDiv(key, value, component));
      });
    }
    if (component.state.lowerTrimFeatures.Interior) {
      const sortedlowerTrimFeaturesInt = new Map([...component.state.lowerTrimFeatures.Interior.entries()].sort((a, b) => b[1].localeCompare(a[1])));
      sortedlowerTrimFeaturesInt.forEach((value, key) => {
        firstTrimIntFeatures.push(getFeaturesDiv(key, value, component));
      });
    }
    if (component.state.lowerTrimFeatures.Safety) {
      const sortedlowerTrimFeaturesSaf = new Map([...component.state.lowerTrimFeatures.Safety.entries()].sort((a, b) => b[1].localeCompare(a[1])));
      sortedlowerTrimFeaturesSaf.forEach((value, key) => {
        firstTrimSafFeatures.push(getFeaturesDiv(key, value, component));
      });
    }
  }

  if (component.state.higherTrimFeatures) {
    if (component.state.higherTrimFeatures.Exterior
            && Object.keys(component.state.higherTrimFeatures).length !== 0
            && component.state.higherTrimFeatures.Exterior.size !== 0) {
      const sortedhigherTrimFeaturesEx = new Map([...component.state.higherTrimFeatures.Exterior.entries()].sort((a, b) => b[1].localeCompare(a[1])));
      sortedhigherTrimFeaturesEx.forEach((value, key) => {
        secondTrimExtFeatures.push(getFeaturesDiv(key, value, component));
      });
    } else {
      const extItem = <div key={component.state.lowerTrimSelection + component.state.higherTrimSelection} className="row featureRow">
                    <div className="column small-12 item-description">
                        <FormattedMessage id="no.additional.features" defaultMessage="No additional features added" />
                    </div>
                </div>;
      secondTrimExtFeatures.push(extItem);
    }
    if (component.state.higherTrimFeatures.Interior
            && Object.keys(component.state.higherTrimFeatures).length !== 0
            && component.state.higherTrimFeatures.Interior.size !== 0) {
      const sortedhigherTrimFeaturesInt = new Map([...component.state.higherTrimFeatures.Interior.entries()].sort((a, b) => b[1].localeCompare(a[1])));
      sortedhigherTrimFeaturesInt.forEach((value, key) => {
        secondTrimIntFeatures.push(getFeaturesDiv(key, value, component));
      });
    } else {
      const intItem = <div key={component.state.lowerTrimSelection + component.state.higherTrimSelection} className="row featureRow">
                    <div className="column small-12 item-description">
                        <FormattedMessage id="no.additional.features" defaultMessage="No additional features added" />
                    </div>
                </div>;
      secondTrimIntFeatures.push(intItem);
    }
    if (component.state.higherTrimFeatures.Safety
            && Object.keys(component.state.higherTrimFeatures).length !== 0
            && component.state.higherTrimFeatures.Safety.size !== 0) {
      const sortedhigherTrimFeaturesSaf = new Map([...component.state.higherTrimFeatures.Safety.entries()].sort((a, b) => b[1].localeCompare(a[1])));
      sortedhigherTrimFeaturesSaf.forEach((value, key) => {
        secondTrimSafFeatures.push(getFeaturesDiv(key, value, component));
      });
    } else {
      const safetyItem = <div key={component.state.lowerTrimSelection + component.state.higherTrimSelection} className="row featureRow">
                    <div className="column small-12 item-description">
                        <FormattedMessage id="no.additional.features" defaultMessage="No additional features added" />
                    </div>
                </div>;
      secondTrimSafFeatures.push(safetyItem);
    }
  }
  const coachScreen = getCoachScreenContent();
  return (
        <StickyContainer>
            <div key={component.state.lowerTrimSelection + component.state.higherTrimSelection}>
                <div className="trimWalk-content">
                    <div className="header-wrapper">
                        <SectionHeader id="trimWalk.pageTitle" style={{ marginBottom: '0px' }} />
                        <div className="button-container">
                            <a className="what-is-this-button" onClick={() => component.handleCoachScreenClick()} href="#coach-screen"><FormattedMessage id="trimWalk.coach.screen.button" defaultMessage="What is this?" /></a>
                        </div>
                    </div>
                    <div className="row align-left">
                        <div className="column small-6 lower-trim">
                            <MultipleVehicleSelection modelYears={component.state.vehicleYears}
                                engine={component.props.route.engine}
                                selectedTrimIndex={component.state.lowerTrimSelection}
                                trimWalk={true}
                                trims={component.state.trims}
                                forceCurrentYear={false}
                                lowerTrim={true}
                                analyticsOnChange={component.sendLowerTrimAnalytics}
                                yearOptions={component.state.lowerTrimYearsOptions}
                                year={(component.state.selectedVehicles && component.state.selectedVehicles.length > 1)
                                  ? component.state.selectedVehicles[0].year : component.state.secondYear}
                                onSelectionChanged={component.updatelowerTrimSelectedVehicles.bind(component)}
                                maxSelections={2}
                                s3Path={component.state.s3Path}
                                disclaimerCallback={component.disclaimerCallback} />

                        </div>
                        <div className="column small-6 higher-trim">
                            <MultipleVehicleSelection modelYears={component.state.vehicleYears}
                                engine={component.props.route.engine}
                                selectedTrimIndex={component.state.higherTrimSelection}
                                trimWalk={true}
                                analyticsOnChange={component.sendHigherTrimAnalytics}
                                trims={component.state.trims}
                                forceCurrentYear={true}
                                lowerTrim={false}
                                year={component.state.higherTrimYear}
                                onSelectionChanged={component.updatehigherTrimSelectedVehicles.bind(component)}
                                maxSelections={2}
                                s3Path={component.state.s3Path}
                                disclaimerCallback={component.disclaimerCallback}
                                trimWalkTier={component.state.trimWalkTier}
                                />
                        </div>
                    </div>
                    <div className="row">
                        <div className="column small-6">
                            <span className="msrp-label">
                                <FormattedMessage id="msrp.base" defaultMessage="Base MSRP:" />
                                <sup className="disclaimer-link" onClick={() => component.disclaimers[0].onOpenModal()}>&dagger;</sup>
                            </span>
                            <label className="accessory-detail-bold">
                                {component.state.lowerTrimMsrp}
                            </label>
                        </div>
                        <div className="column small-6 ">
                            <span className="msrp-label">
                                <FormattedMessage id="msrp.base" defaultMessage="Base MSRP:" />
                                <sup className="disclaimer-link" onClick={() => component.disclaimers[0].onOpenModal()}>&dagger;</sup>
                            </span>
                            <div className="row">
                                <div className="column small-12 secondMsrp">
                                    <div className="accessory-detail-bold">
                                        {component.state.higherTrimMsrp}
                                    </div>
                                    <div className="additional">
                                        (
                                <FormattedMessage id="msrp.additional" defaultMessage="Addit'l" />
                                        {component.state.msrpDiff}
                                        )
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row feature-header">
                        <div className="column small-6">
                            <label className="feature-header-content">Notable Features</label>
                        </div>
                        <div className="column small-6">
                            <label className="feature-header-content">
                                Adds to {(component.state.selectedVehicles && component.state.selectedVehicles.length > 0) ? component.state.selectedVehicles[0].trim.name : ''}
                            </label>
                        </div>
                    </div>

                    <Sticky topOffset={340}>
                        {
                            ({ style, isSticky }) => {
                              if (isSticky) {
                                return (
                                        <div 
                                            className="trim-walk-sticky-models"
                                            style={{
                                                ...style, backgroundColor: '#252725', color: '#FFFFFF', zIndex: '9999',
                                            }}
                                        >
                                            <div className="row">
                                                <div className="column small-6">
                                                    <div className="header-bar">
                                                        {component.state.lowerTrimFullName}
                                                    </div>
                                                </div>
                                                <div className="column small-6 header-bar-right">
                                                    <div className="header-bar">
                                                        {component.state.higherTrimFullName}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                );
                              }
                              return <div></div>;
                            }
                        }
                    </Sticky>
                    <hr className="hrLine"></hr>
                    <div className="row">
                        <div className="column small-6 lower-trim-ext">
                            <label className="feature-cat">Exterior</label>
                            <div className="red-border-right"></div>
                            {firstTrimExtFeatures}
                        </div>
                        <div className="column small-6 higher-trim-ext">
                            <label className="feature-cat">Exterior</label>
                            <div className="red-border-right"></div>
                            {secondTrimExtFeatures}
                        </div>
                    </div>
                    <hr className="hrLine"></hr>
                    <div className="row">
                        <div className="column small-6 lower-trim-int">
                            <label className="feature-cat">Interior</label>
                            <div className="red-border-right"></div>
                            {firstTrimIntFeatures}
                        </div>
                        <div className="column small-6 higher-trim-int">
                            <label className="feature-cat">Interior</label>
                            <div className="red-border-right"></div>
                            {secondTrimIntFeatures}
                        </div>
                    </div>
                    <hr className="hrLine"></hr>
                    <div className="row">
                        <div className="column small-6 lower-trim-saf">
                            <label className="feature-cat">Safety/Convenience</label>
                            <div className="red-border-right"></div>
                            {firstTrimSafFeatures}
                        </div>
                        <div className="column small-6 higher-trim-saf">
                            <label className="feature-cat">Safety/Convenience</label>
                            <div className="red-border-right"></div>
                            {secondTrimSafFeatures}
                        </div>
                    </div>
                    <hr className="hrLine"></hr>
                    <div className="row">
                        <div className="column small-6 lower-trim-package">
                            <label className="feature-cat">Packages</label>
                            <div className="red-border-right"></div>

                            {lowerTrimPackageElemnet}


                        </div>
                        <div className="column small-6 higher-trim-package">
                            <label className="feature-cat">Packages</label>
                            <div className="red-border-right"></div>
                            {higherTrimPackageElemnet}
                        </div>
                    </div>
                    <hr className="hrLine"></hr>
                    {disclaimers}
                    {coachScreen}
                    <div 
                        className="column small-12" 
                        style={{
                            justifyContent: 'flex-end',
                            display: 'flex'
                        }}
                    >
                        <div className="row legends-row">
                            <div className='column small-2 spec-circle-s'>
                                <span>S</span>
                            </div>
                            <div className='column legends'>
                                <FormattedMessage id="legend.standard" defaultMessage="Standard" />
                            </div>
                            <div className='column small-2 spec-circle-p' style={{
                                marginLeft: 15
                            }}>
                                <span>P</span>
                            </div>
                            <div className='column legends end'>
                                <FormattedMessage id="legend.pacakge" defaultMessage="Package" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StickyContainer>
  );
}

function getPackages(trimPackages, disclaimerCallback) {
  const items = [];

  trimPackages.forEach((value, key) => {
    const item = <div key={key} className="pacakge-wrapper">
            <div className="package-header">
            </div>
            <div>{applyMarkdown(value.description, disclaimerCallback.bind(null, 0), null, true)}</div>
        </div>;
    items.push(item);
  });
  return items;
}

function getNotAvailablePackage(trimName) {
  const item = <div className="item-description">{`No packages available at this time for ${trimName}`}</div>;
  return item;
}

function getFeaturesDiv(key, value, component) {
  const item = <div key={key + component.state.lowerTrimSelection + component.state.higherTrimSelection} className="row featureRow">
        <div className="column small-2 medium-1 ">
            <div className={value.toLowerCase() === 'p' ? 'spec-circle-p' : 'spec-circle-s'}>
                <span>{applyMarkdown(value)}</span>
            </div>
        </div>
        <div className="column small-10 medium-10 end large-11 item-description">
            {applyMarkdown(key, component.disclaimerCallback.bind(null, 0), null, true)}
        </div>
    </div>;
  return item;
}

function getCoachScreenContent() {
  const content = <div id="coach-screen" className="overlay">
        <div className="coach-screen-wrapper">
            <div className="show-for-small-only row arrows">
                <div className="column small-offset-2 small-3">
                    <div className="left-number-circle">1</div>
                    <span className="show-for-small-only left-arrow">&#8593;</span>
                </div>
                <div className="column small-offset-3 small-2 end">
                    <div className="right-number-circle">2</div>
                    <span className="show-for-small-only left-arrow">&#8593;</span>
                </div>
            </div>

            <div className="popup">
                <a className="close" href="#"></a>
                <div>
                    <div className="row">
                        <div className="column small-12 coach-screen-title">
                            <FormattedMessage id="trimWalk.coach.screen.title" defaultMessage="This is Trim Walk" />
                            <hr className="hrLine"></hr>
                        </div>
                        <div className="column small-6">
                            <div className="step-header">
                                <FormattedMessage id="trimWalk.first.title" defaultMessage="Step 1" />
                            </div>
                            <div className="red-border-right"></div>
                            <div className="step-content">
                                <FormattedMessage
                                    id="trimWalk.first.step.part1"
                                    defaultMessage="Start by selecting model year, powertrain, and trim from the dropdown menus" />
                                <span className="bold-text"> <FormattedMessage
                                    id="trimWalk.first.step.part2"
                                    defaultMessage="on the left." />
                                </span>
                            </div>
                        </div>
                        <div className="column small-6">
                            <div className="step-header">
                                <FormattedMessage id="trimWalk.second.title" defaultMessage="Step 2" />
                            </div>
                            <div className="red-border-right"></div>
                            <div className="step-content">
                                <FormattedMessage
                                    id="trimWalk.second.step.part1"
                                    defaultMessage="Once you've locked in your choice, pick a " />
                                <span className="bold-text">
                                    <FormattedMessage
                                        id="trimWalk.second.step.part2"
                                        defaultMessage="higher trim level " />
                                </span>
                                <FormattedMessage
                                    id="trimWalk.second.step.part3"
                                    defaultMessage="from the dropdown menu on the right and start comparing!" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="show-for-medium row arrows">
                <div className="column small-offset-2 small-3">
                    <span className="left-arrow">&#8595;</span>
                    <div className="left-number-circle">1</div>
                </div>
                <div className="column medium-offset-3 large-offset-2 small-2 end second-step">
                    <span className="right-arrow">&#8595;</span>
                    <div className="right-number-circle">2</div>
                </div>
            </div>
        </div>
    </div>;
  return content;
}
