import React from 'react';
import applyMarkdown from '../../../utils/applyMarkdown';
import AppSettings from '../../../../appsettings.js';

export default function KeyAdvantages(props) {
    const toyotaKeyAdvantages = props.currentEdge.edgeContent.keyAdvantages.toyotaAdvantages.map((toyotaAdvantage, index) => (
        <div className="key-advantage" key={index}>
            <div className="icon-container">
                <img alt={props.sampleImage} className="ka-icon" src={AppSettings.AWSImgRepo.edge + props.s3Path + toyotaAdvantage.icon} />
            </div>
            <div className="description" value={toyotaAdvantage.description}>
                <div>{applyMarkdown(toyotaAdvantage.description, props.disclaimer)}</div>
            </div>
        </div>
    ));

    const competitorKeyAdvantages = props.currentEdge.edgeContent.keyAdvantages.competitorAdvantages.map((competitorAdvantage, index) => (
        <div className="key-advantage" key={index}>
            <div className="icon-container">
                <img alt={props.sampleImage} className="ka-icon" src={AppSettings.AWSImgRepo.edge + props.s3Path + competitorAdvantage.icon} />
            </div>
            <span className="description" value={competitorAdvantage.description}>
                <div>{applyMarkdown(competitorAdvantage.description, props.disclaimer)}</div>
            </span>
        </div>
    ));

    return (
        <div className="container">
            <div className="columns large-6 medium-6 small-6 container-border">
                {toyotaKeyAdvantages}
            </div>
            <div className="columns large-6 medium-6 small-6">
                {competitorKeyAdvantages}
            </div>
        </div>
    );
}