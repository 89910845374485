import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/css/filterMenu.css';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

export function FilterMenu(props) {
  const filterButtons = props.filters && props.filters.map((filter, index) => {
    const isActive = props.active?.toLowerCase() === filter.id?.toLowerCase();
    const filterLabelClass = isActive ? 'active filter-label' : 'filter-label';
    return <div className="filter-item-container" key={index}>
      <div className={isActive ? 'filter-item red-border' : 'filter-item'}>
        {filter.linkTo
          ? <Link to={filter.linkTo} name={filter.id} className={filterLabelClass} onClick={e => props.filterClick(e, filter)}>
            <FormattedMessage id={filter.label} defaultMessage={filter.label} />
          </Link>
          : <button target="_parent" name={filter.id} className={filterLabelClass} onClick={e => props.filterClick(e, filter)}>
            <FormattedMessage id={filter.label} defaultMessage={filter.label} />
          </button>
        }
      </div>
    </div>;
  });

  return (
    <div className={`filter-menu ${props.className}`}>
      {filterButtons}
    </div>
  );
}

FilterMenu.propTypes = {
  active: PropTypes.string,
  filters: PropTypes.array,
  displayArrow: PropTypes.bool,
};
