import React from 'react';
import Vehicle from './vehicle.component';
import PrimaryVehicle from './primaryVehicle.component';

export function VehiclesTemplate(component) {
  let content = null;

  if (component.props.vehicles && component.props.vehicles.length) {
    content = component.props.vehicles.map((vehicle, index) => ((index === 0)
      ? <PrimaryVehicle key={index} image={component.props.comparisonReport ? component.props.comparisonReport.primaryVehicle.imageURL : ''} index={index} totalVehicles={component.props.vehicles.length} vehicle={vehicle} onVehicleChange={component.onVehicleChange} availableVehicles={component.props.availableVehicles} />
      : <Vehicle key={index} index={index} image={component.props.comparisonReport ? component.props.comparisonReport.competitorVehicles[index - 1].imageURL : ''} totalVehicles={component.props.vehicles.length} vehicle={vehicle} onVehicleChange={component.onVehicleChange} availableVehicles={component.props.availableVehicles} />));
  } else {
    content = <div className="loading-message">{component.props.loadingMessage}</div>;
  }

  return (
    <div className="vehicles">
      {content}
    </div>
  );
}
