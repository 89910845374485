import React from 'react';
import ScrollingModal from '../../components/molecules/scrollingModal';
import applyMarkdown from '../utils/applyMarkdown';

function AlertModal(props) {
    return (
        <ScrollingModal visible={props.modalOpen} closeModal={props.closeModal} modalClassName="">
            <div className="alert-modal-container">
                {props.alerts?.length ? (
                    props.alerts.map((alert, index) => (
                        <div className="text" key={index}>
                            <div className="header-text">{alert.title}</div>
                            <span className="message-text">{applyMarkdown(alert.message)}</span>
                        </div>
                    ))
                ) : (
                    <></>
                )}
            </div>
        </ScrollingModal>
    );
}

export default AlertModal;
