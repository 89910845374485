import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router";
import AppSettings from '../../appsettings';
import "../../styles/css/viewLargerImages.css"
import { Swiper, SwiperSlide } from "swiper/react";
import { Thumbs } from "swiper";

const ViewLargerImages = (props) => {
    const [showViewer, setShowViewer] = useState(false);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const sliderRef = useRef(null);
    let location = useLocation();

    // Handles required close viewer tasks on navigation
    useEffect(() => {
        closeViewer();
    }, [location]);

    // Handles required close viewer tasks any time the component is unmounted
    useEffect(() => {
        return () => {
            closeViewer();
        };
    }, []);

    const handleToggleAnalytics = (isExpand) => {
        props?.toggleAnalytics({
            isExpand,
            page: props?.pageTitle,
            modelName: props?.model,
            modelYear: props?.year,
            trim: props?.trim
        })
    }

    const handleClickAnalytics = (label) => {
        props?.clickAnalytics({
            label,
            page: props?.pageTitle,
            modelName: props?.model,
            modelYear: props?.year,
            trim: props?.trim
        })
    }

    const openViewer = () => {
        window.scrollTo(0,0);
        document.documentElement.style.overflow = 'hidden';
        setShowViewer(true);
        handleToggleAnalytics(true);
    };

    const closeViewer = () => {
        document.documentElement.style.overflow = 'unset';
        setThumbsSwiper(null);
        setShowViewer(false);
        handleToggleAnalytics(false)
    };

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
        handleClickAnalytics('Back')
    }, [props]);
    
    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
        handleClickAnalytics('Forward')
    }, [props]);

    return (
        <>
            <div className="view-larger-images" onClick={() => openViewer()}>
                <img className="expand-images-icon" src={`${AppSettings.AWSImgRepo.resources}en/imageExpand.png`} alt="Expand"/>
                <div className="expand-images-text">
                    View Larger Images
                </div>
            </div>
            {showViewer && 
                <div className="image-viewer">
                    <div className="image-viewer-content">
                        <div className="viewer-top-section">
                            <img 
                                className="close-viewer-icon"
                                onClick={() => closeViewer()}
                                alt="Close"
                                src={`${AppSettings.AWSImgRepo.resources}en/closeImageViewer.png`} 
                            />
                        </div>
                        <div className="viewer-carousel-section">
                            <img 
                                className="carousel-nav-arrow"
                                onClick={handlePrev}
                                alt="Previous"
                                style={{transform: 'rotate(180deg)'}}
                                src={`${AppSettings.AWSImgRepo.resources}en/imageViewerArrow.png`} 
                            />
                            
                            <Swiper
                                spaceBetween={0}
                                slidesPerView={1}
                                className="viewer-carousel"
                                ref={sliderRef}
                                loop={true}
                                thumbs={{ swiper: thumbsSwiper }}
                                modules={[Thumbs]}
                            >
                                {props?.images?.map((image, index) => 
                                    <SwiperSlide className="full-size-image-slide">
                                        <img className="full-size-image" src={image} alt="vehicle"/>
                                    </SwiperSlide>
                                )}
                            </Swiper>

                            <img 
                                className="carousel-nav-arrow"
                                onClick={handleNext}
                                alt="Next"
                                src={`${AppSettings.AWSImgRepo.resources}en/imageViewerArrow.png`} 
                            />
                        </div>

                        <div className="thumbs-section">
                            <Swiper
                                spaceBetween={20}
                                slidesPerView={4}
                                onSwiper={setThumbsSwiper}
                                modules={[Thumbs]}
                                freeMode={true}
                                threshold={1000}
                            >
                                {props?.images?.map((image, index) => 
                                    <SwiperSlide onClick={() => handleClickAnalytics(`Image ${index}`)}>
                                        <img className="preview-image" src={image} alt="vehicle"/>
                                    </SwiperSlide>
                                )}
                            </Swiper>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default ViewLargerImages;