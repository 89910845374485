import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Sections } from './vehicleOverview.sections.component';
import { Header } from './vehicleOverview.header.component';
import { Award } from './vehicleOverview.award.component';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import AppSettings from '../../appsettings.js';
import '../../styles/css/vehicleOverview.css';
import DisclaimersComponent from '../disclaimers/disclaimers.component';
import applyMarkdown from '../utils/applyMarkdown';
import CarouselComponent from '../carousel/carousel.component';
import SectionHeader from '../../components/atoms/SectionHeader';

export function VehicleOverviewTemplate(component) {
  const awards = component.state.awards.map((item, index) => (
            <div key={index} onClick={component.awardsClick.bind()}>
                <Award imageFilename={AppSettings.AWSImgRepo.vehicle + component.state.s3Path + item.imageFilename} >
                    {applyMarkdown(item.subheadline, component.disclaimer)}
                </Award>
            </div>
  ));

  return (
    <>
      <div className="vehicle-overview">
          {
              (component.state.displayOverrideText)
                ? <div className="languageOverride">
                      <b><FormattedMessage id="dataUnavailable" defaultMessage="El contenido en Español está pendiente" /></b>
                  </div>
                : <div />
          }
          <SectionHeader id="vehicleOverview.pageTitle" />
          {component.state.displayOverrideText !== null ? Header(component) : null}
          {component.state.displayOverrideText !== null ? Sections(component) : null}
          <div className="awards">
              <div className="awards-title">
                  <FormattedMessage id="vehicleOverview.awardsTitle" defaultMessage="Awards" />
              </div>
              <div className="row">
                  <CarouselComponent {...{
                    settings: {
                      dots: true,
                    },
                  }}>{awards}</CarouselComponent>
              </div>
          </div>
          <DisclaimersComponent template={`${component.props.params.year ? component.props.params.year : component.props.route.year}_${component.props.route.model}`} ref={(instance) => { component.disclaimer = instance; }} addStartingMSRPDisclaimer={true}/>
      </div>
    </>
  );
}
