import React from 'react';
import { FormattedMessage } from 'react-intl';
import MarkdownIt from 'markdown-it';
import MarkdownItSup from 'markdown-it-sup';
import dompurify from 'dompurify';
import { capitalize } from '../../../utils/capitalize';
import applyMarkdown from '../../../utils/applyMarkdown';

export function CostToOwn(props) {
  const md = new MarkdownIt().use(MarkdownItSup);

  function calculateAdvantage(cost) {
    let onlyNumToyota;
    let onlyNumCompetitor;
    if (cost.toyota.startsWith('$')) {
      onlyNumToyota = cost.toyota.match(/\d/g);
      onlyNumToyota = onlyNumToyota.join('');
    }
    if (cost.competitor.startsWith('$')) {
      onlyNumCompetitor = cost.competitor.match(/\d/g);
      onlyNumCompetitor = onlyNumCompetitor.join('');
    }
    const advantage = parseInt(onlyNumCompetitor, 10) - parseInt(onlyNumToyota, 10);
    if (advantage > 0) {
      return advantage;
    }
    return 0;
  }

  function TableRowItemsMobile(props) {
    let elementsArray = [];
    elementsArray = props.item.array ? props.item.array.map((cost, index) => {
      const advantage = cost.toyota || cost.competitor ? calculateAdvantage(cost) : 0;
      return (
                <div key={index}>
                    <div className="sm-title">
                        <div dangerouslySetInnerHTML={{ __html: md.renderInline(dompurify.sanitize(cost.title)) }} />
                    </div>
                    <div className="sm-col columns large-6 medium-6 small-6">
                        {advantage > 0
                          ? <div>
                                <span dangerouslySetInnerHTML={{ __html: md.renderInline(dompurify.sanitize(cost.toyota)) }} />&nbsp;
                <div className="bold">
                                    <FormattedMessage id="caHeadToHead.advantageOf" defaultMessage="(Advantage of $" />
                                    {advantage})
                  </div>
                            </div>
                          : <div dangerouslySetInnerHTML={{ __html: md.renderInline(dompurify.sanitize(cost.toyota)) }} />
                        }
                    </div>
                    <div className="sm-col columns large-6 medium-6 small-6 col2">
                        <div dangerouslySetInnerHTML={{ __html: md.renderInline(dompurify.sanitize(cost.competitor)) }} />
                    </div>
                    <div className="sm-line columns large-12 medium-12 small-12"></div>
                </div >
      );
    }) : null;
    return <div>{elementsArray}</div>;
  }

  function TableRowItemsDesktop(props) {
    let elementsArray = [];
    elementsArray = props.item.array ? props.item.array.map((cost, index) => {
      const advantage = cost.toyota || cost.competitor ? calculateAdvantage(cost) : 0;
      return (
                <tr className="lg-row" key={index}>
                    <td className="lg-title">
                        <div dangerouslySetInnerHTML={{ __html: md.renderInline(dompurify.sanitize(cost.title)) }} />
                    </td>
                    <td className="lg-col">
                        {advantage > 0
                          ? <div>
                                <span dangerouslySetInnerHTML={{ __html: md.renderInline(dompurify.sanitize(cost.toyota)) }} />&nbsp;
                <span className="bold">
                                    <FormattedMessage id="caHeadToHead.advantageOf" defaultMessage="(Advantage of $" />
                                    {advantage})</span>
                            </div>
                          : <div dangerouslySetInnerHTML={{ __html: md.renderInline(dompurify.sanitize(cost.toyota)) }} />
                        }
                    </td>
                    <td className="lg-col">
                        <div dangerouslySetInnerHTML={{ __html: md.renderInline(dompurify.sanitize(cost.competitor)) }} />
                    </td>
                </tr>
      );
    }) : null;
    return <tbody>{elementsArray}</tbody>;
  }

  const costToOwnTableDesktop = [];
  props.costsArray.forEach((item) => {
    costToOwnTableDesktop.push(
            <table key={item.name} className="cost-to-own-table-desktop">
                <caption className="table-row-subheader" >
                    {applyMarkdown(item.name, props.disclaimer)}
                </caption>
                <TableRowItemsDesktop item={item} />
            </table>,
    );
  }, this);

  const costToOwnTableMobile = [];
  props.costsArray.forEach((item) => {
    costToOwnTableMobile.push(
            <div key={item.name} className="cost-to-own-table-mobile row">
                <div className="table-row-subheader" >
                    {applyMarkdown(item.name, props.disclaimer)}
                </div>
                <TableRowItemsMobile item={item} />
            </div>,
    );
  }, this);

  return (
        < div className="cto" >
            <div className="cto-headers">
                <div className="cto-space" />
                <div className='cto-bold'>
                    {props.currentEdge.edgeContent.trueCostToOwn.headerTextToyota
                      ? <span>{applyMarkdown(props.currentEdge.edgeContent.trueCostToOwn.headerTextToyota, null)}</span>
                      : <span><span>{applyMarkdown(capitalize(props.vehicleModel), null)}</span>&nbsp;<span>{props.selectedValueToyota}</span></span>
                    }
                </div>
                <div className='cto-bold'>
                    {props.currentEdge.edgeContent.trueCostToOwn.headerTextCompetitor
                      ? <span>{props.currentEdge.edgeContent.trueCostToOwn.headerTextCompetitor}</span>
                      : <span><span>{applyMarkdown(capitalize(props.vehicleModel), null)}</span>&nbsp;<span>{props.selectedValueCompetitor}</span></span>
                    }
                </div>
            </div>
            {costToOwnTableDesktop}
            {costToOwnTableMobile}
            <div className="cto-footnote">* {props.currentEdge.edgeContent.trueCostToOwn.footnote ? props.currentEdge.edgeContent.trueCostToOwn.footnote : ''}</div>
            <div className="cto-permanent-footnote">
                <FormattedMessage id="caHeadToHead.permanentFootNote" defaultMessage="* Values estimated at time of publication and subject to change without notice." />
            </div>
        </div >
  );
}
