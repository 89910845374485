import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { NitroContext } from "../../components/contexts/nitroContext";
import DropdownV2 from '../../components/molecules/DropdownV2';
import "../../styles/css/engageXPMissions.css";
import applyMarkdown from "../utils/applyMarkdown";
import DashboardImageCard from "./components/dashboardImageCard";
import DashboardInfoCard from "./components/dashboardInfoCard";
import Leaderboard from "./components/leaderboard";
import MissionList from "./missionList";
import RecentActivityList from "./recentActivityList";
import { xpMissionsPageLoad, categoryDropdown, statusDropdown, statsPageButtonClick, howDoClick } from "./engageXPMissions.analytics";
import { postViewPageAction } from "../../services/nitroService";
import { extractTournamentMissionIds } from "./utils";

const missionStatusOptions = ["To Do", "Completed"];
const intialCategories = ["All", "Expiring Soon", "In progress"];

const EngageXPMissions = ({
  pageData,
  missions,
  leaderboardConfig,
  leaderboardData,
  leaderboardSelection,
  setLeaderboardSelection,
  isLoadingMissions,
  isLoadingLeaderboard,
  isLoadingLeaderboardDealerships,
  getBlurredBadgeText,
  getColoredBadgeInfo,
  analyticsData,
  waitForMissionsLabels,
  tournamentsData
}) => {
  const history = useHistory();
  const { nitroUserXPLevel } = useContext(NitroContext);
  const [filteredMissions, setFilteredMissions] = useState([]);
  const [additionalCategories, setAdditionalCategories] = useState([]);
  const [missionCategoryOptions, setMissionCategoryOptions] = useState(intialCategories);
  const [selectedMissionCategory, setSelectedMissionCategory] = useState(missionCategoryOptions[0]);
  const [selectedMissionStatus, setSelectedMissionStatus] = useState(missionStatusOptions[0]);

  const pageName = "Engage XP";
  const analyticsCategory = "My Missions";
  const analyticsDashboardCategory = "My Dashboard";
  const contentTitleCategory = selectedMissionCategory;
  const contentTitleStatus = selectedMissionStatus;
  const clickAnalytics = analyticsData?.clickAnalytics;

  const getCategoriesToAdd = () => {
    const categoriesToAdd = [];
    missions.forEach((mission) => {
      if (mission.labels) {
        mission.labels.forEach((label) => {
          const labelToAdd = pageData?.missions?.colorChipOptions?.some((option) => option.title.toLowerCase() === label.name.toLowerCase()) ||
                            pageData?.missions?.imageChipValues?.some((value) => value.toLowerCase() === label.name.toLowerCase());

          if (labelToAdd && !categoriesToAdd.includes(label.name)) {
            categoriesToAdd.push(label.name);
          }
        });
      }
    });
    return categoriesToAdd;
  }

  useEffect(() => {
    const categoriesToAdd = getCategoriesToAdd();
    setAdditionalCategories(categoriesToAdd);
    setMissionCategoryOptions([...intialCategories, ...categoriesToAdd]);
  }, [missions, pageData]);

  useEffect(() => {
    filterMissions();
  }, [missions, selectedMissionCategory, selectedMissionStatus]);

  useEffect(() => {
    if (analyticsData) {
      postViewPageAction(window.location.href);
      xpMissionsPageLoad(analyticsData?.pageLoad?.missions);
    }
}, [analyticsData]);

  const filterMissions = () => {
    const dateCutoff = new Date();
    dateCutoff.setDate(dateCutoff.getDate() - 60); // 60 day cutoff for missions

    const tournamentMissionIds = extractTournamentMissionIds(tournamentsData);

    const isNotCompletedOrIsRepeatable = (mission) =>
      mission.percentComplete < 100 || mission.repeatable;

    const isCompletedAndNotTooOld = (mission) =>
      mission.completionCount > 0 &&
      new Date(mission.lastCompletedTime * 1000) > dateCutoff;

    const isExpiringSoon = (mission) => mission.expiringSoon.status;

    const isInProgress = (mission) => mission.inProgress;

    const shouldFilterByAdditionalCategory = () => {
      return missionCategoryOptions.includes(selectedMissionCategory);
    };

    const filterByCategoryAndStatus = (mission) => {
      switch (selectedMissionCategory) {
        case "All":
          switch (selectedMissionStatus) {
            case "To Do":
              return isNotCompletedOrIsRepeatable(mission);
            case "Completed":
              return isCompletedAndNotTooOld(mission)
            default:
              return false;
          }
        case "Expiring Soon":
          return (
            selectedMissionStatus === "To Do" &&
            isExpiringSoon(mission) &&
            isNotCompletedOrIsRepeatable(mission)
          );
        case "In progress":
          return (
            selectedMissionStatus === "To Do" &&
            isInProgress(mission) &&
            isNotCompletedOrIsRepeatable(mission)
          );
        default:
          if (shouldFilterByAdditionalCategory()) {
            switch (selectedMissionStatus) {
              case "To Do":
                return (
                  mission.labels.some((label) => label.name === selectedMissionCategory) &&
                  isNotCompletedOrIsRepeatable(mission)
                );
              case "Completed":
                return (
                  mission.labels.some((label) => label.name === selectedMissionCategory) &&
                  isCompletedAndNotTooOld(mission)
                );
              default:
                return false;
            }
          } else {
            return false;
          }
      }
    };

    const newFilteredMissions = missions.filter((mission) => {
      const isTournamentMission = tournamentMissionIds.has(mission.id.toString()) || tournamentMissionIds.has(parseInt(mission.id, 10));
      return filterByCategoryAndStatus(mission) && !isTournamentMission;
    });
    setFilteredMissions(newFilteredMissions);
  };

  const handleChangeMissionCategory = (e) => {
    const option = e.value;
    setSelectedMissionCategory(option);
      if (clickAnalytics) {
        categoryDropdown(clickAnalytics, pageName, option, analyticsCategory);
    }
  };

  const handleChangeMissionStatus = (e) => {
    const option = e.value;
    setSelectedMissionStatus(option);
    if (clickAnalytics) {
      statusDropdown(clickAnalytics, pageName, option, analyticsCategory);
    }
    if (["Completed"].includes(option)) {
      const allowedCategories = ["All", ...additionalCategories];
      setMissionCategoryOptions(allowedCategories);
      if (!allowedCategories.includes
        (selectedMissionCategory)) {
        setSelectedMissionCategory("All");
      }
    } else {
      setMissionCategoryOptions([...intialCategories, ...additionalCategories]);
    }
  };

  const navigateToStatsPage = () => {
    statsPageButtonClick(clickAnalytics, pageName, analyticsDashboardCategory);
    history.push("/engage-xp/missions/stats");
  };

  const navigateToHowDoPage = () => {
    howDoClick(clickAnalytics, pageName, analyticsDashboardCategory);
    history.push("/engage-xp/missions/how-do-points-work");
  };

  return (
    <div className="engage-xp-missions">
      <div className="header-section">
        <div className="header-title">
          {applyMarkdown(pageData?.header?.title)}
        </div>
        <div className="header-subtitle">
          {applyMarkdown(pageData?.header?.date)}
        </div>
        <div className="header-description">
          {applyMarkdown(pageData?.header?.description)}
        </div>
      </div>

      <div className="missions-section">
        <div className="missions-top-section">
          <div className="info-container">
            <div className="missions-title">
              {applyMarkdown(pageData?.missions?.title)}
            </div>
            <div className="missions-subtitle">
              {applyMarkdown(pageData?.missions?.description)}
            </div>
          </div>

          <div className="dropdown-section">
            <div className="dropdown-container">
              <div className="dropdown-label">
                {applyMarkdown(pageData?.missions?.dropDownOne?.title)}
              </div>
              <DropdownV2
                style="white"
                options={missionCategoryOptions}
                value={selectedMissionCategory}
                onValueChange={handleChangeMissionCategory}
              />
            </div>
            <div className="dropdown-container">
              <div className="dropdown-label">
                {applyMarkdown(pageData?.missions?.dropDownTwo?.title)}
              </div>
              <DropdownV2
                style="white"
                options={missionStatusOptions}
                value={selectedMissionStatus}
                onValueChange={handleChangeMissionStatus}
              />
            </div>
          </div>
        </div>

        <div className="missions-cards-section">
          <MissionList
            isLoadingMissions={isLoadingMissions}
            missionItems={filteredMissions}
            selectedMissionStatus={selectedMissionStatus}
            getBlurredBadgeText={getBlurredBadgeText}
            getColoredBadgeInfo={getColoredBadgeInfo}
            waitForMissionsLabels={waitForMissionsLabels}
            analyticsData={analyticsData}
          />
        </div>
      </div>

      <div className="dashboard-section">
        <div className="top-section">
          <div className="title">
            {applyMarkdown(pageData?.dashboard?.title)}
          </div>
          <div className="description">
            {applyMarkdown(pageData?.dashboard?.description)}
          </div>
          <div className="dashboard-wrapper">
            <div className="dashboard-grey-area">
              <div className="grid-dashboard-level-points">
                <DashboardInfoCard
                  currentLevel={nitroUserXPLevel?.nitroCurrentLevel ?? ''}
                  nextLevel={nitroUserXPLevel?.nitroNextLevel ?? ''}
                  pointsNeeded={nitroUserXPLevel?.nitroPointsNeeded ?? ''}
                  currentLevelPoints={nitroUserXPLevel?.nitroCurrentLevelPoints ?? ''}
                  userPoints={nitroUserXPLevel?.nitroUserPoints ?? ''}
                  moreInfoText={pageData?.dashboard?.moreInfoText ?? ''}
                  handleMoreInfoClick={navigateToHowDoPage}
                  analyticsData={analyticsData}
                />
              </div>
              <div className="dashboard-row-2">
                <div className="row-grouping-1">
                  <RecentActivityList
                    missions={missions}
                  />
                  <Leaderboard
                    type="partial"
                    pageData={pageData}
                    leaderboardConfig={leaderboardConfig}
                    items={leaderboardData}
                    isLoadingLeaderboard={isLoadingLeaderboard}
                    isLoadingLeaderboardDealerships={isLoadingLeaderboardDealerships}
                    leaderboardSelection={leaderboardSelection}
                    setLeaderboardSelection={setLeaderboardSelection}
                    analyticsData={analyticsData}
                  />
                </div>
                <div className="row-grouping-2">
                  <DashboardImageCard
                    desktopImageName={pageData?.dashboard?.engageHubDesktopImage}
                    tabletImageName={pageData?.dashboard?.engageHubTabletImage}
                    mobileImageName={pageData?.dashboard.engageHubMobileImage}
                    page="dashboard"
                    imageTitle={pageData?.dashboard?.imageTitle}
                    imageLargeText={pageData?.dashboard?.imageLargeText}
                    imageLinkText={pageData?.dashboard?.imageLinkText}
                    imageLink={pageData?.dashboard?.checkMyBalanceUrl}                  analyticsData={analyticsData}
                  />
                </div>
              </div>
              <div className="grid-dashboard-button">
                <button onClick={navigateToStatsPage}>View Full Stats</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EngageXPMissions;
