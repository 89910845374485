import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../../styles/css/glossaryLanding.css';
import AppSettings from '../../appsettings';
import getNavigation from '../../services/navigationService';
import getAnalytics from '../../services/analyticsService';
//import GlossaryCategory from './glossaryCategory';
import Glossary from './glossary';
import DynamicLanding from '../../components/pages/dynamicLanding';
import Display404NotFound from '../../components/atoms/404NotFound';
import { glossaryLandingPageLoaded } from './glossary.analytics';
import MobileBackButton from '../../components/molecules/MobileBackButton';

const GlossaryRouterComponent = (props) => {
  const [glossaryLandingPageData, setLandingPageData] = useState({});
  const [categories, setCategories] = useState(null);
  const [categoryNames, setCategoryNames] = useState(null);
  const [analyticsData, setAnalyticsData] = useState(null);

  const getGlossaryImagePath = () => AppSettings.AWSImgRepo.glossary;
  const params = useParams();
  const page = params && params.page;


  const getGlossaryData = () => {
    getNavigation('glossary')
      .then((data) => {
        setLandingPageData(data);
        const tiles = data.items.tileSections["Glossary"].tiles;
        // Links may be formatted '/glossary/hybrid' OR '/glossary/hybird/'
        const names = tiles.map(item => item.link.replace('glossary','').replaceAll('/',''));
        setCategories(tiles);
        setCategoryNames(names);
      })
      .catch((error) => {
        console.log(`ERROR: failed to load  landing page data. ${JSON.stringify(error.statusText)}`);
      });
  };

  useEffect(() => {
    getGlossaryData();
    getAnalyticsData();
  }, []);

  const getAnalyticsData = async () => {
    const glossaryAnalytics = await getAnalytics('glossary');
    setAnalyticsData(glossaryAnalytics);


    if(page === undefined){
      glossaryAnalytics && glossaryLandingPageLoaded(glossaryAnalytics?.pageLoad);
    }
  
  };

  const getGlossaryPage = () => {
    if (page) {
      if (page.includes('search')) {
        return <Glossary 
          {...props} 
          params={params} 
          categories={categories}
          analytics={analyticsData} 
        />
      }
      if (categoryNames) {
        if (categoryNames.includes(page.split('/')[0])) {
          return <Glossary 
            {...props} 
            params={params} 
            category={page} 
            categories={categories}
            analytics={analyticsData} 
            />;
        }
        else {
          return <Display404NotFound display />;
        }
      } else {
        // If the page data hasn't loaded yet, show a blank placeholder instead of the 404 component
        return <div style={{ height: "600px" }}></div>;
      }
    } else {
      return (
        <>
          <div style={{width: 100, backgroundColor: 'red'}} />
          {glossaryLandingPageData?.items &&
            <DynamicLanding
              clickAnalytics={analyticsData?.clickAnalytics ? analyticsData?.clickAnalytics : null}
              pageTitle={glossaryLandingPageData?.items?.title}
              description={glossaryLandingPageData?.items?.description}
              tileSections={glossaryLandingPageData?.items?.tileSections}
              sectionTitle={analyticsData?.pageLoad ? analyticsData?.pageLoad?.tileSection?.section: null}
              imagePath={getGlossaryImagePath()}
              smallTiles={true}
              pageStyle={glossaryLandingPageData?.items?.style}
            />
          }
        </>
      );
    }
  }

  return getGlossaryPage();
  
};

GlossaryRouterComponent.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default GlossaryRouterComponent;