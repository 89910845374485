/* global
    $
*/

import { Component } from 'react';
import {
  postEmail
} from '../../services/emailService.js';
import { getLocale } from '../../services/languageTranslationService.js';
import {
  getProfile, postUserData
} from '../../services/profileService.js';
import { isValidEmail } from '../utils/helpers.js';
import { EmailModalTemplate } from './emailModal.tpl';


export default class EmailModalComponent extends Component {
  constructor(props) {
    super(props);
    let subject = this.props.subject || '';
    if (this.props.subject) {
      const fName = localStorage.firstName ? localStorage.firstName : '';
      const lName = localStorage.lastName ? localStorage.lastName : '';
      if (fName && lName) {
        subject = `${subject} from ${fName} ${lName} at Toyota`;
      }
    }
    console.log(localStorage);
    this.state = {
      modalIsOpen: false || this.props.open,
      emailField: '',
      messageField: '',
      subjectField: subject,
      termsBox: false,
      articleId: '',
      isValid: false,
      replyToField: '',
      replyToEmailChanged: false,
      replyToEmailValid: true,
      updateDealerProfileEmail: false,
      userData: {}
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateEmailField = this.updateEmailField.bind(this);
    this.updateMessageField = this.updateMessageField.bind(this);
    this.updateCheckboxField = this.updateCheckboxField.bind(this);
    this.updateSubjectField = this.updateSubjectField.bind(this);
    this.sendEmailClick = this.sendEmailClick.bind(this);
    this.showSuccessMessage = this.showSuccessMessage.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.validateMessage = this.validateMessage.bind(this);
    this.validateCheckbox = this.validateCheckbox.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.showFailureMessage = this.showFailureMessage.bind(this);
    this.showSuccessMessage = this.showSuccessMessage.bind(this);
    this.showStatusMessage = this.showStatusMessage.bind(this);
  }

  componentDidMount() {
    getProfile().then((userProfile) => {
      this.setState({
        userData: userProfile,
        replyToField: userProfile.email
      })
    }).catch((error) => {
      console.log(error);
    });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.openModal(undefined);
    }
  }

  openModal(articleId) {
    this.setState({
      modalIsOpen: true,
      articleId,
    });
  }

  closeModal(timeout) {
    if (this.props.vinUrl) {
      this.props.closeModal();
    }
    window.setTimeout(() => {
      this.setState({
        modalIsOpen: false,
        emailField: '',
        messageField: '',
        subjectField: '',
        termsBox: false,
      });
    }, timeout);
  }

  sendEmail(email) {
    if (this.state.replyToField
      && this.state.replyToEmailChanged
      && this.state.replyToEmailValid
      && this.state.updateDealerProfileEmail) {
      this.updateUserProfileEmail()
    }
    return new Promise((resolve, reject) => {
      postEmail(email, getLocale())
        .then((payload) => {
          this.hideStatusMessage();
          this.showSuccessMessage();
          this.closeModal(2000);
          resolve(payload);
        }).catch((error) => {
          this.hideStatusMessage();
          this.showFailureMessage();
          reject(error);
        });
    });
  }

  sendEmailClick() {
    if (this.validateFields()) {
      const toFields = (this.state.emailField.replace(/ /g, '')).split(',');
      const message = this.state.messageField;
      const subject = this.state.subjectField;
      const emailObject = {
        toFields,
        message,
        subject,
        replyTo: this.state.replyToField,
        senderFirstName: localStorage.firstName ? localStorage.firstName : '',
        senderLastName: localStorage.lastName ? localStorage.lastName : '',
        articleId: this.state.articleId,
        vinUrl: this.props.vinUrl,
      };
      this.showStatusMessage();
      this.sendEmail(emailObject)
        .then((result) => {
          console.log('Email response hit');
        });
    }
  }

  updateUserProfileEmail = () => {
    const userData = {
      ...this.state.userData,
      email: this.state.replyToField
    };
    postUserData(userData);
  }

  updateEmailField(input) {
    this.setState({
      emailField: input,
    });
  }

  updateReplyToField = (input) => {
    const replyToEmailChanged = input !== localStorage.email;
    this.setState({
      replyToField: input,
      replyToEmailChanged
    }, this.validateReplyTo());
  }

  updateMessageField(input) {
    this.setState({
      messageField: input,
    });
  }

  updateCheckboxField(input) {
    this.validateCheckbox(input);
    this.setState({
      termsBox: input,
    });
  }

  updateDealerEmail = (input) => {
    this.setState({
      updateDealerProfileEmail: input,
    });
  }

  updateSubjectField(input) {
    this.setState({
      subjectField: input,
    });
  }

  showStatusMessage() {
    $('#statusMessageBox').fadeIn(400);
  }

  hideStatusMessage() {
    $('#statusMessageBox').fadeOut(400);
  }

  showSuccessMessage() {
    $('#successMessageBox').fadeIn(2000);
    $('#successMessageBox').fadeOut(2000);
  }

  showFailureMessage() {
    $('#failureMessageBox').fadeIn(2000);
    $('#failureMessageBox').fadeOut(2000);
  }


  validateFields() {
    let validationCount = 0;
    validationCount += this.validateEmail();
    validationCount += this.validateMessage();
    validationCount += this.validateCheckbox(this.state.termsBox);

    if (validationCount === 3) {
      this.setState({ isValid: true });
      return true;
    }
    this.setState({ isValid: false });
    return false;
  }

  validateEmail() {
    let emails = this.state.emailField;
    emails = emails.replace(/ /g, '');
    emails = emails.split(',');

    let emailCount = 0;
    emails.forEach((email) => {
      if (!isValidEmail(email)) {
        $('#emailError').show();
        return 0;
      }
      emailCount++;
    });
    if (emailCount === emails.length) {
      $('#emailError').hide();
      return 1;
    }
  }

  validateReplyTo = () => {
    if (!this.state.replyToField || isValidEmail(this.state.replyToField)) {
      this.setState({
        replyToEmailValid: true
      })
    } else {
      this.setState({
        replyToEmailValid: false
      })
    }
  }

  validateMessage() {
    if (this.state.messageField) {
      $('#messageError').hide();
      return 1;
    }
    $('#messageError').show();
    return 0;
  }

  validateCheckbox(isChecked) {
    if (isChecked) {
      $('#checkboxError').hide();
      return 1;
    }
    $('#checkboxError').show();
    return 0;
  }


  render() {
    return EmailModalTemplate(this);
  }
}
