// todo: update analytics so we can pass them in and reuse them throughout smartpath

import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();

const smartPathBaseObject = {
  tag: '35.1',
  section: 'Product',
  subsection: 'Featured Technology',
  technologyName: 'SmartPath',
};

export const pageLoadAnalytics = (page) => {
  analytics.firePageLoad({
    ...smartPathBaseObject,
    page,
  });
};

export function pageLoad(tabTitle) {
  analytics.firePageLoad({
    tag: '35.1',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'SmartPath',
    page: tabTitle,
  });
}

export function faqFilterClick(category, title) {
  analytics.fireOnClick({
    tag: '35.2',
    mediaTitle: ' ',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'SmartPath',
    page: 'FAQ',
    category,
    contentTitle: title,
  });
}

export function filterLinksClick(event, pageTitle) {
  analytics.fireOnClick({
    tag: '35.2',
    mediaTitle: ' ',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'SmartPath',
    page: pageTitle,
    category: 'Filter Click',
    contentTitle: event.target.innerText,
  });
}

export function linkClick(tabTitle, linkText) {
  analytics.fireOnClick({
    tag: '35.2',
    mediaTitle: ' ',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'SmartPath',
    page: tabTitle,
    category: 'Link Click',
    contentTitle: linkText,
  });
}

export function downloadFullManualClick(content, pageTitle) {
  analytics.fireOnClick({
    tag: '35.2',
    mediaTitle: ' ',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'SmartPath',
    page: pageTitle,
    category: 'Download Click',
    contentTitle: content,
  });
}

export function readMoreClick(pageTitle) {
  analytics.fireOnClick({
    tag: '35.2',
    mediaTitle: ' ',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'SmartPath',
    page: pageTitle,
    category: 'Read More Click',
    contentTitle: 'Show More',
  });
}

export const sendVideoEventAnalytics = (item) => {
  analytics.fireOnClick({
    tag: '40.5',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'SmartPath',
    page: 'Videos',
    category: item.category,
    contentTitle: item.contentTitle,
    mediaTitle: item.videoTitle.replace(/(<([^>]+)>)/g, ''),
    modelName: ' ',
    module: 'video player',
    label: item.videoTitle.replace(/(<([^>]+)>)/g, ''),
    events: item.event,
  });
};

export const videoAnalytics = {
  pageLoadAnalytics,
  sendVideoEventAnalytics,
};

export const overviewAnalytics = {
  pageLoadAnalytics,
};

export const featuresAnalytics = {
  pageLoadAnalytics,
};

const grabPageNameAnalyticsFromSubheaderClick = (pageName) => {
  switch (pageName) {
    case '"FREQUENTLY ASKED QUESTIONS"':
      return 'Frequently Asked Questions';
    case '"CONTACT US"':
      return 'Contact Us';
    case '"TESTIMONIALS"':
      return 'Testimonials';
    case '"TRAINING"':
      return 'Training';
    case '"FEATURES"':
      return 'Features';
    case '"OVERVIEW"':
    default:
      return 'Overview';
  }
};

export const handleSubheaderClick = (event) => {
  const newPageName = event.currentTarget.firstChild && event.currentTarget.firstChild.firstChild;
  const pageName = grabPageNameAnalyticsFromSubheaderClick(newPageName);

  const analyticsObject = {
    ...smartPathBaseObject,
    tag: '35.2',
    page: pageName,
    mediaTitle: pageName,
    category: pageName,
  };
  analytics.fireOnClick(analyticsObject);
};
