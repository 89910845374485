import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { GlobalAnalytics } from '../../analytics';
import RedLine from '../atoms/RedLine';
import applyMarkdown from '../../screens/utils/applyMarkdown';
import DisclaimersComponent from '../../screens/disclaimers/disclaimers.component';
import '../../styles/css/horizontal-media-text-tile.css';

const analytics = new GlobalAnalytics();

const HorizontalMediaTextTile = (props) => {
  const disclaimerRef = useRef();
  const readMoreLink = props.link || (props.fileName && encodeURI(`${props.internalFilePath}${props.fileName}`));
  const onReadMoreLinkClick = () => {
    analytics.fireOnClick({
      category: 'Tile Click',
      mediaTitle: props.title,
      page: props.headline, // should be a value like 'Overview,' 'Lineup,' or 'Resources'
      section: 'Product',
      subsection: 'Featured Technology', // all static safety/audio pages have a common section and subsection
      tag: '35.2',
      technologyName: props.parentPageName, // should be a value like 'Service Connect,' 'Hybrid,' or 'Certification'
    });
  };

  const summaryDescription = () => (
    <div className="horizontal-media-text-summary-description">
      <div className="horizontal-media-text-title">{props.title}</div>
      <RedLine className="horizontal-media-text-tile-redline" />
      <p>{applyMarkdown(props.description, disclaimerRef.current)}</p>
      <p>{props.date}</p>
      <a href={readMoreLink} target="_blank" onClick={() => onReadMoreLinkClick()}>
        <div className="red-button-link">{props.buttonText}</div>
      </a>
    </div>
  );

  const articleTile = () => (
    <React.Fragment>
      <div className="horizontal-media-text-space-between">
        <a className="horizontal image-link-overlay" href={readMoreLink} target="_blank" onClick={() => onReadMoreLinkClick()}>
          <img src={`${props.imagePath}${props.image}`} />
        </a>
        {summaryDescription()}
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <div className="horizontal-media-text-tile-row">
        {articleTile()}
      </div>
      <DisclaimersComponent template={props.disclaimerName} ref={(instance) => { disclaimerRef.current = instance; }} />
    </React.Fragment>
  );
};

HorizontalMediaTextTile.propTypes = {
  date: PropTypes.string,
  numberOfColumns: PropTypes.number,
  imagePath: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  disclaimerName: PropTypes.string,
  buttonText: PropTypes.string,
  title: PropTypes.string,
  internalFilePath: PropTypes.string.isRequired,
  fileName: PropTypes.string,
  link: PropTypes.string,
  parentPageName: PropTypes.string.isRequired,
};

export default HorizontalMediaTextTile;
