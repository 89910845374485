/* eslint-disable no-param-reassign */
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '../../styles/css/carousel.css';


export function CarouselTemplate(component) {
  try {
    // eslint-disable-next-line no-return-assign
    const sliderTest = component.props.children.length > 0 ? <Slider ref={c => (component.slider = c)} {...component.state.settings}>{component.props.children}</Slider> : null;
    return (sliderTest);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(`Error${e}`);
    return (e);
  }
}
