import React, { useEffect, useState } from 'react';
import FeaturedCarouselItem from './featuredCarouselItem';
import CarouselV2 from '../../../components/molecules/CarouselV2';
import '../../../styles/css/homepage/featuredCarousel.css'
import AppSettings from '../../../appsettings';
import { carouselArrowClicked } from '../homepage.analytics';

const FeaturedCarouselComponent = (props) => {
    const [displayedContent, setDisplayedContent] = useState([]);
    const [remainingContent, setRemainingContent]= useState([]);
    const featuredCarouselContent = props.featuredContent;

    useEffect(() => {
        if(featuredCarouselContent && props.device === 'mobile'){
            loadMoreContent(3);
            setRemainingContent(featuredCarouselContent.length);
        }
    }, [featuredCarouselContent, props.device]);

    const loadMoreContent = (numToDisplay) => {
        const numContentToDisplay = numToDisplay || displayedContent.length + 3;
        carouselArrowClicked(props.analytics, 'More');
        setDisplayedContent(featuredCarouselContent.slice(0, numContentToDisplay));
        setRemainingContent(featuredCarouselContent.length - displayedContent.length);
    }

    const loadLessContent = () => {
        carouselArrowClicked(props.analytics, 'Less');
        setDisplayedContent(featuredCarouselContent.slice(0, 3));
        setRemainingContent(featuredCarouselContent.length);
    }

    return (<>
        {props.device !== 'mobile' && featuredCarouselContent?.length > 0 &&
            <div className='featuredCarousel'>
                <CarouselV2
                    navigation
                    pagination
                    loop
                    slidesCount={1}
                    spaceBetween={16}
                    spotlight
                    prevArrow={<div className='circleArrow prev' onClick={() => carouselArrowClicked(props.analytics, 'Prev')}><img className='arrowIcon' src={AppSettings.AWSImgRepo.resources + 'en/left-arrow-white.png'} /></div>}
                    nextArrow={<div className='circleArrow next' onClick={() => carouselArrowClicked(props.analytics, 'Next')}><img className='arrowIcon' src={AppSettings.AWSImgRepo.resources + 'en/right-arrow-white.png'} /></div>}
                    items={featuredCarouselContent?.map(item =>
                        <FeaturedCarouselItem
                            key={item.title}
                            image={item.image}
                            title={item.title}
                            description={item.description}
                            link={item.link}
                            analytics={props.analytics}
                        />
                    )}
                />
            </div>
        }

        {props.device === 'mobile' && displayedContent.length > 0 && <>
            <div className='featuredItemsMobile' >
                {displayedContent.map(item =>
                    <FeaturedCarouselItem
                        key={item.title}
                        image={item.image}
                        title={item.title}
                        description={item.description}
                        link={item.link}
                        analytics={props.analytics}
                    />
                )}
            </div>
            {featuredCarouselContent.length <= 3 ? <></> : remainingContent > 3 ?
                <div
                    className='featuredItemsMobileButton'
                    onClick={() => loadMoreContent()}> MORE 
                </div> : <div
                    className='featuredItemsMobileButton'
                    onClick={() => loadLessContent()}> LESS
                </div> 
            }
        </>
        }
    </>);
}

export default FeaturedCarouselComponent;