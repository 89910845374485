import { GlobalAnalytics } from '../../../analytics';

const analytics = new GlobalAnalytics();

export function clickEvent(category, title) {
  analytics.fireOnClick({
    tag: '35.2',
    mediaTitle: ' ',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'Audio Multimedia',
    page: 'FAQ',
    category,
    contentTitle: title,
  });
}

export function pageLoad() {
  analytics.firePageLoad({
    tag: '35.1',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'Audio Multimedia',
    page: 'FAQ',
  });
}
