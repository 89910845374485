import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useParams, useHistory } from 'react-router';
import { DynamicColumnItemList } from '../../components/molecules/dynamic-column-item-list';
import SubheaderComponent from '../subheader/subheader.component';
import Display404NotFound from '../../components/atoms/404NotFound';
import { ServiceConnectResources } from './components/service-connect-resources';
import { overviewAnalytics } from './service-connect-analytics';
import AppSettings from '../../appsettings';
import getMisc from '../../services/miscService';

const ServiceConnectRouterComponent = (props) => {
  const [pageData, setPageData] = useState(null);
  const history = useHistory();

  useEffect(() => {
    getMisc('ServiceConnect', 'en')
      .then((data) => {
        setPageData(data);
      })
      .catch((error) => {
        console.log(`ERROR: failed to load Service Connect page data. ${JSON.stringify(error.statusText)}`);
      });
  }, []);

  const overviewPageProps = {
    disclaimerName: 'ServiceConnect',
    internalFilePath: AppSettings.AWSImgRepo.serviceConnect,
    imagePath: AppSettings.AWSImgRepo.serviceConnect,
    parentPageName: 'Service Connect',
  };

  const getSubheaderChildComponent = (path) => {
    const page = path ? path.toLowerCase() : '';
    switch (page) {
      case 'overview':
        return pageData.items && <DynamicColumnItemList
          {...pageData.items.overview}
          {...overviewPageProps}
          printHeadline="overviewpage.printHeadline.serviceConnect"
          pageLoadAnalytics={overviewAnalytics.pageLoadAnalytics} />;
      case 'resources':
        return <ServiceConnectResources />;
      default:
        history.push('/serviceConnect/overview');
        return null;
    }
  };

  const params = useParams();
  const pageName = params && params.page;
  const route = {
    engine: '',
    featSpec: undefined,
    model: '',
    path: pageName || 'overview',
    trim: '',
    year: params.year,
  };
  const isValidTabName = () => ['overview', 'resources'].filter(x => x === pageName).length > 0;
  const getPageDisplay = () => {
    if (isValidTabName()) {
      return (
        <div>
        {pageData
          && <SubheaderComponent {...props} route={route} params={params}>
            {getSubheaderChildComponent(pageName)}
          </SubheaderComponent>
        }
      </div>
      );
    }
    return <Display404NotFound display />;
  };
  return (
    <div>{getPageDisplay()}</div>
  );
};

export default injectIntl(ServiceConnectRouterComponent);
