import "../../../styles/scss/vehicleDetails/disclaimerpopup.scss";
const DisclaimerPopup = ({
    isVisible,
    onClose,
    disclaimerText
}) => {

    return (
        <div className={`disclaimer-overlay ${isVisible ? 'show' : ''}`} onClick={onClose}>
            <div className="disclaimer-content" onClick={e => e.stopPropagation()}>
                <div className="disclaimer-text">{disclaimerText}</div>
                <button className="disclaimer-exit" onClick={onClose}><div className="disclaimer-exit-name">×</div></button>
            </div>
        </div>
    )
}

export default DisclaimerPopup;
