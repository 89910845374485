import React from 'react';
import RedLine from '../../atoms/RedLine';
import applyMarkdown from '../../../screens/utils/applyMarkdown';
import '../../../styles/css/marketingPage/sectionHeader.css'

function SectionHeader(props) {
    const headerStyle = {};
    const headerClasses = ['section-header', props.device];

    return (
        <>
            <div className={headerClasses.join(' ')} style={{ ...props.style, ...headerStyle }}>
                {props.backgroundImage && <img src={props.imagePath + props.backgroundImage[props.device]} />}
                <div className={props.backgroundImage && 'background-image-content'}>
                    <div className={`section-title ${props.device}`}>
                        {applyMarkdown(props.title.toUpperCase())}
                    </div>
                    {props.redline && <RedLine className={`redline ${props.device}`} />}
                    <div className={`description ${props.device}`}>
                        {applyMarkdown(props.description)}
                        {props.tagline &&
                            <div className={`tagline ${props.device}`}>{props.tagline}</div>
                        }
                    </div>
                </div>
            </div>
            {props.nonBackgroundImage &&
                <img className={`marketing-hero-image ${props.device}`} src={props.imagePath + props.nonBackgroundImage} />
            }
        </>
    );
}

export default SectionHeader;