import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import appsettings from '../../appsettings';
import applyMarkdown from '../../screens/utils/applyMarkdown';
import '../../styles/css/dynamic-column-item-list-print.css';

export const DynamicColumnItemListPrint = (props) => {
  const generateColumn = colNum => <div className="columns small-6">
    {props.sections.map((section, index) => {
      if (index % 2 === colNum) {
        return <div key={section.title} className="section">
          <div className="section-title">
            {applyMarkdown(section.title)}
          </div>
          <div className="section-red-line" />
          <div className="section-description">
            {applyMarkdown(section.description)}
          </div>
        </div>;
      }
    })}
  </div>;

  return <div className="dynamic-column-item-list-print">
    <div className="print-header-section">
      <div className="print-title">
        {props.printHeadline && <FormattedMessage id={props.printHeadline} />}
      </div>
      <div className="print-icon-section">
        {props.imagePath && props.printHeaderIcon
          && <img className="print-header-icon" src={`${props.imagePath}${props.printHeaderIcon}`} />
        }
        <img className="print-engage-icon" src={`${appsettings.AWSImgRepo.logo}en/engage_logo.png`} />
      </div>
      <div className="print-description">
        {applyMarkdown(props.description)}
      </div>
      <div className="divider-line" />
      <div className="sections container">
        {generateColumn(0)}
        {generateColumn(1)}
      </div>
    </div>
  </div>;
};

DynamicColumnItemListPrint.propTypes = {
  printHeadline: PropTypes.string,
  imagePath: PropTypes.string,
  printHeaderIcon: PropTypes.string,
  description: PropTypes.string,
  sections: PropTypes.array,
};
