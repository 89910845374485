import 'whatwg-fetch';
import httpOption from './httpOption';
import AppSettings from '../appsettings';

export default function getDealership(dealerCode) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/dealerships/${dealerCode}`;
  const options = httpOption.GET();

  return fetch(url, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(response);
    });
}