import { GlobalAnalytics } from "../../analytics";

const analytics = new GlobalAnalytics();

export function incentivesButtonClick(event) {
  analytics.fireOnClick({
    tag: "29.2",
    section: "Product",
    page: event?.modelName,
    category: "Overview",
    label: "Local Incentives",
    modelName: event?.modelName,
    modelYear: event?.modelYear,
    positionTitle: "Banner",
  });
}

export function pageLoad(event) {
  analytics.firePageLoad({
    tag: "29.1",
    section: "Product",
    modelName: event?.modelName,
    page: "Overview",
    modelYear: event?.modelYear,
  });
}
