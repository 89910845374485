import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import applyMarkdown from '../../../utils/applyMarkdown';
import MarkdownIt from 'markdown-it';
import MarkdownItSup from 'markdown-it-sup';
import dompurify from 'dompurify';


export function ThirdPartyReviews(props) {
    const md = new MarkdownIt().use(MarkdownItSup);

    const thirdPartyReviews = props.currentEdge.edgeContent.thirdPartyReviews.map((thirdPartyReview, index) => (
        <div key={index} className="tpr-quote columns large-4 medium-4 small-4">
            <div className="quote">
                <span className="quote-symbol-lrg">&ldquo;</span><div className="quote-desc">{applyMarkdown(thirdPartyReview.description, props.disclaimer)}<span className="quote-symbol">&rdquo;</span></div>
            </div>
            <div className="tpr-source-block">
                -&nbsp;<a target="_blank" className="tpr-source" href={thirdPartyReview.link}>{thirdPartyReview.source}</a>
            </div>
        </div>
    ));

    const settings = {
        arrows: true,
        autoplay: true,
        autoplaySpeed: 5000,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '30',
        infinite: true,
    };

    const carouselContents = props.currentEdge.edgeContent.thirdPartyReviews.map((thirdPartyReview, index) => (
        <div key={index}>
            <div className="quote">
                <span className="quote-symbol-lrg">&ldquo;</span>
                <div className="quote-desc">
                    <span dangerouslySetInnerHTML={{ __html: md.renderInline(dompurify.sanitize(thirdPartyReview.description)) }} />
                    <span className="quote-symbol">&rdquo;</span>
                </div>
            </div>
            <div className="tpr-source-block">
                -&nbsp;<a target="_blank" className="tpr-source" href={thirdPartyReview.link}>{thirdPartyReview.source}</a>
            </div>
        </div>
    ));

    return (
        <div className="tpr-body">
            {thirdPartyReviews}
            <Slider className="tpr-carousel" {...settings}>
                {carouselContents}
            </Slider>
        </div>

    );
}