import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import AppSettings from '../../appsettings';
import DisclaimersComponent from '../disclaimers/disclaimers.component';
import applyMarkdown from '../utils/applyMarkdown';
import { capFirst } from '../utils/capitalize';
import { getDisplayFormattedModelNameFromUrl, getDynamoName } from '../utils/vehicleName';
import AwardsSection from './modelOverviewSections/awardsSection';
import FeaturedContentSection from './modelOverviewSections/featuredContentSection';
import FeaturedIconsSection from './modelOverviewSections/featuredIconsSection';
import PowertrainComparisonSection from './modelOverviewSections/powertrainComparisonSection';
import QuickFactsSection from './modelOverviewSections/quickFactsSection';
import TitleSection from './modelOverviewSections/titleSection';
import Display404NotFound from '../../components/atoms/404NotFound';

export function ModelOverviewTemplate(component) {
  const modelName = component.props.params.model && component.props.params.model.toString().toLowerCase();
  const formattedModelName = modelName && getDisplayFormattedModelNameFromUrl(component.props.params.model.toString().toLowerCase());
  const s3ModelName = capFirst(formattedModelName.toLowerCase());
  const imagePath = `${AppSettings.AWSImgRepo.vehicle}${component.props.params.year}/${s3ModelName}/en/`;
  const disclaimerRequestParam = `${component.props.params.year}_${getDynamoName(modelName)}_overview`;
  const getCustomStylesForSection = sectionId => (component.state.overviewData && component.state.overviewData.styles ? component.state.overviewData.styles.find(x => x.sectionId === sectionId) : null);

  function titleSection() {
    return (
      <React.Fragment>
        {component.state.overviewData && component.state.overviewData.titleSection
          ? <React.Fragment>
            <TitleSection
              disclaimer={component.disclaimer}
              modelName={`${component.props.params.year} ${formattedModelName} Overview `}
              styles={getCustomStylesForSection('Title Section')}
              imagePath={imagePath}
              titleSection={component.state.overviewData.titleSection} />
          </React.Fragment>
          : null}
      </React.Fragment>
    );
  }
  const awards = () => (
    <React.Fragment>
      {component.state.overviewData && component.state.overviewData.awards
        ? <AwardsSection
          id='Awards'
          awards={component.state.overviewData.awards}
          styles={getCustomStylesForSection('Awards')}
          imagePath={imagePath}
          disclaimer={component.disclaimer} />
        : null}
    </React.Fragment>
  );

  const printViewTopFive = headerData => (
    {
      title: headerData.subheadline,
      text: headerData.description,
    }
  );

  const printViewAwards = (awardItems, ref) => (
    awardItems.map(item => (<li>{applyMarkdown(item.subheadline.toString(), ref)}</li>))
  );

  const quickFactsSection = () => (
    <React.Fragment>
      {component.state.overviewData && component.state.overviewData.quickFacts
        ? <QuickFactsSection
          quickFacts={component.state.overviewData.quickFacts}
          styles={getCustomStylesForSection('Quick Facts')}
          disclaimer={component.disclaimer}
          headerData={printViewTopFive(component.state.overviewData.titleSection)}
        >
          {component.state.overviewData.awards
            ? printViewAwards(component.state.overviewData.awards, component.disclaimer)
            : <div style={{ fontSize: '16px', textAlign: 'center' }}>Awards coming soon!</div>}
        </QuickFactsSection>
        : null}
    </React.Fragment>
  );

  const featuredContentSection = () => (
    <React.Fragment>
      {component.state.overviewData && component.state.overviewData.featuredContent
        ? <FeaturedContentSection
          featuredContent={component.state.overviewData.featuredContent}
          styles={getCustomStylesForSection('Featured Content')}
          imagePath={imagePath}
          disclaimer={component.disclaimer} />
        : null}
    </React.Fragment>
  );

  const featuredIconsSection = () => (
    <React.Fragment>
      {component.state.overviewData && component.state.overviewData.featuredIcons
        ? <FeaturedIconsSection
          featuredIcons={component.state.overviewData.featuredIcons}
          styles={getCustomStylesForSection('Featured Icons')}
          imagePath={imagePath}
          disclaimer={component.disclaimer} />
        : null}
    </React.Fragment>
  );

  const powertrainComparisonSection = () => (
    <React.Fragment>
      {component.state.overviewData && component.state.overviewData.powertrainComparison
        ? <PowertrainComparisonSection
          powertrainComparisonData={component.state.overviewData.powertrainComparison}
          styles={getCustomStylesForSection('Powertrain Comparison')}
          imagePath={imagePath}
          disclaimer={component.disclaimer} />
        : null}
    </React.Fragment>
  );

  const orderedSections = () => {
    const sections = [
      { component: titleSection(), styles: getCustomStylesForSection('Title Section') },
      { component: awards(), styles: getCustomStylesForSection('Awards') },
      { component: quickFactsSection(), styles: getCustomStylesForSection('Quick Facts') },
      { component: featuredContentSection(), styles: getCustomStylesForSection('Featured Content') },
      { component: featuredIconsSection(), styles: getCustomStylesForSection('Featured Icons') },
      { component: powertrainComparisonSection(), styles: getCustomStylesForSection('Powertrain Comparison') },
    ];

    const orderedSectionArray = component.state.overviewData ? sections.sort((a, b) => (a.styles?.sectionOrder - b.styles?.sectionOrder)) : null;
    return orderedSectionArray;
  };

  const orderedAndMappedSections = () => ((orderedSections() && orderedSections()[0].styles) ? orderedSections().map((section, index) => <React.Fragment key={index}>{section.component}</React.Fragment>) : null);

  return (
    <div>
      {component.state.overviewData && component.disclaimer
        ? (
          <div className="model-overview-page">
            {orderedAndMappedSections() || <Display404NotFound display />}
          </div>
        ) : (
          <>
            <div style={{
              marginLeft: -50
            }}>
              <SkeletonTheme inline height={455} width={window.innerWidth + 100}>
                <Skeleton />
              </SkeletonTheme>
            </div>
            <SkeletonTheme height={455}>
              <Skeleton />
            </SkeletonTheme>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
              <div style={{ paddingLeft: (window.innerWidth / 2) - 100 }}>
                <SkeletonTheme width={200} height={80}>
                  <Skeleton />
                </SkeletonTheme>
              </div>
            </div>
            <SkeletonTheme inline width={(window.innerWidth / 3) - 30} height={455}>
              <Skeleton wrapper={PictureWrapper} />
              <Skeleton wrapper={PictureWrapper} />
              <Skeleton wrapper={PictureWrapper} />
            </SkeletonTheme>
          </>
        )}
      <DisclaimersComponent template={disclaimerRequestParam} ref={(instance) => { component.disclaimer = instance; }} addStartingMSRPDisclaimer={true}/>
    </div>
  );
}

const PictureWrapper = ({ children }) => (
  <span style={{ paddingLeft: 10 }}>
    {children}
  </span>
)
