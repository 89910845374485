import React, { useState, useMemo, useRef, useEffect } from "react";
import DropdownV2 from "../../../components/molecules/DropdownV2";
import { isMobileView } from '../../utils/getScreenSize';
import "../../../styles/css/beyondZero/bzTabs.css";

const BZTabs = ({ active, variant = 'dark', items = [], onClick, hasImages = false, primaryColor = '#000', gap = '0px', showDropDownOnMobile = true, hasScroll = false }) => {
    const containerRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [clickDiff, setClickDiff] = useState(0);
    const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);

    const formatDropdownLabel = (item) => {
        return <div>
            {item?.image && <img src={item.image} style={{ width: 20, marginLeft: 0, marginRight: 10 }} />}
            <span>{item?.label}</span>
        </div>
    };

    const selectedOption = useMemo(() => {
        return items?.find(item => item?.value === active);
    }, [active, items]);

    const dropdownSelectedOption = useMemo(() => {
        return { ...selectedOption, label: formatDropdownLabel(selectedOption) }
    }, [selectedOption])

    useEffect(() => {
        const container = containerRef.current;
        if (!hasScroll || !container) return;

        const handleWheel = (event) => {
            if (event.deltaY !== 0) {
                event.preventDefault();
                container.scrollLeft += event.deltaY;
            }
        };

        container.addEventListener('wheel', handleWheel);

        return () => {
            container.removeEventListener('wheel', handleWheel);
        };
    }, [hasScroll]);

    useEffect(() => {
        const container = containerRef.current;
        if (!hasScroll || !container) return;

        const handleMouseDown = (event) => {
            setIsDragging(true);
            container.classList.add('dragging');
            setStartX(event.pageX - container.offsetLeft);
            setScrollLeft(container.scrollLeft);
        };

        const handleMouseLeave = () => {
            setIsDragging(false);
            container.classList.remove('dragging');
        };

        const handleMouseUp = (event) => {
            setIsDragging(false);
            container.classList.remove('dragging');
            setClickDiff(Math.abs(event.pageX - (startX + container.offsetLeft)));
        };

        const handleMouseMove = (event) => {
            if (!isDragging) return;

            event.preventDefault();
            const x = event.pageX - container.offsetLeft;
            const walk = (x - startX);
            container.scrollLeft = scrollLeft - walk;
        };

        const handleScroll = () => {
            const maxScrollLeft = container.scrollWidth - container.clientWidth;
            if (container.scrollLeft >= maxScrollLeft - 1) {
                setIsScrolledToEnd(true);
            } else {
                setIsScrolledToEnd(false);
            }
        };

        container.addEventListener('mousedown', handleMouseDown);
        container.addEventListener('mouseleave', handleMouseLeave);
        container.addEventListener('mouseup', handleMouseUp);
        container.addEventListener('mousemove', handleMouseMove);
        container.addEventListener('scroll', handleScroll);

        handleScroll();

        return () => {
            container.removeEventListener('mousedown', handleMouseDown);
            container.removeEventListener('mouseleave', handleMouseLeave);
            container.removeEventListener('mouseup', handleMouseUp);
            container.removeEventListener('mousemove', handleMouseMove);
            container.removeEventListener('scroll', handleScroll);
        };
    }, [isDragging, startX, scrollLeft, hasScroll]);

    const handleClick = (value) => {
        if (clickDiff < 5) { // Detect a click versus a drag
            onClick(value);
        }
    };

    return (
        <div className={`beyond-zero-tabs-container variant-${variant}`}>
            {!isMobileView(window.innerWidth) || !showDropDownOnMobile ?
                <>
                    <div
                        ref={containerRef}b
                        style={{ gap: gap }}
                        className={`beyond-zero-tabs-section ${hasScroll && !isScrolledToEnd ? 'fade-out' : ''}`}
                    >
                        {items?.map((item, index) =>
                            <div className={`tab-container ${active === item.value ? 'active' : ''}`} key={index}>
                                <div className="bz-tab" onClick={() => handleClick(item.value)}>
                                    {item?.image && <img src={item.image} alt="logo"/>}
                                    <span style={{color: primaryColor}}>{item?.label}</span>
                                </div>
                                {active === item.value && <div className="selection-indicator" style={{backgroundColor: primaryColor}}/>}
                                {active === item.value && selectedOption?.description &&
                                    <div className="tab-description">
                                        {selectedOption.description}
                                    </div>
                                }
                            </div>
                        )}
                    </div>
                    <div className="constant-line" style={{top: hasImages ? '110px' : '50px'}}/>
                </>

                :

                <div className="mobile-variant">
                    <DropdownV2
                        style={variant === 'light' ? 'white-translucent' : 'black'}
                        options={items}
                        value={dropdownSelectedOption}
                        onValueChange={(item) => onClick(item?.value)}
                    />
                    {selectedOption?.description && <div className="tab-description">{selectedOption.description}</div>}
                </div>
            }
        </div>
    );
};

export default BZTabs;
