import React, { useState, useEffect } from 'react';
import TabFilterCard from '../../components/molecules/cards/tabFilterCard';
import ChipGroup from '../../components/molecules/ChipGroup';
import RedLine from '../../components/atoms/RedLine.js';
import FeatureLookupSearchPane from './featureLookupSearchPane';
import FeatureList from './featureLookupFeatureList';
import NoResults from './featureLookupNoResults';
import SearchFeedback from '../../components/molecules/search-feedback';
import applyCustomMarkdown from '../utils/applyCustomMarkdown';
import AppSettings from '../../appsettings.js';
import ScrollButton from '../../components/molecules/scrollButton.js';
import '../../styles/css/featureLookupOverview.css';
import RoundedButton from '../../components/molecules/roundedButton';
import { cloneObject } from '../utils/helpers'
import {
    featureLookupOverviewPageLoad, featureLookupOverviewNoResultsPageLoad, featureLookupOverviewFilterClick, featureLookupOverviewAddFeatureClick, featureLookupRemoveFeatureClick, featureLookupOverviewGoLick
} from './featureLookup.analytics';
import MobileBackButton from '../../components/molecules/MobileBackButton';

const FeatureLookup = (props) => {
    const [filteredFeatures, setFilteredFeatures] = useState([]);
    const [tabSelected, setTabSelected] = useState({title: 'All'});
    const [subcategories, setSubcategories] = useState([]);
    const [subcategorySelected, setSubcategorySelected] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [noResultsMessage, setNoResultsMessage] = useState('');
    const [showSuggestedFeatures, setShowSuggestedFeatures] = useState(true);

    useEffect(() => {
        if (props.device != "mobile") {
            document.getElementsByClassName("off-canvas-wrapper")[0].style.overflow = "visible";
        }
    }, [])

    useEffect(() => {
        return () => {
            if (props.device != "mobile") {
                document.getElementsByClassName("off-canvas-wrapper")[0].style.overflow = null;
            }
        };
    }, []);

    useEffect(() => {
        const keyword = [searchText, subcategorySelected, ...props.selectedFeatures.map(f => f.featureName)].filter(i => i && i.length).join(', ');
        props.setAnalyticsKeyword(keyword);
        props.analyticsData && featureLookupOverviewPageLoad(props.analyticsData.pageLoadAnalytics, keyword);
    }, [props.analyticsData]);

    useEffect(() => {
        setTitle(props.overviewPageData?.title);
        setDescription(props.overviewPageData?.description);
        setNoResultsMessage(props.overviewPageData?.noResultsMessage);
    }, [props.overviewPageData]);

    useEffect(() => {
        if (props.features) {
            const keyword = [searchText, subcategorySelected, ...props.selectedFeatures.map(f => f.featureName)].filter(i => i && i.length).join(', ');
            props.setAnalyticsKeyword(keyword);

            const filtered = filterFeatures();
            setFilteredFeatures(filtered);

            if (filtered.length == 0 && props.analyticsData) {
                featureLookupOverviewNoResultsPageLoad(props.analyticsData.pageLoadAnalytics, keyword);
            }
        }
    }, [props.features, tabSelected, subcategorySelected, searchText, props.selectedFeatures]);

    useEffect(() => {
        updateSubcategories();
    }, [props.features]);

    useEffect(() => {
        let showSuggested = false;
        if (props.featuresLoaded) {
            if (props.features?.length > 0) {
                if (props.selectedFeatures.length == 0 && !searchText) {
                    showSuggested = true;
                }
            }
        } else {
            if (props.selectedFeatures.length == 0 && !searchText) {
                showSuggested = true;
            }
        }
        setShowSuggestedFeatures(showSuggested);
    }, [props.selectedFeatures, props.featuresLoaded, searchText])

    function updateSubcategories() {
        const subcategoriesList = [];

        props.features?.forEach(feature => {
            const subcategory = { featureType: feature.featureType, category: feature.category }
            if (!subcategoriesList.some(subcat => (subcat.featureType === feature.featureType && subcat.category === feature.category))) {
                subcategoriesList.push(subcategory);
            }
        });

        setSubcategories(subcategoriesList);
    }

    function filterFeatures() {
        // Start with all features
        let filtered = [...props.features];
        // Filter by tab
        if (tabSelected && tabSelected.title !== 'All') {
            filtered = filtered.filter(item => item.featureType === tabSelected.title)
        }
        // Filter by subcategory
        if (subcategorySelected) {
            filtered = filtered.filter(item => item.category === subcategorySelected)
        }
        // Filter by search text (check feature name)
        if (searchText) {
            filtered = filtered.filter(item => (item.featureName.toLowerCase().includes(searchText.trim().toLowerCase())));
        }
        // Remove duplicates
        const filteredNoDuplicates = []
        for (let i = 0; i < filtered.length; i++) {
            if (filteredNoDuplicates.findIndex(item => item.featureName === filtered[i].featureName) === -1) {
                filteredNoDuplicates.push(filtered[i]);
            }
        }
        filtered = filteredNoDuplicates;

        // Filter by compatibility
        if (props.selectedFeatures.length > 0 && props.compatibleModels) {
            filtered = props.compatibleModels?.length > 0 ? filtered.filter(f =>
                f.modelYears.some(y => props.compatibleModels?.find(c => c.model == y.model && c.year == y.year))
            ) : [];
        }

        // Sort by number of models
        filtered = filtered.sort((a, b) => (countCompatibleModels(b.modelYears) - countCompatibleModels(a.modelYears)));

        return filtered;
    }

    function countCompatibleModels(modelYears) {
        if (props.compatibleModels?.length > 0) {
            // If any features have been selected, compare props.compatibleModels to modelYears
            let modelArray = [];
            for (let i = 0; i < modelYears.length; i++) {
                for (let j = 0; j < props.compatibleModels.length; j++) {
                    if (modelYears[i].year == props.compatibleModels[j].year &&
                        modelYears[i].model == props.compatibleModels[j].model &&
                        modelArray.filter(e => e.model === modelYears[i].model).length === 0) {
                        modelArray.push(modelYears[i]);
                    }
                }
            }
            return modelArray.length;
        } else {
            // If no feature have been selected, just count the unique models in modelYears (ignoring years)
            return new Set(modelYears.map(item => item.model)).size;
        }
    }

    function addModelYears(suggestedFeatures) {
        for (let a = 0; a < suggestedFeatures.all.length; a++) {
            let match = props.features.find(feature => feature.featureName == suggestedFeatures.all[a].featureName)
            if (match) suggestedFeatures.all[a].modelYears = match.modelYears;
        }
        for (let e = 0; e < suggestedFeatures.exterior.length; e++) {
            let match = props.features.find(feature => feature.featureName == suggestedFeatures.exterior[e].featureName)
            if (match) suggestedFeatures.exterior[e].modelYears = match.modelYears;
        }
        for (let i = 0; i < suggestedFeatures.interior.length; i++) {
            let match = props.features.find(feature => feature.featureName == suggestedFeatures.interior[i].featureName)
            if (match) suggestedFeatures.interior[i].modelYears = match.modelYears;
        }
        for (let s = 0; s < suggestedFeatures.safetyconvenience.length; s++) {
            let match = props.features.find(feature => feature.featureName == suggestedFeatures.safetyconvenience[s].featureName)
            if (match) suggestedFeatures.safetyconvenience[s].modelYears = match.modelYears;
        }
        return suggestedFeatures;
    }

    function selectTab(tab) {
        setShowSuggestedFeatures(true);
        setTabSelected(tab);
        setSubcategorySelected(null);
    }

    const selectSubcategory = (subcategory) => {
        setSubcategorySelected(subcategory);
        subcategory != null && setShowSuggestedFeatures(false);
    }
    const isSelected = (feature) => props.selectedFeatures.some(i => i.featureName == feature);
    const indexOfFeature = (feature) => props.selectedFeatures.findIndex(i => i.featureName == feature);
    const goClick = () => {
        featureLookupOverviewGoLick(props.analyticsData.clickAnalytics, tabSelected.title, props.selectedFeatures.map(f => f.featureName).join(", "));
        props.setPage('Results')
    };

    const headerText = () => {
        const total = <>
            <span className='bold'>{filteredFeatures.length}</span>
        </>;
        const features = <>
            {props.selectedFeatures.length > 0 ? ' compatible' : ''}
            {filteredFeatures.length === 1 ? ' feature' : ' features'}
        </>;
        let inScope = <></>;
        if (subcategorySelected) {
            inScope = <> in <span className='bold'>{subcategorySelected}</span></>;
        } else if (tabSelected && tabSelected.title !== "All") {
            inScope = <> in <span className='bold'>{tabSelected.title}</span></>;
        }
        const matchingSearch = searchText ? <> matching "<span className='bold'>{searchText}</span>"</> : <></>;

        if (showSuggestedFeatures) {
            return <span className='reg'>{"Suggested Features"}</span>
        } else if (props.featuresLoaded) {
            return <span className='reg'>Showing {total}{features}{inScope}{matchingSearch}.</span>
        } else {
            return <span className='reg'>Loading features...</span>
        }
    }

    const listFeatures = () => {
        if (showSuggestedFeatures) {
            if (props.overviewPageData?.suggestedFeatures) {
                if (props.featuresLoaded) {
                    const featureNames = props.features.map(feat => feat.featureName);
                    let suggestedFeaturesFiltered = cloneObject(props.overviewPageData.suggestedFeatures);
                    suggestedFeaturesFiltered = addModelYears(suggestedFeaturesFiltered);
                    return suggestedFeaturesFiltered[tabSelected.title.toLowerCase().replace("\/", "")] = suggestedFeaturesFiltered[tabSelected.title.toLowerCase().replace("\/", "")].filter(features => featureNames.includes(features.featureName));
                }
                return props.overviewPageData?.suggestedFeatures[tabSelected.title.toLowerCase().replace("\/", "")]
            } else {
                return []
            }
        } else {
            return filteredFeatures
        }
    }

    return (
        <div className='featureLookupContainer'>
            {window.ReactNativeWebView && (
                <MobileBackButton containerStyle={{
                    position: 'absolute',
                    height: '28px',
                    left: "39px",
                    top: "53px"
                }} />
            )}
            <div className='title'>{title}</div>
            <RedLine />
            <div className='description'>{applyCustomMarkdown(description, 'bold')}</div>
            <div className={props.device != "mobile" && "stickySection"}>
                <div className="cardChipsSection">
                    <TabFilterCard
                        tabs={[{title: "All"}, {title: "Exterior"}, {title: "Interior"}, {title: "Safety/Convenience"}]}
                        setTabSelected={selectTab}
                        tabSelected={tabSelected}
                        device={props.device}
                        selectTabAnalytics={(tabSelected) => featureLookupOverviewFilterClick(props.analyticsData.clickAnalytics, tabSelected)}
                    >
                        <FeatureLookupSearchPane
                            subcategories={subcategories}
                            tabSelected={tabSelected.title}
                            subcategorySelected={subcategorySelected}
                            setSubcategorySelected={selectSubcategory}
                            setSearchText={setSearchText}
                            setShowSuggestedFeatures={setShowSuggestedFeatures}
                            searchText={searchText}
                            analyticsData={props.analyticsData}
                        />
                    </TabFilterCard>
                    <div className="chipsGoRow">
                        <ChipGroup
                            activeChips={props.selectedFeatures.map(f => f.featureName)}
                            deleteChip={props.deleteSelectedFeature}
                            totalPlaceholderChips={props.maxSelections}
                            placeholderText={"Add Feature"}
                            groupClassName={"featureLookupOverview-chipGroup"}
                            deleteChipAnalyticsClick={feature => featureLookupRemoveFeatureClick(props.analyticsData.clickAnalytics, props.analyticsData.clickAnalytics.overview.category, feature, tabSelected)}
                        />
                        <RoundedButton title={"GO"} className="featureLookup-goButton" colorStyle="red" disabled={props.selectedFeatures?.length === 0 || !props.featuresLoaded} onClick={() => goClick()} />
                    </div>
                </div>
                {props.device != "mobile" && <div className="fadeGradient" />}
            </div>
            {!showSuggestedFeatures && props.featuresLoaded && filteredFeatures.length == 0 ?
                <>
                    <NoResults
                        headerText={headerText()}
                        message={noResultsMessage}
                        tab={tabSelected.title}
                        category={subcategorySelected}
                    />
                    <SearchFeedback
                        location={'Feature Lookup'}
                        filter={tabSelected.title}
                        category={subcategorySelected}
                        searchParam={searchText}
                    />
                </>
                : <FeatureList
                    headerText={headerText()}
                    listFeatures={listFeatures()}
                    tab={tabSelected}
                    category={subcategorySelected}
                    selectedFeatures={props.selectedFeatures}
                    searchText={searchText}
                    addFeature={props.selectFeature}
                    removeFeature={props.deleteSelectedFeature}
                    isSelected={isSelected}
                    indexOfFeature={indexOfFeature}
                    analyticsData={props.analyticsData}
                    device={props.device}
                    listFeaturesLoaded={showSuggestedFeatures ? !!props.overviewPageData?.suggestedFeatures : props.featuresLoaded}
                    countCompatibleModels={countCompatibleModels}
                />
            }
            <div className="featureLookupScroll"><ScrollButton imagePath={AppSettings.AWSImgRepo.resources + "en/"} /></div>
        </div>
    );
}

export default FeatureLookup;