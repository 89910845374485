import React, { useEffect, useState, useRef } from 'react';
import '../../styles/css/towingPayload-infoPopUp.css'
import { CalculatorModal } from './towingPayload-calculatorModal'
import AppSettings from '../../appsettings.js';
import * as analytics from './towingPayload-analytics';

export const InfoPopUp = (props) => {
    const [definitionsModalVisible, setDefinitionsModalVisibility] = useState(false);
    const moreLinkVisible = props.moreLinkVisible === false ? false : true;
    const moreLinkCTA = "MORE";

    useEffect(() => {
        props.clickAnalytics && analytics.popupOpenClick(props.clickAnalytics, props.vehicleSelection.modelYear, props.vehicleSelection.modelName, props.trimDetails, props.content.title, props.trailerPanel);
    }, []);

    const closeDefinitionsModal = () => {
        setDefinitionsModalVisibility(false);
    };

    const closeButtonClick = () => {
        props.closePopUp();
        props.clickAnalytics && analytics.popupCloseClick(props.clickAnalytics, props.vehicleSelection.modelYear, props.vehicleSelection.modelName, props.trimDetails, props.content.title, props.trailerPanel);
    };

    const moreButtonClick = () => {
        setDefinitionsModalVisibility(true);
        props.clickAnalytics && analytics.popupMoreClick(props.clickAnalytics, props.vehicleSelection.modelYear, props.vehicleSelection.modelName, props.trimDetails, props.content.title, moreLinkCTA, props.trailerPanel);
    };

    return (
        <div className="popup-container">
            {/* info pop up: */}
            <div className="tp-infoPopUp">
                <div className="popUpCloseButton" onClick={()=>closeButtonClick()}>
                    <img className="closeIcon" src={AppSettings.AWSImgRepo.resources + 'en/' + 'icon_close_black.svg'} />
                </div>
                <div className="popUpContent">
                    <div className="title">{props.content.title}</div>
                    {props.content.subtitle && <div className="subtitle">{props.content.subtitle}</div>}
                    <div className="description">
                        {props.content.definition}
                        {AppSettings.FeatureFlags.showTowingPayloadMoreLink && moreLinkVisible && <a className="moreLink" onClick={()=>moreButtonClick()}>{moreLinkCTA}</a>}
                    </div>
                </div>
            </div>

            {/* towing definitions modal: */}
            {definitionsModalVisible && <CalculatorModal 
                modalType={"Towing Definitions"} 
                visible={definitionsModalVisible} 
                closeModal={closeDefinitionsModal}
                towingDefinitionsData={props.towingDefinitionsData}
                selectedTerm={props.content.title} // use in modal to scroll to selected term
            />}
        </div>

    );
};