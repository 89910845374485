import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import '../../../styles/css/searchBar.css';
import '../../../styles/css/searchSpecItem.css';
import '../../../styles/css/searchColorAvailability.css'
import applyMarkdown from '../../utils/applyMarkdown';
import DisclaimersComponent from '../../disclaimers/disclaimers.component';
import {
  generateLearnMoreButton,
  generateOptionsDropdown,
  getEngineOptions,
  getTrimOptions,
  filterTrims,
  optionsDropdownAnalytics,
} from '../resultHelperFunctions.js';

export class SpecSearchItemComponent extends React.Component {
  constructor(props) {
    super(props);
    const engineSet = [...new Set(props.item.values.map(x => x.engine))];
    this.state = {
      selectedTrimIndex: 0,
      filteredTrims: filterTrims(props.item.values, engineSet[0]),
      selectedEngineValue: engineSet[0],
    };
    this.updateSelectedTrimValue = this.updateSelectedTrimValue.bind(this);
    this.updateSelectedEngineValue = this.updateSelectedEngineValue.bind(this);
  }

  createSpecSearchResult() {
    const { item } = this.props;
    const engineOptions = getEngineOptions(item.values);
    const trimValues = getTrimOptions(this.state.filteredTrims);
    const engineDropdown = generateOptionsDropdown(engineOptions, this.updateSelectedEngineValue, 'Engine');
    const trimDropdown = generateOptionsDropdown(trimValues, this.updateSelectedTrimValue, 'Trim');
    const learnMoreButton = generateLearnMoreButton(item, this.state, this.props.parentComponent.state.analyticsData.clickAnalytics, this.props.parentComponent.state.searchParam, this.props.parentComponent.state.totalResults);

    return (
      <div className="item-content spec-content">
        <div className="content-header">
          <span>
            {item.descriptionToShow === 'MSRP' ? 'Base MSRP' : item.descriptionToShow}
            {item.Description === 'MSRP' ? <sup className="disclaimer-link" onClick={() => this.disclaimer.onOpenModal()}>&dagger;</sup> : null}
          </span>
        </div>
        <div className="content-dropdowns">
          {SpecSearchItemComponent.shouldDisplayDrowpdowns(item) ? engineDropdown : null}
          {SpecSearchItemComponent.shouldDisplayDrowpdowns(item) ? trimDropdown : null}
        </div>
        <div className="content-output search-spec-output">
          <div className="content-output-title">{this.state.filteredTrims[this.state.selectedTrimIndex].trim}</div>
          <div className="content-output-text">{applyMarkdown(this.state.filteredTrims[this.state.selectedTrimIndex].value, this.props.disclaimerCallback, this.props.index)}</div>
        </div>
        <div className="quickAnswersSeeMore">
          {learnMoreButton}
        </div>
        <DisclaimersComponent
          ref={(instance) => {
            this.disclaimer = instance;
          }}
          addBaseMSRPDisclaimer={true}
        />
      </div>
    );
  }

  static shouldDisplayDrowpdowns(item) {
    return item && !item.allTrims;
  }

  updateSelectedTrimValue(value) {
    optionsDropdownAnalytics(this.state.filteredTrims[value].trim, 'Trim', this.props.item, this.props.parentComponent.state.analyticsData.clickAnalytics, this.props.parentComponent.state.searchParam, this.props.parentComponent.state.totalResults);
    this.setState({
      selectedTrimIndex: value,
    }, this.props.parentComponent.reRenderHeight);
  }

  updateSelectedEngineValue(value) {
    optionsDropdownAnalytics(value, 'Engine', this.props.item, this.props.parentComponent.state.analyticsData.clickAnalytics, this.props.parentComponent.state.searchParam, this.props.parentComponent.state.totalResults);
    this.setState({
      selectedEngineValue: value,
      filteredTrims: filterTrims(this.props.item.values, value),
      selectedTrimIndex: 0,
    }, this.props.parentComponent.reRenderHeight);
  }

  getFormattedType() {
    switch (this.props.item.type) {
      case 'spec':
        return 'Specs';
      case 'msrp':
        return 'MSRP';
      default:
        return 'Unspecified';
    }
  }

  componentDidUpdate(nextProps) {
    if (this.props.item.descriptionToShow !== nextProps.item.descriptionToShow
        || this.props.item.displayModelName !== nextProps.item.displayModelName
        || this.props.item.year !== nextProps.item.year
        || this.props.item.displayGroupName !== nextProps.item.displayGroupName) {
      const { item } = this.props;
      const engineSet = [...new Set(item.values.map(x => x.engine))];
      this.setState({
        selectedTrimIndex: 0,
        filteredTrims: filterTrims(this.props.item.values, engineSet[0]),
        selectedEngineValue: engineSet[0],
      });
    }
  }

  render() {
    return (this.createSpecSearchResult());
  }
}

SpecSearchItemComponent.propTypes = {
  item: PropTypes.object,
  disclaimerCallback: PropTypes.func,
  index: PropTypes.number,
  parentComponent: PropTypes.object,
};

export default injectIntl(SpecSearchItemComponent);
