import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();


export function sendUpdateDropDownSelection(model, year, category, label) {
  analytics.fireOnClick({
    tag: '29.2',
    section: 'Product',
    page: 'Features',
    modelName: model,
    modelYear: year,
    container: 'Compare',
    module: 'Drop Down',
    category,
    label,
  });
}

export function sendPageLoadAnalytics(model, year) {
  analytics.firePageLoad({
    tag: '29.1',
    section: 'Product',
    page: 'Features',
    modelName: model,
    modelYear: year,
  });
}

export function sendClickAnalytics(model, year, container, mod, category, label) {
  analytics.fireOnClick({
    tag: '29.2',
    section: 'Product',
    page: 'Features',
    modelName: model,
    modelYear: year,
    container,
    module: mod,
    category,
    label,
  });
}
