import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import "../../../styles/css/news/newsArchive/newsArchives.css";
import NewsArchiveCard from "./newsArchiveCard";
import { FormattedMessage } from "react-intl";

const NewsArchive = (props) => {
  const [displayedArticles, setDisplayedArticles] = useState([]);
  const [remainingArticles, setRemainingArticles] = useState([]);

  useEffect(() => {
    if (props.articles) {
      loadMoreArticles(6);
      setRemainingArticles(props.articles.length);
    }
  }, [props.articles]);

  const loadMoreArticles = (totalArticles) => {
    const numArticlesToDisplay = totalArticles || displayedArticles.length + 6;
    setDisplayedArticles(props.articles.slice(0, numArticlesToDisplay));
    setRemainingArticles(props.articles.length - displayedArticles.length);
  };

  return (
    <div className="newsArchiveDiv">
      {props.articles ? (
        <div className="newsArchiveCards">
          {displayedArticles.map((article) => {
            return <NewsArchiveCard 
              article={article} 
              analytics={props.analytics}
              analyticsData={props.analyticsData}
              pageDate={props.pageDate}
            />;
          })}
        </div>
      ) : (
        <div className="newsSpinner">
          <ClipLoader color={"#EB0A1E"} loading={true} size={50} />
        </div>
      )}
      <div className="loadMore">
        {remainingArticles > 6 ? (
          <div className="viewMore" onClick={() => { loadMoreArticles(); props.analytics.viewMoreClick(props.analyticsData.clickAnalytics.viewMore, props.pageDate); }}>
            <FormattedMessage id="View More" />
          </div>
        ) : displayedArticles == 0 ? (
          <div className="button secondary" id="noMoreButton">
            <FormattedMessage id={`No Articles Posted in ${props.date}`} />
          </div>
        ) : (
          <div className="button secondary" id="noMoreButton">
            <FormattedMessage id="global.button.noMoreToDisplay" />
          </div>
        )}
      </div>
    </div>
  );
};

NewsArchive.propTypes = {
  newsArchive: PropTypes.object.isRequired,
  articles: PropTypes.array,
};

export default NewsArchive;
