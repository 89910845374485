import { GlobalAnalytics } from '../../../analytics';

const analytics = new GlobalAnalytics();

const contentLinkClick = (tag, section, subsection, technologyName, page, category, positionTitle, contentTitle) => {
  analytics.fireOnClick({
    tag,
    section,
    subsection,
    technologyName,
    page,
    category,
    positionTitle,
    contentTitle,
  });
};

export const linkClick = (clickAnalytics, contentTitle) => {
  clickAnalytics && clickAnalytics.pricingLinkClick && contentTitle && contentLinkClick(
    clickAnalytics.pricingLinkClick.tagId,
    clickAnalytics.pricingLinkClick.section,
    clickAnalytics.pricingLinkClick.subsection,
    clickAnalytics.pricingLinkClick.technologyName,
    clickAnalytics.pricingLinkClick.page,
    clickAnalytics.pricingLinkClick.category,
    clickAnalytics.pricingLinkClick.positionTitle,
    contentTitle,
  );
};

export const pricingSubLoad = (pageLoadAnalytics) => {
  pageLoadAnalytics && pageLoadAnalytics.pricingSubLoad && contentLinkClick(
    pageLoadAnalytics.pricingSubLoad.tagId,
    pageLoadAnalytics.pricingSubLoad.section,
    pageLoadAnalytics.pricingSubLoad.subsection,
    pageLoadAnalytics.pricingSubLoad.technologyName,
    pageLoadAnalytics.pricingSubLoad.page,
    pageLoadAnalytics.pricingSubLoad.category,
    pageLoadAnalytics.pricingSubLoad.positionTitle,
    pageLoadAnalytics.pricingSubLoad.contentTitle,
  );
};
