import { GlobalAnalytics } from '../../analytics';
import { postUserActivityRecord } from '../../services/userActivityService';

const analytics = new GlobalAnalytics();

const resources = {
  tag: '35.2',
  section: 'Product',
  subsection: 'Featured Technology',
  technologyName: 'Star Safety System',
  page: 'Resources',
};

const starSafetySystemBaseSpec = {
  tag: '35.1',
  section: 'Product',
  subsection: 'Featured Technology',
  technologyName: 'Star Safety System',
};

const resourceClick = (resource) => {
  analytics.fireOnClick({
    ...resources,
    category: resource.recordType,
    contentTitle: resource.title,
    mediaTitle: resource.title,
  });
};

const pageLoadAnalytics = (page) => {
  postUserActivityRecord();
  analytics.firePageLoad({
    ...starSafetySystemBaseSpec,
    page,
  });
};

export const resourceAnalytics = {
  resourceClick,
  pageLoadAnalytics,
};

const articleClick = (mediaTitle) => {
  analytics.fireOnClick({
    ...starSafetySystemBaseSpec,
    tag: '35.2',
    category: 'Tile Click',
    page: 'Overview',
    mediaTitle,
  });
};

export const overviewAnalytics = {
  articleClick,
  pageLoadAnalytics,
};

const grabPageNameAnalyticsFromSubheaderClick = (pageName) => {
  switch (pageName) {
    case '"RESOURCES"':
      return 'Resources';
    case '"OVERVIEW"':
    default:
      return 'Overview';
  }
};

export const starSafetySystemSubheaderAnalyticOnClick = (event) => {
  const newPageName = event.currentTarget.firstChild && event.currentTarget.firstChild.firstChild;
  const pageName = grabPageNameAnalyticsFromSubheaderClick(newPageName);

  const analyticsObject = {
    ...starSafetySystemBaseSpec,
    tag: '35.2',
    page: pageName,
    mediaTitle: pageName,
    category: pageName,
  };
  analytics.fireOnClick(analyticsObject);
};
