import { Component } from 'react';
import { LegacyEdgeTemplate } from './legacy.edge.tpl';
import { getLegacyEdgeSheets } from '../../../services/caEdge.service';
import { postUserActivityRecord } from '../../../services/userActivityService';

export default class LegacyEdgeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edgeSheets: [],
    };
  }

  loadCAEdge() {
    // In the future this variable will come from the state in index.js
    const yearAndModel = `${this.props.year} ${this.props.model}`;
    return new Promise((resolve, reject) => {
      getLegacyEdgeSheets(yearAndModel)
        .then((payload) => {
          resolve(payload);
        }).catch((error) => {
          console.log(`ERROR : ${error}`);
          reject(error);
        });
    });
  }


  getPageData() {
    this.loadCAEdge().then((values) => {
      this.setState({
        edgeSheets: values[0].edgeSheets,
      });
      console.log(values);
    }).catch((reason) => {
      console.log(reason);
    });
  }


  componentDidMount() {
    postUserActivityRecord();
    this.getPageData();
  }

  componentWillReceiveProps() {

  }

  componentWillMount() {
    this.getPageData();
  }

  render() {
    return LegacyEdgeTemplate(this);
  }
}
