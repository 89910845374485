import React from 'react';
import { FormattedMessage } from 'react-intl';
import '../../../styles/css/audioMultimediaResources.css';
import DisclaimersComponent from '../../disclaimers/disclaimers.component';
import ArticleListComponent from '../../articleList/articleList.component';
import { getLocale } from '../../../services/languageTranslationService.js';
import {
  filterMenuClick, sortAnalytics, resourceListClick,
} from './audioMultimediaResourceAnalytics';
import defaultImage from '../../../resources/images/entune_default_thumb.jpg';
import { translateText } from '../../utils/translateText';

export function AudioMultimediaResourcesTemplate(component) {
  const AUDIOMULTIMEDIA = 'audio multimedia';
  const CONNECTEDSERVICES = 'connected services';
  const SUPRACONNECT = 'supra connect';

  return (
        <div className="spotlight-audioMultimedia">
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <div className="row">
                <div className="small-12 large-12 columns">
                    <div className="title">
                        <FormattedMessage id="entuneResources.pageTitle" defaultMessage="Audio Multimedia Resources" />
                    </div>
                </div>
            </div>
            <div className="list">
                <div className="row">
                    <div className="small-12 large-9 columns">
                        <div className="filter-label">
                            <FormattedMessage id="entuneResources.filterBy" defaultMessage="Filter by:" />
                        </div>
                        <div className='input-label-amm-container'>
                            <div className='input-label-amm-group'>
                                <input name="Audio Multimedia" type="checkbox" id="checkbox-1" checked={AUDIOMULTIMEDIA === component.state.articleTag.toLowerCase()} onChange={event => component.productFilterClick(event, AUDIOMULTIMEDIA)} />
                                <label className="checkbox-label" htmlFor="checkbox-1">{translateText('dropdown.audioMultimedia.audioMultimedia')}</label>
                            </div>
                            <div className='input-label-amm-group'>
                                <input name="Connected Services" type="checkbox" id="checkbox-2" checked={CONNECTEDSERVICES === component.state.articleTag.toLowerCase()} onChange={event => component.productFilterClick(event, CONNECTEDSERVICES)} />
                                <label className="checkbox-label" htmlFor="checkbox-2">{translateText('global.connectedServices')}</label>
                            </div>
                            <div className='input-label-amm-group'>
                                <input name="Supra Connect" type="checkbox" id="checkbox-3" checked={SUPRACONNECT === component.state.articleTag.toLowerCase()} onChange={event => component.productFilterClick(event, SUPRACONNECT)} />
                                <label className="checkbox-label" htmlFor="checkbox-3">{translateText('global.supraConnect')}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
            <ArticleListComponent
                defaultImage={defaultImage}
                filterMenuClick={filterMenuClick}
                key={component.state.articleTag}
                recordType={`tag-${component.state.articleTag}-${getLocale()}`}
                resourceClick={resourceListClick}
                sortClick={sortAnalytics}
            />
            <DisclaimersComponent template={'entune'} ref={(instance) => { component.disclaimer = instance; }} />
        </div>
  );
}
