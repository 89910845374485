import PropTypes from 'prop-types';
import React from 'react';
import '../../../styles/css/contactUs/contactUsMoreInfo/contactUsMoreInfoTile.css';
import { buttonClick } from '../contactUsAnalytics';

const ContactUsMoreInfoTile = (props) => {
    const getText = () => {
        let text = <span>{props.tile.text}</span>;
        const index = props.tile.text.indexOf(props.tile.phoneNumber);
        const handleClick = () =>{
            const clickAnalytics = props.analytics.clickAnalytics;
            buttonClick(clickAnalytics, clickAnalytics.phone, props.tile.phoneNumber)
        }
        if (index > -1) {
            text = <span>
                {props.tile.text.substring(0, index)}
                <a onClick={handleClick} href={`tel:${props.tile.phoneNumber}`}>{props.tile.phoneNumber}</a>
                {props.tile.text.substring(index + props.tile.phoneNumber.length)}
            </span>
        }
        return text;
    }
    return (
        <div className='contactUsMoreInfoTile' >
            <div className='contactUsMoreInfoTileText' >
                {getText()}
            </div>
        </div>
    )
};

ContactUsMoreInfoTile.propTypes = {
    analytics: PropTypes.object,
    tile: PropTypes.object,
};

export default ContactUsMoreInfoTile;