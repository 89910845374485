import PropTypes from 'prop-types';
import React from 'react';
import ReactFreezeframe from 'react-freezeframe';
import '../../../styles/css/news/newsArchive/newsArchiveCardHeader.css';
import { getArticleThumbnailPath } from '../../utils/articleThumbnailHelpers';
import { formatDateAbbreviated } from '../../utils/formatDate';

const NewsArchiveCardHeader = (props) => {
    const resourceImage = getArticleThumbnailPath(props.article);
    const isGif = resourceImage.endsWith('.gif');
    const thumbnailImage = isGif ?
        <div className="NACHeaderThumbnail"><ReactFreezeframe alt="failed to load" src={resourceImage} /></div> :
        <div className="NACHeaderThumbnail"><img alt="failed to load" src={resourceImage} /></div>;

    return (
        <div className='NACHeader'>
            {thumbnailImage}
            <div className='NACHeaderContent'>
                <div>
                    <div className='NACHeaderContentType'>
                        ARTICLE
                    </div>
                    <div className='NACHeaderContentTitle'>
                        {props.article.title}
                    </div>
                </div>
                <div className='NACHeaderContentDate'>
                    {formatDateAbbreviated(props.article.createdDate)}
                </div>
            </div>
        </div>
    )
};

NewsArchiveCardHeader.propTypes = {
    article: PropTypes.object.isRequired,
};

export default NewsArchiveCardHeader;