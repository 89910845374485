import React, { useEffect } from "react";
import { BrowseArticles } from "../../../components/molecules/articles/browseArticles";
import { RecommendedArticles } from "../../../components/molecules/articles/recommendedArticles";
import "../../../styles/scss/beyondZero/resoures.scss";
import { bzResourcesClick, bzResourcesPageload } from "./resources.analytics";
import { postUserActivityRecord } from "../../../services/userActivityService";

export const BeyondZeroResources = ({ recommendedArticles, articles}) => {

    useEffect(() => {
      bzResourcesPageload({
        page: 'Resources'
      });
      postUserActivityRecord();
    }, [])

    const articleAnalytics = (articleTitle, type) => {
      // Analytics for article click
      bzResourcesClick({
        label: `${type} Article`,
        content_title: articleTitle
      })
    }

    const articleFilterAnalytics = (label) => {
      // Analytics for article filter click
      bzResourcesClick({
        label: 'Filter Articles',
        content_title: label
      })
    }

    const seeMoreAnalitycs = (type) => {
      // Analytics for see more click
      bzResourcesClick({
        label: `See ${type}`
      })
    }

    

    return (
        <div className="resources-container">
            { (!recommendedArticles || recommendedArticles?.length > 0) &&
                <RecommendedArticles
                    maxToShow={6}
                    articleAnalytics={articleAnalytics}
                    seeMoreAnalitycs={seeMoreAnalitycs}
                    articles={recommendedArticles}
                />
            }
            <BrowseArticles
                articleFilterAnalytics={articleFilterAnalytics}
                seeMoreAnalitycs={seeMoreAnalitycs}
                articleAnalytics={articleAnalytics}
                articles={articles}
            />
        </div>
    );
};