import { useState, useEffect } from 'react'

export default function useOnScreen(ref) {
    const [isIntersecting, setIntersecting] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [entryTime, setEntryTime] = useState(null);
    const [exitTime, setExitTime] = useState(null);

    const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    );

    useEffect(() => {
        observer.observe(ref.current);
        return () => { observer.disconnect(); }
    }, [])

    if (isVisible !== isIntersecting) {
        setIsVisible(isIntersecting);
        if (isIntersecting) {
            setEntryTime(Date.now());
        } else {
            setExitTime(Date.now());
        }
    }
    if (entryTime && exitTime && (exitTime - entryTime >= 2000)) {
        setEntryTime(null);
        return true;
    } else {
        return false;
    }
}