import React, { useState, useEffect } from "react";
import VehicleSelectorGroup from "./vehicleSelectorGroup";
import VehicleComparisonChart from "./vehicleComparisonChart";
import { getFeaturesAndSpecs } from "../../../services/featuresAndSpecsService";
import { isDash } from "../../utils/helpers";
import "../../../styles/css/beyondZero/electrifiedComparison.css"

const ElectrifiedComparison = ({ electrifiedComparisonData, analyticsAction }) => {
    const [veh1Info, setVeh1Info] = useState(null);
    const [veh2Info, setVeh2Info] = useState(null);
    const [veh3Info, setVeh3Info] = useState(null);

    const [veh1ChartData, setVeh1ChartData] = useState(null);
    const [veh2ChartData, setVeh2ChartData] = useState(null);
    const [veh3ChartData, setVeh3ChartData] = useState(null);

    const [showThirdComp, setShowThridComp] = useState(false);

    useEffect(() => {
        veh1Info && getVehicleData(veh1Info, setVeh1ChartData);
    }, [veh1Info]);
    
    useEffect(() => {
        veh2Info && getVehicleData(veh2Info, setVeh2ChartData);
    }, [veh2Info]);

    useEffect(() => {
        veh3Info && getVehicleData(veh3Info, setVeh3ChartData);
    }, [veh3Info]);

    useEffect(() => {
        analyticsAction?.onClick({
            label: `${showThirdComp ? 'Add' : 'Remove'} Vehicle`,
        })

        if(!showThirdComp) {
            setVeh3ChartData(
                {
                    vehicleName: '',
                    vehicleData: ['', '', '', '', '', '', '', '']
                }
            );
        }
    }, [showThirdComp]);

    const getVehicleData = (vehicleInfo, update) => {
        getFeaturesAndSpecs(vehicleInfo.model, vehicleInfo.modelYear).then(data => {
            const trimSpecs = data.featuresAndSpecs.Specs;

            const startingPrice = vehicleInfo.startingPrice;
            const horsepower = getHorsepower(vehicleInfo.modelCode, trimSpecs);
            const mpg = getMpg(vehicleInfo.modelCode, trimSpecs);
            const evDriveBattery = getDriveBattery(vehicleInfo.modelCode, trimSpecs);
            const evBatteryWaranty = getBatteryWarranty(vehicleInfo.modelCode, trimSpecs);
            const evHybridSystemWaranty = getHybridWarranty(vehicleInfo.modelCode, trimSpecs);

            const vehicleChartData = {
                vehicleName: `${vehicleInfo.model} ${vehicleInfo.name}`,
                vehicleData: [
                    isEmpty(startingPrice) ? 'MSRP Coming Soon' : formatPrice(startingPrice),
                    horsepower,
                    mpg,
                    evDriveBattery,
                    evBatteryWaranty,
                    evHybridSystemWaranty,
                    vehicleInfo.buildLocation,
                    vehicleInfo.costOfOwnership,
                ]
            };
            update(vehicleChartData);
        }).catch(error => {
            console.log(`Error getting features and specs data: ${error}`);
        });
    };

    const getHorsepower = (modelCode, trimSpecs) => {
        const dataPointsToCheck = [
            {specName: 'electric motor', description: 'net', showNet: true},
            {specName: 'electric motor', description: 'power', showNet: false},
            {specName: 'engines', description: 'net', showNet: true},
            {specName: 'engines', description: 'power', showNet: false},
        ];

        for (const item of dataPointsToCheck) {
            const data = lookForData(trimSpecs, modelCode, item.specName, item.description);
            
            if (!isEmpty(data)) { 
                return formatHorsePower(data, item.showNet);
            }
        }

        return '';
    };

    const getMpg = (modelCode, trimSpecs) => {
        const mpg = lookForData(trimSpecs, modelCode, 'fuel facts', 'mpg');

        if (!isEmpty(mpg)) { 
            return formatMpg(mpg);
        }

        return '';
    };

    const getDriveBattery = (modelCode, trimSpecs) => {
        const evDriveBattery = lookForData(trimSpecs, modelCode, 'drive battery', 'type');
        
        if (!isEmpty(evDriveBattery)) { 
            return evDriveBattery;
        }

        return '';
    };

    const getBatteryWarranty = (modelCode, trimSpecs) => {
        const dataPointsToCheck = [
            {specName: 'warranties', description: 'hybrid battery'},
            {specName: 'warranties', description: 'electric vehicle'},
            {specName: 'warranties', description: 'fuel cell battery'},
        ];

        for (const item of dataPointsToCheck) {
            const data = lookForData(trimSpecs, modelCode, item.specName, item.description);
            
            if (!isEmpty(data)) { 
                return data;
            }
        }

        return '';
    };

    const getHybridWarranty = (modelCode, trimSpecs) => {
        const dataPointsToCheck = [
            {specName: 'warranties', description: 'hybrid system'},
            {specName: 'warranties', description: 'electric vehicle'},
            {specName: 'warranties', description: 'fuel cell system'},
        ];

        for (const item of dataPointsToCheck) {
            const data = lookForData(trimSpecs, modelCode, item.specName, item.description);
            
            if (!isEmpty(data)) { 
                return data;
            }
        }

        return '';
    };

    const lookForData = (trimSpecs, modelCode, specName, description) => {
        const data = trimSpecs
            ?.find(spec => spec.name.toLowerCase().includes(specName))
            ?.featureSpecs
            ?.find(j => j.Description.toLowerCase().includes(description))
            ?.[modelCode];
        
        return data;
    };

    const isEmpty = (data) => {

        // data object does not exist
        if (data === null || data === undefined) {
            return true;
        }

        const removedWhitespace = data.replace(/\s/g, "");

        // data object is a dash or white space
        if (isDash(removedWhitespace) || removedWhitespace === '' || removedWhitespace === 'N/A') {
            return true;
        }

        return false;
    }

    const formatPrice = (price) => {
        return `$${price.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    };

    const formatHorsePower = (hp, showNet) => {
        const strings = hp.split('@');
        const ret = strings[0]
        return (isNaN(ret) || !showNet) ? ret : `${ret} (net)`;
    };

    const formatMpg = (mpg) => {
        return (mpg.includes('/') ? `${mpg} (city / hwy / combined)` : mpg);
    };

    const handleOnVehicleDropdownChange = (vehicleIndex, key, value) => {
        if (key && value) {
            analyticsAction.onClick({
                label: `${key} Dropdown`,
                content_title: value
            })
        } 
    }

    return (
        <div className="electrified-comparison-container">
            <div className="electrified-comparison-header-section">
                <div className="header-title">{electrifiedComparisonData?.headers?.title}</div>
                <div className="header-description">{electrifiedComparisonData?.headers?.description}</div>
            </div>
            
            <VehicleSelectorGroup
                electricVehicleData={electrifiedComparisonData?.electricVehicles}
                headerData={electrifiedComparisonData?.headers}
                showThirdComp={showThirdComp}
                setShowThridComp={setShowThridComp}
                veh1Info={veh1Info}
                setVeh1Info={setVeh1Info}
                veh2Info={veh2Info}
                setVeh2Info={setVeh2Info}
                veh3Info={veh3Info}
                setVeh3Info={setVeh3Info}
                onVehicleDropdownChange={handleOnVehicleDropdownChange}
            />
            <VehicleComparisonChart
                veh1ChartData={veh1ChartData}
                veh2ChartData={veh2ChartData}
                veh3ChartData={veh3ChartData}
                showThirdComp={showThirdComp}
            />
      </div>
    );
};

export default ElectrifiedComparison;