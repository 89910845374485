import "../../../styles/scss/engageXP/tournaments/tournamentDashboard.scss";
import React, { useEffect, useState } from "react";
import { colorHelper, getRoundStatus } from "../utils";
import { getTournamentMissionsDetails } from "../../../services/nitroService";
import Skeleton from "react-loading-skeleton";

const TournamentDashboard = ({
    rounds,
    currentRound,
    imgPath,
    myRank,
    userAverageScore,
    userTournamentPoints,
    userTournamentXp,
    setUserTournamentXp,
}) => {
    const [userTournamentXpFetched, setUserTournamentXpFetched] = useState();
    const [roundsDetails, setRoundsDetails] = useState();
    const [currentRoundDetails, setCurrentRoundDetails] = useState();
    const userRank = myRank ? `#${myRank}` : "-";
    const userScore = userAverageScore ? `${Math.round(userAverageScore * 100)}%` : "-";
    const userPoints = userTournamentPoints ? userTournamentPoints : "-";
    const userXp = userTournamentXp ? userTournamentXp : "-";
    const checkImg = imgPath + "small-white-check.svg";

    const calculateRoundXpPoints = async (round) => {
        const roundMissionsWithDetails = await getTournamentMissionsDetails(round.missions);
    
        let roundXpPoints = 0;
        roundMissionsWithDetails.forEach((mission) => {
            if (mission.details.completionCount) {
                mission.details.rewards.forEach((reward) => {
                    if (reward.pointCategory.name === "XP") {
                        roundXpPoints += reward.pointAward;
                    }
                });
            }
        });

        const roundDetails = round;
        roundDetails.xpEarned = roundXpPoints;
        roundDetails.missions = roundMissionsWithDetails;
        return roundDetails;
    };
    
    const getRoundsDetails = async (rounds) => {
        const roundsDetailsPromises = rounds.map(round => {
            const status = getRoundStatus(round);
            if (status === "closed" || status === "open") {
                return calculateRoundXpPoints(round);
            }
            return Promise.resolve({ ...round, xpEarned: 0 });
        });
    
        const roundsDetailsResults = await Promise.all(roundsDetailsPromises);
        return roundsDetailsResults;
    };

    const updateXpStats = async () => {
        setUserTournamentXpFetched(prevValue => {
            if (prevValue) {
                return prevValue;
            } else {
                getRoundsDetails(rounds)
                    .then(roundsDetailsData => {
                        const totalXpPoints = roundsDetailsData.reduce((acc, xp) => acc + xp.xpEarned, 0);
                        setUserTournamentXp(totalXpPoints ?? "-");
                        setRoundsDetails(roundsDetailsData);
                    })
                    .catch(error => {
                        console.error("Error fetching tournament XP:", error);
                    });
                return true;
            }
        });
    };

    useEffect(() => {
        updateXpStats();
    }, [rounds]);

    const updateWithCurrentRound = async () => {
        if (!currentRound || !roundsDetails) return;

        if (currentRound.order === currentRoundDetails?.order) return;

        const newCurrentRoundDetails = await calculateRoundXpPoints(currentRound);
        setCurrentRoundDetails(newCurrentRoundDetails);

        setRoundsDetails(prevRounds => {
            if (!prevRounds) {
                return [];
            }
            return prevRounds.map(round => {
                if (round.order === newCurrentRoundDetails.order) {
                    return newCurrentRoundDetails;
                }
                return round;
            });
        });
    };

    useEffect(() => {
        updateWithCurrentRound();
    }, [currentRound]);

    const statsToDisplay = [
        {
            title: "Your Rank",
            value: userRank,
        },
        {
            title: "Pro Points",
            value: userPoints,
        },
        {
            title: "Total XP Points",
            value: userXp,
        },
        {
            title: "Average Score (per mission)",
            value: userScore,
        },
    ];

    const ProgressCard = () => {
        const colors = colorHelper("rgb(43,170,253)", "rgb(12,86,201)", rounds.length);
        return (
            <div className="tourn-progress-container">
                <div className="progress-title">Tournament Progress</div>
                <div className="round-diagram-container">
                    {rounds?.map((round, index) => {
                        const currentDate = new Date();
                        const roundEndDate = new Date(round.endDate);
                        const isComplete = currentDate > roundEndDate;
                        return (
                            <React.Fragment key={index}>
                                {index > 0 && (
                                    <div
                                        className="round-rectangle"
                                        style={{
                                            background: isComplete
                                                ? `linear-gradient(90deg, ${colors[index - 1]} 0%, ${
                                                      colors[index]
                                                  } 100%) 0% 0%`
                                                : "#C9C9C9",
                                        }}
                                    />
                                )}
                                <div className="round-node-container">
                                    <div className="round-circle-outer">
                                        <div
                                            className="round-circle-inner"
                                            style={{ background: isComplete ? colors[index] : "#C9C9C9" }}
                                        />
                                        <div className="round-check-container">
                                            <img className="round-check" src={checkImg} />
                                        </div>
                                    </div>
                                    <div className="round-name">{round.title}</div>
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
        );
    };

    const StatsCard = ({ title, value }) => {
        return (
            <div className="stat-card">
                <div className="stat-title">{title}</div>
                <div className="stat-value">{value}</div>
            </div>
        );
    };

    const StatsCardPair = ({ stats }) => {
        return (
            <div className="stats-card-pair">
                {stats.map(
                    (stat, index) => index < 2 && <StatsCard key={index} title={stat.title} value={stat.value} />
                )}
            </div>
        );
    };

    const RoundChecklistCard = () => (
        <div className="round-checklist-container">
            <div className="checklist-title">{currentRound?.title ?? "Round 1"} Checklist</div>
            <div className="checklist-content">
                {!!currentRoundDetails?.missions?.length ? (
                    currentRoundDetails.missions.map((mission, index) => {
                        const isComplete = !!mission.details.completionCount;
                        const displayName = mission.details.displayName || mission.details.name;
                        return (
                            <div key={index} className="checklist-line-item">
                                <span className={`checklist-circle${isComplete ? " complete" : ""}`} />
                                {isComplete && <img src={checkImg} className="check" />}
                                <div className="line-item-text">
                                    <span className="mission-number">{`Mission ${index + 1} `}</span>
                                    <span className="mission-title">{displayName}</span>
                                </div>
                            </div>
                        );
                    })
                ): (
                    Array.from({ length: 3 }).map((_, index) => (
                        <Skeleton key={index} />
                    ))
                )}
            </div>
        </div>
    );

    return (
        <>
            <div className="tourn-dashboard">
                <div className="tourn-dashboard-content-container">
                    <ProgressCard />
                    <div className="stats-cards">
                        <StatsCardPair stats={statsToDisplay} />
                        {statsToDisplay.map((stat, index) => (
                            <React.Fragment key={index}>
                                {index >= 2 && <StatsCard title={stat.title} value={stat.value} />}
                            </React.Fragment>
                        ))}
                    </div>
                    <RoundChecklistCard />
                </div>
            </div>
        </>
    );
};

export default TournamentDashboard;
