import React from 'react';
import applyMarkdown from '../../../utils/applyMarkdown';
import '../../../../styles/css/headToHead.css';
import advantageIcon from '../../../../resources/images/advantage-icon.png';

export default function CostToOwn(props) {
  function QuickSpecsTableRowsMobile(props) {
    let specsArray = [];
    specsArray = props.item.quickSpecs ? props.item.quickSpecs.map((spec, index) => {
      // don't map the header or footnote as a table row
      if (!spec.toyotaHeader && !spec.footnote) {
        return (
                    <tr className="cto" key={index}>
                        <td className="full-width-header columns small-12">{applyMarkdown(spec.specName, props.disclaimer)}</td>
                        <td className="sm-col columns small-6">
                            {spec.toyotaAdvantage
                              ? <span>
                                    <img src={advantageIcon} />

                                </span> : null}
                            &nbsp;{applyMarkdown(spec.toyotaSpec, props.disclaimer)}</td>
                        <td className="sm-col columns small-6 col2">
                            {spec.competitorAdvantage
                              ? <span>
                                    <img src={advantageIcon} />
                                    &nbsp;
                            </span> : null}
                            {applyMarkdown(spec.competitorSpec, props.disclaimer)}</td>
                        <td className="sm-line columns small-12"></td>
                    </tr >
        );
      }

      return null;
    }) : null;
    return <tbody><tr />{specsArray}</tbody>;
  }


  function QuickSpecsTableRowsDesktop(props) {
    let specsArray = [];
    specsArray = props.item.quickSpecs ? props.item.quickSpecs.map((spec, index) => {
      // don't map the header or footnote as a table row
      if (!spec.toyotaHeader && !spec.footnote) {
        return (
                    <tr className="lg-row" key={index}>
                        <td className="lg-title">
                            {applyMarkdown(spec.specName, props.disclaimer)}
                        </td>
                        <td className="lg-col">
                            {spec.toyotaAdvantage
                              ? <span>
                                    <img src={advantageIcon} />
                                    &nbsp;
                            </span> : null}
                            {applyMarkdown(spec.toyotaSpec, props.disclaimer)}
                        </td>
                        <td className="lg-col">
                            {spec.competitorAdvantage
                              ? <span>
                                    <img src={advantageIcon} />
                                    &nbsp;
                            </span> : null}
                            {applyMarkdown(spec.competitorSpec, props.disclaimer)}
                        </td>
                    </tr >
        );
      }

      return null;
    }) : null;
    return <tbody><tr />{specsArray}</tbody>;
  }

  const quickSpecsTableDesktop = [];
  const tableHeaders = getTableHeaders(props.edgeContent.quickSpecs);
  quickSpecsTableDesktop.push(
        <table className="cost-to-own-table-desktop">
            <tr className="lg-row">
                <td />
                <td className="column-header">{tableHeaders.toyotaHeader ? applyMarkdown(tableHeaders.toyotaHeader, props.disclaimer) : applyMarkdown(props.selectedValueToyota, props.disclaimer)}</td>
                <td className="column-header">{tableHeaders.competitorHeader ? applyMarkdown(tableHeaders.competitorHeader, props.disclaimer) : applyMarkdown(props.selectedValueCompetitor, props.disclaimer)}</td>
            </tr>
            <div className="row"></div>
            <QuickSpecsTableRowsDesktop item={props.edgeContent} disclaimer={props.disclaimer} />
        </table>,
  );

  const quickSpecsTableMobile = [];
  quickSpecsTableMobile.push(
        <React.Fragment>
            <div className='cto'>
                <div className="cto-headers row">
                    <div className='cto-bold'>
                        {tableHeaders.toyotaHeader
                          ? <span>{applyMarkdown(tableHeaders.toyotaHeader, props.disclaimer)}</span>
                          : <span>{applyMarkdown(props.selectedValueToyota, props.disclaimer)}</span>
                        }
                    </div>
                    <div className='cto-bold'>
                        <span>{tableHeaders.competitorHeader ? applyMarkdown(tableHeaders.competitorHeader, props.disclaimer) : applyMarkdown(props.selectedValueCompetitor, props.disclaimer)}</span>
                    </div>
                </div>
                <table className='cost-to-own-table-mobile row'>
                    <QuickSpecsTableRowsMobile item={props.edgeContent} disclaimer={props.disclaimer} />
                </table>
            </div>
        </React.Fragment>,
  );

  function getTableHeaders(quickSpecs) {
    let toyotaHeader = props.selectedValueToyota;
    let competitorHeader = props.selectedValueCompetitor;
    quickSpecs.forEach((spec) => {
      if (spec.toyotaHeader) {
        toyotaHeader = spec.toyotaHeader;
      }
      if (spec.competitorHeader) {
        competitorHeader = spec.competitorHeader;
      }
    });
    return { toyotaHeader, competitorHeader };
  }

  function getFootnote(quickSpecs) {
    let footnote = null;
    quickSpecs.forEach((spec) => {
      if (spec.footnote) {
        footnote = `* ${spec.footnote}`;
      }
    });
    return footnote;
  }

  const footnote = getFootnote(props.edgeContent.quickSpecs);
  return (
        <div className="cost-to-own">
            <div className="cto-body">
                <div className="cto">
                    <div className="row">
                    </div>
                    <div className="hide-for-small-only">{quickSpecsTableDesktop}</div>
                    <div className="show-for-small-only">{quickSpecsTableMobile}</div>
                </div>
            </div>
            <div className="advantage-footnote-container"><div>{footnote || null}</div>
                <img src={advantageIcon} />
                &nbsp;
                    <span>Advantage</span>
            </div>
        </div>
  );
}
