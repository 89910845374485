import React from 'react';
import ReactDOM from 'react-dom';
import App from './screens/app/App';
import reportWebVitals from './reportWebVitals';
import './polyfills.js';
import 'react-loading-skeleton/dist/skeleton.css'
import '../node_modules/react-responsive-modal/styles.css';


ReactDOM.render(
  // <React.StrictMode>
    <App />,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
