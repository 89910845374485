import VideoPlayerComponent from "../../videoPlayer/videoPlayer.component";
import "../../../styles/scss/model-overview-v2/overview-video.scss";
import applyMarkdown from "../../utils/applyMarkdown";
import DisclaimersComponent from "../../disclaimers/disclaimers.component";
import { useRef } from "react";

const OverviewVideo = (props) => {
  const disclaimerComponentRef = useRef();

  return (
    <>
      <div className="overview-video-container">
        <div className="overview-video">
          <VideoPlayerComponent
            item={{
              hideCTA: false,
              qumuId: props.data?.videoId,
            }}
            analyticsModule="Overview Video Module"
            analyticsPage={props.displayModelName}
            analyticsSection="Product"
            analyticsVehicleSpecs={{
              section: "Product",
              modelName: props.displayModelName,
              modelYear: props.year
            }}
          />
        </div>
        <div className="overview-video-info">
          <div className="info-content">
            {props.imagePath && (
              <img
                className="overview-video-icon"
                src={props.imagePath + props.data?.icon}
              />
            )}
            <div className="overview-video-title">
              {applyMarkdown(props.data?.title, disclaimerComponentRef.current)}
            </div>
            <div className="overview-video-description">
              {applyMarkdown(props.data?.description, disclaimerComponentRef.current)}
            </div>
          </div>
        </div>
      </div>
      <DisclaimersComponent
        template={`${props.year}_${props.model}_overview`}
        ref={disclaimerComponentRef}
        addBaseMSRPDisclaimer={true}
      />
    </>
  );
};

export default OverviewVideo;
