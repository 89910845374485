import { Component } from 'react';
import { LegacyCAOverviewTemplate } from './legacy.overview.tpl';
import getCompetitiveAdvantagesOverview from '../../../services/caOverview.service';
import AppSettings from '../../../appsettings.js';
import { getLocale } from '../../../services/languageTranslationService.js';
import { postUserActivityRecord } from '../../../services/userActivityService';

export default class CaOverviewComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tagline: '',
      carousel: [],
    };
  }

  loadCAOverview() {
    return new Promise((resolve, reject) => {
      getCompetitiveAdvantagesOverview(this.props.model, this.props.year, this.state.languageOverride ? 'en' : undefined)
        .then((payload) => {
          resolve(payload);
        }).catch((error) => {
          console.log(`ERROR : ${error}`);
          reject(error);
        });
    });
  }

  getPageData() {
    this.loadCAOverview().then((values) => {
      this.setState({
        hasData: true,
        tagLine: values.tagLine,
        carousel: values.carousel,
        modelReview: values.modelReview,
        languageOverride: false,
      });
    }).catch((reason) => {
      console.log(reason);
    });
  }

  componentWillReceiveProps() {
    this.getPageData();
  }

  componentWillMount() {
    window.addEventListener('resize', this.checkWindowDimensions);
    this.getPageData();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkWindowDimensions);
  }

  componentDidMount() {
    postUserActivityRecord();
    this.getPageData();
  }

  componentDidUpdate() {
    if (this.state.languageOverride) {
      this.getPageData();
    } else if (this.state.displayOverrideText && getLocale() === 'en') {
      this.setState({ displayOverrideText: false });
    }
  }

  checkWindowDimensions() {
    this.setState({
      isInMobileView: window.innerWidth < AppSettings.ScreenWidthBreakpoints.small,
    });
  }

  render() {
    return LegacyCAOverviewTemplate(this);
  }
}
