import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();

export const eBrochuresLoaded = (pageLoadAnalytics, filterCategory) => {
  analytics.firePageLoad({
    tag: pageLoadAnalytics.tagId,
    section: pageLoadAnalytics.section,
    subsection: pageLoadAnalytics.subsection,
    page: filterCategory,
  });
};

const contentLinkClick = (tag, section, subsection, filterCategory, category, action) => {
  analytics.fireOnClick({
    tag,
    section,
    subsection,
    page: filterCategory,
    category,
    action,
  });
};

export const eBrochureDownloadClicked = (clickAnalytics, pageLoadAnalytics, filterCategory, modelName, action) => {
  (clickAnalytics && pageLoadAnalytics) && contentLinkClick(
    clickAnalytics.tagId,
    pageLoadAnalytics.section,
    pageLoadAnalytics.subsection,
    filterCategory,
    modelName,
    action,
  );
};

export const filterCategoryClicked = (clickAnalytics, pageLoadAnalytics, filterCategory, action) => {
  (clickAnalytics && clickAnalytics.categorySelection && pageLoadAnalytics) && contentLinkClick(
    clickAnalytics.tagId,
    pageLoadAnalytics.section,
    pageLoadAnalytics.subsection,
    filterCategory,
    clickAnalytics.categorySelection.category,
    action,
  );
};

export const yearSelectionClicked = (clickAnalytics, pageLoadAnalytics, filterCategory, modelName, action) => {
  (clickAnalytics && pageLoadAnalytics) && contentLinkClick(
    clickAnalytics.tagId,
    pageLoadAnalytics.section,
    pageLoadAnalytics.subsection,
    filterCategory,
    modelName,
    action,
  );
};
