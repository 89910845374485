import { GlobalAnalytics } from '../../analytics';
import { postTowCapacityAction } from '../../services/nitroService';

const analytics = new GlobalAnalytics();

const pageLoaded = (tag, section, page, modelYear, modelName, modelCode, trim, modelEngine, modelCab, modelBed, modelDrivetrain, positionTitle) => {
    analytics.firePageLoad({
        tag,
        section,
        page,
        modelYear,
        modelName,
        modelCode, 
        trim, 
        modelEngine, 
        modelCab, 
        modelBed, 
        modelDrivetrain,
        positionTitle,
    });
};

const pageClicked = (tag, container, category, label, positionTitle, modelYear, modelName, modelCode, trim, modelEngine, modelCab, modelBed, modelDrivetrain, contentTitle, section, page) => {
    analytics.fireOnClick({
      tag,
      container,
      category,
      label,
      positionTitle,
      modelYear,
      modelName,
      modelCode, 
      trim, 
      modelEngine, 
      modelCab, 
      modelBed, 
      modelDrivetrain,
      contentTitle,
      section,
      page,
    });
};

// Vehicle Selection Page
export const vehicleSelectionLoaded = (pageLoadAnalytics) => {
    pageLoadAnalytics && pageLoaded(
        pageLoadAnalytics.tagId,
        pageLoadAnalytics.section,
        pageLoadAnalytics.vehicleSelection.page,
        undefined,
        undefined,
    );
};

export const arrowClick = (clickAnalytics, direction) => {
    const label = _.replace(clickAnalytics.arrowClick.labelNeedsReplace, clickAnalytics.arrowClick.labelToReplace, direction);
    clickAnalytics && pageClicked(
        clickAnalytics.tagId,
        clickAnalytics.container,
        clickAnalytics.arrowClick.category,
        label,
    );
};
export const dropdownChangeClick = (clickAnalytics, modelName, modelYear) => {
    const label = _.replace(clickAnalytics.dropdownChangeClick.labelNeedsReplace, clickAnalytics.dropdownChangeClick.labelToReplace, modelYear);
    clickAnalytics && pageClicked(
        clickAnalytics.tagId,
        clickAnalytics.container,
        modelName,
        label,
        null,
        modelYear,
        modelName,
    );
};

export const goClick = (clickAnalytics, modelName, modelYear) => {
    const label = _.replace(clickAnalytics.goClick.labelNeedsReplace, clickAnalytics.goClick.labelToReplace, modelYear);
    clickAnalytics && pageClicked(
        clickAnalytics.tagId,
        clickAnalytics.container,
        modelName,
        label,
        null,
        modelYear,
        modelName,
    );
};

// Vehicle Filter Page
export const vehicleFilterLoaded = (pageLoadAnalytics, vehicleSelection) => {
    postTowCapacityAction(vehicleSelection.modelName, vehicleSelection.modelYear);
    const page = vehicleSelection.modelName;
    pageLoadAnalytics && pageLoaded(
        pageLoadAnalytics.tagId,
        pageLoadAnalytics.section,
        page,
        vehicleSelection.modelYear,
        vehicleSelection.modelName,
    );
};

export const backButtonClick = (clickAnalytics, modelYear, modelName, trimDetails, currentPage) => {
    const getPositionTitle = () => {
        switch (currentPage) {
            case 'ExpertCalculator': return clickAnalytics.backButtonClick.positionTitleExpert;
            case 'BeginnerCalculator': return clickAnalytics.backButtonClick.positionTitleBeginner
            default: return null;
        }
    };

    const getSection = () => {
        switch (currentPage) {
            case 'ExpertCalculator': return clickAnalytics.calculatorPage.section;
            case 'BeginnerCalculator': return clickAnalytics.calculatorPage.section;
            default: return null;
        }
    };

    const tagId = currentPage === 'Filter' ? clickAnalytics.tagId : clickAnalytics.calculatorPage.tagId;
    const category = currentPage === 'Filter' ? modelName : clickAnalytics.calculatorPage.category;
    const page = currentPage !== 'Filter' ? modelName : null;
    clickAnalytics && pageClicked(
        tagId,
        clickAnalytics.container,
        category,
        clickAnalytics.backButtonClick.label,
        getPositionTitle(),
        modelYear,
        modelName,
        trimDetails && trimDetails.modelCode,
        trimDetails && trimDetails.trimName,
        trimDetails && trimDetails.engine,
        trimDetails && trimDetails.cab,
        trimDetails && trimDetails.bedLength,
        trimDetails && trimDetails.driveType,
        null,
        getSection(),
        page,
    );
};

export const beginCalculatingClick = (clickAnalytics, modelYear, modelName, trimDetails) => {
    clickAnalytics && pageClicked(
        clickAnalytics.tagId,
        clickAnalytics.container,
        modelName,
        clickAnalytics.beginCalculatingClick.label,
        clickAnalytics.beginCalculatingClick.positionTitle,
        modelYear,
        modelName,
        trimDetails.modelCode,
        trimDetails.trimName,
        trimDetails.engine,
        trimDetails.cab,
        trimDetails.bedLength,
        trimDetails.driveType,
        trimDetails.trimName,
    );
};

export const cardTermClick = (clickAnalytics, modelYear, modelName, trimDetails, term) => {
    clickAnalytics && pageClicked(
        clickAnalytics.tagId,
        clickAnalytics.container,
        modelName,
        clickAnalytics.cardTermClick.label,
        trimDetails.trimName,
        modelYear,
        modelName,
        trimDetails.modelCode,
        trimDetails.trimName,
        trimDetails.engine,
        trimDetails.cab,
        trimDetails.bedLength,
        trimDetails.driveType,
        term,
    );
};

export const clearSelectionsClick = (clickAnalytics, modelYear, modelName) => {
    clickAnalytics && pageClicked(
        clickAnalytics.tagId,
        clickAnalytics.container,
        modelName,
        clickAnalytics.clearSelectionsClick.label,
        undefined,
        modelYear,
        modelName,
    );
};

export const filterDropdownClick = (clickAnalytics, modelYear, modelName, selection, placeholder) => {
    const getModelDetails = () => {
        const modelDetails = {
            "Trim": undefined,
            "Engine": undefined,
            "Cab": undefined,
            "Bed": undefined,
            "Drivetrain": undefined,
        };
        modelDetails[placeholder] = selection;
        return Object.values(modelDetails);
    };
    const positionTitle = _.replace(clickAnalytics.filterDropdownClick.positionTitleNeedsReplace, clickAnalytics.filterDropdownClick.positionTitleToReplace, placeholder);
    clickAnalytics && pageClicked(
        clickAnalytics.tagId,
        clickAnalytics.container,
        modelName,
        selection,
        positionTitle,
        modelYear,
        modelName,
        undefined,
        ...getModelDetails(),
    );
};

export const modelCodeDropdownClick = (clickAnalytics, modelYear, modelName, modelCode) => {
    clickAnalytics && pageClicked(
        clickAnalytics.tagId,
        clickAnalytics.container,
        modelName,
        modelCode,
        clickAnalytics.modelCodeDropdownClick.positionTitle,
        modelYear,
        modelName,
        modelCode,
    );
};

export const popupCloseClick = (clickAnalytics, modelYear, modelName, trimDetails, term, trailerPanel) => {
    const trailerPanelSuffix = trailerPanel ? `:${clickAnalytics.calculatorPage.category}` : "";
    const contentTitle = `${clickAnalytics.container}:${modelName}${trailerPanelSuffix}`;
    clickAnalytics && pageClicked(
        clickAnalytics.tagId,
        clickAnalytics.popupCloseClick.container,
        term,
        clickAnalytics.popupCloseClick.label,
        undefined,
        modelYear, 
        modelName,
        trimDetails.modelCode,
        trimDetails.trimName,
        trimDetails.engine,
        trimDetails.cab,
        trimDetails.bedLength,
        trimDetails.driveType,
        contentTitle,
    );
};

export const popupOpenClick = (clickAnalytics, modelYear, modelName, trimDetails, term, trailerPanel) => {
    const trailerPanelSuffix = trailerPanel ? `:${clickAnalytics.calculatorPage.category}` : "";
    const contentTitle = `${clickAnalytics.container}:${modelName}${trailerPanelSuffix}`;
    clickAnalytics && pageClicked(
        clickAnalytics.tagId,
        clickAnalytics.popupOpenClick.container,
        term,
        clickAnalytics.popupOpenClick.label,
        undefined,
        modelYear, 
        modelName,
        trimDetails.modelCode,
        trimDetails.trimName,
        trimDetails.engine,
        trimDetails.cab,
        trimDetails.bedLength,
        trimDetails.driveType,
        contentTitle,
    );
};

export const popupMoreClick = (clickAnalytics, modelYear, modelName, trimDetails, term, cta, trailerPanel) => {
    const trailerPanelSuffix = trailerPanel ? `:${clickAnalytics.calculatorPage.category}` : "";
    const contentTitle = `${clickAnalytics.container}:${modelName}${trailerPanelSuffix}`;
    clickAnalytics && pageClicked(
        clickAnalytics.tagId,
        clickAnalytics.popupMoreClick.container,
        term,
        cta,
        undefined,
        modelYear,
        modelName,
        trimDetails.modelCode,
        trimDetails.trimName,
        trimDetails.engine,
        trimDetails.cab,
        trimDetails.bedLength,
        trimDetails.driveType,
        contentTitle,
    );
};

export const scrollButtonClick = (clickAnalytics, modelYear, modelName) => {
    clickAnalytics && pageClicked(
        clickAnalytics.tagId,
        clickAnalytics.container,
        modelName,
        clickAnalytics.scrollButtonClick.label,
        undefined,
        modelYear,
        modelName,
    );
};

// Calculator Page
export const calculatorLoaded = (pageLoadAnalytics, page, modelYear, modelName, modelCode, trim, modelEngine, modelCab, modelBed, modelDrivetrain) => {
    pageLoadAnalytics && pageLoaded(
        pageLoadAnalytics.tagId,
        pageLoadAnalytics.section,
        page,
        modelYear, 
        modelName, 
        modelCode, 
        trim, 
        modelEngine, 
        modelCab, 
        modelBed, 
        modelDrivetrain,
    );
};

export const openInputPopup = (pageLoadAnalytics, vehicleSelection, trimDetails, term) => {
    const page = _.replace(pageLoadAnalytics.openInputPopup.pageNeedsReplace, pageLoadAnalytics.openInputPopup.pageToReplace, vehicleSelection.modelName)
    pageLoadAnalytics && pageLoaded(
        pageLoadAnalytics.tagId,
        pageLoadAnalytics.section,
        page,
        vehicleSelection.modelYear,
        vehicleSelection.modelName,
        trimDetails.modelCode,
        trimDetails.trimName,
        trimDetails.engine,
        trimDetails.cab,
        trimDetails.bedLength,
        trimDetails.driveType,
        term,
    );
};

export const openMenuModal = (pageLoadAnalytics, vehicleSelection, trimDetails, menuLabel) => {
    const page = vehicleSelection ? _.replace(pageLoadAnalytics.openMenuModal.pageNeedsReplace, pageLoadAnalytics.openMenuModal.pageToReplace, `${vehicleSelection.modelName}:${menuLabel}`) : menuLabel;
    pageLoadAnalytics && pageLoaded(
        pageLoadAnalytics.tagId,
        pageLoadAnalytics.section,
        page,
        vehicleSelection ? vehicleSelection.modelYear : null,
        vehicleSelection ? vehicleSelection.modelName : null,
        trimDetails ? trimDetails.modelCode : null,
        trimDetails ? trimDetails.trimName : null,
        trimDetails ? trimDetails.engine : null,
        trimDetails ? trimDetails.cab : null,
        trimDetails ? trimDetails.bedLength : null,
        trimDetails ? trimDetails.driveType : null,
    );
};

export const errorModalLoaded = (pageLoadAnalytics, vehicleSelection, trimDetails) => {
    const page = _.replace(pageLoadAnalytics.errorModal.pageNeedsReplace, pageLoadAnalytics.errorModal.pageToReplace, vehicleSelection.modelName);
    pageLoadAnalytics && pageLoaded(
        pageLoadAnalytics.tagId,
        pageLoadAnalytics.section,
        page,
        vehicleSelection.modelYear,
        vehicleSelection.modelName,
        trimDetails.modelCode,
        trimDetails.trimName,
        trimDetails.engine,
        trimDetails.cab,
        trimDetails.bedLength,
        trimDetails.driveType,
    );
};

export const calculatorButtonClick = (clickAnalytics, vehicleSelection, trimDetails, buttonText) => {
    clickAnalytics && pageClicked(
        clickAnalytics.calculatorPage.tagId,
        null,
        clickAnalytics.calculatorPage.category,
        buttonText,
        null,
        vehicleSelection.modelYear,
        vehicleSelection.modelName,
        trimDetails.modelCode,
        trimDetails.trimName,
        trimDetails.engine,
        trimDetails.cab,
        trimDetails.bedLength,
        trimDetails.driveType,
        null,
        clickAnalytics.calculatorPage.section,
        vehicleSelection.modelName,
    );
};

export const errorModalButtonClick = (clickAnalytics, vehicleSelection, trimDetails, buttonText) => {
    clickAnalytics && pageClicked(
        clickAnalytics.calculatorPage.tagId,
        null,
        clickAnalytics.calculatorPage.errorModalButtonClick.category,
        buttonText,
        null,        
        vehicleSelection.modelYear,
        vehicleSelection.modelName,
        trimDetails.modelCode,
        trimDetails.trimName,
        trimDetails.engine,
        trimDetails.cab,
        trimDetails.bedLength,
        trimDetails.driveType,
        null,
        clickAnalytics.calculatorPage.section,
        vehicleSelection.modelName,
    );
};

export const iIconClick = (clickAnalytics, vehicleSelection, trimDetails, term, trailerOverlay, beginner) => {
    const label = beginner ? clickAnalytics.calculatorPage.iIconClick.labelBeginner : clickAnalytics.calculatorPage.iIconClick.labelExpert;
    const positionTitle = trailerOverlay ? clickAnalytics.calculatorPage.iIconClick.positionTitleTrailerOverlay : clickAnalytics.calculatorPage.iIconClick.positionTitleGaugeSection;
    clickAnalytics && pageClicked(
        clickAnalytics.calculatorPage.tagId,
        null,
        clickAnalytics.calculatorPage.category,
        label,
        positionTitle,
        vehicleSelection.modelYear,
        vehicleSelection.modelName,
        trimDetails.modelCode,
        trimDetails.trimName,
        trimDetails.engine,
        trimDetails.cab,
        trimDetails.bedLength,
        trimDetails.driveType,
        term,
        clickAnalytics.calculatorPage.section,
        vehicleSelection.modelName,
    );
};

export const inputPopupClick = (clickAnalytics, vehicleSelection, trimDetails, term, weight, isEnter) => {
    const label = isEnter ? clickAnalytics.calculatorPage.inputPopupClick.enterLabel : clickAnalytics.calculatorPage.inputPopupClick.closeLabel;
    clickAnalytics && pageClicked(
        clickAnalytics.calculatorPage.tagId,
        null,
        clickAnalytics.calculatorPage.inputPopupClick.category,
        label,
        term,
        vehicleSelection.modelYear,
        vehicleSelection.modelName,
        trimDetails.modelCode,
        trimDetails.trimName,
        trimDetails.engine,
        trimDetails.cab,
        trimDetails.bedLength,
        trimDetails.driveType,
        weight.toString(),
        clickAnalytics.calculatorPage.section,
        vehicleSelection.modelName,
    );
};

export const menuButtonClick = (clickAnalytics, vehicleSelection, trimDetails, buttonText, pageName) => {
    const category = () => {
        if (pageName == "BeginnerCalculator" || pageName == "ExpertCalculator") {
            return clickAnalytics.calculatorPage.category;
        } else if (pageName == "Filter" && vehicleSelection) {
            return vehicleSelection.modelName;
        } else if (pageName == "Home") {
            return "Landing Page"
        } else {
            return null;
        }
    }
    const tagId = (pageName == "BeginnerCalculator" || pageName == "ExpertCalculator") ? clickAnalytics.calculatorPage.tagId : clickAnalytics.tagId;
    const container = (pageName == "BeginnerCalculator" || pageName == "ExpertCalculator") ?  null : clickAnalytics.container;
    const section = (pageName == "BeginnerCalculator" || pageName == "ExpertCalculator") ? clickAnalytics.calculatorPage.section : null;
    const page = (pageName == "BeginnerCalculator" || pageName == "ExpertCalculator") ? (vehicleSelection ? vehicleSelection.modelName : null) : null;

    clickAnalytics && pageClicked(
        tagId,
        container,
        category(),
        buttonText,
        null,
        vehicleSelection ? vehicleSelection.modelYear : null,
        vehicleSelection ? vehicleSelection.modelName : null,
        trimDetails ? trimDetails.modelCode : null,
        trimDetails ? trimDetails.trimName : null,
        trimDetails ? trimDetails.engine : null,
        trimDetails ? trimDetails.cab : null,
        trimDetails ? trimDetails.bedLength : null,
        trimDetails ? trimDetails.driveType : null,
        null,
        section,
        page,
    );
};

export const menuCloseClick = (clickAnalytics, vehicleSelection, trimDetails, menuLabel, pageName) => {
    const category = _.replace(clickAnalytics.calculatorPage.menuCloseClick.categoryNeedsReplace, clickAnalytics.calculatorPage.menuCloseClick.categoryToReplace, menuLabel);

    const tagId = (pageName == "BeginnerCalculator" || pageName == "ExpertCalculator") ? clickAnalytics.calculatorPage.tagId : clickAnalytics.tagId;
    const container = (pageName == "BeginnerCalculator" || pageName == "ExpertCalculator") ?  null : clickAnalytics.container;
    const section = (pageName == "BeginnerCalculator" || pageName == "ExpertCalculator") ? clickAnalytics.calculatorPage.section : null;
    const page = (pageName == "BeginnerCalculator" || pageName == "ExpertCalculator") ? (vehicleSelection ? vehicleSelection.modelName : null) : null;
 
    clickAnalytics && pageClicked(
        tagId,
        container,
        category,
        clickAnalytics.calculatorPage.menuCloseClick.label,
        null,
        vehicleSelection ? vehicleSelection.modelYear : null,
        vehicleSelection ? vehicleSelection.modelName : null,
        trimDetails ? trimDetails.modelCode : null,
        trimDetails ? trimDetails.trimName : null,
        trimDetails ? trimDetails.engine : null,
        trimDetails ? trimDetails.cab : null,
        trimDetails ? trimDetails.bedLength : null,
        trimDetails ? trimDetails.driveType : null,
        null,
        section,
        page,
    );
};

export const menuExpandCollapseAllClick = (clickAnalytics, vehicleSelection,trimDetails, menuLabel, isExpand, sectionName, pageName) => {
    const label = isExpand ? clickAnalytics.calculatorPage.menuExpandCollapseAllClick.labelExpand : clickAnalytics.calculatorPage.menuExpandCollapseAllClick.labelCollapse;
    const category = _.replace(clickAnalytics.calculatorPage.menuExpandCollapseClick.categoryNeedsReplace, clickAnalytics.calculatorPage.menuExpandCollapseClick.categoryToReplace, menuLabel);

    const tagId = (pageName == "BeginnerCalculator" || pageName == "ExpertCalculator") ? clickAnalytics.calculatorPage.tagId : clickAnalytics.tagId;
    const container = (pageName == "BeginnerCalculator" || pageName == "ExpertCalculator") ?  null : clickAnalytics.container;
    const section = (pageName == "BeginnerCalculator" || pageName == "ExpertCalculator") ? clickAnalytics.calculatorPage.section : null;
    const page = (pageName == "BeginnerCalculator" || pageName == "ExpertCalculator") ? (vehicleSelection ? vehicleSelection.modelName : null) : null;
    
    clickAnalytics && pageClicked(
        tagId,
        container,
        category,
        label,
        sectionName,
        vehicleSelection ? vehicleSelection.modelYear : null,
        vehicleSelection ? vehicleSelection.modelName : null,
        trimDetails ? trimDetails.modelCode : null,
        trimDetails ? trimDetails.trimName : null,
        trimDetails ? trimDetails.engine : null,
        trimDetails ? trimDetails.cab : null,
        trimDetails ? trimDetails.bedLength : null,
        trimDetails ? trimDetails.driveType : null,
        null,
        section,
        page,
    );
};

export const menuExpandCollapseClick = (clickAnalytics, vehicleSelection, trimDetails, menuLabel, isExpand, term, sectionName, pageName) => {
    const category = _.replace(clickAnalytics.calculatorPage.menuExpandCollapseClick.categoryNeedsReplace, clickAnalytics.calculatorPage.menuExpandCollapseClick.categoryToReplace, menuLabel);
    const action = isExpand ? clickAnalytics.calculatorPage.menuExpandCollapseClick.labelExpand : clickAnalytics.calculatorPage.menuExpandCollapseClick.labelCollapse;
    const label = menuLabel === 'FAQs' ? `${clickAnalytics.calculatorPage.menuExpandCollapseClick.labelFAQs} ${action}` : `${clickAnalytics.calculatorPage.menuExpandCollapseClick.labelDefault} ${action}`;
    
    const tagId = (pageName == "BeginnerCalculator" || pageName == "ExpertCalculator") ? clickAnalytics.calculatorPage.tagId : clickAnalytics.tagId;
    const container = (pageName == "BeginnerCalculator" || pageName == "ExpertCalculator") ?  null : clickAnalytics.container;
    const section = (pageName == "BeginnerCalculator" || pageName == "ExpertCalculator") ? clickAnalytics.calculatorPage.section : null;
    const page = (pageName == "BeginnerCalculator" || pageName == "ExpertCalculator") ? (vehicleSelection ? vehicleSelection.modelName : null) : null;

    clickAnalytics && pageClicked(
        tagId,
        container,
        category,
        label,
        sectionName,
        vehicleSelection ? vehicleSelection.modelYear : null,
        vehicleSelection ? vehicleSelection.modelName : null,
        trimDetails ? trimDetails.modelCode : null,
        trimDetails ? trimDetails.trimName : null,
        trimDetails ? trimDetails.engine : null,
        trimDetails ? trimDetails.cab : null,
        trimDetails ? trimDetails.bedLength : null,
        trimDetails ? trimDetails.driveType : null,
        term,
        section,
        page,
    );
};

export const moreLessClick = (clickAnalytics, vehicleSelection, trimDetails, openClick) => {
    const label = openClick ? clickAnalytics.calculatorPage.moreLessClick.labelOpen : clickAnalytics.calculatorPage.moreLessClick.labelClose;
    clickAnalytics && pageClicked(
        clickAnalytics.calculatorPage.tagId,
        null,
        clickAnalytics.calculatorPage.category,
        label,
        null,
        vehicleSelection.modelYear,
        vehicleSelection.modelName,
        trimDetails.modelCode,
        trimDetails.trimName,
        trimDetails.engine,
        trimDetails.cab,
        trimDetails.bedLength,
        trimDetails.driveType,
        null,
        clickAnalytics.calculatorPage.section,
        vehicleSelection.modelName,
    );
};

export const valueEntry = (clickAnalytics, vehicleSelection, trimDetails, title, value) => {
    const label = _.replace(clickAnalytics.calculatorPage.valueEntry.labelNeedsReplace, clickAnalytics.calculatorPage.valueEntry.labelToReplace, title);
    clickAnalytics && pageClicked(
        clickAnalytics.calculatorPage.tagId,
        null,
        clickAnalytics.calculatorPage.category,
        label,
        clickAnalytics.calculatorPage.valueEntry.positionTitle,
        vehicleSelection.modelYear,
        vehicleSelection.modelName,
        trimDetails.modelCode,
        trimDetails.trimName,
        trimDetails.engine,
        trimDetails.cab,
        trimDetails.bedLength,
        trimDetails.driveType,
        value,
        clickAnalytics.calculatorPage.section,
        vehicleSelection.modelName,
    );
};
