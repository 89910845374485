import React from 'react';
import PropTypes from 'prop-types';
import CarouselComponent from '../carousel/carousel.component';
import AppSettings from '../../appsettings.js';
import applyMarkdown from '../utils/applyMarkdown';
import DisclaimersComponent from '../disclaimers/disclaimers.component';

export function AwardsTemplate(component) {
  function NextArrow(props) {
    return (
      <div className={'slick-next'} onClick={(e) => { props.onArrowClick(e); }}></div>
    );
  }

  function PrevArrow(props) {
    return (
      <div className={'slick-prev'} onClick={(e) => { props.onArrowClick(e); }}></div>
    );
  }


  const awardsCarouselItem = component.state.awards.map((item, index) => {
    const sanitizedImageUrl = encodeURI(component.props.imagePath ? `${component.props.imagePath}${item.imageFilename}` : `${AppSettings.AWSImgRepo.static}/${item.imageFilename}`);
    return (
      <div className="awardDiv" key={index}>
        <div className="award">
          <div className="awardImage">
            <img alt="" src={sanitizedImageUrl} />
          </div>
          <div className="awardSubHeadline">
            {applyMarkdown(item.subheadline, component.props.disclaimer)}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div>
      <div className="row">
        <div className="carousel-container" style={{ ...component.props.style }}>
          {awardsCarouselItem.length > 0 ? <CarouselComponent {...{
            settings: {
              dots: true,
              infinite: awardsCarouselItem.length > 3,
              nextArrow: <NextArrow onArrowClick={e => component.props.onArrowClick(e)}/>,
              prevArrow: <PrevArrow onArrowClick={e => component.props.onArrowClick(e)}/>,
              onSwipe: component.props.onSwipe,
            },
          }}>{awardsCarouselItem}</CarouselComponent> : null}
        </div>
      </div>
      <DisclaimersComponent template={component.props.disclaimer ? component.props.disclaimer : `${component.props.modelYear}_${component.props.modelName}_awards`} ref={(instance) => { component.disclaimer = instance; }} />
    </div>
  );
}

AwardsTemplate.propTypes = {
  imagePath: PropTypes.string,
  modelName: PropTypes.string,
  modelYear: PropTypes.string || PropTypes.number,
  onArrowClick: PropTypes.func,
  onDotClick: PropTypes.func,
  onSwipe: PropTypes.func,
  style: PropTypes.object,
};
