import React from 'react';
import '../../../styles/css/headToHead.css';
import { FormattedMessage } from 'react-intl';

export function SectionHeaderTemplate(component) {
    const sanitizedImageURL = encodeURI(component.props.image);

    return (
        <div>
            <div className="header-line no-print" />
            <div className="header-title">
                <div className="header-box">
                    <img alt={component.state.sampleImage} className="header-icon" src={sanitizedImageURL} />
                    <span className="header-spacer">|</span><span className="header-bold"><FormattedMessage id={component.props.boldName} />
                    </span>&nbsp;
                    <span><FormattedMessage id={component.props.nonBoldName} /></span>
                </div>
            </div>
        </div>
    );
}
