import React, { useEffect, useState } from 'react';
import '../../../styles/css/topNav/menus/mainMenu.css';
import AppSettings from '../../../appsettings';
import VehicleMenu from '../dropdownMenu/vehiclesMenu/vehicleMenu';
import { isMobileView, isTabletView, isDesktopView } from '../../utils/getScreenSize';
import ProfilePicture from '../../../components/atoms/profilePicture';
import { generalNavButtonClicked, menuItemClicked, closeNavClicked } from '../topNav.analytics';

const mainMenu = (props) => {
    const [selectedSubMenu, setSelectedSubMenu] = useState(null);
    const [subMenuOpen, setSubMenuOpen] = useState(false);
    const [device, setDevice] = useState('desktop');
    const vinLookupUrl = "/vinLookup";
    const profileUrl = "/myAccount";
    const vinLookupImageUrl = AppSettings.AWSImgRepo.resources + 'en/vinLookupMobileMenu.png';
    const [scrollPosition, setScrollPosition] = useState(0);
    const [showMenuItems, setShowMenuItems] = useState(null);
    let hoverTime;

    useEffect(() => {
        if(props.menuOpen){
            setShowMenuItems(props.menuOpen);
        }else{
            const timeout = setTimeout(() => setShowMenuItems(props.menuOpen), 200);
            return () => clearTimeout(timeout);
        }

    }, [props.menuOpen]);

    useEffect(() => {
        updateDevice();
        window.addEventListener("resize", updateDevice);
        return () =>
            window.removeEventListener("resize", updateDevice);
    }, []);

    const updateDevice = () => {
        if (isDesktopView(window.innerWidth)) {
            setDevice('desktop');
        } else if (isTabletView(window.innerWidth)) {
            setDevice('tablet');
        } else if (isMobileView(window.innerWidth)) {
            setDevice('mobile');
        }
    }

    useEffect(() => {
        if (device == "mobile" && props.menuOpen) {
            //disable background body scrolling and input field zoom
            disableMobileScrolling();
        } else if (device == "mobile" && !props.menuOpen) {
            enableMobileScrolling();
        }
    }, [props.menuOpen]);

    const disableMobileScrolling = () => {
        const y = window.scrollY;
        setScrollPosition(y);
        document.getElementsByClassName("off-canvas-wrapper")[0].style.top = -y + 'px';

        document.getElementsByTagName("html")[0].classList.add("overlay-disable-body-scroll");
        document.body.classList.add("overlay-disable-body-scroll");
    }

    const enableMobileScrolling = () => {
        document.getElementsByTagName("html")[0].classList.remove("overlay-disable-body-scroll");
        document.body.classList.remove("overlay-disable-body-scroll");

        window.scrollTo(0, scrollPosition);
        document.getElementsByClassName("off-canvas-wrapper")[0].style.top = 0;
    }

    useEffect(() => {
        if (!props.menuOpen) {
            hideSubMenu();
            setSelectedSubMenu(null);
        }
    }, [props.menuOpen]);

    const showSubMenu = (item) => {
        document.getElementsByClassName('sub-menu-items')[0].scrollTop = 0;
        menuItemClicked(props.analytics.mainMenu, item.title);
        setSubMenuOpen(true);
        setSelectedSubMenu(item);
    }

    const hideSubMenu = () => {
        setSubMenuOpen(false);
        setSelectedSubMenu(null);
    }

    const handleCloseClick = () => {
        // Needed for analyics because tags for closing navigation menu differ depending on which submenu is open
        if (selectedSubMenu) {
            const analyticsItem = 'subMenu' + selectedSubMenu.title;
            closeNavClicked(props.analytics[analyticsItem]);
        } else {
            generalNavButtonClicked(props.analytics.hamburgerClose);
        }
        props.clearMenuSelections();
    }

    const handleSubMenuItemClick = (item, menuCategory) => {
        item.bookmarkFlag && props.bookmarkCallback();
        const analyticsItem = 'subMenu' + menuCategory;
        menuItemClicked(props.analytics[analyticsItem], item.title);
    }

    const handlerHoverMouseEnter = (item) => {
        if (device != 'mobile') {
            hoverTime = setTimeout(() => {
                showSubMenu(item);
            }, 500);
        }
    };

    const handlerHoverMouseOut = () => {
        hoverTime && clearTimeout(hoverTime);
    };

    return (
        <React.Fragment>
            {device === 'mobile' ?
                <div className={props.menuOpen ? 'mobile-top-section-visible' : 'mobile-top-section-hidden'}>
                    <a style={!selectedSubMenu ? { visibility: 'hidden' } : {}} onClick={hideSubMenu}>
                        <img className='mobile-menu-icons' src={AppSettings.AWSImgRepo.resources + 'en/black_left_arrow.png'} />
                    </a>
                    {selectedSubMenu?.title}
                    <a onClick={handleCloseClick}>
                        <img className='mobile-menu-icons' src={AppSettings.AWSImgRepo.resources + 'en/topNav_cancel.png'} />
                    </a>
                </div> : null
            }
            {window.ReactNativeWebView ? null :
                <div className='menu-container hide-for-print'>
                    <div className={`main-menu ${props.menuOpen ? 'main-menu-slide-in' : ''} ${subMenuOpen ? 'main-menu-expand-gradient' : ''} ${selectedSubMenu?.title == "Vehicles" && subMenuOpen ? 'main-menu-vehicle' : ''}`}>
                        <div className="menu-items-list" style={showMenuItems ? {display: "block"} : {display: "none"}}>
                            {props.menuData?.map((item) => {
                                return <div
                                    key={item?.title}
                                    className={`menu-item ${selectedSubMenu?.title == item?.title ? 'menu-item-bold' : ''}`}
                                    onMouseEnter={() => handlerHoverMouseEnter(item)}
                                    onMouseOut={handlerHoverMouseOut}
                                    onClick={() => showSubMenu(item)}
                                    style={subMenuOpen && device === 'mobile' ? {borderTop: 'none', marginTop: '1px'} : {}}>
                                    {item.title}
                                    <img className='menu-arrow' style={subMenuOpen && device === 'mobile' ? {display: 'none'} : {}} src={AppSettings.AWSImgRepo.resources + 'en/black_right_arrow.png'} />
                                </div>
                            })}   
                        </div>
                        {device === 'mobile' && !subMenuOpen ?
                            <div className='mobileMenuBottomIcons'>
                                <a href={profileUrl}>
                                    <ProfilePicture small menuLocated clickFunction={() => {
                                        generalNavButtonClicked(props.analytics.profile)
                                    }} />
                                </a>
                                <a href={vinLookupUrl}>
                                    <div className="mobileMenuBottomIconContainer" onClick={() => generalNavButtonClicked(props.analytics.vinScan)}>
                                        <img className="mobileMenuBottomVINIcon" src={vinLookupImageUrl} />
                                    </div>
                                </a>
                            </div>
                        : <></>}
                    </div>

                    <div className={`menu-divider-line ${props.menuOpen ? 'menu-divider-line-visible' : ''}`}></div>

                    <div className={`sub-menu-container ${subMenuOpen ? 'sub-menu-container-slide-in' : ''} ${selectedSubMenu?.title == "Vehicles" ? 'sub-menu-vehicle' : ''}`} style={showMenuItems ? {display: "block"} : {display: "none"}}>
                        {selectedSubMenu?.title == "Vehicles" ?
                            <div className={`sub-menu-items ${subMenuOpen ? 'sub-menu-items-slide-in' : ''}`}>
                                <div className='sub-menu-title'>
                                    {selectedSubMenu?.title}
                                </div>
                                <VehicleMenu
                                    vehicleData={selectedSubMenu?.menuItems}
                                    device={device}
                                    analytics={props.analytics.subMenuVehicles}
                                />
                            </div> :
                            <div className={`sub-menu-items ${subMenuOpen ? 'sub-menu-items-slide-in' : ''}`}>
                                <div className='sub-menu-title'>
                                    {selectedSubMenu?.title}
                                </div>
                                {selectedSubMenu?.menuItems.map((item) => {
                                    return <a className='menu-link' key={item.href} href={item.href} target={item.target} onClick={() => handleSubMenuItemClick(item, selectedSubMenu?.title)}>
                                        {item.title}
                                        {item.target == '_blank' && <img className='menu-external' src={AppSettings.AWSImgRepo.resources + 'en/topNav_external.png'} />}                                </a>
                                })}
                            </div>
                        }
                    </div>
                </div>
            }
        </React.Fragment>
    );
};

export default mainMenu;
