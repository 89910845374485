import { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Pagination from "../../../components/molecules/engage-xp-pagination/Pagination";
import usePagination from "../../../components/molecules/engage-xp-pagination/usePagination";
import S from "../../../styles/scss/engageXP/tournaments/leaderboard.module.scss";
import { getTournamentLeadersRegion, getTournamentLeadersScores } from "../../../services/nitroService";
import appsettings from "../../../appsettings";
import { getRoundStatus } from "../utils";

const Leaderboard = ({ items, pageData, isLoadingLeaderboard, analyticsData, myRank, setMyRank }) => {
    const {
        displayedItems,
        page,
        setDisplayedItems,
        setPage,
        handlePaginationBack,
        handlePaginationNext
    } = usePagination({ items, analyticsData });
    const [itemsToShow, setItemsToShow] = useState({ [page]: displayedItems });
    const tournamentsData = JSON.parse(sessionStorage.getItem("tournamentsData"));
    const firstRoundStatus = getRoundStatus(tournamentsData?.tournamentSection.rounds[0]);
    const shouldShowLeaderboard = firstRoundStatus !== "locked";

    useEffect(() => {
        setPage(1);
        setDisplayedItemsHandler(1);
    }, [items]);

    useEffect(() => {
        getMyRankAverageScoreAndRegion();
    }, [items]);

    const getMyRankAverageScoreAndRegion = async () => {
        const myIdFromLocalStorage = localStorage.getItem("spin_id");
        const myItem = items?.find((item) => item?.user?.id === myIdFromLocalStorage);
        if (myItem) {
            getTournamentLeadersScoresAndRegionData([myItem], myItem);
            setMyRank(myItem);
        } else {
            setMyRank(null);
        }
    };

    useEffect(() => {
        setDisplayedItemsHandler(page);
    }, [page]);

    useEffect(() => {
        setItemsToShow({ ...itemsToShow, [page]: displayedItems });
        if (displayedItems?.length > 0) {
            getTournamentLeadersScoresAndRegionData(displayedItems);
        }
    }, [displayedItems]);

    const getTournamentLeadersScoresAndRegionData = async (leaders, myRank) => {
        try {
            if (
                !myRank &&
                itemsToShow[page]?.length &&
                itemsToShow[page]?.every((item) => item.value && item.averageScore && item.region)
            ) {
                // Data already fetched
                return;
            }
            getTournamentLeadersScores(leaders, tournamentsData).then((response) => {
                if (myRank && (!myRank?.value || !myRank?.averageScore)) {
                    setMyRank((prev) => {
                        return {
                            ...prev,
                            averageScore:
                                response && (response[0].averageScore || response[0].averageScore === 0)
                                    ? `${Math.round(response[0].averageScore * 100)}%`
                                    : "-",
                        };
                    });
                    return;
                }
                setItemsToShow((prev) => {
                    return {
                        ...prev,
                        [page]: prev[page]?.map((item) => {
                            const user = response?.find((res) => res.user.id === item.user.id);
                            return {
                                ...item,
                                averageScore:
                                    user?.averageScore || user?.averageScore === 0 ? `${Math.round(user.averageScore * 100)}%` : "-",
                            };
                        }),
                    };
                });
            });

            getTournamentLeadersRegion(leaders).then((response) => {
                if (myRank && !myRank?.region) {
                    setMyRank((prev) => {
                        return {
                            ...prev,
                            region: response && response[0].user.regionCode
                                ? appsettings.Regions[parseInt(response[0].user.regionCode.replace("regionCode ", ""))]
                                : "-",
                        };
                    });
                    return;
                }
                setItemsToShow((prev) => {
                    return {
                        ...prev,
                        [page]: prev[page]?.map((item) => {
                            const regionCode = response?.find((res) => res?.user?.id === item?.user?.id)?.user?.regionCode;
                            const region = regionCode
                                ? appsettings.Regions[parseInt(regionCode.replace("regionCode ", ""))]
                                : "-";
                            return {
                                ...item,
                                region,
                            };
                        }),
                    };
                });
            });
        } catch (error) {
            console.error(error);
        }
    };

    const setDisplayedItemsHandler = (item) => {
        if (itemsToShow[item]?.length) {
            // Data already fetched
            setDisplayedItems(itemsToShow[item]);
            return;
        }
        setDisplayedItems(items?.slice((item - 1) * 10, item * 10));
    };

    const isTop3 = (rank) => rank <= 3;

    return (
        <div className={S["leaderboard-wrapper"]}>
            <SkeletonTheme baseColor="#202020" highlightColor="#444">
                <div id="leaderboard" className={S["leaderboard-container"]}>
                    <div className={S["header"]}>
                        <span className={S["header-title"]}>{pageData?.leaderboardSection?.title ?? ""}</span>
                    </div>
                    {shouldShowLeaderboard ? (
                        <table border={0}>
                            <thead>
                                <tr>
                                    <th>
                                        <div>#</div>
                                    </th>
                                    <th>Name</th>
                                    <th className={S["not-shown-on-mobile"]}>Region</th>
                                    <th>Pro Points</th>
                                    <th className={S["not-shown-on-mobile"]}>
                                        <p>Average Score</p>
                                        <p>(Per mission)</p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoadingLeaderboard ? (
                                    Array.from({ length: 10 }).map((_, index) => (
                                        <tr key={index} className={S["loading"]}>
                                            <td>
                                                <Skeleton height={40} />
                                            </td>
                                            <td>
                                                <Skeleton height={40} />
                                            </td>
                                            <td className={S["not-shown-on-mobile"]}>
                                                <Skeleton height={40} />
                                            </td>
                                            <td>
                                                <Skeleton height={40} />
                                            </td>
                                            <td className={S["not-shown-on-mobile"]}>
                                                <Skeleton height={40} />
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <>
                                        {itemsToShow[page]?.length
                                            ? itemsToShow[page].map((item, index) => (
                                                  <RankRow
                                                      {...item}
                                                      key={`${item.rank}_${item.user.firstName}_${index}`}
                                                      isMyRank={item.user.id === myRank?.user?.id}
                                                      isTop3={isTop3(item.rank)}
                                                  />
                                              ))
                                            : null}
                                        {myRank &&
                                            itemsToShow[page]?.every((item) => item.user.id !== myRank.user.id) && (
                                                <>
                                                    <tr className={S["spacing"]}>
                                                        <td colSpan="5" />
                                                    </tr>
                                                    <RankRow {...myRank} isMyRank={true} isTop3={false} />
                                                </>
                                            )}
                                        <tr className={S["leaderboard-pagination"]}>
                                            <td colSpan="5">
                                                <Pagination
                                                    items={items}
                                                    page={page}
                                                    pageName="leaderboard"
                                                    handlePaginationBack={handlePaginationBack}
                                                    handlePaginationNext={handlePaginationNext}
                                                />
                                            </td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                    ) : (
                        <span className={S["alt-message"]}>{pageData?.leaderboardSection?.altMessage ?? "No data available"}</span>
                    )}
                </div>
            </SkeletonTheme>
        </div>
    );
};

const RankRow = ({ rank, user, group, region, value, averageScore, isMyRank, isTop3 }) => {
    const isUser = user !== undefined;
    let displayName;
    if (isUser) {
        if (user.firstName && user.lastName) {
            displayName = `${user.firstName} ${user.lastName}`;
        } else if (user.firstName) {
            displayName = user.firstName;
        } else if (user.lastName) {
            displayName = user.lastName;
        } else {
            displayName = user.id;
        }
    } else {
        displayName = group?.shortName;
    }

    return (
        <tr className={`${isMyRank ? S["my-rank"] : ""} ${isTop3 ? S["top-three"] : ""}`}>
            <td>{rank ? <div>{rank}</div> : <Skeleton height={40} />}</td>
            <td>{displayName ?? <Skeleton height={40} />}</td>
            <td className={S["not-shown-on-mobile"]}>{region ?? <Skeleton height={40} />}</td>
            <td>{value ?? <Skeleton height={40} />}</td>
            <td className={S["not-shown-on-mobile"]}>{averageScore ?? <Skeleton height={40} />}</td>
        </tr>
    );
};

export default Leaderboard;
