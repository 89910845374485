import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import '../../styles/css/sectionHeader.css';


const SectionHeader = (props) => {
  let text;

  if (props.id) {
    text = (<FormattedMessage id={props.id} defaultMessage="Section Header Title"/>);
  } else {
    text = (props.children);
  }

  return (
    <div className="section-header-title" style={{ ...props.style }}>
      {text}
    </div>
  );
};

export default SectionHeader;

SectionHeader.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
  id: PropTypes.string,
};
