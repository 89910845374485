import { GlobalAnalytics } from "../../../analytics";

const analytics = new GlobalAnalytics();

export function toggleLargeImages(event){
    const actionType = event?.isExpand ? 'Expand' : 'Close';

    analytics.fireOnClick({
        tag: "29.2",
        section: "Product",
        page: event?.page,
        category : 'Trim Results',
        label: `${actionType} Larger Images​`,
        modelName: event?.modelName,
        modelYear: event?.modelYear,
        trim: event?.trim
    })
}

export function colorClick(event){
    analytics.fireOnClick({
        tag: "29.2",
        section: "Product",
        page: event?.page,
        category : 'Trim Results',
        label: `${event?.resourceType} Click`,
        contentTitle: event?.resourceValue,
        modelName: event?.modelName,
        modelYear: event?.modelYear,
        trim: event?.trim
    })
}

export function largeModalButtonClick(event){
    analytics.fireOnClick({
        tag: "29.2",
        section: "Product",
        page: event?.page,
        category : 'Colors Large Modal​',
        label: `${event?.label} Click`,
        modelName: event?.modelName,
        modelYear: event?.modelYear,
        trim: event?.trim
    })
}