import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import '../../../styles/css/topNav/headerComponents/avatar.css';
import ProfilePicture from '../../../components/atoms/profilePicture';
import { TooltipV2 } from '../../../components/molecules/tooltipV2';
import { isDesktopView } from '../../utils/getScreenSize';
import { generalNavButtonClicked } from '../topNav.analytics';
import NitroStats from "../../../components/molecules/nitroStats";

const avatar = (props) => {
  // not using PopupTooltipContext since it falls outside of context's scope and would cause unecessary re-renders
  const [displayPopup, setDisplayPopup] = useState(false);
  const [userName, setUserName] = useState();

  useEffect(() => {
    if (localStorage.firstName && localStorage.lastName) {
      setUserName(`${localStorage.firstName} ${localStorage.lastName}`);
    }
  }, [localStorage.firstName, localStorage.lastName]);

  return (
    <div
      className="avatar-container"
      onMouseEnter={() => userName && setDisplayPopup(true)}
      onMouseLeave={() => setDisplayPopup(false)}
      onClick={() => generalNavButtonClicked(props.analytics)}
    >
      <a
        target="_parent"
        href={props.profileUrl}
      >
        <ProfilePicture small />
      </a>
      {isDesktopView(window.innerWidth) && displayPopup && (
        <div className="avatarHoverPopup">
          <TooltipV2
            title={userName}
            subtitle={<NitroStats />}
            analyticsOpenTooltip={() => {}}
          />
        </div>
      )}
    </div>
  );
};

avatar.propTypes = {
  profileUrl: PropTypes.string,
  userImage: PropTypes.string,
};

export default avatar;
