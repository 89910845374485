import { Component } from 'react';
import { VehicleOverviewTemplate } from './vehicleOverview.tpl';
import getVehicleOverview from '../../services/vehicleOverviewService';
import AppSettings from '../../appsettings.js';
import { GlobalAnalytics } from '../../analytics';
import { getLocale } from '../../services/languageTranslationService.js';
import { postUserActivityRecord } from '../../services/userActivityService.js';
import { getDynamoName, getS3PathName } from '../utils/vehicleName';

const analytics = new GlobalAnalytics();

export default class VehicleOverviewComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      header: {},
      sections: [],
      awards: [],
      displaySections: [],
      locale: getLocale(),
      s3Path: '',
      languageOverride: false,
      displayOverrideText: false,
      width: '',
    };


    this.loadVehicleOverview = this.loadVehicleOverview.bind(this);
    this.convertTextPosition = this.convertTextPosition.bind(this);
    this.toggleCollapsedSections = this.toggleCollapsedSections.bind(this);
    this.awardsClick = this.awardsClick.bind(this);
    this.newForClick = this.newForClick.bind(this);
    this.salesStoryClick = this.salesStoryClick.bind(this);
    this.getS3Path = this.getS3Path.bind(this);
  }

  setAllDisplaySectionsToFalse(response) {
    const displaySections = new Array(response.sections[response.sections.length - 1].collapseGroup);
    for (let i = 0; i < displaySections.length; i++) {
      displaySections[i] = false;
    }
    return displaySections;
  }

  async getS3Path ({ getLanguage } = { getLanguage: false }) {
    const s3PathName = await getS3PathName(getDynamoName(this.props.params.model));
    const language = getLanguage ? getLocale() : 'en';
    const year = this.props.params.year;
    this.setState({
      s3Path: `${year}/${s3PathName}/${language}/`,
    });
  }

  // Separate service calls into local functions for testing purposes
  loadVehicleOverview(modelName, modelYear, mockedResponse) {
    getVehicleOverview(modelName, modelYear, mockedResponse)
      .then((response) => {
        if (Object.keys(response).length === 0 && response.constructor === Object) {
          this.setState({
            languageOverride: true,
            displayOverrideText: true,
          });
        } else {
          const displaySections = this.setAllDisplaySectionsToFalse(response);
          this.setState({
            header: {
              headline: response.header.headline,
              subheadline: response.header.subheadline,
              textPlacement: this.convertTextPosition(response.header.textPlacement),
              textBackground: response.header.textBackground,
              imageFilename: AppSettings.AWSImgRepo.vehicle + this.state.s3Path + response.header.imageFilename,
            },
            sections: response.sections,
            awards: response.awards,
            displaySections,
            languageOverride: false,
          });
        }
      }).catch((err) => {
        console.log(`ERROR: vehicleOverviewComponent.js:getPageData(): ${err}`);
      });
  }

  // Converting text position for the header
  convertTextPosition(placement) {
    // Setting default values
    const convertedPlacement = {
      textAlignment: 'left',
      columns: 'small-12 large-6 columns',
    };
    if (placement === 'Top Center') {
      convertedPlacement.textAlignment = 'center';
      convertedPlacement.columns = 'small-12 large-6 large-offset-3 columns';
      return convertedPlacement;
    }
    if (placement === 'Top Right') {
      convertedPlacement.textAlignment = 'right';
      convertedPlacement.columns = 'small-12 large-6 large-offset-6 columns';
      return convertedPlacement;
    }
    if (placement === 'Bottom Center') {
      convertedPlacement.textAlignment = 'center';
      return convertedPlacement;
    }
    return convertedPlacement;
  }

  updateDisplaySections(index) {
    const { displaySections } = this.state;
    displaySections.push(false);

    this.setState({ displaySections });
  }

  toggleCollapsedSections(title, event) {
    const { displaySections } = this.state;
    let index = event.target.id;
    if (!displaySections[index]) {
      index = 0;
    }
    if (displaySections[index] === true) {
      displaySections[index] = false;
    } else {
      displaySections[index] = true;
    }

    this.setState({ displaySections });
  }

  awardsClick() {
    analytics.fireOnClick({

      tag: '29.2',
      modelName: this.props.route.model,
      page: 'Overview',
      modelYear: this.props.params.year,
      section: 'Product',
      contentTitle: ' ',
      mediaTitle: ' ',

      container: 'Module',
      module: 'Feed',
      category: 'Awards',
      label: 'Content link',
    });
  }

  // Function never called due to tpl logic
  newForClick() {
    analytics.fireOnClick({
      tag: '29.2',
      modelName: this.props.route.model,
      page: 'Overview',
      modelYear: this.props.params.year,
      section: 'Product',
      contentTitle: ' ',
      mediaTitle: ' ',

      container: 'Module',
      module: 'Feed',
      category: 'New for 2018',
      label: 'Content link',
    });
  }

  salesStoryClick() {
    analytics.fireOnClick({
      tag: '29.2',
      modelName: this.props.route.model,
      page: 'Overview',
      modelYear: this.props.params.year,
      section: 'Product',
      contentTitle: ' ',
      mediaTitle: ' ',

      container: 'Module',
      module: 'Feed',
      category: 'Sales Story',
      label: 'Content link',
    });
  }

  componentDidMount() {
    this.getS3Path({ getLanguage: true });
    postUserActivityRecord();
    this.updateDimensions();
    getVehicleOverview(this.props.route.model, this.props.params.year, false)
      .then((response) => {
        if (Object.keys(response).length === 0 && response.constructor === Object) {
          this.setState({
            languageOverride: true,
            displayOverrideText: true,
          });
        } else {
          const displaySections = this.setAllDisplaySectionsToFalse(response);
          this.setState(() => ({
            header: response.header,
            sections: response.sections,
            awards: response.awards,
            displaySections,
            languageOverride: false,
          }));
        }
      });
    analytics.firePageLoad({
      tag: '29.1',
      modelName: this.props.route.model,
      modelYear: this.props.params.year,
      subsection: 'Featured Technology',

      section: 'Product',
      page: 'Overview',
    });
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  componentWillMount() {
    getVehicleOverview(this.props.route.model, this.props.params.year, false)
      .then((response) => {
        const displaySections = this.setAllDisplaySectionsToFalse(response);
        this.setState(() => ({
          header: response.header,
          sections: response.sections,
          awards: response.awards,
          displaySections,
        }));
      });
  }

  componentDidUpdate() {
    if (this.state.languageOverride) {
      getVehicleOverview(this.props.route.model, this.props.params.year, false, 'en')
        .then((response) => {
          const displaySections = this.setAllDisplaySectionsToFalse(response);
          this.setState(() => ({
            header: response.header,
            sections: response.sections,
            awards: response.awards,
            displaySections,
            languageOverride: false,
          }));
          this.getS3Path();
        });
    } else if (this.state.displayOverrideText && getLocale() === 'en') {
      this.setState({ displayOverrideText: false });
    }
  }

  render() {
    if (this.state.locale !== getLocale()) {
      getVehicleOverview(this.props.route.model, this.props.params.year, false)
        .then((response) => {
          if (Object.keys(response).length === 0 && response.constructor === Object) {
            this.setState({
              languageOverride: true,
              displayOverrideText: true,
            });
          } else {
            const displaySections = this.setAllDisplaySectionsToFalse(response);
            this.setState(() => ({
              header: response.header,
              sections: response.sections,
              awards: response.awards,
              displaySections,
              locale: getLocale(),
              languageOverride: false,
            }));
          }
        });
    }

    return VehicleOverviewTemplate(this);
  }
}
