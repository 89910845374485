import React, { useEffect, useRef } from 'react';
import PageHeader from '../../../components/molecules/marketingPage/pageHeader';
import SectionHeader from '../../../components/molecules/marketingPage/sectionHeader';
import CardGroupSection from '../../../components/molecules/marketingPage/cardGroupSection';
import { FiveCategory } from '../../../components/molecules/marketingPage/fiveCategory'
import applyMarkdown from '../../utils/applyMarkdown';
import { appPageLoad, scrollButtonClick, whatIsTheApp, appleIconClick, googleIconClick, appleDownloadClick, googleDownloadClick } from '../appPage-analytics';
import '../../../styles/css/appPage-overview.css';

function AppPageOverviewComponent(props) {
    const ref0 = useRef();
    const ref1 = useRef();
    const ref2 = useRef();
    const ref3 = useRef();
    const quickLinkRefs = [ref0, ref1, ref2, ref3];
    const pageLoadAnalytics = props.analyticsData && props.analyticsData.pageLoad;
    const clickAnalytics = props.analyticsData && props.analyticsData.clickAnalytics;
    // Pull the section header, quick link button datas, and section data out of overviewData, then sort the sections by sectionId
    const pageHeader = props.overviewData?.pageHeader;
    const quickLinkButtonData = props.overviewData?.pageHeader?.quickLinks;
    const sections = props.overviewData?.sections;
    sections.sort((a, b) => (parseInt(a.sectionId) - parseInt(b.sectionId)));
    
    useEffect(()=>{
        appPageLoad(pageLoadAnalytics, "Overview");
    }, []);

    function handleScrollClick(index) {
        switch (index) {
            case 0:
                ref0.current.scrollIntoView({ behavior: "smooth" });
                break;
            case 1:
                ref1.current.scrollIntoView({ behavior: "smooth" });
                break;
            case 2:
                ref2.current.scrollIntoView({ behavior: "smooth" });
                break;
            case 3:
                ref3.current.scrollIntoView({ behavior: "smooth" });
                break;
        }
    }

    function formatTitle(buttonTitle) {
        // on desktop, remove line breaks
        if (props.device === 'desktop') {
            return applyMarkdown(buttonTitle.replace('<br/>', '').replace('<br>', '').replace('<br />', ''));
        }
        // on tablet & mobile, render line breaks as marked
        return applyMarkdown(buttonTitle);
    }

    function renderButtons(buttonData) {
        const handleButtonClick = (scrollItem, isScrollButton, index) => {
            scrollButtonClick(clickAnalytics, scrollItem.title.replace('<br>',''));
            isScrollButton && handleScrollClick(index);
        };

        const buttons = buttonData?.map((item, index) => {
            // if the button points to a section on the page, return a button with handleScrollClick to the sectionRef
            if (item.samePageNavigation) {
                return (
                    <button
                        className={`marketing-scroll-button ${props.device}`}
                        key={index}
                        onClick={() => handleButtonClick(item, true, index)}
                    >
                        {formatTitle(item.title)}
                    </button>
                );
            }
            // otherwise, return a button within a link pointing to the href
            return (
                <a
                    key={index}
                    onClick={() => handleButtonClick(item, false, index)}
                    href={item.href}
                    target={item.href.includes('engage.toyota.com') ? '' : '_blank'}
                >
                    <button className={`marketing-scroll-button ${props.device}`} key={item.title}>
                        {formatTitle(item.title)}
                    </button>
                </a>
            );
        })

        return buttons;
    }

    function renderComponent(section, index) {
        const refIndex = quickLinkButtonData?.findIndex(item => item.sectionRef === section.sectionId);
        const sectionRef = quickLinkRefs[refIndex];
        const componentProps = {
            imagePath: props.imagePath,
            device: props.device,
        };

        switch (section?.type) {
            case 'SectionHeader':
                return (
                    <React.Fragment key={index}>
                        {sectionRef ? <div className={`quick-link-${refIndex}`} ref={sectionRef} /> : null}
                        <SectionHeader
                            {...componentProps}
                            title={section.title}
                            redline={section.redline}
                            description={section.description}
                            tagline={section.tagline}
                            backgroundImage={section.backgroundImage}
                            backgroundHeight={section.backgroundHeight}
                            nonBackgroundImage={section.nonBackgroundImage}
                            heroImage={section.heroImage}
                            key={section.sectionId}
                        />
                    </React.Fragment>
                );
            case 'CardGroupSection':
                return (
                    <React.Fragment key={index}>
                        {sectionRef ? <div className={`quick-link-${refIndex}`} ref={sectionRef} /> : null}
                        <CardGroupSection
                            {...componentProps}
                            clickAnalytics = {clickAnalytics}
                            clickFunctions = {{appleIconClick, googleIconClick, appleDownloadClick, googleDownloadClick}}
                            sectionId={section.sectionId}
                            title={section.title}
                            cardGroups={section.cardGroups}
                            footnote={section.footnote}
                            key={section.sectionId}
                            titleType = "leftSemiBold"
                            pageName = {props.pageName}
                        />
                    </React.Fragment>
                );
            case 'FiveCategory':
                return (
                    <React.Fragment key={index}>
                        {sectionRef ? <div className={`quick-link-${refIndex}`} ref={sectionRef} /> : null}
                        <FiveCategory
                            {...componentProps}
                            clickAnalytics={clickAnalytics}
                            clickFunction={whatIsTheApp}
                            sectionId={section.sectionId}
                            tabs={section.details}
                            title={section.title}
                            key={section.sectionId}
                        />
                    </React.Fragment>
                );
            default: return;
        }
    }

    return (
        <div className="app-page-overview">
            <PageHeader
                videoAnalytics = {props.analyticsData && props.analyticsData.videoAnalytics && props.analyticsData.videoAnalytics.introVideo}
                title={pageHeader.title}
                scrollButtons={renderButtons(quickLinkButtonData)}
                imagePath={pageHeader.imagePath}
                heroGif={pageHeader.heroGif}
                heroVideoId={pageHeader.heroVideoId}
                device={props.device}
            />
            {sections.map((section, index) => renderComponent(section, index))}
        </div>
    );
}

export default AppPageOverviewComponent;