import React, { useEffect, useState } from 'react';
import { cloneObject } from '../../utils/helpers';
import { Accordion } from '../../../components/molecules/Accordion';
import '../../../styles/css/towingPayload-accordion.css'
import AppSettings from '../../../appsettings.js';
import { menuExpandCollapseAllClick, menuExpandCollapseClick } from '../towingPayload-analytics';

export const TowingPayloadAccordion = (props) => {
    const [accordionContent, setAccordionContent] = useState(props.data);
    const [device, setDevice] = useState('mobile');

    const imagePath = AppSettings.AWSImgRepo.towingPayload;

    useEffect(() => {
        updateAccordionContent(props.searchParam);
    }, [props.searchParam]);

    useEffect(() => {
        updateDevice();
        window.addEventListener("resize", updateDevice);
        return () => window.removeEventListener("resize", updateDevice);
      }, []);
    
      const updateDevice = () => {
        if (window.innerWidth >= 1320) {
          setDevice('desktop');
        } else if (window.innerWidth >= 768) {
          setDevice('tablet');
        } else if (window.innerWidth < 768) {
          setDevice('mobile');
        }
      }

    const getAccordionClass = () => {
        switch (props.accordionType) {
            case 'FAQs':
                return 'faqAccordionContent';
            case 'Towing Definitions':
                return 'towingDefinitionsAccordionContent';
            case 'Common Weights':
                return 'commonWeightsAccordionContent';
            default:
                return '';
        };
    }

    const handleClick = (term, isExpand, headerTitle) => {
        props.clickAnalytics && menuExpandCollapseClick(props.clickAnalytics, props.vehicleSelection, props.trimSelection, props.accordionType, isExpand, term, headerTitle, props.page);
    };
    
    const toggleAllClick = (isExpand, headerTitle) => {
        props.clickAnalytics && menuExpandCollapseAllClick(props.clickAnalytics, props.vehicleSelection, props.trimSelection, props.accordionType, isExpand, headerTitle, props.page);
    };

    const scanListSearchParam = (list, searchParam) => (
        list.filter(i => i != null)
            .map(i => i.toLowerCase().includes(searchParam))
            .every(i => i == false)
    );

    const scanItems = (items, searchParam) => {
        switch (props.accordionType) {
            case 'FAQs':
                return items.map(obj => ({
                    ...obj,
                    content: obj.content.filter(i => !scanListSearchParam([i.question, i.answer], searchParam))
                })).filter(obj => obj.content.length > 0);
            case 'Towing Definitions':
                return items.map(obj => ({
                    ...obj,
                    content: obj.content.filter(i => !scanListSearchParam([i.term, i.definition, i.disclaimer], searchParam))
                })).filter(obj => obj.content.length > 0);
            case 'Common Weights':
                return items.map(obj => ({
                    ...obj,
                    subHeaders: obj.subHeaders.map(i => ({
                        ...i,
                        content: i.content.filter(j => !scanListSearchParam([i.title, j.name, j.weight, j.trailerType], searchParam))
                    })).filter(i => i.content.length > 0)
                })).filter(obj => obj.subHeaders.length > 0);
            default:
                return [];
        };
    }

    const updateAccordionContent = (searchParam) => {
        let filteredContent = cloneObject(props.data);
        if (searchParam != "") {
            filteredContent = scanItems(filteredContent, searchParam.toLowerCase())
            setAccordionContent(filteredContent);
        }
        else {
            setAccordionContent(props.data);
        }
    };

    const getTowingDefinitionContent = (item) => (
        <>
        <div className='towing-definition-content'>
            <div className="towing-definition-text">{item.definition}
            {item.disclaimer && <div className="towing-definition-disclaimer">{item.disclaimer}</div>}</div>
            {getImageContent(item)}
            </div>
        </>
    );

    const getImageArray = (item) => {
        const array = [];

        item.image1 && array.push(item.image1);
        item.image2 && array.push(item.image2);
        item.image3 && array.push(item.image3);
        item.image4 && array.push(item.image4);

        return array;
    }

    const getImageContent = (item) => {
        const imageArray = getImageArray(item);

        return(
            imageArray && imageArray.length > 0 && (
            <div className="towing-definition-image-container" style={imageArray > 1 || device != 'desktop' ? { justifyContent: "flex-start" } : { justifyContent: "flex-end" }}>
                {imageArray.map((image) =>
            <div className="towing-definition-vehicleImage" key={image}>
                <img src={imagePath + image} />
            </div>)}
            </div>
      ));
    }

    const getCommonWeightContent = (data) => {
        let subHeaders = [...new Set(data.map(i => i.trailerType))];
        return subHeaders.map(item => (
            <div key={item} className="common-weights-section">
                {item && <div className="common-weights-subHeader-title">{item}</div>}
                {getCommonWeightDetails(data, item)}
            </div>
        ));
    }
    const getCommonWeightDetails = (data, header) => {
        let filteredData = data.filter(e => e.trailerType == header);
        return filteredData.map((item, index) => (
            <div key={item + index} className="common-weights-subsection">
                <div className="common-weights-name">{item.name}</div>
                <div className="common-weights-weightText">{item.weight}</div>
            </div>
        ));
    }

    const buildAccordionRowData = (items) => {
        switch (props.accordionType) {
            case 'FAQs':
                return items.content.map((item, index) => (
                    {
                        title: item.question,
                        body: <div className="faq-answer-text">{item.answer}</div>,
                        key: `${item.question}.${index}`
                    }
                ));
            case 'Towing Definitions':
                return items.content.map((item, index) => (
                    {
                      title: item.term,
                      body: getTowingDefinitionContent(item),
                      key: `${item.term}.${index}`
                    }
                ));
            case 'Common Weights':
                return items.subHeaders.map((item, index) => (
                    {
                      title: item.title,
                      body: getCommonWeightContent(item.content),
                      key: `${item.title}.${index}`
                    }
                ));
            default:
                return <></>;
        };
    }

    return accordionContent.map((item, index) => (
        <div key={item.title + index} id={`${item.title} accordion`}>
            <Accordion
                headerTitle={!props.hideHeader && item.title}
                expandCollapseAll={!props.hideHeader}
                rows={buildAccordionRowData(item)}
                className={getAccordionClass()}
                expandAllAnalytics={()=>toggleAllClick(true, item.title)}
                collapseAllAnalytics={()=>toggleAllClick(false, item.title)}
                termAnalyticsClick={(term, isExpand)=>handleClick(term, isExpand, item.title)}
            />
        </div>
    ));
}