import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();

const pageLoad = (tag, section, subsection, technologyName, page) => {
  analytics.firePageLoad({
    tag,
    section, 
    subsection,
    technologyName,
    page,
  });
};

export const overviewLoaded = (pageLoadAnalytics) => {
  pageLoadAnalytics && pageLoadAnalytics.overviewLoaded && pageLoad(
    pageLoadAnalytics.overviewLoaded.tagId,
    pageLoadAnalytics.overviewLoaded.section,
    pageLoadAnalytics.overviewLoaded.subsection,
    pageLoadAnalytics.overviewLoaded.technologyName,
    pageLoadAnalytics.overviewLoaded.page,
  );
};

const contentLinkClick = (tag, section, subsection, technologyName, page, category, positionTitle, contentTitle, action) => {
  analytics.fireOnClick({
    tag,
    section,
    subsection,
    technologyName,
    page,
    category,
    positionTitle,
    contentTitle,
    action,
  });
};

export const contactUsSubmit = (clickAnalytics) => {
  clickAnalytics && clickAnalytics.contactUsSubmit && contentLinkClick(
    clickAnalytics.contactUsSubmit.tagId,
    clickAnalytics.contactUsSubmit.section,
    clickAnalytics.contactUsSubmit.subsection,
    clickAnalytics.contactUsSubmit.technologyName,
    clickAnalytics.contactUsSubmit.page,
    clickAnalytics.contactUsSubmit.category,
    clickAnalytics.contactUsSubmit.positionTitle,
    clickAnalytics.contactUsSubmit.contentTitle,
    null,
  );
};

export const subheaderLinkClick = (clickAnalytics, contentTitle) => {
  clickAnalytics && clickAnalytics.subheader && contentTitle && contentLinkClick(
    clickAnalytics.subheader.tagId,
    clickAnalytics.subheader.section,
    clickAnalytics.subheader.subsection,
    clickAnalytics.subheader.technologyName,
    clickAnalytics.subheader.page,
    clickAnalytics.subheader.category,
    clickAnalytics.subheader.positionTitle,
    contentTitle,
    null,
  );
};

export const subheaderClick = (clickAnalytics, currentPage, destinationPage) => {
  clickAnalytics && clickAnalytics.subheader && destinationPage && contentLinkClick(
    clickAnalytics.subheader.tagId,
    clickAnalytics.subheader.section,
    clickAnalytics.subheader.subsection,
    clickAnalytics.subheader.technologyName,
    clickAnalytics.subheader.page,
    clickAnalytics.subheader.category,
    clickAnalytics.subheader.positionTitle,
    destinationPage,
    null,
  );
};


export const bookmarkClick = (clickAnalytics, route, isBookmarked) => {
  const bookmarkCategory = isBookmarked ? clickAnalytics.bookmark.unbookmarkCategory : clickAnalytics.bookmark.bookmarkCategory;
  
  clickAnalytics && clickAnalytics.bookmark && contentLinkClick(
    clickAnalytics.bookmark.tagId,
    clickAnalytics.bookmark.section,
    clickAnalytics.bookmark.subsection,
    null,
    clickAnalytics.bookmark.page,
    bookmarkCategory,
    null,
    null,
    clickAnalytics.bookmark.action
  );
};

export const contactUsSubLoad = (pageLoadAnalytics) => {
  pageLoadAnalytics && pageLoadAnalytics.contactUsSubLoad && contentLinkClick(
    pageLoadAnalytics.contactUsSubLoad.tagId,
    pageLoadAnalytics.contactUsSubLoad.section,
    pageLoadAnalytics.contactUsSubLoad.subsection,
    pageLoadAnalytics.contactUsSubLoad.technologyName,
    pageLoadAnalytics.contactUsSubLoad.page,
    pageLoadAnalytics.contactUsSubLoad.category,
    pageLoadAnalytics.contactUsSubLoad.positionTitle,
    pageLoadAnalytics.contactUsSubLoad.contentTitle,
    null,
  );
};
