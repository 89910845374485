import React, { useEffect, useState } from "react";
import "../../../styles/scss/resources-v2/side-by-side.scss";
import AppSettings from "../../../appsettings";
import Skeleton from 'react-loading-skeleton';

const SideBySideV2 = (props) => {

  const [selectedToyotaImage, setSelectedToyotaImage] = useState('');
  const [selectedCompetitorImage, setSelectedCompetitorImage] = useState('');

  useEffect(() => {
    setSelectedToyotaImage(`${props.imagePath}${props.selectedComparison?.headToHeadHeader?.toyotaImage}`)
  }, [props.imagePath, props.selectedComparison?.headToHeadHeader?.toyotaImage])

  useEffect(() => {
    setSelectedCompetitorImage(`${props.imagePath}${props.selectedComparison?.headToHeadHeader?.competitorImage}`)
  }, [props.imagePath, props.selectedComparison?.headToHeadHeader?.competitorImage])

  return (
    <div className="competitor-selection-v2">
      <div className="comparison-vehicle-container">
        <div className="comparison-image-container">
          {props.selectedComparison?.headToHeadHeader ?
            <img 
              src={selectedToyotaImage} 
              alt="Toyota Image" 
              onError={() =>
                setSelectedToyotaImage(
                  encodeURI(
                    AppSettings.AWSImgRepo.resources + 'en/image_coming_soon_placeholder.png'
                  ).toString()
                )
              }
            />
          : <Skeleton width={200} height={100} />}
        </div>
        <div className="comparison-title">
          <div className="comparison-year">{props.selectedComparison?.toyotaVehicle.year}</div>
          <div className="comparison-model">{props.selectedComparison?.toyotaVehicle.model}</div>
        </div>
        <div className="comparison-trim">
          {props.selectedComparison?.toyotaVehicle.trim.toUpperCase() || <Skeleton />}
        </div>
      </div>

      <div className="comparison-vehicle-container">
        <div className="comparison-image-container">
          {props.selectedComparison?.headToHeadHeader ? (
            <img 
              src={selectedCompetitorImage} 
              alt="Other Image"
              onError={() =>
                setSelectedCompetitorImage(
                  encodeURI(
                    AppSettings.AWSImgRepo.resources + 'en/image_coming_soon_placeholder.png'
                  ).toString()
                )
              }
            />
          ) : <Skeleton width={200} height={100} />}
        </div>
        <div className="comparison-title">
          <div className="comparison-year">{props.selectedComparison?.competitorVehicle.year}</div>
          <div className="comparison-model">{props.selectedComparison?.competitorVehicle.model}</div>
        </div>
        <div className="comparison-trim">
          {props.selectedComparison?.competitorVehicle.trim.toUpperCase() || <Skeleton />}
        </div>
      </div>
    </div>
  )
};

export default SideBySideV2;
