import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import AppSettings from '../../../appsettings';
import getDealership from '../../../services/dealershipsService';
import { postEmail } from '../../../services/emailService';
import '../../../styles/css/contactUs/contactUsForm/contactUsFormValues.css';
import { buttonClick, pageLoad } from '../contactUsAnalytics';
import ContactUsFormButtons from './contactUsFormButtons';
import ContactUsFormField from './contactUsFormField';
import ContactUsFormModal from './contactUsFormModal';

const ContactUsFormValues = (props) => {
    const [origDealership, setOrigDealership] = useState('');
    const [dealership, setDealership] = useState('');
    const [title, setTitle] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState(localStorage.email);
    const [questions, setQuestions] = useState('');
    const [sending, setSending] = useState(false);
    const [showValidations, setShowValidations] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const clickAnalytics = props.analytics?.clickAnalytics || {};
    const fullName = `${localStorage.firstName ? localStorage.firstName : ''} ${localStorage.lastName ? localStorage.lastName : ''}`

    useEffect(() => {
        getInitialDealership();
    }, []);

    const formComplete = !!(dealership && title && phone && email && questions);

    const getInitialDealership = async () => {
        const dealershipData = await getDealership(localStorage.dealer_code);
        if (dealershipData) {
            setDealership(dealershipData.dealerName);
            setOrigDealership(dealershipData.dealerName);
        }
    }

    const handleReset = () => {
        setDealership(origDealership);
        setTitle('');
        setPhone('');
        setEmail(localStorage.email);
        setQuestions('');
    }

    const openModal = () => {
        pageLoad(props.analytics.pageLoad.modalLoad);
        setModalOpen(true);
    }

    const handleSave = () => {
        if (formComplete) {
            setSending(true);
            const emailObject = {
                toFields: AppSettings.ContactEmail,
                message: getFormattedMessage(),
                subject: getFormattedSubject(),
                replyTo: email,
                senderFirstName: localStorage.firstName ? localStorage.firstName : '',
                senderLastName: localStorage.lastName ? localStorage.lastName : '',
            };
            sendEmail(emailObject);
        } else {
            setShowValidations(true);
            buttonClick(clickAnalytics, clickAnalytics.submitError);
        }
    }

    const sendEmail = async (emailObject) => {
        try {
            await postEmail(emailObject);
            buttonClick(clickAnalytics, clickAnalytics.submit);
            setModalMessage("We got your message. We'll get back to you soon!")
            openModal();
            setSending(false);
            setShowValidations(false);
            handleReset();
        } catch (error) {
            setModalMessage("Error sending message, please try again!")
            openModal();
            setSending(false);
            setShowValidations(false);
        };
    };

    const getFormattedSubject = () => {
        return `Question from ${title} ${fullName} at ${dealership}`
    }

    const getFormattedMessage = () => {
        return `From: ${fullName} \n\n
        Title at Dealership: ${title} \n\n
        Dealership: ${dealership} \n\n
        Phone Number: ${phone} \n\n
        Email Address: ${email} \n\n
        Questions: ${questions}`;
    }

    const handleCloseModal = () => {
        buttonClick(clickAnalytics, clickAnalytics.closeModal);
        setModalMessage('');
        setModalOpen(false)
    }

    return (
        <React.Fragment>
            <div className='contactUsFormFields'>
                <ContactUsFormField
                    name='DEALERSHIP: *'
                    value={dealership}
                    updateValue={setDealership}
                    sending={sending}
                    showValidations={showValidations}
                />
                <ContactUsFormField
                    name='TITLE AT DEALERSHIP: *'
                    value={title}
                    updateValue={setTitle}
                    sending={sending}
                    showValidations={showValidations}
                />
                <ContactUsFormField
                    name='PHONE NUMBER: *'
                    value={phone}
                    updateValue={setPhone}
                    sending={sending}
                    showValidations={showValidations}
                />
                <ContactUsFormField
                    name='EMAIL ADDRESS: *'
                    value={email}
                    updateValue={setEmail}
                    sending={sending}
                    showValidations={showValidations}
                />
                <ContactUsFormField
                    name='QUESTIONS: *'
                    value={questions}
                    updateValue={setQuestions}
                    sending={sending}
                    showValidations={showValidations}
                    multiline
                />
            </div>
            <div className='contactUsFormHelperText'>
                * Required field
            </div>
            <ContactUsFormButtons
                clickAnalytics={clickAnalytics}
                complete={formComplete}
                handleReset={handleReset}
                handleSave={handleSave}
                sending={sending}
            />
            {modalOpen &&
                <ContactUsFormModal
                    open={modalOpen}
                    message={modalMessage}
                    onClose={handleCloseModal}
                />
            }
        </React.Fragment>
    )
};

ContactUsFormValues.propTypes = {
    analytics: PropTypes.object,
    header: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string
};

export default ContactUsFormValues;