import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();

// Comments Analytics Click Functions

export const myAccountHideRepliesClick = (clickAnalytics, articleTitle) => {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        page: clickAnalytics.page,
        section: clickAnalytics.section,
        category: clickAnalytics.activity.category,
        label: clickAnalytics.activity.comments.hideRepliesLabel,
        positionTitle: articleTitle
    })
}

export const myAccountSeeMoreClick = (clickAnalytics, articleTitle) => {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        page: clickAnalytics.page,
        section: clickAnalytics.section,
        category: clickAnalytics.activity.category,
        label: clickAnalytics.activity.comments.seeMoreLabel,
        positionTitle: articleTitle
    })
}

export const myAccountSeeLessClick = (clickAnalytics, articleTitle) => {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        page: clickAnalytics.page,
        section: clickAnalytics.section,
        category: clickAnalytics.activity.category,
        label: clickAnalytics.activity.comments.seeLessLabel,
        positionTitle: articleTitle
    })
}

export const myAccountViewRepliesClick = (clickAnalytics, articleTitle) => {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        page: clickAnalytics.page,
        section: clickAnalytics.section,
        category: clickAnalytics.activity.category,
        label: clickAnalytics.activity.comments.viewRepliesLabel,
        positionTitle: articleTitle
    })
}