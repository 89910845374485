import React from "react";
import { isTabletView } from "../../utils/getScreenSize";
import "../../../styles/css/xp-progress-bar.css";

const XPProgressBar = (props) => {

    switch(props?.variant) {
        case 'missionCard':
        case 'tournamentCard':
            return (
                <div className={`xp-progress-bar ${props?.variant}`}>
                    {props.leftLabel && <div className="progress-bar-left-label">{props.leftLabel}</div>}
                    <div className="bar">
                        <div className="inner-bar" style={{width: `${props?.percent}%`}}></div>
                        <div className="percentage">{`${props?.percent}%`}</div>
                    </div>
                </div>
            );
        case 'dashboard':
            return (
                <div className={`xp-progress-bar ${props?.variant}`}>
                    <div className="left-info">
                        <div className="progress-bar-left-label">{props?.leftLabel}</div>
                    </div>
                    
                    <div className="bar">
                        <div className="inner-bar" style={{width: `${props?.percent}%`}}></div>
                    </div>
                    <div className="xp-info">{props?.rightLabel}</div>
                </div>
            )
        case 'missionPage':
            return (
                <div className={`xp-progress-bar ${props?.variant}`}>
                    <div className="top-labels">
                        <div className="progress-bar-left-label">{props.leftLabel}</div>
                        <div className="percentage">{`${props?.percent}%`}</div>
                    </div>
                    <div className="bar">
                        <div className="inner-bar" style={{width: `${props?.percent}%`, background: props?.gradient ? `linear-gradient(90deg, ${props.gradient[0]} 0%, ${props.gradient[1]} 100%) 0% 0%`: ''}}></div>
                    </div>
                </div>
            );
        case 'profilePage':
            if(isTabletView(window.innerWidth)) {
                return (
                    <div className={`xp-progress-bar ${props?.variant}`}>
                        <div className="top-info">
                            <div className="progress-bar-left-label">{props?.leftLabel}</div>
                            <div className="xp-info">{props?.rightLabel}</div>
                        </div>
                        
                        <div className="bar">
                            <div className="inner-bar" style={{width: `${props?.percent}%`}}></div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className={`xp-progress-bar ${props?.variant}`}>
                        <div className="left-info">
                            <div className="progress-bar-left-label">{props?.leftLabel}</div>
                        </div>
                        
                        <div className="bar">
                            <div className="inner-bar" style={{width: `${props?.percent}%`}}></div>
                        </div>
                        <div className="xp-info">{props?.rightLabel}</div>
                    </div>
                );
            }
        default:
            return null;
    }
};

export default XPProgressBar;
