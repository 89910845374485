/* eslint-disable no-nested-ternary */
import React from 'react';
import '../../styles/css/articleList.css';
import { FormattedMessage } from 'react-intl';
import { ClipLoader } from 'react-spinners';
import applyMarkdown from '../utils/applyMarkdown';
import { formatDate } from '../utils/formatDate';
import { FilterMenu } from '../filterMenu/filterMenu.component';
import Select from '../select/select.component';
import { articleSortOptions, getCategoryLocalId } from '../utils/getArticleCategories';
import ReactFreezeframe from 'react-freezeframe';
import { getArticleThumbnailPath, getDisplayCategory } from '../utils/articleThumbnailHelpers';

const formatArticleLink = (link) => {
  let formattedLink = link;
  if (link.indexOf('product') > 0) {
    const splitLink = link.split('/');
    splitLink[2] = splitLink[2].replace(' ', '');
    formattedLink = splitLink.join('/');
  }
  return formattedLink;
};

export function ArticleListTemplate(component) {
  const filteredArticleResults = component.state.displayedArticles && component.state.displayedArticles.map((resource, index) => {
    const articleLink = resource.link ? formatArticleLink(resource.link) : `/articles/${resource.id}`;
    const displayCategory = getDisplayCategory(resource);
    const resourceImage = getArticleThumbnailPath(resource, component.state.s3Path);
    const isGif = resourceImage.endsWith('.gif');
    const thumbnailImage = isGif ?
      <div className="thumbnail"><ReactFreezeframe alt="failed to load" src={resourceImage} /></div> :
      <img alt="failed to load" src={resourceImage} className="thumbnail" />;

    return (
      <div className="result-content" key={resource.id}>
      <a href={articleLink} target="_blank" onClick={() => component.props.resourceClick(resource)} rel="noreferrer">
          {thumbnailImage}
        </a>
        <div className="result-type">
          <FormattedMessage id={getCategoryLocalId(displayCategory)} />
        </div>
        <a href={articleLink} target="_blank" onClick={() => component.props.resourceClick(resource)} rel="noreferrer">
          <div className="article-title">{applyMarkdown(resource.title, component.disclaimer)}</div>
        </a>
        <div className="date">{formatDate(resource.createdDate)}</div>
      </div>
    );
  });

  const tabletFillerDiv = () => {
    const numArticles = component.state.displayedArticles ? component.state.displayedArticles.length : 0;
    const articlesInLastRow = numArticles % 3;
    const tabletWhiteSpace = articlesInLastRow === 0 ? articlesInLastRow : 3 - articlesInLastRow;
    const divs = [];
    for (let index = 0; index < tabletWhiteSpace; index++) {
      divs.push(<div key={`tablet-only ${index}`} className={'tablet-only result-content'}></div>);
    }
    return divs.map(div => div);
  };

  const desktopFillerDiv = () => {
    const numArticles = component.state.displayedArticles ? component.state.displayedArticles.length : 0;
    const articlesInLastRow = numArticles % 5;
    const desktopWhiteSpace = articlesInLastRow === 0 ? articlesInLastRow : 5 - articlesInLastRow;
    const divs = [];
    for (let index = 0; index < desktopWhiteSpace; index++) {
      divs.push(<div key={`desktop-only ${index}`} className={'desktop-only result-content'}></div>);
    }
    return divs.map(div => div);
  };

  const SortOptions = () => {
    const sortOptions = articleSortOptions();
    const options = sortOptions.map((option, index) => <FormattedMessage id={option.id} key={`${option.value}-${index}`} >
      {message => <option value={option.value}>{message}</option>}
    </FormattedMessage>);
    return (
      <div className="sort-container">
        <Select
          name="sort-options"
          value={component.state.selectedSortValue}
          onChange={component.onSortSelectionChange}
          customOptions={options}
        />
      </div>
    );
  };

  const loadMoreButton = () => (
    <div className="button moreButton" onClick={() => component.setDisplayedArticles()}>
      <FormattedMessage id="global.button.seeMore" />
    </div>
  );

  const desktopSpinner = () => (
    <React.Fragment>
      <div className={'filterLoadSpinner hide-for-small-only'}><ClipLoader color={'#EB0A1E'} size={50} /></div>
    </React.Fragment>
  );

  const loadingDisplay = () => (
    <React.Fragment>
      {component.props.recordType === 'category-previousmodelyearresources-en'
        ? <div className="loading-text"><FormattedMessage id="resources.archiveLoadingMessage" defaultMessage="Loading previous model year resources..." /></div>
        : null}
      {desktopSpinner()}
      <div className={'filterLoadSpinner show-for-small-only'}><ClipLoader color={'#EB0A1E'} size={20} /></div>
    </React.Fragment>
  );

  return (
    <div className="articleList">
      <div className="filter-sort-container">
        <div className="filter-container">
          <FilterMenu
            active={component.state.activeCategoryFilter}
            filters={component.state.categoryFilterOptions}
            filterClick={component.onCategoryFilterClick}
            displayArrow={false}
          />
        </div>
        <SortOptions />
      </div>
      <div className="list">
        {component.state.gettingArticles ? loadingDisplay() : filteredArticleResults}
        {tabletFillerDiv()}
        {desktopFillerDiv()}
      </div>
      <div className="loadMore">
        {!component.state.gettingArticles && component.state.displayedArticles.length !== component.state.filteredArticleResults.length && loadMoreButton()}
      </div>
    </div>
  );
}
