import React, { useEffect, useState } from 'react';
import getAnalytics from '../../services/analyticsService';
import getMisc from '../../services/miscService';
import { isDesktopView } from '../utils/getScreenSize';
import { pageLoad } from './contactUsAnalytics';
import ContactUsForm from './contactUsForm/contactUsForm';
import ContactUsMoreInfo from './contactUsMoreInfo/contactUsMoreInfo';
import MobileBackButton from '../../components/molecules/MobileBackButton';

const ContactUs = (props) => {
    const [contactData, setContactData] = useState({});
    const [contactAnalytics, setContactAnalytics] = useState();

    useEffect(() => {
        getContactUsData();
        getContactUsAnalytics();
    }, []);

    const getContactUsData = async () => {
        const contactUsRawData = await getMisc('ContactUs');
        if (contactUsRawData) {
            setContactData(contactUsRawData.items);
        }
    }

    const getContactUsAnalytics = async () => {
        const contactUsAnalyticsRawData = await getAnalytics('contactUs');
        setContactAnalytics(contactUsAnalyticsRawData);
        pageLoad(contactUsAnalyticsRawData.pageLoad.pageLoad);
    }

    return (
        <React.Fragment>
            {contactData.header && contactAnalytics &&
                <div className='contactUs'>
                    {
                        window.ReactNativeWebView &&
                        <MobileBackButton containerStyle={{
                            backgroundColor:"white",
                            padding: "12px",
                            position: 'absolute',
                            borderRadius: '50%',
                            top: '48px',
                            left: '12px',
                            display: 'flex',
                            placeContent:'center',
                            width: '12px',
                            height: '12px'
                        }} style={{top: '4px', left:'7px'}}/>
                    }
                    <ContactUsForm
                        analytics={contactAnalytics}
                        header={contactData.header}
                        description={contactData.description}
                        image={isDesktopView(window.innerWidth) ? contactData.image : contactData.mobileImage}
                    />
                    <ContactUsMoreInfo
                        moreInformation={contactData.moreInformation}
                        analytics={contactAnalytics}
                    />
                </div>
            }
        </React.Fragment>
    )
};

export default ContactUs;
