import { useRef } from "react";
import DisclaimersComponent from "../../disclaimers/disclaimers.component";
import applyMarkdown from "../../utils/applyMarkdown";
import "../../../styles/css/model-overview-v2/awards.css";

const Awards = (props) => {
  const disclaimerComponentRef = useRef();
  return (
    <>
      <div className="awards-wrapper">
        <div className="awards-container">
          <div className="header-section">
            <span>Awards</span>
            {props.imagePath && props.awardsIcon && (
              <div className="awards-icon">
                <img src={encodeURI(props.imagePath + props.awardsIcon)} />
              </div>
            )}
          </div>
          <div className="items-section">
            {props.awards?.map((award) => (
              <div className="award-item" key={award.subheadline}>
                {applyMarkdown(
                  award.subheadline,
                  disclaimerComponentRef.current
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <DisclaimersComponent
        template={`${props.year}_${props.model}_overview`}
        ref={disclaimerComponentRef}
        addBaseMSRPDisclaimer={true}
      />
    </>
  );
};

export default Awards;
