import { GlobalAnalytics } from "../../../analytics";
import { postCompetitiveAdvantagesAction } from '../../../services/nitroService';

const analytics = new GlobalAnalytics();

export function filterClick(event) {
  postCompetitiveAdvantagesAction(undefined, undefined, event?.modelName, event?.modelYear, false);
  analytics.fireOnClick({
    tag: "29.2",
    section: "Product",
    page: event?.page,
    category : 'Competitor Filter',
    label: 'Filter Click​',
    contentTitle: event?.contentTitle,
    modelName: event?.modelName,
    modelYear: event?.modelYear,
  })
}

export function videoPlayerClick(event) {
  analytics.fireOnClick({
    tag: "29.2",
    section: "Product",
    page: event?.page,
    category : 'Video Player',
    label: 'Video Player Click​',
    modelName: event?.modelName,
    modelYear: event?.modelYear,
  })
}