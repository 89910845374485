import PropTypes from 'prop-types';
import React from 'react';
import '../../../styles/css/contactUs/contactUsMoreInfo/contactUsMoreInfo.css';
import ContactUsMoreInfoTile from './contactUsMoreInfoTile';

const ContactUsMoreInfo = (props) => {
    return (
        <div className='contactUsMoreInfoParent'>
            <div className='contactUsMoreInfo' >
                <div className='contactUsMoreInfoHeader' >
                    {props.moreInformation.header}
                </div>
                <div className='contactUsMoreInfoDiv' >
                    {props.moreInformation.tiles.map(tile =>
                        <ContactUsMoreInfoTile
                            key={tile.text}
                            tile={tile}
                            analytics={props.analytics}
                        />
                    )}
                </div>
            </div>
        </div>
    )
};

ContactUsMoreInfo.propTypes = {
    analytics: PropTypes.object,
    moreInformation: PropTypes.object,
};

export default ContactUsMoreInfo;