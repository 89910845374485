import React, { useRef } from 'react';
import TitleText from '../../atoms/TitleText';
import applyMarkdown from '../../../screens/utils/applyMarkdown';
import '../../../styles/css/marketingPage/fiveCategory.css';

export const FiveCategory = (props) => {
    const menuRef = useRef();
    let isClicked = new Array(props.tabs.length).fill(false)
    isClicked[0] = true

    const [menuInfo, setMenuInfo] = React.useState(
        {
            groupTitle: props.tabs[0].groupTitle,
            title: props.tabs[0].cardData.title,
            description: props.tabs[0].cardData.description,
            stats: props.tabs[0].cardData.dataPoints,
            activeItems: isClicked,
            cards: props.tabs[0].cardData.cards
        }
    );

    const isNumberOrPercentage = (value) => {
        const numberPercentageRegex = /^\d*(.\d*)?\%?$/;
        return numberPercentageRegex.test(value);
    };

    const getNewState = (tabs, index) => {
        isClicked.fill(false)
        isClicked[index] = true
        let newState = {
            groupTitle: props.tabs[0].grourpTitle,
            title: tabs.cardData.title,
            description: tabs.cardData.description,
            stats: tabs.cardData.dataPoints,
            activeItems: isClicked,
            cards: tabs.cardData.cards
        }
        return newState;
    }

    const getItemClasses = (isActive) => {
        let menuClasses

        menuClasses = {
            imageActive: isActive ? "tab-menu-imageActive" : "tab-menu-imageInactive",
            imageInactive: isActive ? "tab-menu-imageInactive" : "tab-menu-imageActive",
            line: isActive ? "tab-menu-lineActive" : "tab-menu-lineInactive",
            imageTitle: isActive ? "tab-menu-imageTitleActive" : "tab-menu-imageTitleInactive"
        }
        return menuClasses
    }

    const handleMenuHeaderClick = (tab, index) => {
        setMenuInfo(getNewState(tab, index));
        props.clickAnalytics && props.clickFunction(props.clickAnalytics, tab.groupTitle)
    };

    const menuHeader = props.tabs.map((tab, index) => (
        <div className='tab-menu' key={index} onClick={() => handleMenuHeaderClick(tab, index)}>
            <div className='tab-menu-header'>
                <div className="tab-menu-header-icon-container">    
                    <div className={getItemClasses(menuInfo.activeItems[index]).imageInactive}>{<img src={props.imagePath + tab.inactiveIcon} />}</div>
                    <div className={getItemClasses(menuInfo.activeItems[index]).imageActive}>{<img src={props.imagePath + tab.activeIcon} />}</div>
                </div>
                <div className={getItemClasses(menuInfo.activeItems[index]).line}></div>
                <div className={getItemClasses(menuInfo.activeItems[index]).imageTitle}>{applyMarkdown(tab.groupTitle)}</div>
            </div>
        </div>
    ));

    const removeNullStats = menuInfo.stats && menuInfo.stats.filter(stat => { return stat.description != null });

    const stats = removeNullStats && removeNullStats.map((stat, index) => {
        let statline;

        if (isNumberOrPercentage(stat.value)) {
            statline = (
                <div className="tab-menu-stats" key={index}>
                    <div className='tab-menu-stats-iconStats'>
                        <div className='tab-menu-stats-icon'><img src={props.imagePath + stat.icon} /></div>
                        <div className="tab-menu-stats-text">{stat.value}</div>
                    </div>
                    <div className="tab-menu-blackLine"></div>
                    <div className="tab-menu-stats-description">{applyMarkdown(stat.description)}</div>
                </div>
            );
        } else {
            statline = (
                <ul className="tab-menu-stats-bullet" key={index}>
                    <li className="tab-menu-stats-bullet-text">
                        <span className="tab-menu-stats-bullet-title">{stat.value && `${stat.value} - `}</span>{applyMarkdown(stat.description)}
                    </li>
                </ul>
            );
        }

        return statline;
    });

    const cards = (
            <div className='tab-menu-cards'>
                {menuInfo.cards?.map((card, index) => {
                    return(
                    <div className='tab-menu-card' key={index}>
                        <div className='tab-menu-card-image'><img src={props.imagePath + card.image}/></div>
                        <div className='tab-menu-card-description'
                        >{card.description}</div>
                    </div>);
                })}
            </div>
    );
    

    const menuContent = (
        <div className="tab-menu-subSection">
            <div>
                <div className="tab-menu-subSection-title">{menuInfo.title}</div>
                {menuInfo.description && <div className="tab-menu-subSection-description">{applyMarkdown(menuInfo.description)}</div>}
                <div className="tab-menu-footer">{menuInfo.stats && stats}{menuInfo.cards && cards}</div>
            </div>
        </div>
    );

    return (
        <>
            {props.title ?
                <TitleText className={props.device}>{applyMarkdown(props.title)}</TitleText>
                : null
            }
            <div ref={menuRef} className="tab-menu-container">
                <div className="tab-menu-container-alignment">
                    {menuHeader}
                </div>
                {menuContent}
            </div>
        </>
    );
}

export default FiveCategory;
