import React from 'react';
import CardGroup from './cardGroup';
import applyMarkdown from '../../../screens/utils/applyMarkdown';

import '../../../styles/css/marketingPage/cardGroupSection.css'
import { videoAnalytics } from './marketingPage-analytics';

function CardGroupSection(props) {

    return (
        <div className={`card-group-section ${props.device}`}>
            {props.title &&
                <div className={`section-title ${props.device}`}>
                    {applyMarkdown(props.title)}
                </div>
            }
            <div className={`card-groups-container ${props.device}`}>
                {props.cardGroups?.map((group, index) => (
                    <CardGroup
                        key={index}
                        {... group}
                        device={props.device}
                        imagePath={props.imagePath}
                        titleType={props.titleType}
                        clickAnalytics={props.clickAnalytics}
                        clickFunctions={props.clickFunctions}
                        videoAnalytics={videoAnalytics}
                        pageName={props.pageName}
                        loadMore={props.loadMore}
                        loadMoreAnalytics={props.loadMoreAnalytics}
                    />
                ))}
            </div>
            {props.footnote && 
                <div className={`section-footnote ${props.device}`}>
                    {applyMarkdown(props.footnote)}
                </div>
            }
        </div>
    );
}

export default CardGroupSection;