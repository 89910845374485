import 'whatwg-fetch';
import httpOption from './httpOption';
import AppSettings from '../appsettings';

export function getAnnouncements() {
    const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/notifications/getAnnouncements`;
    const options = httpOption.GET();
    return new Promise((resolve, reject) => {
        fetch(url, options)
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return resolve(response.json());
                }
                return reject(response);
            });
    });
}

export function getOutageAlerts() {
    const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/notifications/getOutageAlerts`;
    const options = httpOption.GET();
    return new Promise((resolve, reject) => {
        fetch(url, options)
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return resolve(response.json());
                }
                return reject(response);
            });
    });
}

export function dismissOutageAlert(alertId) {
    const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/notifications/dismissOutageAlert`;
    const options = httpOption.POST();
    options.body = JSON.stringify({
        alertId
    });
    return new Promise((resolve, reject) => {
        fetch(url, options)
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return resolve(response.json());
                }
                return reject(response);
            });
    });
}

export function readAnnouncement(announcementId) {
    const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/notifications/readAnnouncement`;
    const options = httpOption.POST();
    options.body = JSON.stringify({
        announcementId
    });
    return new Promise((resolve, reject) => {
        fetch(url, options)
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return resolve(response.json());
                }
                return reject(response);
            });
    });
}

export function readAnnouncements(announcementsList) {
    const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/notifications/readAnnouncements`;
    const options = httpOption.POST();
    options.body = JSON.stringify({
        announcementsList
    });
    return new Promise((resolve, reject) => {
        fetch(url, options)
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return resolve(response.json());
                }
                return reject(response);
            });
    });
}
