import React from 'react';
import SpecCategory from './specCategory.component';
import jatoDataBadge from '../../../resources/images/jatoDataBadge.png';

export function DataTableTemplate(component) {
  let categories = null;
  if (component.props.comparisonReport) {
    categories = component.props.comparisonReport.map((category, index) => <SpecCategory key={index} category={category}/>);
  } else {
    categories = <div className="loading-message">{component.props.loadingMessage}</div>;
  }

  return (
    <div className="data-table">
      <div className="clearfix">
        <div className="legend float-left">
          <span className="flag best" /><span>Best</span>
        </div>
        <div className="jato-data-badge float-right">
          <a href="https://www.jato.com/" target="_blank">
            <img src={jatoDataBadge} alt="" />
          </a>
        </div>
      </div>
      {categories}
    </div>
  );
}
