import { GlobalAnalytics } from "../../analytics";

const analytics = new GlobalAnalytics();

// Page Load
export const xpMissionDetailsPageLoad = (pageLoad, name) => {
  pageLoad &&
    analytics.firePageLoad({
      app: pageLoad.appId,
      tag: pageLoad.tagId,
      section: pageLoad.section,
      page: name,
      subsection: pageLoad.subsection,
      technologyName: pageLoad.technology_name,
    });
};

// Tracking click for a back button click
export const backButtonClick = (clickAnalytics, analyticsPage, analyticsCategory) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: analyticsPage,
      category: analyticsCategory,
      label: "Back Button"
    });
  }
};


// Tracking click for a faq button click
export const faqButtonClick = (clickAnalytics, analyticsPage, analyticsCategory) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: analyticsPage,
      category: analyticsCategory,
      label: "FAQs Link"
    });
  }
};

// Tracking click for a task button click
export const taskClick = (clickAnalytics, contentTitle) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: clickAnalytics.page || "Engage XP",
      category: "Missions Detail",
      label: clickAnalytics.label || "Mission Action Link",
      contentTitle: contentTitle
    });
  }
};


// Tracking click for back to mission widget button click
export const backToMissionClick = (clickAnalytics, contentTitle) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: clickAnalytics.page || "Engage XP",
      category: "Mission",
      label: "Back to Mission Widget",
      contentTitle: contentTitle
    });
  }
};