import React, { useEffect } from "react";
import Header from "./components/header";
import PowerOfChoice from "./components/powerOfChoice";
import BZVideoSection from "./components/BZVideoSection";
import CarbonEmissionReductions from "./components/carbonEmissionReductions";
import QuickLinks from "./components/quickLinks";
import DesignedForFuture from "./components/designedForFuture";
import { bzOverviewClick, bzOverviewPageload, bzOverviewTabsClick } from "./beyondZeroOverview.analytics";
import { postUserActivityRecord } from "../../../services/userActivityService";

const BeyondZeroOverview = ({ overviewData }) => {
    useEffect(() => {
        bzOverviewPageload({
            page: 'Overview',
        });
        postUserActivityRecord();
    }, [])

    return (
        <div className="bz-overview-page-content">
            <Header 
                data={overviewData?.header}
            />

            <PowerOfChoice 
                data={overviewData?.powerOfChoice}
                analyticsActions={{
                    onTabClick: bzOverviewTabsClick
                }}
            />

            <BZVideoSection 
                data={overviewData?.videos}
                analyticsActions={{
                    onTabClick: bzOverviewTabsClick
                }}
            />

            <DesignedForFuture
                data={overviewData?.designedForTheFuture}
                analyticsActions={{
                    onTabClick: bzOverviewTabsClick,
                    onClick: bzOverviewClick
                }}
            />

            <CarbonEmissionReductions 
                data={overviewData?.electrifiedDiversified}
            />

            <QuickLinks 
                data={overviewData?.quickLinks}
                analyticsActions={{
                    onTabClick: bzOverviewTabsClick,
                    onClick: bzOverviewClick
                }}
            />

        </div>
    )
};

export default BeyondZeroOverview;
