import { useState } from "react";
import { paginationButtonClick } from "../../../screens/engageXP/engageXPStats.analytics";

const usePagination = ({ items, pageSize = 10, analyticsData, pageName }) => {
  const [displayedItems, setDisplayedItems] = useState([]);
  const [page, setPage] = useState(1);

  const handlePaginationAnalytics = () => {
    if (analyticsData) {
      const clickAnalytics = analyticsData.clickAnalytics;
      const label = analyticsData.clickAnalytics?.label 
        ? analyticsData.clickAnalytics?.label 
        : pageName === "leaderboard" 
          ? pageName 
          : 'badges';

      paginationButtonClick(clickAnalytics, label);
    }
  }

  const handlePaginationBack = () => {
    if (page === 1) {
      return;
    }
    handlePaginationAnalytics();
    setPage(page - 1);

  };

  const handlePaginationNext = () => {
    if (page * pageSize > items.length) {
      return;
    }
    handlePaginationAnalytics();
    setPage(page + 1);
  };

  return {
    page,
    setPage,
    displayedItems,
    setDisplayedItems,
    handlePaginationBack,
    handlePaginationNext,
  };
};

export default usePagination;
