import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import '../../styles/css/dynamicLandingSection.css';
import DynamicTile from '../atoms/dynamic-landing-tile';

const dynamicLandingSection = (props) => {
  const fireTileClickAnalyticsInSection = tileTitle => {
    props.fireTileClickAnalytics(props.sectionTitle, tileTitle);
  }
  const cardClickFunction = () => {
    props.tileClickFunction  && props.tileClickFunction()
  }
  

  const tiles = props.tiles && props.tiles.map(tile => (
      <DynamicTile 
        awsImagePath={props.awsImagePath}
        fireTileClickAnalytics={fireTileClickAnalyticsInSection}
        description={tile.description}
        key={tile.title}
        image={tile.image}
        link={tile.link}
        onClickFunction={cardClickFunction}
        title={tile.title}
        smallTiles={props.smallTiles}
      ></DynamicTile>
    )
  );
  return (
    <React.Fragment>
      <div className="dynamic-landing-section" style={{
        backgroundColor: `${props.sectionStyles.backgroundColor ? props.sectionStyles.backgroundColor : '#FFFFFF'}`,
        color: `${props.sectionStyles.textColor ? props.sectionStyles.textColor : '#000000'}`,
      }}>
        <div className="dynamic-landing-section-content">
          {props.sectionTitle ? <div className="dynamic-landing-section-title">{props.sectionTitle} </div>: null}
          {props.description ? <div className="dynamic-landing-section-description">{props.description} </div> : null}
          <div className="dynamic-landing-tiles">
            {tiles}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

dynamicLandingSection.propTypes = {
  awsImagePath: PropTypes.string,
  fireTileClickAnalytics: PropTypes.func,
  tileClickFunction: PropTypes.func,
  sectionStyles: PropTypes.shape({
    backgroundColor: PropTypes.string,
    sectionOrder: PropTypes.string,
    textColor: PropTypes.string,
  }),
  sectionTitle: PropTypes.string,
  tiles: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.string,
    link: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
  })),
};

export default dynamicLandingSection;
