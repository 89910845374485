import { GlobalAnalytics } from "../../../analytics";

const analytics = new GlobalAnalytics();

export function articleFilterClick(event) {
    analytics.fireOnClick({
      tag: "29.2",
      section: "Product",
      page: event?.modelName,
      category : 'Resources',
      label: 'Tab Click​',
      positionTitle: 'Browse',
      contentTitle: event?.contentTitle,
      modelName: event?.modelName,
      modelYear: event?.modelYear,
    });
}

export function articleClick(event) {
    analytics.fireOnClick({
      tag: "29.2",
      section: "Product",
      page: event?.modelName,
      category : 'Resources',
      label: 'Tile Click​',
      positionTitle: event?.positionTitle,
      contentTitle: event?.contentTitle,
      modelName: event?.modelName,
      modelYear: event?.modelYear,
    })
}

export function seeMoreClick(event) {
    analytics.fireOnClick({
      tag: "29.2",
      section: "Product",
      page: event?.modelName,
      category : 'Resources',
      label: 'SEE MORE​',
      positionTitle: event?.positionTitle,
      modelName: event?.modelName,
      modelYear: event?.modelYear,
    });
}
  