import { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import MediaTextTemplate from './mediaText.tpl';

export class MediaTextComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTextExpanded: false,
    };
    this.toggleReadMore = this.toggleReadMore.bind(this);
  }

  shouldDisplayReadMore() {
    if (this.props.buttonText) {
      return false;
    }
    const descriptionText = this.props.description;
    let displayMore = false;
    if (descriptionText) {
      displayMore = (descriptionText.replace(/(<([^>]+)>)/g, '').length > 500);
    }
    return displayMore;
  }

  itemPlacementShouldAlternate() {
    return this.props.alternate;
  }

  itemHasLeftAlignedPlacement() {
    return (this.props.index % 2 > 0);
  }

  formatImageLink(imageFileName) {
    return this.props.imagePath + imageFileName;
  }

  toggleReadMore(buttonText) {
    const { isTextExpanded } = this.state;
    this.setState({
      isTextExpanded: !isTextExpanded,
    });
    this.props.readMoreClick(buttonText, this.props.title);
  }

  render() {
    return MediaTextTemplate(this);
  }
}

MediaTextComponent.propTypes = {
  readMoreClick: PropTypes.func,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  description: PropTypes.string,
  fileName: PropTypes.string,
  link: PropTypes.string,
  imagePath: PropTypes.string,
  videoId: PropTypes.string,
  qumuId: PropTypes.string,
  item: PropTypes.object,
  sendVideoEventAnalytics: PropTypes.func,
  alternate: PropTypes.bool,
  imageArrow: PropTypes.bool,
  index: PropTypes.number,
};
export default injectIntl(MediaTextComponent);
