import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/css/titleText.css';

const TitleText = (props) => {
  const StyleName = props.className || '';

  return (
    <div className={`title-text ${StyleName}`} style={{ ...props.style }}>
      {props.children}
    </div>
  );
};

export default TitleText;

TitleText.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  disclaimerParam: PropTypes.string,
};
