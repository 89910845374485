import React, { Component } from 'react';
import '../../styles/css/resources.css';
import { FormattedMessage } from 'react-intl';
import applyMarkdown from '../utils/applyMarkdown';
import { GlobalAnalytics } from '../../analytics';
import { getFilteredArticles } from '../../services/articlesService.js';
import { translateText } from '../utils/translateText';
import { getCategoryLocalId } from '../utils/getArticleCategories';

const analytics = new GlobalAnalytics();

export default class TopResourcesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resources: [],
      categoryName: `resources.${this.props.categoryName}`,
    };

    this.getTopResources = this.getTopResources.bind(this);
    this.resourcesClick = this.resourcesClick.bind(this);
  }

  getTopResources(categoryName) {
    return new Promise((resolve, reject) => {
      getFilteredArticles(categoryName, 3)
        .then((payload) => {
          this.setState({ resources: payload.Items });
          resolve(payload);
        }).catch((error) => {
          reject(error);
        });
    });
  }

  resourcesClick(mod, category, label, mediaTitle) {
    analytics.fireOnClick({
      tag: '30.2',
      container: 'Resources',
      page: 'Insights',
      section: 'Insights',
      module: mod,
      category,
      label,
      contentTitle: mediaTitle,
      mediaTitle,
    });
  }

  componentDidMount() {
    this.getTopResources(this.props.tag.toLowerCase());
  }

  render() {
    const resourceType = this.props.tag.split('-')[0];
    const categoryName = this.props.tag.split(/-(.+)/)[1];

    const resources = this.state.resources.length > 0 ? this.state.resources.map((resource, index) => {
      const resourceLink = resource.link ? (resource.link) : (`/articles/${resource.id}`);
      return (
                <div className="resource-item" key={index} onClick={() => this.resourcesClick(translateText(this.state.categoryName), 'Nav Link', resource.category, resource.title)}>
                    <a href={resourceLink} target='_blank'>
                        <span className="category-type"><FormattedMessage id={getCategoryLocalId(resource.category)} /></span><span> | </span>
                        <span className="article-title">{applyMarkdown(resource.title, null)}</span>
                    </a>
                </div>
      );
    }) : null;

    return (
            <div className="large-3 medium-6 small-12 columns wide">
                <div className="resource-box">
                    <div className={this.props.index === 0 ? '' : 'lineBreak-small'}></div>
                    <div className='resource-title'><FormattedMessage id={this.state.categoryName} /></div>
                    <a href={`/engageResources/${resourceType}/${categoryName}`} target='_blank'>
                        <img alt="" onClick={() => this.resourcesClick(translateText(this.state.categoryName), 'Nav Link', 'Category Click', 'Image Click')} src={encodeURI(this.props.categoryImage)} />
                    </a>
                    {resources}
                    <div className="view-more" onClick={() => this.resourcesClick(translateText(this.state.categoryName), 'Nav Link', 'View More Click', 'View More Click')}>
                        <a href={`/engageResources/${resourceType}/${categoryName}`} target='_blank'>
                            <FormattedMessage id="global.viewMore" />
                        </a>
                    </div>
                </div>
            </div>
    );
  }
}
