import AppSettings from "../../appsettings";
import '../../styles/css/checkRadio.css';

const s3Path = AppSettings.AWSImgRepo.resources;
const checkIcon = 'en/check_icon.svg';

const CheckRadio = (props) => {
    return <div className={`check-radio-wrapper ${props?.checked && 'checked'}`}>
        <img src={s3Path + checkIcon} />
    </div>
}

export default CheckRadio;
