import React from "react";
import "../../../../styles/scss/beyondZero/carbonEmissionReductions.scss";
import applyMarkdown from "../../../utils/applyMarkdown";
import {isMobileView, isTabletView} from "../../../utils/getScreenSize";
import appsettings from "../../../../appsettings";

const CarbonEmissionReductions = ({ data }) => {
    const isMobile = isMobileView(window.innerWidth);
    const isTablet = isTabletView(window.innerWidth);

    const Columns = () =>
        data.columns.map((column, index) => (
            <div className="cer-column" key={index}>
                <div className="cer-column-bevImg">
                    <img src={appsettings.AWSImgRepo.beyondZero + (isMobile ? column.columnMainImageMobile : isTablet ? column.columMainImageTablet : column.columnMainImageDesktop)} alt="BEV" />
                </div>
                <div className="cer-column-icon">
                    <img src={appsettings.AWSImgRepo.beyondZero + column.columnSecondaryImage} alt="BEV" />
                </div>
                <div className="cer-column-tonsAmount">{column.columnPrimaryText}</div>
                <div className="cer-column-details">{column.columnDescription}</div>
                <div className="cer-column-vehicleAmount">{column.columnSecondaryText}</div>
            </div>
        ));

    return (
        <div className="cer-container">
            <div className="cer-content">
                <div className="cer-title">{applyMarkdown(data.title)}</div>
                <div className="cer-description">{applyMarkdown(data.description)}</div>
                <div className="cer-columns">
                    <Columns />
                </div>
                <div className="cer-footer">{applyMarkdown(data.disclaimer)}</div>
            </div>
        </div>
    );
};

export default CarbonEmissionReductions;
