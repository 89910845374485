import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { WindowSize } from "../../components/contexts/windowSizeContext";
import { getArticles } from "../../services/articlesService";
import SubheaderV2 from "../subheaderV2/subheaderV2";
import ElectrifiedDiversified from "./electrifiedDiversified/electrifiedDiversified";
import { BeyondZeroResources } from "./resources/resources";
import getMisc from "../../services/miscService";
import BeyondZeroOverview from "./overview/beyondZeroOverview";
import EVMatchmaker from "./evMatchmaker/evMatchmaker";



const listOfTags = ['tag-hybrid-en','tag-electrification-en','tag-beyond zero-en','tag-battery ev-en','tag-fuel cell ev-en',
'tag-plug-in hybrid ev-en','tag-hybrid ev-en','tag-i-force max ev-en'];


const BeyondZero = () => {
    const params = useParams();
    const history = useHistory();
    const { device } = useContext(WindowSize);
    const [currentTab, setCurrentTab] = useState(null);
    const [beyondZeroArticles, setBeyondZeroArticles] = useState([]);
    const [beyondZeroRecommendedArticles, setBeyondZeroRecommendedArticles] = useState([]);
    const [beyondZeroData, setBeyondZeroData] = useState(null);

    const subheaderData =
        [
            { id: 'Overview', urlPath: 'overview', subcategory: 'Overview' },
            { id: 'Electrified Diversified', urlPath: 'electrified-diversified', subcategory: 'Electrified Diversified'},
            { id: 'EV Matchmaker', urlPath: 'ev-matchmaker', subcategory: 'EV Matchmaker'},
            { id: 'Resources', urlPath: 'resources', subcategory: 'Resources'}
        ];

    useEffect(() => {
        getMisc('beyondZero').then(data => {
            setBeyondZeroData(data?.items);
        }).catch(error => {
            console.log(`Error getting Beyond Zero Template Data: ${error}`);
        })
    }, []);

    const getUniqueItemsById = (array) => {
        const uniqueItemsMap = new Map();
        array.forEach(item => {
            if (!uniqueItemsMap.has(item.id)) {
                uniqueItemsMap.set(item.id, item);
            }
        });
        return Array.from(uniqueItemsMap.values());
    }

    useEffect(() => {
        const fetchBeyondZeroArticles = async () => {
            try {
                const req = listOfTags?.map(tag => getArticles(tag));
                Promise.all(req).then((data) => {
                    const items = data.map(list => list?.Items).flat();
                    const nonRepeatedItems = getUniqueItemsById(items);
                    nonRepeatedItems.sort((a,b) => new Date(b.createdDate) - new Date(a.createdDate));
                    setBeyondZeroArticles(nonRepeatedItems);
                    setBeyondZeroRecommendedArticles(nonRepeatedItems.filter((article) => article?.isRecommended === true));
                })
            } catch (error) {
                console.log(`Error: ${error}`);
            }
        } 
        fetchBeyondZeroArticles();
      }, []);    

    useEffect(() => {
        const currentPageData = subheaderData.find((item) => item.urlPath === params.page);
        if(currentPageData) {
            setCurrentTab(currentPageData);
        } else {
            history.replace("/hybrid/overview");
        }
    }, [params.page]);

    const getBZComponent = () => {
        switch (currentTab.urlPath) {
            case "overview":
                return beyondZeroData && (
                    <BeyondZeroOverview
                        overviewData={beyondZeroData?.overview} 
                    />
                );
            case "electrified-diversified":
                return beyondZeroData && (
                    <ElectrifiedDiversified
                        electrifiedDiversifiedData={beyondZeroData?.electrifiedDiversified}
                        electrifiedComparisonData={beyondZeroData?.electrifiedComparison}
                    />
                );
            case "ev-matchmaker":
                return beyondZeroData && (
                    <EVMatchmaker
                        evMatchmakerData={beyondZeroData?.evMatchmaker}
                        electrifiedComparisonData={beyondZeroData?.electrifiedComparison}
                    />
                );
            case "resources":
                return (
                    <BeyondZeroResources
                        articles={beyondZeroArticles}
                        recommendedArticles={beyondZeroRecommendedArticles}
                    />
                );
            default:
                return beyondZeroData && (
                    <BeyondZeroOverview
                        overviewData={beyondZeroData?.overview}
                    />
                );
        }
    };
    
  return (
    <div className="beyond-zero-page">
        <SubheaderV2
          title={'BEYOND ZERO'}
          baseUrl="/hybrid"
          device={device}
          tabs={subheaderData}
          mobileTabs={subheaderData}
          currentTab={currentTab}
          mobileVerticalMenu
          bookmarkable
        />
        <div className="beyond-zero-page-content">
            {currentTab && getBZComponent()}
        </div>
    </div>
  );
};

export default BeyondZero;
