import * as _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import getEntuneLineupData from "../../../services/entune/entuneLineupService";
import { getLocale } from "../../../services/languageTranslationService";
import getMisc from "../../../services/miscService";
import { postViewPageAction } from "../../../services/nitroService";
import SubheaderComponent from "../../subheader/subheader.component";
import AudioMultimediaFAQs from "../audioMultimediaFAQs/audioMultimediaFAQs";
import AudioMultimediaResourcesComponent from "../audioMultimediaResources/audioMultimediaResources.component";
import AudioMultimediaRegistrationComponent from "../audioMultimediaRegistration/audioMultimediaRegistration.component";
import AudioMultimediaOverview from "../audioMultimediaOverview/audioMultimediaOverview";
import Display404NotFound from "../../../components/atoms/404NotFound";
import * as analytics from "../audioMultimediaOverview.analytics";
import en from "../../../locales/en.json";
import getAnalytics from "../../../services/analyticsService";

const AudioMultimediaRouterComponent = (props) => {
  const [languageOverride, setLanguageOverride] = useState(false);
  const [lineupData, setLineupData] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [analyticsData, setAnalyticsData] = useState(null);
  const [resourcesFilter, setResourcesFilter] = useState(null);
  const history = useHistory();

  const validTabNames = ["overview", "registration", "faqs", "resources"];
  const isValidTabName = (name) =>
    validTabNames.filter((x) => x === name).length > 0;

  const params = useParams();
  const splat = params && params.page;

  const route = {
    engine: "",
    featSpec: undefined,
    model: "",
    path: params.page || "overview",
    trim: "",
    year: params.year,
  };

  useEffect(() => {
    postViewPageAction(window.location.href);
    getAnalyticsData();
    const language = getLocale();
    getPageData(language);
    if (languageOverride && !pageData) {
      getPageData("en");
    }
  }, []);

  const getPageData = (language) => {
    getMisc("amm", language)
      .then((p) => {
        if (Object.keys(p).length === 0 && pageData.constructor === Object) {
          setLanguageOverride(true);
        } else {
          getEntuneLineupData(language)
            .then((l) => {
              setPageData(p);
              setLineupData(l);
            })
            .catch((error) => {
              console.log(
                `ERROR: failed to load audio year model trim data. ${error}`
              );
            });
        }
      })
      .catch((error) => {
        console.log(`ERROR: failed to load audio page data. ${error}`);
      });
  };

  const getAnalyticsData = () =>
    new Promise((resolve, reject) => {
      getAnalytics("audioMultimediaLanding")
        .then((analyticsData) => {
          resolve(analyticsData);
          setAnalyticsData({ ...analyticsData });
        })
        .catch((error) => {
          reject(error);
        });
    });

  const getSubheaderChildComponent = (path) => {
    switch (path ? path.toLowerCase() : "") {
      case "overview":
        return (
          <AudioMultimediaOverview
            lineupData={lineupData}
            entuneData={pageData.items.vehicleApplicabilityCard}
            pageData={pageData}
            setResourcesFilter={setResourcesFilter}
          />
        );
      case "registration":
        return (
          <AudioMultimediaRegistrationComponent
            registrationData={pageData.items.toyotaApp}
          />
        );
      case "faqs":
        return (
          <AudioMultimediaFAQs
            data={{ faqs: pageData.items.faqs }}
            languageOverride={languageOverride}
          />
        );
      case "resources":
        return <AudioMultimediaResourcesComponent filter={resourcesFilter} />;
      default:
        history.push("/audioMultimedia/overview");
    }
  };

  const getPageDisplay = () => {
    if (!params.page) {
      history.push("/audioMultimedia/overview");
    }

    if (isValidTabName(splat)) {
      let sourcePage =
        splat == "resources"
          ? en["subheader.audioResources"]
          : splat == "registration"
          ? en["subheader.connectedServicesRegistration"]
          : en[`subheader.${splat}`];

      return (
        <div>
          {pageData && lineupData && (
            <SubheaderComponent
              {...props}
              route={route}
              params={params}
              bookmarkNavClickEvent={() =>
                analyticsData &&
                analytics.bookmarkClick(
                  analyticsData.clickAnalytics,
                  sourcePage
                )
              }
              subheaderNavClickEvent={(destinationPage) =>
                analyticsData &&
                analytics.navLinkClick(
                  analyticsData.clickAnalytics,
                  sourcePage,
                  destinationPage
                )
              }
            >
              {getSubheaderChildComponent(splat)}
            </SubheaderComponent>
          )}
        </div>
      );
    }
    if (_.isUndefined(splat)) {
      return history.push("/audioMultimedia/overview");
    }
    return <Display404NotFound display />;
  };

  return <div>{getPageDisplay()}</div>;
};

AudioMultimediaRouterComponent.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default injectIntl(AudioMultimediaRouterComponent);
