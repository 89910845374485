import { compiler } from 'markdown-to-jsx';

export default function applyMarkdownRemoveDisclaimers(text) {
  if (!text) return null;

  text = text.replace(/\^(\d+)\^/g, '');
  text = text.replace(/\^\\(\*\d+)\^/g, '');
  text = text.replace(/\^\(?SM\)?\^/g, '℠');
  text = text.replace(/\^\(?TM\)?\^/g, '™');
  text = text.replace(/\^\(?R\)?\^/g, '®');

  const textJSX = compiler(text);

  return textJSX.props.children;
}