import 'whatwg-fetch';
import printIcon from '../resources/images/print_icon.png';
import httpOption from './httpOption';

export default function getPdf(articleNumber, languageOverride) {
  const options = httpOption.GET();
  options.headers['accept-language'] = languageOverride || options.headers['accept-language'];

  return (printIcon);
}
