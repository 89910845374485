import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import FAQs from '../../../components/pages/FAQs';
import AskQuestionForm from './appPage-askQuestionForm';
import { postViewPageAction } from '../../../services/nitroService';
import { appPageLoad, formClicked } from '../appPage-analytics';

const AppPageFAQs = (props) => {
  const [availableFilters, setAvailableFilters] = useState([]);
  const [filteredFaqs, setFilteredFaqs] = useState([]);
  const pageLoadAnalytics = props.analyticsData && props.analyticsData.pageLoad;
  const clickAnalytics = props.analyticsData && props.analyticsData.clickAnalytics;
  useEffect(() => {
    appPageLoad(pageLoadAnalytics, "FAQ's");
    postViewPageAction(window.location.href);
  }, []);

  useEffect(() => {
    const availableFiltersFromProps = props.data.faqFilters ? props.data.faqFilters.slice() : [];
    const faqsFromProps = props.data.questions ? props.data.questions : props.data;

    setAvailableFilters(availableFiltersFromProps);
    setFilteredFaqs(faqsFromProps);
  }, [props.data]);

  return (
    <>
      <FAQs
        clickEvent={() => console.log('click')}
        disclaimer={'appLanding'}
        faqs={filteredFaqs}
        filters={availableFilters}
        hideFilters={true}
        languageOverride={false}
        urlPath={'/app/faqs'}
      />
      <AskQuestionForm
        header={props.data.header}
        description={props.data.description}
        clickAnalytics = {clickAnalytics}
        clickFunction  = {formClicked}
      />
    </>
  );
};

AppPageFAQs.propTypes = {
  data: PropTypes.object,
};

export default AppPageFAQs;
