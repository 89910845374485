import React from 'react';
import SpecData from './specData.component';

export function SpecCategoryTemplate(component) {
  let categoryTitle = null;
  let specData = null;

  if (component.props.category) {
    categoryTitle = <div className="spec-category-title">{component.props.category.specCategoryName}</div>;
    specData = component.props.category.specCategoryData.map((spec, index) => <SpecData key={index} spec={spec}/>);
  } else {
    categoryTitle = 'No Spec Data';
    specData = 'No Spec Data';
  }

  return (
    <div className="spec-category">
      {categoryTitle}
      {specData}
    </div>
  );
}
