import React from 'react';
import '../../styles/css/competitiveAdvantages.css';
import OverviewComponent from './overview/CaOverview.component';
import HeadToHeadComponent from './headToHead/headToHead.component';
import ThirdPartyComparisonComponent from './thirdPartyComparison/thirdPartyComparison.component';
import LegacyEdgeComponent from './headToHead/legacy.edge.component';
import LegacyOverviewComponent from './overview/legacy.overview.component';
import { FilterMenu } from '../filterMenu/filterMenu.component';
import SectionHeader from '../../components/atoms/SectionHeader';
import NewHeadToHeadComponent from './newRedesignPlaceholder/2021HeadToHeadComponent.js';


export function CompetitiveAdvantagesTemplate(component) {
  const header = (
    <div className="competitiveadvantages">
      <SectionHeader id='ca.pageTitle' />
      <FilterMenu
        active={component.props.subPage}
        filters={component.state.filters}
        filterClick={component.navigationClick}
        displayArrow={true}
      />
    </div>
  );

  if (component.props.params.year > 2020) {
    return (
      <div>
        {header}
        {component.props.subPage === 'headToHead' ? <NewHeadToHeadComponent model={component.props.route.model} year={component.props.params.year} /> : null}
        {component.props.subPage === 'thirdPartyComparison'
          ? <ThirdPartyComparisonComponent model={component.props.route.model} year={component.props.params.year} />
          : null}
      </div>
    );
  }

  if (component.props.params.year >= 2020 && component.props.params.year < 2021) {
    return (
      <div>
        {header}
        {component.props.subPage === 'headToHead'
          ? <HeadToHeadComponent model={component.props.route.model} year={component.props.params.year} />
          : null}
        {component.props.subPage === 'thirdPartyComparison'
          ? <ThirdPartyComparisonComponent model={component.props.route.model} year={component.props.params.year} />
          : null}
      </div>
    );
  }

  if (component.props.params.year > 2017 && component.props.params.year < 2020) {
    return (
      <div>
        {header}
        {component.props.subPage === 'overview'
          ? <OverviewComponent model={component.props.route.model} year={component.props.params.year} />
          : null}
        {component.props.subPage === 'headToHead'
          ? <HeadToHeadComponent model={component.props.route.model} year={component.props.params.year} />
          : null}
        {component.props.subPage === 'thirdPartyComparison'
          ? <ThirdPartyComparisonComponent model={component.props.route.model} year={component.props.params.year} />
          : null}
      </div>
    );
  }
  return (
    <div>
      {header}
      {component.props.subPage === 'overview'
        ? <LegacyOverviewComponent model={component.props.route.model} year={component.props.params.year} />
        : null}
      {component.props.subPage === 'headToHead'
        ? <LegacyEdgeComponent model={component.props.route.model} year={component.props.params.year} />
        : null}
      {component.props.subPage === 'thirdPartyComparison'
        ? <ThirdPartyComparisonComponent model={component.props.route.model} year={component.props.params.year} />
        : null}
    </div>
  );
}
