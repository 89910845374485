import React, {useRef, useEffect} from 'react';
import RedLine from '../../../components/atoms/RedLine';
import '../../../styles/css/ammFPOContent.css';
import applyMarkdown from '../../utils/applyMarkdown';

const FPOContent = props => {

    const disclaimerRef = useRef();

    useEffect(() => {
        props.setDisclaimer(disclaimerRef.current);
      }, [disclaimerRef]);

    const getColor = (color) => {
        switch (color) {
            case 'blue':
                return '#EAF8FF';
            case 'green':
                return '#F7FFF5';
            default:
                return '#FFFFFF';
        }
    }

    const getTables = (tables) => {
        return(tables.map((table) => (
            checkRows(table) && (<table>
                <div className="rowHeaders">
                <th className='rowTitle' style={
                    {
                        background: getColor(table.color)
                    }
                }>
                    {
                    applyMarkdown(table.title)
                } {table.tag && <div className='tag'>{table.tag}</div>}</th>
                <th className='rowPrice' style={
                    {
                        background: getColor(table.color)
                    }
                }>
                    {
                    applyMarkdown(table.price)
                }</th></div>
                <div className="tableRows">
                {
                getTableRows(table)
            }</div></table>)
        )));
    }

    const checkRows = (table) => {
        let hasRows = false;

        table.rows.map(function(row) {
            if(row.availability != 'Not Available'){
                hasRows = true;
            }
        })

        return hasRows;
    }

    const getTableRows = (table) => {
        return(table.rows.map((row) => (
            row.availability != 'Not Available' && (<tr>
                <td className='rowOption'>{
                    applyMarkdown(row.option)
                }</td>
                <td className='rowAvailability'>{
                    applyMarkdown(row.availability)
                }</td>
            </tr>)
        )));
    }

    return (
        <div className='ammModalContent'>
            <div className='ammModalHeader'>
                <div className='ammModalImage'>
                    <img src={
                        props.imagePath + props.modalContent.image
                    }/>
                </div>
                <div className='ammModalTitle'>
                    {
                    applyMarkdown(props.modalContent.text, props.disclaimer)
                }</div>
                <RedLine/>
                <div className='description'>
                    {
                    applyMarkdown(props.modalContent.description, props.disclaimer)
                }</div>
            </div>
            <div className='tableSection'>
                {
                getTables(props.modalContent.tables)
            }</div>
        </div>
    );
};


export default FPOContent;
