import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import applyMarkdown from '../../utils/applyMarkdown';
import '../../../styles/css/smartpath-overview-features.css';
import * as featuresAnalytics from '../overviewAnalytics/smartpath-overview-features.analytics';
import useOnScreen from '../useIntersection';


export const SmartPathOverviewFeatures = (props) => {
    const featuresRef = useRef();
    if (useOnScreen(featuresRef) && props.pageLoadAnalytics) {
        featuresAnalytics.featuresSubLoad(props.pageLoadAnalytics);
    }

    let getlabelclass = function(label){
        let labelClass
        if (label != null){
            labelClass = "smartpath-feature-cardlabel"
        }
        else{
            labelClass = "smartpath-feature-emptyCardlabel"
        }
        return labelClass
    }
    
    let getFeaturesContent = function(featureContent){
        
        const content = featureContent.map(content => (
            
            <div className = "smartpath-feature-cards">
                <div className = "smartpath-feature-label-container">
                    <div className = {getlabelclass(content.label)}>{content.label}</div>
                </div>
                <div className ="smartpath-feature-cardIcon" >{<img src={props.imagePath + content.image}/>}</div>
                <div className = "smartpath-feature-cardTitle">{applyMarkdown(content.title)}</div>
                <div className = "smartpath-feature-cardContent">
                    {applyMarkdown(content.description)}
                </div> 
            </div>
        ));
        return content;
    };
     
    const features = props.data.sections.map(feature =>(
        <div>
            <div className = "smartpath-feature-title">{applyMarkdown(feature.title)}</div> 
            <div className = "smartpath-feature-content">{getFeaturesContent(feature.content)}</div>
        </div>
    ));

    return <div ref={featuresRef} className = "smartpath-overview-features">
        <div className="smartpath-overview-features-header">
            {applyMarkdown(props.data.header)}
        </div>
        <div className = "smartpath-overview-features-content">
            {features}
        </div>
    </div>;
};