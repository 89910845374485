import React, { Component } from 'react';
import { VehicleTemplate } from './vehicle.tpl';
import { getModelTrims } from '../../../services/comparisonService.js';
import { translateText } from '../../utils/translateText';
import AppSettings from '../../../appsettings';
import { noCustomImageAvailable } from './noCustomImageAvailable';

export default class VehicleComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMake: this.props.vehicle.make,
      selectedModelId: this.props.vehicle.modelId,
      selectedTrim: this.props.vehicle.trimId,
      imagePath: this.props.image,
      availableTrims: null,
    };

    this.noCustomImageAvailable = noCustomImageAvailable.bind(this);
  }

  getMakes() {
    if (this.props.availableVehicles) {
      const makes = this.props.availableVehicles.map((make, index) => <option key={index + 1} value={make.makeName}>{make.makeName.toUpperCase()}</option>);
      return makes;
    }
    return <option>{translateText('ca.thirdPartyComparison.noMakesAvailable')}</option>;
  }

  getModels() {
    if (this.props.availableVehicles) {
      const make = this.props.availableVehicles.find(make => make.makeName === this.state.selectedMake);
      const models = make.modelNameList.map((model, index) => model.modelYearList.map((year, index) => <option key={index + 1} value={year.modelId.modelId}>{`${model.modelName.toUpperCase()} (${year.modelYear})`}</option>));
      models.unshift(<option key={0} value=''>{translateText('ca.thirdPartyComparison.competitorVehicleSelectModelPlaceholder')}</option>);
      return models;
    }
    return <option>{translateText('ca.thirdPartyComparison.competitorVehicleSelectModelPlaceholder')}</option>;
  }

  getTrims() {
    if (this.state.availableTrims) {
      const trims = this.state.availableTrims.map((trim, index) => <option key={index + 1} value={trim.trimId}>{trim.trimName.toUpperCase()}</option>);
      trims.unshift(<option key={0} value=''>{translateText('ca.thirdPartyComparison.competitorVehicleSelectTrimPlaceholder')}</option>);
      return trims;
    }
    return <option>{translateText('ca.thirdPartyComparison.competitorVehicleSelectTrimPlaceholder')}</option>;
  }

  getImage() {
    let imageUrl;
    if (this.trimListIsSet()) {
      const trim = this.getTrimDataFromAvailableTrimsList();
      imageUrl = trim.imageUrl;
    }
    else {
      imageUrl = this.state.imagePath;
    }
    if (this.noCustomImageAvailable(imageUrl)) {
      return AppSettings.AWSImgRepo.resources + 'no_image_available_tall.png';
    }
    else {
      return imageUrl;
    }
  }

  trimListIsSet() {
    if (this.state.availableTrims && this.state.selectedTrim) {
      return true;
    }
    return false;
  }

  getTrimDataFromAvailableTrimsList() {
    return this.state.availableTrims.find(trim => trim.trimId.toString() === this.state.selectedTrim.toString());
  }

  setAvailableTrims() {
    if (this.state.selectedModelId) {
      return getModelTrims(this.state.selectedModelId).then((trims) => {
        this.setState({
          availableTrims: trims,
        });
      });
    }
  }

  handleMakeChange(event) {
    this.setState(
      {
        selectedMake: event.target.value,
        selectedModelId: '',
        selectedTrim: '',
        availableTrims: null,
      },
      () => {
        this.props.onVehicleChange(this, false, 'Make', this.state.selectedMake);
      },
    );
  }

  handleModelChange(event) {
    this.setState(
      {
        selectedModelId: event.target.value,
        selectedTrim: '',
        availableTrims: null,
      },
      () => {
        this.setAvailableTrims();
        const make = this.props.availableVehicles.find(make => make.makeName === this.state.selectedMake, this);
        const modelsList = [];

        for (const model of make.modelNameList) {
          for (const year of model.modelYearList) {
            const curMod = {
              id: year.modelId.modelId,
              modelName: `${model.modelName.toUpperCase()} (${year.modelYear})`,
            };
            modelsList.push(curMod);
          }
        }

        const currentModel = this.state.selectedModelId;
        const foundModel = modelsList.find(model => model.id === currentModel);
        this.props.onVehicleChange(this, false, 'Model', foundModel.modelName);
      },
    );
  }

  handleTrimChange(event) {
    this.setState(
      {
        selectedTrim: event.target.value,
      },
      () => {
        const currentTrim = this.state.selectedTrim;
        const foundTrim = this.state.availableTrims.find(trim => trim.trimId.toString() === currentTrim);
        this.props.onVehicleChange(this, false, 'Trim', foundTrim.trimName);
      },
    );
  }

  componentDidMount() {
    this.setAvailableTrims();
  }

  render() {
    return VehicleTemplate(this);
  }
}