import React, {useState, useEffect} from "react";
import CardLayout from "./cardLayout";
import AppSettings from "../../../../appsettings";
import applyMarkdown from "../../../utils/applyMarkdown";
import { handleLinkClick } from "./utils";
import '../../../../styles/scss/beyondZero/designedForFuture.scss'
import BZTabs from "../../sharedComponents/bzTabs";

const DesignedForFuture = ({ data, analyticsActions }) => {

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [textCard, setTextCard] = useState();
    const currentTab = data?.tabs[selectedTabIndex];

    useEffect(()=>{
        changeTab();
    },[currentTab])

    /* tabItems object accepts value props with a numeric key. You can start at 0 or 1, just make sure your active tab key matches the same number system.  
    
    tabItems = [
        {
            value: 0,
            label: 'Carbon'
        },
        {
            value: 1,
            label: 'Water'
        },
        {
            value: 2,
            label: 'Materials'
        },
        {
            value: 3,
            label: 'Biodiversity'
        },
    ] */

    const tabItems = data?.tabs?.map((tab, index) =>
        {
            return (
                {
                    value: index,
                    label: tab.tabName
                }
            )
            
        }
    );

    const changeTab = () => {
        setTextCard({
            text: currentTab?.infoDescription,
            buttonText: currentTab?.buttonText,
            buttonOnClick: () => {
                analyticsActions.onClick({
                    label: 'Designed for the Future Button',
                    content_title: currentTab?.tabName
                })
                handleLinkClick(currentTab?.buttonUrl)
            }
        });

        analyticsActions.onTabClick({
            label: 'Designed for the Future Tab',
            content_title: currentTab?.tabName
        })
    }

    return (
        <div className='designed-for-future'>
            <div className='heading-wrapper'>
                <span className='designed-for-future-title'>{applyMarkdown(data?.title)}</span>
                <span className='description'>{applyMarkdown(data?.description)}</span>
                <div className='tab-selector'>
                    <BZTabs
                        active={selectedTabIndex}
                        onClick={setSelectedTabIndex}
                        items={tabItems}
                    />
                </div>
            </div>
            <CardLayout
                className='card-wrapper'
                isFlipped={(selectedTabIndex % 2)}
                textCard={textCard}
                images={{
                    first: {
                        desktop: AppSettings.AWSImgRepo.beyondZero + currentTab?.primaryImageDesktop,
                        tablet: AppSettings.AWSImgRepo.beyondZero + currentTab?.primaryImageTablet,
                        mobile: AppSettings.AWSImgRepo.beyondZero + currentTab?.primaryImageMobile,
                    },
                    second: {
                        desktop: AppSettings.AWSImgRepo.beyondZero + currentTab?.secondaryImageDesktop,
                        tablet: AppSettings.AWSImgRepo.beyondZero + currentTab?.secondaryImageTablet,
                        mobile: AppSettings.AWSImgRepo.beyondZero + currentTab?.secondaryImageMobile,
                    }
                }}
            />
        </div>
    );
}

export default DesignedForFuture;