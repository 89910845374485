import PropTypes from 'prop-types';
import React from 'react';
import CarouselComponent from '../../carousel/carousel.component';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '../../../styles/css/carousel.css';
import '../../../styles/css/news/newsCarousel.css';

const NewsCarousel = (props) => {
    const carouselContent = (articles) => (
            articles.map((item, index) => (
                <div key={index} className='newsCarouselContent'>
                    <a href={item.link} className='newsCarouselLink'>
                        {/* CROPPED SQUARE VERSION */}
                        <div className='newsCarouselImage' style={{ backgroundImage: `url(${props.imagePath + item.image})` }}></div>
                        {/* SHORT WIDE & FULL WIDE VERSION */}
                        {/* <img className='newsCarouselImage' alt={item.title} src={props.imagePath + item.image} /> */}
                    </a>
                    <div className='newsCarouselText'>
                        <div className='newsCarouselTitle'>{item.title}</div>
                        <div className='newsCarouselContentType'>{item.contentType}</div>
                        <div className='newsCarouselDate'>{parseDate(item.link)}</div>
                        <div className='newsCarouselDescription'>{item.description}</div>
                    </div>
                    <div className='break'></div>
                    <div className='newsCarouselTabletText'>
                        <div className='newsCarouselDescription'>{item.description}</div>
                    </div>
                </div>
                )
            )
    );

    
    function parseDate (link) {
        // Will only parse dates formatted as 'articles/MM_DD_YYYY' in the link
        if (!link.includes('articles/')) return;
        const dateArray = link.substring(link.indexOf('articles/') + 9).split('_');
        const months = ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return months[dateArray[0]] + ' ' + dateArray[1] + ', ' + dateArray[2];
    }
    
    function NextArrow(Items) {
        const {
            onClick, items, currentSlide, slideCount
        } = Items;
        return (
            <div className={'slick-next'}><img src={props.imagePath + 'newsCarouselArrow.png'} onClick={() => { props.analytics.carouselArrowClick(props.analyticsData.clickAnalytics.carouselArrow, props.articleDate, props.analyticsData.clickAnalytics.carouselArrow.nextArrow, items[currentSlide].title); onClick(); }} /></div>
            );
        }
        
    function PrevArrow(Items) {
        const {
            onClick, items, currentSlide, slideCount
        } = Items;
        return (
            <div className={'slick-prev'}><img src={props.imagePath + 'newsCarouselArrow.png'} onClick={() => { props.analytics.carouselArrowClick(props.analyticsData.clickAnalytics.carouselArrow, props.articleDate, props.analyticsData.clickAnalytics.carouselArrow.prevArrow, items[currentSlide].title); onClick(); }} /></div>
            );
        }
            
    const handleDotClick = (index, title) => {
        props.analytics.carouselDotClick(props.analyticsData.clickAnalytics.carouselDot, props.articleDate, index, title);
    };

    function CarouselDots(Items) {
        const {
            index, items,
        } = Items;

        return (
            // eslint-disable-next-line no-undef
            <div className="dot" key={index} onClick={() => handleDotClick(index + 1, items[index].title)}></div>
        );
    }

    const settings = {
        customPaging: (index) => {
            return <button><CarouselDots items={props.articles} index={index} analytics={props.analytics}/></button>
          },
        adaptiveHeight: false,
        respondTo: 'window',
        zIndex: 0,
        dots: true,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 0,
        nextArrow: <NextArrow items={props.articles} />,
        prevArrow: <PrevArrow items={props.articles} />,
        slidesToShow: 1,
        slidesToScroll: 1,
      };
    

    return (
        <div className='newsCarouselContainer'>
            <div className='newsCarousel'>
                <div className='newsCarouselHeader'>Current News</div>
                <CarouselComponent settings={settings} > 
                    {props.articles && carouselContent(props.articles)}
                </CarouselComponent>
            </div>
        </div>
    );
};

NewsCarousel.propTypes = {
    articles: PropTypes.array
};

export default NewsCarousel;