import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { SourcebooksTemplate } from './sourcebooks.tpl';
import { GlobalAnalytics } from '../../analytics';
import getMisc from '../../services/miscService.js';
import { postUserActivityRecord } from '../../services/userActivityService.js';

const analytics = new GlobalAnalytics();

export class SourcebooksComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      header: { image: '', description: '' },
      legacySourcebookItems: [],
      sourcebookItems: [],
      readMore: [],
      imagePath: this.props.imagePath ? this.props.imagePath : ' ',
      pageName: this.props.pageName ? this.props.pageName : ' ',
    };
  }

  getSourcebooksData() {
    getMisc('sourceBooks')
      .then((sourcebooksData) => {
        this.setState({
          header: sourcebooksData.header,
          sourcebookItems: sourcebooksData.rows,
          legacySourcebookItems: sourcebooksData.items,
        });
      });
  }

  sourceBookClick(model, year) {
    analytics.fireOnClick({
      tag: '38.2',
      section: 'Certification',
      technologyName: 'Certification',
      subsection: 'Source Books',
      page: 'Source Books',
      category: `${year} ${model}`,
      mediaTitle: 'Click Here',
      action: 'Click',
    });
  }

  componentDidMount() {
    postUserActivityRecord();
    this.getSourcebooksData();
    analytics.firePageLoad({
      tag: '35.1',
      section: 'Product',
      subsection: 'Featured Technology',
      technologyName: 'Source Books',
      page: 'Source Books',
    });
  }

  componentWillMount() {
    this.getSourcebooksData();
  }

  render() {
    return SourcebooksTemplate(this);
  }
}
export default injectIntl(SourcebooksComponent);
