import React, { useEffect, useState, useRef, useContext } from "react";

import { Navigation, Pagination, Scrollbar, A11y, Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ClipLoader } from 'react-spinners';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import "swiper/css/scrollbar";
import '../../styles/css/carouselV2.css';
import AppSettings from '../../appsettings';
import { isDesktopView, isTabletView, isMobileView } from '../../screens/utils/getScreenSize';
import { WindowSize } from '../contexts/windowSizeContext';

const CarouselV2 = (props) => {
    const [slidesOffset, setSlidesOffset] = useState(0);
    const [screenWidth, setScreenWidth] = useState([]);
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)
    const firstSlideRef = useRef(null)
    const [itemsToShow, setItemsToShow] = useState(props.items);

    const { resizing } = useContext(WindowSize);

    useEffect(() => {
        setSlidesOffset(firstSlideRef.current?.swiperSlideSize + (props.spaceBetween || 10))
    }, [firstSlideRef.current, screenWidth]);

    useEffect(() => {
        setItemsToShow(props.items);
    }, [props.items])

    useEffect(() => {
        function handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);
        return() => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const getModules = () => {
        let modules=[];
        props.navigation && modules.push(Navigation);
        props.pagination && modules.push(Pagination);
        return modules;
    }

    const applySpotlightClasses = (swiper) => {
        if(props.spotlight && props.loop) {
            let spotlightSlides = 0
            swiper.slides.forEach(s => {
                if ([...s.classList].includes("swiper-slide-active") || (spotlightSlides > 0 && spotlightSlides < props.slidesCount)) {
                    spotlightSlides++
                    s.classList.add("spotlight-slide")
                } else {
                    s.classList.remove("spotlight-slide")
                }
            })
        }
    }

    return ( 
        !resizing ? <div className={`carouselV2 ${(props.spotlight && props.loop) ? "spotlight" : ""}`} style={props.width ? {width: `${props.width * 100}% `} : {}}>
            {props.items?.length > props.slidesCount ? <>
                <div className="carouselV2-slides">
                    <Swiper
                        onReachEnd={props.onReachEnd}
                        onReachBeginning={props.onReachBeginning}
                        updateOnWindowResize={true}
                        observer={true}
                        observeParents={true}
                        resizeObserver={true}
                        modules={getModules()}
                        slidesPerView={(props.spotlight && props.loop) ? props.slidesCount + 2 : props.slidesCount}
                        navigation={props.navigation && {
                            prevEl: navigationPrevRef?.current,
                            nextEl: navigationNextRef?.current,
                        }}
                        onBeforeInit={(swiper) => {
                            if (props.navigation) {
                                swiper.params.navigation.prevEl = navigationPrevRef?.current;
                                swiper.params.navigation.nextEl = navigationNextRef?.current;
                            }
                        }}
                        pagination={props.pagination && {clickable: true}}
                        loop={props.items?.length > props.slidesCount ? props.loop : false}
                        centerInsufficientSlides={props.items?.length >= props.slidesCount - 1 ? false : true}
                        spaceBetween={props.spaceBetween || 10}
                        watchSlidesVisibility
                        slidesOffsetBefore={(props.spotlight && props.loop) && slidesOffset}
                        onUpdate={(swiper) => applySpotlightClasses(swiper)}
                        onSlideChangeTransitionStart={(swiper) => applySpotlightClasses(swiper)}
                    >
                        {itemsToShow.map((e, index) =>
                            <SwiperSlide key={index} ref={index==1 ? firstSlideRef : null}>{e}</SwiperSlide>
                        )}
                    </Swiper>
                </div>
                {props.navigation && <>
                    <div className="carouselV2-arrow prev" ref={navigationPrevRef}>
                        {props.prevArrow ? props.prevArrow : <img className="arrowIcon" src={AppSettings.AWSImgRepo.resources + 'en/black_left_arrow.png'}/>}
                    </div>
                    <div className="carouselV2-arrow next" ref={navigationNextRef}>
                        {props.nextArrow ? props.nextArrow : <img className="arrowIcon" src={AppSettings.AWSImgRepo.resources + 'en/black_right_arrow.png'}/>}
                    </div>
                </>}
            </> :
                <div className="nonCarouselItems">{props.items}</div>
        }
        </div> : <div className="resizeSpinner"><ClipLoader color={"#EB0A1E"} loading={true} size={50} /></div>
    );
}

export default CarouselV2;