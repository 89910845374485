import React, {useState} from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import '../../../styles/css/PackageByTrimSearchItem.css';
import applyMarkdown from '../../utils/applyMarkdown';
import { FormattedMessage } from 'react-intl';
import { isDesktopView, isMobileView, isTabletView } from '../../utils/getScreenSize';

import {
    generateLearnMoreButton,
    generateOptionsDropdown,
    getEngineOptions,
    getTrimOptions,
    filterTrims,
    getAllPackages,
    getPackageOptions,
    optionsDropdownAnalytics,
} from '../resultHelperFunctions.js';

export class PackageByTrimSearchItemComponent extends React.Component {
    
    constructor(props) {
        super(props);
        const engineSet = [...new Set(props.item.values.map(x => x.engine))];
        const trimsSet = filterTrims(props.item.values, engineSet[0]);
        const packageSet = getAllPackages(props.item.values);
       
        this.state = {
            year: props.item.year,
            model: props.item.model,
            trims: trimsSet,
            selectedPackages: null,
            engines: engineSet,
            visible:false,
            selectedTrimindex:null,
            packages:packageSet,
            selectedTrims:null,
            packageActive:"searchPackagesTabsActive",
            modelActive: "searchmodelActiveTabsInActive",
            circleIconActive:"tabletCircleIconActive",
            circleIconInActive:"tabletCircleIconInActive"

        };
        this.updateSelectedTrimValue = this.updateSelectedTrimValue.bind(this);
        this.updateSelectedEngineValue = this.updateSelectedEngineValue.bind(this);
        this.updateSelectedPackageValue = this.updateSelectedPackageValue.bind(this);
    }

    createPackageByTrimResult() {
        const engineOptions = getEngineOptions(this.props.item.values);
        const trimOptions = getTrimOptions(this.state.trims);
        const engineDropdown = generateOptionsDropdown(engineOptions, this.updateSelectedEngineValue, 'Engine');
        const trimDropdown = generateOptionsDropdown(trimOptions, this.updateSelectedTrimValue, 'Trims');
        const packagesContainer = this.getPackagesContainer();
        const learnMoreButton = generateLearnMoreButton(this.props.item, this.state, this.props.parentComponent.state.analyticsData.clickAnalytics, this.props.parentComponent.state.searchParam, this.props.parentComponent.state.totalResults);

        return (
            <div>
                <div>
                    {engineDropdown}
                    {trimDropdown}
                    {this.getTrimTitle()}
                    <span className = "packagesList"key={this.state.year+this.state.model}>
                        {packagesContainer}
                    </span>
                </div>
                <div className="quickAnswersSeeMore">
                    {learnMoreButton}
                </div>
            </div>
        );
    }
    getTrimTitle(){
        let title
        if (this.state.selectedTrimindex != null){
            title = <div className="selectedTrimHeader">{this.state.trims[this.state.selectedTrimindex].trim}</div> 
        }
        return title
    }
    createTrimByPackageResult() {

        const packageoptions = getPackageOptions(Object.keys(this.state.packages))
        const packageDropdown =  generateOptionsDropdown(packageoptions, this.updateSelectedPackageValue, 'Packages');
        const trimsContainer = this.getTrimsContainer();
        const learnMoreButton = generateLearnMoreButton(this.props.item, this.state, this.props.parentComponent.state.analyticsData.clickAnalytics, this.props.parentComponent.state.searchParam, this.props.parentComponent.state.totalResults);

        return (
            <div className="">
                <div className="">
                    {packageDropdown}
                     {this.state.selectedPackageCode && <div className="packageCodeheader" ><span className="packageCodeHeaderText">PACKAGE CODE:</span> <span className = "packagecodeText">{this.state.selectedPackageCode}</span>
                     <span className= "TrimAvailableHeader">AVAILABLE ON:</span></div>}
                    <span className = "trimListByPackage">
                        {trimsContainer}
                    </span>
                </div>
                <div className="quickAnswersSeeMore">
                    {learnMoreButton}
                </div>
            </div>
        );
    }
    

    updateSelectedTrimValue(value) {
        const packagesToDisplay = this.filterPackages(this.state.trims, value);
        optionsDropdownAnalytics(this.state.trims[value].trim, 'Trim', this.props.item, this.props.parentComponent.state.analyticsData.clickAnalytics, this.props.parentComponent.state.searchParam, this.props.parentComponent.state.totalResults);
        this.setState({
            selectedTrimindex: value,
            selectedPackages: packagesToDisplay,
        }, this.props.parentComponent.reRenderHeight);
    }

    updateSelectedPackageValue(value) {
        const selectedpackageName = Object.keys(this.state.packages)[value]
        const selectedPackageCode = this.state.packages[selectedpackageName]
        const trimToDisplay = this.filterTrimsByPackages(this.state.trims, selectedpackageName);
        optionsDropdownAnalytics(selectedpackageName, 'Package', this.props.item, this.props.parentComponent.state.analyticsData.clickAnalytics, this.props.parentComponent.state.searchParam, this.props.parentComponent.state.totalResults);
        this.setState({
            selectedPackageCode:selectedPackageCode,
            selectedTrims: trimToDisplay,
        }, this.props.parentComponent.reRenderHeight);
    }

    updateSelectedEngineValue(value) {
        const filteredTrims = filterTrims(this.props.item.values, value);
        const packagesToDisplay = this.filterPackages(filteredTrims, 0);
        optionsDropdownAnalytics(value, 'Engine', this.props.item, this.props.parentComponent.state.analyticsData.clickAnalytics, this.props.parentComponent.state.searchParam, this.props.parentComponent.state.totalResults);
        this.setState({
            selectedTrimindex:0,
            selectedEngineValue: value,
            trims: filteredTrims,
            selectedPackages: packagesToDisplay,
        }, this.props.parentComponent.reRenderHeight);
    }

    filterPackages(filteredTrims, selectedTrimValue) {
        return filteredTrims[selectedTrimValue].values;
    }

    filterTrimsByPackages(trimObject, selectedPackage) {
        const selectedTrims = [];
        for (let item in trimObject){
            let values = trimObject[item].values
            for (let i in values){
               if(values[i]['title'] == selectedPackage){
                   selectedTrims.push(trimObject[item]['trim'])
               }
            }          
        }
        return selectedTrims
    }

    getPackagesContainer() {
        var packageContainer = [];
        var packages = (this.state.selectedPackages && this.state.selectedPackages.length > 0) ? this.state.selectedPackages : null;
        if (packages && packages.length > 0) {
            Object.keys(packages).forEach(function (index, keyIndex) {
                var codeAndMsrp = <span>{' (' +packages[index].code + '  ' + PackageByTrimSearchItemComponent.formatMSRP(packages[index].msrp) + ')'}</span>
                var description = (packages[index].title).replace(/\^(\d+)\^/g, '');
                var descToDisplay = applyMarkdown(description);
                var item =
                    <span className = "packageStyling" key={packages[index].title}>
                        {descToDisplay} <span className="addMsrpSpacing">{codeAndMsrp}</span>
                    </span>
                packageContainer.push(item);
            });
        }
        return packageContainer.length > 0 ? packageContainer : 
              <div><FormattedMessage id="tcop.noTrimSelectedSearch" defaultMessage="No packages available at this time" /></div>;
    }
    getTrimsContainer() {
        var trims = (this.state.selectedTrims && this.state.selectedTrims.length > 0) ? this.state.selectedTrims : null;
        var trimsContainer = [];
        if (trims && trims.length > 0) {
            trims.map(x =>{
            var item = <span className = "trimFont">{x} </span>
            trimsContainer.push(item)
            })
        }
        return (this.state.selectedTrims && trimsContainer.length > 0) ? trimsContainer : 
              <div><FormattedMessage id="tcop.noPackageSelectedSearch" defaultMessage="No trims available for this package" /></div>;
    }

    static formatMSRP(msrp) {
        if (msrp) {
            msrp = msrp.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            if (msrp.indexOf("$") === -1) {
                msrp = "$" + msrp;
            }
        }
        else {
            msrp = "MSRP coming soon."
        }
        return msrp;
    }
    
    updateTabInfo(isActive){

        if (isDesktopView(window.innerWidth)){
            if (isActive == 0){
                this.setState(
                    {packageActive:"searchPackagesTabsActive",
                    modelActive:"searchModelTabsInActive"})
            }
            else if (isActive == 1){
                this.setState(
                    {packageActive:"searchPackagesTabsInActive",
                    modelActive:"searchModelTabsActive"})
            }
        }
        else{
            if (isActive == 0){
                this.setState(
                    {circleIconActive:"tabletCircleIconActive",
                    circleIconInActive:"tabletCircleIconInActive"})
            }
            else if (isActive == 1){
                this.setState(
                    {circleIconActive:"tabletCircleIconInActive",
                    circleIconInActive:"tabletCircleIconActive"})
            }
        }
    }
    changeState(isvisible,isActive){
        this.setState({visible:isvisible})
        this.updateTabInfo(isActive)
    }
    getDesktopModelAndPackages(){

        return  <div>
        <div className="searchPackages">
            <div className="searchPackagesTabs">
                {/* <span className="searchPackagesTabsHeader" >Packages</span> */}
                <span className ={this.state.packageActive} onClick={() => this.changeState(false,0)}>Availability by Packages</span>
                <span className ={this.state.modelActive} onClick={() => this.changeState(true,1)}>Availability by Model</span>
            </div>
            <div className = "contentDivider">
                {!this.state.visible && <div className = "topImagePosition"> <img src={this.props.imagePath + "Search_Path_Icon.png"} /></div>}
                {this.state.visible && <div className = "bottomImagePosition"> <img src={this.props.imagePath + "Search_Path_Icon.png"} /></div>}

                <div className = "dividerLine"></div>
            </div>
            <div>
                {this.state.visible && this.createPackageByTrimResult()}
                {!this.state.visible && this.createTrimByPackageResult()}
            </div>
        </div>
    </div>
    }
    getTabletModelAndPackages(){

        return  <div className="searchPackages">
            <div className="searchPackagesTabs">
                <div className = "tabletPackagesDivider">
                    <div className="tabletPackagesLeftArrow" onClick={() => this.changeState(false,0)}> <img src={this.props.imagePath + "Left_Arrow.png"} /></div>
                    <div className="tabletCircleIcons">
                        <div className = {this.state.circleIconActive}></div>
                        <div className = {this.state.circleIconInActive}></div>
                    </div>
                    <div className="tabletPackagesRightArrow" onClick={() => this.changeState(true,1)}> <img src={this.props.imagePath + "Right_Arrow.png"} /></div>
                </div>
            </div>
            <div className="contentContainer">
                <div className="titleContainer">
                    {this.state.visible && <span className ={this.state.packageActive}>Availability by Packages</span>}
                    {!this.state.visible && <span className ={this.state.packageActive} >Availability by Model</span>}
                </div>
                {this.state.visible && this.createPackageByTrimResult()}
                {!this.state.visible && this.createTrimByPackageResult()}
            </div>
        </div>
    }

    getModelAndPackagesByScreenSize(){
        if (isDesktopView(window.innerWidth)){
            return this.getDesktopModelAndPackages()
        }
        if (isTabletView(window.innerWidth)){
            return this.getTabletModelAndPackages()
        }
        if( isMobileView(window.innerWidth)){
            return this.getTabletModelAndPackages()
        }
    }

    render() {
        return (this.getModelAndPackagesByScreenSize());
    }
}

PackageByTrimSearchItemComponent.propTypes = {
    item: PropTypes.object,
    parentComponent: PropTypes.object,
    disclaimerCallback: PropTypes.func,
    index: PropTypes.number,
};


export default injectIntl(PackageByTrimSearchItemComponent);
