import 'whatwg-fetch';
import httpOption from './httpOption';
import AppSettings from '../appsettings';

export function postVinLookup(vin) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/vinLookup/${vin}`;
  const options = httpOption.POST();
  options.headers['accept-language'] = options.headers['accept-language'] || 'en';

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return resolve(response.json());
        }
        return Promise.reject(response);
      });
  });
}

export function checkVin(vin) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/vinLookup/check/${vin}`;
  const options = httpOption.POST();
  options.headers['accept-language'] = options.headers['accept-language'] || 'en';

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return resolve(response.json());
        }
        return reject(response);
      });
  });
}