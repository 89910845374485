import React, { useEffect, useState } from "react";
import ProfileSection from './profileSection';
import '../../../styles/scss/profilePage/profile-page.scss';
import applyMarkdown from "../../utils/applyMarkdown";
import { profilePagePageLoad, privacyNoticeClick, privacyChoicesClick } from "./profilePage.analytics";
import appsettings from "../../../appsettings";
import getNavigation from "../../../services/navigationService";

const ProfileComponent = (props) => {
  const isCorporate = props.userData.dealerCode == '90010' || localStorage.getItem('isCorporate') == 'True';
  const disclaimerMessage = "**Disclaimer:** If your profile information is incorrect, please contact your Dealer Daily admin to update."
  const [privacyLinks, setPrivacyLinks] = useState(null);

  useEffect(() => {
    getFooterNavigation();
  }, []);

  const getFooterNavigation = () => new Promise((resolve, reject) => {
    getNavigation('footer')
      .then((footerNavigation) => {
        setPrivacyLinks(footerNavigation.routes.privacyLinks);
      }).catch((error) => {
        reject(error);
      });
  });

  useEffect(() => {
    if(props?.analyticsData) {
      profilePagePageLoad(props?.analyticsData?.pageLoad?.profile);
    }
  }, [props?.analyticsData]);

  const profileSections = [
    {
      fields: [
        { label: 'First Name', value: props.userData.firstName },
        { label: 'Last Name', value: props.userData.lastName },
        { label: 'Email', value: props.userData.email },
        { label: 'Spin ID', value: props.userData.spinId },
      ],
    },
    {
      fields: [
        { label: 'Dealer', value: isCorporate ? '--' : props.userData.dealerName },
        { label: 'Dealer ID', value: isCorporate ? '--' : props.userData.dealerCode },
        { label: 'Region', value: props.userData.region },
      ],
    },
  ];


  return (
    <div className='profile-container'>
      <div className='profile-top'>
        {profileSections.map((section, index) => (
          <ProfileSection key={index} label={section.label} fields={section.fields}/>
        ))}
      </div>
      <div className='profile-bottom'>
        <div className='profile-disclaimer'>
          {applyMarkdown(disclaimerMessage, 'semibold')}
        </div>
        { window.ReactNativeWebView &&  privacyLinks && (
          <div className="profile-privacy">
            <a
              onClick={() => {privacyNoticeClick(props?.analyticsData?.clickAnalytics, privacyLinks.privacyNotice.name)}}
              href={privacyLinks.privacyNotice.href}
              target={privacyLinks.privacyNotice.target}
            >
              {privacyLinks.privacyNotice.name}
              <img
                className='external-icon'
                src={appsettings.AWSImgRepo.resources + `en/topNav_external.png`}
                alt={privacyLinks.privacyNotice.name}
              />
            </a>
            <div className="privacy-choice">
              <a
                onClick={() => privacyChoicesClick(props?.analyticsData?.clickAnalytics, privacyLinks.privacyChoices.name)}
                href={privacyLinks.privacyChoices.href}
                target={privacyLinks.privacyChoices.target}
              >
                <span>
                  {privacyLinks.privacyChoices.name}
                </span>
                <img
                  className='privacy-icon'
                  src={appsettings.AWSImgRepo.resources + `en/privacyoption.svg`}
                  alt={privacyLinks.privacyChoices.name}
                />
              </a>
            </div>
          </div>
       )}
      </div>
    </div>
  );
};

export default ProfileComponent;
