import { Component } from 'react';
import { SectionHeaderTemplateV2 } from './sectionHeaderV2.template';

export default class SectionHeaderComponentV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sampleImage: '',
    };
  }

  render() {
    return SectionHeaderTemplateV2(this);
  }
}
