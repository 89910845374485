import React, { useEffect } from 'react';
import AppSettings from "../../../appsettings";
import RoundedButton from '../../../components/molecules/roundedButton';
import '../../../styles/scss/engageXP/triviaModal.scss';
import { postViewPageAction } from '../../../services/nitroService';
import { triviaPassedPageLoad, triviaFailedPageLoad, passedQuizClick, failedQuizClick, finishedClick } from './triviaModal.analytics';

const TriviaResult = (props) => {
    const imagePath = AppSettings.AWSImgRepo.nitroOverview;
    const clickAnalytics = props?.analyticsData?.clickAnalytics;
    const analyticsPageName = "Engage XP";
    const analyticsCategory = "Trivia";

    useEffect(() => {
        if (props.triviaResult && props.analyticsData && props?.quizName) {
            postViewPageAction(window.location.href);
            const name = props.quizName;

            if (props.triviaResult?.passed) {
                const passedResultName = "Trivia Passed - " + name;
                triviaPassedPageLoad(props.analyticsData?.pageLoad?.triviaPassed, passedResultName);
            } else {
                const failedResultName = "Trivia Failed - " + name;
                triviaFailedPageLoad(props.analyticsData?.pageLoad?.triviaFailed, failedResultName);
            }
        }
    }, [props.triviaResult, props.analyticsData, props.quizName]);


    const buttonAction = () => {
        const { analyticsData, triviaResult, restartQuiz, endQuiz } = props;
    
        if (analyticsData) {
            const clickAnalyticsFunction = triviaResult.passed ? failedQuizClick : passedQuizClick;
            clickAnalyticsFunction(clickAnalytics, analyticsPageName, analyticsCategory);
    
            // Call finishedClick only if the quiz is passed
            if (triviaResult.passed) {
                finishedClick(clickAnalytics, analyticsPageName, analyticsCategory);
            }
        }
    
        // Call endQuiz or restartQuiz based on triviaResult
        triviaResult.passed ? endQuiz() : restartQuiz();
    };
    

    return (
        <div className="trivia-content trivia-result-content modalScrollArea">
            <div className="innerScrollArea">
                <div className="trivia-image"><img src={imagePath + (props.triviaResult?.passed ? "trivia-passed.png" : "trivia-failed.png")} /></div>
                {props.description && <div className="trivia-description">{props.description}</div>}
                <div className="trivia-button">
                    <RoundedButton
                        title={!props.triviaResult?.passed && props.repeatable ? "Play Again" : "Done"}
                        className="customColor bold"
                        color="#000"
                        textColor="#fff"
                        height="44px"
                        onClick={() => buttonAction()}
                    />
                </div>
            </div>
        </div>
    )
}

export default TriviaResult;
