import React from "react";
import "../../../styles/scss/engageXP/tournaments/jumpButtons.scss";

const JumpButtons = ({ tournamentsData, analytics }) => {
    const scrollTo = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const menuHeight = document.querySelector(".notification-nav-container")?.offsetHeight;
            const elementPosition = element.offsetTop - (menuHeight || 0);
            window.scrollTo({
                behavior: "smooth",
                top: elementPosition,
            });
        }
    };

    const handleAnalyticsClick = (button) => {
        scrollTo(button.onClickLocation);
        analytics({
            category: "Tabs",
            label: button.text,
            content_title: button.text,
        });
    };

    return (
        <div className="jump-buttons">
            {tournamentsData?.jumpButtons?.map((button) => (
                <button key={button.order} onClick={() => handleAnalyticsClick(button)}>
                    {button.text}
                </button>
            ))}
        </div>
    );
};

export default JumpButtons;
