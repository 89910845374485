import React, {useEffect, useState} from "react";
import ScrollingModal from "../../components/molecules/scrollingModal";
import {deleteBookmark, getBookmarks} from "../../services/bookmarksService";
import {ClipLoader} from "react-spinners";
import AppSettings from "../../appsettings";
import '../../styles/css/bookmarkModal.css';
import applyMarkdown from "../utils/applyMarkdown";

const bookmarkModalComponent = (props) => {

    const [bookmarks, setBookmarks] = useState(null);

    useEffect(() => {
        if (props.bookmarksVisible) {
            loadBookmarks();
        }

    }, [props.bookmarksVisible]);

    const loadBookmarks = () => {
        getBookmarks().then((bookmarkList) => {
            setBookmarks(sortBookmarks(bookmarkList));
        })
    }

    const sortBookmarks = (bookmarks) => {
        const sortedBookmarks = bookmarks.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return (dateB - dateA);
        });

        return sortedBookmarks;
    }

    const handleCloseModal = () => {
        props.setBookmarksVisible(false);
    }

    const handleDelete = async (bookmark, index) => {
        await deleteBookmark(bookmark);
        bookmarks.splice(index, 1);
        loadBookmarks();
    }

    const getDateFormatted = (time) => {
        let dateStr = '';
        if (time) {
            const months = [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec'
            ];
            const t = new Date(time);
            dateStr = `${ months[t.getMonth()]} ${t.getDate()}, ${t.getFullYear()}`;
        }
        return dateStr;
    };

    return (
        <ScrollingModal visible={props.bookmarksVisible} closeModal={() => handleCloseModal()} modalClassName="bookmarksModal" title="Bookmarks">
            {!bookmarks ? <div className="bookmarksSpinner">
                <ClipLoader color={"#EB0A1E"}
                    loading={true}
                    size={50}/>
            </div> : bookmarks && bookmarks.length > 0 ? bookmarks.map((bookmark, index) => {
                return (
                    <div className="bookmarkContainer">
                        <div>
                            <div className="bookmarkDate">
                                {getDateFormatted(Date.parse(bookmark.date))} </div>
                            <a className="bookmarkTitle"href={bookmark.url}>{applyMarkdown(`${bookmark.title}${bookmark.subcategory ? ': ' + bookmark.subcategory : ''}`)}</a>
                        </div>
                        <div>
                            <img className='trashIcon' alt='trash Icon'
                                src={ AppSettings.AWSImgRepo.resources + 'en/' + 'Trash_Can.svg' }
                                onClick={ () => handleDelete(bookmark, index) }/>
                        </div>
                    </div>
                )
            }) : <div className="noResultsMessage">No Results Found</div>
        } </ScrollingModal>
    );
}

export default bookmarkModalComponent;
