import React from 'react';
import { FormattedMessage } from 'react-intl';
import '../../styles/css/emailModal.css';
//import '../../styles/css/smartpath-legacy.css';
import '../../styles/css/smartpath-overview-contact.css';
import SPRegionalContactChart from './spRegionalContactChart';
import applyMarkdown from '../utils/applyMarkdown';
import { SPContactForm } from './spContactForm.js';

export function SPContactUsTemplate(component) {
  return (
        <div className="smartpath-overview-contact">
            <div id="statusMessageBox">
                <div className="messageText">Sending email...</div>
            </div>
            <div id="failureMessageBox">
                <div className="messageText">Error - email was not able to send.</div>
            </div>
            <div className="desktopView row">
                {/* <div className="headingText">
                    <FormattedMessage id="smartPath.about.contactUs" />
                </div> */}
                {SPContactForm(component)}
                {/* <SPRegionalContactChart rows={component.props.regionalContacts} /> */}
            </div>
            {/* <div className="mobileView">
                <div className="mobileTabs">
                    <button
                        className={`emailTab ${component.state.emailTabSelected ? 'selectedTab' : 'nonSelectedTab'}`}
                        disabled={component.state.emailTabSelected}
                        onClick={component.toggleTab}
                    >
                        <span className="buttonText"><FormattedMessage id="smartPath.contactUs.emailTab" /></span>
                    </button>
                    <button
                        className={`listTab ${component.state.emailTabSelected ? 'nonSelectedTab' : 'selectedTab'}`}
                        disabled={!component.state.emailTabSelected}
                        onClick={component.toggleTab}
                    >
                        <span className="buttonText"><FormattedMessage id="smartPath.contactUs.listTab" /></span>
                    </button>
                </div>
                {component.state.emailTabSelected
                  ? SPContactForm(component)
                  : <SPRegionalContactChart rows={component.props.regionalContacts} />
                }
            </div> */}
        </div>
  );
}
