import React, { useState } from 'react';
import '../../styles/css/towingPayload-calculatorMenu.css'
import PropTypes from 'prop-types';
import { CalculatorModal } from './towingPayload-calculatorModal'
import { isDesktopView, isMobileView } from '../../screens/utils/getScreenSize.js';
import AppSettings from '../../appsettings';
import { menuButtonClick } from './towingPayload-analytics';
import isAppleMobileDevice from '../utils/isAppleMobileDevice';
import RoundedButton from '../../components/molecules/roundedButton';

export const CalculatorMenu = (props) => {
    const [selectedButton, setSelectedButton] = useState(null);
    const [modalVisible, setModalVisibility] = useState(false);

    const openModal = (title) => {
        //disable background body scrolling and input field zoom
        setSelectedButton(title);
        setModalVisibility(true);
        props.clickAnalytics && menuButtonClick(props.clickAnalytics, props.vehicleSelection, props.trimSelection, title, props.page);
    };

    const closeModal = () => {
        setSelectedButton(null);
        setModalVisibility(false);
    };

    const CalculatorMenuButton = (props) => {
        return (
            <RoundedButton 
                className="calculatorMenuButton"
                onClick={()=> openModal(props.title)} 
                title={(isMobileView(window.innerWidth) && props.mobileTitle) ? props.mobileTitle : props.title}
            />
        );
    };
    
    const createMenuButtons = () => {
        let buttons = [];
        AppSettings.FeatureFlags.showTowingPayloadDefinitionsButton && buttons.push(<CalculatorMenuButton key='2' title='Towing Definitions'  mobileTitle='Definitions' />);
        AppSettings.FeatureFlags.showTowingPayloadFAQsButton && buttons.push(<CalculatorMenuButton key='1' title='FAQs' />);
        AppSettings.FeatureFlags.showTowingPayloadCommonWeightsButton && buttons.push(<CalculatorMenuButton key='3' title='Common Weights' mobileTitle='Weights' />);
        buttons.push(<CalculatorMenuButton key='4' title='Videos' />);
        let desktopOrientation = isDesktopView(window.innerWidth) ? `${props.desktopOrientation}Desktop` : ""
        return (
            <div className={`calculatorMenuButtons ${desktopOrientation}`}>
                {buttons}
            </div>
        );
    };

    return (
        <div className="towingPayloadCalculatorMenu">
            {createMenuButtons()}
            <CalculatorModal 
                modalType={selectedButton} 
                visible={modalVisible} 
                closeModal={closeModal}
                {...props}
            />
        </div>
    );
};