import { GlobalAnalytics } from '../../analytics';
import { postAMMAction } from '../../services/nitroService';

const analytics = new GlobalAnalytics();

export function overviewPageLoad(pageLoadAnalytics) {
    pageLoadAnalytics && analytics.firePageLoad({
        tag: pageLoadAnalytics.tagId,
        section: pageLoadAnalytics.section,
        subsection: pageLoadAnalytics.subsection,
        technologyName: pageLoadAnalytics.technologyName,
        page: pageLoadAnalytics.page,
    });
}

export function compareFeaturesOverlayPageLoad(pageLoadAnalytics, modelYear, modelName, modelGrade) {
    pageLoadAnalytics && analytics.firePageLoad({
        tag: pageLoadAnalytics.tagId,
        section: pageLoadAnalytics.section,
        subsection: pageLoadAnalytics.subsection,
        technologyName: pageLoadAnalytics.technologyName,
        page: pageLoadAnalytics.compareFeaturesOverlayPage,
        modelYear: modelYear,
        modelName: modelName,
        trim: modelGrade,
    });
}

export function featureDetailsOverlayPageLoad(pageLoadAnalytics, tileName) {
    pageLoadAnalytics && analytics.firePageLoad({
        tag: pageLoadAnalytics.tagId,
        section: pageLoadAnalytics.section,
        subsection: pageLoadAnalytics.subsection,
        technologyName: pageLoadAnalytics.technologyName,
        page: _.replace(pageLoadAnalytics.featureDetailsOverlayPage, pageLoadAnalytics.findAndReplace, tileName)
    });
}

export function systemInfoOverlayPageLoad(pageLoadAnalytics, tileName) {
    pageLoadAnalytics && analytics.firePageLoad({
        tag: pageLoadAnalytics.tagId,
        section: pageLoadAnalytics.section,
        subsection: pageLoadAnalytics.subsection,
        technologyName: pageLoadAnalytics.technologyName,
        page: _.replace(pageLoadAnalytics.systemInfoOverlayPage, pageLoadAnalytics.findAndReplace, tileName)
    });
}

export function navLinkClick(clickAnalytics, sourcePage, linkName) {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        section: clickAnalytics.section,
        subsection: clickAnalytics.subsection,
        technologyName: clickAnalytics.technologyName,
        page: sourcePage,
        category: clickAnalytics.navLinkClick.category,
        positionTitle: clickAnalytics.navLinkClick.positionTitle,
        contentTitle: linkName
    });
}

export function bookmarkClick(clickAnalytics, sourcePage) {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        section: clickAnalytics.section,
        subsection: clickAnalytics.subsection,
        technologyName: clickAnalytics.technologyName,
        page: sourcePage,
        category: clickAnalytics.bookmarkClick.category,
        positionTitle: clickAnalytics.bookmarkClick.positionTitle,
    });
}

export function vehicleApplicabilityModelYearSelectClick(clickAnalytics, modelYear) {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        section: clickAnalytics.section,
        subsection: clickAnalytics.subsection,
        technologyName: clickAnalytics.technologyName,
        page: clickAnalytics.vehicleApplicabilityClick.page,
        category: clickAnalytics.vehicleApplicabilityClick.modelYearSelectCategory,
        positionTitle: clickAnalytics.vehicleApplicabilityClick.positionTitle,
        modelYear: modelYear,
    });
}

export function vehicleApplicabilityModelNameSelectClick(clickAnalytics, modelName) {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        section: clickAnalytics.section,
        subsection: clickAnalytics.subsection,
        technologyName: clickAnalytics.technologyName,
        page: clickAnalytics.vehicleApplicabilityClick.page,
        category: clickAnalytics.vehicleApplicabilityClick.modelNameSelectCategory,
        positionTitle: clickAnalytics.vehicleApplicabilityClick.positionTitle,
        modelName: modelName,
    });
}

export function vehicleApplicabilityModelGradeSelectClick(clickAnalytics, modelGrade) {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        section: clickAnalytics.section,
        subsection: clickAnalytics.subsection,
        technologyName: clickAnalytics.technologyName,
        page: clickAnalytics.vehicleApplicabilityClick.page,
        category: clickAnalytics.vehicleApplicabilityClick.modelGradeSelectCategory,
        positionTitle: clickAnalytics.vehicleApplicabilityClick.positionTitle,
        trim: modelGrade,
    });
}

export function vehicleApplicabilityGoClick(clickAnalytics, modelYear, modelName, modelGrade) {
    postAMMAction(modelYear, modelName, modelGrade);
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        section: clickAnalytics.section,
        subsection: clickAnalytics.subsection,
        technologyName: clickAnalytics.technologyName,
        page: clickAnalytics.vehicleApplicabilityClick.page,
        category: clickAnalytics.vehicleApplicabilityClick.goClickCategory,
        positionTitle: clickAnalytics.vehicleApplicabilityClick.positionTitle,
        modelYear: modelYear,
        modelName: modelName,
        trim: modelGrade,
    });
}

export function vehicleApplicabilityLineupYearSelectClick(clickAnalytics, modelYear) {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        section: clickAnalytics.section,
        subsection: clickAnalytics.subsection,
        technologyName: clickAnalytics.technologyName,
        page: clickAnalytics.vehicleApplicabilityClick.page,
        category: clickAnalytics.vehicleApplicabilityClick.lineupYearSelectCategory,
        positionTitle: clickAnalytics.vehicleApplicabilityClick.positionTitle,
        modelYear: modelYear
    });
}

export function vehicleApplicabilityChartDownloadClick(clickAnalytics, modelYear) {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        section: clickAnalytics.section,
        subsection: clickAnalytics.subsection,
        technologyName: clickAnalytics.technologyName,
        page: clickAnalytics.vehicleApplicabilityClick.page,
        category: clickAnalytics.vehicleApplicabilityClick.chartDownloadCategory,
        positionTitle: clickAnalytics.vehicleApplicabilityClick.positionTitle,
        modelYear: modelYear
    });
}

export function vehicleApplicabilityCompareFeaturesClick(clickAnalytics, modelYear, modelName, modelGrade) {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        section: clickAnalytics.section,
        subsection: clickAnalytics.subsection,
        technologyName: clickAnalytics.technologyName,
        page: clickAnalytics.vehicleApplicabilityClick.page,
        category: clickAnalytics.vehicleApplicabilityClick.compareFeaturesCategory,
        positionTitle: clickAnalytics.vehicleApplicabilityClick.positionTitle,
        modelYear: modelYear,
        modelName: modelName,
        trim: modelGrade,
    });
}

export function vehicleApplicabilityCloseClick(clickAnalytics, modelYear, modelName, modelGrade) {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        section: clickAnalytics.section,
        subsection: clickAnalytics.subsection,
        technologyName: clickAnalytics.technologyName,
        page: clickAnalytics.vehicleApplicabilityClick.page,
        category: clickAnalytics.vehicleApplicabilityClick.closeClickCategory,
        positionTitle: clickAnalytics.vehicleApplicabilityClick.positionTitle,
        modelYear: modelYear,
        modelName: modelName,
        trim: modelGrade,
    });
}

export function compareFeaturesOverlayCloseClick(clickAnalytics, modelYear, modelName, modelGrade) {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        section: clickAnalytics.section,
        subsection: clickAnalytics.subsection,
        technologyName: clickAnalytics.technologyName,
        page: clickAnalytics.compareFeaturesOverlayCloseClick.page,
        category: clickAnalytics.compareFeaturesOverlayCloseClick.category,
        modelYear: modelYear,
        modelName: modelName,
        trim: modelGrade,
    });
}

export function systemInfoTileClick(clickAnalytics, tileName) {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        section: clickAnalytics.section,
        subsection: clickAnalytics.subsection,
        technologyName: clickAnalytics.technologyName,
        page: clickAnalytics.systemInfoTileClick.page,
        category: clickAnalytics.systemInfoTileClick.category,
        positionTitle: clickAnalytics.systemInfoTileClick.positionTitle,
        contentTitle: tileName
    });
}

export function systemInfoOverlayFilterClick(clickAnalytics, tileName, currentFilter, selectedFilter) {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        section: clickAnalytics.section,
        subsection: clickAnalytics.subsection,
        technologyName: clickAnalytics.technologyName,
        page: _.replace(clickAnalytics.systemInfoOverlayClick.page, clickAnalytics.findAndReplace, tileName),
        category: clickAnalytics.systemInfoOverlayClick.filterClickCategory,
        positionTitle: currentFilter,
        contentTitle: selectedFilter
    });
}

export function systemInfoOverlayCloseClick(clickAnalytics, tileName, currentFilter) {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        section: clickAnalytics.section,
        subsection: clickAnalytics.subsection,
        technologyName: clickAnalytics.technologyName,
        page: _.replace(clickAnalytics.systemInfoOverlayClick.page, clickAnalytics.findAndReplace, tileName),
        category: clickAnalytics.systemInfoOverlayClick.closeClickCategory,
        positionTitle: currentFilter,
    });
}

export function featureDetailsTileClick(clickAnalytics, tileName) {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        section: clickAnalytics.section,
        subsection: clickAnalytics.subsection,
        technologyName: clickAnalytics.technologyName,
        page: clickAnalytics.featureDetailsTileSectionClick.page,
        category: clickAnalytics.featureDetailsTileSectionClick.tileClickCategory,
        positionTitle: clickAnalytics.featureDetailsTileSectionClick.tileClickPositionTitle,
        contentTitle: tileName,
    });
}

export function featureDetailsViewMoreClick(clickAnalytics) {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        section: clickAnalytics.section,
        subsection: clickAnalytics.subsection,
        technologyName: clickAnalytics.technologyName,
        page: clickAnalytics.featureDetailsTileSectionClick.page,
        category: clickAnalytics.featureDetailsTileSectionClick.viewMoreClickCategory,
        positionTitle: clickAnalytics.featureDetailsTileSectionClick.viewMoreClickPositionTitle,
    });
}

export function featureDetailsOverlayCloseClick(clickAnalytics, tileName) {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        section: clickAnalytics.section,
        subsection: clickAnalytics.subsection,
        technologyName: clickAnalytics.technologyName,
        page: _.replace(clickAnalytics.featureDetailsOverlayClick.page, clickAnalytics.findAndReplace, tileName),
        category: clickAnalytics.featureDetailsOverlayClick.category,
    });
}

export function externalLinkClick(clickAnalytics, linkName) {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        section: clickAnalytics.section,
        subsection: clickAnalytics.subsection,
        technologyName: clickAnalytics.technologyName,
        page: clickAnalytics.externalLinkClick.page,
        category: _.replace(clickAnalytics.externalLinkClick.category, clickAnalytics.findAndReplace, linkName),
        positionTitle: clickAnalytics.externalLinkClick.positionTitle,
    });
}