import PropTypes from "prop-types";
import React from "react";
import "../../styles/css/news/newsHeader.css";
import RedLine from "../../components/atoms/RedLine";

const NewsHeader = (props) => {
  return (
    <div className="newsHeaderDiv">
      <div className="newsHeaderTitle">{props.title}</div>
      <RedLine
        style={{
          margin: "auto",
          width: "57px",
        }}
      />
    </div>
  );
};

NewsHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default NewsHeader;
