import React from 'react';
import '../../../../../styles/css/headToHead.css';
import { FormattedMessage } from 'react-intl';
import { getFormattedName } from '../../../../utils/vehicleName';

export function SectionHeaderTemplateV2(component) {
  const sanitizedImageURL = encodeURI(component.props.image);

  return (
        <div>
            <div className="header-title">
                <div className="header-box">
                    <span className="header-bold"><FormattedMessage id={component.props.boldName} />
                    </span>&nbsp;
                    <span><FormattedMessage id={component.props.nonBoldName} /></span>
                </div>
                <div className="toyota-competitor-table-text-holder">
                  <div className="toyota-text-holder">
                    <span className="vehicle-year-model-trim">{component.props.year ? component.props.year : component.props.toyotaVehicle.year}<b>{` ${getFormattedName(component.props.toyotaVehicle.model)} ${component.props.toyotaVehicle.trim}`}</b></span>
                  </div>
                  <div className="competitor-text-holder">
                    <span className="vehicle-year-model-trim">{component.props.year ? component.props.year : component.props.competitorVehicle.year}<b>{` ${component.props.competitorVehicle.model} ${component.props.competitorVehicle.trim}`}</b></span>
                  </div>
                </div>
            </div>
        </div>
  );
}
