import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import AppSettings from "../../appsettings";
import { postMessageToApp } from "../../screens/utils/hybridAppHelpers";
import "../../styles/css/mobileBackButton.css";

const MobileBackButton = (props) => {
  const filePath = AppSettings.AWSImgRepo.resources + "en/";
  const history = useHistory();

  const handleBack = () => {
    if (props.onClick) {
      props.onClick();
    } else if (window.ReactNativeWebView) {
      postMessageToApp("Go Back");
    } else {
      history.goBack();
      window.scroll({top: 0});
    }
  };

  const containerClassName = useMemo(() => `back-button-outer-container ${props.containerClassName ?? ''}`, [props.containerClassName])
  const imgClassName = useMemo(() => `mobile-back-button ${props.className ?? ''}`, [props.className])

  return (
    <div
      className={containerClassName}
      style={props.containerStyle ?? {}}
    >
      <img
        className={imgClassName}
        src={filePath + "mobile_back_arrow.svg"}
        alt="Back"
        onClick={handleBack}
        style={props.style ?? {}}
      />
    </div>
  );
};

export default MobileBackButton;

MobileBackButton.propTypes = {};
