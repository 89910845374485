import CarouselComponent from '../carousel/carousel.component';
import '../../styles/css/towingPayload-vehicleSelection.css';
import DropdownV2 from '../../components/molecules/DropdownV2';
import React, { useEffect, useState } from 'react';
import { isDesktopView, isMobileView, isTabletView } from '../utils/getScreenSize';
import * as analytics from './towingPayload-analytics';
import { useHistory } from 'react-router-dom';

export const VehicleSelection = (props) => {
    const history = useHistory();

    useEffect(() => {
        props.pageLoadAnalytics && analytics.vehicleSelectionLoaded(props.pageLoadAnalytics);
    }, []);

    const VehicleCarouselItem = (props) => {
        let selectedYear = props.latestYear;

        return (
            <div className="vehicleCarouselItem">
                <div className="vehicleItemContent">
                    <div className="vehicleTitle">{props.modelName}</div>
                    <img className="vehicleImage" src={props.jellyImagePath + (props.modelName).split(' ').join('_') + '.png'} alt={props.modelName}/>
                    <div className="yearSelection">
                        {generateYearsDropdown(getYearOptions(props.earliestYear, props.latestYear), ((year)=>{selectedYear = year}), props.latestYear, props.modelName)}
                        <a className='goButton'
                            onClick={() => onGo(props.modelName, selectedYear)}>
                            <div className="buttonText">GO</div>
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    const onGo = (selectedModel, selectedYear) => {
        history.push(`/towingPayload/${selectedModel.toString()}/${selectedYear.toString()}`)
        props.setPage("Filter");
        props.setInitialVehicleSelection({
            modelName: selectedModel.toString(),
            modelYear: selectedYear.toString()
        });
        props.clickAnalytics && analytics.goClick(props.clickAnalytics, selectedModel, selectedYear);
    };

    function getYearOptions (earliestYear, latestYear) {
        var years = [];
        for (var i = parseInt(latestYear); i>=parseInt(earliestYear); i--) {
            years.push(i);
        }
        return years;
    }

    function generateYearsDropdown(options, onChangeFunction, placeholder, modelName) {
        const redDownChevron = props.imagePath + 'chevron_down_red.png';
        const dropdownClosedArrow = <img className="towing-years-dropdown-arrow" src={redDownChevron}/>;
        const dropdownOpenArrow = <img className="towing-years-dropdown-arrow-upside-down" src={redDownChevron} />;

        return (
            <div className="towing-years-dropdown">
                <DropdownV2
                    arrowClosed={dropdownClosedArrow}
                    arrowOpen={dropdownOpenArrow}
                    defaultValue={placeholder}
                    options={options}
                    onValueChange={value => {
                        onChangeFunction(value.value);
                        props.clickAnalytics && analytics.dropdownChangeClick(props.clickAnalytics, modelName, value.value);
                    }}
                    menuClassName={`towing-years-dropdown-menu`}
                    placeholderClassName={`towing-years-dropdown-placeholder`}
                />
            </div>
        )
    }

    function sortVehicles() {
        return props.vehicleData.sort((a,b)=>(a.order > b.order) ? 1 : -1);
    }

    function getVehicleCarouselItems() {
        let vehicles = [];
        const sortedVehicles = sortVehicles();
        sortedVehicles.forEach(element => {
            vehicles.push(
                <VehicleCarouselItem
                    key = {element.modelName}
                    modelName = {element.modelName}
                    latestYear = {element.latestYear}
                    earliestYear = {element.earliestYear}
                    {...props}
                />
            )
        });
        return vehicles;
    }

    const vehicleCarouselItemList = getVehicleCarouselItems();

    function NextArrow(Items) {
        const {
            onClick, items,
        } = Items;
        return (
            <div className={'slick-next'}>
                <img src={props.imagePath + 'Right_Arrow.png'} onClick={() => { onClick(); props.clickAnalytics && analytics.arrowClick(props.clickAnalytics, "Right"); }} />
            </div>
        );
    };

    function PrevArrow(Items) {
        const {
            onClick, items,
        } = Items;
        return (
            <div className={'slick-prev'}>
                <img src={props.imagePath + 'Left_Arrow.png'} onClick={() => { onClick(); props.clickAnalytics && analytics.arrowClick(props.clickAnalytics, "Left"); }} />
            </div>
        );
    };

    const VehicleSelectionBody = () => {
        let slideCount = 4;
        if (isTabletView(window.innerWidth)) {
            slideCount = 3;
        } else if (isMobileView(window.innerWidth)) {
            slideCount = 1;
        }

        return (
            <CarouselComponent settings={{
                adaptiveHeight: false,
                respondTo: 'window',
                zIndex: 0,
                dots: false,
                infinite: true,
                autoplay: false,
                autoplaySpeed: 0,
                nextArrow: <NextArrow items={vehicleCarouselItemList} />,
                prevArrow: <PrevArrow items={vehicleCarouselItemList} />,
                slidesToShow: slideCount,
                slidesToScroll: 1,
            }}>
                {vehicleCarouselItemList}
            </CarouselComponent>
        )
    }

    const getVehicleSelectionNote = () => {
        let vehicleSelectionNote = props.miscText.filter(obj => obj.name === "VehicleSelectionNote");
        if (vehicleSelectionNote) {
            return vehicleSelectionNote[0].text1
        } else return ""
    }

    return (
        <div className="vehicleSelectionContainer">
            <div className="vehicleSelectionHeader">
                Whether you’re just checking specs or need to calculate a trip in detail, we’ve got you covered. Pick a model below to get started.
            </div>
            <div className="vehicleCarousel">
                <VehicleSelectionBody />
            </div>
            <div className="vehicleSelectionBottomMessage">
                {props.miscText && getVehicleSelectionNote()}
            </div>
        </div>
    )
    }
