import AppSettings from "../../appsettings";
import React, { useState, useEffect } from "react";
import '../../styles/css/localincentives.css';

const LocalIncentives = (props) => {
    const [regionPath, setRegionPath] = useState();
    const [zipCode, setZipCode] = useState();
    const [associatedModels, setAssociatedModels] = useState([]);

    useEffect(() => {
        let batRegion = null;
        // First try getting BAT region code if 'region' value exists in local storage
        if (localStorage.getItem('region')) {
            batRegion = AppSettings.BATIncentivesRegions[localStorage.getItem('region')];
        }
        // Try 'region_code' local storage value if exists and if BAT region code not found by 'region' value above
        if (localStorage.getItem("region_code") && !batRegion) {
            batRegion = AppSettings.BATIncentivesRegions[AppSettings.Regions[localStorage.getItem('region_code')]]
        }
        // If BAT region code not found by 'region' or 'region_code' local storage values, default to gst
        if (!batRegion) {
            batRegion = "gst"
        }

        const zip = localStorage.getItem('zip_code') || null;
        
        let formattedModelName = props.params?.model?.toLowerCase().replace(" ", "");
        formattedModelName = formattedModelName === "prime" ? "priusprime" : formattedModelName;
        const modelsList = [formattedModelName, `${formattedModelName}hybrid`];

        setRegionPath(batRegion);
        setZipCode(zip);
        setAssociatedModels(modelsList);
    }, [])

    const getZipCodeParam = () => {
        if(zipCode) {
            return `&offersZip=${zipCode}`;
        }
        return '';
    }

    const getResultsIndicatorText = () => {
        if(zipCode) {
            return <>ZIP code: <b>{zipCode}</b></>;
        } else {
            const regionName = Object.keys(AppSettings.BATIncentivesRegions).find(k => AppSettings.BATIncentivesRegions[k] === regionPath);
            return <>Region: <b>{regionName || 'Gulf States'}</b></>
        }
    }

    return (
        <div className={`local-incentives-page ${props?.params?.isCompact ? 'compact' : ''}`}>
            {!props?.params?.isCompact && <div className="local-incentives-title">
                INCENTIVES
            </div>}
            <div className="local-incentives-results-indicator">
                <p>Showing results for {getResultsIndicatorText()}</p>
            </div>
            <div className="local-incentives-iframe">
                <iframe
                    src={`https://www.buyatoyota.com/${regionPath}/landing/toyota-engage-frame/index.page?filters=${associatedModels}${getZipCodeParam()}`}
                    className="incentivesIFrame"
                    id="incentivesIFrame"
                    frameborder="0"
                />
            </div>
        </div>
    );
};

export default LocalIncentives;
