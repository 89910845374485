import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import AppSettings from '../../appsettings';
import '../../styles/css/searchBarInput.css';
import {AdvancedSearch} from '../../screens/searchBar/advancedSearch'
import { isMobileView } from '../../screens/utils/getScreenSize';
import * as analytics from '../../screens/searchBar/searchBar.analytics';
import MobileBackButton from './MobileBackButton';


const SearchBarInput = (props) => {
  const [inputValue, setInputValue] = useState("");
  const filePath = AppSettings.AWSImgRepo.resources + 'en/';
  const [showModal, setShowModal] = useState(false);
  const inputRef = useRef();

  useEffect(()=>{
    props.searchParam ? setInputValue(props.searchParam) : setInputValue("");

  },[props.searchParam])

  useEffect(() => {
    if(inputValue === ""){
      props.clearClicked && props.clearClicked();
    }
  }, [inputValue]);

  const runSearch = (value) => {
    !props.dynamicSearching && searchAnalytics(value)
    props.onSearch(value);
    props.searchClicked && inputValue != "" && props.searchClicked();
  };
  const clearSearch = () => {
    props.clickAnalytics && analytics.clearIconClick(props.clickAnalytics, inputValue, props.numberOfSearchResults, props.analyticsPositionTitle);
    inputRef.current.focus();
    setInputValue('');
    props.clearClicked && props.clearClicked()
    if(props.dynamicSearching){
      props.onSearch('');
    }
  };
  const closeModal = () => {
    setShowModal(false);
  }
  const openAdvancedSearchModal = () => {
    analytics.advancedSearchClick(props.clickAnalytics, props.searchParam, props.numberOfSearchResults);
    setShowModal(true);
  }
  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      runSearch(inputValue);
      e.target.blur();
    }
    else if(props.dynamicSearching){
      props.onSearch(inputValue);
    }
  };
  const searchAnalytics = (value) => {
    (value != "" && props.clickAnalytics) && analytics.searchIconClick(props.clickAnalytics, value, props.numberOfSearchResults, props.analyticsPositionTitle);
  }

  const Icons = () => {
    return (
      <div className="search-bar-icons">
        <img
          className={props.dynamicSearching ? "search-bar-icons-search" : "search-bar-icons-search selectable"}
          src={filePath + "red_search.png"}
          onClick={() => runSearch(inputValue)}
          alt="Search"
          style={props.showSearchIcon != null && props.showSearchIcon === false ? {display: 'none'} : {}}
        />
        <img
          className="search-bar-icons-close"
          src={filePath + "icon_close_black.svg"}
          onClick={() => clearSearch()}
          alt="Clear"
          style={props.showCloseIcon != null && props.showCloseIcon === false ? {display: 'none'} : {}}
        />
      </div>
    );
  };

  return (
    <div className="search-bar-search-results">
      {
        window.ReactNativeWebView && props.showBackButton &&
        <div style={{width: '10vw'}} >
          <MobileBackButton style={{top: -6}}/>
        </div>
      }
      <div className="search-bar-input">
        <input
          ref={inputRef}
          style={!props.advancedSearch && isMobileView(window.innerWidth) ? { width: '30vw'} : {width: window.innerWidth * 0.4}}
          id="search-bar-input-field" 
          className="search-bar-input-field" 
          placeholder={props.placeholder}
          onKeyUp={(e) => handleEnterKey(e)}
          defaultValue={inputValue}
          autoFocus={props.autoFocus}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={() => props.dynamicSearching && searchAnalytics(inputValue)}
          value={inputValue}
          autoComplete= "off"
        />
        <Icons />
      </div>
      {props.advancedSearch && (
        <div className = "search-bar-advancedSearch-icon">
          <img
            alt=""
            src={filePath + "gear_Icon.png"}
            className="search-results-icon"
            onClick={() => { openAdvancedSearchModal(); }}
          />
          <AdvancedSearch
            updateAdvancedSearchParam = {props.updateAdvancedSearchParam}
            imagePath = {filePath}
            searchParam = {props.searchParam}
            open = {showModal}
            close = {closeModal}
          />
        </div>
      )}
    </div>
  );
};

export default SearchBarInput;

SearchBarInput.propTypes = {
  searchParam: PropTypes.string,
  autoFocus: PropTypes.bool,
  clickAnalytics: PropTypes.bool,
  numberOfSearchResults: PropTypes.number,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  advance_Search: PropTypes.bool,
  updateAdvancedSearchParam: PropTypes.func,
  dynamicSearching: PropTypes.bool,
};