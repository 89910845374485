import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();

const contentLoad = (tag, section, page, description, keyword, numberOfSearchResults) => {
    analytics.firePageLoad({
        tag,
        section,
        page,
        description,
        keyword,
        numberOfSearchResults,
    });
};
export const searchLoaded = (pageLoad, description, keyword, numberOfSearchResults) => {
    (pageLoad) && contentLoad(
        pageLoad.tagId,
        pageLoad.section,
        pageLoad.page,
        description,
        keyword,
        numberOfSearchResults,
    );
};

const contentLinkClick = (tag, section, category, positionTitle, contentTitle, mediaTitle, label, container, modelYear, modelName, keyword, numberOfSearchResults) => {
    analytics.fireOnClick({
        tag,
        section,
        category,
        positionTitle,
        contentTitle,
        mediaTitle,
        label,
        container,
        modelYear,
        modelName,
        keyword,
        numberOfSearchResults,
    });
};

export const exploreButtonClicked = (clickAnalytics, positionTitle, contentTitle, label, modelYear, modelName, keyword, numberOfSearchResults) => {
    (clickAnalytics) && contentLinkClick(
        clickAnalytics.tagId,
        undefined,
        clickAnalytics.exploreButtonSelection.category,
        positionTitle,
        contentTitle,
        undefined,
        label,
        clickAnalytics.exploreButtonSelection.container,
        modelYear,
        modelName,
        keyword,
        numberOfSearchResults,
    );
};

export const filterClicked = (clickAnalytics, positionTitle, label, keyword, numberOfSearchResults) => {
    (clickAnalytics) && contentLinkClick(
        clickAnalytics.tagId,
        clickAnalytics.section,
        clickAnalytics.searchFilter.category,
        positionTitle,
        undefined,
        undefined,
        label,
        clickAnalytics.section,
        undefined,
        undefined,
        keyword,
        numberOfSearchResults,
    );
};

export const scrollButtonClicked = (clickAnalytics, keyword, numberOfSearchResults) => {
    (clickAnalytics) && contentLinkClick(
        clickAnalytics.tagId,
        undefined,
        clickAnalytics.jumpButtonSelection.category,
        clickAnalytics.jumpButtonSelection.positionTitle,
        undefined,
        undefined,
        clickAnalytics.jumpButtonSelection.label,
        clickAnalytics.jumpButtonSelection.container,
        undefined,
        undefined,
        keyword,
        numberOfSearchResults,
    );
};

export const navLinkGoClicked = (clickAnalytics, keyword) => {
    (clickAnalytics) && contentLinkClick(
        clickAnalytics.tagId,
        undefined,
        clickAnalytics.navLinkSelection.category,
        undefined,
        undefined,
        undefined,
        clickAnalytics.navLinkSelection.labelSearchGo,
        clickAnalytics.navLinkSelection.container,
        undefined,
        undefined,
        keyword,
        undefined,
    );
};

export const navLinkSearchIconClicked = (clickAnalytics, keyword) => {
    (clickAnalytics) && contentLinkClick(
        clickAnalytics.tagId,
        undefined,
        clickAnalytics.navLinkSelection.category,
        undefined,
        undefined,
        undefined,
        clickAnalytics.navLinkSelection.labelSearchIcon,
        clickAnalytics.navLinkSelection.container,
        undefined,
        undefined,
        keyword,
        undefined,
    );
};

export const navLinkCloseClicked = (clickAnalytics, keyword) => {
    (clickAnalytics) && contentLinkClick(
        clickAnalytics.tagId,
        undefined,
        clickAnalytics.navLinkSelection.category,
        undefined,
        undefined,
        undefined,
        clickAnalytics.navLinkSelection.labelHideSearch,
        clickAnalytics.navLinkSelection.container,
        undefined,
        undefined,
        keyword,
        undefined
    );
};

export const paginationButtonClicked = (clickAnalytics, positionTitle, label, keyword, numberOfSearchResults) => {
    (clickAnalytics) && contentLinkClick(
        clickAnalytics.tagId,
        undefined,
        clickAnalytics.paginationButtons.category,
        positionTitle,
        undefined,
        undefined,
        label,
        clickAnalytics.paginationButtons.container,
        undefined,
        undefined,
        keyword,
        numberOfSearchResults,
    );
};

export const resultClicked = (clickAnalytics, positionTitle, contentTitle, label, keyword, numberOfSearchResults) => {
    (clickAnalytics) && contentLinkClick(
        clickAnalytics.tagId,
        undefined,
        clickAnalytics.resultSelection.category,
        positionTitle,
        contentTitle,
        undefined,
        label,
        clickAnalytics.section,
        undefined,
        undefined,
        keyword,
        numberOfSearchResults,
    );
};

export const thumbnailClicked = (clickAnalytics, positionTitle, contentTitle, mediaTitle, keyword, numberOfSearchResults) => {
    (clickAnalytics) && contentLinkClick(
        clickAnalytics.tagId,
        clickAnalytics.section,
        clickAnalytics.category,
        positionTitle,
        contentTitle,
        mediaTitle,
        clickAnalytics.thumbnailSelection.label,
        clickAnalytics.section,
        undefined,
        undefined,
        keyword,
        numberOfSearchResults,
    );
};

export const moreResultsClicked = (clickAnalytics, keyword, numberOfSearchResults) => {
    (clickAnalytics) && contentLinkClick(
        clickAnalytics.tagId,
        null,
        clickAnalytics.moreResults.category,
        clickAnalytics.moreResults.positionTitle,
        null,
        null,
        clickAnalytics.moreResults.label,
        clickAnalytics.moreResults.container,
        null,
        null,
        keyword,
        numberOfSearchResults,
    );
};

export const expandCollapseAnswers = (clickAnalytics, category, positionTitle, contentTitle, label, modelYear, modelName, keyword, numberOfSearchResults) => {
    (clickAnalytics) && contentLinkClick(
        clickAnalytics.tagId,
        null,
        category,
        positionTitle,
        contentTitle,
        null,
        label,
        clickAnalytics.container,
        modelYear,
        modelName,
        keyword,
        numberOfSearchResults,
    );
};

export const enhancedSearchClick = (clickAnalytics, positionTitle, contentTitle, label, modelYear, modelName, keyword, numberOfSearchResults) => {
    (clickAnalytics) && contentLinkClick(
        clickAnalytics.tagId,
        null,
        clickAnalytics.enhancedSearch.categoryRichAnswers,
        positionTitle,
        contentTitle,
        null,
        label,
        clickAnalytics.container,
        modelYear,
        modelName,
        keyword,
        numberOfSearchResults,
    );
};

export const searchIconClick = (clickAnalytics, keyword, numberOfSearchResults, positionTitle) => {
    clickAnalytics && contentLinkClick(
        clickAnalytics.tagId,
        null,
        clickAnalytics.searchIconClick.category,
        positionTitle,
        null,
        null,
        clickAnalytics.searchIconClick.label,
        clickAnalytics.container,
        null,
        null,
        keyword,
        numberOfSearchResults
    );
}

export const clearIconClick = (clickAnalytics, keyword, numberOfSearchResults, positionTitle) => {
    clickAnalytics && contentLinkClick(
        clickAnalytics.tagId,
        null,
        clickAnalytics.clearIconClick.category,
        positionTitle,
        null,
        null,
        clickAnalytics.clearIconClick.label,
        clickAnalytics.container,
        null,
        null,
        keyword,
        numberOfSearchResults
    );
}

export const advancedSearchClick = (clickAnalytics, keyword, numberOfSearchResults) => {
    clickAnalytics && contentLinkClick(
        clickAnalytics.tagId,
        null,
        clickAnalytics.advancedSearchClick.category,
        null,
        null,
        null,
        clickAnalytics.advancedSearchClick.label,
        clickAnalytics.container,
        null,
        null,
        keyword,
        numberOfSearchResults
    );
} 