import React, { cloneElement, useState, useCallback, useEffect } from 'react';
import closeButton from '../../../resources/images/cancel.png';
import { getLocale } from '../../../services/languageTranslationService';
import applyCustomMarkdown from '../../utils/applyCustomMarkdown';
import { getS3PathName } from '../../utils/vehicleName';
import AppSettings from '../../../appsettings';
import '../../../styles/css/ammCarDisplay.css';

const CarDisplay = props => {
    const [imgSrc, setImgSrc] = useState('');
    const modelName = props.contentSelected.selectionModel.toLowerCase();
    const noImgSrc = `${AppSettings.AWSImgRepo.vehicle}${getLocale()}/no_image_available.png`;

    const getImgSrcValue = useCallback(async () => {
        const s3PathName = await getS3PathName(modelName);
        const s3Path = `${props.contentSelected.selectionYear}/${s3PathName}/${getLocale()}/`;
        const newImgSrc = `${AppSettings.AWSImgRepo.vehicle}${s3Path}${props.contentSelected.imageUrl}`;
        setImgSrc(encodeURI(newImgSrc));
    }, [modelName, props.contentSelected.imageUrl, props.contentSelected.selectionYear])

    useEffect(() => {
        getImgSrcValue();
    }, [getImgSrcValue])

    const compareFeatures = () => {
        props.setModalContent(cloneElement(props.modalContentComponent, { contentSelected: props.contentSelected }))
        props.clickFunctions && props.clickFunctions(props.contentSelected.selectionYear, props.contentSelected.selectionModel, props.contentSelected.selectionTrim)
        props.compareFeaturesAnalytics && props.compareFeaturesAnalytics(props.contentSelected.selectionYear, props.contentSelected.selectionModel, props.contentSelected.selectionTrim)
    }

    const clearSelection = () => {
        props.parentCallback()
        props.closeCompareFeaturesAnalytics && props.closeCompareFeaturesAnalytics(props.contentSelected.selectionYear, props.contentSelected.selectionModel, props.contentSelected.selectionTrim)
    }

    return (
        <div>
            <div className="clearSelection" onClick={()=>clearSelection()}><img src={closeButton} /></div>
            <div className="carDisplay">
                <div className="carImage">
                    <img src={props.contentSelected.imageUrl != undefined ? imgSrc : noImgSrc}/>
                </div>
                <div className="infoSection">
                    <div className="nameSection">
                        <div className="carName">{applyCustomMarkdown(props.contentSelected.selectedCar, "bold")}</div>
                    </div>
                    <div className="audioSection">
                        <div className="standardSystem">
                            <div className="systemTitle">Standard System</div>
                            <div className="systemOption">{applyCustomMarkdown(props.contentSelected.standardSystem.title, "bold")}</div>
                        </div>
                        <div className="optionalSystem">
                            <div className="systemTitle">Optional System</div>
                            <div className="systemOption">{props.contentSelected.optionalSystem.title ? applyCustomMarkdown(props.contentSelected.optionalSystem.title, "bold") : applyCustomMarkdown('Not Available', "bold")}</div>
                        </div>
                    </div>
                </div>
                <div className='buttonSection'>
                    <div className='featuresButton' onClick={()=>compareFeatures()}>
                        <span>Compare Features</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CarDisplay;
