import React from 'react';
import PropTypes from 'prop-types';
import applyMarkdown from '../../../screens/utils/applyMarkdown';
import '../../../styles/css/marketingPageWideCard.css';
import { isDesktopView, isMobileView, isTabletView } from '../../../screens/utils/getScreenSize';
import '../../../styles/css/marketingPageCtaWideCard.css';

const MarketingCTAWideCard = (props) => {

    
    const imageAnalytics = (href) => {
        if (href.includes('apple')){
            props.clickFunctions.appleIconClick(props.clickAnalytics,props.pageName)
        }
        else{
            if(href.includes('google')){
                props.clickFunctions.googleIconClick(props.clickAnalytics,props.pageName)
            }
        }
    };

    const linkAnalytics = (href) => {
        if (href.includes('apple')){
            props.clickFunctions.appleDownloadClick(props.clickAnalytics,props.pageName)
        }
        else{
            if(href.includes('google')){
                props.clickFunctions.googleDownloadClick(props.clickAnalytics,props.pageName)
            }
        }
    };
    
    const getContent = props.ctas.map((cta, index)=>(
        <div className='ctaCard-content' key={index}>
            <div className='ctaCard-appImage'>
                <a onClick={()=>imageAnalytics(cta.ctaHref)}href={cta.ctaHref} target="_blank">
                    <img className='ctaCard-appImage' src={props.imagePath + cta.image}/>
                </a>
            </div>
            <div className='ctaCard-description'>{applyMarkdown(cta.description)}</div>
            <div className='ctaCard-link'>
                <a onClick={()=>linkAnalytics(cta.ctaHref)} href={cta.ctaHref} target="_blank">{cta.ctaText}</a>
            </div>
        </div>
    ));

    if (isDesktopView(window.innerWidth) || isTabletView(window.innerWidth)) {
        return (
            <div data-testid="cta-card" className = "ctaCard">
                {props.title && <div className="ctaCard-Title">{applyMarkdown(props.title)}</div>}
                <div className = "ctaCard-mainImage">
                    <img src={props.imagePath + props.image}/>
                </div>
                {getContent}
            </div>
        )
    }
    else {
        return (
            <div data-testid="cta-card" className = "ctaCard">
                {props.title && <div className="ctaCard-Title">{applyMarkdown(props.title)}</div>}
                {getContent}
                <div className="ctaCard-mainImage">
                    <img src={props.imagePath + props.image}/>
                </div>
            </div>
        )
    }
};

MarketingCTAWideCard.propTypes = {
    title: PropTypes.string,
    imagePath: PropTypes.string,
    image: PropTypes.string,
    ctas: PropTypes.array
};

export default MarketingCTAWideCard;
