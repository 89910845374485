import React from 'react';
import PropTypes from 'prop-types';

import '../../styles/css/bodyText.css';

const BodyText = (props) => {
  const StyleName = props.className || '';

  return (
        <div className={`body-text ${StyleName}`} style={{ ...props.style }}>
        {props.children}
        </div>
  );
};

export default BodyText;


BodyText.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
};
