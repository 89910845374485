import ReactSpeedometer from 'react-d3-speedometer';
import '../../../styles/css/towingPayload-calculatorCard.css';
import { useRef } from 'react';
import { InfoPopUp } from '../towingPayload-infoPopUp';
import { isMobileView } from '../../../screens/utils/getScreenSize';
import isSafari from '../../../screens/utils/browserDetectors';
import { TooltipV2 } from '../../../components/molecules/tooltipV2';
import { valueEntry, iIconClick, popupOpenClick, popupCloseClick, popupMoreClick } from '../towingPayload-analytics';

const CalculatorCards = (props) => {
    const inputRef = useRef();

    const getLabel = (value) => {
        return value === 1 ? "lb." : "lbs.";
    }

    const getColor = () => {
        switch (props.title) {
            case "Payload":
                return "#57C2D7";
            case "Towing":
                return "#72B85F";
            default:
                return "#818386";
        }
    };

    const SvgPattern = () => {
        return (
            <svg className={"svg-pattern"}>
                <pattern id="blue" width="1" height="1">
                    <image width="200" height="200" xlinkHref={`${props.imagePath}blueWithStripesPattern.png`} />
                </pattern>
            </svg>
        );
    };

    const infoIconClick = (i) => {
        props.setCurrentPopupTooltip(i);
        props.clickAnalytics && iIconClick(props.clickAnalytics, props.vehicleSelection, props.trimSelection, props.title);
    };

    const getInfoPopup = () => (
        <TooltipV2
            closeTooltip={()=>props.setVisiblePopupTooltip(null)}
            title={props.infoPopUpData.title}
            subtitle={props.infoPopUpData.subtitle}
            description={props.infoPopUpData.definition}
            analyticsOpenTooltip={()=>popupOpenClick(props.clickAnalytics, props.vehicleSelection.modelYear, props.vehicleSelection.modelName, props.trimSelection, props.infoPopUpData.title, true)}
            analyticsCloseTooltip={()=>popupCloseClick(props.clickAnalytics, props.vehicleSelection.modelYear, props.vehicleSelection.modelName, props.trimSelection, props.infoPopUpData.title, true)}
            analyticsMoreClick={()=>popupMoreClick(props.clickAnalytics, props.vehicleSelection.modelYear, props.vehicleSelection.modelName, props.trimSelection, props.infoPopUpData.title, "MORE", true)}
        />
    );

    const CardTitle = () => {
        return (
            <div className={"calc-card-title"}>
                <div className="titleText">{props.title}</div>
                <div onClick={()=>infoIconClick(props.termKey)}><img className={"i-icon"} src={`${props.imagePath}infoIcon.png`} /></div>
                <div className={`gaugeInfoPopUp ${props.termKey}-popup`}>
                    {!isMobileView(window.innerWidth) && props.checkCurrentTooltipMatch(props.termKey) && getInfoPopup()}
                </div>
                {isMobileView(window.innerWidth) && props.checkCurrentTooltipMatch(props.termKey) && getInfoPopup()}

            </div>
        );
    };

    const CardContent_Image = () => {
        return (
            <div className={"calc-card-image"}>
                <img src={props.rotatedJelly} />
            </div>
        );
    };

    const CardContent_Gauge = () => {

        const NeedleDot = () => {
            return (
                <div className={"calc-card-dot"}>
                    <svg><circle cx={10} cy={10} r={10} fill={"black"} /></svg>
                </div>
            );
        };

        const getClassName = () => {
            if (props.title === "Payload" && !props.error) {
                return "calc-card-gauge blue-segment-visible";
            }
            return "calc-card-gauge";
        };

        const getSegmentsAndColors = () => {
            if (props.error) {
                return [[0, props.maxValue], ['#EA0C1E']]; 
            }
            return [[0, props.value, props.maxValue], [getColor(), '#E9EAEA']];
        };

        return (
            <>
                <div className={getClassName()}>
                    <ReactSpeedometer
                        customSegmentStops={getSegmentsAndColors()[0]}
                        segments={getSegmentsAndColors()[1].length}
                        segmentColors={getSegmentsAndColors()[1]}
                        maxSegmentLabels={0}
                        minValue={0}
                        maxValue={props.maxValue}
                        value={_.clamp(props.value, 0, props.maxValue)}
                        needleColor={'black'}
                        width={186}
                        needleHeightRatio={0.6}
                        ringWidth={40}
                    />
                </div>
                <NeedleDot />
                <div className={"calc-card-labels"}><div>{`0 lbs.`}</div><div>{`${props.maxValue.toLocaleString()} ${getLabel(props.maxValue)}`}</div></div>
            </>   
        );
    };

    const submitValue = () => {
        if (!(parseInt(inputRef.current.value) > 0)) {
            inputRef.current.value = 0;
        }
        props.setValue(parseInt(inputRef.current.value));
        props.clickAnalytics && valueEntry(props.clickAnalytics, props.vehicleSelection, props.trimSelection, props.title, inputRef.current.value);
        inputRef.current.value = props.value; 
    }

    const handleKeyPress = (e) => {
        inputChanged = true;
        if (e && e.key === "Enter") { 
            submitValue();
        } 
        if (inputRef.current.value.length > 3) {
            inputRef.current.value = inputRef.current.value.trim(0, 3);
        }
    };

    const handleOnFocus = () => {
        inputChanged = false;
        selectText();
    }

    const handleOnBlur = () => {
        if (inputChanged || inputRef.current.value === "") {
            submitValue();
        }
    }

    const selectText = () => {
        inputRef.current.focus();
        inputRef.current.select();
    };

    let inputChanged = false;

    const CardValue = () => {
        const style = props.title === "Payload" ? {backgroundImage: `url(${props.imagePath}blueWithStripesPattern.png)`, backgroundSize: "cover"} : {backgroundColor: getColor()};
        const inputColor = props.title === "Payload" ? "#3996A8" : "#478f35";
        if (props.expertMode && !props.isImg) {
            return (
                <div className={"calc-card-value"} style={style}>
                    <input
                        style={{backgroundColor: inputColor}}
                        type="text"
                        defaultValue={props.value}
                        maxLength={5}
                        ref={inputRef}
                        onKeyDown={(e) => handleKeyPress(e)}
                        onBlur={() => handleOnBlur()}
                        onClick={() => handleOnFocus()}
                    />
                    <span>{getLabel(props.value)}</span>
                </div>
            );
        }
        return (
            <div className={"calc-card-value"} style={style} onClick={() => props.setVisiblePopupTooltip && props.setVisiblePopupTooltip("beginnerMessage")}>
                <span>{`${props.value} ${getLabel(props.value)}`}</span>
            </div>
        );
    };

    const CardFooter = () => {
        const amountRemaining = _.clamp((props.maxValue - props.value), 0, props.maxValue);
        return (
            <div className={"calc-card-footer"}>
                {`(${amountRemaining.toLocaleString()} ${getLabel(amountRemaining)} remaining)`}
            </div>
        );
    };

    const ImportantNote = () => {
        if (props.title === "Payload") {
            const content = { title: props.importantNote.text1, definition: props.importantNote.text2 };
            const safariMargin = isSafari() ? "important-note-safari-margin" : "";
            return (
                <>
                    <div className={`calc-card-important-note ${safariMargin}`} onClick={()=>infoIconClick(props.importantNote.name)}>{props.importantNote.text1}</div>
                    <div className="important-note-info-popup">
                        {props.checkCurrentTooltipMatch(props.importantNote.name) && <InfoPopUp closePopUp={()=>props.setVisiblePopupTooltip(null)} content={content} moreLinkVisible={false} />}
                    </div>
                </>
            );
        }
        return null;
    };

    const marginBottom = props.isImg ? "margin-bottom" : "";

    return (
        <div className={`calculator-card ${marginBottom}`}>
            <CardTitle />
            {props.isImg ? <CardContent_Image /> : <CardContent_Gauge />}
            <CardValue />
            {!props.isImg && <CardFooter />}
            <ImportantNote />
            <SvgPattern />
        </div>
    );
};

export default CalculatorCards;