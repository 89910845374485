import { GlobalAnalytics } from "../../../analytics";

const analytics = new GlobalAnalytics();

// Tracking click for a Check Balance click
export const checkMyBalanceClick = (clickAnalytics) => {
    if (clickAnalytics) {
      analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        app: clickAnalytics.appId,
        section: clickAnalytics.section,
        page: "Profile",
        category: "Profile Header",
        label: "Check My Balance Button Click"
      });
    }
};

export const editProfilePicClick = (clickAnalytics) => {
  console.log(clickAnalytics)
    if (clickAnalytics) {
      analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        app: clickAnalytics.appId,
        section: clickAnalytics.section,
        page: "Profile",
        category: "Profile Header",
        label: "Edit profile pic click"
      });
    }
};

export const profilePageSideBarMenuClick = (clickAnalytics, label) => {
    if (clickAnalytics) {
      analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        app: clickAnalytics.appId,
        section: clickAnalytics.section,
        page: "Profile",
        category: "Profile SideNav tab click",
        label: label
      });
    }
};