import { Component } from 'react';
import PropTypes from 'prop-types';
import { AwardsTemplate } from './awards.tpl';
import getAwards from '../../services/awardsService.js';


export default class AwardsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      awards: [],
    };
    this.getAwards = this.getAwards.bind(this);
  }

  getAwards(modelName, modelYear, lang) {
    try {
      if (this.props.awards) {
        this.setAwards(this.props.awards);
      } else {
        getAwards(`${modelName}-${lang}`, modelYear)
          .then((payload) => {
            this.setAwards(payload.awards);
          });
      }
    } catch (err) {
      console.log(`Error getting awards: ${err}`);
    }
  }

  setAwards(awardsArray) {
    this.setState({
      awards: awardsArray,
    });
  }

  componentWillReceiveProps(nextProp) {
    this.getAwards(nextProp.modelName, nextProp.modelYear, nextProp.lang);
  }

  componentDidMount() {
    this.getAwards(this.props.modelName, this.props.modelYear, this.props.lang);
  }

  render() {
    return AwardsTemplate(this);
  }
}

AwardsComponent.propTypes = {
  modelName: PropTypes.string,
  modelYear: PropTypes.string,
  lang: PropTypes.string,
  awards: PropTypes.array,
  imagePath: PropTypes.string,
  disclaimer: PropTypes.node,
};
