import React from 'react';
import PropTypes from 'prop-types';
import TitleText from '../../../components/atoms/TitleText';
import overviewVideoPlayer from './videoContainer';
import '../../../styles/css/model-overview/featured-content.css';
import CarouselComponent from '../../carousel/carousel.component';
import applyMarkdown from '../../utils/applyMarkdown';
import { hasVideoId } from '../../utils/helpers';

const analytics = require('../modelOverview.analytics');

const featuredItems = (featuredContent, styles, imagePath, disclaimer) => (
  featuredContent.map((item, index) => (<div className="featured-content-item" key={index}>
    {hasVideoId(item.brightcoveId, item.qumuId)
      ? <div className="featured-content-video" onClick={() => analytics.fireModelOverviewClick('Video Image Click', item.title, 'Featured Content')}>{overviewVideoPlayer(item.brightcoveId, item.qumuId, item.title)}</div>
      : <a target='_blank' href={item.link ? item.link : null} onClick={() => analytics.fireModelOverviewClick('Article Image Click', item.title, 'Featured Content')}>
        <div className="featured-content-image" style={{ backgroundImage: `url("${imagePath + item.thumbnailImage}")` }}></div>
        </a>
      }
    <div className="featured-content-item-title" style={{ color: (styles.textColor ? styles.textColor : '#000000') }}>{applyMarkdown(item.title, disclaimer)}</div>
  </div >))
);

function NextArrow(props) {
  const {
    customClassName, style, onClick, margin,
  } = props;
  return (
    <div className={`${customClassName} slick-next`} style={{ ...style, marginRight: margin }} onClick={() => { analytics.fireModelOverviewClick('Carousel', 'Next', 'Featured Content'); onClick(); }}></div>
  );
}
function PrevArrow(props) {
  const {
    customClassName, style, onClick, margin,
  } = props;
  return (
    <div className={`${customClassName} slick-prev`} style={{ ...style, marginLeft: margin }} onClick={() => { analytics.fireModelOverviewClick('Carousel', 'Previous', 'Featured Content'); onClick(); }}></div>
  );
}

export default function FeaturedContentSection(props) {
  const styles = props.styles ? props.styles : null;
  const settings = {
    adaptiveHeight: false,
    respondTo: 'window',
    zIndex: 0,
    dots: true,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="model-overview-web-content">
      <div className="featured-content-section" style={{ backgroundColor: (styles.backgroundColor ? styles.backgroundColor : '#FFFFFF') }}>
        <TitleText className="section-title">Featured Content</TitleText>
        <div className="row">
          <div className="carousel-container">
            <CarouselComponent settings={settings}>
              {featuredItems(props.featuredContent, styles, props.imagePath, props.disclaimer)}
            </CarouselComponent>
          </div>
        </div>
      </div>
    </div>
  );
}

FeaturedContentSection.propTypes = {
  imagePath: PropTypes.string,
  featuredContent: PropTypes.array.isRequired,
  styles: PropTypes.object,
  disclaimer: PropTypes.node,
};
