import React, { useRef }from 'react';
import { FormattedMessage } from 'react-intl';
import applyMarkdown from '../../../utils/applyMarkdown';
import { getFormattedName } from '../../../utils/vehicleName';
import Select from '../../../select/select.component';
import AppSettings from '../../../../appsettings.js';
import DisclaimersComponent from '../../../disclaimers/disclaimers.component';

export default function VehicleHeader(props) {
  const getCurrentSelectedName = () => `${props.item.vehicleYear} ${getFormattedName(props.item.vehicleModel)} ${props.item.selectedValueToyota}`;

  const toyotaOptions = () => props.item.dbVehicles.map(dropdownOption => `${props.item.vehicleYear} ${getFormattedName(props.item.vehicleModel)} ${dropdownOption.gradeAndTrim}`);
  const disclaimerComponentRef = useRef();

  const ToyotaDropdown = () => (
        <div className="columns large-6 medium-6 small-6">
            <img alt="" className="dropdown-image toyota-image" src={AppSettings.AWSImgRepo.edge + props.item.s3Path + props.item.currentEdge.edgeContent.vehicleInfo.toyotaImage} />
            <Select
                name="toyota-dropdown"
                value={getCurrentSelectedName()}
                onChange={props.updateDropdown}
                options={toyotaOptions()}
                customStyle="edge-dropdown"
            />
            {props.item.currentEdge.edgeContent.vehicleInfo.toyotaMSRP != null
              ? <div className="toyota-msrp">
                    <span>
                        {props.item.currentEdge.edgeContent.vehicleInfo.toyotaMSRP.startsWith('$') ? '' : '$'}{applyMarkdown(props.item.currentEdge.edgeContent.vehicleInfo.toyotaMSRP, props.disclaimer)}&nbsp;Base MSRP
                        <sup className="disclaimer-link" onClick={() => disclaimerComponentRef.current.onOpenModal()}>&dagger;</sup>
                    </span>
              </div>
              : <div />}
            <div className="toyota-footnote">
                {props.item.currentEdge.edgeContent.vehicleInfo.toyotaMSRPFootnote ? applyMarkdown(props.item.currentEdge.edgeContent.vehicleInfo.toyotaMSRPFootnote) : ''}
            </div>
        </div>
  );

  const CompetitorDropdown = () => (
        <div className="columns large-6 medium-6 small-6">
            <img alt={props.item.sampleImage} className="dropdown-image" src={AppSettings.AWSImgRepo.edge + props.item.s3Path + props.item.currentEdge.edgeContent.vehicleInfo.competitorImage} />
            <Select
                name="competitor-dropdown"
                value={props.item.selectedValueCompetitor}
                onChange={props.updateDropdown}
                options={props.item.competitorDropdown}
                customStyle="edge-dropdown"
            />
            {props.item.currentEdge.edgeContent.vehicleInfo.competitorMSRP != null
              ? <div className="competitor-msrp">
                    {props.item.currentEdge.edgeContent.vehicleInfo.competitorMSRP.startsWith('$') ? '' : '$'}{applyMarkdown(props.item.currentEdge.edgeContent.vehicleInfo.competitorMSRP, props.disclaimer)}&nbsp; Base MSRP
              </div>
              : <div />}
            <div className="competitor-footnote">
                {props.item.currentEdge.edgeContent.vehicleInfo.competitorMSRPFootnote ? applyMarkdown(props.item.currentEdge.edgeContent.vehicleInfo.competitorMSRPFootnote) : ''}
            </div>
        </div>
  );

  const DisclaimerBox = () => (
        <div className="disclaimer-box">
            <div className="disclaimer-icon"><img alt={props.item.sampleImage} src={AppSettings.AWSImgRepo.resources + 'en/hazardSymbol.png'} /></div>
            <div className="disclaimer-text">
                <div>
                    <FormattedMessage id="caHeadToHead.internalUseLine" defaultMessage="This document is for internal training purposes only." />
                </div>
                <div>
                    <FormattedMessage id="caHeadToHead.publishedOn" defaultMessage="EDGE SHEET PUBLISHED ON: " />
                    {props.item.currentEdge.edgeContent.vehicleInfo.publishedOn ? props.item.currentEdge.edgeContent.vehicleInfo.publishedOn : null}
                </div>
            </div>
        </div>
  );

  return (
        <div>
            <div className="background">
                <div className="background-image"
                    style={props.item.currentEdge.edgeContent.vehicleInfo.backgroundImage ? { backgroundImage: `url(${AppSettings.AWSImgRepo.edge}${encodeURI(props.item.s3Path)}${props.item.currentEdge.edgeContent.vehicleInfo.backgroundImage})` } : null}>
                    <div className="dropdown-content">
                        <DisclaimerBox />
                        <div className="row"></div>
                        <div className="row dropdowns h2h-images">
                            <ToyotaDropdown />
                            <div className="vs">
                                <span className="vs-text"><FormattedMessage id="caHeadToHead.versus" defaultMessage="VS" /></span>
                            </div>
                            <CompetitorDropdown />
                        </div>
                    </div>
                </div>
            </div>
            <DisclaimersComponent
                ref={disclaimerComponentRef}
                addBaseMSRPDisclaimer={true}
            />
        </div>
  );
}
