import React from 'react';
import { FormattedMessage } from 'react-intl';
import applyMarkdown from '../utils/applyMarkdown';
import '../../styles/css/emailModal.css';
import '../../styles/css/smartpath-overview-contact.css';

export function SPContactForm(component) {
  return (<div className="column small-12 large-12">
        {/* <div className="interestLine1"><FormattedMessage id="smartPath.contactUs.interestLine1" /></div>
        <div className="interestLine2"><FormattedMessage id="smartPath.contactUs.interestLine2" /></div> */}

        <div className="smartpath-overview-contact-header">{applyMarkdown(component.props.data.header)}</div>
        <div className="smartpath-overview-contact-description">{applyMarkdown(component.props.data.description)}</div>
        <div className="smartpath-overview-contact-subdescription">{applyMarkdown(component.props.data.subDescription)}</div>

        {!component.state.success
          ? <div className="form">
                <span className="formLabel"><FormattedMessage id="smartPath.contactUs.dealership" /></span>
                <div className="errorMessage" id="emailError">Please add a dealership name</div>
                <input className="formInput" type="text" id="dealership" value={component.state.dealership} onChange={event => component.updateDealershipField(event.target.value)} onBlur={component.checkRequiredFields} />

                <span className="formLabel"><FormattedMessage id="smartPath.contactUs.name" /></span>
                <div className="errorMessage" id="emailError">Please add your name</div>
                <input className="formInput" type="text" id="name" value={component.state.name} onChange={event => component.updateNameField(event.target.value)} onBlur={component.checkRequiredFields} />

                <span className="formLabel"><FormattedMessage id="smartPath.contactUs.jobTitle" /></span>
                <div className="errorMessage" id="emailError">Please add a job title</div>
                <input className="formInput" type="text" id="jobTitle" value={component.state.jobTitle} onChange={event => component.updateJobTitleField(event.target.value)} onBlur={component.checkRequiredFields} />

                <div className="small-12 large-6 columns splitField start">
                    <span className="formLabel"><FormattedMessage id="smartPath.contactUs.phoneNumber" /></span>
                    <div className="errorMessage" id="emailError">Please add a phone number</div>
                    <input className="formInput" type="text" id="phoneNumber" value={component.state.phoneNumber} onChange={event => component.updatePhoneNumberField(event.target.value)} onBlur={component.checkRequiredFields} />
                </div>

                <div className="small-12 large-6 columns splitField end">
                    <span className="formLabel"><FormattedMessage id="smartPath.contactUs.emailAddress" /></span>
                    <div className="errorMessage" id="emailError">Please add an email address</div>
                    <input className="formInput" type="text" id="emailAddress" value={component.state.emailAddress} onChange={event => component.updateEmailAddressField(event.target.value)} onBlur={component.checkRequiredFields} />
                </div>

                <span className="formLabel"><FormattedMessage id="smartPath.contactUs.questions" /></span>
                <textarea className="questions" id="questions" value={component.state.questions} onChange={event => component.updateQuestionsField(event.target.value)} onBlur={component.checkRequiredFields} />
                <span className="required"><FormattedMessage id="smartPath.contactUs.required" /></span>
                <div className="buttonGroup">
                    <button disabled={!component.state.isValid} className="button" id="shareButton" onClick={component.onSubmitButtonClick}><span className="buttonText"><FormattedMessage id="smartPath.contactUs.submit" /></span></button>
                    <button className="button" id="cancelButton" onClick={component.onCancelButtonClick}><span className="buttonText"><FormattedMessage id="smartPath.contactUs.reset" /></span></button>
                </div>
            </div>
          : <div className="smartPathConfirmation column small-12">
                <FormattedMessage id="smartPath.contactUs.confirmation" />
            </div>
        }
    </div>
  );
}
