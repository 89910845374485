import AppSettings from '../../../appsettings';
import { isDesktopView } from   '../../utils/getScreenSize';
import getTarget from "../../utils/getTarget";
import RoundedButton from "../../../components/molecules/roundedButton";
import "../../../styles/css/homepage/featuredVehicle.css";
import { featuredVehilceButtonClicked } from '../homepage.analytics';

const FeaturedVehicle = (props) => {

    const getVehicleBackgroundImage = (featuredVehicle) => {
        const isMobileOrTablet = !isDesktopView(window.innerWidth-1);
        return AppSettings.AWSImgRepo.homepage_v3 + featuredVehicle[isMobileOrTablet ? 'tabletMobileImage' : 'desktopImage'];
    }

    const handleClick = (link, label, modelName, modelYear) => {
      featuredVehilceButtonClicked(props.analytics, label, modelName, modelYear);
      window.open(link, getTarget(link));
    }

  return (
    <div className="featured-vehicle-container">
      {props.featuredVehicles &&
        props.featuredVehicles.map((featuredVehicle) => (
          <div className="featured-vehicle-item" style={{
            backgroundImage: `url(${getVehicleBackgroundImage(featuredVehicle)})`
          }}>
            <span>
              <text className="featured-vehicle-item-year">{featuredVehicle.year}</text> <span className='featured-vehicle-item-name'>{featuredVehicle.vehicleName}</span>
            </span>
            <h6>
              {featuredVehicle.msrp} <span className='msrpRetailTab'> | </span>{featuredVehicle.retailDate}
            </h6>
            <div className="featured-vehicle-item-buttons-wrap">
              {featuredVehicle.buttons.map((button) => (
                <RoundedButton
                  title={button.text.toUpperCase()}
                  color={'#00000000'}
                  activeColor={'rgba(0, 0, 0, 0.28)'}
                  borderColor={'#FFFFFF'}
                  textColor={'#FFFFFF'}
                  className="darkHover"
                  onClick={() => handleClick(button.link, button.text.toUpperCase(), featuredVehicle.vehicleName, featuredVehicle.year)}
                />
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default FeaturedVehicle;
