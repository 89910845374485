import React from 'react';
import { FormattedMessage } from 'react-intl';
import applyMarkdown from '../utils/applyMarkdown';
import { hasVideoId } from '../utils/helpers';
import VideoPlayerComponent from '../videoPlayer/videoPlayer.component';
import '../../styles/css/mediaText.css';

export default function MediaTextTemplate(component) {
  function ItemImages() {
    return (
      <div>
        {
          component.props.images ? component.props.images.map((image, index) => (component.props.imageArrow
            ? <div key={index} className="item-image item-image-arrow" alt="" style={{ backgroundImage: `url(${encodeURI(component.props.imagePath + image)})` }}>
              <div className="item-arrow" />
            </div>
            : <div key={index} className="item-image" alt="">
              <img alt="" src={component.props.imagePath + image} />
            </div>
          )) : null
        }
      </div>
    );
  }

  // const hasVideoId = () => !!component.props.videoId || !!component.props.qumuId;

  function ItemMedia() {
    const page = window.location.pathname.split('/');
    const pageTitle = page[1];
    const videoItem = {
      alias: component.props.videoId,
      qumuId: component.props.qumuId,
      ...component.props.item,
      videoTitle: component.props.title,
      pageTitle,
    };
    return (
      <div className="item-media">
        {hasVideoId(component.props.videoId, component.props.qumuId)
          && <div className="item-video">
            <VideoPlayerComponent
              item={videoItem}
              sendVideoEventAnalytics={component.props.sendVideoEventAnalytics}
            />
          </div>
        }
        {ItemImages()}
      </div>
    );
  }

  function SingleItemButton(buttonText, url) {
    return (
      <div className="item-button">
        <a target={component.props.openInParent ? '_parent' : '_blank'}
          href={url}
          onClick={component.props.linkClick ? component.props.linkClick : null}>
          {buttonText.toUpperCase()}
        </a>
      </div>
    );
  }

  function MultipleItemButton(files) {
    return (
      <div>
        {
          files.map((file, index) => (
            <div key={index}>
              {SingleItemButton(file.buttonText, component.formatImageLink(file.fileName))}
            </div>
          ))
        }
      </div>);
  }

  function ReadMoreButton() {
    return (
      <div className="item-button" onClick={e => component.toggleReadMore(e.target.innerText)}>
        {component.state.isTextExpanded ? <FormattedMessage id="global.readLess" /> : <FormattedMessage id="global.readMore" />}
      </div>
    );
  }

  function AvailableOn(availableOn) {
    return (
      <div>
        <p className="AvailableOn"><FormattedMessage id="entuneFeatures.availableOn" defaultMessage="Availability:" /></p>
        {availableOn.map((availability, index) => (<p key={index} className="availableVersions">{applyMarkdown(availability, component.props.disclaimer)}</p>))}
      </div>
    );
  }

  function ItemText() {
    return (
      <div className={component.props.images || component.props.videoId ? 'item-text media-text' : 'item-text text-only'}>
        {component.props.title
          ? <div>
            <div className="item-title">{applyMarkdown(component.props.title, component.props.disclaimer)}</div>
            <div className="divide" />
          </div> : null}
        {component.props.subtitle
          ? <div className="item-subtitle">
            {applyMarkdown(component.props.subtitle, component.props.disclaimer)}
          </div> : null}
        {component.props.availableOn ? AvailableOn(component.props.availableOn) : null}
        {component.props.description
          ? <div className={component.state.isTextExpanded || !component.shouldDisplayReadMore() ? 'item-description description-expanded' : 'item-description description-hidden'}>
            {applyMarkdown(component.props.description, component.props.disclaimer, null, component.props.enableCustomLinks)}
          </div> : null}
        {component.shouldDisplayReadMore() ? ReadMoreButton() : null}
        {component.props.buttonText && component.props.fileName ? SingleItemButton(component.props.buttonText, component.formatImageLink(component.props.fileName)) : null}
        {component.props.buttonText && component.props.link ? SingleItemButton(component.props.buttonText, component.props.link) : null}
        {component.props.files ? MultipleItemButton(component.props.files) : null}
      </div>
    );
  }

  return (
    <div className="media-text-item-container" key={component.props.index} id={component.props.id}>
      <div className={component.itemPlacementShouldAlternate() && component.itemHasLeftAlignedPlacement() ? 'leftItem' : 'rightItem'}>
        {component.props.images || component.props.videoId ? ItemMedia() : null}
        {ItemText()}
      </div>
    </div>
  );
}
