import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import * as _ from 'lodash';
import '../../styles/css/faqs.css';
import { FormattedMessage } from 'react-intl';
import history from '../../screens/app/history';
import { FilterMenu } from '../../screens/filterMenu/filterMenu.component';
import applyMarkdown from '../../screens/utils/applyMarkdown';
import DisclaimersComponent from '../../screens/disclaimers/disclaimers.component';

const faqs = (props) => {
  const [activeFilter, setActiveFilter] = useState('');
  const [disclaimer, setDisclaimer] = useState(null);
  const [filters, setFilters] = useState([]);
  const [filteredFaqs, setFilteredFaqs] = useState([]);
  const disclaimerRef = useRef();

  const addAllToPropFilters = () => {
    let propFilters = props.filters || [];
    propFilters = [{ id: 'All', label: 'All', url: 'All' }, ...propFilters];
    return propFilters;
  };

  const parseQueryString = (filtersToQuery) => {
    const query = queryString.parse(history.location.search).tab;
    const queriedFilter = _.find(filtersToQuery, filter => filter.url === query);
    return queriedFilter ? queriedFilter.id : 'All';
  };

  const refineAndFilterFaqs = (faqsToFilter, filter) => _.filter(faqsToFilter, (faq) => {
    if (filter === 'All') {
      return true;
    }
    return faq.filter === activeFilter;
  });

  const switchFilter = (filter) => {
   
    const urlWithFilter = `${props.urlPath}?tab=${filter.url}`;
    setActiveFilter(filter.id);
    history.replace(urlWithFilter);
    props.clickEvent('Nav Link', filter.id);
  };

  useEffect(() => {
    setDisclaimer(disclaimerRef.current);
  }, [disclaimerRef]);

  useEffect(() => {
    const allFilters = addAllToPropFilters();
    setFilters(allFilters);

    const filterId = parseQueryString(allFilters);
    setActiveFilter(filterId);
  }, [props.filters]);

  useEffect(() => {
    const refinedFaqs = refineAndFilterFaqs(props.faqs, activeFilter);
    setFilteredFaqs(refinedFaqs);
  }, [activeFilter, props.faqs]);

  const faqFilters = <FilterMenu
        active={activeFilter}
        filters={filters}
        filterClick={(e, filter) => switchFilter(filter)}
        displayArrow={true}
    />;

  const faqItems = filteredFaqs && filteredFaqs.map(faq => (
    <div key={faq.number} className="media-text-item-container">
        <div className="faq-item">
            <div className="item-text faq-text">
                {faq.question
                && <div>
                    <div className="faq-title">
                        {applyMarkdown(faq.question, disclaimer)}
                    </div>
                    <div className="faq-divider" />
                </div>}
                {faq.answer
                && <div className="description-expanded faq-answer">
                    {applyMarkdown(faq.answer, disclaimer)}
                </div>}
            </div>
        </div>
    </div>
  ));

  return (
        <div className="features-page faqs-body">
            {props.languageOverride
            && <div className="language-unavailable" >
                <FormattedMessage id="global.faqs.languageOverride" default="Language is unavailable" />
            </div>}
            <div className="title">
                <div>
                    <FormattedMessage id="global.faqs" defaultMessage="Frequently Asked Questions" />
                </div>
                <div className="print-dividing-line" />
            </div>
            <div>{!props.hideFilters && faqFilters}</div>
            <div>{faqItems}</div>
            <DisclaimersComponent
                template={props.disclaimer}
                ref={(instance) => { disclaimerRef.current = instance; }}
            />
        </div>
  );
};

faqs.propTypes = {
  clickEvent: PropTypes.func,
  disclaimer: PropTypes.string,
  faqs: PropTypes.array,
  filters: PropTypes.array,
  hideFilters: PropTypes.bool,
  languageOverride: PropTypes.bool,
  urlPath: PropTypes.string,
};

export default faqs;
