import AppSettings from "../../appsettings";
import React, { useState, useEffect, useRef, useContext } from "react";
import DropdownV2 from "../../components/molecules/DropdownV2.js";
import SidebarMenuMobile from "../sidebarMenu/sidebarMenuMobile"
import { getUrlModelName, getFormattedName } from "../utils/vehicleName";
import "../../styles/css/subheaderV2.css";
import { useHistory } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import applyMarkdown from "../utils/applyMarkdown";
import { getBookmarks, addBookmark, deleteBookmark } from "../../services/bookmarksService.js";

import * as analyticsFunctions from "./subheaderV2.analytics";
import { isCurrentVersionLower } from "../utils/appMinimumVersionVerify";
import { isMobileView } from "../utils/getScreenSize";
import { VehiclePageIsPrint } from "../../components/contexts/vehiclePagePrintContext.js";
import ShareModalButtonWrapper from "../shareModal/shareModalButtonWrapper.js";
import { ReactComponent as ShareIcon } from "../../resources/images/share-icon.svg";

const SubheaderV2 = (props) => {
  const [bookmarks, setBookmarks] = useState(null);
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
  const [bookmarked, setBookmarked] = useState(null);
  const [longTitle, setLongTitle] = useState(null);
  const [linkOverflow, setLinkOverflow] = useState(null);
  const [linkToBeShared, setLinkToBeShared] = useState(window.location.href);

  const { setIsPrint } = useContext(VehiclePageIsPrint);
  const activeRef = useRef();
  const history = useHistory();
  const isPrintableSubnavPage = props?.comparisonTools?.find((navItems) => navItems.urlPath === props.analyticsPageData.subnavPage) && !window.ReactNativeWebView; // no printing on app

  useEffect(() => {
    // Update URL when location changes
    setLinkToBeShared(`${window.location.origin}${history.location.pathname}`);
  }, [history.location]);
  
  useEffect(()=> {
    props.bookmarkable && getBookmarkData();
  }, [])

  useEffect(() => {
    props.bookmarkable && isPageBookmarked();
  }, [window.location.pathname]);

  useEffect(() => {
    props.bookmarkable && bookmarked === null && isPageBookmarked();
  }, [bookmarks]);

  useEffect(() => {
    props.currentTab &&
      activeRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
  }, [props.currentTab]);

  useEffect(()=> {
    setMobileMenuOpened(false)
  }, [props.device])

  useEffect(() => {
    if(props.title && longTitle === null) {
      const title = document.getElementsByClassName("subheader-title")
      if(title.length && title[0].firstChild.offsetWidth >= 201) {
        setLongTitle(true)
      } else {
        setLongTitle(false)
      }
    }
  }, [props.title])

  useEffect(()=> {
    if(props.device === "desktop") {
      if (props.tabs?.length >= 6) {
        const title = document.getElementsByClassName("subheader-title")[0]
        const titleWidth = title?.firstChild?.offsetWidth || 201;
        if (titleWidth >= 201 && window.innerWidth < 1350) {
          setLinkOverflow(true)
        } else if (titleWidth < 201 && window.innerWidth < 1230) {
          setLinkOverflow(true)
        } else setLinkOverflow(false)
      } else if (props.tabs?.length < 6) {
        setLinkOverflow(false)
      }
    } else {
      setLinkOverflow(false)
    }
  }, [window.innerWidth, props.tabs, props.title])

  const isPageBookmarked = () => {
    if (bookmarks) {
      const pageBookmarked = bookmarks?.find(
        (b) => b.url === window.location.pathname
      );
      setBookmarked(!!pageBookmarked);
    }
  };

  const updateBookmarks = (bookmarked, bookmark) => {
    if (bookmarked) {
      addBookmark(bookmark)
        .then(() => getBookmarkData())
        .catch((error) => console.log(`error: ${error}`));
    } else {
      deleteBookmark(bookmark)
        .then(() => getBookmarkData())
        .catch((error) => console.log(`error: ${error}`));
    }
  };

  const getBookmarkData = () => {
    getBookmarks()
      .then((bookmarks) => {
        const filteredBookmarks = bookmarks?.filter((b) =>
          b.url?.startsWith(props.baseUrl)
        );
        setBookmarks(filteredBookmarks);
      })
      .catch((error) => {
        console.log(`error: ${error}`);
        setBookmarks([]);
      });
  };

  const toggleBookmark = () => {
    setBookmarked(!bookmarked);
    const bookmark = {
      url: window.location.pathname,
      title: props.bookmarkTitle || props.title,
      subcategory: props.bookmarkSubcategory ? props.bookmarkSubcategory(props.currentTab.subcategory) : props.currentTab.subcategory,
    };
    updateBookmarks(!bookmarked, bookmark);

    // ANALYTICS
    analyticsFunctions.bookmarkClick({
      pageTitle: typeof props.title === "string" ? props.title : props.analyticsPageData?.pageTitle,
      ...props.analyticsPageData
    }, !bookmarked);
  };

  const shareClickAnalytics = () => {
    analyticsFunctions.shareClick({
      pageTitle: typeof props.title === "string" ? props.title : props.analyticsPageData?.pageTitle,
      ...props.analyticsPageData
    });
  };

  const SubpageLinks = () =>
    linkOverflow === false && props.tabs?.map((t) => (
      <div
        key={t.id}
        className={`subpageLink ${
          props.currentTab?.subcategory === t?.subcategory ? "active" : ""
        }`}
        onClick={() => {
          props.navClick ? props.navClick(t.urlPath) : history.push(`${props.baseUrl}/${t.urlPath}`);
          setBookmarked(null);
          // ANALYTICS
          analyticsFunctions.subNavClick({
            pageTitle: typeof props.title === "string" ? props.title : props.analyticsPageData?.pageTitle,
            navTitle: t.id,
            ...props.analyticsPageData
          });
        }}
        ref={
          props.currentTab?.subcategory === t?.subcategory && props.device === "mobile"
            ? activeRef
            : null
        }
      >
        {props.device === "mobile" && t.fullId ? t.fullId : t.id}
      </div>
    )) || (
      <div className="skeleton-section">
        <Skeleton
          width={150}
          height={20}
          count={3}
          baseColor={props.device === "mobile" ? "#dddddd" : "#58595B"}
          highlightColor={props.device === "mobile" ? "#e6e6e6" : "#656565"}
        />
      </div>
    );

  const SubpageLinksDropdown = () =>
    props.tabs ? (
      <DropdownV2
        options={props.tabs.map((t) => ({ label: t.id, value: t.urlPath }))}
        value={<b>{props.currentTab?.id}</b>}
        onValueChange={(e) => {
          props.navClick ? props.navClick(e.value) : history.push(`${props.baseUrl}/${e.value}`);
          setBookmarked(null);

          // ANALYTICS
          analyticsFunctions.subNavDropdownClick({
            pageTitle: typeof props.title === "string" ? props.title : props.analyticsPageData?.pageTitle,
            ...props.analyticsPageData
          });
        }}
        style="fullBackground"
      />
    ) : (
      <Skeleton width={200} height={20} />
    );

  return (
    <SkeletonTheme baseColor="#58595B" highlightColor="#656565" inline>
      <div className="subheaderV2">
        <div
          className={`subheaderRow ${longTitle ? "longTitle" : "shortTitle"} ${linkOverflow && "linkOverflow"} `}
        >
          {props.title ?
            typeof props.title === "string" ?
              <div className="subheader-title title-text">{applyMarkdown(props.title)}</div>
              : <div className={`subheader-title title-component ${isMobileView(window.innerWidth) && !isCurrentVersionLower('12.0.17') ? 'add-margin-left':''}`}
                onClick={() => props.device === "mobile" && mobileMenuOpened && setMobileMenuOpened(false)}
              >
                {props.title}
              </div>
          : <Skeleton width={200} height={20} />}
          {(props.device === "desktop" && !linkOverflow) && (
            <div
              className={`subpageLinks length${props.tabs?.length}`}
            >
              <SubpageLinks />
            </div>
          )}
          {(props.device === "tablet" || (props.device === "desktop" && linkOverflow)) && (
            <div className="subpageLinksDropdown">
              <SubpageLinksDropdown />
            </div>
          )}
          <div className={"actionIcons"}>
            <ShareModalButtonWrapper linkToBeShared={linkToBeShared} analyticsFunction={shareClickAnalytics}>
              <button>
                <ShareIcon height="24" width="24" color="#FFF"/>
              </button>
            </ShareModalButtonWrapper>
            {(props.currentTab?.print || isPrintableSubnavPage) && (
              <img
                className="print-icon"
                src={AppSettings.AWSImgRepo.resources + "en/print-icon.svg"}
                onClick={() => {setIsPrint(true); setTimeout(window.print, 1); setTimeout(() => setIsPrint(false), 5000)}}
              />
            )}
            {props.bookmarkable && !mobileMenuOpened && (bookmarks ? (
              <img
                className="bookmark-icon"
                src={
                  AppSettings.AWSImgRepo.resources +
                  (bookmarked
                    ? "en/filled-bookmark-icon.svg"
                    : "en/empty-bookmark-icon.svg")
                }
                onClick={() => toggleBookmark()}
              />
            ) : (
              <Skeleton
                width={25}
                height={30}
                containerClassName="bookmarkSkeleton"
              />
            ))}
            {props.device === "mobile" && props.mobileVerticalMenu && (props.mobileTabs ?
              <img
                className={`menu-mobile-icon ${mobileMenuOpened ? 'opened' : ''}`}
                src={AppSettings.AWSImgRepo.resources + `en/${mobileMenuOpened ? 'close-white.svg' : 'meatballs.svg'}`}
                onClick={() => setMobileMenuOpened(!mobileMenuOpened)}
              />
              : <Skeleton
                width={25}
                height={30}
                containerClassName="mobileMenuSkeleton"
              />
            )}
          </div>
          {props.device === "mobile" && props.mobileVerticalMenu && props.mobileTabs &&
            <SidebarMenuMobile
              isOpened={mobileMenuOpened}
              tabs={props.mobileTabs}
              navItems={props?.navItems}
              analyticsData={props.analyticsPageData}
              onClose={() => setMobileMenuOpened(false)}
              currentTab={props.currentTab}
              baseUrl={props.baseUrl}
              navClick={props.navClick}
            />}
        </div>
        {props.device === "mobile" && !props.mobileVerticalMenu && (
          <div className="subpageLinks scrollArea">
            <SubpageLinks />
          </div>
        )}
      </div>
    </SkeletonTheme>
  );
};

export default SubheaderV2;


