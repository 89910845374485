import React from 'react';
import applyMarkdown from '../../utils/applyMarkdown';
import AppSettings from '../../../appsettings.js';
import '../../../styles/css/headToHead.css';
import DisclaimersComponent from '../../disclaimers/disclaimers.component';
import { FormattedMessage } from 'react-intl';

export function LegacyEdgeTemplate(component) {
  const edgeSheets = component.state.edgeSheets.map((item, index) => {
    const sanitizedImageUrl = encodeURI(AppSettings.AWSImgRepo.static + item.img);
    return (
            <div key={index} className="row vehicleRow">
                <div className="medium-6 large-2 columns large-offset-1">
                    <img className="row " alt="" src={sanitizedImageUrl} />
                    {item.edgeSheetUrl
                      ? <a className="row learnMore" href={AppSettings.AWSImgRepo.static + item.edgeSheetUrl} target="_blank"><FormattedMessage id="caHeadToHead.downloadComparisonData" /></a>
                      : <p className="row"><FormattedMessage id="caHeadToHead.noLegacyComparisonAvailable" /></p>}
                </div>
                <div className="medium-6 large-9 columns">
                    <h4 style={{ fontWeight: 'bold' }}>{item.competitor}</h4>
                    <div>
                        <h5 style={{ fontWeight: 'bold' }}><FormattedMessage id="caHeadToHead.keyTakeaways" /></h5>
                        <ul>{applyMarkdown(item.keyTakeAways, component.disclaimer)}</ul>
                        <h5 style={{ fontWeight: 'bold' }}><FormattedMessage id="caHeadToHead.challenges" /></h5>
                        <ul>{applyMarkdown(item.challenges, component.disclaimer)}</ul>
                    </div>
                    <hr />
                </div>
            </div>
    );
  });

  return (
        <div>
            <div className="ca-edge-legacy">
                {edgeSheets}
            </div>
            <DisclaimersComponent template={`${component.props.year}_${component.props.model}_overview`} ref={(instance) => { component.disclaimer = instance; }} />
        </div>
  );
}
