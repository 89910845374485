import { Component } from 'react';
import { SectionHeaderTemplate } from './sectionHeader.tpl';

export default class SectionHeaderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sampleImage: '',
    };
  }

  render() {
    return SectionHeaderTemplate(this);
  }
}
