import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Component } from 'react';
import { postSmartPathEmail } from '../../services/emailService.js';
import { postUserActivityRecord } from '../../services/userActivityService.js';
import history from '../app/history';
import { linkClick, pageLoad } from './smartpath-analytics.js';
import { SPContactUsTemplate } from './spContactUs.tpl';
import * as analytics from './smartpath-overview.analytics';

export default class SPContactUsComponent extends Component {
  constructor(props) {
    super(props);

    const query = queryString.parse(location.search);
    //let emailTabSelected = true;

    // if (query.tab === 'contactList') {
    //   emailTabSelected = false;
    // } else {
    //   history.replace('contactUs?tab=email');
    // }

    this.state = {
      dealership: '',
      name: '',
      jobTitle: '',
      phoneNumber: '',
      emailAddress: '',
      questions: '',
      isValid: false,
      success: false,
      //emailTabSelected,
    };

    //this.toggleTab = this.toggleTab.bind(this);
    this.updateDealershipField = this.updateDealershipField.bind(this);
    this.updateNameField = this.updateNameField.bind(this);
    this.updateJobTitleField = this.updateJobTitleField.bind(this);
    this.updatePhoneNumberField = this.updatePhoneNumberField.bind(this);
    this.updateEmailAddressField = this.updateEmailAddressField.bind(this);
    this.updateQuestionsField = this.updateQuestionsField.bind(this);
    this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.checkRequiredFields = this.checkRequiredFields.bind(this);
    this.showFailureMessage = this.showFailureMessage.bind(this);
    this.formatMessageBody = this.formatMessageBody.bind(this);
    this.onCancelButtonClick = this.onCancelButtonClick.bind(this);
  }

  sendEmail(email) {
    return new Promise((resolve, reject) => {
      postSmartPathEmail(email)
        .then((payload) => {
          this.setState({
            success: true,
          });
          resolve(payload);
        }).catch((error) => {
          this.showFailureMessage();
          reject(error);
        });
    });
  }

  // toggleTab() {
  //   history.replace(`contactUs?tab=${this.state.emailTabSelected ? 'contactList' : 'email'}`);
  //   this.setState({ emailTabSelected: !this.state.emailTabSelected });
  // }

  onSubmitButtonClick() {
    if (this.props.clickAnalytics) {
      analytics.contactUsSubmit(this.props.clickAnalytics);
    }
    if (this.checkRequiredFields()) {
      const emailObject = this.formatMessageBody();
      this.sendEmail(emailObject)
        .then(() => {
          console.log('Email response hit');
          linkClick('Contact Us', 'Submit Button Click');
        });
    }
  }

  onCancelButtonClick() {
    this.setState({
      dealership: '',
      name: '',
      jobTitle: '',
      phoneNumber: '',
      emailAddress: '',
      questions: '',
      isValid: false,
    });
  }

  formatMessageBody() {
    const firstLine = `This is a contact request from ${this.state.name} at the ${this.state.dealership} dealership.`;
    const secondLine = `\nTheir position is: ${this.state.jobTitle}`;
    const thirdLine = `\nYou can reach ${this.state.name} at ${this.state.emailAddress} or ${this.state.phoneNumber}\n`;
    const questions = this.state.questions ? `\nHere are their question(s): \n${this.state.questions}` : '';

    const emailObject = {
      message: firstLine + secondLine + thirdLine + questions,
    };

    return emailObject;
  }

  updateDealershipField(input) {
    this.setState({
      dealership: input,
    });
  }

  updateNameField(input) {
    this.setState({
      name: input,
    });
  }

  updateJobTitleField(input) {
    this.setState({
      jobTitle: input,
    });
  }

  updatePhoneNumberField(input) {
    this.setState({
      phoneNumber: input,
    });
  }

  updateEmailAddressField(input) {
    this.setState({
      emailAddress: input,
    });
  }

  updateQuestionsField(input) {
    this.setState({
      questions: input,
    });
  }

  showFailureMessage() {
    $('#failureMessageBox').fadeIn(2000);
    $('#failureMessageBox').fadeOut(3000);
  }

  checkRequiredFields() {
    let validCount = 0;
    if (this.state.dealership.length > 0) {
      validCount++;
    }
    if (this.state.name.length > 0) {
      validCount++;
    }
    if (this.state.jobTitle.length > 0) {
      validCount++;
    }
    if (this.state.phoneNumber.length > 0) {
      validCount++;
    }
    if (this.state.emailAddress.length > 0) {
      validCount++;
    }
    if (validCount === 5) {
      this.setState({
        isValid: true,
      });
      return true;
    }

    this.setState({
      isValid: false,
    });
    return false;
  }

  render() {
    return SPContactUsTemplate(this);
  }
}

SPContactUsComponent.propTypes = {
  //regionalContacts: PropTypes.array,
};
