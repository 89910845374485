import React, { useContext, useEffect, useState } from "react";
import AppSettings from "../../appsettings";
import { postMessageToApp } from "../../screens/utils/hybridAppHelpers";
import { getProfileImageUrl } from "../../screens/utils/profileImageUrl";
import "../../styles/css/profilePicture.css";
import { NitroContext } from "../contexts/nitroContext";
import { NotificationContext } from "../contexts/notificationContext";

const ProfilePicture = (props) => {
  const [nitroPoints, setNitroPoints] = useState(null);
  const [pictureUrl, setPictureUrl] = useState(null);
  const [totalNotificationCount, setTotalNotificationCount] = useState(0);

  const { notificationCount } = useContext(NotificationContext);
  const { nitroUserXPLevel } = useContext(NitroContext);

  useEffect(() => {
    const fetchImages = async () => {
      const imageUrl = await getProfileImageUrl();
      setPictureUrl(imageUrl);
    };
    fetchImages();
  }, []);


  useEffect(() => {
    if (
      nitroUserXPLevel?.nitroCurrentLevelPoints &&
      nitroUserXPLevel?.nitroNextLevelPoints
    ) {
      setNitroPoints({
        levelGained: nitroUserXPLevel.nitroCurrentLevelPoints,
        levelTotal: nitroUserXPLevel.nitroNextLevelPoints,
      });
    }
  }, [nitroUserXPLevel]);

  useEffect(() => {
    setTotalNotificationCount(
      AppSettings.FeatureFlags.showAnnouncements
        ? notificationCount.unreadReplies +
            notificationCount.unreadAnnouncements
        : notificationCount.unreadReplies
    );
    postMessageToApp("NotificationCount", notificationCount);
  }, [notificationCount]);

  const classArray = props.menuLocated
    ? [
        "menu-located-profile-picture",
        "menu-located-container",
        "menu-located-image",
        "menu-located-badge",
      ]
    : ["", "", "", ""];

  return (
    <div
      className={`profilePicture 
      ${classArray[0]} 
      ${totalNotificationCount > 0 && "displayNotification"} 
      ${nitroPoints && "displayProgress"} 
      ${props.small && "small"}`}
    >
      <div
        className={`circleContainer ${classArray[1]}`}
        onClick={() => (props.clickFunction ? props.clickFunction() : null)}
      >
        {pictureUrl ? (
          <img
            className={`profilePictureImage ${classArray[2]}`}
            src={`data:image;base64, ${pictureUrl}`}
          />
        ) : (
          <img
            className={`profilePictureImage ${classArray[2]}`}
            src={AppSettings.AWSImgRepo.resources + `en/default.svg`}
          />
        )}
      </div>
      {totalNotificationCount > 0 && (
        <div className={`notificationBadge ${classArray[3]}`}>
          {totalNotificationCount <= 9 ? totalNotificationCount : "9+"}
        </div>
      )}
    </div>
  );
};

export default ProfilePicture;
