import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getLocale } from '../../../services/languageTranslationService.js';
import ArticleListComponent from '../../articleList/articleList.component';
import '../../../styles/css/resources-page.css';


export function TSSResourcesTemplate(component) {
  const filterMenuItems = filters => (
    filters.map((filter, index) => (
          <div key={index} style={{ display: 'inline' }}>
              <input name={filter.articleTag} type="checkbox" checked={filter.articleTag === component.state.articleTag} onChange={component.switchFilter} />
              <label className="checkbox-label" htmlFor="checkbox2">{filter.id}</label>
          </div>
    ))
  );

  const filterMenu = filters => (
      <div>
          <div className="filter-label">
              <FormattedMessage id="entuneResources.filterBy" defaultMessage="Filter by:" />
          </div>
          {filterMenuItems(filters)}
      </div>
  );
  return (
        <div className="resources-page">
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <div className="row">
                <div className="small-12 large-12 columns">
                    <div className="title">
                        <FormattedMessage id="global.resources" defaultMessage="Resources" />
                    </div>
                </div>
            </div>
            {filterMenu(component.props.filters)}
            <ArticleListComponent
                recordType={`tag-${component.state.articleTag}-${getLocale()}`}
                resourceClick={component.resourceClick}
                filterMenuClick={component.filterClick}
                sortClick={component.sortClick}
            />
        </div>
  );
}
