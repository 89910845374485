import AppSettings from '../appsettings';
import httpOption from './httpOption';


export function getSharedApiKey() {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/sharedApi`;
  const options = httpOption.GET();
  
  return fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return Promise.resolve(response.json());
        }
        return Promise.reject(response);
      });
}
