import React, { useEffect, useState } from 'react';
import SearchBarInput from '../../components/molecules/searchBarInput';
import '../../styles/css/featureLookupSearchPane.css';
import DropdownV2 from '../../components/molecules/DropdownV2';
import AppSettings from '../../appsettings';
import { featureLookupOverviewResetSearchClick, featureLookupOverviewCategorySelect } from './featureLookup.analytics';

const featureLookupSearchPane = (props) => {
    const [subcategoryPlaceholder, setSubcategoryPlaceholder] = useState('Category');
    const [dropdownOptions, setDropdownOptions] = useState(['']);

    const updateSearch = (search) => {
        props.setSearchText(search);
    }

    const resetSearch = () => {
        props.analyticsData && featureLookupOverviewResetSearchClick(props.analyticsData.clickAnalytics, props.tabSelected, props.searchText);
        props.setSubcategorySelected(null);
        clearSearch();
    }

    const clearSearch = () => {
        setSubcategoryPlaceholder('Category');
        props.setSubcategorySelected(null);
        props.setSearchText("");
    }

    const loadSubcategories = (tabSelected) => {
        let subcategories = [];

        for (const i in props.subcategories) {
            if (props.subcategories[i].featureType == tabSelected) {
                if (!subcategories.includes(props.subcategories[i].category)) {
                    subcategories.push(props.subcategories[i].category);
                }
            }
        }

        setDropdownOptions(subcategories);

    }

    function subcategorySelected(event) {
        props.analyticsData && featureLookupOverviewCategorySelect(props.analyticsData.clickAnalytics, props.tabSelected, event.value);
        props.setSubcategorySelected(event.value);
    }

    useEffect(() => {
        const tabSelected = props.tabSelected;
        loadSubcategories(tabSelected);
        clearSearch();
    }, [props.tabSelected]);

    return (
        <div className='featureLookupSearchPane'>
            {props.tabSelected.toLowerCase() != 'all' &&
                <div className='subcategorySection'>
                    <div className='subcategoryText'>Search all <span className='tabSelectedText'>{props.tabSelected}</span> features or narrow to a specific category first.</div>
                    <DropdownV2
                        style="black"
                        options={dropdownOptions}
                        value={props.subcategorySelected ? props.subcategorySelected : 'Category'}
                        onValueChange={subcategorySelected}
                        rootClassName="featureLookupSubcategoryDropdown"
                        placeholder={subcategoryPlaceholder}
                    />
                </div>
            }
            <div className='searchSection'>
                <SearchBarInput
                    placeholder={'Search...'}
                    autoFocus={true}
                    dynamicSearching={true}
                    onSearch={updateSearch}
                    searchParam={props.searchText}
                    clickAnalytics={props.analyticsData && props.analyticsData.clickAnalytics}
                    analyticsPositionTitle={props.tabSelected}
                />
                <span className='searchResetButton' onClick={() => resetSearch()}>Reset</span>
            </div>
        </div>
    );
};

export default featureLookupSearchPane;
