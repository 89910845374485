import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UserManagerContext } from '../contexts/userManagerContext';

const LogoutCallback = () => {
  const history = useHistory();
  const { userManager } = useContext(UserManagerContext);

  useEffect(() => {
    const handleLogoutCallback = async () => {
      try {
        if (userManager) {
            await userManager?.signoutRedirectCallback();
        }
      } catch (error) {
        console.error('Error during logout callback:', error);
      }

      history.push('/');
    };

    handleLogoutCallback();
  }, [userManager, history]);


  return <div>Completing sign-out...</div>;
};

export default LogoutCallback;
