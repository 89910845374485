import React, { useEffect } from "react";
import { useHistory } from "react-router";
import AppSettings from "../../appsettings";
import Tabs from "../../components/molecules/Tabs";
import { postViewPageAction } from '../../services/nitroService';
import "../../styles/css/engageXP.css";
import { playNowButtonClick, xpOverviewPageLoad } from "./engageXPOverview.analytics";
import RoundedButton from "../../components/molecules/roundedButton";
import applyMarkdown from "../utils/applyMarkdown";

const BLUE = "#0C56C9";
const EngageXPOverview = ({ 
    analyticsData,
    pageData, 
}) => {
    const history = useHistory();
    const playNowButtonText = "Play Now";

    useEffect(() => {
        if (analyticsData) {
            postViewPageAction(window.location.href);
            xpOverviewPageLoad(analyticsData.pageLoad?.whatIsXP);
        }
    }, [analyticsData]);

    const navigateToMissionsPage = () => {
        if (analyticsData) {
            const clickAnalytics = analyticsData.clickAnalytics;
            playNowButtonClick(clickAnalytics, playNowButtonText);
        }
        history.push("/engage-xp/missions");
    };

    const renderSubheaderSections = () => 
        pageData?.welcomeToXP?.subheaderSections.map((section, index) => (
            <div className={index === 0 ? "what-is-xp-container" : "why-play-container"} key={index}>
                <div className={index === 0 ? "what-is-xp-title" : "why-play-title"}>
                    {section.subheaderTitle}
                </div>
                <div className={index === 0 ? "what-is-xp-description" : "why-play-description"}>
                    {section.description}
                </div>
            </div>
        ));

    const renderTab = (tab) => {
        return <ul className="engage-xp-tabs-items-container">
            {tab?.cards?.map((card, index) => <div key={index} className="engage-xp-tabs-card">
                <img src={`${AppSettings.AWSImgRepo.engageXP}${card?.image}.png`} />
                <p>{applyMarkdown(card?.description)}</p>
            </div>)}
        </ul>
    }

    return (
        <div className="engageXPOverview">
            <div className="welcome-to-engage-xp-overview-container">
                <div className="welcome-to-xp-message">
                    {pageData?.welcomeToXP?.title}
                </div>
                <div className="welcome-to-xp-info-area">
                    {renderSubheaderSections()}
                </div>
            </div>
            <div className="engage-xp-tabs-container">
                <Tabs 
                    tabs={pageData?.welcomeToXP?.tabs} 
                    renderTab={renderTab}
                    analyticsData={analyticsData}
                    tournamentTextColor={BLUE}
                />
            </div>
            <div className="engage-xp-button-container">
                <RoundedButton
                    className="xp-play-now-button"
                    colorStyle="red"
                    title={playNowButtonText}
                    onClick={navigateToMissionsPage}
                />
            </div>
        </div>
    );
};

export default EngageXPOverview;
