import PropTypes from 'prop-types';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import history from '../../app/history';
import { GlobalAnalytics } from '../../../analytics';
import { getCompetitiveAdvantagesEdge, getVehiclesAndCompetitorsList } from '../../../services/caEdge.service';
import { getLocale } from '../../../services/languageTranslationService.js';
import { getUrlModelName, getS3PathName } from '../../utils/vehicleName';
import { HeadToHeadTemplate } from './headToHead.tpl.js';
import queryString from 'query-string';
import * as userHistory from '../../../services/userActivityService';

const parsed = queryString.parse(location.search);
const analytics = new GlobalAnalytics();

class HeadToHeadComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s3Path: '',
      dbVehicles: [
        {
          yearAndModel: '',
        },
      ],
      currentEdge: {
        edgeContent: {},
      },
      displayContent: true,
      costsArray: [],
      competitorDropdown: [],
      toyotaDropdown: [],
      selectedValueToyota: '',
      selectedValueCompetitor: '',
      selectedToyotaTrim: '',
      selectedCompetitor: '',
      vehicleYear: this.props.year,
      vehicleModel: '',
    };

    this.updateDropdown = this.updateDropdown.bind(this);
    this.loadCAEdge = this.loadCAEdge.bind(this);
    this.getS3Path = this.getS3Path.bind(this);
  }

  async getS3Path ({ getLanguage } = { getLanguage: false }) {
    const s3PathName = await getS3PathName(this.props.model);
    const language = getLanguage ? getLocale() : 'en';
    const year = this.props.year;
    this.setState({
      s3Path: `${year}/${s3PathName}/${language}/`,
      vehicleModel: s3PathName,
    });
  }

  getVehiclesAndCompetitorsList() {
    const modelYear = `${this.props.year} ${this.props.model}`;
    getVehiclesAndCompetitorsList(modelYear)
      .then((vehiclesCompetitorList) => {
        if (vehiclesCompetitorList.length > 0) {
          const selectedToyotaTrim = parsed.trim ? parsed.trim : vehiclesCompetitorList[0].gradeAndTrim;
          const selectedCompetitor = parsed.competitor ? parsed.competitor : vehiclesCompetitorList[0].competitors[0];
          const competitorDropdown = vehiclesCompetitorList.find(element => element.gradeAndTrim === selectedToyotaTrim);
          this.setState({
            modelYear,
            competitorsList: vehiclesCompetitorList,
            selectedValueToyota: selectedToyotaTrim,
            selectedValueCompetitor: selectedCompetitor,
            dbVehicles: vehiclesCompetitorList,
            competitorDropdown: competitorDropdown.competitors,
          });
        } else {
          this.setState({
            modelYear,
            noResponse: true,
          });
        }
        this.loadCAEdge();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateDropdown(event) {
    let selectedToyotaTrim;
    let selectedCompetitor;
    if (event.target.name === 'toyota-dropdown') {
      selectedToyotaTrim = event.target.value;
      const competitorDropdown = this.state.competitorsList.find((element) => {
        // selectedToyotaTrim looks like "2019 Sienna XLE" where as element.gradeAndTrim looks like "XLE"
        // need string manipulation to select the correct competitorList value but need to pass "just the "XLE" to the endpoint
        let elementYearAndModel = element.yearandModel.split(`-${getLocale()}`)[0];
        const a = `${elementYearAndModel} ${element.gradeAndTrim}`;
        selectedToyotaTrim = element.gradeAndTrim;
        return a.toUpperCase() === event.target.value.toUpperCase();
      });
      selectedCompetitor = competitorDropdown.competitors[0];

      this.getS3Path();
      this.setState({
        selectedValueToyota: selectedToyotaTrim,
        selectedValueCompetitor: selectedCompetitor,
        competitorDropdown: competitorDropdown.competitors,
      });
      const label = `${this.state.vehicleYear} ${this.state.vehicleModel} ${selectedToyotaTrim}`;
      this.sendDropDownUpdateAnalytics('Toyota Trim', label);
    } else {
      selectedCompetitor = event.target.value;
      selectedToyotaTrim = this.state.selectedValueToyota;
      this.setState({
        selectedValueCompetitor: selectedCompetitor,
      });
      this.sendDropDownUpdateAnalytics('Competitive Trim', selectedCompetitor);
    }
    history.push(`/product/${getUrlModelName(this.state.vehicleModel)}/${this.state.vehicleYear}/competitiveAdvantages/headToHead?trim=${selectedToyotaTrim}&competitor=${selectedCompetitor}`);
    getCompetitiveAdvantagesEdge(this.state.modelYear, selectedToyotaTrim, selectedCompetitor)
      .then((edgeSheetContent) => {
        const costs = this.createCosts(edgeSheetContent.edgeContent.trueCostToOwn);
        this.setState({
          currentEdge: edgeSheetContent,
          costsArray: costs,
        });
      })
      .catch((error) => {
        console.log(`Error:${error}`);
      });
  }

  loadCAEdge() {
    const toyotaYearAndModel = `${this.props.year} ${this.props.model}`;
    const selectedToyotaTrim = this.state.selectedValueToyota;
    const selectedCompetitor = this.state.selectedValueCompetitor;
    history.push(`/product/${getUrlModelName(this.state.vehicleModel)}/${this.state.vehicleYear}/competitiveAdvantages/headToHead?trim=${selectedToyotaTrim}&competitor=${selectedCompetitor}`);
    getCompetitiveAdvantagesEdge(toyotaYearAndModel, selectedToyotaTrim, selectedCompetitor)
      .then((edgeSheetContent) => {
        const costs = this.createCosts(edgeSheetContent.edgeContent.trueCostToOwn);

        this.getS3Path();
        this.setState({
          currentEdge: edgeSheetContent,
          costsArray: costs,
        });
      })
      .catch((error) => {
        console.log(`loadCAEdge Error: ${error}`);
      });
  }

  sendDropDownUpdateAnalytics(category, label) {
    analytics.fireOnClick({
      tag: '29.2',
      modelName: this.props.model,
      section: 'Product',
      subsection: 'Featured Technology',
      page: 'Competitive Advantages: Head to Head',
      modelYear: this.props.year,
      container: 'Module',
      module: 'Filter',
      category,
      label,
    });
  }

  createCosts(costs) {
    const costsArray = [];
    if (costs) {
      costsArray.push(costs.fiveYearCost);
      costsArray.push(costs.valueRating);
    }
    return costsArray;
  }

  componentWillMount() {
    this.getVehiclesAndCompetitorsList();
  }

  // Logic to call polyfill for IE image
  componentDidUpdate() {
    if (window.objectFitPolyfill) {
      window.objectFitPolyfill();
    }
  }

  componentDidMount() {
    this.getS3Path({ getLanguage: true });
    userHistory.postUserActivityRecord();
    analytics.firePageLoad({
      tag: '29.1',
      modelName: this.props.model,
      modelYear: this.props.year,
      section: 'Product',
      page: 'Competitive Advantage: Head to Head',
      subsection: 'Head to Head',
    });
  }

  render() {
    return HeadToHeadTemplate(this);
  }
}

HeadToHeadComponent.propTypespropTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(HeadToHeadComponent);
