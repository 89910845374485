import { GlobalAnalytics } from '../../../analytics';

const analytics = new GlobalAnalytics();

export const smartPathLoaded = (pageLoadAnalytics, filterCategory) => {
  analytics.firePageLoad({
    tag: pageLoadAnalytics.tagId,
    section: pageLoadAnalytics.section,
    subsection: pageLoadAnalytics.subsection,
    page: filterCategory,
  });
};

const contentLinkClick = (tag, section, subsection, filterCategory, category, action) => {
  analytics.fireOnClick({
    tag,
    section,
    subsection,
    page: filterCategory,
    category,
    action,
  });
};

export const smartPathDownloadClicked = (clickAnalytics, pageLoadAnalytics, filterCategory, modelName, action) => {
  (clickAnalytics && pageLoadAnalytics) && contentLinkClick(
    clickAnalytics.tagId,
    pageLoadAnalytics.section,
    pageLoadAnalytics.subsection,
    filterCategory,
    modelName,
    action,
  );
};

export const filterCategoryClicked = (clickAnalytics, pageLoadAnalytics, filterCategory, action) => {
  (clickAnalytics && clickAnalytics.categorySelection && pageLoadAnalytics) && contentLinkClick(
    clickAnalytics.tagId,
    pageLoadAnalytics.section,
    pageLoadAnalytics.subsection,
    filterCategory,
    clickAnalytics.categorySelection.category,
    action,
  );
};

export const yearSelectionClicked = (clickAnalytics, pageLoadAnalytics, filterCategory, modelName, action) => {
  (clickAnalytics && pageLoadAnalytics) && contentLinkClick(
    clickAnalytics.tagId,
    pageLoadAnalytics.section,
    pageLoadAnalytics.subsection,
    filterCategory,
    modelName,
    action,
  );
};

const sendAnalytics = (tag, section, subsection, technologyName, page, category, positionTitle, contentTitle) => {
  analytics.fireOnClick({
    tag,
    section,
    subsection,
    technologyName,
    page,
    category,
    positionTitle,
    contentTitle,
  });
};

export const featuresSubLoad = (pageLoadAnalytics) => {
  pageLoadAnalytics && pageLoadAnalytics.featuresSubLoad && sendAnalytics(
    pageLoadAnalytics.featuresSubLoad.tagId,
    pageLoadAnalytics.featuresSubLoad.section,
    pageLoadAnalytics.featuresSubLoad.subsection,
    pageLoadAnalytics.featuresSubLoad.technologyName,
    pageLoadAnalytics.featuresSubLoad.page,
    pageLoadAnalytics.featuresSubLoad.category,
    pageLoadAnalytics.featuresSubLoad.positionTitle,
    pageLoadAnalytics.featuresSubLoad.contentTitle,
  );
};
