import appsettings from "../../../appsettings";
import "../../../styles/css/engageXP/engageXPPagination.css";

const Pagination = ({
  items,
  page,
  handlePaginationBack,
  handlePaginationNext,
  pageSize = 10, 
  pageName,
  analyticsData
}) => {

  return (
    <div className="pagination-section">
      <button
        className={page === 1 ? "inactive rotate" : "rotate"}
        disabled={page === 1}
        onClick={handlePaginationBack}
        analyticsData={analyticsData}
        pageName={pageName}
      >
        <img
          src={
            `${appsettings.AWSImgRepo.resources}en/white-arrow-right.svg`
          }
        />
      </button>
      <span>
        {(page - 1) * pageSize + 1} -{" "}
        {page * pageSize > items.length ? items.length : page * pageSize} of {items.length}
      </span>
      <button
        className={page * pageSize > items.length ? "inactive" : ""}
        disabled={page * pageSize > items.length}
        onClick={handlePaginationNext}
        analyticsData={analyticsData}
        pageName={pageName}
      >
        <img
          src={
            `${appsettings.AWSImgRepo.resources}en/white-arrow-right.svg`
          }
        />
      </button>
    </div>
  );
};

export default Pagination;
