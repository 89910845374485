import React, {useState} from 'react';
import '../../../styles/css/resultContent.css';
import {filterResults} from './resultCommonFunctions'
import HeaderInfo from './resultHeaderInfo'
import {getArticles,getPagecount} from './resultCommonFunctions'
import { FormattedMessage } from 'react-intl';
import DownArrow from '../../../resources/images/arrowDown_Red.png';
import ReactPaginate from 'react-paginate';
import * as analytics from '../searchBar.analytics';
import AppSettings from '../../../appsettings.js';

const searchResultContent = (props) => {
  const NoValidSearchResults = () => {    
    if (props.component.state.articleResults.length === 0 &&
      props.component.state.highlightItems.length === 0 &&
      props.component.state.searchResults.total === 0) {
        props.component.state.isSearching = false;
      return (
        <div className="no-results-header">
          <br />
          <span className="noResults"> <FormattedMessage id="global.noResult" defaultMessage="Try the following:" /> </span>
          <ul className="suggestions">
            <li><FormattedMessage id="global.noResult.firstSuggestion" defaultMessage="Adding vehicle model and year to find results" /></li>
            <li><FormattedMessage id="global.noResult.secondSuggestion" defaultMessage="Searching for specific pages, such as TSS Lineup" /></li>
          </ul>
        </div>
      );
    } else {
      return null;
    }
  }

  const FilterTabs = () => {
      const filters = ['All', 'Articles']
      if (AppSettings.FeatureFlags.showSearchResultsGlossaryTab) {filters.push('Glossary')}
      if (AppSettings.FeatureFlags.showSearchResultsPagesTab) {filters.push('Pages')}
      filters.push('Videos')
        return (
          filters.map((filter, index) => (
          <span 
            key={index}
            style={{zIndex: `${10-index}`}} 
            onClick={() => filterResults(props.component,`${filter}`)}
            className={filter === props.component.state.activeFilter ? "active" : "inactive"}
          >
            {filter}
          </span>
          ))
        )
  }

  const FilterDesktopTablet = () => {
      return(
        <div className="filter-tabs">
          <FilterTabs />
        </div>
      );
  }

  const FilterMobile = () => {
      return(
        <div className="filter-tabs-mobile">
          <FilterTabs />
        </div>
      );
  }
    
  const FilterArea = () => {

    if (props.component.state.articleResults.length > 0) {
      return (
        <>
          <FilterDesktopTablet />
          <FilterMobile />
        </>
      );
    } else {
      return null;
    }
  }
  const PaginatedArticles = (props) => {
    const firstResultOnPage = props.component.state.pageNumber * props.component.resultsPerPage;
    const displayedResults = props.articles.slice(firstResultOnPage, firstResultOnPage + props.component.resultsPerPage);
    if(props.articles.length !=0){
    props.component.state.isSearching = false;
    }
    return displayedResults;
  }
  const IsSearchingResults = (props) => {
      const LoadingContent = "Loading";
      const LoadingContentDiv = props.component.state.isSearching ? "search-results-loading-content": "search-results-loading-content-hide";
      return(
        <div className = {LoadingContentDiv}>
          {LoadingContent}
        <img className = "search-results-loading" src = {`${AppSettings.AWSImgRepo.logo}en/loading_icon_cropped.gif`} ></img>
        </div>
      );
  }
  function pageChange({ selected }) {
    props.component.scroll = 0;
    props.component.setState({ pageNumber: selected });
    window.scrollTo(0,0);
  }

  const PaginationButtons = () => {
    if (getArticles(props.component).length > 0) {
      return (
        <ReactPaginate
        pageCount={getPagecount(props.component)}
        onPageChange={pageChange}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        containerClassName={"paginationBtns"}
        previousLinkClassName={"previousBtn"}
        nextLinkClassName={"nextBtn"}
        activeLinkClassName={"activeBtn"}
        disabledClassName={"disabledBtn"}
        forcePage={props.component.state.pageNumber}
        breakLinkClassName={"ellipsisBtns"}
        />
      );  
    } else {
      return null;
    }
  }
  
  const MoreResultsButton = () => {
    function loadMore() {
      if (props.component.state.analyticsData) {
        analytics.moreResultsClicked(
          props.component.state.analyticsData.clickAnalytics,
          props.component.state.searchParam,
          props.component.state.articleResults.length.toString(),
        );
      }
      props.component.scroll = window.scrollY;
      props.component.resultsPerPage += 8;
      props.component.setState({pageNumber: 0});
    }
    if ((props.component.state.pageNumber + 1) === getPagecount(props.component) || getArticles(props.component).length === 0) {
      return null
    } else {
      return (
        <div className="more-results" onClick={loadMore}>
          <FormattedMessage id="global.button.moreResults" />
          <img src={DownArrow} />
        </div>
      )
    }
  }

  return (
    <div className="search-results-column" style={{maxWidth: props.component.state.knowledgePaneFlag ? "810px" : "924px"}}>
      <div className="column-results-content">
        <FilterArea />
        <HeaderInfo 
          component = {props.component}
        />
        <NoValidSearchResults />
        <PaginatedArticles articles={getArticles(props.component)}
        component = {props.component} />
        <IsSearchingResults
        component = {props.component} />
        <PaginationButtons />
        <MoreResultsButton />
      </div>
    </div>
  );
}


export default searchResultContent;