// This template provides the main entry point for our of our pages.
// When a user navigates to a link, the component associated with that page is
// inject into {component.props.children}

import React from "react";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import downArrow from "../../resources/images/arrowDown_Red.png";
import upArrow from "../../resources/images/arrowUp_Red.png";
import "../../styles/css/subheader.css";
import applyMarkdown from "../utils/applyMarkdown";
import printIcon from "../../resources/images/print_icon.png";
import { getUrlModelName, getFormattedName } from "../utils/vehicleName";
import BookmarkPageComponent from "../bookmarks/bookmarkPage.component";
import Display404NotFound from "../../components/atoms/404NotFound";
import MobileBackButton from "../../components/molecules/MobileBackButton";

export function SubheaderTemplate(component) {
  const isProductPage = () =>
    component.props.match.url.indexOf("product") >= 0 &&
    component.props.match.url.indexOf("certification") <= -1;
  const isStaticResourcePage = () => component.isResourceOverviewPage();
  const urlFormattedModelName =
    isProductPage() && getUrlModelName(component.state.vehicleModel);

  const getPageData = () => {
    if (isStaticResourcePage()) {
      return component.getResourceOverviewPageData();
    }
    if (isProductPage()) {
      return component.productSubheaderItems;
    }
    return null;
  };

  const getBookmarkUrl = (pageData) => {
    if (isProductPage()) {
      let bookmarkUrl = component.props.match.url;
      Object.keys(component.props.params).forEach((key) => {
        bookmarkUrl = bookmarkUrl.replace(
          `:${key}`,
          component.props.params[key]
        );
      });
      return {
        bookmarkUrl,
        bookmarkTitle: `${component.state.activeYear} ${getFormattedName(
          component.state.vehicleModel
        )}`,
        subcategory: getSubcategory(bookmarkUrl, pageData),
      };
    }
    return {
      bookmarkUrl: pageData.bookmarkUrl,
      bookmarkTitle: pageData.bookmarkTitle,
      subcategory: getSubcategory(
        pageData.bookmarkUrl,
        pageData.subheaderItems
      ),
    };
  };

  const getSubcategory = (url, tabItems) => {
    if(url.includes('thirdPartyComparison')){
      return 'Competitive Advantages - 3rd Party Spec';
    }else if(url.includes('/competitiveAdvantages/edge')){
      return 'Competitive Advantages - Head-to-Head'
    } else {
      return tabItems.filter((tab) => tab.linkTo === url)[0].subcategory;
    }
  };

  const renderBookmarkComponent = (items) => {
    const bookmark = getBookmarkUrl(items);
    return (
      <BookmarkPageComponent
        title={bookmark.bookmarkTitle}
        url={bookmark.bookmarkUrl}
        subcategory={bookmark.subcategory}
        bookmarkNavClickEvent={component.props.bookmarkNavClickEvent}
      />
    );
  };

  const renderPrintButton = () => (
    <div>
      {component.props.printDisabled ? (
        <img
          className="print-icon-disabled"
          alt={component.state.sampleImage}
          src={printIcon}
        />
      ) : (
        <img
          className="print-icon"
          alt={component.state.sampleImage}
          src={printIcon}
          onClick={component.printDiv}
        />
      )}
    </div>
  );

  const renderVehicleTitle = () => (
    <span className="yearAndModel">
      {window.ReactNativeWebView && <MobileBackButton />}
      <span
        style={{
          paddingLeft: window.ReactNativeWebView ? "32px" : 0,
          lineHeight: window.ReactNativeWebView ? "36px" : "normal",
        }}
      >
        {component.state.activeYear}
      </span>
      <span className="boldModel">
        &nbsp;
        {applyMarkdown(getFormattedName(component.props.displayName), null)}
      </span>
    </span>
  );

  // pretty sure this 'sanitizedUrl' logic is used to encode the URL's of head to head pages so that
  // the spaces between trim/competitor names in the query string are preserved
  const renderDesktopModelYearDropdown = () =>
    component.state.yearSubheaderItems.map((item, index) => {
      let sanitizedUrl;
      if (parseInt(item.year, 10) > 2016) {
        const splitURL = window.location.pathname.split("/");
        let path;
        if (splitURL.length === 5) {
          path = splitURL[splitURL.length - 1];
        }
        if (splitURL.length === 6) {
          // case with multiple subtabs, such as /competitiveAdvantages/overview
          path = `${splitURL[splitURL.length - 2]}/${
            splitURL[splitURL.length - 1]
          }`;
        }
        sanitizedUrl = encodeURI(
          `/product/${urlFormattedModelName}/${item.year}/${path}`
        );
      } else {
        sanitizedUrl = encodeURI(
          `/product/${urlFormattedModelName}/${item.year}`
        );
      }
      return (
        <div
          className={
            item.year === component.state.activeYear
              ? "year-link active-year"
              : "year-link"
          }
        >
          <a key={index} target="_parent" href={sanitizedUrl}>
            {applyMarkdown(
              `${item.year} ${getFormattedName(component.props.displayName)}`
            )}
          </a>
        </div>
      );
    });

  const renderMobileModelYearDropdown = () =>
    component.state.yearSubheaderItems.map((year, index) => (
      <a
        target="_parent"
        className="year"
        href={`/product/${urlFormattedModelName}/${year.year}`}
        key={index}
      >
        {applyMarkdown(
          `${year.year} **${getFormattedName(component.props.displayName)}**`
        )}
      </a>
    ));

  const renderDesktopPageHeader = (title) => {
    if (isProductPage()) {
      return (
        <div className="vehicle-title">
          <div
            className="year-model-container"
            onClick={component.toggleYearLinks.bind(null)}
          >
            {renderVehicleTitle()}
            <img
              className="dropDownArrow"
              alt=""
              src={component.state.yearLinkExpand ? downArrow : upArrow}
            ></img>
          </div>
          {!component.state.yearLinkExpand ? (
            <div className="modal-year">
              <div
                className="year-list-container"
                onClick={component.toggleYearLinks.bind(null)}
              >
                {renderDesktopModelYearDropdown()}
              </div>
            </div>
          ) : null}
        </div>
      );
    }
    return <div className="main-component-title">{title}</div>;
  };

  const renderMobilePageHeader = (title) => {
    if (isProductPage()) {
      return (
        <div>
          <div className="subHeader-title">
            <span
              className="vehicle-title"
              onClick={
                component.state.yearSubheaderItems &&
                component.state.yearSubheaderItems.length > 0
                  ? component.toggleYearLinks.bind(null)
                  : null
              }
            >
              {renderVehicleTitle()}
            </span>
            {component.state.yearSubheaderItems &&
            component.state.yearSubheaderItems.length > 0 &&
            component.state.yearLinkExpand ? (
              <span
                className="changeYear"
                onClick={component.toggleYearLinks.bind(null)}
              >
                <FormattedMessage id="subheader.changeYear" />
              </span>
            ) : null}
            {!component.state.yearLinkExpand ? (
              <span
                className="dropDownArrow"
                onClick={component.toggleYearLinks.bind(null)}
              >
                <img alt="" src={upArrow}></img>
              </span>
            ) : null}
          </div>
          <div
            className="mobile-modal-year"
            style={{
              display: component.state.yearLinkExpand ? "none" : "block",
            }}
          >
            <div className="modal-content">
              <div className="modal-year-list">
                {renderMobileModelYearDropdown()}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div style={{ display: "flex" }}>
        {window.ReactNativeWebView && (
          <>
            <MobileBackButton style={{ top: 8 }} />
            <div style={{ width: 32, height: 32 }} />
          </>
        )}
        <div className="subHeader-title">{title}</div>
      </div>
    );
  };

  const renderSubheaderTabs = (subheaderData) => {
    const baseStyle = { borderBottom: "5px solid #D2091E" };
    const activeTabStyle = {
      borderBottom: "5px solid #D2091E",
      borderLeft: "5px solid #D2091E",
    };
    if (isProductPage()) {
      return (
        subheaderData &&
        subheaderData.map((item, index) => {
          if (
            component.props.location.pathname.indexOf("competitiveAdvantages") >
              -1 &&
            item.id === "subheader.competitiveAdvantages"
          ) {
            const pathList = component.props.location.pathname.split("/");
            const link =
              item.linkTo.slice(0, -8) + pathList[pathList.length - 1];
            return (
              <NavLink
                key={index}
                to={link}
                className="subHeaderLink"
                onClick={component.productSubheaderNavClick.bind(component)}
                style={baseStyle}
                activeStyle={activeTabStyle}
              >
                <FormattedMessage id={item.id} />
              </NavLink>
            );
          }
          return (
            <NavLink
              key={index}
              to={item.linkTo}
              className="subHeaderLink"
              onClick={component.productSubheaderNavClick.bind(component)}
              activeStyle={activeTabStyle}
            >
              <FormattedMessage id={item.id} />
            </NavLink>
          );
        })
      );
    }
    return (
      subheaderData &&
      subheaderData.subheaderItems.map((item, index) => (
        <NavLink
          key={index}
          to={item.linkTo}
          className="subHeaderLink"
          onClick={component.nonProductSubheaderNavClick.bind(component)}
          activeStyle={activeTabStyle}
        >
          <FormattedMessage id={item.id} />
        </NavLink>
      ))
    );
  };

  const renderTabTitle = (subheaderData) => {
    if (isProductPage()) {
      return component.getProductTitle();
    }
    if (isStaticResourcePage()) {
      return subheaderData && subheaderData.title;
    }
    return "";
  };

  const renderToolbar = (isMobile, items) => {
    switch (isMobile) {
      case true:
        return (
          <div
            className={
              window.ReactNativeWebView
                ? "webview-bookmarks-container"
                : "mobile-bookmarks-container"
            }
            style={{
              display: component.state.yearLinkExpand ? "block" : "none",
            }}
          >
            {renderBookmarkComponent(items)}
          </div>
        );
      case false:
      default:
        return (
          <span className="tool-bar">
            {component.state.shouldDisplayPrintButton && renderPrintButton()}
            {renderBookmarkComponent(items)}
          </span>
        );
    }
  };

  const getSubheaderDisplay = (isDesktopView) => {
    const subheaderItems = getPageData();
    if (subheaderItems) {
      if (isDesktopView) {
        return (
          <div className="subHeaderLarge">
            <ul>
              {renderDesktopPageHeader(subheaderItems.mainComponent)}
              {renderSubheaderTabs(subheaderItems)}
              {renderToolbar(false, subheaderItems)}
            </ul>
          </div>
        );
      }
      return (
        <div className="subHeaderSmall">
          {renderMobilePageHeader(subheaderItems.mainComponent)}
          <div className="mobile-tab-dropdown">
            <span
              className="tab-title"
              onClick={component.toggleSubLinks.bind(null)}
            >
              {renderTabTitle(subheaderItems)}
            </span>
            <span
              className="dropDownArrow"
              onClick={component.toggleSubLinks.bind(null)}
            >
              <img
                alt=""
                src={component.state.subLinkExpand ? downArrow : upArrow}
              />
            </span>
          </div>
          <div
            className="mobile-modal-tabs"
            style={{
              display: component.state.subLinkExpand ? "none" : "block",
            }}
          >
            <div className="modal-content">
              <div
                className="modal-tab-list"
                onClick={component.toggleSubLinks.bind(null)}
              >
                {renderSubheaderTabs(subheaderItems)}
              </div>
            </div>
          </div>
          {renderToolbar(true, subheaderItems)}
        </div>
      );
    }
    return (
      <div>
        <Display404NotFound display />
      </div>
    );
  };

  return (
    <div>
      <div className="subHeader">
        <div className="show-for-large">{getSubheaderDisplay(true)}</div>
        <div className="hide-for-large">{getSubheaderDisplay(false)}</div>
      </div>
      {component.props.children}
    </div>
  );
}
