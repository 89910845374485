import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { MiscTemplate } from './misc.tpl';
import getMisc from '../../services/miscService';
import { GlobalAnalytics } from '../../analytics';

const globalAnalytics = new GlobalAnalytics();

export class MiscComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      header: { title: '', description: '' },
      pageItems: [],
      readMore: [],
      imagePath: this.props.imagePath ? this.props.imagePath : ' ',
      pageName: this.props.pageName ? this.props.pageName : ' ',
    };
  }

  getPageData() {
    getMisc(this.state.pageName)
      .then((pageData) => {
        const readMore = new Array(pageData.rows.length).fill(false);
        this.setState({
          header: pageData.header,
          pageItems: pageData.rows,
          readMore,
        });
        globalAnalytics.firePageLoad({
          page: pageData.header.title,
          section: pageData.header.title,
          tag: '40.1',
        });
      });
  }

  handleClick(event) {
    console.log(event.target);
  }

  componentDidMount() {
    this.getPageData();
  }

  render() {
    return MiscTemplate(this);
  }
}
export default injectIntl(MiscComponent);
