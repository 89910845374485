import React from 'react';

import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import '../../../styles/css/searchBar.css';
import '../../../styles/css/searchGroupFeatureItem.css';
import applyMarkdown from '../../utils/applyMarkdown';
import { FormattedMessage } from 'react-intl';
import {
  generateLearnMoreButton,
  generateOptionsDropdown,
  getEngineOptions,
  getTrimOptions,
  filterTrims,
  optionsDropdownAnalytics,
} from '../resultHelperFunctions.js';

export class GroupFeatureSearchItemComponent extends React.Component {
  constructor(props) {
    super(props);

    const engineSet = [...new Set(props.item.features.map(x => x.engine))];
    const filteredTrims = filterTrims(props.item.features, engineSet[0]);
    this.state = {
      selectedTrimIndex: null,
      filteredTrims,
      selectedEngineValue: engineSet[0],
      standardOnFeatures: this.getStandardOnTrims(filteredTrims[0]),
      availableForFeatures: this.getAvailableForTrims(filteredTrims[0]),
      expandedText: false,
    };

    this.updateSelectedTrimValue = this.updateSelectedTrimValue.bind(this);
    this.updateSelectedEngineValue = this.updateSelectedEngineValue.bind(this);
    this.getStandardOnTrims = this.getStandardOnTrims.bind(this);
    this.getAvailableForTrims = this.getAvailableForTrims.bind(this);
  }

  getTrimOutput() {
    const { item } = this.props;

    const selectedTrim = (this.state.selectedTrimIndex == null ? null : this.state.filteredTrims[this.state.selectedTrimIndex])
    const noValue = (!this.state.standardOnFeatures || !this.state.standardOnFeatures.length) // function bool
      && (!this.state.availableForFeatures || !this.state.availableForFeatures.length);

    if (selectedTrim == null) {
      return <div className="select-trim-message"><FormattedMessage id="tcop.noTrimSelectedSearch" /></div>
    } else {
      return (
          <div>
            {this.state.standardOnFeatures && this.state.standardOnFeatures.length ? 
              <div>
                <div className="content-output-title"> {`${selectedTrim.trim}`}</div>
                <div className="content-output-text">{this.state.standardOnFeatures}</div>
              </div>
            : null }
            {this.state.availableForFeatures && this.state.availableForFeatures.length ? 
              <div>
                <div className="content-output-title">{`Available Option/Package`}</div>
                <div className="content-output-text">{this.state.availableForFeatures}</div>
              </div>
            : null }
            {noValue ? <div className="content-output-title">{`Not Available on: ${selectedTrim.trim}`}</div> : null}
          </div>
      )
    }
  }

  createFeatureSearchResult() {
    const { item } = this.props;
    const engineOptions = getEngineOptions(item.features);
    const trimValues = getTrimOptions(this.state.filteredTrims);
    const learnMoreButton = generateLearnMoreButton(item, this.state, this.props.parentComponent.state.analyticsData.clickAnalytics, this.props.parentComponent.state.searchParam, this.props.parentComponent.state.totalResults);
    const engineDropdown = generateOptionsDropdown(engineOptions, this.updateSelectedEngineValue, 'Engine');
    const trimDropdown = generateOptionsDropdown(trimValues, this.updateSelectedTrimValue, 'Trim');
    const trimOutput = this.getTrimOutput();

    return (
      <div className="group-content">
        <div className="content-header">{item.descriptionToShow}</div>
        <div className="content-dropdowns">
          {GroupFeatureSearchItemComponent.shouldDisplayDrowpdowns(item) ? engineDropdown : null}
          {GroupFeatureSearchItemComponent.shouldDisplayDrowpdowns(item) ? trimDropdown : null}
        </div>
        <div className="content-output">
          {trimOutput}
        </div>
        <div className="quickAnswersSeeMore">
          {learnMoreButton}
        </div>
      </div>
    );
  }

  static shouldDisplayDrowpdowns(item) {
    return item && !item.allTrims;
  }

  getStandardOnTrims(selectedTrim) {
    const standardOnFeatures = [];
    if (selectedTrim && selectedTrim.value && selectedTrim.value.length) {
      selectedTrim.value.forEach((trim, index) => { // display the first 240 characters of the first trim descirption & "hide" the remaining description
      const featureName = <div className="feature-line" key={index}>{applyMarkdown(trim.Description, this.props.disclaimerCallback, this.props.index)}</div>;
      if (trim.standardOn) {
        standardOnFeatures.push(featureName);
      }
      });
    }
    return standardOnFeatures;
  }

  getAvailableForTrims(selectedTrim) {
    const availableForFeatures = [];
    if (selectedTrim && selectedTrim.value && selectedTrim.value.length) {
      selectedTrim.value.forEach((trim, index) => {
        const featureName = <div className="feature-line" key={index}>{applyMarkdown(trim.Description, this.props.disclaimerCallback, this.props.index)}</div>;
        if (trim.availableFor) {
          availableForFeatures.push(featureName);
        }
      });
    }
    return availableForFeatures;
  }

  updateSelectedTrimValue(event) {
    optionsDropdownAnalytics(this.state.filteredTrims[event].trim, 'Trim', this.props.item, this.props.parentComponent.state.analyticsData.clickAnalytics, this.props.parentComponent.state.searchParam, this.props.parentComponent.state.totalResults);
    this.setState({
      selectedTrimIndex: event,
      standardOnFeatures: this.getStandardOnTrims(this.state.filteredTrims[event]),
      availableForFeatures: this.getAvailableForTrims(this.state.filteredTrims[event]),
      expandedText: false,
    }, this.props.parentComponent.reRenderHeight);
  }

  updateSelectedEngineValue(event) {
    const filteredTrims = filterTrims(this.props.item.features, event);
    optionsDropdownAnalytics(event, 'Engine', this.props.item, this.props.parentComponent.state.analyticsData.clickAnalytics, this.props.parentComponent.state.searchParam, this.props.parentComponent.state.totalResults);
    this.setState({
      selectedEngineValue: event,
      filteredTrims,
      selectedTrimIndex: 0,
      standardOnFeatures: this.getStandardOnTrims(filteredTrims[0]),
      availableForFeatures: this.getAvailableForTrims(filteredTrims[0]),
      expandedText: false,
    }, this.props.parentComponent.reRenderHeight);
  }

  componentDidUpdate(nextProps) {
    if (this.props.item.descriptionToShow !== nextProps.item.descriptionToShow
      || this.props.item.displayModelName !== nextProps.item.displayModelName
      || this.props.item.year !== nextProps.item.year
      || this.props.item.displayGroupName !== nextProps.item.displayGroupName) {
      const { item } = this.props;
      const engineSet = [...new Set(item.features.map(x => x.engine))];
      const filteredTrims = filterTrims(this.props.item.features, engineSet[0]);
      this.setState({
        selectedTrimIndex: 0,
        filteredTrims,
        selectedEngineValue: engineSet[0],
        standardOnFeatures: this.getStandardOnTrims(filteredTrims[0], false),
        availableForFeatures: this.getAvailableForTrims(filteredTrims[0], false),
        expandedText: false,
      });
    }
  }

  render() {
    return (this.createFeatureSearchResult());
  }
}

GroupFeatureSearchItemComponent.propTypes = {
  item: PropTypes.object,
  parentComponent: PropTypes.object,
  disclaimerCallback: PropTypes.func,
  index: PropTypes.number,
};

export default injectIntl(GroupFeatureSearchItemComponent);
