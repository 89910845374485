import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CarouselTemplate } from './carousel.tpl';
import leftArrow from '../../resources/images/red_arrow_left.png';
import rightArrow from '../../resources/images/red_arrow_right.png';

export default class CarouselComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: {},
      slides: <div></div>,
    };
    this.getIndex = this.getIndex.bind(this);
  }

  getIndex() {
    return this.slider.innerSlider.state.currentSlide;
  }

  goToIndex(index) {
    return this.slider.slickGoTo(index);
  }

  forceUpdate() {
    this.slider.forceUpdate();
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error, info) {
    // eslint-disable-next-line no-console
    console.log(error, info);
  }

  componentWillMount() {
    this.setCarouselState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setCarouselState(nextProps);
  }

  setCarouselState(props) {
    // eslint-disable-next-line no-shadow
    function NextArrow(props) {
      const { className, style, onClick } = props;
      return (
        <div className={className} style={{ ...style, padding: '19px', background: `url(${rightArrow})` }} onClick={onClick}></div>
      );
    }

    // eslint-disable-next-line no-shadow
    function PrevArrow(props) {
      const { className, style, onClick } = props;
      return (
        <div className={className} style={{ ...style, padding: '19px', background: `url(${leftArrow})` }} onClick={onClick}></div>
      );
    }

    this.setState({
      slides: props.children,
      settings: {
        // eslint-disable-next-line func-names
        customPaging: props.settings.customPaging ? props.settings.customPaging : function (slider, i) {
          const value = i + 1;
          return (<button type="button">{value.toString()}</button>);
        },
        onSwipe: props.settings.onSwipe ? props.settings.onSwipe : null,
        adaptiveHeight: props.settings.adaptiveHeight ? props.settings.adaptiveHeight : false,
        infinite: props.settings.infinite ? props.settings.infinite : false,
        autoplay: props.settings.autoplay ? props.settings.autoplay : false,
        autoplaySpeed: props.settings.autoplaySpeed ? props.settings.autoplaySpeed : 5000,
        slidesToShow: props.settings.slidesToShow ? props.settings.slidesToShow : 3,
        slidesToScroll: props.settings.slidesToScroll ? props.settings.slidesToScroll : 3,
        // eslint-disable-next-line no-prototype-builtins
        arrows: props.settings.hasOwnProperty('arrows') ? props.settings.arrows : true,
        nextArrow: props.settings.nextArrow ? props.settings.nextArrow : <NextArrow />,
        prevArrow: props.settings.prevArrow ? props.settings.prevArrow : <PrevArrow />,
        dots: props.settings.dots ? props.settings.dots : false,
        dotsClass: props.settings.dotsClass ? props.settings.dotsClass : 'slick-dots dots',
        responsive: props.settings.responsive ? props.settings.responsive
          : [{
            breakpoint: 640,
            settings: {
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
            },
          }],
      },
    });
  }

  render() {
    return CarouselTemplate(this);
  }
}

CarouselComponent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};
