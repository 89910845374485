import { useEffect, useMemo, useState } from 'react';
import DropdownV2 from './DropdownV2';

import '../../styles/scss/tabs.scss';
import { tabClick, dropdownChangeClick } from '../../screens/engageXP/engageXPOverview.analytics';

const Tabs = (props) => {

    const [selected, setSelected] = useState(null);

    const dropdownValues = useMemo(() => {
        return props?.tabs?.map(group => ({ value: group?.groupTitle, label: group?.groupTitle }))
    }, [props]);

    useEffect(() => {
        setSelected(props?.tabs?.[0]?.groupTitle);
    }, [props]);

    const handleTabClick = (groupTitle) => {
        setSelected(groupTitle);

        if (props?.analyticsData) {
            const clickAnalytics = props?.analyticsData?.clickAnalytics;
            tabClick(clickAnalytics, groupTitle);
        }
    };

    const handleDropdownValueChange = (selectedOption) => {
        setSelected(selectedOption?.value);

        if (props?.analyticsData) {
            const clickAnalytics = props.analyticsData.clickAnalytics;
            const contentTitle = selectedOption.value;
            dropdownChangeClick(clickAnalytics, contentTitle);
        }
    };

    return <div className='tabs-selector-container'>
        <ul className='tabs-header-tabs'>
            {props?.tabs?.map((group, index) =>
                <li
                    key={index}
                    className={group?.groupTitle === selected ? 'selected' : ''}
                    onClick={() => handleTabClick(group?.groupTitle)}
                    style={{
                        color: group?.groupTitle === 'XP Tournament' ? props.tournamentTextColor : 'initial'
                    }}
                >
                    {group?.groupTitle}
                </li>
            )}
        </ul>

        <div className='tabs-header-dropdown'>
            <DropdownV2
                style="white"
                options={dropdownValues}
                value={selected}
                onValueChange={(option) => handleDropdownValueChange(option)}
            />
        </div>
        <div className='tabs-items'>
            {props?.tabs?.map((group, key) => <div key={key} className={group?.groupTitle === selected ? 'selected' : ''}>{props?.renderTab(group)}</div>)}
        </div>
    </div>
}

export default Tabs;