export function requestRetry(url, options, maxAttempts = 3, currentAttempt = 1) {
  let delay = 500 * currentAttempt;
  return fetch(url, options)
    .then(response => {
      if (!response.ok) throw new Error('Request failed');
      return response.json();
    })
    .catch(error => {
      if (currentAttempt < maxAttempts) {
        console.log(`Attempt ${currentAttempt} failed, retrying again...`);
        const nextAttempt = currentAttempt + 1;
        // Wait for delay before retrying
        return new Promise(resolve => setTimeout(resolve, delay)).then(() =>
          requestRetry(url, options, maxAttempts, nextAttempt)
        );
      } else {
        throw error;
      }
    });
}
