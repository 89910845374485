import React, { useEffect, useState, useRef } from 'react';
import '../../styles/css/towingPayload-inputPopUp.css'
import AppSettings from '../../appsettings.js';
import * as analytics from './towingPayload-analytics';

export const InputPopUp = (props) => {
    const [value, setValue] = useState(props.currentValues[props.variable]);

    useEffect(() => {
        props.pageLoadAnalytics && analytics.openInputPopup(props.pageLoadAnalytics, props.vehicleSelection, props.trimDetails, props.content.title);
    }, []);

    const enterValue = () => {
        let updateValues = {...props.currentValues}
        updateValues[props.variable] = value;
        props.setValues(updateValues);
        props.closePopUp()
        props.clickAnalytics && analytics.inputPopupClick(props.clickAnalytics, props.vehicleSelection, props.trimDetails, props.content.title, value, true);
    };

    const updateInput = (value) => {
        if (!isNaN(value) && value>=0){
            setValue(value);
        } else if (value<0){
            setValue(0)
        }
    };

    const closeButtonClick = () => {
        props.clickAnalytics && analytics.inputPopupClick(props.clickAnalytics, props.vehicleSelection, props.trimDetails, props.content.title, value, false);
        props.closePopUp();
    };

    return (
        <div className="popup-container">
            <div className="tp-inputPopUp">
                <div className="popUpCloseButton" onClick={()=>closeButtonClick()}>
                    <img className="closeIcon" src={AppSettings.AWSImgRepo.resources + 'en/' + 'icon_close_black.svg'} />
                </div>
                <div className="popUpContent">
                    <div className="title">{props.content.title.toUpperCase()}</div>
                    {props.content.subtitle && <div className="subtitle">{props.content.subtitle}</div>}
                    <div className="description">{props.content.definition}</div>
                    <div className="userInputHeader">{props.content.userInputHeader}</div>
                    <div className="userInput">
                        <div className="valueField">
                            <input
                                autoFocus
                                type="number"
                                defaultValue={value ? value : 0}
                                onBlur={(e) => updateInput(parseInt(e.target.value))}
                            />
                        </div>
                        <div className="enterButton" onClick={()=>enterValue()}>ENTER</div>
                    </div>
                </div>
            </div>
        </div>
    );
};