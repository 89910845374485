import React from 'react';
import { FormattedMessage } from 'react-intl';
import VideoPlayerComponent from '../../videoPlayer/videoPlayer.component';
import { FilterMenu } from '../../filterMenu/filterMenu.component';
import '../../../styles/css/tss-videos.css';

export function TSSVideosTemplate(component) {
  const videos = component.state.fullVideoList && component.state.fullVideoList.map((item, index) => {
    const videoItem = {
      ...item,
      alias: item.brightcoveId,
      qumuId: item.qumuId,
      videoTitle: item.videoTitle,
      pageTitle: 'TSS Videos',
    };
    return (
      (item.packageType.indexOf(component.state.activeFilter) > -1)
        && <div key={index} className="small-12 medium-6 large-4 columns end">
            <div className="video">
                <VideoPlayerComponent
                    item={videoItem}
                    sendVideoEventAnalytics={component.sendVideoEventAnalytics}
                />
                <div className="video-title">{item.videoTitle}</div>
                <div className="redline"></div>
                <div className="video-description-text">{item.videoDescription}</div>
            </div>
        </div>
    );
  });

  return (
        <div className="tss-video">
            {component.state.languageOverride
              ? <div className="language-unavailable">
                    <FormattedMessage id="dataUnavailable" default="Language is unavailable" />
                </div> : null
            }
            <div className="title">
                <FormattedMessage id="global.videos" defaultMessage="Videos" />
            </div>
            <FilterMenu
                active={component.state.activeFilter}
                filters={component.props.filters}
                filterClick={component.switchFilter}
                displayArrow={true}
            />
            <div className="row">
                {videos}
            </div>
        </div>

  );
}
