import React from 'react';

import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import '../../../styles/css/searchBar.css';
import '../../../styles/css/searchColorAvailability.css'
import { generateOptionsDropdown, optionsDropdownAnalytics, generateLearnMoreButton } from '../resultHelperFunctions.js';
import AppSettings from '../../../appsettings.js';


export class ColorAvailabilitySearchItemComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedColorIndex: 0,
    };

    this.updateSelectedColorValue = this.updateSelectedColorValue.bind(this);
    this.generateAvailableOnList = this.generateAvailableOnList.bind(this);
    this.generateColorDetails = this.generateColorDetails.bind(this);
  }


  static getColorOptions(colorItems) {
    const colorOptions = colorItems ? colorItems.map((colorItem, index) => (
      {
        label: colorItem.paintCodeName,
        value: index,
      }
    )) : null;

    return colorOptions;
  }

  updateSelectedColorValue(value) {
    optionsDropdownAnalytics(this.props.item.values[value].paintCodeName, 'Color', this.props.item, this.props.parentComponent.state.analyticsData.clickAnalytics, this.props.parentComponent.state.searchParam, this.props.parentComponent.state.totalResults);
    this.setState({
      selectedColorIndex: value,
    }, this.props.parentComponent.reRenderHeight);
  }

  createSearchResult() {
    const { item } = this.props;
    const selectedColor = item.values[this.state.selectedColorIndex];
    const colorOptions = ColorAvailabilitySearchItemComponent.getColorOptions(this.props.item.values);
    const colorDetails = this.generateColorDetails(selectedColor);
    const availableOn = selectedColor.trims.length > 0 ? selectedColor.trims : null;
    const availableOnList = this.generateAvailableOnList(availableOn);
    const colorDropDown = generateOptionsDropdown(colorOptions, this.updateSelectedColorValue);
    const learnMoreButton = generateLearnMoreButton(item, this.state, this.props.parentComponent.state.analyticsData.clickAnalytics, this.props.parentComponent.state.searchParam, this.props.parentComponent.state.totalResults);

    return (
      <div className="item-content">
        <div className="content-header">{item.descriptionToShow}</div>
        <div className="content-dropdowns">
          {colorDropDown}
        </div>
        <div className="content-output">
            {colorDetails}
            <div className="color-output-title">AVAILABLE ON:</div>
            <div className="color-availability">{availableOnList}</div>
        </div>
        <div className="quickAnswersSeeMore">
          {learnMoreButton}
        </div>
      </div>
    );
  }

  generateAvailableOnList(items) {
    const availableOnList = items.map((item, index) => (
        <div className={"color-output-text color-availability-item"} key={index}>{item}</div>
      ));
    return availableOnList;
  }

  generateColorDetails(selectedColor) {
    return (
      <div className="color-output">
        <div className="color-output-title">PAINT CODE:</div>
          <div className="color-output-details">
            <div className="color-output-text">{selectedColor.paintCode}</div>
            {this.generateColorSwatch()}
          </div>
      </div> 
    );
  }

  generateColorSwatch() {
    const selectedColor = this.props.item.values[this.state.selectedColorIndex];
    const hexCode = selectedColor.hexCode || AppSettings.PaintHexCodes[selectedColor.paintCode];
    const style = hexCode === '#FFFFFF' ? { backgroundColor: hexCode, border: 'solid black 1px' } : { backgroundColor: hexCode };
    return (
      <div className="color-output-circle" style={style}></div>
    );
  }

  render() {
    return (this.createSearchResult());
  }
}

ColorAvailabilitySearchItemComponent.propTypes = {
  item: PropTypes.object,
  parentComponent: PropTypes.object,
  disclaimerCallback: PropTypes.func,
  index: PropTypes.number,
};

export default injectIntl(ColorAvailabilitySearchItemComponent);
