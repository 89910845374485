import React from 'react';

export const CompetitorModelTrimComponent = ({ competitorObject, isLastCompetitor, handleCompetitorComparisonOnClick, ...props}) => {

  const toyotaVehicle = competitorObject.toyotaVehicle;
  const competitorVehicle = competitorObject.competitorVehicle

  return isLastCompetitor ? (
    <div className="model-trim-component">
      <a className="model-title" onClick={() => {handleCompetitorComparisonOnClick(toyotaVehicle,competitorVehicle)}}>{competitorVehicle.model}</a>
      <div className="model-title-trim">{competitorVehicle.trim.toUpperCase()}</div>
    </div>
  ) : (
    <div className="model-trim-component">
      <a className="model-title" onClick={() => {handleCompetitorComparisonOnClick(toyotaVehicle,competitorVehicle)}}>{competitorVehicle.model}</a>
      <div className="model-title-trim">{competitorVehicle.trim.toUpperCase()}</div>
      <hr className="ca-black-line" />
    </div>
  )
}