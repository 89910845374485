import React from "react";
import AppSettings from "../../../appsettings";
import "../../../styles/css/engageXP/engageXPMyStatsInfoCard.css";
import getTarget from "../../utils/getTarget";
import { myStatsLinkClick } from "../engageXPStats.analytics";
import { useHistory } from "react-router";

const ProgressRing = ({ progressPercent }) => {
  const measurements = { cx: 0, cy: 0, r: 0, strokeDashArray: 0 };

  measurements.cx = "48";
  measurements.cy = "48";
  measurements.r = "48";
  measurements.strokeDashArray = [(299 * progressPercent) / 100, 1000];

  return (
    <div
      className="progressRing"
      style={{ "--strokeDashArray": measurements.strokeDashArray }}
    >
      <svg>
        <circle
          className="grey-ring"
          cx={measurements.cx}
          cy={measurements.cy}
          r={measurements.r}
        ></circle>
        <circle
          className="blue-ring"
          cx={measurements.cx}
          cy={measurements.cy}
          r={measurements.r}
        ></circle>
      </svg>
    </div>
  );
};

const MyStatsInfoCard = ({
  currentLevelPoints,
  pointsNeeded,
  moreInfoLink,
  currentLevel,
  nextLevel,
  userPoints,
  moreInfoText,
  analyticsData
}) => {

  const clickAnalytics = analyticsData?.clickAnalytics;
  const analyticsCategory = "My Stats";
  const analyticsPage = "Engage XP";
  const history = useHistory();
  
  const getPercentToNextLevel = () => {
    const totalPointsInCurrentLevel = currentLevelPoints + pointsNeeded;
    return (currentLevelPoints / totalPointsInCurrentLevel) * 100;
  };

  const handleLinkClick = () => {
    myStatsLinkClick(clickAnalytics,analyticsPage, analyticsCategory, moreInfoText);
    history.push("/engage-xp/missions/how-do-points-work");
  };

  return (
    <div className="my-stats-info-card">
      <div className="ring-section">
        <span className="left-aligned bold26">Your Progress</span>
        <div className="ring">
          <ProgressRing progressPercent={getPercentToNextLevel()} />
        </div>
        <span className="bold46 uppercase">{currentLevel}</span>
        <span className="reg16">({`${pointsNeeded}XP to ${nextLevel}`})</span>
      </div>

      <div className="points-section">
        <span className="bold46">{userPoints}</span>
        <span className="bold16">XP POINTS</span>

        <div className="points-link">
          <img
            className="link-icon"
            src={`${AppSettings.AWSImgRepo.resources}en/xpInfoIcon.png`}
            alt="info"
          />
          <div className="link-text" onClick={() => handleLinkClick()}>
            {moreInfoText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyStatsInfoCard;
