import React, { useState, useEffect, useRef } from 'react';
import RedLine from '../../../components/atoms/RedLine';
import applyMarkdown from '../../utils/applyMarkdown';
import applyCustomMarkdown from '../../utils/applyCustomMarkdown';
import AppSettings from '../../../appsettings';
import '../../../styles/css/ammCompareFeaturesContent.css';

function CompareFeaturesContent(props) {
    const content = props.contentSelected;
    const [vehicle, setVehicle] = useState('Year **Vehicle**');
    const [model, setModel] = useState("");
    const [year, setYear] = useState();
    const [standardTitle, setStandardTitle] = useState();
    const [optionalTitle, setOptionalTitle] = useState();
    const [standardImage, setStandardImage] = useState();
    const [optionalImage, setOptionalImage] = useState();
    const [standardFeatures, setStandardFeatures] = useState([]);
    const [optionalFeatures, setOptionalFeatures] = useState([]);
    const [standardHowToIdentify, setStandardHowToIdentify] = useState([]);
    const [optionalHowToIdentify, setOptionalHowToIdentify] = useState([]);
    const imagePath = AppSettings.AWSImgRepo.amm;
    const disclaimerRef = useRef();

    useEffect(() => {
        props.setDisclaimer(disclaimerRef.current);
      }, [disclaimerRef]);

    useEffect(() => {
        setVehicle(content.selectedCar);
        setModel(content.selectionModel);
        setYear(content.selectionYear);
        setStandardTitle(content.standardSystem?.title || 'Audio Lineup Not Available');
        setOptionalTitle(content.optionalSystem?.title || 'Audio Lineup Not Available');
        setStandardImage(content.standardSystem?.headerImage || 'MMA_coming_soon.png');
        setOptionalImage(content.optionalSystem?.headerImage || 'MMA_coming_soon.png');
        setStandardFeatures(content.standardSystem?.features);
        setOptionalFeatures(content.optionalSystem?.features);
        if (content.standardSystem?.howTos) {
            setStandardHowToIdentify(content.standardSystem?.howTos);
        }
        if (content.optionalSystem?.howTos) {
            setOptionalHowToIdentify(content.optionalSystem?.howTos);
        }
    }, [content]);

    useEffect(() => {
        if (standardFeatures[0]?.title?.includes('How to')) {
            const standardHowTos = standardFeatures[0].title.replace(/(\r\n|\n|\r)/gm, '').split('* ').filter(item => !item.includes('How to'));
            setStandardHowToIdentify([...standardHowToIdentify, ...standardHowTos]);
        }
    }, [standardFeatures]);

    useEffect(() => {
        if (optionalFeatures[0]?.title?.includes('How to')) {
            const optionalHowTos = optionalFeatures[0].title.replace(/(\r\n|\n|\r)/gm, '').split('* ').filter(item => !item.includes('How to'));
            setOptionalHowToIdentify([...optionalHowToIdentify, ...optionalHowTos]);
        }
    }, [optionalFeatures]);

    function renderHeader(type) {
        const image = (type === 'standard') ? standardImage : optionalImage;
        const title = (type === 'standard') ? standardTitle : optionalTitle;
        const showImage = (title !== 'Audio Lineup Not Available');
        const tcopLink = model && year && `${AppSettings.BasePath}/product/${(model.replace(/\s+/g, '')).toUpperCase()}/${year}/tcop`;

        return (<>
            <div className={`${type}-audio-header`} >
                <div className='system-label'>
                    {type === 'standard' ? 'STANDARD SYSTEM' : 'OPTIONAL SYSTEM'}
                </div>
                <div className='system-title'>
                    {applyMarkdown(title)}
                </div>
            </div>
            {showImage ?
                <img className={`${type}-image image`} alt={image} src={imagePath + (image)} />
                :
                <div className={`tcop-link-container tcop-link-container-${type}`}>
                    <a href={tcopLink} className='tcop-link'>See package options for the {year} {model}</a>
                </div>
            }
        </>);
    }

    function renderHowToIdentify(systemType, howToArray) {
        const rows = Math.max(standardHowToIdentify?.length, optionalHowToIdentify?.length);
        const howToIdentify = new Array(rows);
        for (let i = 0; i < rows; i++) {
            howToIdentify[i] = howToArray[i] || '';
        }

        return (
            <table className={`${systemType}-table`}>
                <thead>
                    <tr><th><span className={`${systemType}-column-label`}>HOW TO IDENTIFY</span></th></tr>
                </thead>
                <tbody>
                    {howToIdentify.map(howTo => <tr><td key={howTo} className='table-row'>{applyMarkdown(howTo)}</td></tr>)}
                </tbody>
            </table>
        )
    }

    function renderFeatures(systemType, features) {
        return (
            <table className={`${systemType}-table`}>
                <tr><th><span className={`${systemType}-column-label`}>FEATURES</span></th></tr>
                <tbody>
                    {features.map(feature => {
                        if (feature.title && feature.title.includes('How to')) return;
                        return (<tr><td key={feature.title} className='table-row'>{applyMarkdown(feature.title, props.disclaimer)}</td></tr>)
                    })}
                </tbody>
            </table>
        );
    }

    return (
        <div className='compare-features-content'>
            <div className='modal-title' >
                {applyCustomMarkdown(vehicle, 'bold')}
            </div>
            <RedLine />
            <div className='systems'>
                <div className='standard-audio-system'>
                    {renderHeader('standard')}
                    {(standardHowToIdentify?.length !== 0 || optionalHowToIdentify?.length !== 0) ? <>
                        <div className='standard-how-to-identify'>
                            {renderHowToIdentify('standard', standardHowToIdentify)}
                        </div>
                    </> : <></>}
                    <div className='standard-features'>
                        {renderFeatures('standard', standardFeatures)}
                    </div>
                </div>
                <div className='optional-audio-system'>
                    {renderHeader('optional')}
                    {(standardHowToIdentify?.length !== 0 || optionalHowToIdentify?.length !== 0) && (optionalTitle !== 'Audio Lineup Not Available') ? <>
                        <div className='optional-how-to-identify'>
                            {renderHowToIdentify('optional', optionalHowToIdentify)}
                        </div>
                    </> : <></>}
                    <div className='optional-features'>
                        {(optionalFeatures.length > 0) && renderFeatures('optional', optionalFeatures)}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompareFeaturesContent;