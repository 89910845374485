import { React } from "react";
import AppSettings from "../../../../appsettings";
import RoundedButton from "../../../../components/molecules/roundedButton";
import "../../../../styles/scss/beyondZero/evMatchMaker.scss";

const EvMatchMakerLandingPage = ({ landingData, onClick }) => {
  const {
    title,
    description,
    smallText,
    buttonText,
    desktopImage,
    tabletImage,
    mobileImage,
  } = landingData;

  return (
    <div className="beyond-zero-ev-matchmaker-wrapper">
      <header
        style={{
          "--desktop-banner": `url(${AppSettings.AWSImgRepo.beyondZero}${desktopImage})`,
          "--tablet-banner": `url(${AppSettings.AWSImgRepo.beyondZero}${tabletImage})`,
          "--mobile-banner": `url(${AppSettings.AWSImgRepo.beyondZero}${mobileImage})`,
        }}
      ></header>
      <div className="beyond-zero-ev-matchmaker-container">
        <h1>{title}</h1>
        <p>{description}</p>
        <small>{smallText}</small>
        <RoundedButton
          onClick={() => onClick("quiz")}
          title={buttonText}
          height={58}
          width={223}
          color="#000"
          textColor="#fff"
          activeColor="#000"
          borderColor="#000"
          className="beyond-zero-ev-matchmaker-find-match-button"
        />
      </div>
    </div>
  );
};

export default EvMatchMakerLandingPage;
