import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import '../../../../styles/css/topNav/dropdownMenu/vehiclesMenu/vehicleMenu.css';
import AppSettings from '../../../../appsettings';
import { menuItemClicked, vehicleClicked } from '../../topNav.analytics';

const VehicleMenu = props => {
  const [vehicleCategory, setVehicleCategory] = useState(null);
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [columns, setColumns] = useState(1);
  const activeRef = useRef();

  const CATEGORY_BACKGROUND = {
    "Cars & Minivan": "cars-minivan.png",
    "Crossovers & SUVs": "crossovers-suvs.png",
    "Trucks": "trucks.png",
    "Beyond Zero": "electrified.png",
    "Non-Production": "non-production.png"
  }

  useEffect(() => {
    if (props.vehicleData) {
      const defaultCategory = props.vehicleData.find(e => e.order == 1)
      defaultCategory ? setVehicleCategory(defaultCategory) : setVehicleCategory(props.vehicleData[0])
    }
  }, [props.vehicleData])

  useEffect(() => {
    vehicleCategory && activeRef.current?.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'})
  }, [vehicleCategory]);

  useEffect(() => {
    getVehicleColumns();
    window.addEventListener("resize", getVehicleColumns);
    return () =>
      window.removeEventListener("resize", getVehicleColumns);
  }, [props.device]);

  const getVehicleColumns = () => {
    if(props.device == "desktop") {
      if ((window.innerWidth - 550) > 1110) {
        setColumns(3)
      } else if ((window.innerWidth - 550) > 725) {
        setColumns(2)
      } else setColumns(1)
    } else if (props.device == "tablet") {
      if ((window.innerWidth - 428) > 522) {
        setColumns(2)
      } else setColumns(1)
    }
  }

  const handleCategoryClick = (item) => {
    setVehicleCategory(item)
    // analytics
    menuItemClicked(props.analytics, item.title);
  }

  const handleVehicleClick = (item) => {
    const indexOfSpace = item.title.indexOf(' ');
    const year = item.title.substring(0, indexOfSpace);
    const model = item.title.substring(indexOfSpace + 1);
    vehicleClicked(props.analytics, year, model);
  }

  return (
    <div className="vehicle-menu-container">
      <div className="vehicle-categories-scroll-container">
        <div className="vehicle-categories">
          {props.vehicleData?.map((item) => {
            let activeClass = vehicleCategory && vehicleCategory.title === item.title ? 'active' : '';
            if (hoveredCategory) {
              activeClass = hoveredCategory === item.title ? 'active' : '';
            }
            return <div style={{ backgroundImage: `url(${AppSettings.AWSImgRepo.vehicleCategoryCards + CATEGORY_BACKGROUND[item.title]})` }} 
                        className={`vehicle-category-item ${activeClass}`} 
                        onClick={() => handleCategoryClick(item)} 
                        ref={activeClass === 'active' ? activeRef : null}
                        onMouseEnter={() => setHoveredCategory(item.title)}
                        onMouseLeave={() => setHoveredCategory(null)}
                    >
              <div className={`vehicle-category-item-overlay ${activeClass}`}>
                <span className='vehicle-category-item-title'>{item.title}</span>
              </div>
            </div>
          })}
        </div>
      </div>
      <div className="vehicle-items" style={{"--columns": columns}}>
          {vehicleCategory?.menuItems.map((item, index, array) => (
            <div className={`vehicle-item ${(index >= array.length - columns) && "bottom-vehicle"}`} onClick={() => handleVehicleClick(item)}>
              <a className="vehicle-image-name" href={item.href}>
                  <div className="jelly-container">
                      <img className="vehicle-jelly" src={AppSettings.AWSImgRepo.jellybeans + (item.rotatedImgSrc ? item.rotatedImgSrc : item.imgSrc)} /></div>
                  <div className="model-year-title">{item.title}</div>
              </a>
            </div>
          ))}
          {vehicleCategory?.menuItems.length < 3 && 
          <div className={`vehicle-item bottom-vehicle`}></div>}

          {vehicleCategory?.menuItems.length === 1 && 
          <div className={`vehicle-item bottom-vehicle`}></div>}
      </div>
    </div>
  )
};

VehicleMenu.propTypes = {
  vehicleData: PropTypes.array,
};

export default VehicleMenu;
