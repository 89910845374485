import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useParams, useHistory } from 'react-router';
import SubheaderComponent from '../subheader/subheader.component';
import Display404NotFound from '../../components/atoms/404NotFound';
import { DynamicColumnItemList } from '../../components/molecules/dynamic-column-item-list';
import { DynamicRowItemList } from '../../components/molecules/dynamic-row-item-list';
import { overviewAnalytics } from './recalls-and-safety-analytics';
import getMisc from '../../services/miscService';
import AppSettings from '../../appsettings';
import { availableFilters } from './recalls-and-safety-utils';

const RecallsAndSafetyRouter = (props) => {
  const [pageData, setPageData] = useState(null);
  const history = useHistory();

  useEffect(() => {
    getMisc('RecallsAndSafetyCampaign', 'en')
      .then((data) => {
        setPageData(data);
      })
      .catch((error) => {
        console.log(`ERROR: failed to load Recalls And Safety Campaign page data. ${JSON.stringify(error.statusText)}`);
      });
  }, []);

  const sharedProps = {
    defaultFilterId: 'awareness',
    disclaimerName: 'RecallsAndSafetyCampaign',
    internalFilePath: AppSettings.AWSImgRepo.recallAndSafety,
    imagePath: AppSettings.AWSImgRepo.recallAndSafety,
    parentPageName: 'Recalls and Safety Campaign',
    availableFilters,
  };

  const getSubheaderChildComponent = (path) => {
    const page = path ? path.toString() : '';
    switch (page) {
      case 'overview':
        return pageData.items && <DynamicColumnItemList
          {...pageData.items.overview}
          {...sharedProps}
          pageLoadAnalytics={overviewAnalytics.pageLoadAnalytics} />;
      case 'takata':
        return <DynamicRowItemList
          {...sharedProps}
          headline={'Takata'}
          items={pageData.items.takata}
          pageLoadAnalytics={() => overviewAnalytics.pageLoadAnalytics} />;
      case 'otherRecalls':
        return <DynamicRowItemList
          {...sharedProps}
          headline={'Other Recalls'}
          items={pageData.items.nonTakata}
          pageLoadAnalytics={() => overviewAnalytics.pageLoadAnalytics} />;
      case 'quickReferenceGuides':
        return <DynamicRowItemList
          {...sharedProps}
          headline={'All Quick Reference Guides'}
          items={pageData.items.quickReferenceGuide}
          pageLoadAnalytics={() => overviewAnalytics.pageLoadAnalytics} />;
      default:
        history.push('/recallsAndSafety/overview');
        break;
    }
  };

  const params = useParams();
  const pageName = params && params.page;
  const route = {
    engine: '',
    featSpec: undefined,
    model: '',
    path: pageName || 'overview',
    trim: '',
    year: params.year,
  };
  const isValidTabName = () => ['overview', 'takata', 'otherRecalls', 'quickReferenceGuides'].filter(x => x === pageName).length > 0;
  const getPageDisplay = () => {
    if (isValidTabName()) {
      return (
        <div>
        {pageData
          && <SubheaderComponent {...props} route={route} params={params}>
            {getSubheaderChildComponent(pageName)}
          </SubheaderComponent>
        }
      </div>
      );
    }
    return <Display404NotFound display />;
  };
  return (
  <div>{getPageDisplay()}</div>
  );
};

export default injectIntl(RecallsAndSafetyRouter);
