import { getLocale } from '../services/languageTranslationService.js';

export function formatPage(page) {
  switch (page) {
    case 'rules':
      return 'Rules';
    case 'fundamentalsLearning':
      return 'Fundamentals Learning';
    case 'productLearning':
      return 'Product Learning';
    case 'inCabinTechnologyLearning':
      return 'In-Cabin Technology Learning';
    case 'safetyLearning':
      return 'Safety Learning';
    case 'industryLearning':
      return 'Industry Learning';
    default:
      return 'Error: Invalid page value';
  }
};

export function getSiteSize() {
  if (window.innerWidth >= 1025) {
    return 'Full Size';
  } if (window.innerWidth < 1025 && window.innerWidth >= 640) {
    return 'Tablet';
  }
  return 'Micro Size';
};

export function getViewPort() {
  if (window.innerWidth >= 1025) {
    return 'Large';
  } if (window.innerWidth < 1025 && window.innerWidth >= 640) {
    return 'Medium';
  }
  return 'Small';
};

export function getAppId() {
  const locale = getLocale();
  switch (locale) {
    case 'en':
      return 'eShow';
    case 'es':
      return 'eShowESP';
    default:
      return 'eShow';
  }
};
