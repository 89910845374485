import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Appsettings from "../../appsettings";
import "../../styles/css/engageXP.css";
import BackButton from "../../components/molecules/BackButton";
import applyMarkdown from "../utils/applyMarkdown";

import { postViewPageAction } from "../../services/nitroService";
import { HowDoPageLoad, backButtonClick, checkBalanceClick } from "./engageXPHowDo.analytics";

const s3Path = '/images/engageXP/';

const EngageHowDo = ({ pageData, analyticsData }) => {
    const analyticsPage = "Engage XP";
    const analyticsCategory = "How Do Points Work";
    const clickAnalytics = analyticsData?.clickAnalytics;
    const history = useHistory();
    const handleBackClick = () => {
        if (analyticsData) {
            backButtonClick(clickAnalytics, analyticsPage, analyticsCategory);
        }
        history.goBack();
    }

    const handleButtonClick = () => {
        if (analyticsData) {
            checkBalanceClick(clickAnalytics, analyticsPage, analyticsCategory);
        }
    }

    useEffect(() => {
        if (analyticsData) {
            postViewPageAction(window.location.href);
            HowDoPageLoad(analyticsData.pageLoad?.howDoPointsWork)
            HowDoPageLoad();
        }
    }, [analyticsData]);

    return (
        <div className="engageXPHowDo">
            <div className="headerContainer">
                <BackButton onClick={handleBackClick} />
                <div className="titleTextContainer">
                    <div className="titleText">{pageData.title}</div>
                </div>
            </div>

            <div className="cardsContainer">
                <FourCard cards={pageData.cards} />
            </div>
            <div className="checkMyBalanceContainer">
                <a className="checkMyBalanceButton" target="_blank" href={pageData.bottomButtonLink} onClick={handleButtonClick}>
                    {pageData.bottomButtonText}
                </a>
            </div>
        </div>
    );
}

const FourCard = ({ cards }) => {
    return (cards.map((card, index) => (
        <div className="cardContainer">
            <div className="mainImage">
                <img src={Appsettings.AWSImgRepo.static + s3Path + card.cardImage} />
                <div className="imageIndex">0{index + 1}</div>
            </div>
            <div className="cardTitle">
                {card.title}
            </div>
            <div className="description">
                {applyMarkdown(card.description)}
            </div>
        </div>
    )));
}

export default EngageHowDo;
