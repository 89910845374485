import { GlobalAnalytics } from '../../analytics';
import { postTrimWalkAction } from '../../services/nitroService';

const analytics = new GlobalAnalytics();

export function sendPageLoadAnalytics() {
  analytics.firePageLoad({
    tag: '29.1',
    section: 'Product',
    page: 'Trim Walk',
    modelName: this.props.route.model,
    modelYear: this.props.params.year,
  });
}

export function sendTrimSelectionAnalytics(container, category, label, modelName, modelYear, trim) {
  postTrimWalkAction(category, label, modelName, modelYear, trim);
  analytics.fireOnClick({
    tag: '29.2',
    section: 'Product',
    page: 'Trim Walk',
    modelName: modelName,
    modelYear: modelYear,
    technologyName: 'Trim Walk',
    trim: trim,
    container: container,
    module: 'Dropdown',
    category: category,
    label: label,
  });
}

export function sendClickAnalytics(category, label) {
  postTrimWalkAction(category, label, this.props.route.model, this.props.params.year);
  analytics.fireOnClick({
    container: 'Module',
    page: 'Trim Walk',
    section: 'Product',
    modelName: this.props.route.model,
    modelYear: this.props.params.year,
    category: category,
    label: label,
    tag: '29.2',
  });
}
