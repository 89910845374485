import { GlobalAnalytics } from '../../../analytics';

const analytics = new GlobalAnalytics();

export const smartPathLoaded = (pageLoadAnalytics, filterCategory) => {
  analytics.firePageLoad({
    tag: pageLoadAnalytics.tagId,
    section: pageLoadAnalytics.section,
    subsection: pageLoadAnalytics.subsection,
    page: filterCategory,
  });
};

const contentLinkClick = (tag, section, subsection, technologyName, page, category, positionTitle, contentTitle) => {
  analytics.fireOnClick({
    tag,
    section,
    subsection,
    technologyName,
    page,
    category,
    positionTitle,
    contentTitle,
  });
};

export const quickNav = (clickAnalytics, contentTitle) => {
  clickAnalytics && clickAnalytics.introQuickNav && contentTitle && contentLinkClick(
    clickAnalytics.introQuickNav.tagId,
    clickAnalytics.introQuickNav.section,
    clickAnalytics.introQuickNav.subsection,
    clickAnalytics.introQuickNav.technologyName,
    clickAnalytics.introQuickNav.page,
    clickAnalytics.introQuickNav.category,
    clickAnalytics.introQuickNav.positionTitle,
    contentTitle,
  );
};
