import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getFormattedName, getFormattedTrim } from '../../../utils/vehicleName';
import applyMarkdown from '../../../utils/applyMarkdown';

export function TopTenFeatures(props) {
  const toyotaFeatures = props.edgeContent.topFeatures.toyotaFeatures.map((feature, index) => (
        <div key={index}>
            <div>
                {feature.color.toLowerCase() === 'black'
                  ? <div>{applyMarkdown(feature.description, props.disclaimer)}</div>
                  : <div className="red">{applyMarkdown(feature.description, props.disclaimer)}</div>
                }
            </div>
            <div className="black-line" />
        </div>
  ));

  const competitorFeatures = props.edgeContent.topFeatures.competitorFeatures.map((feature, index) => (
        <div key={index}>
            <div>
                {feature.color.toLowerCase() === 'black'
                  ? <div>{applyMarkdown(feature.description, props.disclaimer)}</div>
                  : <div className="red">{applyMarkdown(feature.description, props.disclaimer)}</div>
                }
            </div>
            <div className="black-line" />
        </div>
  ));

  return (
        <div className="ttf">
            <div className="ttf-subheader">
                <span className="ttf-redText">
                    <FormattedMessage id="caHeadToHead.redText" defaultMessage="Red Text" />
                </span>&nbsp;
        <span>
                    <FormattedMessage id="caHeadToHead.redTextEquals" defaultMessage=" = Package/Option" />
                </span>
            </div>
            <div className="ttf-toyota columns large-6 medium-6 small-6">
                <div className='ttf-bold'>
                    {props.edgeContent.topFeatures.headerTextToyota
                      ? <span>{props.edgeContent.topFeatures.headerTextToyota}</span>
                      : <span><span>{applyMarkdown(getFormattedName(props.vehicleModel), null)}</span>&nbsp;<span>{applyMarkdown(getFormattedTrim(props.selectedValueToyota))}</span></span>
                    }
                </div>
                <div className='bold-line' />
                {toyotaFeatures}
            </div>
            <div className="ttf-competitor columns large-6 medium-6 small-6">
                <div className='ttf-bold'>
                    {props.edgeContent.topFeatures.headerTextCompetitor
                      ? <span>{props.edgeContent.topFeatures.headerTextCompetitor}</span>
                      : <span>{props.selectedValueCompetitor}</span>
                    }
                </div>
                <div className='bold-line' />
                {competitorFeatures}
            </div>
        </div>
  );
}
