import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { DealershipListTemplate } from './dealershipList.tpl';
import { getDealershipUsers, getDealership } from '../../services/profileService';


class DealerListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profiles: [],
      dealerName: '',
      regionCode: '',
    };
  }

  alphabetizeProfiles(profiles) {
    profiles.sort(this.compareName);
    return profiles;
  }

  compareName(a, b) {
    const p1Name = `${a.firstName} ${a.lastName}`;
    const p2Name = `${b.firstName} ${b.lastName}`;

    if (p1Name < p2Name) return -1;
    if (p1Name > p2Name) return 1;
    return 0;
  }

  componentDidMount() {
    const { dealerCode } = this.props.match.params;
    this.loadSpinner.startSpinner();
    Promise.all([getDealershipUsers(dealerCode), getDealership(dealerCode)])
      .then((dealershipData) => {
        this.loadSpinner.stopSpinner();
        this.setState({
          profiles: this.alphabetizeProfiles(dealershipData[0]),
          dealershipName: dealershipData[1].dealerName,
          regionCode: dealershipData[1].regionCode,
        });
      })
      .catch((error) => {
        this.loadSpinner.stopSpinner();
        console.log(`Error getting page data: ${error}`);
      });
  }


  render() {
    return DealershipListTemplate(this);
  }
}

export default injectIntl(DealerListComponent);
