import React, {useState} from "react";
import "../../../styles/css/beyondZero/addVehicleButton.css"
import appsettings from "../../../appsettings";

const AddVehicleButton = ({onClick, isClicked}) => {

    const handleClick = () => {
        onClick();
    }; 
   
    function ReturnImage({imgName}){
        return(
        <img
            alt="vehicle-button"
            draggable="false"
            className="add-icon-element"
            src={`${appsettings.AWSImgRepo.beyondZero}${imgName}`}
        />
        );
    }

    return (
        <div className="bz-add-remove-vehicle-button" onClick={handleClick}>
            {!isClicked ? (
                <div className="add-to-vehicle-button-container">
                    <ReturnImage imgName={'bz-add-vehicle-button.png'}></ReturnImage>
                    <div className="add-text-container"> Add Vehicle </div>
                </div>
            ) : (
                <ReturnImage imgName={'bz-remove-vehicle-button.png'}></ReturnImage>
            )}
        </div>
    );
};

export default AddVehicleButton;
