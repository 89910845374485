import React, { useEffect, useState } from 'react';
import DropdownV2 from './DropdownV2';
import AppSettings from '../../appsettings';
import '../../styles/css/pdfDownloader.css';
import { isMobileView } from '../../screens/utils/getScreenSize';

const PdfDownloader = (props) => {
  const [pdfOptions, setPdfOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const noOptions = !(pdfOptions.length > 0);
  const noMessage = !(props.message);
  const defaultOption = noOptions ? 'N/A' : pdfOptions[0];
  const downloadIcon = AppSettings.AWSImgRepo.resources + 'en/eBrochures_download.png';
  const redDownloadIcon = AppSettings.AWSImgRepo.resources + 'en/eBrochures_download_red.png';
  const redDownChevron = AppSettings.AWSImgRepo.resources + 'en/chevron_down_red.png';

  const downloadPdf = () => {
    props.chartDownloadAnalytics && props.chartDownloadAnalytics(selectedOption);
    const selection = props.pdfs.filter(pdf => pdf.label === selectedOption);
    const pdfUrl = selection[0].url;
    window.open(pdfUrl, '_blank');
  };

  const handleYearDropdownValueChange = dropdownValue => {
    props.lineupSelectAnalytics && props.lineupSelectAnalytics(dropdownValue.value);
    setSelectedOption(dropdownValue.value);
  }

  useEffect(() => {
    if (props.pdfs) {
      const sortedOptions = props.pdfs.sort((a, b) => b.label.localeCompare(a.label))
      const options = sortedOptions.map(option => option.label);
      setPdfOptions(options);
      setSelectedOption(options[0]);
    } else {
      setPdfOptions([]);
    }
  }, [props.pdfs]);

  const dropdownClosedArrow = !noOptions && <img className="pdf-downloader-dropdown-arrow" src={redDownChevron} />;
  const dropdownOpenArrow = <img className="pdf-downloader-dropdown-arrow-upside-down" src={redDownChevron} />;

  return (
    <div className='pdf-downloader-container'>
      {noMessage ? <></> :
        <div className="pdf-downloader-message">
          {props.message}
        </div>
      }
      <div className={`pdf-downloader ${noMessage ? 'centered' : ''}`}>
        <div className={`pdf-downloader-dropdown ${noOptions ? 'action-disabled' : ''}`}>
          <DropdownV2
            arrowClosed={dropdownClosedArrow}
            arrowOpen={dropdownOpenArrow}
            defaultValue={defaultOption}
            disabled={noOptions}
            options={pdfOptions}
            onValueChange={value => handleYearDropdownValueChange(value)}
            placeholderClassName={`pdf-downloader-dropdown-placeholder ${noOptions ? 'placeholder-centered' : ''}`}
          />
        </div>
        <a
          className={`pdf-downloader-download ${noOptions ? 'action-disabled' : ''}`}
          onClick={() => !noOptions && downloadPdf()}
        >
          <img
            className="pdf-downloader-download-icon"
            src={downloadIcon}
            onMouseOver={e => !noOptions && !isMobileView(window.innerWidth) && (e.currentTarget.src = redDownloadIcon)}
            onMouseOut={e => e.currentTarget.src = downloadIcon}
            alt="download"
          />
        </a>
      </div>
    </div>
  );
}

export default PdfDownloader;