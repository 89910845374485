import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();

const contentClicked = (tag, container, category, label, contentTitle) => {
    analytics.fireOnClick({
      tag,
      container,
      category,
      label,
      contentTitle
    });
};

export const handleAClick = (clickAnalytics, contentHeader, tabText) => {
    clickAnalytics && contentClicked(
        clickAnalytics.tagId,
        clickAnalytics.container,
        contentHeader,
        tabText,
        tabText
    )
}

export const handleButtonClick = (clickAnalytics, contentHeader) => {
    clickAnalytics && contentClicked(
        clickAnalytics.tagId,
        clickAnalytics.container,
        contentHeader,
        "See More",
        "See More"
    )
}