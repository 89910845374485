import { useEffect, useState, useContext } from "react"
import '../../../styles/css/towingPayload-trimCard.css';
import * as analytics from '../towingPayload-analytics';
import { InfoPopUp } from '../towingPayload-infoPopUp';
import { PopupTooltipContext } from '../../../components/contexts/popupTooltipContext';
import { TooltipV2 } from '../../../components/molecules/tooltipV2';
import { valueEntry, iIconClick, popupOpenClick, popupCloseClick, popupMoreClick } from '../towingPayload-analytics';

export const TrimCard = (props) => {
    const { visiblePopupTooltip, setVisiblePopupTooltip, setCurrentPopupTooltip, checkCurrentTooltipMatch } = useContext(PopupTooltipContext);
    const [validData, setValidData] = useState(true);
    const [buttonClass, setButtonClass] = useState();

    useEffect(() => {
        if(isTrimDataValid(props.item)){
            setButtonClass("tp-trim-calculate-button")
            setValidData(true);
        }else{
            setButtonClass("tp-trim-calculate-button-disabled")
            setValidData(false);
        }
    }, [props.item]);

    const getInfoPopup = (content, item) => (
        <TooltipV2
            closeTooltip={()=>setVisiblePopupTooltip({modelCode: null, term: null})}
            title={content.title}
            subtitle={content.subtitle}
            description={content.definition}
            analyticsOpenTooltip={()=>popupOpenClick(props.clickAnalytics, props.vehicleSelection.modelYear, props.vehicleSelection.modelName, item, content.title)}
            analyticsCloseTooltip={()=>popupCloseClick(props.clickAnalytics, props.vehicleSelection.modelYear, props.vehicleSelection.modelName, item, content.title)}
            analyticsMoreClick={()=>popupMoreClick(props.clickAnalytics, props.vehicleSelection.modelYear, props.vehicleSelection.modelName, item, content.title, "MORE")}
        />
    );

    const termClick = (term, item) => {
        popUpVisible(term, item.modelCode) ? setVisiblePopupTooltip({modelCode: null, term: null}) : setVisiblePopupTooltip({modelCode: item.modelCode, term: term});
        props.clickAnalytics && analytics.cardTermClick(props.clickAnalytics, props.vehicleSelection.modelYear, props.vehicleSelection.modelName, item, props.infoPopUpData[term].title);
    };

    const popUpVisible = (term, modelCode) => (
        (visiblePopupTooltip && (visiblePopupTooltip.term == term && visiblePopupTooltip.modelCode == modelCode)) ? true : false
    );

    const isValidNumber = (value) => {
        if (value && isNaN(value) && value.includes(",")) {
            value = value.replace(/,/g, '');
        }
        return !isNaN(value);
    }

    const isTrimDataValid = (item) => {
        return (
            isValidNumber(trimTowing(item.towingCapacity)) &&
            isValidNumber(item.payloadCapacity) &&
            isValidNumber(item.curbWeight) &&
            isValidNumber(item.GVWR) &&
            isValidNumber(item.GCWR)
        );
    }

    const trimTowing = (value) => {
        if (isNaN(value) && value.includes("/")) {
            return value.split("/")[1].trim();
        }
        return value;
    };

    const button = (item) => (
        <button className = {buttonClass}
            onClick={() => validData && props.handleNavClick(item)}
        >
            <span className = "tp-trim-calculate-buttonText" >Begin Calculating</span>
        </button> 
    );

    const getFullName = (name) => {
        if (name.length <= 32) {
            return name
        }
        const halfway = Math.ceil(name.length / 2);
        const index = name.indexOf(' ', halfway);
        if (index === -1) {
            return name
        };
        return <>{name.slice(0, index)}<br />{name.slice(index)}</>;
    };

    const cardBodyTop = (item)=>(
        <div className = "tp-trim-cardInfoTop">
            <div>
                <div className="filterInfoRow"> 
                    <div className="filterInfoPopUp">
                        {popUpVisible("towingCapacity", item.modelCode) && getInfoPopup(props.infoPopUpData.towingCapacity, item)}
                    </div>
                    <div className = "tp-trim-cardTitle" onClick={()=>termClick("towingCapacity", item)}>Towing Capacity</div>
                </div>
                <div className="filterInfoRow"> 
                    <div className="filterInfoPopUp">
                        {popUpVisible("payloadCapacity", item.modelCode) && getInfoPopup(props.infoPopUpData.payloadCapacity, item)}
                    </div>
                    <div className = "tp-trim-cardTitle" onClick={()=>termClick("payloadCapacity", item)}>Payload Capacity</div>
                </div>
            </div>
            <div>
                <div className =  "tp-lbsText">lbs.</div>
                <div className = "tp-trim-cardText">{item.towingCapacity ? trimTowing(item.towingCapacity) : "N/A"}</div>
                <div className = "tp-trim-cardText">{item.payloadCapacity ? item.payloadCapacity : "N/A"}</div>
            </div>
        </div>
    )

    const cardBodyBottom = (item) => (
        <div className = "tp-trim-cardInfoBottom">
            <div className = "tp-trim-cardText">
                <div className="filterInfoRow">
                    <div className="filterInfoPopUp">
                        {popUpVisible("curbWeight", item.modelCode) && getInfoPopup(props.infoPopUpData.curbWeight, item)}
                    </div>
                    <div className = "tp-trim-cardTitle" onClick={()=>termClick("curbWeight", item)}>Curb Weight</div>
                </div>
                <div className="filterInfoRow">    
                    <div className="filterInfoPopUp">
                        {popUpVisible("gvwr", item.modelCode) && getInfoPopup(props.infoPopUpData.gvwr, item)}
                    </div>
                    <div className = "tp-trim-cardTitle" onClick={()=>termClick("gvwr", item)}>GVWR</div>
                </div>
                <div className="filterInfoRow">
                    <div className="filterInfoPopUp">
                        {popUpVisible("gcwr", item.modelCode) && getInfoPopup(props.infoPopUpData.gcwr, item)}
                    </div>
                    <div className = "tp-trim-cardTitle" onClick={()=>termClick("gcwr", item)}>GCWR</div>
                </div>
            </div>
            <div className = "tp-trim-cardText" >
                <div className = "tp-trim-cardText">{item.curbWeight ? item.curbWeight : "N/A"}</div>
                <div className = "tp-trim-cardText">{item.GVWR ? item.GVWR : "N/A"}</div>
                <div className = "tp-trim-cardText">{item.GCWR ? item.GCWR : "N/A"}</div>

            </div> 
        </div>
    )

    return (
        <div className = "tp-trim-card" key={props.index}>
            <div className = "tp-trim-card-trim">{props.item.trimName}</div>
            <div className = "tp-trim-card-model">{props.item.modelCode}</div>
            <div className = "tp-trim-card-fullName">{getFullName(props.item.fullName)}</div>
            <img className="tp-trim-card-image" src={props.vehicleImage}/>
            {cardBodyTop(props.item)}
            <div className = "tp-HLine"></div>
            {cardBodyBottom(props.item)}
            {button(props.item)}
        </div>
    )
}