import React from 'react';
import applyMarkdown from '../utils/applyMarkdown';
import { translateText } from '../utils/translateText.js';
import '../../styles/css/general-misc-component.css';
import MobileBackButton from "../../components/molecules/MobileBackButton";

export function MiscTemplate(component) {
  const header = (
        <div className="misc-header">
            <div className="header-title">
                {applyMarkdown(component.state.header.title, '')}
            </div>
            <hr></hr>
            <div className="header-description">
                {applyMarkdown(component.state.header.description, '')}
            </div>
        </div>
  );

  const backToTop = (
        <a className="back-to-top" href="termsConditions#top">{translateText('global.backToTop', null)}</a>
  );

  const pageItems = component.state.pageItems ? component.state.pageItems.map((item, index) => (
            <div className="page-item" key={index} id={item.tableOfContentsText}>
                <div className="page-item-title">{index + 1}. {applyMarkdown(item.title, '')}</div>
                <div className="page-item-description" style={{ height: 'auto' }} >{applyMarkdown(item.description)}</div>
                {item.appendBackToTop ? backToTop : null}
            </div>
  )) : null;

  const pageItemsList = (
        <div className="page-items-list">
            {pageItems}
        </div>
  );

  const tableOfContentsItems = component.state.pageItems ? component.state.pageItems.map((item, index) => (
            <li className={'table-of-contents-item'}>
                <a href={`termsConditions#${item.tableOfContentsText}`}>{applyMarkdown(item.tableOfContentsText)}</a>
            </li>
  )) : null;

  const tableOfContents = (
        <ol className="table-of-contents">
            {tableOfContentsItems}
        </ol>
  );

  return (
        <div className="misc-component" id="top">
            {
               window.ReactNativeWebView &&
                <MobileBackButton style={{ top: '38px' }}/>
            }
            {header}
            {tableOfContents}
            {pageItemsList}
        </div>
  );
}
