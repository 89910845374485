import en from '../../locales/en.json';
import es from '../../locales/es.json';
import { getLocale } from '../../services/languageTranslationService.js';

export function translateText(label, locale) {
  if (!locale) {
    locale = getLocale();
  }

  switch (locale) {
    case 'en':
      return en[label];
    case 'es':
      return es[label];
    default:
      return en[label];
  }
};