import * as _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import RedLine from '../../components/atoms/RedLine';
import GlossarySearchBar from './glossarySearchBar.component.js';
import { GlossaryAccordion } from './glossaryAccordion.js';
import getGlossary from '../../services/glossaryService';
import AppSettings from '../../appsettings.js';
import history from '../app/history';
import SearchFeedback from '../../components/molecules/search-feedback';
import { glossaryResultsPageLoaded, glossaryNoResultsPageLoaded, headerLinkClick, resultsLinkClick, accordionLinkClick, glossaryContactFormSubmit } from './glossary.analytics';
import '../../styles/css/glossarySearch.css';
import MobileBackButton from '../../components/molecules/MobileBackButton';
import { postMessageToApp } from '../utils/hybridAppHelpers';

const Glossary = (props) => {
    const location = useLocation().pathname;
    const filePath = AppSettings.AWSImgRepo.glossary;
    const searchParams = useLocation().search;
    const urlSearchParams = queryString.parse(searchParams);
    const categoryNames = props.categories.map(item => item.title);
    const categoryLinks = props.categories.map(item => item.link);
    const clickAnalytics = props.analytics?.clickAnalytics || {};

    const [glossaryTerms, setGlossaryTerms] = useState([]);
    const [filteredTerms, setFilteredTerms] = useState([]);

    const [activeLetter, setActiveLetter] = useState('');
    const [searchText, setSearchText] = useState(urlSearchParams?.search);
    const [activeTerm, setActiveTerm] = useState(urlSearchParams?.term);

    const [device, setDevice] = useState('desktop');
    const [tabletPosition, setTabletPosition] = useState(1);
    const [mobilePosition, setMobilePosition] = useState(1);

    const alphabetUp = ("#ABCDEFGHIJKLMNOPQRSTUVWXYZ").split("");
    const mobileAlphabetGroups = { 1: "#ABCDEF", 2: "GHIJKLM", 3: "NOPQRST", 4: "UVWXYZ" };
    const tabletAlphabetGroups = { 1: "#ABCDEFGHIJKLM", 2: "NOPQRSTUVWXYZ" };
    const [tabletAlphabet, setTabletAlphabet] = useState(tabletAlphabetGroups[1].split(""));
    const [mobileAlphabet, setMobileAlphabet] = useState(mobileAlphabetGroups[1].split(""));

    useEffect(() => {
        updateDevice();
        populateGlossary();
        window.addEventListener("resize", updateDevice);
        return () =>
            window.removeEventListener("resize", updateDevice);
    }, []);

    useEffect(() => filterGlossary(), [glossaryTerms, activeLetter, searchText]);

    useEffect(() => {
        if (props.analytics && filteredTerms.length === 0) {
            glossaryResultsPageLoaded(props.analytics.pageLoad.results, props.analytics.pageLoad.noResults);
        } else if (props.analytics) {
            glossaryResultsPageLoaded(props.analytics.pageLoad.results, reformat(props.category));
        }
    }, [props.analytics]);

    const sendSubmitAnalytics = (error) => {
        const key = !error ? 'submit': 'submitError';
        const finalPayload = clickAnalytics[key];
        glossaryContactFormSubmit({
            tagId: clickAnalytics.tagId,
            ...finalPayload
        }, props.category)
    }

    function updateDevice() {
        if (window.innerWidth >= 1320) {
            setDevice('desktop');
        } else if (window.innerWidth >= 768) {
            setDevice('tablet');
        } else if (window.innerWidth < 768) {
            setDevice('mobile');
        }
    }

    function populateGlossary() {
        getGlossary()
            .then((data) => {
                const sortedTerms = data.Items?.sort((a, b) => (a.term.toLowerCase() > b.term.toLowerCase()) ? 1 : -1);
                setGlossaryTerms(sortedTerms);
            })
            .catch((error) => {
                console.log(`ERROR: failed to load glossary terms. ${JSON.stringify(error.statusText)}`);
            });
    }

    function updateSearchText(query) {
        let urlWithQuery = `/glossary/${props.category}/?search=${query}`;
        if (!query) {
            urlWithQuery = `/glossary/${props.category}`;
        }
        setSearchText(query);
        setActiveTerm(null);
        history.replace(urlWithQuery);
    }

    const reformat = (link, display) => {
        if (props.category === "electrified" && !display) {
            return "Electrified"
        }
        const index = categoryLinks.indexOf(`/glossary/${link}`);
        return categoryNames[index];
    }

    function isLetter(char) {
        return char.toLowerCase() !== char.toUpperCase();
    }

    function filterGlossary() {
        // Start with all glossary terms & alphabetize
        let termsToShow = [...glossaryTerms];
        // Filter by category
        if (props.category && props.category !== 'all') {
            termsToShow = termsToShow.filter(item => item.category === reformat(props.category))
        }
        // Filter by search text
        if (searchText) {
            termsToShow = termsToShow.filter(item => (item.term.toLowerCase().includes(searchText.toLowerCase()) ||
                                                      item.description.toLowerCase().includes(searchText.toLowerCase())) );
        }
        // Filter by active letter (unless we're aleady filtering by search text)
        if (!searchText && activeLetter) {
            if (activeLetter === '#') {
                termsToShow = termsToShow.filter(item => !isLetter(item.term.charAt(0)));
            } else {
                termsToShow = termsToShow.filter(item => item.term.charAt(0).toUpperCase() === activeLetter);
            }
        }

        if (props.analytics && searchText && termsToShow.length === 0) {
            glossaryNoResultsPageLoaded(props.analytics.pageLoad.results, props.analytics.pageLoad.noResults.page, searchText);
        }

        setFilteredTerms(termsToShow);
    }

    function changeStyle(letter) {
        if (activeLetter != letter) {
            setActiveLetter(letter);
            props.analytics && headerLinkClick(clickAnalytics, reformat(props.category), clickAnalytics.alphabetNav.positionTitle, letter + " " + clickAnalytics.alphabetNav.label);
        } else {
            setActiveLetter('');
        }
        setActiveTerm(null);
    };

    function alphabetScroll(direction) {
        switch (direction) {
            case "tabletRight":
                if (tabletPosition == 1) {
                    setTabletAlphabet(tabletAlphabetGroups[2].split(""));
                    setTabletPosition(2);
                }; return;
            case "tabletLeft":
                if (tabletPosition == 2) {
                    setTabletAlphabet(tabletAlphabetGroups[1].split(""));
                    setTabletPosition(1);
                }; return;
            case "mobileRight":
                if (mobilePosition != 4) {
                    const newPosition = mobilePosition + 1;
                    setMobilePosition(newPosition);
                    setMobileAlphabet(mobileAlphabetGroups[newPosition].split(""));
                }; return;
            case "mobileLeft":
                if (mobilePosition != 1) {
                    const newPosition = mobilePosition - 1;
                    setMobilePosition(newPosition);
                    setMobileAlphabet(mobileAlphabetGroups[newPosition].split(""));
                } return;
            default:
                return;
        }
    }

    function getAlphabetNav() {
        const onCategoryPage = !location.endsWith('/all') && !location.endsWith('/all/');
        if (onCategoryPage || searchText) {
            return <></>;
        }

        switch (device) {
            case "desktop":
                return (
                    <nav className='AlphabetNav'>
                        {alphabetUp.map((letter) => (
                            <a key={letter} name={letter + 'Link'}
                                onClick={() => changeStyle(letter)}
                                className={activeLetter === `${letter}` ? "active" : "letterLink"}>
                                {letter}</a>
                        ))}
                    </nav>
                );
            case "tablet":
                return (<>
                    <nav className='AlphabetNav'>
                        <a onClick={() => alphabetScroll('tabletLeft')}><img src={filePath + 'Left_Arrow.png'} /></a>
                        {tabletAlphabet.map((letter) => (
                            <a key={letter} name={letter + 'Link'}
                                onClick={() => changeStyle(letter)}
                                className={activeLetter === `${letter}` ? "active" : "letterLink"}>
                                {letter}</a>
                        ))}
                        <a onClick={() => alphabetScroll('tabletRight')}><img src={filePath + 'Right_Arrow.png'} /></a>
                    </nav>

                </>);
            case "mobile":
                return (
                    <nav className='AlphabetNav'>
                        <a onClick={() => alphabetScroll('mobileLeft')}><img src={filePath + 'Left_Arrow.png'} /></a>
                        {mobileAlphabet.map((letter) => (
                            <a key={letter} name={letter + 'Link'}
                                onClick={() => changeStyle(letter)}
                                className={activeLetter === `${letter}` ? "active" : "letterLink"}>
                                {letter}</a>
                        ))}
                        <a onClick={() => alphabetScroll('mobileRight')}><img src={filePath + 'Right_Arrow.png'} /></a>
                    </nav>
                );
            default:
                return;
        }
    }

    const searchAllMessage = () => {
        const onAllTerms = props.category === 'all';

        let searchTextMessage = '';
        if (searchText) {
            searchTextMessage = ` for “${searchText.toLowerCase()}”`
        } else if (activeLetter) {
            searchTextMessage = ` for “${activeLetter}”`
        }

        // If searching in a category, "Show All Terms" links to search results for that term in the full glossary
        if (searchText && !onAllTerms) {
            return (
                <div className="glossary-cta-message">
                    <a href={`/glossary/all/?search=${searchText}`}>{`Search all terms${searchTextMessage}`}</a>
                </div>
            );
        }

        // If viewing all terms in a category, "Show All Terms" links to the full glossary
        if (!searchText && !onAllTerms) {
            return (
                <div className="glossary-cta-message">
                    <a href='/glossary/all'>{`Show All Terms`}</a>
                </div>
            );
        }

        // If searching all terms, "Show All Terms" clears the search text
        if (searchText && onAllTerms) {
            return (
                <div onClick={() => setSearchText('')} className="glossary-cta-message">
                    Show All Terms
                </div>
            );
        }

        // If filtering by a letter, "Show All Terms" clears the alpha filter
        if (!searchText && activeLetter && onAllTerms) {
            return (
                <div onClick={() => setActiveLetter('')} className="glossary-cta-message">
                    Show All Terms
                </div>
            );
        }

        return <div className="glossary-cta-message"></div>;
    }

    const accordionTitle = () => {
        if (searchText) {
            if (props.category && props.category !== 'all') {
                return `Results for “**${searchText}**” in ${reformat(props.category, true)}`
            }
            return `Results for “**${searchText}**”`;
        } else if (activeLetter) {
            return ` **All Terms (${activeLetter})** `;
        }
        return ` **${reformat(props.category, true)}** `;
    }

    const backButtonAnalytics = () => {

        if (props.analytics && searchText && filteredTerms.length > 0) {
            accordionLinkClick(clickAnalytics, clickAnalytics.searchBar.resultsLabel, clickAnalytics.backLink.label, searchText);
        } else if (props.analytics && filteredTerms.length === 0) {
            resultsLinkClick(clickAnalytics, clickAnalytics.noResults.category, clickAnalytics.backLink.label, searchText);
        } else if (props.analytics) {
            resultsLinkClick(clickAnalytics, reformat(props.category), clickAnalytics.backLink.label, null);
        }

    }

    const renderBackButton = () => {
        if (window.ReactNativeWebView) {
            return (
                <MobileBackButton onClick={() => {
                    backButtonAnalytics();
                    postMessageToApp("Go Back")
                }} style={{
                    top: '50px',
                    width: "14px",
                    height: '14px'
                }} />
            );
        }
        return <a href={"/glossary"} onClick={() => backButtonAnalytics()}><img src={filePath + 'red_left_arrow.png'} />Back</a>;
    }

    return (<>
        <div className="glossaryBackButton">
            {renderBackButton()}
        </div>
        <div className="glossary-container">
            <div className={`glossary-title ${window.ReactNativeWebView && "increase-padding-title"}`}>
                Glossary
            </div>

            <RedLine style={{ margin: 'auto', width: '57px', bottomBorder: '4px' }} />
            {location.endsWith('/all') ? getAlphabetNav() : null}
            <div className="glossarySearchBar">
                <GlossarySearchBar imagePath={filePath} setSearchText={updateSearchText} category={reformat(props.category, true)} analytics={props.analytics} />
            </div>
            <GlossaryAccordion
                terms={filteredTerms}
                title={accordionTitle()}
                category={reformat(props.category)}
                analytics={props.analytics}
                search={searchText}
                activeTerm={activeTerm}
            />
            {filteredTerms.length > 0 ? <></> : <>
                <div className="glossary-search-message">
                    No terms found.
                </div>
            </>}
            {searchAllMessage()}
            <SearchFeedback
                location={`Glossary`}
                category={props.category}
                filter={activeLetter}
                searchParam={searchText}
                onSubmitAnalytics={() => sendSubmitAnalytics()}
                onSubmitErrorAnalytics={() => sendSubmitAnalytics(true)}
            />
        </div>
    </>);
};

export default Glossary;