/* eslint-disable linebreak-style */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import '../../styles/css/404NotFound.css';
import { GlobalAnalytics } from '../../analytics';
import MobileBackButton from '../molecules/MobileBackButton';

const analytics = new GlobalAnalytics();

const sharedAnalyticAttributes = {
  page: 'Page Does Not Exist',
  section: 'Error',
  subsection: '404',
  contentTitle: 'Got a question? Ask in the comments!',
};

const onPageLoad = () => {
  analytics.firePageLoad({
    tag: '28.1',
    ...sharedAnalyticAttributes,
  });
};

const onClickLink = () => {
  analytics.fireOnClick({
    category: 'Error Message',
    container: sharedAnalyticAttributes.section,
    label: 'Article Link',
    mediaTitle: sharedAnalyticAttributes.contentTitle,
    module: sharedAnalyticAttributes.subsection,
    tag: '28.3',
    ...sharedAnalyticAttributes,
  });
};

export const Display404NotFound = (props) => {
  useEffect(() => {
    onPageLoad();
  });
  return (
    <div>
      {
        window.ReactNativeWebView &&
        <MobileBackButton containerStyle={{
          backgroundColor:"white",
          padding: "12px",
          position: 'absolute',
          borderRadius: '50%',
          top: '80px',
          left: '32px',
          display: 'flex',
          placeContent:'center',
          width: '12px',
          height: '12px'
        }} style={{top: '4px', left:'7px'}}/>
      }
      <div className="noResponse"
        style={{ display: props.display ? 'block' : 'none' }}>
        {props.messageId ? <FormattedMessage id={props.messageId} />
          : <div>This page appears to have driven away.&nbsp;
          <a target='_blank'
              href='https://engage.toyota.com/articles/4_27_2020_got_a_question_ask_in_the_comments'
              onClick={() => onClickLink()}
            >Got a question? Ask in the comments!</a>
          </div>}
      </div>
    </div>
  );
};

export default Display404NotFound;

Display404NotFound.propTypes = {
  messageId: PropTypes.string,
  display: PropTypes.bool,
};
