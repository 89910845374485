import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/css/select.css';

export default function Select(props) {
  const options = props.options ? props.options.map((dropdownOption) => (
    <option key={dropdownOption} value={dropdownOption}>{dropdownOption}</option>
  )) : null;

  const getClassName = () => {
    let className = "select";
    if (props.customStyle) {
      className += ` ${props.customStyle}`;
    }
    if (props.rounded) {
      className += " rounded";
    }
    return className;
  };

  return (
    <div className={props.divStyle ? `${props.divStyle}` : ''}>
      {window.innerWidth < 640 ? <meta name="viewport" content="width=device-width, minimum-scale=1.0, maximum-scale=1.0" /> : null}
      <select name={props.name ? props.name : ''}
        value={props.value}
        onChange={props.onChange}
        className={getClassName()}
        disabled={props.disabled}>
        {props.defaultOption ? <option value={props.defaultOption}>{props.defaultOption}</option> : null}
        {props.customOptions ? props.customOptions : options}
      </select>
    </div>

  );
}

Select.propTypes = {
  options: PropTypes.array,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  customStyle: PropTypes.string,
  disabled: PropTypes.bool,
  defaultOption: PropTypes.string,
  customOptions: PropTypes.array,
};
