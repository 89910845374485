import React from 'react';

const ProfileField = ({ label, value }) => {

  return (
    <div className="profile-field-container">
      <div className='profile-field-top'>
        <label className='profile-field-label'>{label}</label>
      </div>
      <div className='profile-field-item'>{value !== undefined ? value : 'N/A'}</div>
    </div>
  );
};

export default ProfileField;
