import queryString from "query-string";
import { Component } from "react";
import AppSettings from "../../appsettings.js";
import { getLocale } from "../../services/languageTranslationService.js";
import { postTcopAction } from "../../services/nitroService.js";
import { getTrimOptions } from "../../services/trimOptionsService.js";
import { getTrims } from "../../services/trimsService.js";
import "../../styles/css/TCOP.css";
import { getS3PathName } from "../utils/vehicleName.js";
import { TCOPTemplate } from "./TCOP.tpl";

const analyticsFunctions = require("./TCOP.analytics.js");
const userHistory = require("../../services/userActivityService.js");

export default class TCOPComponent extends Component {
  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    const queryParamTrim = query && query.trim;
    const queryParamColor = query && query.color;
    const scrollToColor = queryParamTrim && queryParamColor;
    const scrollToPackage = !scrollToColor && queryParamTrim;
    this.state = {
      trims: [],
      allTrims: [],
      selectedTrim: {
        availableColors: {},
        availableOptions: [],
        availablePackages: [],
      },
      s3Path: "",
      selectedExtColor: {},
      extColorImgFilename: "",
      isInMobileView:
        window.innerWidth < AppSettings.ScreenWidthBreakpoints.small,
      activeTrim: "",
      languageOverride: false,
      displayOverrideText: false,
      queryParamTrim,
      queryParamColor,
      scrollToColor,
      scrollToPackage,
      readyForScroll: false,
      imagesRendered: {
        carousel: false,
        color: false,
        options: false,
        packages: false,
      },
    };

    this.extColors = [];
    this.intColors = {};
    this.factoryOptions = {};
    this.packages = {};

    this.handleTrimChange = this.handleTrimChange.bind(this);
    this.handleClickAnalytics = this.handleClickAnalytics.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.checkWindowDimensions = this.checkWindowDimensions.bind(this);
    this.checkBoxClicked = this.checkBoxClicked.bind(this);
    this.carouselMoved = this.carouselMoved.bind(this);
    this.switchTrim = this.switchTrim.bind(this);
    this.checkLocaleAgainstLanguageOverride =
      this.checkLocaleAgainstLanguageOverride.bind(this);
    this.getS3Path = this.getS3Path.bind(this);

    // Bind Scope of 'this' for analytics functions
    this.sendPageLoadAnalytics =
      analyticsFunctions.sendPageLoadAnalytics.bind(this);
    this.sendClickAnalytics = analyticsFunctions.sendClickAnalytics.bind(this);
  }

  handleClickAnalytics(container, category, label) {
    this.sendClickAnalytics(container, category, label);
    postTcopAction(category, label);
  }

  handleImageLoad(image) {
    const imagesRendered = { ...this.state.imagesRendered, [image]: true };
    if (
      JSON.stringify(imagesRendered) !==
      JSON.stringify(this.state.imagesRendered)
    ) {
      this.setState({
        imagesRendered,
      });
    }
  }

  checkIfEmptyOrRendered(param) {
    let imageRendered;
    let available;

    if (param === "packages") {
      imageRendered = this.state.imagesRendered.packages;
      available = this.state.selectedTrim.availablePackages;
    } else if (param === "options") {
      imageRendered = this.state.imagesRendered.options;
      available = this.state.selectedTrim.availableOptions;
    } else {
      return false;
    }

    return (
      imageRendered ||
      (typeof this.state.selectedTrim.colorsAvailable !== "undefined" &&
        available.length === 0)
    );
  }

  switchTrim(trim) {
    this.setState(prevState => ({
      activeTrim: prevState.trims[trim].name,
    }));
  }

  loadTrims() {
    return new Promise((resolve, reject) => {
      getTrims(
        this.props.route.model,
        this.props.params.year,
        this.state.languageOverride ? "en" : undefined
      )
        .then((payload) => {
          resolve(payload);
          this.setState({
            activeTrim: payload.trims[0].name,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  loadTrimOptions() {
    return new Promise((resolve, reject) => {
      getTrimOptions(
        this.props.route.model,
        this.props.params.year,
        this.state.languageOverride ? "en" : undefined
      )
        .then((payload) => {
          resolve(payload);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  componentWillMount() {
    this.getPageData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.languageOverride && !prevState.languageOverride) {
      this.getPageData();
    } else if (this.state.displayOverrideText && getLocale() === "en") {
      this.setState({ displayOverrideText: false });
    }

    const packagesEmptyOrRendered = this.checkIfEmptyOrRendered("packages");
    const optionsEmptyOrRendered = this.checkIfEmptyOrRendered("options");

    if (
      this.state.imagesRendered.carousel &&
      this.state.imagesRendered.color &&
      optionsEmptyOrRendered &&
      packagesEmptyOrRendered &&
      !this.state.readyForScroll
    ) {
      setTimeout(() => {
        this.setState({
          readyForScroll: true,
        });
      }, 500);
    }
  }

  componentDidMount() {
    this.sendPageLoadAnalytics();
    this.getS3Path();
    userHistory.postUserActivityRecord();
  }

  checkLocaleAgainstLanguageOverride() {
    if (getLocale() === "es" && !this.state.languageOverride) {
      return "es";
    }
    return "en";
  }

  async getS3Path() {
    const localeWithOverride = this.checkLocaleAgainstLanguageOverride();
    const s3PathName = await getS3PathName(this.props.route.model);
    const year = this.props.params.year;
    this.setState({
      s3Path: `${year}/${s3PathName}/${localeWithOverride}/`,
    });
  }

  getPageData() {
    Promise.all([this.loadTrims(), this.loadTrimOptions()])
      .then((values) => {
        if (
          Object.keys(values[0]).length === 0 &&
          values[0].constructor === Object &&
          Object.keys(values[1]).length === 0 &&
          values[1].constructor === Object
        ) {
          if (!this.state.languageOverride) {
            this.setState({
              languageOverride: true,
              displayOverrideText: true,
            });
          }
        } else {
          this.intColors = values[1].trimOptions.interiorColors;
          this.factoryOptions = values[1].trimOptions.factoryOptions;
          this.packages = values[1].trimOptions.packages;
          this.setState({
            allTrims: JSON.parse(JSON.stringify(values[0].trims)),
          });
          const uniqueTrims = [];
          values[0].trims.forEach((e) => {
            if (!e.oneToMany) {
              uniqueTrims.push(e);
            } else {
              const uniqueTrimIndex = uniqueTrims.length - 1;
              if (e.name === uniqueTrims[uniqueTrimIndex].name) {
                if (e.msrp < uniqueTrims[uniqueTrimIndex].msrp) {
                  uniqueTrims[uniqueTrimIndex].msrp = e.msrp;
                }
              }
            }
          });
          values[0].trims.forEach((e) => {
            // TODO: replace this with real values for msrp
            e.checked = true;
            e.modelCode = parseInt(e.modelCode, 10);
            e.availableOptions = [];
            e.availablePackages = [];
            e.trimImageFilename = e.trimImageFilename
              ? e.trimImageFilename.trim()
              : "";
            e.colorsAvailable.forEach((color) => {
              const img = new Image();
              img.src =
                AppSettings.AWSImgRepo.vehicle +
                this.state.s3Path +
                color.imageFilename;
              // Build extColors object since it no longer comes from trimOptions
              if (
                !this.extColors.find((x) => x.paintCode === color.paintCode)
              ) {
                this.extColors.push({
                  paintCode: color.paintCode,
                  paintCodeName: color.paintCodeName,
                  hexCode: color.hexCode || undefined,
                });
              }
            });
          });
          this.factoryOptions.forEach((e) => {
            e.availability.forEach((mc) => {
              values[0].trims.forEach((trim) => {
                if (mc === trim.modelCode) {
                  trim.availableOptions.push(e);
                }
              });
            });
          });

          this.packages.forEach((e) => {
            e.availability.forEach((mc) => {
              values[0].trims.forEach((trim) => {
                if (mc === trim.modelCode) {
                  trim.availablePackages.push(e);
                }
              });
            });
          });

          // Add availableOptions, availablePackages, and colorsAvailable to uniqueTrims
          values[0].trims.forEach((trim) => {
            if (trim.oneToMany) {
              uniqueTrims.forEach((uniqueTrim) => {
                if (trim.name === uniqueTrim.name) {
                  // availableOptions
                  trim.availableOptions.forEach((trimOption) => {
                    let unique = true;
                    uniqueTrim.availableOptions.forEach((uniqueOption) => {
                      if (
                        uniqueOption.code === trimOption.code &&
                        uniqueOption.msrp === trimOption.msrp &&
                        uniqueOption.title === trimOption.title
                      ) {
                        unique = false;
                      }
                    });
                    if (unique) {
                      uniqueTrim.availableOptions.push(trimOption);
                    }
                  });
                  // availablePackages
                  trim.availablePackages.forEach((trimPackage) => {
                    let unique = true;
                    uniqueTrim.availablePackages.forEach((uniquePackage) => {
                      if (
                        uniquePackage.code === trimPackage.code &&
                        uniquePackage.msrp === trimPackage.msrp &&
                        uniquePackage.title === trimPackage.title
                      ) {
                        unique = false;
                      }
                    });
                    if (unique) {
                      uniqueTrim.availablePackages.push(trimPackage);
                    }
                  });
                  // colorsAvailable
                  trim.colorsAvailable.forEach((trimColor) => {
                    let unique = true;
                    uniqueTrim.colorsAvailable.forEach((uniqueColor) => {
                      if (uniqueColor.paintCode === trimColor.paintCode) {
                        unique = false;
                      }
                    });
                    if (unique) {
                      uniqueTrim.colorsAvailable.push(trimColor);
                    }
                  });
                }
              });
            }
          });

          // Tort Trims options Package
          for (const i in uniqueTrims) {
            uniqueTrims[i] = this.getSortedOptionsPackages(uniqueTrims[i]);
          }

          let selectedTrim = this.getTrimByFullName(
            uniqueTrims,
            this.state.queryParamTrim
          );
          if (!selectedTrim) {
            selectedTrim = uniqueTrims[0];
          }

          const searchSelectedExtColor = selectedTrim.colorsAvailable.find(
            (color) => color.paintCodeName === this.state.queryParamColor
          );
          const startingExtColor =
            searchSelectedExtColor ||
            this.selectedExtColor(selectedTrim.colorsAvailable);

          this.setState({
            trims: uniqueTrims,
            selectedTrim,
            selectedExtColor: startingExtColor,
            extColorImgFilename:
              this.props.params.year > 2017
                ? AppSettings.AWSImgRepo.vehicle +
                  this.state.s3Path +
                  startingExtColor.imageFilename
                : AppSettings.AWSImgRepo.static +
                  startingExtColor.imageFilename,
          });
        }
        this.getS3Path();
      })
      .catch((error) => {
        reject(error);
      });

    window.addEventListener("resize", this.checkWindowDimensions);
  }

  selectedExtColor(exteriorColors) {
    const colorObject = {};
    for (const i in exteriorColors) {
      const colorCode = exteriorColors[i];
      colorObject[colorCode.paintCodeName] = colorCode;
    }
    const sortedColorObject = Object.keys(colorObject).sort((color1, color2) =>
      colorObject[color1].paintCodeName.localeCompare(
        colorObject[color2].paintCodeName
      )
    );
    return colorObject[sortedColorObject[0]];
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkWindowDimensions);
  }

  checkWindowDimensions() {
    if (window.innerWidth < AppSettings.ScreenWidthBreakpoints.small) {
      this.selectAll();
      this.setState({
        isInMobileView: true,
      });
    } else {
      this.setState({
        isInMobileView: false,
      });
    }
  }

  isLastCheckedTrim(index) {
    for (let i = index + 1; i < this.state.trims.length; i++) {
      if (this.state.trims[i].checked) return false;
    }
    return true;
  }

  getNextCheckedTrimName(index) {
    for (let i = index + 1; i < this.state.trims.length; i++) {
      if (this.state.trims[i].checked) return this.state.trims[i].name;
    }
  }

  getMsrpDiff(index) {
    const myPrice = this.state.trims[index].msrp;
    for (let i = index + 1; i < this.state.trims.length; i++) {
      if (this.state.trims[i].checked) {
        const diff = this.state.trims[i].msrp - myPrice;
        if (diff >= 0) {
          return `+$${diff.toLocaleString()}`;
        }
        return `-$${Math.abs(diff).toLocaleString()}`;
      }
    }
  }

  deselectAll() {
    this.state.trims.forEach((e) => {
      e.checked = false;
    });

    this.handleClickAnalytics("Module", "Compare Trim Filters", "Deselect All");

    this.setState(prevState => ({
      trims: prevState.trims,
    }));
  }

  selectAll(shouldRecordAnalytics, event) {
    if (shouldRecordAnalytics) {
      this.handleClickAnalytics("Module", "Compare Trim Filters", "Select All");
    }

    this.state.trims.forEach((e) => {
      e.checked = true;
    });

    this.setState(prevState => ({
      trims: prevState.trims,
    }));
  }

  boxChanged(name, index) {
    const trims = this.state.trims.slice();
    trims[index].checked = !trims[index].checked;
    this.setState({
      trims,
    });
  }

  checkBoxClicked(trimName, event) {
    this.handleClickAnalytics("Module", "Compare Trim Filters", trimName);
  }

  // Not being called
  carouselMoved() {
    this.handleClickAnalytics(
      "Module",
      "Trims pagination",
      "Toyota.com, Toyota Safety, etc."
    );
  }

  handleTrimChange(index) {
    this.handleClickAnalytics(
      "Product Nav",
      "Trims Nav Link",
      this.state.trims[index].name
    );

    const startingExtColor = this.selectedExtColor(
      this.state.trims[index].colorsAvailable
    );
    this.setState(prevState => ({
      selectedTrim: prevState.trims[index],
      selectedExtColor:
        prevState.trims[index].colorsAvailable.length > 0
          ? startingExtColor
          : {},
      extColorImgFilename:
        prevState.trims[index].colorsAvailable.length > 0
          ? this.createImageFilePath(
              this.props.params.year,
              startingExtColor.imageFilename
            )
          : this.createImageFilePath(
              this.props.params.year,
              prevState.trims[index].trimImageFilename
            ),
      activeTrim: prevState.trims[index].name,
    }));
  }

  createImageFilePath(year, filename) {
    let filepath = "";
    if (year > 2017) {
      filepath = AppSettings.AWSImgRepo.vehicle + this.state.s3Path + filename;
    } else {
      filepath = AppSettings.AWSImgRepo.static + filename;
    }
    return filepath;
  }

  handleColorChange(colorCode) {
    this.handleClickAnalytics("Module", "Color Swatch", colorCode);

    let index;
    for (let i = 0; i < this.state.selectedTrim.colorsAvailable.length; i++) {
      if (this.state.selectedTrim.colorsAvailable[i].paintCode === colorCode) {
        index = i;
      }
    }
    this.setState(prevState => ({
      selectedExtColor: prevState.selectedTrim.colorsAvailable[index],
      extColorImgFilename:
        prevState.selectedTrim.colorsAvailable.length > 0
          ? this.createImageFilePath(
              this.props.params.year,
              prevState.selectedTrim.colorsAvailable[index].imageFilename
            )
          : this.createImageFilePath(
              this.props.params.year,
              prevState.selectedTrim.trimImageFilename
            ),
    }));
  }

  getSortedOptionsPackages(optionsPackages) {
    optionsPackages.availablePackages = optionsPackages.availablePackages.sort(
      this.compareOptionsPackages
    );
    optionsPackages.availableOptions = optionsPackages.availableOptions.sort(
      this.compareOptionsPackages
    );
    return optionsPackages;
  }

  compareOptionsPackages(a, b) {
    if (a.code < b.code) return -1;
    if (a.code > b.code) return 1;
    return 0;
  }

  getTrimByFullName(trims, fullName) {
    let found;
    if (trims && trims.length > 0 && fullName) {
      const fullNameTrimmed =
        fullName.indexOf("(") !== -1
          ? fullName.substring(0, fullName.indexOf("(")).trim()
          : fullName.trim();
      found = trims.find((element) => element.name.trim() === fullNameTrimmed);
    }
    return found;
  }

  render() {
    return TCOPTemplate(this);
  }
}
