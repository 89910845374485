import React, { useEffect, useState, useContext } from 'react';
import '../../styles/css/towingPayload-trailerPanel.css';
import { TooltipV2 } from '../../components/molecules/tooltipV2';
import { InfoPopUp } from './towingPayload-infoPopUp';
import { InputPopUp } from './towingPayload-inputPopUp';
import { isDesktopView, isTabletView, isMobileView } from '../../screens/utils/getScreenSize.js';
import AppSettings from '../../appsettings.js';
import { calculatorButtonClick, iIconClick, moreLessClick, popupOpenClick, popupCloseClick, popupMoreClick } from './towingPayload-analytics';
import { PopupTooltipContext } from '../../components/contexts/popupTooltipContext';

export const TrailerPanel = (props) => {
    const [mobileHiddenTrailer, setMobileHiddenTrailer] = useState(true);
    const [placeholderTrailerImagePath, setPlaceholderTrailerImagePath] = useState(null);
    const [placeholderIsolatedImagePath, setPlaceholderIsolatedImagePath] = useState(null);

    const trailerImagePath = `${AppSettings.AWSImgRepo.towingPayload}${props.modelYear}_${props.modelCode}_${props.modelName.toLowerCase().split(' ').join('')}_trailer_flatbed_box.png`
    const mobileIsolatedVehicleImagePath = `${AppSettings.AWSImgRepo.towingPayload}${props.modelYear}_${props.modelCode}_${props.modelName.toLowerCase().split(' ').join('')}_isolated.png`
    const mobileIsolatedTrailerImagePath = `${AppSettings.AWSImgRepo.towingPayload}_trailer_isolated_mobile.png`

    const plusIconPath = `${props.imagePath}plusIcon.png`;
    const checkIconPath = `${props.imagePath}checkIcon.png`;
    const infoIconPath = `${props.imagePath}infoIcon.png`;

    const { visiblePopupTooltip, setVisiblePopupTooltip, setCurrentPopupTooltip, checkCurrentTooltipMatch } = useContext(PopupTooltipContext);

    useEffect(() => {
        if (isMobileView(window.innerWidth) && props.mode == "beginner"){
            // if mobile and beginner views
            if(visiblePopupTooltip && (props.iconsToDisplay.payload.includes(visiblePopupTooltip) || props.iconsToDisplay.towing.includes(visiblePopupTooltip))){
                // if pop up belongs to trailer
                setMobileHiddenTrailer(true)
            } else if (visiblePopupTooltip == null) {
                setMobileHiddenTrailer(false)
            }
        }
      },[visiblePopupTooltip]);

    useEffect(() => {
        // props.setVisiblePopUp(null);
        setVisiblePopupTooltip(null);
        if(isMobileView(window.innerWidth)){
            if(props.mode=="expert"){
                setMobileHiddenTrailer(true);
            } else if (props.mode=="beginner"){
                setMobileHiddenTrailer(false);
            }
        } else {
            setMobileHiddenTrailer(false);
        }

    },[isMobileView(window.innerWidth),props.mode]);

    useEffect(() => {
        setPlaceholderTrailerImagePath(null);
        setPlaceholderIsolatedImagePath(null);
      },[props.modelName, props.modelYear, props.modelCode]);

    const iconClick = (i, beginner) => {
        setCurrentPopupTooltip(i);
        props.clickAnalytics && iIconClick(props.clickAnalytics, props.vehicleSelection, props.trimSelection, props.infoPopUpData[i].title, true, beginner);
    };

    const getVehicleIconPlacementClassNames = () => {
        //allow custom placement in css based on vehicle type, model, cab, etc.
        let classNames = [];
        classNames.push(props.vehicleType);
        //remove leading number in 4runner to use in css
        classNames.push(isNaN(props.modelName[0]) ? props.modelName : props.modelName.slice(1,props.modelName.length));
        if(props.vehicleType == "truck"){
            classNames.push(props.cab);
            if(props.bedLength){
                classNames.push(`b${props.bedLength[0]}`);
            }
        }
        return classNames.join(" ").toLowerCase();
    }

    const getInfoPopup = (i) => (
        <TooltipV2
            closeTooltip={()=>setVisiblePopupTooltip(null)}
            title={props.infoPopUpData[i].title}
            subtitle={props.infoPopUpData[i].subtitle}
            description={props.infoPopUpData[i].definition}
            analyticsOpenTooltip={()=>popupOpenClick(props.clickAnalytics, props.vehicleSelection.modelYear, props.vehicleSelection.modelName, props.trimSelection, props.infoPopUpData[i].title, true)}
            analyticsCloseTooltip={()=>popupCloseClick(props.clickAnalytics, props.vehicleSelection.modelYear, props.vehicleSelection.modelName, props.trimSelection, props.infoPopUpData[i].title, true)}
            analyticsMoreClick={()=>popupMoreClick(props.clickAnalytics, props.vehicleSelection.modelYear, props.vehicleSelection.modelName, props.trimSelection, props.infoPopUpData[i].title, "MORE", true)}
        />
    );

    const getExpertTrailerOverlay = () => {
        return (
            <div className = "expertInfoIconsOverlay">
                {props.iconsToDisplay.map(i =>
                    <>
                    <div className={`trailerIcon ${i} ${getVehicleIconPlacementClassNames()}`} key={i}>
                        <div className="iconClickableArea" onClick={()=>iconClick(i, false)}>
                            <div className="expertInfoIcon"><img className="i-icon" src={infoIconPath} /></div>
                        </div>
                        {(isDesktopView(window.innerWidth) && checkCurrentTooltipMatch(i)) && getInfoPopup(i)}
                    </div>
                    {(!isDesktopView(window.innerWidth) && checkCurrentTooltipMatch(i)) && getInfoPopup(i)}
                    </>
                )}
            </div>
        );
    };

    const getInputPopup = (i, isPayload) => {
        return <InputPopUp
            closePopUp={()=>setVisiblePopupTooltip(null)}
            variable={i}
            content={props.infoPopUpData[i]}
            currentValues={isPayload ? props.payloadValues : props.towingValues} 
            setValues={isPayload ? props.setPayloadValues : props.setTowingValues}
            clickAnalytics={props.clickAnalytics}
            pageLoadAnalytics={props.pageLoadAnalytics}
            vehicleSelection={props.vehicleSelection}
            trimDetails={props.trimSelection}
        />;
    };

    const getBeginnerTrailerOverlay = () => {
        return (
            <div className = "beginnerPlusIconsOverlay">
                {/* payload icons: */}
                {props.iconsToDisplay.payload.map(i => 
                    <>
                    {!mobileHiddenTrailer && <div className={`trailerIcon ${i} ${getVehicleIconPlacementClassNames()}`} key={i}>
                        <div className="beginnerPlusIcon" onClick={()=>iconClick(i, true)}><img className="plus-icon" src={props.payloadValues[i]==0 ? plusIconPath : checkIconPath} /></div>
                        {(isDesktopView(window.innerWidth) && checkCurrentTooltipMatch(i)) && getInputPopup(i, true)}
                    </div>}
                    {(!isDesktopView(window.innerWidth) && checkCurrentTooltipMatch(i)) && getInputPopup(i, true)}
                    </>
                )}
                {/* towing icons: */}
                {props.iconsToDisplay.towing.map(i =>
                    <>
                    {!mobileHiddenTrailer && <div className={`trailerIcon ${i}`}  key={i}>
                        <div className="beginnerPlusIcon" onClick={()=>setCurrentPopupTooltip(i)}><img className="plus-icon" src={props.towingValues[i]==0 ? plusIconPath : checkIconPath} /></div>
                        {(isDesktopView(window.innerWidth) && checkCurrentTooltipMatch(i)) && getInputPopup(i, false)}
                    </div>}
                    {(!isDesktopView(window.innerWidth) && checkCurrentTooltipMatch(i)) && getInputPopup(i, false)}
                    </>                    
                )}
            </div>
        );
    };

    const getPlaceholderTrailerImage = (modelName, vehicleType) => {
        if(modelName) {
            setPlaceholderTrailerImagePath(`${AppSettings.AWSImgRepo.towingPayload}placeholder_trailer_${modelName.toLowerCase()}.png`);
        } else if (vehicleType) {
            setPlaceholderTrailerImagePath(`${AppSettings.AWSImgRepo.towingPayload}placeholder_trailer_${vehicleType == "truck" ? "tundra" : "4runner"}.png`);
        }
    }

    const getMobilePlaceholderIsolatedImage = (modelName, vehicleType) => {
        if (modelName) {
            setPlaceholderIsolatedImagePath(`${AppSettings.AWSImgRepo.towingPayload}placeholder_isolated_${modelName.toLowerCase()}.png`);
        } else if (vehicleType) {
            setPlaceholderIsolatedImagePath(`${AppSettings.AWSImgRepo.towingPayload}placeholder_isolated_${vehicleType == "truck" ? "tundra" : "4runner"}.png`);
        }
    }

    const getTrailerImage = () => {
        if (isDesktopView(window.innerWidth) || isTabletView(window.innerWidth)){
            return (
                <div className="trailerImageContainer">
                    <div className="fullTrailerImage">
                        {!placeholderTrailerImagePath ? 
                            <img alt="trailer image" src={trailerImagePath} onError={()=>getPlaceholderTrailerImage(props.modelName, null)} /> : 
                            <img alt="trailer image" src={placeholderTrailerImagePath} onError={()=>getPlaceholderTrailerImage(null, props.vehicleType)} />
                        }</div>
                </div>
            );
        } else if (isMobileView(window.innerWidth)) {
            return (
                <div className="trailerImageContainer">
                    <div className="isolatedVehicleImage">
                        {!placeholderIsolatedImagePath ? 
                            <img alt="isolated vehicle image" src={mobileIsolatedVehicleImagePath} onError={()=>getMobilePlaceholderIsolatedImage(props.modelName, null)} /> : 
                            <img alt="isolated vehicle image" src={placeholderIsolatedImagePath} onError={()=>getMobilePlaceholderIsolatedImage(null, props.vehicleType)} />
                        }</div>
                    <div className="isolatedTrailerImage"><img alt="isolated trailer image" src={mobileIsolatedTrailerImagePath} /></div>
                </div>
            );
        }

    };

    const getTrailerPanel = () => {
        if (isMobileView(window.innerWidth) && mobileHiddenTrailer) {
            if (props.mode=="beginner") {
                // if hidden on beginner mode
                // hide image and icons, not pop ups
                // show spacer div
                return (
                    <div className="trailerOverlay">
                        {getBeginnerTrailerOverlay()}
                        <div className="hiddenTrailerSpacer"></div>
                    </div>
                );
            } // if hidden on expert mode, show nothing
        } else {
            // if not mobile view or not hidden on mobile, show full panel
            return (
                <>
                    <div className="trailerOverlay">
                        {props.mode == "expert" ? getExpertTrailerOverlay() : getBeginnerTrailerOverlay()}
                    </div>
                    {getTrailerImage()}
                </>
            );
        }
    };

    const handleMoreLessClick = () => {
        props.clickAnalytics && moreLessClick(props.clickAnalytics, props.vehicleSelection, props.trimSelection, mobileHiddenTrailer);
        setMobileHiddenTrailer(!mobileHiddenTrailer);
    }

    const getSeeMoreLessButton = () => {
        // only display on mobile expert mode
        if (isMobileView(window.innerWidth) && props.mode == "expert") {
            return (
                <div className="trailerSeeMoreLessButton" onClick={()=>handleMoreLessClick()}>
                    <div className="seeMoreLessText">{mobileHiddenTrailer ? "See More" : "Close"}</div>
                    <div className="seeMoreLessIcon">{mobileHiddenTrailer ? "+" : "-"}</div>
                </div>
            );
        }
    };

    const handleBeginButtonClick = (buttonText) => {
        props.setCalculatorType("Beginner");
        props.clickAnalytics && calculatorButtonClick(props.clickAnalytics, props.vehicleSelection, props.trimSelection, buttonText);
    };

    const getBottomMessage = () => {
        if (props.mode == "expert") {
            const buttonText = "Begin";
            return (
                <div className="expertBottomMessage">
                    <div className="expertMessageText">
                        <div className="needHelp">Need Help?</div>
                        <div className="stepByStep">Calculate it step-by-step.</div>
                    </div>
                    <div className='expertMessageButton' onClick={()=>handleBeginButtonClick(buttonText)}>{buttonText}</div>
                </div>
            );
        } else if (props.mode == "beginner" && !mobileHiddenTrailer) {
            return (
                <div className="beginnerBottomMessage">
                    <div className="beginnerMessageText">
                        Select a
                        <div className="beginnerMessageIcon">
                            <img className="plus-icon" src={`${props.imagePath}plusIconSmall.png`} />
                        </div>
                        on the image above to get started. As you enter each value, the calculator will update below.
                    </div>
            </div>
            );
        }
    };

    return (
        <div className="calculatorTrailerButtonMessage">
            {getSeeMoreLessButton()}
            <div className="calculatorTrailerPanel">
                {getTrailerPanel()}
            </div>
            {getBottomMessage()}
        </div>

    );
};