import { Component } from 'react';
import { SpecDataTemplate } from './specData.tpl';

export default class SpecDataComponent extends Component {
  hasAdvantage(index) {
    return index === 0 && this.props.spec.specData[0].advantageFlag === 'True';
  }

  isBest(index) {
    if (index === 0) {
      return this.props.spec.specData[0].advantageFlag === 'True';
    }
    return false;
  }

  render() {
    return SpecDataTemplate(this);
  }
}
