import { GlobalAnalytics } from "../../../analytics";
import { postTrimWalkAction } from '../../../services/nitroService';

const analytics = new GlobalAnalytics();

const handleNitroAction = (index, year, engine, trimName, modelName, category, label) => {
  const finalLabel = engine && trimName ? `${year} ${engine} ${trimName}` : label;
  postTrimWalkAction(category || `${index === 0 ? 'Lower' : 'Higher'} Trim`, finalLabel, modelName, year, trimName)
}

export function yearSelect(event) {
  handleNitroAction(event?.index, event?.modelYear, event?.engine, event.trimName, event?.modelName)
  analytics.fireOnClick({
    tag: "29.2",
    section: "Product",
    page: event?.page,
    category : event?.category,
    label: 'Model Year Select',
    contentTitle: event?.contentTitle,
    modelName: event?.modelName,
    modelYear: event?.modelYear,
  })
}

export function engineSelect(event) {
    handleNitroAction(event?.index, event?.modelYear, event?.contentTitle, event?.trimName, event?.modelName)
    analytics.fireOnClick({
      tag: "29.2",
      section: "Product",
      page: event?.page,
      category : event?.category,
      label: 'Engine Select',
      contentTitle: event?.contentTitle,
      modelName: event?.modelName,
      modelYear: event?.modelYear,
    })
  }

  export function trimSelect(event) {
    handleNitroAction(event?.index, event?.modelYear, event?.contentTitle, event?.trimName, event?.modelName)
    analytics.fireOnClick({
      tag: "29.2",
      section: "Product",
      page: event?.page,
      category : event?.category,
      label: 'Trim Select',
      contentTitle: event?.contentTitle,
      modelName: event?.modelName,
      modelYear: event?.modelYear,
    })
  }
  

export function accordionClick(event) {
  analytics.fireOnClick({
    tag: "29.2",
    section: "Product",
    page: event?.page,
    category : 'Trim Results',
    label: `Result ${event.isExpand ? "Expand" : "Collapse"}`,
    contentTitle: event?.term,
    modelName: event?.modelName,
    modelYear: event?.modelYear,
  })
}

export function disclaimerClick(event) {
  handleNitroAction(event?.index, event?.modelYear, undefined, undefined, event?.modelName, 'Disclaimer', event?.label);
}
