import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();

export const pageLoad = (data, page) => {
  analytics.firePageLoad({
    tag: data.tagId,
    section: data.section, 
    subsection: data.subsection,
    page: page
  });
};

export const bookmarkClick = (data, page) => {
  analytics.fireOnClick({
    tag: data.tagId,
    section: data.section, 
    subsection: data.subsection,
    page: page,
    category: data.category,
    action: data.action
  });
};

export const carouselArrowClick = (data, page, direction, title) => {
  analytics.fireOnClick({
    tag: data.tagId,
    section: data.section, 
    subsection: data.subsection,
    page: page,
    category: data.category,
    positionTitle: title,
    action: direction
  });
};

export const carouselDotClick = (data, page, position, title) => {
  analytics.fireOnClick({
    tag: data.tagId,
    section: data.section, 
    subsection: data.subsection,
    page: page,
    category: data.category,
    positionTitle: title,
    action: "Nav Dot " + position
  });
};

export const learnMoreClick = (data, page, title) => {
  analytics.fireOnClick({
    tag: data.tagId,
    section: data.section, 
    subsection: data.subsection,
    page: page,
    positionTitle: title,
    category: data.category,
    action: data.action
  });
};

export const viewMoreClick = (data, page) => {
  analytics.fireOnClick({
    tag: data.tagId,
    section: data.section, 
    subsection: data.subsection,
    page: page,
    category: data.category,
    action: data.action
  });
};

export const filterDateDropdownSelect = (data, page, dropdownValue) => {
  analytics.fireOnClick({
    tag: data.tagId,
    section: data.section, 
    subsection: data.subsection,
    page: page,
    category: data.category,
    positionTitle: data.positionTitle,
    action: dropdownValue
  });
};

export const changeMonth = (data, page, dropdownValue) => {
  analytics.fireOnClick({
    tag: data.tagId,
    section: data.section, 
    subsection: data.subsection,
    page: page,
    category: data.category,
    positionTitle: dropdownValue,
    action: data.action
  });
};

export const backToTopClick = (data, page) => {
  analytics.fireOnClick({
    tag: data.tagId,
    section: data.section, 
    subsection: data.subsection,
    page: page,
    category: data.category,
    positionTitle: data.positionTitle,
    action: data.action
  });
};