import { GlobalAnalytics } from "../../../analytics";

const analytics = new GlobalAnalytics();

// Page Load
export const recentActivityPageLoad = (pageLoad) => {
  if(pageLoad) {
    analytics.firePageLoad({
      app: pageLoad.appId,
      tag: pageLoad.tagId,
      section: pageLoad.section,
      page: pageLoad.page,
      subsection: pageLoad.subsection,
      technologyName: pageLoad.technology_name,
    });
  }
};

// Tracking click for a recent activity click
export const recentActivityTabClick = (clickAnalytics, label) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: "Profile",
      category: "Recent Activity",
      label: label + ' tab click'
    });
  }
};

// Tracking click for comment reply card click
export const replyCardClick = (clickAnalytics) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: "Profile",
      category: "Recent Activity",
      label: "Read Reply Click"
    });
  }
};

// Tracking click for hiding a comment reply card click
export const repliesToggleClick = (clickAnalytics, label) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: "Profile",
      category: "Recent Activity",
      label: label
    });
  }
};

// Tracking click for comment replies expand/collapse
export const expandCollapseRepliesClick = (clickAnalytics, label) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: "Profile",
      category: "Recent Activity",
      label: label
    });
  }
};