/* eslint-disable no-tabs */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { capitalize } from '../../utils/capitalize';
import '../../../styles/css/tssLineup.css';
import Select from '../../select/select.component';
import { translateText } from '../../utils/translateText';

import AppSettings from '../../../appsettings';

function generateFeaturesList(component) {
  const featuresList = component.state.lineupItem.features.map((feature, index) => {
    const image = `${AppSettings.AWSImgRepo.CloudFrontURL}/static/images/toyota_safety_sense/${feature.iconImage}`;
    return (
		<div key={index} className="tss-item-feature">
			<div className="feature-icon">
				<img alt="" src={image} />
			</div>
			{feature.title}
		</div>
    );
  });

  return featuresList;
}

function generateTssItem(component) {
  const features = generateFeaturesList(component);

  return (
		<div className="tss-item row">
			<div className="tss-item-summary large-6 columns">
				<div className="tss-item-package-name">{component.state.lineupItem.packageName}</div>
				<div className="tss-item-features">
					{features}
				</div>
			</div>
			<div className="tss-item-image large-6 columns">
				<img alt="" src={AppSettings.AWSImgRepo.tss + component.state.selectedTrim.headerImage} />
			</div>
		</div>
  );
}

export default function TSSLineupTemplate(component) {
  const modelOptions = component.state.modelDropdown ? component.state.modelDropdown.map((model, index) => (
			<option key={index + 1}>{capitalize(model.model)}</option>
  )) : null;

  const trimOptions = component.state.trimDropdown ? component.state.trimDropdown.map((trim, index) => (
			<option key={index + 1}>{trim.trim}</option>
  )) : null;

  const tssItem = component.state.lineupItem ? generateTssItem(component) : null;

  return (
		<div>
			{component.state.languageOverride
			// eslint-disable-next-line no-mixed-spaces-and-tabs
			  ? <div className="language-unavailable" style={{ marginTop: '25px', textAlign: 'Center' }}>
					<FormattedMessage id="dataUnavailable" default="Language is unavailable" />
				</div> : null
			}

			<div className="tss-lineup" style={{ textAlign: 'center' }}>
				<div className="tss-header">
					<div className="print-tss-title">
						<FormattedMessage id="dropdown.safety.toyotaSafetySense" />
					</div>
					<FormattedMessage id="tss.lineup" defaultMessage="TSS Lineup" />
				</div>
				<div className="tss-subheader">
					<FormattedMessage id="tss.headerMessage" defaultMessage="..." />
				</div>
				<div className="row dropdowns">
					<Select
						name="modelYear"
						value={component.state.selectedYear}
						onChange={component.selectYear}
						options={component.state.yearDropdown}
						defaultOption={translateText('entuneLineup.yearDropdownPlaceholder')}
						divStyle="small-12 medium-2 medium-offset-1 large-offset-2 columns"
					/>
					<Select
						name="modelName"
						value={component.getSelectedModel()}
						onChange={component.selectModel}
						customOptions={modelOptions}
						defaultOption={translateText('entuneLineup.modelDropdownPlaceholder')}
						divStyle="small-12 medium-4 large-3 columns"
					/>
					<Select
						name="trim"
						value={component.getSelectedTrim()}
						onChange={component.selectTrim}
						customOptions={trimOptions}
						defaultOption={translateText('entuneLineup.trimDropdownPlaceholder')}
						divStyle="small-12 medium-3 columns end"
						disabled={component.isTrimDropdownDisabled()}
					/>
				</div>
				{tssItem}
			</div>
		</div>
  );
}
