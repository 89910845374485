import { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { AudioMultimediaResourcesTemplate } from './audioMultimediaResources.tpl';
import { pageLoad, filterMenuClick } from './audioMultimediaResourceAnalytics';

class AudioMultimediaResourcesComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      articleTag: props.filter ? props.filter : 'audio multimedia',
    };

    this.productFilterClick = this.productFilterClick.bind(this);
    this.pageLoad = pageLoad.bind(this);
    this.filterMenuClick = filterMenuClick.bind(this);
  }

  updateActiveResourceType(event) {
    this.setState({ activeResourceType: event.target.name });
  }

  handleClick(event) {
    this.updateActiveResourceType(event);
  }

  productFilterClick(event, articleTag) {
    this.setState({
      articleTag,
    });

    this.filterMenuClick(event);
  }

  componentDidMount() {
    this.pageLoad();
  }

  render() {
    return AudioMultimediaResourcesTemplate(this);
  }
}

AudioMultimediaResourcesTemplate.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(AudioMultimediaResourcesComponent);
