import { useEffect, useState } from "react";
import Skeleton from 'react-loading-skeleton';
import DropdownV2 from '../../../components/molecules/DropdownV2';
import Pagination from "../../../components/molecules/engage-xp-pagination/Pagination";
import usePagination from "../../../components/molecules/engage-xp-pagination/usePagination";
import "../../../styles/css/engageXP/leaderboard.css";
import { leaderboardDropdownClick } from "../engageXPMissions.analytics";

const RankItem = ({ rank, user, group, value, isLoadingLeaderboardDealerships }) => {
  const isUser = user !== undefined;
  const displayName = isUser ? `${user.firstName} ${user.lastName}` : group.shortName;

  const getLocation = () => {
    if (isLoadingLeaderboardDealerships) {
      return <Skeleton width={100} />;
    }
    return isUser ? user?.dealership ?? '' : '';
  };

  const location = getLocation();

  return (
    <div className="rank-item">
      <span className="rank-item-position">{rank}</span>
      <div className="rank-item-name-section">
        <span className="rank-item-name">{displayName}</span>
        {isUser && <span className="rank-item-location">{location}</span>}
      </div>
      <span className="rank-item-points">{value} XP</span>
    </div>
  );
};


const Leaderboard = ({ leaderboardConfig, items, type, pageData, isLoadingLeaderboard, isLoadingLeaderboardDealerships, leaderboardSelection, setLeaderboardSelection, analyticsData }) => {
  const {
    displayedItems,
    page,
    setDisplayedItems,
    setPage,
    handlePaginationBack,
    handlePaginationNext,
  } = usePagination({ items, analyticsData });
  const [myRank, setMyRank] = useState(null);

  useEffect(() => {
    setPage(1);
    setDisplayedItemsHandler(1);
    const myIdFromLocalStorage = localStorage.getItem('spin_id');
    const myItem = items.find((item) => item?.user?.id === myIdFromLocalStorage);
    if (myItem) {
      setMyRank(myItem);
    } else {
      setMyRank();
    }
  }, [items, type]);

  useEffect(() => {
    setDisplayedItemsHandler(page);
  }, [page]);

  const setDisplayedItemsHandler = (item) => {
    if (type === "partial") {
      setDisplayedItems(items.slice(0, 3));
    } else {
      setDisplayedItems(items.slice((item - 1) * 10, item * 10));
    }
  };

  const handleChangeLeaderboard = (e) => {
    const option = e.value;
    const selectedLeaderboardObject = leaderboardConfig.find((leaderboard) => leaderboard.name === option);
    setLeaderboardSelection(selectedLeaderboardObject);

    if (analyticsData) {
      const clickAnalytics = analyticsData.clickAnalytics;

      if (type !== "partial"){
        const analyticsCategory = "My Stats";
        const contentTitle = selectedLeaderboardObject.name;
        leaderboardDropdownClick(clickAnalytics, analyticsCategory, contentTitle);
      } else {
        const analyticsCategory = "My Dashboard";
        const contentTitle = selectedLeaderboardObject.name;
        leaderboardDropdownClick(clickAnalytics, analyticsCategory, contentTitle);
      }
    }
  };

  const options = leaderboardConfig?.map((leaderboard) => leaderboard.name) ?? [];

  return type === "partial" ? (
    <div className="leaderboard-container partial">
      <div className="header">
        <span className="header-title">{pageData?.dashboard?.leaderboardDropDown?.title ?? ''}</span>
        <div className="header-dropdown">
          <DropdownV2
            style="white"
            options={options}
            value={leaderboardSelection?.name ?? ''}
            onValueChange={handleChangeLeaderboard}
          />
        </div>
      </div>
      {isLoadingLeaderboard ? (
        <Skeleton height={75} count={4} />
      ) : (
        <>
          <div className={`ranking ${displayedItems.length ? "": "display-grid"}`}>
            {displayedItems.length ? displayedItems.map((item) => {
              const key = item.rank + (item.user ? item.user.id : item.group.shortName);
              return (
                <RankItem {...item} isLoadingLeaderboardDealerships={isLoadingLeaderboardDealerships} key={key} />
              )
            }) : (
              <span className="no-leaders-message">
                No leaders to show
              </span>
            )}
          </div>
          {myRank && (
            <div className="my-rank">
              <span className="my-rank-title">{pageData?.dashboard?.usersRankTitle ?? ''}</span>
              <RankItem {...myRank} isLoadingLeaderboardDealerships={isLoadingLeaderboardDealerships} />
            </div>
          )}
        </>
      )}
    </div>
  ) : (
    <div className="leaderboard-container full">
      <div className="header">
        <span className="header-title">{pageData?.dashboard?.leaderboardDropDown?.title ?? ''}</span>
        <div className="header-dropdown">
          <DropdownV2
            style="white"
            options={options}
            value={leaderboardSelection?.name ?? ''}
            onValueChange={handleChangeLeaderboard}
          />
        </div>
      </div>
      {isLoadingLeaderboard ? (
        <Skeleton height={65} count={11} />
      ) : (
        <>
          <div className={`ranking ${displayedItems.length ? "": "display-grid"}`}>
            {myRank && (
              <div className="my-rank">
                <RankItem {...myRank} isLoadingLeaderboardDealerships={isLoadingLeaderboardDealerships} />
              </div>
            )}
            {displayedItems.length ? displayedItems.map((item) => {
              const key = item.rank + (item.user ? item.user.id : item.group.shortName);
              return (
                <RankItem {...item} key={key} isLoadingLeaderboardDealerships={isLoadingLeaderboardDealerships} />
              )
            }): (
              <span className="no-leaders-message">
                No leaders to show
              </span>
            )}
          </div>
          <Pagination
            items={items}
            page={page}
            pageName="leaderboard"
            handlePaginationBack={handlePaginationBack}
            handlePaginationNext={handlePaginationNext}
          />
        </>
      )}
    </div>
  );
};

export default Leaderboard;
