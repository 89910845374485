import 'whatwg-fetch';
import httpOption from './httpOption';
import AppSettings from '../appsettings';

export default function getSpotlightGallery(modelName, modelYear, mockResponse, languageOverride) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/vehicle/spotlight/${modelName.toLowerCase()}/${modelYear}`;
  const options = httpOption.GET();
  options.headers['accept-language'] = languageOverride || options.headers['accept-language'];
  options.uri = url;

  return new Promise((resolve, reject) => {
    if (mockResponse !== true) {
      fetch(url, options)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return resolve(response.json());
          }
          return reject(response);
        });
    }
  });
}

export async function getAllValidEBrochures() {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/vehicle/spotlight/allEBrochures`;
  const options = httpOption.GET();
  options.uri = url;

  const response = await fetch(url, options);

  if (response.status >= 200 && response.status < 300) {
    return response.json();
  } else {
    return Error(response);
  }
}