import React, {useEffect, useRef, useState} from 'react';
import '../../styles/css/knowledgePane.css';
import AppSettings from '../../appsettings';
import applyMarkdown from '../utils/applyMarkdown';
import * as analytics from './searchBar.analytics';
import { isDesktopView } from '../../screens/utils/getScreenSize.js';
import { toggleVisibilityAnalytics } from './resultHelperFunctions';
import DisclaimersComponent from '../disclaimers/disclaimers.component';


const KnowledgePane = (props) => {
    const [content, setContent] = useState(null);
    const [visible, setVisible] = useState(false);
    const disclaimerComponentRef = useRef();

    useEffect(() => {
        getContent();
    }, [])

    function isTabletOrMobile(){
        if (isDesktopView(window.innerWidth)) {
            return false;
        }
        return true;
    }
    const getContent = () => {
        if (props.isProd && props.details && props.details.titleSection) {
            const keySellingPoints = props.details.keySellingPoints.sellingPoints.map(s => `* ${s.text} `).join('\n')
            setContent(applyMarkdown(keySellingPoints ?? props.details.titleSection.description, disclaimerComponentRef.current));
        }
        if (!content && props.details && props.details.sections) {
            setContent(applyMarkdown(props.details.sections[0].text, disclaimerComponentRef.current));
        }
    };

    function toggleVisibility(toggle) {
        toggleVisibilityAnalytics(toggle, true, props);
        setVisible(toggle);
    }

    const clickExploreButtonAnalytics = () => {
        if (props.clickAnalytics) {
            const positionTitle = `${props.vehicle.modelYear} ${props.vehicle.model}`;
            const contentTitle = _.replace(props.clickAnalytics.exploreButtonSelection.contentTitleNeedsReplace, props.clickAnalytics.exploreButtonSelection.toReplace, props.vehicle.model);
            const label = _.replace(props.clickAnalytics.exploreButtonSelection.labelNeedsReplace, props.clickAnalytics.exploreButtonSelection.toReplace, props.vehicle.model);
            analytics.exploreButtonClicked(
                props.clickAnalytics,
                positionTitle,
                contentTitle,
                label,
                props.vehicle.modelYear,
                props.vehicle.model,
                props.keyword,
                props.numberOfSearchResults,
            );
        }
    };

    const CardCollapsed = () => {
        return (
            <div className="card-collapsed" onClick={() => toggleVisibility(true)}>
                <div className="header-text">
                    <span className="model-year">{props.vehicle.modelYear} </span>
                    <span className="model">{props.vehicle.model}</span>
                </div>
                <span className="body-title">{props.isProd ? "Key Selling Points" : "At a Glance"}</span>
                <img className="down-arrow" src={props.imagePath + "Down_Arrow_Black.png"} />
            </div>
        );
    };

    const HeaderContent = () => {
        const imagePath = AppSettings.AWSImgRepo.jellybeans;
        const rotatedImgSrc = props.vehicle.rotatedImgSrc ? imagePath + props.vehicle.rotatedImgSrc : imagePath + props.vehicle.imgSrc;
        return (
            <div className="header-content">
                <HeaderText />
                <img className="header-image" src={rotatedImgSrc} />
            </div>
        );
    };

    let headerTitle = !props.vehicle.displayName ? props.vehicle.model : props.vehicle.displayName ;
    const HeaderText = () => {
        return (
            <span className="header-text">
                <span className="model-year">{props.vehicle.modelYear} </span>
                <span className="model">{headerTitle}</span>
            </span>
        );
    };
    
    const BodyContent = () => {  
        return (
            <div className="body-content">
                <QuickSpecsTitle />
                <QuickSpecsContent />
                <QuickSpecsButton />
                {isTabletOrMobile() && <span className = "arrowButtonSpacing" onClick={() => toggleVisibility(false)}><img className="arrowButton" src={props.imagePath + "Up_Arrow_Black.png"} /></span>}
            </div>
        );
    };

    const QuickSpecsTitle = () => {
        return (
            content && 
            <div className="quick-specs-title">
                <span className="body-title">{props.isProd ? "Key Selling Points" : "At a Glance"}</span>
                <div className="red-border" />
            </div>
        );
    };

    const QuickSpecsContent = () => {
        return (
            <div className="quick-specs">{content}</div>
        );
    };

    const KnowledgePaneDesktop = () => {
        return (
            <div className="display-desktop">
                <HeaderContent />
                <BodyContent /> 
            </div>
        );
    };

    const KnowledgePaneTabletMobile = () => {
        return (
            <div className="display-tablet-mobile">
                {!visible && <CardCollapsed />}
                {visible && <HeaderContent />} 
                {visible && <BodyContent />}
                
            </div>
        );
    };

    const QuickSpecsButton = () => {
        let lineBreak = "";
        if((props.vehicle.model.indexOf(' ') >= 0 ) && (props.vehicle.model.length > 12)){
            lineBreak = <br></br>;
        }
        return (
            <a href={props.vehicle.href} onClick={() => {clickExploreButtonAnalytics()}}>
                <button className="explore-button">Explore {lineBreak}{props.vehicle.model}</button>
            </a>
        )
    }

    return (
        <div className="knowledge-pane">
            <KnowledgePaneDesktop />
            <KnowledgePaneTabletMobile />
            <DisclaimersComponent
                template={`${props.vehicle.modelYear}_${props.vehicle.model.toLowerCase()}_overview`}
                ref={disclaimerComponentRef}
                addBaseMSRPDisclaimer={true}
            />
        </div>
    );
}

export default KnowledgePane;