import React from 'react';
import PropTypes from 'prop-types';
import applyMarkdown from '../../../screens/utils/applyMarkdown';
import '../../../styles/css/marketingPageWideCard.css';

const MarketingWideCard = (props) => {
    const wideCardImage = () => {
        if (props.device === 'desktop') {
            return props.image && <div className="wide-card-image">{<img src={props.imagePath + props.image} />}</div>;
        } else {
            return props.mobileImage && <div className="wide-card-image">{<img src={props.imagePath + props.mobileImage} />}</div>;
        }
    };

    return (
        <div data-testid="wide-card" className = "wide-card">
            {props.title && <div className="wide-card-cardTitle">{applyMarkdown(props.title)}</div>}
            <div className="wide-card-description">{applyMarkdown(props.description)}</div>
            {wideCardImage()}
        </div>
    );
};

MarketingWideCard.propTypes = {
    imagePath: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
}

export default MarketingWideCard;