import AppSettings from "../../../../appsettings";

export const handleLinkClick = (url) => {
    const internalRegex = /engage\.toyota\.com|localhost|^\//;

    if (url.includes('.pdf')) {
        window.open(`${AppSettings.AWSImgRepo.beyondZero}${url}`, '_blank').focus();
    } else if (url.startsWith('/') || internalRegex.test(url)) {
        window.location.href = url;
    } else {
        window.open(url);
    }
};