import React from 'react';
import PropTypes from 'prop-types';
import AwardsComponent from '../../awards/awards.component';
import TitleText from '../../../components/atoms/TitleText';
import RedLine from '../../../components/atoms/RedLine';
import '../../../styles/css/model-overview/awards.css';

const analytics = require('../modelOverview.analytics');

export default function AwardsSection(props) {
  const styles = props.styles ? props.styles : null;

  const onAwardCarouselClick = (clickDirection) => {
    const isNext = clickDirection === 'next';
    if (isNext) {
      analytics.fireModelOverviewClick('Carousel:Arrow Click', 'next', 'Awards');
    } else {
      analytics.fireModelOverviewClick('Carousel:Arrow Click', 'previous', 'Awards');
    }
  };

  return (
    <React.Fragment>
      <div className="model-overview-web-content">
        <div className="awards-section" style={{ color: (styles.textColor ? styles.textColor : '#000000'), backgroundColor: (styles.backgroundColor ? styles.backgroundColor : '#FFFFFF') }}>
          <TitleText className="awards-section-title" style={{ marginBottom: '25px' }}>
            Awards<br />
            <RedLine style={{
              marginBottom: 'auto', marginLeft: 'auto', marginRight: 'auto', marginTop: '20px', paddingBottom: '0px',
            }} />
          </TitleText>
          {props.awards ? <AwardsComponent onArrowClick={onAwardCarouselClick} onDotClick={onAwardCarouselClick} style={{ color: 'inherit', backgroundColor: 'inherit' }} imagePath={props.imagePath} awards={props.awards} disclaimer={props.disclaimer} /> : <div>Awards coming soon!</div>}
        </div>
      </div>
    </React.Fragment>
  );
}

AwardsSection.propTypes = {
  awards: PropTypes.array,
  styles: PropTypes.object,
  imagePath: PropTypes.string,
  disclaimer: PropTypes.node,
};
