import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import { NitroContext } from '../../../components/contexts/nitroContext';
import { postQuizSubmit, sendQuestionAnswers } from '../../../services/nitroService';
import '../../../styles/scss/engageXP/triviaModal.scss';
import isAppleMobileDevice from '../../utils/isAppleMobileDevice';
import StartTrivia from './startTrivia';
import TriviaQuestion from './triviaQuestion';
import TriviaResult from './triviaResult';
import { closeModalClick } from './triviaModal.analytics';

const TriviaStates = {
    Start: "start",
    Questions: "questions",
    Passed: "passed",
    Failed: "failed"
}

const QUIZ_TYPES = {
    SURVEY: "SURVEY",
    QUIZ: "QUIZ",
};

const TriviaModal = (props) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [questionIndex, setQuestionIndex] = useState(0);
    const [triviaState, setTriviaState] = useState();
    const [triviaResult, setTriviaResult] = useState();
    const { nitroLoadingState, setNitroLoadingState } = useContext(NitroContext);
    
    useEffect(() => {
        if (props.visible) {
            if (!props.triviaData?.overview.inProgress) {
                setTriviaState(TriviaStates.Start);
                setTriviaResult(null);
                setQuestionIndex(0);
            } else {
                setTriviaState(TriviaStates.Questions);
                // set the question index to where you last left off
                setQuestionIndex(0)
            }
            setNitroLoadingState(false);
        }
    }, [props.triviaData, props.visible]);

    useEffect(() => {
        if (props.visible) {
            //disable background body scrolling and input field zoom
            disableMobileScrolling();
        } else {
            enableMobileScrolling();
        }
    }, [props.visible]);

    const disableMobileScrolling = () => {
        if (isAppleMobileDevice()) {
            const y = window.scrollY;
            setScrollPosition(y);
            document.getElementsByClassName("off-canvas-wrapper")[0].style.top = -y + 'px';

            document.getElementsByTagName("html")[0].classList.add("overlay-disable-body-scroll");
            document.body.classList.add("overlay-disable-body-scroll");
            document.getElementsByName("viewport")[0].setAttribute("content", "width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0");
        }
    }

    const enableMobileScrolling = () => {
        if (isAppleMobileDevice()) {

            document.getElementsByTagName("html")[0].classList.remove("overlay-disable-body-scroll");
            document.body.classList.remove("overlay-disable-body-scroll");
            document.getElementsByName("viewport")[0].setAttribute("content", "width=device-width, initial-scale=1");

            if (!props.disableAutoScrolling) {
                window.scrollTo(0, scrollPosition);
            }

            document.getElementsByClassName("off-canvas-wrapper")[0].style.top = 0;
        }
    }

    useEffect(() => {
        return () => {
            props.visible && closeModal();
            enableMobileScrolling();
        };
    }, []);

    const closeModal = (shouldFetchData = false) => {
        if (props?.analyticsData) {
            const clickAnalytics = props.analyticsData.clickAnalytics;
            closeModalClick(clickAnalytics);
        }
        props.closeModalClick && props.closeModalClick();
        props.closeModal(shouldFetchData);
    };

    const getTriviaScreen = () => {
        if (triviaState === TriviaStates.Start) {
            // if not in progress, show start screen
            return <StartTrivia
                image={props.triviaData?.overview?.image}
                description={props.triviaData?.overview?.description}
                startQuiz={() => startQuiz()}
                quizNameAnalytics={props.triviaData?.overview?.name}
                analyticsData={props?.analyticsData}
            />
        } else if (triviaState === TriviaStates.Questions) {
            // get current question
            const currentQuestion = props.triviaData?.questions[questionIndex]

            return <TriviaQuestion
                isSurvey={props.triviaData?.overview?.quizType === QUIZ_TYPES.SURVEY}
                question={currentQuestion?.text || 'No question remaining, please submit the quiz.'}
                answers={currentQuestion?.answers}
                correctCount={currentQuestion?.enforceAnswerMinimum && currentQuestion?.correctCount}
                index={currentQuestion?.index}
                answersDetails={props.triviaData?.details.questions.find((question) => question.id === currentQuestion?.id)}
                totalQuestions={props.triviaData?.overview?.maxQuestionsToDisplay}
                submitAnswers={submitAnswers}
                nextQuestion={nextQuestion}
                submitQuiz={submitQuiz}
                analyticsData={props.analyticsData}
                quizName={props.triviaData?.overview?.name}
                handleTournamentPoints={props.handleTournamentPoints}
                quizId={props.triviaData?.overview?.id}
            />
        } else if (triviaState === TriviaStates.Passed || triviaState === TriviaStates.Failed) {
            const repeatable = props.maxAttempts === 0 || (props.triviaData?.overview?.attempts < props.triviaData?.overview?.maxAttempts);

            return <TriviaResult
                triviaResult={triviaResult}
                repeatable={repeatable}
                description={"Thanks for playing!"}
                endQuiz={endQuiz}
                restartQuiz={restartQuiz}
                analyticsData={props.analyticsData}
                quizName={props.triviaData?.overview?.name}
            />
        }
        return null;
    }

    const startQuiz = () => {
        setTriviaState(TriviaStates.Questions)
    }

    const submitAnswers = async (questionId, selectedAnswers) => {
        const quizId = props.triviaData.overview.id;
        await sendQuestionAnswers(quizId, questionId, selectedAnswers);
    }

    const nextQuestion = () => {
        setQuestionIndex((prevIndex) => prevIndex + 1);
    }

    const submitQuiz = async () => {
        try {
            const quizId = props.triviaData.overview.id;
            // submit trivia API call
            // get trivia response API call
            setNitroLoadingState(true);
            const resultAPI = await postQuizSubmit(quizId);
            setNitroLoadingState(false);
            const result = resultAPI.response
            const state =
              props.triviaData.overview.quizType === QUIZ_TYPES.SURVEY ||
              result.passed
                ? TriviaStates.Passed
                : TriviaStates.Failed;

            setTriviaState(state)
            setTriviaResult(result)
        } catch (error) {
            console.log(error);
            setNitroLoadingState(false);
            endQuiz();
        }
    }

    const endQuiz = () => {
        const shouldFetchData = true;
        closeModal(shouldFetchData);
    }

    const restartQuiz = async () => {
        const quizId = props.triviaData.overview.id;
        await props.reloadData(quizId);
        setTriviaState(TriviaStates.Start);
        setTriviaResult(null);
        setQuestionIndex(0);
    }

    return (
        props.visible ? <Modal
            open={props.visible}
            onClose={() => closeModal(!!triviaResult)}
            blockScroll={!isAppleMobileDevice()}
            showCloseIcon
            center
            closeOnOverlayClick={false}
            classNames={{
                modal: "triva-modal-modal",
                root: nitroLoadingState ? 'loading' : ''
            }}
        >
            <div className="trivia-modal-container">
                <div className="trivia-title">{props.triviaData?.overview?.name}</div>
                {getTriviaScreen()}
            </div>
        </Modal> : <></>
    )
}

export default TriviaModal;
