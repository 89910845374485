import PropTypes from 'prop-types';
import React from 'react';
import { GlobalAnalytics } from '../../analytics';
import { postWatchVideoAction } from '../../services/nitroService';
import { postUserActivityRecord } from '../../services/userActivityService';
import '../../styles/css/engageLive.css';

const analytics = new GlobalAnalytics();

export const qumuVideoAnalytics = (pageTitle, videoBeingPlayed, event, category, module, section, tag, percentWatched, videoLength, sendPlayClick, vehicleSpecs) => {
  postWatchVideoAction(videoBeingPlayed.guid, percentWatched);
  if (sendPlayClick) {
    const videoPath = `${window.location.pathname}#${videoBeingPlayed.guid}`;
    postUserActivityRecord(videoPath, true, videoBeingPlayed.title);
  }
  analytics.fireOnClick({
    tag,
    section,
    page: pageTitle,
    category,
    contentTitle: videoBeingPlayed.title,
    mediaTitle: videoBeingPlayed.title,
    modelName: ' ',
    module,
    label: videoBeingPlayed.title,
    events: event,
    videoLength: formatTime(videoLength),
    ...vehicleSpecs,
  });
};

export function formatTime(milliseconds) {
  const hh = Math.floor((milliseconds / 1000 / 60 / 60) % 60);
  const mm = Math.floor((milliseconds / 1000 / 60) % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 });
  const ss = Math.floor((milliseconds / 1000) % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 });
  if (hh) {
    return `${hh}:${mm}:${ss}`;
  } else {
    return `${mm}:${ss}`;
  }
}


const QumuVideoWidget = (props) => {
  const widgetId = `${props.guid}qumu-widget`;
  const videoAnalyticsModule = props.analyticsModule ? props.analyticsModule : 'Video';
  const videoAnalyticsSection = props.analyticsSection ? props.analyticsSection : 'Engage Live';
  const videoAnalyticsPage = props.analyticsPage ? props.analyticsPage : "Video"
  const videoAnalyticsTag = props.analyticsTag ? props.analyticsTag : '40.5';
  const videoAnalyticsVehicleSpecs = props.analyticsVehicleSpecs ? {
    modelBed: props.analyticsVehicleSpecs.modelBed,
    modelCab: props.analyticsVehicleSpecs.modelCab,
    modelCode: props.analyticsVehicleSpecs.modelCode,
    modelDrivetrain: props.analyticsVehicleSpecs.modelDrivetrain,
    modelEngine: props.analyticsVehicleSpecs.modelEngine,
    modelName: props.analyticsVehicleSpecs.modelName,
    modelYear: props.analyticsVehicleSpecs.modelYear,
    trim: props.analyticsVehicleSpecs.trim,
  } : null;
  const videoPlayerWidth = props.width ? props.width : null;
  const videoPlayerHeight = props.height ? props.height : null;

  let hideCTA = props.hideCTA ? props.hideCTA : false;
  if (window.ReactNativeWebView) {
    hideCTA = true; // Share button does not work in mobile app
  }

  let sentAnalyticsForTwentyFive;
  let sentAnalyticsForFifty;
  let sentAnalyticsForSeventyFive;
  // eslint-disable-next-line no-undef
  const widgetApi = KV.widget({
    playIcon: {
      hide: false,
      width: 98,
      height: 98,
      largeWidth: 98,
      largeHeight: 98,
      position: 'middle-center',
      padding: 0,
    },
    type: props.type,
    hideDownloadButton: hideCTA,
    hideEmbedButton: true,
    hideShareButton: hideCTA,
    downloadVideoButtonText: 'Download',
    shareButtonText: 'Share',
    shareButtons: [
      'twitter',
      'facebook',
      'email',
      'linkedin'
    ],
    infoLabels: false,
    showInfoPanel: true,
    sidebar: false,
    selector: `#${widgetId}`,
    ...props,
    overlayPosition: 'middle-center',
    packshot: {
      width: videoPlayerWidth,
      height: videoPlayerHeight
    },
    player: {
      width: videoPlayerWidth,
      maxWidth: videoPlayerWidth,
      height: videoPlayerHeight,
      maxHeight: videoPlayerHeight
    },
    trackProgress: false
  }).api;

  widgetApi.init((err) => {
    if (err) {
      console.log(err);
    } else {
      widgetApi.bind('play', () => {
        widgetApi.get('kulu', (kulu) => {
          qumuVideoAnalytics(videoAnalyticsPage, kulu, 'event71', 'Video Play Click', videoAnalyticsModule, videoAnalyticsSection, videoAnalyticsTag, '0', kulu.duration, true, videoAnalyticsVehicleSpecs);
        });
      });

      widgetApi.bind('ended', () => {
        widgetApi.get('kulu', (kulu) => {
          qumuVideoAnalytics(videoAnalyticsPage, kulu, 'event72', 'Video Complete', videoAnalyticsModule, videoAnalyticsSection, videoAnalyticsTag, '100', kulu.duration, false, videoAnalyticsVehicleSpecs);
        });
      });
      widgetApi.bind('timeupdate', () => {
        widgetApi.get('kulu', (kulu) => {
          widgetApi.get('currentTime', (currentTime) => {
            const percentWatched = Math.floor((currentTime / kulu.duration) * 100);
            if (!sentAnalyticsForTwentyFive && percentWatched > 25) {
              qumuVideoAnalytics(videoAnalyticsPage, kulu, 'event73', 'Video 25%', videoAnalyticsModule, videoAnalyticsSection, videoAnalyticsTag, '25', kulu.duration, false, videoAnalyticsVehicleSpecs);
              sentAnalyticsForTwentyFive = true;
            } else if (!sentAnalyticsForFifty && percentWatched > 50) {
              qumuVideoAnalytics(videoAnalyticsPage, kulu, 'event74', 'Video 50%', videoAnalyticsModule, videoAnalyticsSection, videoAnalyticsTag, '50', kulu.duration, false, videoAnalyticsVehicleSpecs);
              sentAnalyticsForFifty = true;
            } else if (!sentAnalyticsForSeventyFive && percentWatched > 75) {
              qumuVideoAnalytics(videoAnalyticsPage, kulu, 'event75', 'Video 75%', videoAnalyticsModule, videoAnalyticsSection, videoAnalyticsTag, '75', kulu.duration, false, videoAnalyticsVehicleSpecs);
              sentAnalyticsForSeventyFive = true;
            }
          });
        });
      });
    }
  });

  return <div data-testid="generic-qumu-widget" className="generic-qumu-widget"><div id={widgetId} className={props.className} /></div>;
};

QumuVideoWidget.propTypes = {
  analyticsModule: PropTypes.string,
  analyticsSection: PropTypes.string,
  analyticsTag: PropTypes.string,
  className: PropTypes.string,
  guid: PropTypes.string,
  height: PropTypes.number,
  hideCTA: PropTypes.bool,
  pageTitle: PropTypes.string,
  width: PropTypes.number,
};

export default React.memo(QumuVideoWidget);
