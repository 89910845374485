import 'whatwg-fetch';
import httpOption from './httpOption';
import AppSettings from '../appsettings';

export default async function getDisclaimers(template) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/disclaimers/${template}`;
  const options = httpOption.GET();

  const fetchResults = await fetch(url, options);
  if (resultsAreValid(fetchResults)) {
    return fetchResults.json();
  }
}

const resultsAreValid = results => {
  return results.status >= 200 && results.status < 300;
}
