import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import FAQs from '../../components/pages/FAQs';
import { faqFilterClick, pageLoad } from './smartpath-analytics.js';
import { postUserActivityRecord } from '../../services/userActivityService.js';

export class SPFAQComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      languageOverride: this.props.languageOverride,
      faqs: this.props.faqs.questions ? this.props.faqs.questions : this.props.faqs,
      filters: this.props.faqs.faqFilters ? this.props.faqs.faqFilters : null,
    };
    this.pageLoad = pageLoad.bind(this);
  }

  componentDidMount() {
    this.setState({ languageOverride: this.props.languageOverride });
    this.pageLoad('FAQs');
    postUserActivityRecord();
  }

  render() {
    return (
      <FAQs
        clickEvent={faqFilterClick}
        disclaimer={'SP'}
        faqs={this.state.faqs}
        filters={this.state.filters}
        languageOverride={this.state.languageOverride}
        urlPath={'/smartPath/faqs'}
      />
    );
  }
}

export default injectIntl(SPFAQComponent);
