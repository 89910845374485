import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import appsettings from "../../appsettings";
import Banner from "./tournamentSections/banner/banner";
import HowItWorks from "./tournamentSections/howItWorks";
import Prizes from "./tournamentSections/prizes";
import JumpButtons from "./tournamentSections/jumpButtons";
import Leaderboard from "./tournamentSections/leaderboard";
import Tournament from "./tournamentSections/tournament";
import { xpTournamentPageLoad, xpTournamentClick } from "./engageXPTournamentPage.analytics";
import {
    getTournamentConfig,
    getTournamentLeaderboard,
} from "../../services/nitroService";
import { getCurrentRound } from "./utils";

const EngageXPTournamentPage = ({
    tournamentsData,
    isUserEligible,
    userEligibilityUpdated,
    getColoredBadgeInfo,
    getBlurredBadgeText,
    missions,
    selectedRound,
    handleRoundClick,
    handleBackClick,
    userAverageScore,
    userTournamentPoints,
}) => {
    const [tournamentConfig, setTournamentConfig] = useState(null);
    const [tournamentLeaderboard, setTournamentLeaderboard] = useState([]);
    const [isLoadingLeaderboard, setIsLoadingLeaderboard] = useState(true);
    const [deviceType, setDeviceType] = useState();
    const [currentRound, setCurrentRound] = useState();
    const [myRank, setMyRank] = useState(null);
    const [userTournamentXp, setUserTournamentXp] = useState(0);
    const imgPath = appsettings.AWSImgRepo.engageXP + "tournaments/";

    useEffect(() => {
        getTournamentConfiguration();

        const handleResize = () => {
            const screenSize = window.innerWidth;
            if (screenSize <= 917) {
                setDeviceType("mobile");
            } else if (screenSize <= 1504) {
                setDeviceType("tablet");
            } else {
                setDeviceType("desktop");
            }
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        // analytics
        xpTournamentPageLoad({
            subsection: "EngageXP",
            technology_name: "Featured Technology​",
        });

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (tournamentConfig) {
            getTournamentLeaderboardData(tournamentConfig);
        }
    }, [tournamentConfig]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (tournamentsData) {
                setCurrentRound(prevRound => {
                    const newCurrentRound = getCurrentRound(tournamentsData.tournamentSection.rounds);
                    if (!_.isEqual(newCurrentRound, prevRound)) {
                        return newCurrentRound;
                    } else {
                        return prevRound;
                    }
                });
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [tournamentsData]);

    const getTournamentLeaderboardData = async (tournamentConfig) => {
        try {
            const response = await getTournamentLeaderboard(tournamentConfig[0].leaderboardId);
            setTournamentLeaderboard(
                response
                    .sort((a, b) => a.rank - b.rank)
                    .map((item) => ({ ...item, value: `${item.value} ${item.value === 1 ? "point" : "points"}` }))
            );
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoadingLeaderboard(false);
        }
    };

    const getTournamentConfiguration = async () => {
        try {
            const response = await getTournamentConfig();
            setTournamentConfig(response);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <Banner
                tournamentsData={tournamentsData}
                currentRound={currentRound}
                imgPath={imgPath}
                deviceType={deviceType}
                isUserEligible={isUserEligible}
                userEligibilityUpdated={userEligibilityUpdated}
            />
            <JumpButtons tournamentsData={tournamentsData} analytics={xpTournamentClick} />
            <Prizes tournamentsData={tournamentsData} imgPath={imgPath} device={deviceType} />
            <HowItWorks tournamentsData={tournamentsData} analytics={xpTournamentClick} imgPath={imgPath} />
            <Tournament
                tournamentsData={tournamentsData}
                currentRound={currentRound}
                deviceType={deviceType}
                imgPath={imgPath}
                getColoredBadgeInfo={getColoredBadgeInfo}
                getBlurredBadgeText={getBlurredBadgeText}
                missions={missions}
                myRank={myRank?.rank}
                userAverageScore={userAverageScore}
                userTournamentPoints={userTournamentPoints}
                userTournamentXp={userTournamentXp}
                setUserTournamentXp={setUserTournamentXp}
                selectedRound={selectedRound}
                handleRoundClick={handleRoundClick}
                handleBackClick={handleBackClick}
                isUserEligible={isUserEligible}
                userEligibilityUpdated={userEligibilityUpdated}
            />
            <Leaderboard
                items={tournamentLeaderboard}
                pageData={tournamentsData}
                isLoadingLeaderboard={isLoadingLeaderboard}
                myRank={myRank}
                setMyRank={setMyRank}
                analyticsData={{
                    clickAnalytics: {
                        tagId: "29.2​",
                        appId: "eShow",
                        section: "Product",
                        page: "XP Tournament​",
                        category: "Leaderboard​",
                        label: "Leaderboard Pagination​",
                    },
                }}
                currentRound={currentRound}
            />
        </div>
    );
};

export default EngageXPTournamentPage;
