import 'whatwg-fetch';
import httpOption from './httpOption';
import AppSettings from '../appsettings';

export default function getMisc(pageName, language) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/miscTable/${pageName}`;
  const options = httpOption.GET();
  options.headers['accept-language'] = language || options.headers['accept-language'];

  return fetch(url, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(response);
    });
}
