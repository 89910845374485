import PropTypes from 'prop-types';
import React, {cloneElement, useEffect, useState} from 'react';
import '../../../styles/css/tabFilterCard.css';

const tabFilterCard = props => {
    const selectTab = (filter) => {
      props.selectTabAnalytics && props.selectTabAnalytics(filter.title);
      props.setTabSelected(filter);
    }
    
    const FilterTabs = () => {
          return (
            props.tabs.map((filter, index) => (
            <span 
              key={index}
              style={{zIndex: `${10-index}`}} 
              onClick={() => selectTab(filter)}
              className={filter.title === props.tabSelected.title ? "active" : "inactive"}
            >
              { filter.title }
              { props.displayBadges && filter.badgeCount > 0 && <div className="menu-item-badge">{ filter.badgeCount <= 9 ? filter.badgeCount : "9+" }</div> }
            </span>
            ))
          )
    }

    return (
    <div className="tabFilterCard">
        {props.device === 'mobile' && <div className='divider-line'/>}
        <div className={`${props.device === 'mobile' ? "filter-tabs-mobile" : "filter-tabs"} ${props.displayBadges && "displayBadges"}`}>
            <FilterTabs/>
        </div>
        <div className="tabCardContainer">
          { props.children } 
        </div>
    </div>);
};

tabFilterCard.propTypes = {
    tabs: PropTypes.array,
    tabSelectFunction: PropTypes.func,
    cardContent: PropTypes.element
};

export default tabFilterCard;
