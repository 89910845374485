import React from 'react';
import PropTypes from 'prop-types';
import applyMarkdown from '../utils/applyMarkdown';
import { hasVideoId } from '../utils/helpers';
import VideoPlayerComponent from '../videoPlayer/videoPlayer.component';
import '../../styles/css/mediaText.css';

export default function ThumbnailMediaTextItem(props) {

  const mediaTextItems = props.mediaTextItems
        && props.mediaTextItems.map((item, index) => {
          const videoItem = {
            ...item,
            videoTitle: item.title,
            alias: item.brightcoveId,
            qumuId: item.qumuId,
            pageTitle: 'SmartPath',
          };
          return (<div key={index} className="media-text-item-container">
                <div className="leftItem">
                    {hasVideoId(item.brightcoveId, item.qumuId)
                      && <div className="item-video large-3 medium-12 columns" key={index}>
                            <VideoPlayerComponent
                                item={videoItem}
                                sendVideoEventAnalytics={props.sendVideoEventAnalytics}
                            />
                        </div>}
                    {item.image
                      ? <div className="item-image large-3 medium-12 columns">
                            <div key={index} className="item-image" alt="">
                                <img alt="" src={props.imagePath + item.image} />
                            </div>
                        </div> : null}
                    <div className={`item-text item-text ${(item.qumuId || item.image) ? 'large-9' : ''}`}>
                        {item.title
                          ? <div>
                                <div className="item-title">{applyMarkdown(item.title, props.disclaimer)}</div>
                                <div className="divide" />
                            </div> : null}
                        {item.subtitle
                          ? <div className="item-subtitle">
                                {applyMarkdown(item.subtitle, props.disclaimer)}
                            </div> : null}
                        {item.description
                          ? <div className="item-description description-expanded">
                                {applyMarkdown(item.description, props.disclaimer)}
                            </div> : null}
                    </div>
                </div>
            </div>
          );
        });

  return (
        <div>{mediaTextItems}</div>
  );
}

ThumbnailMediaTextItem.propTypes = {
  mediaTextItems: PropTypes.array,
  disclaimer: PropTypes.any,
};
