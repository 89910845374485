import React from 'react';
import Select from '../../select/select.component';
import AppSettings from '../../../appsettings';

export function PrimaryVehicleTemplate(component) {
  const make = component.getMake();
  const models = component.getModels();
  const trims = component.getTrims();
  const image = component.noCustomImageAvailable(component.props.image) ? AppSettings.AWSImgRepo.resources + 'no_image_available_tall.png' : component.props.image;

  let className = 'vehicle';
  if (component.props.totalVehicles) {
    className += ` vehicle-span-${component.props.totalVehicles}`;
  }
  if (component.props.index > 1) {
    className += ' hide-for-small-only';
  }

  return (
    <div className={className}>
      <img className="img-vehicle" alt="" src={image} />
      <div className="primary-vehicle">
        <span>{make}</span>
      </div>
      <Select
        value={component.state.selectedModelId}
        onChange={component.handleModelChange.bind(component)}
        customOptions={models}
        customStyle="vehicle-dropdown"
      />
      <Select
        value={component.state.selectedTrim}
        onChange={component.handleTrimChange.bind(component)}
        customOptions={trims}
        customStyle="vehicle-dropdown"
      />
    </div>
  );
}
