import React, { useContext, useEffect, useState, useRef } from 'react';
import '../../../styles/css/systemInformationModalContent.css';
import RedLine from '../../../components/atoms/RedLine';


const SystemInformationModalContent = (props) => {

    return (
        <div className="systemInfoModalContent">
            <div className="systemInfoHeader">
                <div className="systemInfoTitleDescription">
                    <div className="systemInfoTitle">{props.headerTitle}</div>
                    <RedLine style={{
                        width: '57px',
                        borderBottom: '3px solid #EB0A1E',
                        margin: '20px auto 20px 0px'
                        }} />
                    <div className="systemInfoDescription">{props.headerDescription}</div>
                </div>
                <div className="systemInfoHeaderImage">
                    <img src={props.headerImage} />
                </div>
            </div>
            <div className="systemInfoBody">
                {props.children}
            </div>
        </div>
    )
}

export default SystemInformationModalContent;