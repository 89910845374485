import AppSettings from '../../appsettings';

export function getArticleThumbnailPath(resource, s3Path) {
    const thumbnailPath = resource.thumbnail;
    const displayCategory = getDisplayCategory(resource);
    return getThumbnail(thumbnailPath, displayCategory, s3Path);
};

export function getDisplayCategory(resource){
    const articleFormat = resource.format ? resource.format.toString().toLowerCase() : null;

    let categoryOptions = resource.categories ? (typeof resource.categories === 'string' ? JSON.parse(resource.categories) : resource.categories) : [resource.category.toString().toLowerCase()];
    categoryOptions = categoryOptions.filter(cat => [
        'article',
        'edge',
        'video',
        'kmsm',
        'cfa',
        'hot sheet',
        'head to head',
        'video',
        'overview',
        'how to',
        'feature function benefits',
        '3rd party',
        'news'
    ].includes(cat.toLowerCase()));
    return categoryOptions && categoryOptions.length === 1 ? categoryOptions.toString().toLowerCase() : articleFormat;
}

function getThumbnail(thumbnailPath, displayCategory, s3Path) {
    if (thumbnailPath && thumbnailPath.indexOf('EDGE_Arrow_Icon') < 0) {
        if (thumbnailPath.startsWith('/static/' || thumbnailPath.startsWith('http'))) {
            return thumbnailPath;
        } if (thumbnailPath.startsWith('/images/product_images')) {
            return AppSettings.AWSImgRepo.static + thumbnailPath;
        } if (thumbnailPath.startsWith('/images/') || thumbnailPath.startsWith('/')) {
            return AppSettings.AWSImgRepo.articleImages + thumbnailPath;
        }
        return AppSettings.AWSImgRepo.vehicle + s3Path + thumbnailPath;
    } if (displayCategory) {
        switch (displayCategory.toLowerCase()) {
            case 'kmsm':
                return AppSettings.AWSImgRepo.resources + 'en/KMSM_Icon.png';
            case 'hot sheet':
                return AppSettings.AWSImgRepo.resources + 'en/HOTSHEET_Icon.png';
            case 'cfa':
                return AppSettings.AWSImgRepo.resources + 'en/CFA_Icon.png';
            case 'overview':
                return AppSettings.AWSImgRepo.resources + 'en/OVERVIEW_Icon.png';
            case 'how to':
                return AppSettings.AWSImgRepo.resources + 'en/HOWTO_Icon.png';
            case 'feature function benefits':
                return AppSettings.AWSImgRepo.resources + 'en/FFB_Icon.jpg';
            case 'head to head':
            case 'edge':
                return AppSettings.AWSImgRepo.resources + 'en/H2H_Icon.png';
            case 'video':
                return AppSettings.AWSImgRepo.resources + 'en/VIDEOS_Icon.jpg';
            case 'article':
                return AppSettings.AWSImgRepo.resources + 'en/ARTICLES_Icon.jpg';
            case 'news':
                return AppSettings.AWSImgRepo.resources + 'en/NEWS_Icon.jpg';
            default:
                return AppSettings.AWSImgRepo.resources + 'en/H2H_Icon.png';
        }
    }
    return AppSettings.AWSImgRepo.resources + 'en/H2H_Icon.png';
};

