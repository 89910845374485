import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import SubheaderComponent from '../subheader/subheader.component';
import AppPageOverview from './components/appPage-overview';
import AppPageGetStarted from './components/appPage-getStarted';
import AppPageFAQs from './components/appPage-faqs';
import AppPageVideos from './components/appPage-howToVideos';
import AppPageResources from './components/appPage-resources';
import Display404NotFound from '../../components/atoms/404NotFound';
import AppSettings from '../../appsettings';
import getMisc from '../../services/miscService';
import getAnalytics from '../../services/analyticsService';
import { appBookmarkClick, appSubheaderClick } from './appPage-analytics';

const AppPageRouterComponent = (props) => {
  const [device, setDevice] = useState('');
  const [pageData, setPageData] = useState(null);
  const [analyticsData, setAnalyticsData] = useState(null);
  const history = useHistory();
  const imagePath = AppSettings.AWSImgRepo.appPage;

  useEffect(() => {
      getMisc('appLanding')
        .then((data) => {
          if (!pageData) { setPageData(data); }
        })
        .catch((error) => {
          console.log(`ERROR: failed to load App page data. ${error}`);
        });
  }, []);

  useEffect(() => {
    getAnalyticsData();
    updateDevice();
    window.addEventListener("resize", updateDevice);
    return () => window.removeEventListener("resize", updateDevice);
  }, []);

  function updateDevice() {
    if (window.innerWidth >= 1320) {
      setDevice('desktop');
    } else if (window.innerWidth >= 768) {
      setDevice('tablet');
    } else if (window.innerWidth < 768) {
      setDevice('mobile');
    }
  }

  const getAnalyticsData = async () => {
    const appPageAnalytics = await getAnalytics('appPage');
    setAnalyticsData(appPageAnalytics);
  };

  const overviewPageProps = {
    disclaimerName: 'AppPage', //verify disclaimer name
    parentPageName: 'AppPage', // verify page name is AppPage
  };

  const getSubheaderChildComponent = (path) => {
    const page = path ? path.toLowerCase() : '';
    const subPageProps = {
      device: device,
      imagePath: imagePath,
    };

    switch (page) {
      case 'overview':
        if (pageData?.items?.overview) {
          return <AppPageOverview overviewData={pageData?.items?.overview} {...subPageProps} {...overviewPageProps} analyticsData = { analyticsData } pageName = {_.startCase(page)}/>;
        }
      case 'getstarted':
        if (pageData?.items?.getStarted) {
          return <AppPageGetStarted getStartedData={pageData?.items?.getStarted} {...subPageProps} analyticsData = { analyticsData } pageName = {_.startCase(page)}/>;
        }
      case 'faqs':
        if (pageData?.items?.faqs) {
          return <AppPageFAQs data={pageData?.items?.faqs} analyticsData={ analyticsData } {...subPageProps} pageName = {_.startCase(page)}/>;
        }
      case 'videos':
        if (pageData?.items?.howTo) {
          return <AppPageVideos videoData={pageData?.items?.howTo} {...subPageProps} analyticsData = { analyticsData } pageName = {_.startCase(page)}/>;
        }
      case 'resources':
        if (pageData?.items?.resources) {
          return <AppPageResources filter={pageData?.items?.resources?.articleCategory} analyticsData={ analyticsData } {...subPageProps} pageName = {_.startCase(page)} />;
        }
      default:
        return <Display404NotFound display />;
    }
  };

  const params = useParams();
  const route = {
    engine: '',
    featSpec: undefined,
    model: '',
    path: params.page || 'overview',
    trim: '',
    year: params.year,
  };
  const splat = params?.page;

  // if the route is '/app', redirect to '/app/overview
  if (!splat) history.push('/app/overview');

  const isValidTabName = () => {
    const validTabs = ['overview', 'getstarted', 'faqs', 'videos', 'resources'];
    return validTabs.includes(splat);
  }

  const getPageDisplay = () => {
    if(params.page === undefined){
      history.push('app/overview');
    }else if (isValidTabName() && pageData) {
      return (
        <SubheaderComponent {...props} route={route} params={params}    
          bookmarkNavClickEvent={(isBookmarked) => appBookmarkClick(analyticsData && analyticsData.clickAnalytics, _.startCase(params.page), isBookmarked)}
          subheaderNavClickEvent={(destinationPage) => appSubheaderClick(analyticsData && analyticsData.clickAnalytics, _.startCase(params.page), destinationPage)}
        >
          {getSubheaderChildComponent(splat)}
        </SubheaderComponent>
      );
    }else{    
      return <Display404NotFound display />;
    }
  };

  return getPageDisplay();
};

export default injectIntl(AppPageRouterComponent);
