/* eslint-disable no-nested-ternary */
/* eslint-disable no-cond-assign */
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { ArticleListTemplate } from './articleList.tpl';
import { getArticleCategories } from '../utils/getArticleCategories';
import { getArticles } from '../../services/articlesService';
import { postUserActivityRecord } from '../../services/userActivityService';
import { getS3PathName } from '../utils/vehicleName';

class ArticleListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeCategoryFilter: 'All',
      categoryFilterOptions: [],
      filteredArticleResults: [],
      recordType: this.props.recordType,
      selectedSortValue: '',
      unfilteredArticleResults: [],
      displayedArticles: [],
      s3Path: '',
    };

    this.getArticleCategories = getArticleCategories.bind(this);
    this.getArticles = getArticles.bind(this);
    this.getArticleList = this.getArticleList.bind(this);
    this.getCategoryFilterOptions = this.getCategoryFilterOptions.bind(this);
    this.onCategoryFilterClick = this.onCategoryFilterClick.bind(this);
    this.onSortSelectionChange = this.onSortSelectionChange.bind(this);
    this.formatArticleListForDisplay = this.formatArticleListForDisplay.bind(this);
    this.setArticleList = this.setArticleList.bind(this);
    this.sortAndFilterArticleResults = this.sortAndFilterArticleResults.bind(this);
    this.getS3Path = this.getS3Path.bind(this);
  }

  async getS3Path () {
    if (this.props.routes && this.props.routes[0].model && this.props.routes[0].year) {
      const s3PathName = await getS3PathName(this.props.routes[0].model);
      const language = 'en';
      const year = this.props.routes[0].year;
      this.setState({
        s3Path: `${year}/${s3PathName}/${language}/`,
      });
    }
  }


  // This function determines which set of categories (MY21+ or MY20-) to display on a given article list page
  getCategoryFilterOptions(record) {
    let categories = this;
    if (record.indexOf('vehicle') > -1) {
      const splitTitle = record.split('-');
      categories = this.getArticleCategories(splitTitle[2]);
    } else if (this.props.shouldDisplayOldCategories) {
      categories = this.getArticleCategories('2020');
    } else {
      categories = this.getArticleCategories('2021');
    }
    return categories;
  }

  componentDidMount() {
    try {
      this.getS3Path();
      postUserActivityRecord();
      this.setState({
        categoryFilterOptions: this.getCategoryFilterOptions(this.props.recordType),
      }, this.formatArticleListForDisplay(this.state.activeCategoryFilter)); // pull initial list of 10 articles
    } catch (error) {
      ArticleListComponent.logError(error, 'componentDidMount');
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.recordType !== prevProps.recordType) {
      try {
        this.setState({
          recordType: this.props.recordType,
        }, this.formatArticleListForDisplay(this.state.activeCategoryFilter));
      } catch (error) {
        ArticleListComponent.logError(error, 'componentDidUpdate');
      }
    }
  }

  formatArticleListForDisplay(activeCategoryFilter) {
    // check to see if currently loaded articles have results for the selected record type
    const hasRecordTypeArticlesToDisplay = ArticleListComponent.filterArticlesByRecordType(this.props.recordType, this.state.unfilteredArticleResults, this.props?.additionalTypes).length > 0;
    const hasCategoryArticlesToDisplay = ArticleListComponent.filterArticlesByCategory(activeCategoryFilter, this.state.unfilteredArticleResults).length > 0;

    if (hasRecordTypeArticlesToDisplay && hasCategoryArticlesToDisplay) {
      const updatedFilteredArticles = this.sortAndFilterArticleResults(activeCategoryFilter, this.state.unfilteredArticleResults);
      this.setState({
        filteredArticleResults: updatedFilteredArticles,
      });
    } else {
      this.getArticleList(this.props.recordType, this.props?.additionalTypes).catch((error) => {
        ArticleListComponent.logError(error, 'formatArticleListForDisplay');
      });
    }
  }

  async getArticleList(resourceType, additionalTypes = []) {
    try {
      this.setState({
        gettingArticles: true,
      });
      const requests = [this.getArticles(resourceType), ...additionalTypes.map(type => this.getArticles(type))];
      const results = await Promise.all(requests);
      const combinedResults = results.map(list => list?.Items).flat();
      this.setArticleList({Items: combinedResults});
    } catch (error) {
      ArticleListComponent.logError(error, 'getArticleList');
    }
  }

  setArticleList(articleData) {
    const allArticleItems = this.state.unfilteredArticleResults ? this.state.unfilteredArticleResults.concat(articleData.Items) : articleData.Items;
    const filteredArticles = this.sortAndFilterArticleResults(this.state.activeCategoryFilter, allArticleItems);
    this.setState({
      unfilteredArticleResults: allArticleItems,
      filteredArticleResults: filteredArticles,
      gettingArticles: false,
    }, () => this.setDisplayedArticles());
  }

  setDisplayedArticles = () => {
    const displayedArticleCount = this.state.displayedArticles.length;
    const filteredArticleCount = this.state.filteredArticleResults.length;
    let displayedArticles;
    if (filteredArticleCount - displayedArticleCount <= 10) {
      displayedArticles = this.state.filteredArticleResults;
    } else {
      displayedArticles = this.state.filteredArticleResults.slice(0, displayedArticleCount + 10);
    }
    this.setState({
      displayedArticles: displayedArticles
    })
  }

  sortAndFilterArticleResults(activeCategoryFilter, articles) {
    const articlesFilteredByCategory = ArticleListComponent.filterArticlesByCategory(activeCategoryFilter, articles);
    const articlesFilteredByCategoryAndRecordType = ArticleListComponent.filterArticlesByRecordType(this.props.recordType, articlesFilteredByCategory, this.props?.additionalTypes);

    // Using the reduce function here will ensure that no vehicle or tag records with duplicate article id's are included in the article list
    const uniqueCategoryRecords = articlesFilteredByCategoryAndRecordType.reduce((prev, current) => {
      const x = prev.find(item => item.id === current.id);
      if (!x) {
        return prev.concat(current);
      }
      return prev;
    }, []);
    return ArticleListComponent.sortList(this.state.selectedSortValue, uniqueCategoryRecords);
  }

  static filterArticlesByCategory(activeCategory, articles) {
    const filteredList = activeCategory === 'All' ? articles : articles.filter((article) => {
      if (this.hasCategoryMatch(article, activeCategory)) {
        return article;
      }
    });
    return filteredList;
  }

  static hasCategoryMatch(article, filter) {
    const recordCategories = article.categories ? (typeof article.categories === 'string' ? JSON.parse(article.categories) : article.categories) : [article.category.toString()];

    const categoryMatch = recordCategories.filter(cat => cat.toLowerCase() === filter.toLowerCase()) || [];
    if (categoryMatch.length > 0) {
      return true;
    }
    return false;
  }


  // don't filter on record type for previous model year resources, as that is a super-set of multiple record types
  static filterArticlesByRecordType(recordType, articles, additionalTypes) {
    if (!additionalTypes) {
      const filteredList = recordType === 'category-previousmodelyearresources-en' ? articles : articles.filter(x => x.recordType.toLowerCase() === recordType.toLowerCase());
      return filteredList;
    }
    return articles;
  }

  static sortList(sortValue, resourceList) {
    const sortedList = resourceList.sort((a, b) => {
      const titleA = a.title.toUpperCase();
      const titleB = b.title.toUpperCase();

      const dateA = Date.parse(a.createdDate);
      const dateB = Date.parse(b.createdDate);

      if (sortValue.toLowerCase() === 'a-z') {
        // eslint-disable-next-line no-nested-ternary
        return titleA < titleB ? -1 : titleA > titleB ? 1 : 0;
      } if (sortValue.toLowerCase() === 'z-a') {
        // eslint-disable-next-line no-nested-ternary
        return titleA < titleB ? 1 : titleA > titleB ? -1 : 0;
      }
      return dateB - dateA;
    });

    return sortedList;
  }

  onCategoryFilterClick(event, filter) {
    const activeCategoryFilter = filter.id;
    const filteredList = this.sortAndFilterArticleResults(activeCategoryFilter, this.state.unfilteredArticleResults);
    this.setState({
      activeCategoryFilter,
      filteredArticleResults: filteredList,
      displayedArticles: []
    }, () => this.setDisplayedArticles());
  }

  onSortSelectionChange(event) {
    const eventValue = event.target.value;
    const sortedResources = ArticleListComponent.sortList(eventValue, this.state.filteredArticleResults);
    const displayedArticles = sortedResources.slice(0,this.state.displayedArticles.length)
    this.setState({
      selectedSortValue: eventValue,
      filteredArticleResults: sortedResources,
      displayedArticles
    });
    this.props.sortClick(event);
  }

  static logError(error, errorRef) {
    // eslint-disable-next-line no-console
    const newError = console.log;
    return newError(`error: ${error} from: ${errorRef}`);
  }

  render() {
    return ArticleListTemplate(this);
  }
}

ArticleListComponent.propTypes = {
  recordType: PropTypes.string.isRequired,
  routes: PropTypes.array,
  sortClick: PropTypes.func.isRequired,
  shouldDisplayOldCategories: PropTypes.bool,
};

export default injectIntl(ArticleListComponent);
