import React, { useState, useEffect } from "react";
import '../../../styles/css/profilePage/profile-page-mobile-nav.css';
import SidebarMenuMobile from "../../sidebarMenu/sidebarMenuMobile";
import AppSettings from "../../../appsettings";
import { useHistory } from "react-router";

const ProfilePageMobileNav = (props) => {
    const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
    const history = useHistory();

    const backButtonClick = () => {
        history.goBack();
        window.scroll({top: 0});
    }

    return (
        <div className='profile-page-mobile-nav'>
            {!mobileMenuOpened ? 
                <div className="profile-open-menu-button" onClick={() => setMobileMenuOpened(true)}>
                    <div className="profile-page-dot"/>
                    <div className="profile-page-dot"/>
                    <div className="profile-page-dot"/>
                </div>
            :
                <img
                    src={AppSettings.AWSImgRepo.resources + 'en/profilePageCloseMenu.png'}
                    alt="Close Menu"
                    onClick={() => setMobileMenuOpened(false)}
                    className="profile-close-menu-button"
                />
            }
            {!mobileMenuOpened &&
                <img 
                    src={AppSettings.AWSImgRepo.resources + 'en/profilePageBackArrow.png'}
                    alt="Back Button"
                    onClick={() => backButtonClick()}
                    className="profile-page-back-button"
                />
            }
            <SidebarMenuMobile 
                isOpened={mobileMenuOpened}
                tabs={props.tabs}
                onClose={() => setMobileMenuOpened(false)}
                currentTab={props.currentTab}
            />
        </div>
    );
};

export default ProfilePageMobileNav;
