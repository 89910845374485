import React from 'react';
import { FormattedMessage } from 'react-intl';
import MarkdownIt from 'markdown-it';
import MarkdownItSup from 'markdown-it-sup';
import dompurify from 'dompurify';
import applyMarkdown from '../../../../utils/applyMarkdown';
import advantageIcon from '../../../../../resources/images/advantage-icon.png';

export default function CostToOwnV2(props) {
  const md = new MarkdownIt().use(MarkdownItSup);
  const TableRowItemsMobile = (props) => {
    const elementsArray = props.item.data && props.item.data.map((cost, index) => (
                <div key={index}>
                    <div className="sm-title">
                        <div dangerouslySetInnerHTML={{ __html: md.renderInline(dompurify.sanitize(cost.featureName)) }} />
                    </div>
                    <div className="sm-col columns large-6 medium-6 small-6">
                      {cost.toyotaAdvantage
                          && <span>
                                <img src={advantageIcon} className="advantage-icon" />
                              &nbsp;
                            </span>}
                      <span dangerouslySetInnerHTML={{ __html: md.renderInline(dompurify.sanitize(cost.toyotaData.replace('(', '(**Advantage of ').replace(')', '**)'))) }} />
                    </div>
                    <div className="sm-col columns large-6 medium-6 small-6 col2">
                      {cost.competitorAdvantage
                          && <span>
                                <img src={advantageIcon} className="advantage-icon" />
                                &nbsp;
                            </span>}
                        <span dangerouslySetInnerHTML={{ __html: md.renderInline(dompurify.sanitize(cost.competitorData)) }} />
                    </div>
                    <div className="sm-line columns large-12 medium-12 small-12"></div>
                </div >
    ));
    return <div>{elementsArray}</div>;
  };

  const TableRowItemsDesktop = (props) => {
    const elementsArray = props.item.data && props.item.data.map((cost, index) => (
                <tr className="lg-row" key={index}>
                    <td className="lg-title">
                        <div dangerouslySetInnerHTML={{ __html: md.renderInline(dompurify.sanitize(cost.featureName)) }} />
                    </td>
                    <td className="lg-col">
                      {cost.toyotaAdvantage
                          && <span>
                                <img src={advantageIcon} className="advantage-icon" />
                                &nbsp;
                        </span>}
                        <span dangerouslySetInnerHTML={{ __html: md.renderInline(dompurify.sanitize(cost.toyotaData.replace('(', '(**Advantage of ').replace(')', '**)'))) }} />
                    </td>
                    <td className="lg-col">
                        {cost.competitorAdvantage
                          && <span>
                                <img src={advantageIcon} className="advantage-icon" />
                                &nbsp;
                            </span>}
                        <span dangerouslySetInnerHTML={{ __html: md.renderInline(dompurify.sanitize(cost.competitorData)) }} />
                    </td>
                </tr>
    ));
    return <tbody>{elementsArray}</tbody>;
  };

  const costToOwnTableDesktop = [];
  props.content.costToOwn.specs.forEach((item) => {
    costToOwnTableDesktop.push(
            <table key={item.name} className="cost-to-own-table-desktop">
                <caption className="table-row-subheader" >
                    {applyMarkdown(item.header, props.disclaimer)}
                </caption>
                <TableRowItemsDesktop item={item} />
            </table>,
    );
  }, this);

  const costToOwnTableMobile = [];
  props.content.costToOwn.specs.forEach((item) => {
    costToOwnTableMobile.push(
            <div key={item.name} className="cost-to-own-table-mobile">
                <div className="table-row-subheader-mobile-v2" >
                    {applyMarkdown(item.header, props.disclaimer)}
                </div>
                <TableRowItemsMobile item={item} />
            </div>,
    );
  }, this);

  return (
        < div className="new-cost-to-own" >
            <div className="cto-body">
                <div className="cto">
                  <div className="row" />
                  <div className="show-for-large">{costToOwnTableDesktop}</div>
                  <div className="show-for-small-only">{costToOwnTableMobile}</div>
                  <div className="show-for-medium-only">{costToOwnTableMobile}</div>
                </div>
            </div>
            <div className="advantage-footnote-container">
                <img src={advantageIcon} className="advantage-icon" />
                &nbsp;
                    <span>Advantage</span>
            </div>
            <div className="cto-permanent-footnote">
                <FormattedMessage id="caHeadToHead.permanentFootNote" defaultMessage="* Values estimated at time of publication and subject to change without notice." />
            </div>
        </div >
  );
}
