import React, {useState, useEffect} from "react";
import "../../../../styles/css/beyondZero/bzVideoSection.css"
import VideoPlayerComponent from "../../../videoPlayer/videoPlayer.component";
import BZTabs from "../../sharedComponents/bzTabs";

const BZVideoSection = ({ data, analyticsActions }) => {
    const [tabSelected, setTabSelected] = useState(0);
    const [tabList, setTabList] = useState([]);

    const handleTabOnChange = (tabIndex) => {
        setTabSelected(tabIndex);
    
        analyticsActions.onTabClick({
          label: 'Beyond Zero Videos Tab',
          content_title: tabList[tabIndex].label
        })
    } 

    useEffect(() => {
        const formattedData = data?.tabs?.map((e,index) => {
            return {
                value: index,
                label: e.tabName,
                description: e.tabText,
                qumuId: e.qumuId
            }
        });
        setTabList(formattedData);
    }, [data]);

    return(
        <div className="bz-video-section">
            <div className="bz-video-content">
                <div className="bz-video-text">
                    {data?.title}
                </div>

                <div className="bz-bottom-container">
                    <VideoPlayerComponent
                        key={data?.tabs[tabSelected].qumuId}
                        item={{
                            qumuId: data?.tabs[tabSelected].qumuId,
                            hideCTA: true
                        }} 
                    />

                    <BZTabs
                        active={tabSelected}
                        onClick={handleTabOnChange}
                        items={tabList}
                        gap="50px"
                        hasScroll
                    />
                </div>
            </div>
        </div>
    );

};

export default BZVideoSection;