import 'whatwg-fetch';
import httpOption from './httpOption';
import AppSettings from '../appsettings';

export function getProfile() {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/profiles`;
  const options = httpOption.GET();
  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return resolve(response.json());
        }
        return reject(response);
      });
  });
}

export function getTriviaProfile(spinId) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/profiles/getTriviaProfile/${spinId}`;
  const options = httpOption.GET();
  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return resolve(response.json());
        }
        return reject(response);
      });
  });
}

export function getDealershipUsers(dealerCode) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/profiles/users/${dealerCode}`;
  const options = httpOption.GET();
  return fetch(url, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
      }
      console.log(`Error getting dealership users: ${response}`);
      return Promise.reject(response);
    });
}


export function getRegionalDealerships(regionCode) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/profiles/region/dealership/${regionCode}`;
  const options = httpOption.GET();
  return fetch(url, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(response);
    });
}

export function getDealership(dealerCode) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/profiles/dealership/${dealerCode}`;
  const options = httpOption.GET();

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return resolve(response.json());
        }
        console.log(`Error getting dealership name: ${response}`);
        return reject(response);
      });
  });
}

export function postUserProfileImage(file) {
  if (file == null) {
    return Promise.resolve();
  }

  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/profiles/image`;
  const options = httpOption.POSTFILE();

  const formData = new FormData();
  formData.append('userfile', file);
  options.body = formData;
  
  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          resolve(response.json());
        } else {
          reject(reject);
        }
      });
  });
}

export function postUserData(userData) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/profiles/`;
  const options = httpOption.POST();
  options.body = JSON.stringify({
    userData,
  });

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          resolve(response.json());
        } else {
          reject(reject);
        }
      });
  });
}

export function postUserVehicleSpotlight(vehicles) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/profiles/vehicleSpotlight`;
  const options = httpOption.POST();

  const spotlightVehicles = [];
  for (let i = 0; i < vehicles.length; i++) {
    spotlightVehicles.push({
      model: vehicles[i].model,
      year: vehicles[i].year.toString(),
      image: vehicles[i].image,
    });
  }

  options.body = JSON.stringify({
    spotlightVehicles,
  });

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          resolve(response.json());
        } else {
          reject(reject);
        }
      });
  });
}
