import { createCookie } from '../screens/utils/cookie';

if (window.localStorage.getItem('locale') === null) {
  window.localStorage.setItem('locale', 'en');
}

export function getLocale() {
  return window.localStorage.getItem('locale');
}

export function setLocale(language) {
  if (language !== getLocale()) {
    switch (language) {
      case 'en':
        createCookie('lang', language);
        window.localStorage.setItem('locale', 'en');
        break;
      case 'es':
        createCookie('lang', language);
        window.localStorage.setItem('locale', 'es');
        break;
      default:
        createCookie('lang', language);
        window.localStorage.setItem('locale', 'en');
        break;
    }
  }

  return getLocale();
}
