import React from 'react';
import AppSettings from '../../appsettings.js';
import '../../styles/css/profileList.css';
import { getProfileImageUrl } from '../utils/profileImageUrl.js';

export function ProfileTile(props) {
  return (
    <div className="profile-tile">
      <a href={`/profile/${props.user.id}/accountSettings`}>
        <div className="profile-tile-image" style={{ backgroundImage: `url('${getProfileImageUrl()}')` }}></div>
      </a>
      <div className="profile-tile-summary-text">
        <div className="profile-tile-name"><a href={`/profile/${props.user.id}/accountSettings`}>{getUserName(props.user)}</a></div>
        <div className="redline"></div>
        <div className="profile-tile-job-code">{AppSettings.JobCode[getJobCode(props.user)]}</div>
        <div className="profile-tile-bio-inline">{getBio(props.user)}</div>
      </div>
      <div className="profile-tile-bio-bottom">{getBio(props.user)}</div>
    </div>
  );
}

function getUserName(user) {
  const firstName = user.firstName ? user.firstName : '';
  const lastName = user.lastName ? user.lastName : '';
  return `${firstName} ${lastName}`;
}

function getBio(user) {
  return user.bio ? user.bio : '';
}

function getJobCode(user) {
  let jobcode = user.primaryJobCode ? user.primaryJobCode : '0';

  if (user.isCorporate) {
    jobcode = '0';
  }

  return jobcode;
}
