import { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import * as _ from 'lodash';
import { FeaturesTemplate } from './features.tpl';
import { postUserActivityRecord } from '../../services/userActivityService.js';

export class FeaturesComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      featuresItems: this.props.featuresData ? this.props.featuresData : [],
    };
    this.readMoreClick = this.readMoreClick.bind(this);
  }

  readMoreClick(category, index) {
    this.props.readMoreClick(category, this.state.featuresItems[index].title);
  }

  updateFeatureItems() {
    this.setState({
      featuresItems: this.props.featuresData,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.shouldUpdateState(prevProps)) {
      this.updateFeatureItems();
    }
  }

  shouldUpdateState(prevProps) {
    if (prevProps.featuresData) {
      return !_.isEqual(prevProps.featuresData, this.props.featuresData);
    }

    return false;
  }

  componentDidMount() {
    this.updateFeatureItems();
    postUserActivityRecord();
  }

  render() {
    return FeaturesTemplate(this);
  }
}

FeaturesComponent.propTypes = {
  featuresData: PropTypes.object,
  readMoreClick: PropTypes.func,
};

export default injectIntl(FeaturesComponent);
