import { GlobalAnalytics } from '../../../analytics';

const analytics = new GlobalAnalytics();


export function sendArticleTagClick(eventName) {
  analytics.fireOnClick({
    tag: '35.2',
    section: 'Product',
    subsection: 'Featured Technology',
    mediaTitle: eventName,
    technologyName: 'Toyota Safety Sense™',
    page: 'Resources',
    category: 'Resource Articles',
    contentTitle: eventName,
  });
}

export function resourceClick(resource) {
  analytics.fireOnClick({
    tag: '35.2',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'Toyota Safety Sense™',
    page: 'Resources',
    category: resource.recordType,
    contentTitle: resource.title,
    mediaTitle: resource.title,
  });
}

export function filterClick(event) {
  analytics.fireOnClick({
    tag: '35.2',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'Toyota Safety Sense™',
    page: 'Resources',
    category: event.target.innerText,
    contentTitle: 'Show',
    mediaTitle: event.target.innerText,
  });
}

export function sortClick(event) {
  analytics.fireOnClick({
    tag: '35.2',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'Toyota Safety Sense™',
    page: 'Resources',
    category: event.target.value,
    contentTitle: 'Show',
    mediaTitle: event.target.value,
  });
}

export function sendPageLoadAnalytics() {
  analytics.firePageLoad({
    tag: '35.1',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'Toyota Safety Sense™',
    page: 'Resources',
  });
}
