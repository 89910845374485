import { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { NitroContext } from "../../components/contexts/nitroContext";
import BackButton from "../../components/molecules/BackButton";
import "../../styles/css/engageXPStats.css";
import Badges from "./components/badges";
import DashboardImageCard from "./components/dashboardImageCard";
import Leaderboard from "./components/leaderboard";
import MyStatsInfoCard from "./components/myStatsInfoCard";
import { engageStatsPageLoad, backButtonClick } from "./engageXPStats.analytics";
import { postViewPageAction } from "../../services/nitroService";

const EngageXpStats = ({ missions, leaderboardConfig, leaderboardData, pageData, isLoadingLeaderboard, isLoadingLeaderboardDealerships, leaderboardSelection, setLeaderboardSelection, analyticsData }) => {
  const { nitroUserXPLevel } = useContext(NitroContext);
  const history = useHistory();
  const analyticsPage = "Engage XP";
  const analyticsCategory = "My Stats";
  const clickAnalytics = analyticsData?.clickAnalytics;

  useEffect(() => {
    if (analyticsData) {
        postViewPageAction(window.location.href);
        engageStatsPageLoad(analyticsData?.pageLoad?.myStats)
        engageStatsPageLoad();
    }
}, [analyticsData]);

const handleBackButtonClick = () => {
  if (analyticsData) {
    backButtonClick(clickAnalytics, analyticsPage, analyticsCategory);
  }
  history.push("/engage-xp/missions");
}

  return (
    <div className="my-stats-page">
      <div className="grid-stats-header">
        <BackButton onClick={handleBackButtonClick} />
        <span>{pageData.stats.title ?? ''}</span>
      </div>
      <div className="grid-stats-progress">
        <MyStatsInfoCard
          currentLevel={nitroUserXPLevel?.nitroCurrentLevel ?? ''}
          nextLevel={nitroUserXPLevel?.nitroNextLevel ?? ''}
          pointsNeeded={nitroUserXPLevel?.nitroPointsNeeded ?? ''}
          currentLevelPoints={nitroUserXPLevel?.nitroCurrentLevelPoints ?? ''}
          userPoints={nitroUserXPLevel?.nitroUserPoints ?? ''}
          moreInfoText={pageData?.dashboard?.moreInfoText ?? ''}
          moreInfoLink={"/engage-xp/missions/how-do-points-work"}
          analyticsData={analyticsData}
        />
      </div>
      <div className="grid-stats-banner">
        <DashboardImageCard
          desktopImageName={pageData?.stats?.engageHubDesktopImage}
          tabletImageName={pageData?.stats?.engageHubTabletImage}
          mobileImageName={pageData?.stats.engageHubMobileImage}
          page="stats"
          imageTitle={pageData?.dashboard?.imageTitle}
          imageLargeText={pageData?.dashboard?.imageLargeText}
          imageLinkText={pageData?.dashboard?.imageLinkText}
          imageLink={pageData?.dashboard?.checkMyBalanceUrl}
          analyticsData={analyticsData}
        />
      </div>
      <div className="grid-stats-leaderboard">
        <Leaderboard
          type="full"
          pageData={pageData}
          leaderboardConfig={leaderboardConfig}
          items={leaderboardData} 
          isLoadingLeaderboard={isLoadingLeaderboard}
          isLoadingLeaderboardDealerships={isLoadingLeaderboardDealerships}
          leaderboardSelection={leaderboardSelection}
          setLeaderboardSelection={setLeaderboardSelection}
          analyticsData={analyticsData}
        />
      </div>
      <div className="grid-stats-badges">
        <Badges
          pageData={pageData}
          missions={missions}
          analyticsData={analyticsData}
        />
      </div>
    </div>
  );
};

export default EngageXpStats;
