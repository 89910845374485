import { Component } from 'react';
import PropTypes from 'prop-types';
import { postUserActivityRecord } from '../../services/userActivityService';
import { ModelOverviewTemplate } from './modelOverview.tpl';
import getVehicleOverview from '../../services/vehicleOverviewService';
import { getDynamoName } from '../utils/vehicleName';

const analytics = require('./modelOverview.analytics');

export default class ModelOverviewComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      year: this.props.params.year,
      model: this.props.params.model,

    };

    this.postUserActivityRecord = postUserActivityRecord.bind(this);
    this.getVehicleOverview = getVehicleOverview.bind(this);
  }

  componentDidMount() {
    analytics.fireModelOverviewPageLoad(this.props.params.model, this.props.params.year);
    this.postUserActivityRecord();
    if (this.state.year && this.state.model) {
      this.getVehicleOverview(getDynamoName(this.state.model), this.state.year, false, 'en')
        .then((data) => {
          if (data) {
            this.setState({
              overviewData: data,
            });
          }
        });
    }
  }

  render() {
    return ModelOverviewTemplate(this);
  }
}

ModelOverviewComponent.propTypes = {
  params: PropTypes.object.isRequired,
};
