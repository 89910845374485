import React, {useEffect, useContext} from 'react';
import AppSettings from "../../../appsettings";
import RoundedButton from '../../../components/molecules/roundedButton';
import { triviaStartPageLoad, playNowClick } from './triviaModal.analytics';
import { postViewPageAction } from '../../../services/nitroService';
import '../../../styles/scss/engageXP/triviaModal.scss';
import { NitroContext } from '../../../components/contexts/nitroContext';

const imagePath = AppSettings.AWSImgRepo.nitroOverview;

const StartTrivia = (props) => {
    
    useEffect(() => {
        if (props.analyticsData && props?.description) {
            const quizName = "Trivia Start - " + props?.quizNameAnalytics;
            postViewPageAction(window.location.href);
            triviaStartPageLoad(props.analyticsData.pageLoad?.triviaStart, quizName);
        }
    }, [props.analyticsData, props.description]);

    const handlePlayNowClick = () => {
        if (props.analyticsData) {
            const clickAnalytics = props?.analyticsData?.clickAnalytics;
            const label = props?.description + " - Play Now";

            playNowClick(clickAnalytics, label);
        }
        props.startQuiz();
    }

    const { nitroLoadingState } = useContext(NitroContext);
    return (
        <div className="trivia-content trivia-start-content modalScrollArea">
            <div className="innerScrollArea">
                <div className="trivia-image"><img src={props.image || imagePath + "start-trivia.png"} /></div>
                {props.description && <div className="trivia-description">{props.description}</div>}
                <div className="trivia-button">
                    <RoundedButton
                        title="Play Now"
                        className="customColor bold"
                        color="#000"
                        textColor="#fff"
                        height="44px"
                        onClick={handlePlayNowClick}
                        disabled={nitroLoadingState}
                    />
                </div>
            </div>
        </div>
    )

}

export default StartTrivia;
