import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import '../../styles/css/smartpath-overview.css';
import getAnalytics from '../../services/analyticsService';
import DisclaimersComponent from '../disclaimers/disclaimers.component';
import { SmartPathOverviewIntro } from './overviewSubSections/smartpath-overview-intro.js'
import { SmartPathOverviewSections } from './overviewSubSections/smartpath-overview-sections.js'
import SPContactUsComponent from './spContactUs.component';
import * as analytics from './smartpath-overview.analytics';
import * as introAnalytics from './overviewAnalytics/smartpath-overview-intro.analytics'
import useOnScreen from './useIntersection';

export const SmartPathOverview = (props) => {
  const [analyticsData, setAnalyticsData] = useState(null);
  const disclaimerRef = useRef();

  const getSmartPathAnalytics = async () => {
    const data = await getAnalytics('smartPath');
    setAnalyticsData(data);
    if (data && data.pageLoad) {
      analytics.overviewLoaded(data.pageLoad);
    }
  };

  const contactRef = useRef(null);
  if (useOnScreen(contactRef) && analyticsData && analyticsData.pageLoad) {
    analytics.contactUsSubLoad(analyticsData.pageLoad);
  }

  const whySmartPathRef = useRef();
  const whatsIncludedRef = useRef();
  const supportTrainingRef = useRef();
  const LetsGetStarted = useRef();
  const buttonRefrenceList = [whySmartPathRef,whatsIncludedRef,supportTrainingRef,LetsGetStarted];

  const handleQuickNavClick = (section, item) => {
    if (analyticsData && section) {
      introAnalytics.quickNav(analyticsData.clickAnalytics, section.header);
    }
    buttonRefrenceList[item].current.scrollIntoView({ behavior: "smooth"});
  }
  
  const buttons = props.overviewData.sections.map((section,item) =>(
    <button className = "smartpath-overview-intro-buttons"
    onClick={() => handleQuickNavClick(section, item)} 
    >
    {section.header}
    </button>  
));

  useEffect(() => {
    getSmartPathAnalytics();
  }, []);

  return <div className="smartpath-overview-page">
    <SmartPathOverviewIntro introData = {props.overviewData.introduction} buttons = {buttons} imagePath = {props.imagePath} clickAnalytics={analyticsData && analyticsData.clickAnalytics} />
    <SmartPathOverviewSections overviewData = {props.overviewData} imagePath = {props.imagePath} refrence ={buttonRefrenceList} clickAnalytics={analyticsData && analyticsData.clickAnalytics} pageLoadAnalytics={analyticsData && analyticsData.pageLoad} />
    <div ref={contactRef}><SPContactUsComponent data = {props.overviewData.subSections.contactUs} clickAnalytics={analyticsData && analyticsData.clickAnalytics} pageLoadAnalytics={analyticsData && analyticsData.pageLoad} /></div>
    <DisclaimersComponent template={props.disclaimer} ref={(instance) => { disclaimerRef.current = instance; }} />
  </div>;
};

SmartPathOverview.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  qumuId: PropTypes.string,
  sections: PropTypes.array,
  overviewData: PropTypes.object,
  disclaimer: PropTypes.string,
};