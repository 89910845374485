import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import AppSettings from '../../appsettings.js';
import '../../styles/css/featureLookupModelResultCard.css';
import { Accordion } from '../../components/molecules/Accordion';
import { TooltipV2 } from '../../components/molecules/tooltipV2';
import DropdownV2 from '../../components/molecules/DropdownV2.js';
import { isDesktopView, isMobileView } from '../utils/getScreenSize';
import { getUrlModelName } from '../utils/vehicleName';
import DisclaimersComponent from '../disclaimers/disclaimers.component.js';
import {
    featureLookupResultsAddVehicleFeatureClick,
    featureLookupResultCardSeeMoreLessClick,
    featureLookupResultCardYearSelect,
    featureLookupResultCardMsrpTooltipShowHide,
    featureLookupResultCardFeatureTooltipShowHide,
    featureLookupResultCardTrimExpandCollapseClick
} from './featureLookup.analytics';

const ModelResultCard = (props) => {
    const [expanded, setExpanded] = useState(false);
    const [tooltipsToShow, setTooltipsToShow] = useState([]);
    const [modelNameForJellies, setModelNameForJellies] = useState('');

    const activeTooltipRef = useRef();

    const featureRef1 = useRef();
    const featureRef2 = useRef();
    const featureRef3 = useRef();
    const disclaimerComponentRef = useRef();

    const featuresRefList = [featureRef1, featureRef2, featureRef3];

    const resourcesFilePath = AppSettings.AWSImgRepo.resources + 'en/';
    const vehicleOverviewPath = AppSettings.cloudFrontURL + '/product/';

    const getmodelNameForJellies = useCallback(async () => {
        const jellyFilePath = AppSettings.AWSImgRepo.jellybeans;
        setModelNameForJellies(jellyFilePath + 'front/' + props.modelName.replace(" ", "_") + '.png');
    }, [props.modelName])

    useEffect(() => {
        getmodelNameForJellies();
    }, [getmodelNameForJellies])

    useEffect(() => {
        const handleOutsideClick = (event) => {
          if (activeTooltipRef.current && !activeTooltipRef.current.contains(event.target)) {
            props.setVisiblePopupTooltip(null);
          }
        }

        // Adding click event listener
        document.addEventListener("click", handleOutsideClick);
        return () => document.removeEventListener("click", handleOutsideClick);
      }, [activeTooltipRef]);

    useLayoutEffect(() => {
        let tooltips = featuresRefList.map(r => r.current?.clientWidth < r.current?.scrollWidth);
        setTooltipsToShow(tooltips);
    }, [featureRef1, featureRef2, featureRef3, props.modelData, props.visible]);

    const showHideMSRPTooltipClick = (modelCode, grade, msrp, show) => {
        const label = show ? props.analyticsData.clickAnalytics?.results.msrpIconLabel : props.analyticsData.clickAnalytics?.results.msrpHideLabel;
        featureLookupResultCardMsrpTooltipShowHide(props.analyticsData.clickAnalytics, label, msrp, props.vehicleModelNameYear, grade, props.modelName, props.activeYear, modelCode);
    }

    const showHideFeatureTooltipClick = (feature, show) => {
        const label = show ? props.analyticsData.clickAnalytics?.results.featureTooltipLabel : props.analyticsData.clickAnalytics?.results.featureTooltipHideLabel;
        featureLookupResultCardFeatureTooltipShowHide(props.analyticsData.clickAnalytics, label, feature, props.vehicleModelNameYear);
    }

    const trimExpandCollapseClick = (isExpand, data) => {
        const label = isExpand ? props.analyticsData.clickAnalytics?.results.modelExpandLabel : props.analyticsData.clickAnalytics?.results.modelCollapseLabel;
        featureLookupResultCardTrimExpandCollapseClick(props.analyticsData.clickAnalytics, label, props.vehicleModelNameYear, data.grade, props.modelName, props.activeYear, data.modelCode);
    }

    const getFeatureAvailabilityTable = () => {
        let emptyCells = new Array(3-props.features.length).fill();

        const tableHeader = <tr className="featureNamesHeaderRow">
            <th></th>
            <th></th>
            <th></th>
            {props.features?.map((i, index) =>
                <>
                    <th className="featureNameCell" ref={props.checkCurrentTooltipMatch(`${i}.${props.modelName}.${props.activeYear}`) ? activeTooltipRef : null} onMouseEnter={()=>isDesktopView(window.innerWidth) && props.setVisiblePopupTooltip(`${i}.${props.modelName}.${props.activeYear}`)} onMouseLeave={()=>isDesktopView(window.innerWidth) && props.setVisiblePopupTooltip(null)}>
                        {tooltipsToShow[index] && props.checkCurrentTooltipMatch(`${i}.${props.modelName}.${props.activeYear}`) && <div className="featureTooltip">
                            <TooltipV2
                                closeTooltip={!isDesktopView(window.innerWidth) ? () => props.setVisiblePopupTooltip(null) : null}
                                title={i}
                                analyticsOpenTooltip = {() => showHideFeatureTooltipClick(i, true)}
                                analyticsCloseTooltip = {()=> showHideFeatureTooltipClick(i, false)}
                            />
                        </div>}
                        <div ref={featuresRefList[index]} className="featureText" onClick={()=>!isDesktopView(window.innerWidth) && props.setCurrentPopupTooltip(`${i}.${props.modelName}.${props.activeYear}`)}>{i}</div>
                    </th>
                </>

            )}
            {emptyCells.map(i =>
                <th className="featureNameCell">
                    <div className="addFeature" onClick = {() => addFeature()}>Add Feature</div>
                </th>
            )}
        </tr>
        let tableRows = props.modelData.map((i) =>
            <tr className="modelRow">
                <td className="modelCodeCell">{i.modelCode}</td>
                <td className="msrpCell">
                    <img
                        className='msrpTagImage'
                        src={resourcesFilePath + 'msrp_tag.svg'}
                        ref={props.checkCurrentTooltipMatch(`${i.modelCode}.${i.grade}`) ? activeTooltipRef : null}
                        onMouseEnter={()=>isDesktopView(window.innerWidth) && props.setVisiblePopupTooltip(`${i.modelCode}.${i.grade}`)}
                        onMouseLeave={()=>isDesktopView(window.innerWidth) && props.setVisiblePopupTooltip(null)}
                        onClick={()=>!isDesktopView(window.innerWidth) && props.setCurrentPopupTooltip(`${i.modelCode}.${i.grade}`)}
                    />
                    <sup className="disclaimer-link" onClick={() => disclaimerComponentRef.current.onOpenModal()}>&dagger;</sup>
                    {props.checkCurrentTooltipMatch(`${i.modelCode}.${i.grade}`) && <div className="msrpTooltip" onClick={(e) => e.stopPropagation()}>
                            <TooltipV2
                                closeTooltip={!isDesktopView(window.innerWidth) ? () => props.setVisiblePopupTooltip(null) : null}
                                title={getMSRPToolTip(i.msrp)}
                                analyticsOpenTooltip = {() => showHideMSRPTooltipClick(i.modelCode, i.grade, i.msrp, true)}
                                analyticsCloseTooltip = {() => showHideMSRPTooltipClick(i.modelCode, i.grade, i.msrp, false)}
                            />
                        </div>}
                </td>
                <td className="trimCell">{i.grade}</td>
                {props.features?.map(j => {
                    let feature = i.features.find(f => f.featureName == j);
                    let availability = feature ? feature.availability : "–"
                    return (
                        <td className='availabilityCell'>
                            <div className='circleContainer'>
                                <div className={`spec-circle ${availability == "–" ? "unavailable" : availability}`}>
                                    {availability}
                                </div>
                            </div>
                        </td>
                    )
                })}
                {emptyCells.map(i =>
                    <td className="availabilityCell">
                        <div className='circleContainer'>
                            <div className="spec-circle unavailable">–</div>
                        </div>
                    </td>
                )}
            </tr>
        );

        if(tableRows.length > 5 && !expanded) {
            tableRows = tableRows.slice(0,5);
        } else if (tableRows.length <= 5 && !expanded) {
            setExpanded(true);
        }


        return <table className={`featureAvailabilityTable desktop-tablet ${expanded && "expanded"}`}>
            {tableHeader}
            {tableRows}
        </table>
    }

    const getMSRPToolTip = (msrp) => {
        if(msrp){
            let value = isNaN(msrp) ? msrp : `$${Intl.NumberFormat().format(msrp)}`
            return(
                <div>
                    <div className='msrpTitle'>Base MSRP</div>
                    <div className='msrpPrice'>{value}</div>
                </div>
            );
        }else{
            return(
                <div className='msrpTitle'>Coming Soon</div>
            );
        }
    }

    const addFeature = () => {
        props.setPage('Overview');
        featureLookupResultsAddVehicleFeatureClick(props.analyticsData.clickAnalytics, props.vehicleModelNameYear);
    }

    const seeMoreLess = () => {
        const label = expanded ? props.analyticsData.clickAnalytics?.results.seeLessLabel : props.analyticsData.clickAnalytics?.results.seeMoreLabel;
        featureLookupResultCardSeeMoreLessClick(props.analyticsData.clickAnalytics, props.vehicleModelNameYear, label);
        setExpanded(!expanded);
    }

    const buildAccordionRowData = () => {
        let emptyCells = new Array(3-props.features.length).fill();

        let featureAvailabilityTable = (i) => (
            <table className={`featureAvailabilityTable mobile`}>
                {props.features?.map(j => {
                    let feature = i.features.find(f => f.featureName == j);
                    let availability = feature ? feature.availability : "–";

                    return <tr className="featureAvailabilityRow">
                        <td className="featureNameCell">{j}</td>
                        <td className='availabilityCell'>
                            <div className='circleContainer'>
                                <div className={`spec-circle ${availability == "–" ? "unavailable" : availability}`}>
                                    {availability}
                                </div>
                            </div>
                        </td>
                    </tr>
                })}
                {emptyCells.map((e,index) => {
                    const emptyIndex = props.features.length + index + 1;
                    return (
                        <tr className="featureAvailabilityRow">
                            <td className="addFeatureCell">
                                <div onClick={() => addFeature()}>Add Feature</div>
                            </td>
                        </tr>
                    )
                })}
            </table>

        );


        return props.modelData.map((i) => (
            {
                title:
                    <div className="modelCodeTrimTitle">
                        <div className="modelCode">{i.modelCode}</div>
                        <div className="msrpCell">
                            <img
                                className='msrpTagImage'
                                src={resourcesFilePath + 'msrp_tag.svg'}
                                ref={props.checkCurrentTooltipMatch(`${i.modelCode}.${i.grade}`) ? activeTooltipRef : null}
                                onClick={(e) => onMSRPClick(e, i)}
                            />
                            <sup className="disclaimer-link" onClick={() => disclaimerComponentRef.current.onOpenModal()}>&dagger;</sup>
                            {props.checkCurrentTooltipMatch(`${i.modelCode}.${i.grade}`) && <div className="msrpTooltip" onClick={(e) => e.stopPropagation()}>
                                <TooltipV2
                                    closeTooltip={() => props.setVisiblePopupTooltip(null)}
                                    title={getMSRPToolTip(i.msrp)}
                                    analyticsOpenTooltip = {() => showHideMSRPTooltipClick(i.modelCode, i.grade, i.msrp, true)}
                                    analyticsCloseTooltip = {() => showHideMSRPTooltipClick(i.modelCode, i.grade, i.msrp, false)}
                                />
                            </div>}
                        </div>
                        <div className="trim">{i.grade}</div>
                    </div>,
                body: featureAvailabilityTable(i),
                key: i.order,
                rowAnalyticsData: {modelCode: i.modelCode, grade: i.grade}
            }
        ));
    }

    function onMSRPClick (e, i){
        e.stopPropagation();
        !isDesktopView(window.innerWidth) && props.setCurrentPopupTooltip(`${i.modelCode}.${i.grade}`);
    }

    const getFeatureAvailabilityAccordion = () => {
        let accordionRows = buildAccordionRowData();

        if(accordionRows.length > 4 && !expanded) {
            accordionRows = accordionRows.slice(0,4);
        } else if (accordionRows.length <= 4 && !expanded) {
            setExpanded(true);
        }

        return <Accordion
            rows={accordionRows}
            className={`featureAvailabilityAccordion ${expanded && "expanded"}`}
            termAnalyticsClick={(term, expand, data)=>trimExpandCollapseClick(expand, data)}
        />

    }

    const selectYear = (year) => {
        props.updateActiveYear(year);
        featureLookupResultCardYearSelect(props.analyticsData.clickAnalytics, year, props.vehicleModelNameYear)
    }

    return (
        <div className ="featureLookupModelResultCard">
            <div className="featureLookupModelResultMainContent">
                <div className="selectorContainer">
                    <div className="vehicleImage-yearSelector">
                        <a href = {vehicleOverviewPath + getUrlModelName(props.modelName) + '/' + props.activeYear + '/modelOverview'} >
                        <img className="angledJelly" src={modelNameForJellies} alt="Vehicle"/>
                        </a>
                        <div className="yearDropdown">
                            <DropdownV2
                                style="black-red"
                                options={props.yearOptions}
                                value={props.activeYear.toString()}
                                onValueChange={y=>selectYear(y.value)}
                            />
                        </div>
                    </div>
                </div>
                {props.modelData && (!isMobileView(window.innerWidth) ? getFeatureAvailabilityTable() : getFeatureAvailabilityAccordion())}
            </div>
            <div className="seeMoreLessContainer">
                {props.modelData?.length > 5 &&
                    <div className="seeMoreLess" onClick={() => seeMoreLess()}>{`SEE ${expanded ? 'LESS' : 'MORE'}`}</div>
                }
            </div>
            <DisclaimersComponent
                ref={disclaimerComponentRef}
                addBaseMSRPDisclaimer={true}
            />
        </div>
    )
}

export default ModelResultCard;