import React from "react";
import "../../../../styles/css/beyondZero/selectionCard.css"
import appsettings from "../../../../appsettings";

const SelectionCard = ({image, title, onClick, isSelected}) => {
    const handleClick = () => {
        onClick ();
    }; 

    return (
        <div
            className={`bz-selection-card ${
                isSelected ? "bz-selected-outline" : undefined
            }`}
            onClick={handleClick}
        >
            <img className="bz-selection-card-pic"
                alt="selection card"
                draggable="false"
                src={`${appsettings.AWSImgRepo.beyondZero}${image}`}
            />
            {title}
        </div>
    );
};

export default SelectionCard;