import { isMobileView, isTabletView } from "../utils/getScreenSize";
import * as _ from "lodash";
import "../../styles/scss/model-overview-v2/hero.scss";
import { IncentivesButton } from "./incentivesButton";
import DropdownV2 from "../../components/molecules/DropdownV2";
import AppSettings from "../../appsettings";
import { useEffect, useMemo, useState, useRef } from "react";
import PropTypes from "prop-types";
import formatMSRP from "../utils/formatMSRP";
import { formatModelListItemName, getFormattedName } from "../utils/vehicleName";
import Skeleton from "react-loading-skeleton";
import applyMarkdown from "../utils/applyMarkdown";
import DisclaimersComponent from "../disclaimers/disclaimers.component";

import * as analyticsFunction from "./modelOverviewHero.analytics";

export const ModelOverviewHero = (props) => {
  ModelOverviewHero.propTypes = {
    trims: PropTypes.any,
    data: PropTypes.any,
    style: PropTypes.any,
    incentivesFunction: PropTypes.any,
    model: PropTypes.any,
    year: PropTypes.any
  };

  const { trims = [] } = props;
  const disclaimerComponentRef = useRef();

  const uniqueTrims = useMemo(() => {
    return _.uniqBy(props.trims, "name");
  }, [props.trims]);

  const [msrpFilters, setMsrpFilters] = useState({
    code: null,
    model: null,
  });

  const currentMsrp = useMemo(() => {
    const msrpValue = props.trims?.find(
      (trim) => trim?.modelCode === msrpFilters?.model
    )?.msrp;
    return msrpValue ? `${formatMSRP(msrpValue)} Base MSRP` : "Base MSRP Coming Soon";
  }, [msrpFilters?.model, props.trims]);

  const uniqTrimCodeOptions = useMemo(() => {
    return uniqueTrims?.map((trim) => ({
      label: trim?.name,
      value: trim?.name,
    }));
  }, [uniqueTrims, props.trims]);

  const trimMsrpOptions = useMemo(() => {

    const powertrainCategories = props.trims && new Set(props?.trims?.map(obj => obj?.powertrainCategory));
    const multiplePowerTrainGroups = props.trims && powertrainCategories.size !== 1 ? true : false;

    const formattedTrims = (props?.trims || [])
      ?.filter((trim) => trim?.name === msrpFilters?.code)
      ?.map((trim) => ({
        value: trim?.modelCode,
        label: (
          <div className="dropdown-model-code-option">
            <span>{formatModelListItemName(trim?.fullName, trim?.name)}</span>
            <b>{trim?.modelCode}</b>
          </div>
        ),
        group: trim?.powertrainCategory
      }));

    const groupedItems = formattedTrims.reduce((allPowertrains, trim) => {
      if (trim.group) {
        let group = allPowertrains.find(g => g.type === 'group' && g.name === trim.group);
        if (!group) {
          group = {
            type: 'group',
            name: trim.group,
            items: []
          };
          allPowertrains.push(group);
        }
        group.items.push(trim);
      } else {
        allPowertrains.push(trim);
      }
      return allPowertrains;
    }, []);

    // If there's only one group, return just the items within the group
    if (!multiplePowerTrainGroups) {
      return formattedTrims;
    }
    return groupedItems;

  }, [msrpFilters.code]);

  const getBackgroundImage = () => {
    let url = props.data?.backgroundImage;
    if (isMobileView(window.innerWidth)) {
      url = props.data?.mobileImage;
    }
    if (isTabletView(window.innerWidth)) {
      url = props.data?.tabletImage;
    }

    return `url(${encodeURI(props.imagePath + url)})`;
  };

  const hasLongModelName = (name) => {
    return name?.length > 10;
  };

  useEffect(() => {
    if (uniqueTrims.length) {
      setMsrpFilters({
        ...msrpFilters,
        code: uniqTrimCodeOptions?.[0].value,
      });
    }
  }, [uniqueTrims, props.trims]);

  useEffect(() => {
    const firstItem = trimMsrpOptions?.flatMap(group => group.type === 'group' ? group.items : [group])[0];
    if (trimMsrpOptions?.length) {
      setMsrpFilters({
        ...msrpFilters,
        model: firstItem?.value,
      });
    }
  }, [trimMsrpOptions]);

  return (
    <div
      className="overview-hero-container"
      style={{ backgroundImage: getBackgroundImage() }}
    >
      <div className="overview-hero-content">
        <div className="year-model-container">
          <div className="hero-year" style={{ color: props.style?.textColor }}>
            {props.data?.modelYear || <Skeleton width={100} />}
          </div>
          <div
            className={
              (props.data?.modelName && hasLongModelName(getFormattedName(props.data?.modelName, false, props.data?.modelYear)))
                ? "hero-model hero-model-long"
                : "hero-model"
            }
            style={{ color: props.style?.textColor }}
          >
            {props.data?.modelName ? applyMarkdown(getFormattedName(props.data.modelName, true, props.data?.modelYear)) : <Skeleton width={300} />}
          </div>
        </div>

        {!isMobileView(window.innerWidth) && (
          <div className="hero-bottom-bar">
            <IncentivesButton
              incentivesFunction={props?.incentivesFunction}
            />
            {trimMsrpOptions && uniqTrimCodeOptions && currentMsrp ? (
              <>
                <div className="dropdown-placeholder">
                  <DropdownV2
                    style={"dark-translucent"}
                    rootClassName="hero-msrp-dropdown-filter"
                    options={uniqTrimCodeOptions}
                    placeholder="Code"
                    value={msrpFilters?.code}
                    onValueChange={(item) => {
                      if (item) {
                        setMsrpFilters({
                          ...msrpFilters,
                          code: item?.value,
                        });

                        // ANALYTICS
                        analyticsFunction.modelTrimCodeDropdownClick({
                          modelName: props.data?.modelName,
                          modelYear: props.data?.modelYear,
                          trimCode: item?.value,
                        });
                      }
                    }}
                  />
                </div>
                <div className="dropdown-placeholder">
                  <DropdownV2
                    style={"dark-translucent"}
                    rootClassName="hero-msrp-dropdown-filter dropdown-menu-lg"
                    options={trimMsrpOptions}
                    placeholder="Base MSRP"
                    value={msrpFilters?.model}
                    onValueChange={(item) => {
                      if (item) {
                        setMsrpFilters({
                          ...msrpFilters,
                          model: item?.value,
                        });

                        // ANALYTICS
                        analyticsFunction.modelMSRPDropdownClick({
                          modelName: props.data?.modelName,
                          modelYear: props.data?.modelYear,
                          modelBedSelected:
                            item?.label?.props?.children?.[0]?.props?.children,
                        });
                      }
                    }}
                  />
                </div>
                <div
                  className={`hero-msrp ${currentMsrp === "Base MSRP Coming Soon" && "coming-soon"
                    }`}
                  style={{ color: props.style?.textColor }}
                >
                  <span>{currentMsrp} <sup className="disclaimer-link" onClick={() => disclaimerComponentRef.current.onOpenModal()}>&dagger;</sup></span>
                </div>
              </>
            ) : (
              <div className="skeleton-section">
                <Skeleton width={250} height={38} count={3} inline />
              </div>
            )}
          </div>
        )}
      </div>
      <DisclaimersComponent
        template={`${props.year}_${props.model}_overview`}
        ref={disclaimerComponentRef}
        addBaseMSRPDisclaimer={true}
      />
    </div>
  );
};
