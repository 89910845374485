import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import AppSettings from "../../appsettings.js";
import * as analytics from './sidebarMenu.analytics.js'
import "../../styles/css/sidebarMenuMobile.css";

const SidebarMenuMobile = (props) => {
  const history = useHistory();

  const [currentMenuDataStack, setCurrentMenuDataStack] = useState([]);
  const [selectedMenuDataStack, setSelectedMenuDataStack] = useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState();
  const [badges, setBadges] = useState(null);

  useEffect(() => {
    if (props.tabs && !selectedMenuDataStack?.length) {
      init();
    }
    const badges = props?.tabs?.map(item => ({id: item.id, badge: item.badge}));
    setBadges(badges);
  }, [props.tabs])

  useEffect(() => {
    if(props.isOpened) {
      open();
    }
  }, [props.isOpened])

  useEffect(() => {
    window.addEventListener('popstate', init);
    return() => {
        window.removeEventListener('popstate', init);
    }
  }, []);

  const init = () => {
    const currentMenuDataStructure = {
        items: props.tabs,
        title: null,
        section: null,
      }
    props.onClose()
    setCurrentMenuDataStack([currentMenuDataStructure])
    setSelectedMenuDataStack([currentMenuDataStructure])
  }

  const open = () => {
    setCurrentMenuDataStack(selectedMenuDataStack)
  }

  const renderBackButton = () => {
    return <img
      className={`mobile-menu-back-button`}
      src={AppSettings.AWSImgRepo.resources + `en/chevron-right.svg`}
      onClick={() => back()}
    />
  }

  const back = () => {
    const menuData = [...currentMenuDataStack];
    menuData.pop();
    setCurrentMenuDataStack(menuData)
  }

  const menuNav = (clickedItem) => {
    analytics.subNavItemClick({
      page: props.analyticsData?.navPage,
      category: clickedItem.id || clickedItem.title,
      positionTitle: props.analyticsData?.subnavPage || props.analyticsData?.navPage,
      ...props.analyticsData
    })
    if (clickedItem.urlPath && !clickedItem.nestedItems) {
      setSelectedMenuDataStack(currentMenuDataStack)
      props.onClose();
      setSelectedMenuItem(clickedItem)
      props.navClick ? 
        props.navClick(currentMenuDataStack[currentMenuDataStack.length-1].section || clickedItem.urlPath, currentMenuDataStack[currentMenuDataStack.length-1].section && clickedItem.urlPath) 
      : history.push(`${props.baseUrl ? props.baseUrl + '/' : ''}${clickedItem.urlPath}`);
    } else if (clickedItem.nestedItems) {
      const currentMenuDataStucture = {
        items: clickedItem.nestedItems,
        title: clickedItem.title || clickedItem.id,
        section: clickedItem.urlPath || currentMenuDataStack[currentMenuDataStack.length-1].section,
      }

      const menuData = [...currentMenuDataStack];
      menuData.push(currentMenuDataStucture);
      setCurrentMenuDataStack(menuData)
    } else if (!clickedItem.urlPath && !clickedItem.nestedItems) {
      if("externalLink" in clickedItem && clickedItem.externalLink === "" || "internalLink" in clickedItem && clickedItem.internalLink === "") {
        return false
      }
      const target = "internalLink" in clickedItem ? '_self' : '_blank';
      window.open(clickedItem.internalLink || clickedItem.externalLink, target);

      props.onClose();
    }
  }

  const renderMenu = () => {
    return (
      <>
        {currentMenuDataStack.length > 1 && 
          <div className='sidebarMenuMobile-header'>
            {renderBackButton()}
            <div className="header-text">
              {currentMenuDataStack.slice(1).map((m, i, arr) => 
                  <div className={`${arr.length === 1 ? "main-header uppercase" : (i === 0 ? "top-header" : "main-header")}`}>
                      {m.title}
                  </div>)}
            </div>
          </div>
        }
        <ul className="sidebarMenuMobile-menu">
          {currentMenuDataStack[currentMenuDataStack.length-1].items?.map((item, index) => {
            const menuIcon = "externalLink" in item ? 'external-link.svg' : "internalLink" in item ? 'internal-link.svg' : 'chevron-right.svg'
            const isLink = "externalLink" in item || "internalLink" in item;
            const disableLink = isLink && (item.externalLink === "" || item.internalLink === "");
            const activeItem = !!selectedMenuItem?.urlPath && item?.urlPath === selectedMenuItem?.urlPath;
            const initialMenu = currentMenuDataStack.length === 1;
            let itemBadge = badges.filter(e => e.id === item.id)[0]?.badge;
            if (itemBadge >= 10) {
              itemBadge = '9+'
            }

            return <li
              key={index}
              className={`${disableLink && 'disabled'} ${activeItem && 'active'} ${initialMenu && "initial-menu"}`}
              onClick={() => menuNav(item)}>
                <div className='sidebarMenuMobile-menu-option'>
                  {item?.id || item?.title}
                  {itemBadge > 0 &&
                    <div className='menu-item-badge'>{itemBadge}</div>
                  }
                </div>
                <img
                  className={isLink ? 'isLink' : ''}
                  src={AppSettings.AWSImgRepo.resources + `en/${menuIcon}`}
                />
            </li>
          })}
        </ul>
      </>
    )
  };

  const closeMenuClick = () => {
    props.onClose()
    analytics.subNavMobileClose({...props.analyticsData})
  }

  useEffect(() => {
    if (props?.isOpened) {
      analytics.subNavMobileOpen({...props.analyticsData})
    }
  }, [props?.isOpened])

  return  props.isOpened ? <>
    <div className="sidebarMenuMobile-backdrop" onClick={()=>closeMenuClick()} />
      <div className="sidebarMenuMobile-wrapper">
          {!!currentMenuDataStack.length && renderMenu()}
      </div>
    </> : <></>
}

export default SidebarMenuMobile;
