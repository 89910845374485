import React from 'react';
import { CompetitorModelTrimComponent } from './competitorModelTrimComponent';
import { ImageComponent } from './imageComponent';
import { ImageTitleComponent } from './imageTitleComponent';
import CAVSImage from '../../../../../resources/images/caHeadToHeadVS.png';

export const HeadToHeadLandingBox = ({ s3Path, headToHeadComparisonObject, handleCompetitorComparisonSelection, ...props }) => {
  // Take the first competitor object, since all toyota vehicles are the same.
  const toyotaVehicle = headToHeadComparisonObject.competitors[0].toyotaVehicle;
  const competitorsCount = headToHeadComparisonObject.competitors.length;

  const toyotaCompetitorModelTrimList = headToHeadComparisonObject.competitors.map((competitor, index) =>
    <CompetitorModelTrimComponent
      competitorObject={competitor}
      isLastCompetitor={(competitorsCount === index + 1)}
      handleCompetitorComparisonOnClick={handleCompetitorComparisonSelection}
      key={`${index}-${competitor.competitorVehicle.model}-${competitor.competitorVehicle.trim}`}
    />);

  return (
    <div className="head-to-head-comparison-container">
      <div className="head-to-head-comparison-container-top">
        <ImageTitleComponent toyotaVehicle={toyotaVehicle} s3Path={s3Path} />
        <ImageComponent imageResource={CAVSImage} imageClassName="vsImage" />
      </div>
      <div className="head-to-head-comparison-container-bottom">
        {toyotaCompetitorModelTrimList}
      </div>
    </div>
  )
}