import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();

const resources = {
  tag: '35.2',
  section: 'Product',
  subsection: 'Featured Technology',
  technologyName: 'Toyota Safety Sense',
  page: 'Resources',
};

const tssBaseObject = {
  tag: '35.1',
  section: 'Product',
  subsection: 'Featured Technology',
  technologyName: 'Toyota Safety Sense™',
};

const resourceClick = (resource) => {
  analytics.fireOnClick({
    ...resources,
    category: resource.recordType,
    contentTitle: resource.title,
    mediaTitle: resource.title,
  });
};

const pageLoadAnalytics = (page) => {
  analytics.firePageLoad({
    ...tssBaseObject,
    page,
  });
};

export const resourceAnalytics = {
  resourceClick,
  pageLoadAnalytics,
};

const sendVideoEventAnalytics = (item) => {
  analytics.fireOnClick({
    tag: '40.5',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'Toyota Safety Sense',
    page: 'Videos',
    category: item.category,
    contentTitle: 'Overview',
    mediaTitle: item.videoTitle.replace(/(<([^>]+)>)/g, ''),
    modelName: ' ',
    module: 'video player',
    label: item.videoTitle.replace(/(<([^>]+)>)/g, ''),
    events: item.event,
  });
};

export const videoAnalytics = {
  pageLoadAnalytics,
  sendVideoEventAnalytics,
};

const articleClick = (mediaTitle) => {
  analytics.fireOnClick({
    ...tssBaseObject,
    tag: '35.2',
    category: 'Tile Click',
    page: 'Overview',
    mediaTitle,
  });
};

export const overviewAnalytics = {
  articleClick,
  pageLoadAnalytics,
};

const grabPageNameAnalyticsFromSubheaderClick = (pageName) => {
  switch (pageName) {
    case '"RESOURCES"':
      return 'Resources';
    case '"VIDEOS"':
      return 'Videos';
    case '"TSS LINEUP"':
      return 'TSS Lineup';
    case '"OVERVIEW"':
    default:
      return 'Overview';
  }
};

export const handleSubheaderClick = (event) => {
  const newPageName = event.currentTarget.firstChild && event.currentTarget.firstChild.firstChild;
  const pageName = grabPageNameAnalyticsFromSubheaderClick(newPageName);

  const analyticsObject = {
    ...tssBaseObject,
    tag: '35.2',
    page: pageName,
    mediaTitle: pageName,
    category: pageName,
  };
  analytics.fireOnClick(analyticsObject);
};


const lineupContentSearch = (item) => {
  analytics.fireOnClick({
    ...tssBaseObject,
    tag: '35.2',
    page: 'TSS Lineup',
    mediaTitle: `${item.year}:${item.model}:${item.tssTrim}`,
    category: 'Display Change',
  });
};

export const lineupAnalytics = {
  lineupContentSearch,
  pageLoadAnalytics,
};
