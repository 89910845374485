import AppSettings from "../../appsettings";
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import getVehicleOverview from "../../services/vehicleOverviewService";
import {
  getDynamoName,
  getDisplayFormattedModelNameFromUrl,
} from "../utils/vehicleName";
import { getTrims } from "../../services/trimsService";
import { ModelOverviewHero } from "./modelOverviewHero";
import { IncentivesButton } from "./incentivesButton";
import { capFirst } from "../utils/capitalize";
import { getS3PathName } from "../utils/vehicleName";
import GradeHighlightsSection from "./modelOverviewV2Sections/gradeHightlights";
import ModelCodePricingAccordion from "./modelOverviewV2Sections/modelCodePricingAccordion";
import NewSection from "./modelOverviewV2Sections/newSection";
import KeySellingPoints from "./modelOverviewV2Sections/keySellingPointsSection";
import OverviewVideo from "./modelOverviewV2Sections/overviewVideo";
import Awards from "./modelOverviewV2Sections/awards";
import Technology from "./modelOverviewV2Sections/technology";
import "../../styles/css/model-overview-v2/model-overview.css";
import * as analyticsFunctions from "./modelOverviewV2.analytics";
import { postUserActivityRecord } from "../../services/userActivityService";
import { FeatureFlagContext } from "../../components/contexts/featureFlagContext";

const ModelOverviewV2 = (props) => {
  const [overviewData, setOverviewData] = useState(null);
  const [trims, setTrims] = useState(null);
  const [imagePath, setImagePath] = useState(null);
  const params = useParams();
  const { featureFlags } = useContext(FeatureFlagContext);

  const history = useHistory();

  const getS3Path = async () => {
    const s3PathName = await getS3PathName(getDynamoName(props.model));
    setImagePath(`${AppSettings.AWSImgRepo.vehicle}${props.year}/${s3PathName}/en/`);
  }

  const getCustomStylesForSection = (sectionId) =>
    overviewData && overviewData.styles
      ? overviewData.styles.find((x) => x.sectionId === sectionId)
      : null;

  const loadTrims = () => {
    return new Promise((resolve, reject) => {
      getTrims(getDynamoName(props.model), props.year, "en")
        .then((payload) => {
          setTrims(payload.trims);
          resolve(payload);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  useEffect(() => {
    if (props.year && props.model) {
      getS3Path();
      getVehicleOverview(
        getDynamoName(props.model),
        props.year,
        false,
        "en"
      ).then((data) => {
        if (data) {
          setOverviewData(data);

          // ANALYTICS
          if (data?.titleSection?.modelName && data?.titleSection?.modelYear) {
            analyticsFunctions.pageLoad({
              modelName: data?.titleSection?.modelName,
              modelYear: data?.titleSection?.modelYear,
            });
          }
        }
      });
      postUserActivityRecord();
      loadTrims();

    }
  }, [props.year, props.model]);

  const incentivesButtonClick = () => {
    history.push(
      `/product/${props.model.toUpperCase()}/${params.year}/resources/incentives`
    );

    // ANALYTICS
    if (
      overviewData?.titleSection?.modelName &&
      overviewData?.titleSection?.modelYear
    ) {
      analyticsFunctions.incentivesButtonClick({
        modelName: overviewData?.titleSection?.modelName,
        modelYear: overviewData?.titleSection?.modelYear,
      });
    }
  };

  return (
    <div className="model-overview-v2-page">
      <ModelOverviewHero
        trims={trims}
        data={overviewData?.titleSection}
        style={getCustomStylesForSection("Title Section")}
        imagePath={imagePath}
        incentivesFunction={incentivesButtonClick}
        model={getDynamoName(props.model)}
        year={props.year}
      />
      <div className="model-overview-content">
        <div className="model-overview-section">
          <NewSection
            overviewData={overviewData}
            model={getDynamoName(props.model)}
            year={props.year}
          />
        </div>
        {props.device == "mobile" && (
          <div className="model-overview-section">
            <ModelCodePricingAccordion
              trims={trims}
              modelInfos={{
                modelName: overviewData?.titleSection?.modelName,
                modelYear: overviewData?.titleSection?.modelYear,
              }}
              model={getDynamoName(props.model)}
              year={props.year}
            />
            <IncentivesButton incentivesFunction={incentivesButtonClick} />
          </div>
        )}
        <div className="model-overview-section">
          <GradeHighlightsSection
            trims={trims}
            model={props.model}
            year={props.year}
          />
        </div>
        {overviewData?.keySellingPoints && (
          <div className="model-overview-section">
            <KeySellingPoints
              keySellingPoints={overviewData.keySellingPoints}
              imagePath={imagePath}
              model={getDynamoName(props.model)}
              year={props.year}
            />
          </div>
        )}
        {overviewData?.overviewVideo && (
          <div className="model-overview-section">
            <OverviewVideo
              displayModelName={overviewData?.titleSection?.modelName}
              model={getDynamoName(props.model)}
              year={props.year}
              data={overviewData.overviewVideo}
              imagePath={imagePath}
            />
          </div>
        )}
        {overviewData?.featuredIcons && (
          <div className="model-overview-section">
            <Technology
              displayModelName={overviewData?.titleSection?.modelName}
              model={getDynamoName(props.model)}
              year={props.year}
              features={overviewData.featuredIcons}
              imagePath={imagePath}
            />
          </div>
        )}
        {overviewData?.awards && (
          <div className="model-overview-section">
            <Awards
              awards={overviewData.awards}
              awardsIcon={overviewData.awardsIcon}
              imagePath={imagePath}
              model={getDynamoName(props.model)}
              year={props.year}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ModelOverviewV2;
