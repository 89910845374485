import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();

export const glossaryLandingPageLoaded = (pageLoadAnalytics) => {
    pageLoadAnalytics && analytics.firePageLoad({
      tag: pageLoadAnalytics.tagId,
      section: pageLoadAnalytics.section,
      subsection: pageLoadAnalytics.landingPage.subSection,
      page: pageLoadAnalytics.landingPage.page,
    });
  };

  export const glossaryResultsPageLoaded = (pageLoadAnalytics, pageCategory) => {
    pageLoadAnalytics && analytics.firePageLoad({
      tag: pageLoadAnalytics.tagId,
      section: pageLoadAnalytics.section,
      page: pageCategory,
    });
  };

  export const glossaryNoResultsPageLoaded = (pageLoadAnalytics, pageCategory, keyword) => {
    pageLoadAnalytics && analytics.firePageLoad({
      tag: pageLoadAnalytics.tagId,
      section: pageLoadAnalytics.section,
      page: pageCategory,
      keyword,
      numberOfSearchResults: '0',
    });
  };

  export const glossaryToolTipLoaded = (pageLoadAnalytics) => {
    pageLoadAnalytics && analytics.firePageLoad({
      tag: pageLoadAnalytics.tagId,
      container: pageLoadAnalytics.container,
      category: pageLoadAnalytics.category,
      label: pageLoadAnalytics.label,
      contentTitle: pageLoadAnalytics.contentTitle,
    });
  };

  export const headerLinkClick = (clickAnalytics, category, positionTitle, label) => {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      container: clickAnalytics.container,
      category,
      positionTitle,
      label,
    });
  };

  export const searchLinkClick = (clickAnalytics, category, positionTitle, label, keyword) => {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      container: clickAnalytics.container,
      category,
      positionTitle,
      label,
      keyword,
    });
  };

  export const accordionLinkClick = (clickAnalytics, category, label, keyword) => {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      container: clickAnalytics.container,
      category,
      label,
      keyword,
    });
  };

  export const termLinkClick = (clickAnalytics, category, positionTitle, label) => {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      container: clickAnalytics.container,
      category,
      positionTitle,
      label,
    });
  };

  export const resultsLinkClick = (clickAnalytics, category, label, keyword) => {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      container: clickAnalytics.container,
      category,
      label,
      keyword,
    });
  };

  export const modalLoaded = (pageLoadAnalytics) => {
      pageLoadAnalytics && analytics.firePageLoad({
      tag: pageLoadAnalytics.results.tagId,
      section: pageLoadAnalytics.section,
      page: pageLoadAnalytics.imageModal.page,
    });
  };

  export const modalLinkClick = (clickAnalytics, category, label) => {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      container: clickAnalytics.container,
      category,
      label,
    });
  };

  export const glossaryOverlayOnLoad = (clickAnalytics, category, contentTitle) => {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      container: clickAnalytics.glossaryOverlay.container,
      label: clickAnalytics.glossaryOverlay.openLabel,
      category,
      contentTitle,
    })
  };

  export const glossaryOverlayCtaClick = (clickAnalytics, category, label, contentTitle) => {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      container: clickAnalytics.glossaryOverlay.container,
      category,
      label,
      contentTitle,
    })
  };

  export const glossaryOverlayClose = (clickAnalytics, category, contentTitle) => {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      container: clickAnalytics.glossaryOverlay.container,
      label: clickAnalytics.glossaryOverlay.closeLabel,
      category,
      contentTitle,
    })
  };

  export const glossaryContactFormSubmit = (clickAnalytics, category) => {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      container: clickAnalytics.container,
      category,
      label: clickAnalytics.label,
    });
  };
  