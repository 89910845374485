import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import videoIcon from '../../../../resources/images/Video_Icon.png';
import applyMarkdown from '../../../utils/applyMarkdown';
import VideoPlayerComponent from '../../../videoPlayer/videoPlayer.component';
import '../../../../styles/css/headToHead.css';

export default function HeadToHeadVideo(props) {
  return (
        <div className="head-to-head-video">
            <div className="video-box">
                <VideoPlayerComponent item={{
                  alias: props.currentEdge.edgeContent.headToHeadVideo.brightcoveId, qumuId: props.currentEdge.edgeContent.headToHeadVideo.qumuId, videoTitle: 'HEAD TO HEAD Video', pageTitle: 'Head to Head',
                }} />
            </div>
            <div className="text-box">
                <div className="head-to-head-text">
                    <div className="text">
                        <img alt={props.sampleImage ? props.sampleImage : null} className="vs-icon head-to-head-icon" src={videoIcon} />
                        <span className="bold-title">
                            &nbsp;|&nbsp;
                            <FormattedMessage id="caHeadToHead.headToHeadVideoBoldHeader" />
                        </span>
                        <span className="title">
                            <FormattedMessage id="caHeadToHead.headToHeadVideoHeader" />
                        </span>
                    </div>
                    <div className="description-text">
                        <div>{applyMarkdown(props.currentEdge.edgeContent.headToHeadVideo.videoDescription, props.disclaimer)}</div>
                    </div>
                </div>
            </div>
        </div >
  );
}

HeadToHeadVideo.propTypes = {
  sampleImage: PropTypes.string,
  currentEdge: PropTypes.object,
  disclaimer: PropTypes.any,
};
