import React from 'react';
import FeaturesComponent from '../../features/features.component';
import '../../../styles/css/features-page.css';
import { FormattedMessage } from 'react-intl';
import { FilterMenu } from '../../filterMenu/filterMenu.component';

import AppSettings from '../../../appsettings';

export function TSSFeaturesTemplate(component) {
  return (
        <div className="features-page">
            {component.state.languageOverride
              ? <div className="language-unavailable" >
                    <FormattedMessage id="dataUnavailable" default="Language is unavailable" />
                </div>
              : null
            }
            <div className="title">
                <div className="print-tss-title"><FormattedMessage id="dropdown.safety.toyotaSafetySense" /></div>
                <FormattedMessage id="global.features" defaultMessage="Features" />
                <div className="print-dividing-line" />
            </div>
            <FilterMenu
                active={component.state.activeFilter}
                filters={component.props.filters}
                filterClick={component.switchFilter}
                displayArrow={true}
            />
            <FeaturesComponent featuresData={component.state.featuresData} page={'Features'} clickLink={component.clickLink} readMoreClick={component.readMoreClick} disclaimer={'TSS'} imagePath={AppSettings.AWSImgRepo.tss} />
        </div>
  );
}
