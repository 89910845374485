// This dynamic landing page was originally made for the Audio Multimedia redesign (May 2021), meaning if this is reused then the data structure will need to be the same.
// Here is a link to the original Microsoft excel template found in AWS S3: https://s3.console.aws.amazon.com/s3/object/engage-prod-templates?region=us-west-2&prefix=audioMultimediaLanding/en/Audio_Multimedia_Landing_V02_R01_5.11.21.xlsx
import * as _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import RedLine from "../../components/atoms/RedLine";
import applyMarkdown from "../../screens/utils/applyMarkdown";
import { isDesktopView } from "../../screens/utils/getScreenSize";
import VideoPlayerComponent from "../../screens/videoPlayer/videoPlayer.component";
import "../../styles/css/dynamicLanding.css";
import LandingSection from "../molecules/dynamic-landing-section";
import IconLinks from "../molecules/icon-links";
import SubPageHeader from "../../components/molecules/marketingPage/subPageHeader";
import * as analytics from "./dynamicLanding.analytics";
import { useHistory, useLocation } from "react-router-dom";
import AppSettings from "../../appsettings";
import MobileBackButton from "../molecules/MobileBackButton";

const landingPage = (props) => {
  useEffect(() => {
    props.pageLoadAnalytics &&
      analytics.landingPageLoaded(props.pageLoadAnalytics);
  }, [props.pageLoadAnalytics]);

  const fireExternalResourcesClickAnalytics = (title) =>
    analytics.externalResourcesClicked(
      title,
      props.clickAnalytics,
      props.pageLoadAnalytics
    );
  const fireIconLinkClickAnalytics = (title) =>
    analytics.iconLinkClicked(
      title,
      props.clickAnalytics,
      props.pageLoadAnalytics
    );
  const fireTileClickAnalytics = (section, title) =>
    analytics.tileClicked(
      section,
      title,
      props.clickAnalytics,
      props.pageLoadAnalytics
    );

  const showExternalResources = () => !!props.externalResources;
  const showHeroVideo = () => !!props.heroVideoId;
  const showDescription = () => !!props.description;
  const showIconLinks = () => !!props.iconLinks;
  const showSubPageHeader = () => !!props.subPageHeader;
  const showTileSections = () => !!props.tileSections;

  const history = useHistory();
  const location = useLocation();
  const filePath = AppSettings.AWSImgRepo.resources + "en/";

  const externalResources = (externalResources) => (
    <div className="dynamic-landing-external-resources">
      <div className="external-resources-line"></div>
      <div className="external-resources-container">
        <div className="external-resources">
          <div className="external-resources-title">External Resources</div>
          {externalResources.map((resource) => (
            <div className="external-resources-link" key={resource.title}>
              <a
                href={resource.url}
                target={isDesktopView(window.innerWidth) && resource.target}
                onClick={() =>
                  fireExternalResourcesClickAnalytics(resource.title)
                }
              >
                {resource.title}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const heroVideo = (heroVideoAnalytics, heroVideoId) => (
    <div className="dynamic-landing-page-hero-video">
      <VideoPlayerComponent
        analyticsModule={heroVideoAnalytics && heroVideoAnalytics.module}
        analyticsSection={heroVideoAnalytics && heroVideoAnalytics.section}
        analyticsTag={heroVideoAnalytics && heroVideoAnalytics.videoTagId}
        item={{
          alias: heroVideoId,
          pageTitle: heroVideoAnalytics && heroVideoAnalytics.page,
          qumuId: heroVideoId,
        }}
        videoId={heroVideoId}
      />
    </div>
  );

  const iconLinks = (iconLinks, imagePath) => (
    <IconLinks
      fireIconLinkClick={fireIconLinkClickAnalytics}
      links={iconLinks}
      imagePath={imagePath}
    />
  );

  const description = (description) => {
    if (props.pageStyle == "alternative") {
      return (
        <div className="dynamic-landing-description-alternative">
          {applyMarkdown(description)}
        </div>
      );
    } else {
      return (
        <div className="dynamic-landing-description">
          {applyMarkdown(description)}
        </div>
      );
    }
  };

  const subPageHeader = (subPageHeader) => (
    <SubPageHeader
      device={subPageHeader.device}
      redline={subPageHeader.redline}
      title={subPageHeader.title}
      description={subPageHeader.description}
      columns={subPageHeader.columns}
    />
  );

  const tileSections = (content, imagePath) => {
    const sortedContent = _.orderBy(content, (section) =>
      section.sectionStyles.sectionOrder
        ? section.sectionStyles.sectionOrder
        : "1"
    );
    return (
      <div className="dynamic-landing-tiles-container">
        {sortedContent.map((section) => {
          return (
            <LandingSection
              awsImagePath={imagePath}
              fireTileClickAnalytics={fireTileClickAnalytics}
              key={section.sectionTitle}
              sectionStyles={section.sectionStyles}
              sectionTitle={section.sectionTitle}
              tiles={section.tiles}
              smallTiles={props.smallTiles}
              type={section.type}
              tileClickFunction={props.tileClickFunction}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div className="dynamic-landing-container">
      {window.ReactNativeWebView && !props.hideInnerBackButton && (
        <MobileBackButton
          containerStyle={{
            height: "50px",
            zIndex: 1,
          }}
          style={{ right: 12 }}
        />
      )}
      {props.pageStyle == "alternative" ? (
        <div className="dynamic-landing-title-alternative">
          {props.pageTitle}
        </div>
      ) : (
        <div className="dynamic-landing-title" style={{ position: "relative" }}>
          <div>{props.pageTitle}</div>
        </div>
      )}
      <RedLine
        style={{
          margin: "auto",
          width: "57px",
          bottomBorder: "4px",
        }}
      />

      {showSubPageHeader() && subPageHeader(props.subPageHeader)}
      {showHeroVideo() &&
        heroVideo(
          props.clickAnalytics && props.clickAnalytics.heroVideo,
          props.heroVideoId
        )}
      {showIconLinks() && iconLinks(props.iconLinks, props.imagePath)}
      {showDescription() && description(props.description)}
      {showTileSections() && tileSections(props.tileSections, props.imagePath)}
      {showExternalResources() && externalResources(props.externalResources)}
    </div>
  );
};

landingPage.propTypes = {
  analyticsLocation: PropTypes.string,
  clickAnalytics: PropTypes.shape({
    externalResources: PropTypes.shape({
      category: PropTypes.string,
      positionTitle: PropTypes.string,
    }),
    heroVideo: PropTypes.shape({
      module: PropTypes.string,
      page: PropTypes.string,
      section: PropTypes.string,
      videoTagId: PropTypes.string,
    }),
    iconLinks: PropTypes.shape({
      category: PropTypes.string,
    }),
    tagId: PropTypes.string,
    tileSections: PropTypes.shape({
      category: PropTypes.string,
      positionTitle: PropTypes.string,
    }),
  }),
  subPageHeader: PropTypes.shape({
    device: PropTypes.string,
    redline: PropTypes.bool,
    headerTitle: PropTypes.string,
    headerDescription: PropTypes.string,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        question: PropTypes.string,
        description: PropTypes.string,
      })
    ),
  }),
  externalResources: PropTypes.arrayOf(
    PropTypes.shape({
      target: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  heroVideoId: PropTypes.string,
  iconLinks: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string,
      hoverImgSrc: PropTypes.string,
      imgSrc: PropTypes.string,
      target: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  imagePath: PropTypes.string,
  pageLoadAnalytics: PropTypes.shape({
    page: PropTypes.string,
    section: PropTypes.string,
    subsection: PropTypes.string,
    tagId: PropTypes.string,
    technologyName: PropTypes.string,
  }),
  pageTitle: PropTypes.string,
  tileSections: PropTypes.shape(),
};

export default React.memo(landingPage);
