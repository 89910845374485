import React, { useEffect, useState, useRef } from 'react';
import SystemInformationModalContent from './systemInformationModalContent';
import RoundedButton from '../../../components/molecules/roundedButton';
import '../../../styles/css/audioMultimediaSystemInfo.css';
import AppSettings from '../../../appsettings.js';
import { isMobileView } from '../../utils/getScreenSize';
import { useHistory } from 'react-router';
import * as analytics from '../audioMultimediaOverview.analytics';
import applyMarkdown from '../../utils/applyMarkdown';

const AudioMultimediaSystemInfo = (props) => {
    const [activeTab, setActiveTab] = useState(null);
    const [currentData, setCurrentData] = useState(null);
    const [analyticsData, setAnalyticsData] = useState(props.analyticsData);

    const disclaimerRef = useRef();

    useEffect(() => {
        props.setDisclaimer(disclaimerRef.current);
    }, [disclaimerRef]);

    useEffect(() => {
        setAnalyticsData(props.analyticsData);
    }, [props.analyticsData]);

    const history = useHistory();

    const ammImagePath = `${AppSettings.AWSImgRepo.amm}`

    useEffect(() => {
        let defaultTab;
        let defaultData;
        const filterData = props.data.filterData;
        for (let i = 0; i < filterData.length; i++) {
            if (filterData[i].items && filterData[i].items.length > 0) {
                defaultTab = filterData[i].title;
                defaultData = filterData[i];
                break;
            }
        }
        setCurrentData(defaultData);
        setActiveTab(defaultTab);
        props.setModalCloseFunction(defaultTab)
    }, []);

    const filterButtonOnClick = (i) => {
        props.setModalCloseFunction(i.title)
        analytics.systemInfoOverlayFilterClick(analyticsData?.clickAnalytics, props.data.title, activeTab, i.title)
        if (i.link) {
            props.closeModal()
            history.push(i.link);
            props.setResourcesFilter(props.data.resourceFilter);
        } else {
            setCurrentData(i);
            setActiveTab(i.title);
        }
    }

    const getFilterButtons = () => (
        props.data.filterData
            .filter(i => (i.items && i.items.length > 0 && (i.title != 'Features' || i.items[0].availability.length > 0)) || i.link)
            .map(i => <RoundedButton title={i.title} onClick={()=>{filterButtonOnClick(i)}} width={225} active={activeTab==i.title} />));

    const getSpecCircle = (availability) => {
        if (availability === 'S') {
            return <div className='amm-spec-circle'>S</div>
        } else if (availability === 'O' || availability === 'P') {
            return <div className='amm-spec-circle-optional'>{availability}</div>
        }

        return <div className='amm-spec-circle-dash'>—</div>

    }

    const getRows = (data) => {
        let systems = [];
        let systemExists = false;

        data[0].availability.forEach(system => {
            systems.push(system.system);
        });

        systems.forEach(system => {
            for(let i  = 0; i < data.length; i++){
                if(data[i].availability.find(row => row.system === system && row.featureAvailability != '-') != undefined){
                    systemExists = true;
                }
            }

            if(systemExists === false){
                const index = systems.indexOf(system);
                for(let i  = 0; i < data.length; i++){
                    data[i].availability.splice(index, 1);
                }
            }

            systemExists = false;
        });

        return data;
    }

    const getContent = () => {
        let content = null;
        let data = [];

        if (currentData.items) {
            switch (currentData.title) {
                case "Features":
                    data = getRows(currentData.items);
                    if (!isMobileView(window.innerWidth)) {
                        let headers = currentData.items[0].availability.map((i, index) =>
                            <th className="amm-tableHeader system centerAlign">
                                <div className="headerText">
                                    {i.system}
                                </div>
                            </th>);

                        headers.unshift(<th className="amm-tableHeader empty"></th>);

                        content =
                            <table className="amm-table">
                                <tr className="amm-tableHeaderRow">{headers}</tr>
                                { currentData.items.map(i =>
                                    <tr className="amm-tableDataRow">
                                        <td className="amm-tableData feature leftAlign">{applyMarkdown(i.feature, props.disclaimer)}</td>
                                        {i.availability.map(system =>
                                            <td className="amm-tableData available">
                                                {getSpecCircle(system.featureAvailability)}
                                            </td>)}
                                    </tr>
                                )}
                            </table>
                    } else {
                        content =
                            currentData.items.map(i =>
                                <table className="amm-table">
                                    <tr className="amm-tableHeaderRow">
                                        <th colspan="2" className="amm-tableHeader feature centerAlign">
                                            <div className="headerText">
                                                {applyMarkdown(i.feature, props.disclaimer)}
                                            </div>
                                        </th>
                                    </tr>
                                    {i.availability.slice(0, 3).map(system =>
                                        <tr className="amm-tableDataRow">
                                            <td className="amm-tableData system leftAlign">{system.system}</td>
                                            <td className="amm-tableData available centerAlign">
                                                {getSpecCircle(system.featureAvailability)}
                                            </td>
                                        </tr>
                                    )}
                                </table>
                            );
                    }
                    break;
                case "Vehicle Applicability":
                    content =
                        <table className="amm-table">
                            <tr className="amm-tableHeaderRow">
                                <th className="amm-tableHeader model leftAlign">
                                    <div className="headerText">
                                        {"Model"}
                                    </div>
                                </th>
                                <th className="amm-tableHeader year centerAlign">
                                    <div className="headerText">
                                        {"Year"}
                                    </div>
                                </th>
                            </tr>
                            {currentData.items.map(i =>
                                <tr className="amm-tableDataRow">
                                    <td className="amm-tableData modelName leftAlign">{i.modelName}</td>
                                    <td className="amm-tableData modelYear centerAlign">{i.modelYear}</td>
                                </tr>
                            )}
                        </table>
                    break;
                default:
                    break;
            }

        }
        return (content);
    }

    return (
        <SystemInformationModalContent
            headerTitle={props.data.title}
            headerDescription={props.data.description}
            headerImage={ammImagePath + props.data.image}
        >
            {props.data.filterData.length > 1 && <div className="amm-systemInfoFilters">{getFilterButtons()}</div>}
            {currentData && <div className="amm-systemInfoFilterContent">{getContent()}</div>}
        </SystemInformationModalContent>
    );
}

export default AudioMultimediaSystemInfo;