import React, { useState } from "react";
import S from "../../../../styles/scss/beyondZero/powerOfChoice.module.scss";
import { isDesktopView, isTabletView } from "../../../utils/getScreenSize";
import AppSettings from "../../../../appsettings";
import BZTabs from "../../sharedComponents/bzTabs";
import applyMarkdown from "../../../utils/applyMarkdown";

const PowerOfChoice = ({ data, analyticsActions }) => {
  const [selectedTab, setSelectedTab] = useState(0)

  const tabItems = data.tabs.map((tab, index) => ({
    value: index,
    image: AppSettings.AWSImgRepo.beyondZero + tab.tabImage,
    label: tab.tabName
  }));

  const title = data.tabs[selectedTab].tabName
  const body = data.tabs[selectedTab].bodyText
  const iconImage = AppSettings.AWSImgRepo.beyondZero + data.tabs[selectedTab].tabImage
  const desktopMainImage = AppSettings.AWSImgRepo.beyondZero + data.tabs[selectedTab].bodyImageDesktop
  const tabletMainImage = AppSettings.AWSImgRepo.beyondZero + data.tabs[selectedTab].bodyImageTablet
  const mobileMainImage = AppSettings.AWSImgRepo.beyondZero + data.tabs[selectedTab].bodyImageMobile

  const isDesktop = isDesktopView(window.innerWidth);
  const isTablet = isTabletView(window.innerWidth);
  const mainImage = isDesktop
    ? desktopMainImage
    : isTablet
    ? tabletMainImage
    : mobileMainImage;

  const handleTabOnChange = (tabIndex) => {
    setSelectedTab(tabIndex);

    analyticsActions.onTabClick({
      label: 'Power of Choice Tab',
      content_title: tabItems[tabIndex].label
    })
  } 

  return (
    <div className={S["power-of-choice-section"]}>
      <span>{data.title}</span>
      <div className={S["tabs-section"]}>
        <BZTabs
          variant="light"
          primaryColor={'#fff'}
          active={selectedTab} 
          onClick={handleTabOnChange} 
          items={tabItems}
          hasImages={true}
        />
      </div>
      <section className={S.container}>
        <img src={mainImage} />
        <section>
          <img src={iconImage} />
          <h2>{applyMarkdown(title)}</h2>
          <p>{applyMarkdown(body)}</p>
        </section>
      </section>
    </div>
  );
};

export default PowerOfChoice;
