import React from "react";
import appsettings from "../../../../appsettings";
import S from "../../../../styles/scss/beyondZero/vehicleCard.module.scss";

const VehicleCard = ({ model, year, imageUrl, onClick }) => {
  return (
    <div className={S["vehicle-card-container"]}>
      <div className={S["vehicle-card-info-wrapper"]}>
        <img src={`${appsettings.AWSImgRepo.beyondZero}${imageUrl}`} alt={model} />
        <span className={S.year}>{year}</span>
        <span className={S.model}>{model}</span>
      </div>
      <a onClick={onClick}>Vehicle Overview</a>
    </div>
  );
}

export default VehicleCard;
