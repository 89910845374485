import React from 'react';
import RedLine from '../../atoms/RedLine';
import applyCustomMarkdown from '../../../screens/utils/applyCustomMarkdown';
import '../../../styles/css/marketingPage/subPageHeader.css'

const dummyData = {
    title: "WHAT IS THE APP?",
    redline: true,
    description: "The Toyota Engage app is a tool for sales pros that harnesses the power of the Engage website and puts it right in the palm of your hand. So, you’ll always have access to the Toyota information you need, when you need it.",
}

function SubPageHeader(props) {
    const descriptionSingle = () => (
        <div className={`description ${props.device}`}>{applyCustomMarkdown(props.description,  'bold')}</div>
    );

    const descriptionColumns = () => (
        <div className={`description ${props.device}`}>
            {props.columns.map((column, index) => (
                <div className="subpage-column" key={index}>
                    {column.question && <div className="subpage-column-question">{applyCustomMarkdown(column.question, 'bold')}</div>}
                    {column.description && <div className="subpage-column-description">{applyCustomMarkdown(column.description, 'bold')}</div>}
                </div>
            ))}
        </div>
    );

    return (
        <div className={`subpage-header ${props.device}`} >
            {props.title && <div className={`subpage-title ${props.device}`}>{applyCustomMarkdown(props.title, 'bold')}</div>}
            {props.redline ? <RedLine className={`redline ${props.device}`} /> : null}
            {props.columns && descriptionColumns()}
            {props.description && descriptionSingle()}
        </div>
    );
}

export default SubPageHeader;