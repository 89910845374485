import React, {useEffect, useState} from 'react';
import ContentCard from '../../../components/molecules/cards/contentCard';
import RoundedButton from '../../../components/molecules/roundedButton';
import CarouselV2 from '../../../components/molecules/CarouselV2';
import '../../../styles/css/homepage/exploreCarousel.css'
import AppSettings from '../../../appsettings';
import { carouselArrowClicked, generalButtonClicked, carouselContentClicked } from '../homepage.analytics';

const ExploreCarouselComponent = (props) => {
    const [filter, setFilter] = useState(null);
    const [categories, setCategories] = useState(null);
    const [filteredContent, setFilteredContent] = useState(props.content);

    useEffect(()=>{
        const contentCategories = [...new Set(props.content?.map(e => e.category))];
        setCategories(contentCategories);
    }, [props.content])

    useEffect(() => {
        setFilteredContent(props.content?.filter(e => filter ? e.category.toLowerCase() == filter.toLowerCase() : true));
    }, [filter])

    const getSlidesCount = () => {
        if (window.innerWidth > 1550) {
            return 4;
        } else if (window.innerWidth > 780) {
            return 2;
        } else {
            return 1;
        }
    }

    const handleClick = (filter, label) => {
        setFilter(filter);
        generalButtonClicked(props.analytics, label);
    }

    const getCategoryIcon = (category) => {
        const list = props.categories;
        return (list.find(val => val.categoryName.toLowerCase() === category.toLowerCase())).categoryIcon;
    }

    return (
        <div className="exploreCarouselContainer">
            <div className="exploreTitle">{props.title.toUpperCase()}</div>
            <div className="exploreFilters">
                <RoundedButton
                    title={'ALL'}
                    onClick={()=>handleClick(null, 'ALL')}
                    active={!filter}
                    color={"#00000000"}
                    activeColor={"#00000000"}
                    borderColor={!filter ? "#000000" : "#00000000"}
                    textColor={"#000000"}
                    height={"34px"}
                />
                {categories?.map((f) =>
                    <RoundedButton
                        title={f.toUpperCase()}
                        onClick={()=>handleClick(f, f.toUpperCase())}
                        active={filter == f}
                        color={"#00000000"}
                        activeColor={"#00000000"}
                        borderColor={filter == f ? "#000000" : "#00000000"}
                        textColor={"#000000"}
                        height={"34px"}
                    />
                )}
            </div>
            <div className="exploreCarousel">
                <CarouselV2 
                    navigation={props.device != "mobile"}
                    loop
                    spaceBetween={16}
                    spotlight
                    slidesCount={getSlidesCount()}
                    prevArrow={<div className="circleArrow prev" onClick={() => carouselArrowClicked(props.analytics, 'Prev')}><img className="arrowIcon" src={AppSettings.AWSImgRepo.resources + 'en/left-arrow-white.png'}/></div>}
                    nextArrow={<div className="circleArrow next" onClick={() => carouselArrowClicked(props.analytics, 'Next')}><img className="arrowIcon" src={AppSettings.AWSImgRepo.resources + 'en/right-arrow-white.png'}/></div>}
                    items={filteredContent.map(e =>
                        <ContentCard
                            link={e.link}
                            title={e.title}
                            image={AppSettings.AWSImgRepo.homepage_v3 + e.image}
                            cornerIcon={AppSettings.AWSImgRepo.homepage_v3 + getCategoryIcon(e.category)}
                            hideCornerIcon={filter}
                            analytics={() => carouselContentClicked(props.analytics, e.title)}
                        />
                    ) || []}
                />
            </div>
        </div>
    );
}

export default ExploreCarouselComponent;