import { getLocale } from './languageTranslationService';
import { getAccessToken } from './authenticationService';

const accessToken = getAccessToken();
const credentials = 'include';
const _ = require('lodash');

const option = {
  GET: {
    method: 'GET',
    headers: {
      'accept-language': getLocale(),
      ...(accessToken && { 'Authorization': `Bearer ${accessToken}` }),
      'Content-Type': 'application/json',
      Pragma: 'no-cache',
    },
    credentials,
    cache: 'no-cache',
  },
  GET_NO_LANGUAGE: {
    method: 'GET',
    headers: {
      ...(accessToken && { 'Authorization': `Bearer ${accessToken}` }),
      'Content-Type': 'application/json',
      Pragma: 'no-cache',
    },
    credentials,
    cache: 'no-cache',
  },
  PUT: {
    method: 'PUT',
    headers: {
      ...(accessToken && { 'Authorization': `Bearer ${accessToken}` }),
      'Content-Type': 'application/json',
    },
    credentials,
  },
  POST: {
    method: 'POST',
    headers: {
      'accept-language': getLocale(),
      ...(accessToken && { 'Authorization': `Bearer ${accessToken}` }),
      'Content-Type': 'application/json',
    },
    credentials,
  },
  POSTFILE: {
    method: 'POST',
    headers: {
      'accept-language': getLocale(),
      ...(accessToken && { 'Authorization': `Bearer ${accessToken}` }),
    },
    credentials,
  },
  DELETE: {
    method: 'DELETE',
    headers: {
      ...(accessToken && { 'Authorization': `Bearer ${accessToken}` }),
      'Content-Type': 'application/json',
    },
    credentials,
  },
};

export default {
  GET: () => _.cloneDeep(option.GET),
  GET_NO_LANGUAGE: () => _.cloneDeep(option.GET_NO_LANGUAGE),
  PUT: () => _.cloneDeep(option.PUT),
  POST: () => _.cloneDeep(option.POST),
  POSTFILE: () => _.cloneDeep(option.POSTFILE),
  DELETE: () => _.cloneDeep(option.DELETE),
};
