import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import applyCustomMarkdown from '../utils/applyCustomMarkdown';
import closeIcon from '../../resources/images/icon_close.png';
import redSearchIcon from '../../resources/images/red_search.png';
import SearchBarInput from '../../components/molecules/searchBarInput';
import { cleanString } from '../utils/helpers';

const VinContent = (props) => {
    const [showSearchIcon, setShowSearchIcon] = useState(true);
    const [showClearIcon, setShowClearIcon] = useState(false);

    function searchClicked () {
        setShowSearchIcon(false);
        setShowClearIcon(true);
    }

    function clearClicked () {
        setShowSearchIcon(true);
        setShowClearIcon(false);
        props.resetResults();
    }

    const updateSearch = (search) => {
        props.handleOnChange(cleanString(search));
    }
    return(<div className='vinInputDiv'>
        <div className='vinInput'>
            <SearchBarInput
                onSearch={updateSearch}
                autoFocus={false}
                dynamicSearching={false}
                placeholder='JTMLWRFV5KD041612'
                searchParam={props.vin}
                showSearchIcon={showSearchIcon}
                showCloseIcon={showClearIcon}
                searchClicked={searchClicked}
                clearClicked={clearClicked}
                className="vinInput"
            />
         </div>
    {props.errorMessage
        && <div className='vinError'>{applyCustomMarkdown(props.errorMessage, 'bold')}</div>}

    </div>);
}

export default injectIntl(VinContent);

VinContent.propTypes = {
  errorMessage: PropTypes.string,
  vin: PropTypes.string,
  handleOnChange: PropTypes.func,
  handleOnBlur: PropTypes.func,
  setVin: PropTypes.func,
};
