import { useEffect, useState, useRef } from 'react';
import AppSettings from '../../appsettings';
import getDealership from '../../services/dealershipsService';
import { postEmail } from '../../services/emailService';
import '../../styles/css/search-feedback.css'

const SearchFeedback = (props) => {
    const defaultText = props.linkText || "Can't find what you're looking for? Tell us here.";
    const [linkText, setLinkText] = useState(defaultText);
    const [formVisible, setFormVisible] = useState(!!props.shouldStayOpen);
    const [dealership, setDealership] = useState("");
    const email = localStorage.email;
    const firstName = `${localStorage.firstName ? localStorage.firstName : ""}`;
    const lastName = `${localStorage.lastName ? localStorage.lastName : ""}`;
    const formRef = useRef(null);

    useEffect(() => {
        getDealershipName();
    }, []);

    useEffect(() => {
        !props.shouldStayOpen && formVisible && formRef.current && formRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    }, [formVisible]);

    const getDealershipName = async () => {
        const dealershipData = await getDealership(localStorage.dealer_code);
        if (dealershipData) {
            setDealership(dealershipData.dealerName);
        }
    };

    const toggleVisible = () => {
        if (!props.shouldStayOpen) {
            setFormVisible(prevState => !prevState);
        }
    };

    const getFormattedMessage = (message) => {
        const fromLine = `From: ${firstName} ${lastName} \n\n  `;
        const dealershipLine = `Dealership: ${dealership} \n\n  `;
        const emailLine = `Email Address: ${email} \n\n  `;
        const locationLine = `Location: ${props.location}\n\n  `;
        const modalLine = props.modal ? `Modal: ${props.modal}\n\n  ` : '';
        const categoryLine = props.category ? `Category: ${props.category}\n\n  ` : '';
        const filterLine = props.filter ? `Filter: ${props.filter}\n\n  ` : '';
        const searchLine = props.searchParam ? `Search Term: ${props.searchParam}\n\n  ` : '';
        const featureOneLine = props.featureOne ? `Selected Feature #1: ${props.featureOne}\n\n  ` : '';
        const featureTwoLine = props.featureTwo ? `Selected Feature #2: ${props.featureTwo}\n\n  ` : '';
        const featureThreeLine = props.featureThree ? `Selected Feature #3: ${props.featureThree}\n\n  ` : '';
        const messageLine = `Message: ${message}`;

        return fromLine +
            dealershipLine +
            emailLine +
            locationLine +
            modalLine +
            categoryLine +
            filterLine +
            searchLine +
            featureOneLine +
            featureTwoLine +
            featureThreeLine +
            messageLine;
    };

    const handleCancelClick = () => {
        toggleVisible();
    };
    

    const sendEmail = async (emailObject) => {
        try {
            await postEmail(emailObject);
            setLinkText("Message sent. Thank you!");
            if (!props.shouldStayOpen) {
                setFormVisible(false);
            }
            props.onSubmitAnalytics && props.onSubmitAnalytics();
        } catch (error) {
            setLinkText("Error sending message. Please try again!");
            props.onSubmitErrorAnalytics && props.onSubmitErrorAnalytics();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (e.target.message.value) {
            const emailObject = {
                message: getFormattedMessage(e.target.message.value),
                replyTo: email,
                senderFirstName: firstName,
                senderLastName: lastName,
                subject: `Search feedback from ${firstName} ${lastName} at ${dealership}`,
                toFields: AppSettings.ContactEmail,
            };
            sendEmail(emailObject);
        }
    };

    const SearchFeedbackForm = () => {
        return (
            <form className="feedback-form" onSubmit={handleSubmit}>
                <label className="form-label">
                    <span className="label-text">Message: *</span>
                    <textarea autoFocus={!props.shouldStayOpen} className="form-message" id="message" />
                </label>
                <span className="required">* Required Field</span>
                <div className="form-buttons">
                    <input
                        className="button-cancel"
                        onClick={handleCancelClick}
                        type="reset"
                        value="Cancel"
                    />
                    <input className="button-submit" type="submit" value="Submit" />
                </div>
            </form>
        );
    };

    return (
        <div className={`search-feedback ${props.className}`} ref={formRef}>
            <div className={`search-feedback-link ${props.shouldStayOpen ? 'static' : ''}`} onClick={() => { toggleVisible(); setLinkText(defaultText) }}>{linkText}</div>
            {formVisible && <SearchFeedbackForm />}
        </div>
    );
};

export default SearchFeedback;