import React from 'react';
import DisclaimersComponent from '../disclaimers/disclaimers.component';
import { MediaTextComponent } from '../mediaText/mediaText.component';
import '../../styles/css/general-features-component.css';

export function FeaturesTemplate(component) {
  const featureItems = component.state.featuresItems ? component.state.featuresItems.map((feature, index) => {
    // check for different prop names
    // eslint-disable-next-line no-nested-ternary
    const imageToUse = feature.headerImage ? [feature.headerImage] : (feature.image ? [feature.image] : null);
    return (
      <div key={index}>
        <MediaTextComponent
          index={index}
          title={feature.title}
          subtitle={feature.subtitle}
          description={feature.description}
          files={feature.files ? feature.files : null}
          images={imageToUse}
          imagePath={component.props.imagePath}
          link={feature.link}
          linkClick={component.props.linkClick}
          buttonText={feature.buttonText}
          fileName={feature.fileName}
          disclaimer={component.disclaimer}
          imageArrow={component.props.imageArrow}
          alternate={component.props.alternate}
          readMoreClick={component.props.readMoreClick}
          availableOn={feature.availableOn}
          sendVideoEventAnalytics={component.props.sendVideoEventAnalytics}
          enableCustomLinks={false}
          qumuID={feature.qumuId}
        />
      </div>
    );
  }) : null;

  return (
    <div className="features_component">
      <div className="features">
        {featureItems}
      </div>
      <DisclaimersComponent template={component.props.disclaimer} ref={(instance) => { component.disclaimer = instance; }} />
    </div>
  );
}
