import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();

const contentClicked = (tag, section, page, category, label) => {
    analytics.fireOnClick({
      tag,
      section,
      page,
      category,
      label
    });
};

export const handleClick = (modelName, label) => {
    contentClicked(
        "29.2",
        "Product",
        modelName,
        "Features & Specs",
        label
    )
}