import { GlobalAnalytics } from "../../analytics";

const analytics = new GlobalAnalytics();

// See more button click on missions cards
export const seeMoreClick = (clickAnalytics, pageName, analyticsCategory) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: pageName,
      category: analyticsCategory,
      label: "See More Button Click",
      contentTitle: "See More"
    });
  }
};

// See less button click on missions cards
export const seeLessClick = (clickAnalytics, pageName, analyticsCategory) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: pageName,
      category: analyticsCategory,
      label: "See Less Button Click",
      contentTitle: "See Less"
    });
  }
};

// Tracking click for a mission card button click
export const missionCardButtonClick = (clickAnalytics, contentTitle) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: clickAnalytics.page || "Engage XP",
      category: clickAnalytics.category || "My Missions",
      label: clickAnalytics.label || "Mission Card Button",
      contentTitle: clickAnalytics.contentTitle || contentTitle
    });
  }
};