import React from "react";
import "../../../styles/scss/profilePage/profile-page-navigation-bar.scss";

const ProfilePageNavigationBar = ({ activeTab, onTabClick }) => {

  return (
    <nav className="navigation-bar">
      <div className={`tab ${activeTab === "Most Recent Comments" ? "active" : ""}`} onClick={() => onTabClick("Most Recent Comments")}>
        Most Recent Comments
      </div>
      <div className={`tab ${activeTab === "Recently Visited Pages" ? "active" : ""}`} onClick={() => onTabClick("Recently Visited Pages")}>
        Recently Visited Pages
      </div>
    </nav>
  );
};

export default ProfilePageNavigationBar;
