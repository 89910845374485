import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import SubheaderComponent from '../subheader/subheader.component';
import { EngageLiveStreamComponent } from './engageLiveStream.component';
import { EngageLiveArchivedComponent } from './engageLiveArchived.component';
import { getLocale } from '../../services/languageTranslationService';
import Display404NotFound from '../../components/atoms/404NotFound';

export const EngageLiveRouterComponent = (props) => {
  const [languageOverride, setLanguageOverride] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const language = getLocale();
    if (language === 'es') {
      setLanguageOverride(true);
    }
  }, []);

  const getSubheaderChildComponent = (path) => {
    let childComponent = null;
    const history = useHistory();
    switch (path.toLowerCase()) {
      case 'livevideo':
        childComponent = <EngageLiveStreamComponent tab="engageLive.liveVideo" languageOverride={languageOverride} />;
        break;
      case 'archivedvideos':
        childComponent = <EngageLiveArchivedComponent tab="engageLive.archivedVideos" languageOverride={languageOverride} />;
        break;
      default:
        history.push('/livevideo');
        break;
    }
    return childComponent;
  };
  const params = useParams();
  const route = {
    engine: '',
    featSpec: undefined,
    model: '',
    path: params.page,
    trim: '',
    year: params.year,
  };
  const splat = params && params[0];
  const isValidPageName = () => ['liveVideo', 'archivedVideos'].filter(x => x === splat).length > 0;
  const getPageDisplay = () => {
    if (isValidPageName()) {
      return (
        <div>
          <SubheaderComponent {...props} route={route} params={params}>
            {getSubheaderChildComponent(splat)}
          </SubheaderComponent>
        </div>
      );
    }
    return <Display404NotFound display />;
  };

  return (
  <div>{getPageDisplay()}</div>
  );
};

export default injectIntl(EngageLiveRouterComponent);
