import React, { useRef } from 'react';
import '../../../styles/css/smartpath-overview-advantages.css';
import applyMarkdown from '../../utils/applyMarkdown';
import * as advantagesAnalytics from '../overviewAnalytics/smartpath-overview-advantages.analytics';
import useOnScreen from '../useIntersection';

export const SmartPathOverviewAdvantages = (props) => {
  const advantagesRef = useRef();
  if (useOnScreen(advantagesRef) && props.pageLoadAnalytics) {
    advantagesAnalytics.advantagesSubLoad(props.pageLoadAnalytics);
  }

  const isClicked = new Array(props.advantages.length).fill(false);
  isClicked[0] = true;

  const [advantagesInformation, setAdvantagesInformation] = React.useState(
    {
      title: props.advantages[0].title,
      description: props.advantages[0].description,
      stats: props.advantages[0].stats,
      activeItems: isClicked,
    }
  );

  const getNewState = (advantages, index) => {
    isClicked.fill(false);
    isClicked[index] = true;
    return {
      title: advantages.title,
      description: advantages.description,
      stats: advantages.stats,
      activeItems: isClicked,
    }
  }

  const getItemclasses = (isActive) => ({
    imageActive: isActive ? "smartpath-advantages-imageActive" : "smartpath-advantages-imageInactive",
    imageInactive: isActive ? "smartpath-advantages-imageInactive" : "smartpath-advantages-imageActive",
    line: isActive ? "smartpath-advantages-lineActive" : "smartpath-advantages-lineInactive",
    imageTitle: isActive ? "smartpath-advantages-imageTitleActive" : "smartpath-advantages-imageTitleInactive",
  })

  const handleClick = (imageTitle, state) => {
    if (props.clickAnalytics && imageTitle) {
      advantagesAnalytics.tileClick(props.clickAnalytics, imageTitle);
    }
    setAdvantagesInformation(state);
  };

  const advantageHeader = props.advantages.map((advantage, index) => (
    <div className="smartpath-advantages"
      onClick={() => handleClick(advantage.imageTitle, getNewState(advantage, index))}
    >
      <div className="smartpath-advantages-header">
        <div className={getItemclasses(advantagesInformation.activeItems[index]).imageInactive}><img src={props.imagePath + advantage.inactiveImage} /></div>
        <div className={getItemclasses(advantagesInformation.activeItems[index]).imageActive}><img src={props.imagePath + advantage.activeImage} /></div>
        <div className={getItemclasses(advantagesInformation.activeItems[index]).line} />
        <div className={getItemclasses(advantagesInformation.activeItems[index]).imageTitle}>{applyMarkdown(advantage.imageTitle)}</div>
      </div>
    </div>
  ));

  const removeNullStats = advantagesInformation.stats.filter(stat => stat.image != null);

  const stats = removeNullStats.map(stat => (
    <div className="smartpath-advantages-stats">
      <div className="smartpath-advantages-stats-iconStats">
        <div className="smartpath-advantages-stats-icon"><img src={props.imagePath + stat.image} /></div>
        <div className="smartpath-advantages-stats-text">{stat.statistic}</div>
      </div>
      <div className="smartpath-advantages-blackLine"></div>
      <div className="smartpath-advantages-stats-description">{applyMarkdown(stat.description)}</div>
    </div>
  ));

  const advantageContent = (
    <div className="smartpath-advantages-subSection">
      <div>
        <div className="smartpath-advantages-subSection-title">{advantagesInformation.title}</div>
        <div className="smartpath-advantages-subSection-description">{applyMarkdown(advantagesInformation.description)}</div>
      </div>
      <div className="smartpath-advantages-footer">{stats}</div>
    </div>
  );

  return (
    <div ref={advantagesRef} className="smartpath-advantages-container">
      <div className="smartpath-advantages-container-alignment">
        {advantageHeader}
      </div>
      {advantageContent}
    </div>
  );
};
