import React, { createRef, useEffect } from 'react';
import leftArrowIcon from '../../../../../resources/images/white_arrow_left.png';

export default function StuckButton(props) {
  const ref = createRef();

  useEffect(() => {
    const cachedRef = ref.current;
    const observer = new IntersectionObserver(([e]) => props.setIsOffScreen(e.intersectionRatio === 0), { threshold: [0] });
    observer.observe(cachedRef);

    return (() => {
      observer.unobserve(cachedRef);
    });
  });

  return (
    <button className={'stuck-back-button'} onClick={props.onClick} ref={ref} setIsOffScreen={props.setIsOffScreen}>
      <span className='back-button-wrapper'>
        <img src={leftArrowIcon}/>
        <span className='back-text'>  Browse Comparisons</span>
      </span>
    </button>
  );
}
