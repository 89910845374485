import React from 'react';
import { FormattedMessage } from 'react-intl';
import Vehicles from './vehicles.component';
import DataTable from './dataTable.component';
import StickyVehicles from './stickyVehicles.component';
import applyMarkdown from '../../utils/applyMarkdown';
import '../../../styles/css/thirdPartyComparison.css';
import { StickyContainer, Sticky } from 'react-sticky';

export function ThirdPartyComparisonsTemplate(component) {
  function NoJATOData(props) {
    return (<div className="no-response"><FormattedMessage id="global.dataUnavailable" defaultMessage="JATO is unavailable." /></div>);
  }

  return (
    <div>
      <div className="comparison-container">
        {component.state.error ? <NoJATOData />
          : <div>
            <div className="jato-disclaimer-container">
              <div className="jato-disclaimer"><FormattedMessage id="global.jatoDisclaimer" /></div>
              <div className="jato-disclaimer"><FormattedMessage id="global.jatoDisclaimer2" /></div>
            </div>
            <Vehicles vehicles={component.state.vehicles} comparisonReport={component.state.comparisonReport} loadingMessage={component.state.loadingMessage} availableVehicles={component.state.availableVehicles} onVehicleChange={component.handleVehicleChange} vehicleModelName={component.state.modelName} vehicleModelYear={component.state.modelYear} />
            <StickyContainer>
              <Sticky disableCompensation>
                {({ isSticky, style }) => <StickyVehicles vehicles={component.state.vehicles} show={isSticky} style={style} />}
              </Sticky>
              <DataTable comparisonReport={component.state.comparisonReport ? component.state.comparisonReport.comparisonData : null} loadingMessage={component.state.loadingMessage} />
            </StickyContainer>
            <div className="comparison-container-legal-text">{applyMarkdown(component.getLegalText())}</div>
          </div>
        }
      </div>
    </div >
  );
}
