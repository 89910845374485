import React from 'react';
import GrayIcon from '../../resources/images/Gray_Bookmark.png';
import RedIcon from '../../resources/images/Red_Bookmark.png';
import '../../styles/css/bookmark/bookmark.css';

export function BookmarkPageTemplate(component) {
  return (
    <React.Fragment>
      {component.props.isButton ?
        <div className='bookmark-button' onClick={component.toggleBookmark}>
          <img className='bookmark-icon' src={component.state.pageIsBookmarked ? RedIcon : GrayIcon} />
          <div className={`bookmark-text ${component.state.pageIsBookmarked ? 'bookmarked' : ''}`}>Bookmark</div>
        </div>
        :
        <div className="bookmark-ribbon" onClick={component.toggleBookmark}>
          <img src={component.state.pageIsBookmarked ? RedIcon : GrayIcon} />
        </div>
      }
    </React.Fragment>
  );
}
