import React, { useState, useEffect, useRef } from 'react';
import '../../styles/css/searchQuickAnswers.css';
import { ColorAvailabilitySearchItemComponent } from './resultComponents/colorAvailabilitySearchItem.component';
import { ColorByTrimSearchItemComponent } from './resultComponents/colorByTrimSearchItem.component';
import { PackageByTrimSearchItemComponent } from './resultComponents/PackageByTrimSearchItem.component';
import { FeatureSearchItemComponent } from './resultComponents/featureSearchItem.component';
import { GroupFeatureSearchItemComponent } from './resultComponents/groupFeatureSearchItem.component';
import { TssSearchItemComponent } from './resultComponents/tssSearchItem.component';
import { SpecSearchItemComponent } from './resultComponents/specSearchItem.component';
import { ModelCodeSearchComponent } from './resultComponents/modelCodeSearch.component';
import RedLine from '../../components/atoms/RedLine';
import CarouselComponent from '../carousel/carousel.component';
import { enhancedSearchClick } from './searchBar.analytics';
import applyMarkdown from '../utils/applyMarkdown';
import { generateResultLinkout, toggleVisibilityAnalytics } from './resultHelperFunctions';
import { generateLearnMoreButton, getQuickAnswerType, singleSlideTab } from './resultHelperFunctions';
import { isDesktopView } from '../../screens/utils/getScreenSize.js';
import DisclaimersComponent from '../disclaimers/disclaimers.component';

const SearchQuickAnswers = (props) => {

  const [visible, setVisible] = useState(false);
  const ref = useRef(null);
  const disclaimerComponentRef = useRef();

  function CarouselDots(Items) {
    const {
      index, items,
    } = Items;
    const tabClassName = checkTruncated(index) ? 'tabName tabNameHover' : 'tabName';
    return (
      // eslint-disable-next-line no-undef
      <div className="dot" key={index} onClick={() => slideChangeAnalytics(items, index, isDesktopView(window.innerWidth) ? 'tab' : null)}>
        <div className={tabClassName}>{applyMarkdown(items[index]._source.descriptionToShow)}</div>
        <div className="tabArrow"><img src={props.imagePath + "Search_Path_Icon.png"} /></div>
      </div>
    );
  }

  function getSlideChangeAnalyticsLabel(clickType, index) {
    switch (clickType) {
      case 'tab':
        return props.clickAnalytics.enhancedSearch.labelListSelect;
      case 'left':
        return props.clickAnalytics.enhancedSearch.labelCarouselArrowLeft;
      case 'right':
        return props.clickAnalytics.enhancedSearch.labelCarouselArrowRight;
      default:
        return props.clickAnalytics.enhancedSearch.labelCarouselDot + (index + 1);
    }
  }

  function getSlideNumber(items, index) {
    if (index < 0) {
      return items.length - 1;
    } else if (index >= items.length) {
      return 0;
    } else {
      return index;
    }
  }

  function slideChangeAnalytics(items, index, clickType) {
    const newSlideNumber = getSlideNumber(items, index)
    const positionTitle = `${props.vehicle.modelYear} ${props.vehicle.model}`;
    const contentTitle = items[newSlideNumber]._source.descriptionToShow;
    const label = `${getQuickAnswerType(items[newSlideNumber]._source.type)}:${getSlideChangeAnalyticsLabel(clickType, newSlideNumber)}`;
    if (props.clickAnalytics) {
      enhancedSearchClick(
        props.clickAnalytics,
        positionTitle,
        contentTitle,
        label,
        props.vehicle.modelYear,
        props.vehicle.model,
        props.component.state.searchParam,
        props.component.state.totalResults,
      );
    }
  }

  function checkTruncated(index) {
    if (ref.current && ref.current.children) {
      const el = ref.current.children[0].children[3].children[index].children[0].children[0].children[0];
      return el.offsetWidth < el.scrollWidth;
    } else {
      return false;
    }
  }

  function PrevArrow(Items) {
    const {
      onClick, items, currentSlide,
    } = Items;
    return (
      // eslint-disable-next-line no-undef
      <div className={'slick-prev'} style={{background: 'url('+(props.imagePath)+'quickAnswerArrow.png;'}} onClick={() => {slideChangeAnalytics(items, (currentSlide - 1), 'left'); onClick()}}><img src={props.imagePath + 'quickAnswerArrow.png'} /></div>
    );
  }

  function NextArrow(Items) {
    const {
      onClick, items, currentSlide,
    } = Items;
    return (
      // eslint-disable-next-line no-undef
      <div className={'slick-next'} style={{background: 'url('+(props.imagePath)+'quickAnswerArrow.png;'}} onClick={() => {slideChangeAnalytics(items, (currentSlide + 1), 'right'); onClick()}}><img src={props.imagePath + 'quickAnswerArrow.png'} /></div>
    );

  }

  function getSearchQuickAnswers(highlightItems) {
      let features = null;
      if (highlightItems && highlightItems.length > 0) {
        if(highlightItems.length === 1 || highlightItems[0]._source.type.includes('modelCode') || highlightItems[0]._source.type.includes('packageByTrim') ){
          features = highlightItems[0]._score > 5 ? createSearchResultItem(highlightItems[0]._source, 0, props.component) : null;
        }
        else {
          let highlightItemsToShow
          if(isDesktopView(window.innerWidth) == true){
           highlightItemsToShow = highlightItems.slice(0,5);
          }
          else{
            highlightItemsToShow = highlightItems.slice(0,4);
          }
          features = <div ref={ref}>
            <CarouselComponent ref={c => (props.component.carousel = c)}{...{
            settings: {
              customPaging(index) {
                return <button><CarouselDots items={highlightItemsToShow} index={index} /></button>;
              },
              adaptiveHeight: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              respondTo: 'window',
              zIndex: 0,
              dots: true,
              infinite: true,
              autoplay: false,
              autoplaySpeed: 0,
              nextArrow: <NextArrow items={highlightItemsToShow} />,
              prevArrow: <PrevArrow items={highlightItemsToShow} />,
            },
          }}>{getMultipleFeatures(highlightItemsToShow)}</CarouselComponent>
        </div>;
      }
    }
    return features;
  }

  function getQuickAnswerType(type) {
    switch (type) {
      case 'feature':
        return 'Features';
      case 'groupFeature':
        return 'Features';
      case 'spec':
        return 'Specs';
      case 'msrp':
        return 'Pricing';
      case 'tss':
        return 'TSS';
      case 'colorAvailability':
      case 'colorByTrim':
        return 'Exterior Colors';
      case 'packageByTrim':
        return 'Packages';
      case 'modelCode':
        return 'Model Codes';
      default:
        return type;
    }
  }

  function createSearchResultItem(item, index, itemComponent) {
    const modelYearKey = `${item.model}${item.year}${item.description}`;
    const singleSlide = (itemComponent.state.highlightItems.length == 1 || props.component.state.highlightItems[0]._source.type.includes('modelCode')) ? singleSlideTab(item.descriptionToShow, props.imagePath, openDisclaimer) : null;
    const singleSlideTSS = singleSlideTab(item.Description, props.imagePath, openDisclaimer);
    switch (item.type) {
      case 'feature':
        return <div className="item-container">
            {singleSlide}
            <FeatureSearchItemComponent
            item={item}
            index={index}
            parentComponent={itemComponent}
            imagePath={props.imagePath}
            // disclaimerCallback={itemComponent.disclaimerCallback}
            ></FeatureSearchItemComponent>
          </div>;
      case 'groupFeature':
        return <div className="item-container">
        {singleSlide}
          <GroupFeatureSearchItemComponent
          item={item}
          index={index}
          parentComponent={itemComponent}
          imagePath={props.imagePath}
          disclaimerCallback={itemComponent.disclaimerCallback}
          ></GroupFeatureSearchItemComponent>
        </div>;
      case 'spec':
        return <div className="item-container">
          {singleSlide}
          <SpecSearchItemComponent
          item={item}
          index={index}
          parentComponent={itemComponent}
          imagePath={props.imagePath}
          disclaimerCallback={itemComponent.disclaimerCallback}
          ></SpecSearchItemComponent>
          </div>;
      case 'msrp':
        return <div className="item-container">
          {singleSlide}
          <SpecSearchItemComponent
            item={item}
            index={index}
            parentComponent={itemComponent}
            imagePath={props.imagePath}
          // disclaimerCallback={itemComponent.disclaimerCallback}
          ></SpecSearchItemComponent>
        </div>;
      case 'tss':
        return <div className="item-container">
          {singleSlideTSS}
          <TssSearchItemComponent
          item={item}
          index={index}
          parentComponent={itemComponent}
          imagePath={props.imagePath}
          ></TssSearchItemComponent>
        </div>;
      case 'colorAvailability':
        return <div className="item-container">
          {singleSlide}
          <ColorAvailabilitySearchItemComponent
          item={item}
          index={index}
          parentComponent={itemComponent}
          imagePath={props.imagePath}
          //disclaimerCallback={itemComponent.disclaimerCallback}
          key={modelYearKey}
          ></ColorAvailabilitySearchItemComponent>
        </div>;
      case 'colorByTrim':
        return <div className="item-container">
          {singleSlide}
          <ColorByTrimSearchItemComponent
          item={item}
          index={index}
          parentComponent={itemComponent}
          imagePath={props.imagePath}
          //disclaimerCallback={itemComponent.disclaimerCallback}
          key={modelYearKey}
          ></ColorByTrimSearchItemComponent>
        </div>;
      case 'packageByTrim':
        return <PackageByTrimSearchItemComponent
          item={item}
          index={index}
          parentComponent={itemComponent}
          imagePath={props.imagePath}
          disclaimerCallback={itemComponent.disclaimerCallback}
          key={modelYearKey}
          ></PackageByTrimSearchItemComponent>;
      case 'modelCode':
        return <div className="item-container">
          {singleSlide}
          <ModelCodeSearchComponent
          item={item}
          index={index}
          parentComponent={itemComponent}
          imagePath={props.imagePath}
          disclaimerCallback={itemComponent.disclaimerCallback}
          key={modelYearKey}
          ></ModelCodeSearchComponent>
        </div>;
    }
  }

  function getMultipleFeatures(highlightItems) {
      return highlightItems.map((item, index) => (<div key={index} className="search-item">
        {createSearchResultItem(item._source, index, props.component)}
      </div>));
  }

  function toggleVisibility(toggle) {
    toggleVisibilityAnalytics(toggle, false, props);
    setVisible(toggle);
  }

  const SearchQuickAnswersBody = () => {
    return (
      <div className="quickAnswersBody">

        <div className="quickAnswersMobileCarousel">
          {getSearchQuickAnswers(props.component.state.highlightItems)}
        </div>

        <div className="quickAnswersDesktopCarousel">
          {getSearchQuickAnswers(props.component.state.highlightItems)}
        </div>

      </div>
    );
  }

  const openDisclaimer = () => {
    disclaimerComponentRef.current.onOpenModal()
  }

  let quickAnswersHeaderTitle = !props.component.state.currentVehicle.displayName ? props.component.state.currentVehicle.model : props.component.state.currentVehicle.displayName ;
  return (
    <div className="quickAnswers">
      <div className="quickAnswersHeader">
        <div className="quickAnswersHeaderTitle">
          <span>{props.component.state.currentVehicle.modelYear} </span>
          <span className="model">{quickAnswersHeaderTitle}</span>
        </div>
        <div className="quickAnswersText">quick answers{visible && <span className="nonDesktop"> / <span className="quickAnswersType">{getQuickAnswerType(props.component.state.highlightItems[0]._source.type)}</span></span>}</div>
        {visible && <RedLine />}
      </div>
      {visible && <div className="quickSearchDesktopType">{getQuickAnswerType(props.component.state.highlightItems[0]._source.type)}</div>}
      {visible && <SearchQuickAnswersBody />}
      <div onClick={() => toggleVisibility(true)} className="quickAnswersExpandButton"> {!visible && <img className="arrowButton" src={props.imagePath + "Down_Arrow_Black.png"} />}</div>
      <div className="quickAnswersCollapseButton" onClick={() => toggleVisibility(false)}>{visible && <img className="arrowButton" src={props.imagePath + "Up_Arrow_Black.png"} />}</div>
      <DisclaimersComponent
        ref={disclaimerComponentRef}
        addBaseMSRPDisclaimer={true}
      />
    </div>
  );
}

export default SearchQuickAnswers;