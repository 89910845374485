import { useEffect, useMemo, useRef, useState } from "react";
import AppSettings from "../../../appsettings";
import { getArticleThumbnailPath } from "../../../screens/utils/articleThumbnailHelpers";
import "../../../styles/scss/articles/browse-articles.scss";
import ContentCard from "../cards/contentCard";
import RoundedButton from "../roundedButton";

export const BrowseArticles = ({ articles, articleAnalytics, seeMoreAnalitycs, articleFilterAnalytics }) => {
  const [numToShow, setNumToShow] = useState(8);
  const [filter, setFilter] = useState(null);
  const [filteredContent, setFilteredContent] = useState(articles);
  const [noContent, setNoContent] = useState(false);
  const allArticlesLoaded = useMemo(() => {
    return numToShow >= filteredContent?.length;
  }, [numToShow, filteredContent]);

  const activeRef = useRef();

  const categories = ["video", "article", "news"];

  const getCategoryIcon = (categories) => {
    if (categories?.find((category) => category === "video")) return "Videos.svg";
    if (categories?.find((category) => category === "news")) return "News.svg";
    return "Articles.svg";
  };

  useEffect(() => {
    if(filter === 'article') {
      setFilteredContent(
        articles?.filter((article) =>
            !article?.categories?.includes('video') && !article?.categories?.includes('news')
        )
      );
    } else {
      setFilteredContent(
        articles?.filter((e) =>
          filter
            ? e.categories?.find(
                (category) => category?.toLowerCase() === filter.toLowerCase()
              )
            : true
        )
      );
    }
    filter && activeRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  }, [filter, articles]);

  const handleClick = (filter, label) => {
    setFilter(filter);
    articleFilterAnalytics && articleFilterAnalytics(label);
  };

  useEffect(() => {
    if(filteredContent && filteredContent.length === 0) {
      setNoContent(true);
    } else {
      setNoContent(false);
    }
  }, [filteredContent, filter]);

  const paginationNumber = useMemo(() => {
    // desktop
    if (window.innerWidth >= 1200) {
      return 8;
    }

    // large tablet
    if (window.innerWidth < 1200 && window.innerWidth >= 1024) {
      return 6;
    }

    // tabled
    if (window.innerWidth < 1024 && window.innerWidth >= 640) {
      return 4;
    }

    // mobile
    return 3;
  }, [window.innerWidth]);

  useEffect(() => {
    setNumToShow(paginationNumber);
  }, [paginationNumber]);

  const toggleSeeMore = () => {
    if (!allArticlesLoaded) {
      setNumToShow(numToShow + paginationNumber);
      seeMoreAnalitycs && seeMoreAnalitycs('More');
    } else {
      setNumToShow(paginationNumber);
      seeMoreAnalitycs && seeMoreAnalitycs('Less');
    }
  };

  const getCategoryDisplayName = (name) => {
    switch(name) {
      case 'video':
        return 'videos';
      case 'article':
        return 'articles';
      default:
        return name;
    }
  };

  return (
    <div className="resources-browse-articles">
      <div className="browse-article-header-wrapper">
        <div className="browse-title">Browse</div>
        <div className="browse-article-filters-fade"></div>
        <div className="browse-article-filters">
          <div ref={!filter ? activeRef : null}>
            <RoundedButton
              title={"ALL"}
              onClick={() => handleClick(null, "ALL")}
              active={!filter}
              color={"#00000000"}
              activeColor={"#00000000"}
              borderColor={!filter ? "#000000" : "#00000000"}
              textColor={"#000000"}
              height={"34px"}
              width="84px"
            />
          </div>
          {categories?.map((f) => (
            <div key={f} ref={filter == f ? activeRef : null}>
              <RoundedButton
                title={getCategoryDisplayName(f).toUpperCase()}
                onClick={() => handleClick(f, f.toUpperCase())}
                active={filter == f}
                color={"#00000000"}
                activeColor={"#00000000"}
                borderColor={filter == f ? "#000000" : "#00000000"}
                textColor={"#000000"}
                height={"34px"}
                width="94px"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="browse-articles">
        {filteredContent ? filteredContent?.slice(0, numToShow).map((article) => (
          <ContentCard
            key={article.id}
            link={`/articles/${article.id}`}
            title={article.title}
            imageClass="aspect-ratio"
            image={getArticleThumbnailPath(article)}
            cornerIcon={
              AppSettings.AWSImgRepo.homepage_v3 +
              getCategoryIcon(article.categories)
            }
            // correct video overlay icon needed
            overlayImage={article.categories?.includes("video") && 
              `${AppSettings.AWSImgRepo.resources}en/playButton.png`
            }
            hideCornerIcon={filter}
            style="compact"
            analytics={() => articleAnalytics && articleAnalytics(article.title, "Browse")}
          />
        )) : Array.from({length: numToShow}).map(i => (
          <ContentCard loading key={i} />
        ))}
      </div>
      {noContent && <div className="noResponse">No content for the selected category.</div>}
      {filteredContent?.length > paginationNumber && 
        <div className="see-more-button" onClick={() => toggleSeeMore()}>
          {allArticlesLoaded ? "SEE LESS" : "SEE MORE"}
        </div>
      }
    </div>
  );
};
