import 'whatwg-fetch';
import httpOption from './httpOption';
import AppSettings from '../appsettings';

export async function getBookmarks() {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/bookmarks/getUserBookmarks`;
  const options = httpOption.GET();
  const fetchResults = await fetch(url, options);

  if (resultsAreValid(fetchResults)) {
    return fetchResults.json();
  }
}

export async function addBookmark(bookmark) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/bookmarks/putUserBookmarks`;
  const options = httpOption.PUT();
  options.body = JSON.stringify(bookmark);
  const fetchResults = await fetch(url, options);

  if (resultsAreValid(fetchResults)) {
    return fetchResults.json();
  }
}

export async function deleteBookmark(bookmark) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/bookmarks/deleteUserBookmarks`;
  const options = httpOption.DELETE();
  options.body = JSON.stringify(bookmark);
  const fetchResults = await fetch(url, options);

  if (resultsAreValid(fetchResults)) {
    return fetchResults.json();
  }
}

const resultsAreValid = results => {
  return results.status >= 200 && results.status < 300;
}
