import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { certificationPageLoaded, resourcesFilterClick } from '../certification.analytics';
import { FilterMenu } from '../../filterMenu/filterMenu.component';
import Announcements from './certificationAnnouncements';
import Cornerstone from './certificationResourcesCornerstone';
import DisclaimersComponent from '../../disclaimers/disclaimers.component';
import SectionHeader from '../../../components/atoms/SectionHeader';
import SupportContacts from './certificationSupportContacts';
import AppSettings from '../../../appsettings';
import history from '../../app/history';
import '../../../styles/css/certificationResources.css';
import * as _ from 'lodash';

const certificationResources = (props) => {
  const [activeFilter, setActiveFilter] = useState('');
  const [filters, setFilters] = useState(null);
  const [disclaimer, setDisclaimer] = useState(null);
  const disclaimerRef = useRef();

  useEffect(() => {
    setDisclaimer(disclaimerRef.current);
  }, [disclaimerRef]);

  useEffect(()=>{
    certificationPageLoaded(props.pageLoadAnalytics, "Resources");
  },[]);
  // TODO: Refactor this filter flag process
  const announcementsSystemStatusName = 'Announcements';

  const formatFilterID = (filter) => {
    const id = _.camelCase(filter);
    return id;
  };

  const formatFilters = (resourcesFilters) => {
    const availableFilters = [];

    _.forIn(resourcesFilters, (filter) => {
      const hideAnnouncementsSystemStatus = !AppSettings.FeatureFlags.showCertificationsAnnouncementsSystemStatus && filter.filterName === announcementsSystemStatusName;
      if (!hideAnnouncementsSystemStatus) {
        availableFilters.push({
          data: filter.filterdata,
          id: formatFilterID(filter.filterName),
          label: filter.filterName,
          url: formatFilterID(filter.filterName),
        });
      }
    });

    setFilters(availableFilters);
  };

  useEffect(() => {
    const query = queryString.parse(location.search);
    let defaultFilter;
    if (AppSettings.FeatureFlags.showCertificationsAnnouncementsSystemStatus) {
      defaultFilter = props.resourcesData.filters[0];
    } else {
      defaultFilter = props.resourcesData.filters.find(filter => filter.filterName !== announcementsSystemStatusName)
    }
    setActiveFilter(query.tab ? query.tab : formatFilterID(defaultFilter.filterName));
    formatFilters(props.resourcesData.filters);
  }, []);

  const switchFilter = (event, filter) => {
    const selectedFilter = filter.id;
    const curFilter = _.find(filters, ['id', activeFilter]);
    resourcesFilterClick(props.clickAnalytics, props.pageName, curFilter.label, filter.label);
    setActiveFilter(selectedFilter);
    history.replace(`/certification/resources?tab=${filter.url}`);
  };

  const filteredComponent = () => {
    let component;
    const filterData = _.find(filters, ['id', activeFilter]);
    switch (activeFilter) {
      case 'cornerstone':
        component = <Cornerstone
          clickAnalytics={props.clickAnalytics}
          data={filterData.data}
          disclaimer={props.disclaimer}
          imagePath={props.imagePath}
          internalFilePath={AppSettings.AWSFileRepo.certification + 'en/'}
          pageName={_.startCase(activeFilter)}
          parentPageName={props.parentPageName}
          subheaderPageName={props.subheaderPageName}
        />;
        break;
      case 'announcements':
        component = <Announcements
          data={filterData.data}
          pageName={announcementsSystemStatusName}
        />;
        break;
      case 'supportContacts':
        component = <SupportContacts
          data={filterData.data}
          disclaimer={disclaimer}
          imagePath={props.imagePath}
          pageName={'Support Contact'}
        />;
        break;
      default:
        component = <Cornerstone
          clickAnalytics={props.clickAnalytics}
          data={filterData.data}
          disclaimer={props.disclaimer}
          imagePath={props.imagePath}
          internalFilePath={AppSettings.AWSFileRepo.certification + 'en/'}
          pageName={_.startCase(activeFilter)}
          parentPageName={props.parentPageName}
          subheaderPageName={props.subheaderPageName}
        />;
        break;
    }

    return component;
  };

  return (
    <div>
      <div className="certification-resources">
        <SectionHeader>{props.resourcesData.header}</SectionHeader>
        <div className="certification-resources-filter-container">
          <FilterMenu
            active={activeFilter}
            filters={filters}
            filterClick={switchFilter}
            displayArrow={true}
            />
        </div>
        {filters && filteredComponent()}
        <DisclaimersComponent
          ref={(instance) => { disclaimerRef.current = instance; }}
          template={props.disclaimer}
        />
      </div>
    </div>
  );
};

certificationResources.propTypes = {
  clickAnalytics: PropTypes.shape({
    page: PropTypes.string,
    resourcesFilterCategory: PropTypes.string,
    section: PropTypes.string,
    tagId: PropTypes.string,
  }),
  disclaimer: PropTypes.string,
  imagePath: PropTypes.string,
  parentPageName: PropTypes.string,
  resourcesData: PropTypes.object,
  subheaderPageName: PropTypes.string,
};

export default certificationResources;
