import React, { useEffect, useState } from "react";
import MissionCard from "../../components/molecules/cards/missionCard";
import "../../styles/css/engageXP/missionList.css";
import { seeLessClick, seeMoreClick } from "./missionList.analytics";

const DEFAULT_TO_SHOW_DESKTOP = 5;
const DEFAULT_TO_SHOW_TABLET = 3;
const DEFAULT_TO_SHOW_MOBILE = 3;
const analyticsCategory = "My Missions";

const initialNumToShow = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth > 1595) return DEFAULT_TO_SHOW_DESKTOP;
  if (screenWidth > 905) return DEFAULT_TO_SHOW_TABLET;
  return DEFAULT_TO_SHOW_MOBILE;
};

const MissionList = ({
  isLoadingMissions,
  missionItems,
  selectedMissionStatus,
  getBlurredBadgeText,
  getColoredBadgeInfo,
  waitForMissionsLabels,
  analyticsData
}) => {
  const [showAllMissions, setShowAllMissions] = useState(false);
  const [numToShow, setNumToShow] = useState(initialNumToShow());
  const clickAnalytics = analyticsData?.clickAnalytics;
  const pageName = "Engage XP";

  useEffect(() => {
    const updateNumToShow = () => {
      setNumToShow(initialNumToShow());
    };

    window.addEventListener("resize", updateNumToShow);

    return () => {
      window.removeEventListener("resize", updateNumToShow);
    };
  }, []);

  const toggleShowMore = () => {
    setShowAllMissions(!showAllMissions);
  };

  const handleSeeMoreClick = () => {
    const missionsToAdd = initialNumToShow();
  
    const newNumToShow = Math.min(numToShow + missionsToAdd, missionItems.length);

    if (newNumToShow >= missionItems.length) {
      toggleShowMore();
    }
    setNumToShow(newNumToShow);

    if (clickAnalytics) {
      seeMoreClick(clickAnalytics, pageName, analyticsCategory);
    }
  };
  
  const handleShowLessClick = () => {
    setNumToShow(initialNumToShow());
    setShowAllMissions(false);

    if (clickAnalytics) {
      seeLessClick(clickAnalytics, pageName, analyticsCategory);
    }
  };

  const cardsToShow = selectedMissionStatus === 'Completed' ? missionItems.slice(0, numToShow) : missionItems;
  const isShowMoreVisible = numToShow < missionItems.length;

  const MissionGrid = () => {
    if (isLoadingMissions) {
      return (
        Array.from({ length: numToShow })
          .map((missionItem, index) => (
            <MissionCard
              isLoadingMissions={isLoadingMissions} 
              key={index} 
              mission={missionItem} 
              getBlurredBadgeText={getBlurredBadgeText}
              getColoredBadgeInfo={getColoredBadgeInfo} 
            />
          ))
      )
    } else {
      return (
        cardsToShow
          .map((missionItem, index) => (
            <MissionCard
              isLoadingMissions={isLoadingMissions} 
              key={index} 
              mission={missionItem} 
              getBlurredBadgeText={getBlurredBadgeText}
              getColoredBadgeInfo={getColoredBadgeInfo} 
              waitForMissionsLabels={waitForMissionsLabels}
              analyticsData={analyticsData}
            />
          ))
      )
    }
  }

  return (
    <div className="missions-list-container">
      {!missionItems.length && !isLoadingMissions ? (
          <div className="no-missions-to-show">{"No missions to show"}</div>
        ) : (
          <div className="missions-list-wrapper">
            <MissionGrid />
          </div>
      )}
      {selectedMissionStatus === "Completed" && (
        <div className="missions-list-see-more-container">
          {showAllMissions ? (
            <button
              onClick={handleShowLessClick}
              className="missions-list-see-more-button"
            >
              SEE LESS
            </button>
          ) : (
            isShowMoreVisible && (
              <button
                onClick={handleSeeMoreClick}
                className="missions-list-see-more-button"
              >
                SEE MORE
              </button>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default MissionList;
