import PropTypes from 'prop-types';
import React from 'react';
import '../../../styles/css/contactUs/contactUsForm/contactUsFormField.css';

const ContactUsFormField = (props) => {
    const handleValueChange = (event) => {
        props.updateValue(event.target.value);
    }
    const handleFormClick =() => {
        if (!props.formClicked){
            props.clickFunction(props.clickAnalytics);
            props.setFormClicked(true);
        }
    }

    return (
        <React.Fragment>
            <div className={`contactUsFormField ${props.multiline || props.singlecolumn ? 'multiline' : 'singleline'}`} >
                {props.name}
                {props.multiline ?
                    <textarea
                        disabled={props.sending}
                        className={`contactUsFormFieldValueMultiline ${props.showValidations && !props.value ? 'invalid' : ''}`}
                        value={props.value}
                        onChange={handleValueChange}
                        onClick = {()=>{handleFormClick()}}
                    />
                    :
                    <input
                        disabled={props.sending}
                        className={`contactUsFormFieldValue ${props.showValidations && !props.value ? 'invalid' : ''}`}
                        value={props.value}
                        onChange={handleValueChange}
                        onClick = {()=>{handleFormClick()}}
                    />
                }
            </div>
        </React.Fragment>
    )
};

ContactUsFormField.propTypes = {
    name: PropTypes.string.isRequired,
    sending: PropTypes.bool,
    singlecolumn: PropTypes.bool,
    value: PropTypes.string,
    updateValue: PropTypes.func
};

export default ContactUsFormField;