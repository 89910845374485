import React from 'react';
import '../../../../styles/css/beyondZero/stepTracker.css'
import appsettings from '../../../../appsettings';

const EVStepTracker = ({ questionNum, questions}) => {
    const completeImg = 'black-circle-white-check.png';

    return (
        <div className='step-tracker-container'>
            {questions?.map((question, index) => {
                return (
                    <React.Fragment key={index}>
                        {index > 0 && 
                            <div className="step-line"></div>
                        }
                        
                        <div className='step-node-container'>
                            {index <= questionNum ?
                                <img src={appsettings.AWSImgRepo.beyondZero + completeImg} alt='Check'/> 
                            :
                                <div className='step-circle-inner'></div>
                            }
                            <div className={`step-label${index <= questionNum ? ' bold-label' : ''}`}>{`${question?.oneWord}`}</div>
                        </div>
                    </React.Fragment>
                );
            })}
        </div>
    );
}

export default EVStepTracker;