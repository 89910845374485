import React, { useEffect } from "react";
import ElectrifiedComparison from "../sharedComponents/electrifiedComparison";
import VehicleCarousel from "./components/vehicleCarousel";
import ElectrifiedChart from "./components/Chart";
import "../../../styles/css/beyondZero/electrifiedDiversifiedBase.css"
import { bzElectrifiedDiversifiedClick, bzElectrifiedDiversifiedPageload, bzElectrifiedDiversifiedTabsClick } from "./electrifiedDiversified.analytics";
import { postUserActivityRecord } from "../../../services/userActivityService";

const ElectrifiedDiversified = ({ electrifiedDiversifiedData, electrifiedComparisonData }) => {
  useEffect(() => {
    bzElectrifiedDiversifiedPageload({
      page: 'Electrified Diversified'
    });
    postUserActivityRecord();
  }, [])

  return (
    <div className="electrified-diversifed-container">

      <div className="electrified-diversified-header">
        <div className="title">{electrifiedDiversifiedData?.headers?.title}</div>
        <div className="description">{electrifiedDiversifiedData?.headers?.description}</div>
      </div>

      <ElectrifiedChart
        electrifiedChartData={electrifiedDiversifiedData?.chart}
      />

      <VehicleCarousel
        electrifiedCarousel={electrifiedDiversifiedData?.carousel}
        analyticsAction={{
          onTabClick: bzElectrifiedDiversifiedTabsClick,
          onClick: bzElectrifiedDiversifiedClick
        }}
      />

      <ElectrifiedComparison
        electrifiedComparisonData={electrifiedComparisonData}
        analyticsAction={{
          onClick: bzElectrifiedDiversifiedClick
        }}
      />

    </div>
  );
}

export default ElectrifiedDiversified;