import React, { useState, useMemo, useRef, useEffect } from "react";
import DataListInput from "react-datalist-input";
import '../../styles/css/dataListInputDropdown.css';
import AppSettings from '../../appsettings.js';

////////////////////////////////////////////////////////////////////////////////////
//// DOCUMENTATION FOUND AT  https://www.npmjs.com/package/react-datalist-input ////
////////////////////////////////////////////////////////////////////////////////////
export const DataListInputDropdown = (props) => {
    const [items, setItems] = useState([]);
    const [open, setOpen] = useState(false);

    const inputRef = useRef(null);
    const outerRef = useRef(null);

    const dropdownArrowImagePath = AppSettings.AWSImgRepo.resources + 'en/' + 'Down_Arrow_Black.png';

    useMemo(() => {
        let itemsList = props.options.map((option, index) => ({
            key: index,
            label: option,
        }));
        setItems(itemsList);
    },[props.options]);

    return(
        <div className="dataListInputDropdown" ref={inputRef}>
            <DataListInput
                placeholder={props.placeholder}
                items={items}
                onSelect={val=>props.onSelect(val)}
                suppressReselect={false}
                onInput={val=>props.onInput(val)}
                itemClassName={"dropdownItem"}
                activeItemClassName={"activeDropdownItem"}
                inputClassName={"inputField"}
                dropdownClassName={`dataListDropdown ${open ? "" : "hidden"}`}
                onDropdownClose={()=>{
                    setOpen(false);
                    inputRef.current.firstChild.firstChild.blur();
                }}
                value={props.value}
                onClick={()=>{
                    // clicking outerRef triggers onDropdownClose prop of DataListInput
                    open ? outerRef.current.click() : setOpen(true);
                }}
            />
            <div className="inputFieldArrow" ref={outerRef}><img className={`arrowImage ${open ? "flipped" : ""}`} src={dropdownArrowImagePath}/></div>
        </div>
    )
};