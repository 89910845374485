import ScrollingModal from '../../components/molecules/scrollingModal';
import DropDownV2 from "../../components/molecules/DropdownV2";
import "../../styles/css/advancedSearch.css";
import { isMobileView } from '../utils/getScreenSize';
import { React, useEffect, useState } from 'react'
import * as analytics from './advancedSearch.analytics';
import getAnalytics from '../../services/analyticsService';

export const AdvancedSearch =  (props) =>{
    const [showModal, setShowModal] = useState(false);
    const [analyticsData, setAnalyticsData] = useState(null);
    const [allWords, setAllWords] = useState(null);
    const [exactWords, setExactWords] = useState(null);
    const [anyWords, setAnyWords] = useState(null);
    const [noneWords, setNoneWords] = useState(null);
    const [startMonth, setStartMonth] = useState(null);
    const [endMonth, setEndMonth] = useState(null);
    const [startYear, setStartYear] = useState(null);
    const [endYear, setEndYear] = useState(null);
    const [fileType, setFileType] = useState(null);
    const [usageRight, setUsageRight] = useState(null);
    const [orderResults, setOrderResults] = useState(null);

    useEffect(async () => {
        const data = await getAnalytics('advancedSearch');
        setAnalyticsData(data);
    }, []);

    useEffect(() => {
        if(props.open) {
            analyticsData.pageLoad && analytics.openAdvancedSearchModal(analyticsData.pageLoad, props.searchParam);
        }
    }, [props.open]);

    const closeModal = () => {
        analyticsData.clickAnalytics && analytics.closeAdvancedSearchModal(analyticsData.clickAnalytics, props.searchParam);
        props.close();
      }

    const selectStartMonth = (value) => {
        setStartMonth(value);
        analyticsData.clickAnalytics && analytics.startMonthSelect(analyticsData.clickAnalytics, value, props.searchParam);
    }

    const selectStartYear = (value) => {
        setStartYear(value);
        analyticsData.clickAnalytics && analytics.startYearSelect(analyticsData.clickAnalytics, value, props.searchParam);
    }

    const selectEndMonth = (value) => {
        setEndMonth(value);
        analyticsData.clickAnalytics && analytics.endMonthSelect(analyticsData.clickAnalytics, value, props.searchParam);
    }

    const selectEndYear = (value) => {
        setEndYear(value);
        analyticsData.clickAnalytics && analytics.endYearSelect(analyticsData.clickAnalytics, value, props.searchParam);
    }

    const selectFileType = (value) => {
        setFileType(value);
        analyticsData.clickAnalytics && analytics.fileTypeSelect(analyticsData.clickAnalytics, value, props.searchParam);
    }

    const selectUsageRight = (value) => {
        setUsageRight(value);
        analyticsData.clickAnalytics && analytics.usageRightSelect(analyticsData.clickAnalytics, value, props.searchParam);
    }

    const selectOrderResults = (value) => {
        setOrderResults(value);
        analyticsData.clickAnalytics && analytics.orderResultsSelect(analyticsData.clickAnalytics, value, props.searchParam);
    }

    const showResults = () => {
        fireShowResultsAnalytics();
        props.close()
        const fromDate =startYear? startYear + (startMonth? "-" + startMonth:""): null
        const toDate = endYear? endYear +  (endMonth? "-" + endMonth:"") : null
        let advancedSearchParam = {}
        if(allWords){
            advancedSearchParam.allTheseWords = allWords
        }
        if(exactWords){
            advancedSearchParam.thisExactWords = exactWords
        }
        if(anyWords){
            advancedSearchParam.anyOftheseWords = anyWords
        }
        if(noneWords){
            advancedSearchParam.noneOfTheseWords = noneWords
        }
        if(fromDate){
            advancedSearchParam.fromDate = fromDate
        }
        if(toDate){
            advancedSearchParam.toDate = toDate
        }
        if(fileType){
            advancedSearchParam.selectedFileType = fileType
        }
        if(orderResults){
            advancedSearchParam.orderResults = orderResults
        }
        if(usageRight){
            advancedSearchParam.usageRight = usageRight
        }
        props.updateAdvancedSearchParam(advancedSearchParam)

    }


    const fireShowResultsAnalytics = () => {
        //fire only when show results clicked and if inputs have values
        (allWords && allWords != "" && analyticsData.clickAnalytics) && analytics.allWordsInput(analyticsData.clickAnalytics, allWords, props.searchParam);
        (exactWords && exactWords != "" && analyticsData.clickAnalytics) && analytics.exactWordsInput(analyticsData.clickAnalytics, exactWords, props.searchParam);
        (anyWords && anyWords != "" && analyticsData.clickAnalytics) && analytics.anyWordsInput(analyticsData.clickAnalytics, anyWords, props.searchParam);
        (noneWords && noneWords != "" && analyticsData.clickAnalytics) && analytics.noneWordsInput(analyticsData.clickAnalytics, noneWords, props.searchParam);

        analyticsData.clickAnalytics && analytics.showAdvancedSearchResults(analyticsData.clickAnalytics, props.searchParam);
    }

    const generateAllTheseWords = () =>{
        var  months = {"January":"01","Febuary":"02","March":"03","April":"04","May":"05","June":"06","July":"07","August":"08","September":"09","October":'10',"November":'11',"December":"12"}
        const fileType = ['PDF']
        const orderOfResult = {"Ascending":"asc", "Descending":"desc"}
        const usageRights = ['Internal']
        const smallDropdownClosedArrow = "advancedSearch-dropdown-arrow"
        const smallDropdownOpenArrow = "advancedSearch-dropdown-arrow-upside-down"
        const smallDropdownClass = "advancedSearch-filterInputDropdown"
        const largeDropdownClosedArrow = "large-advancedSearch-dropdown-arrow"
        const largeDropdownOpenArrow = "large-advancedSearch-dropdown-arrow-upside-down"
        const largeDropdownClass = "large-advancedSearch-filterInputDropdown"

        function generateInputDataListDropdown(options,placeholder,dropDownClass,closedArrowClass,OpenArrowClass, setValue) {
            const downChevron = props.imagePath + 'Down_Arrow_Black.png';
            const dropdownClosedArrow = <img className={closedArrowClass} src={downChevron}/>;
            const dropdownOpenArrow = <img className={OpenArrowClass} src={downChevron} />;
            return(
                <div className= {dropDownClass}>
                    <DropDownV2
                        arrowClosed={dropdownClosedArrow}
                        arrowOpen={dropdownOpenArrow}
                        placeholder={placeholder}
                        options={options}
                        onValueChange={setValue}
                        placeholderClassName={`advancedSearch-FilterDropdown-placeHolder`}

                    />
                </div>
            )
        }
        const populateyears = () => {
            const dates = [];
            for (let year = new Date().getFullYear(); year >= 2018; year--) {
              dates.push(year)
            }
            return dates
        };

        const ismobile = () => {
            if (isMobileView(window.innerWidth)){
                return false
            }
            else return true
        }
        const monthYearDropdown = ()=>{
            if (!ismobile()){
                return <div className = "advancedSearch-Dropdowns">
                <div className = "advancedSearchInputHeader">From:</div>
                <div className = "advancedSearch-Mobile-Dropdown">
                    {generateInputDataListDropdown(Object.keys(months),"Month",smallDropdownClass,smallDropdownClosedArrow,smallDropdownOpenArrow, (v)=>selectStartMonth(months[v.value]))}
                    {generateInputDataListDropdown(populateyears(),"Year",smallDropdownClass,smallDropdownClosedArrow,smallDropdownOpenArrow, (v)=>selectStartYear(v.value))}
                </div>

                <div className = "advancedSearchInputHeader">To:</div>
                <div className = "advancedSearch-Mobile-Dropdown">
                    {generateInputDataListDropdown(Object.keys(months),"Month",smallDropdownClass,smallDropdownClosedArrow,smallDropdownOpenArrow, (v)=>selectEndMonth(months[v.value]))}
                    {generateInputDataListDropdown(populateyears(),"Year",smallDropdownClass,smallDropdownClosedArrow,smallDropdownOpenArrow, (v)=>selectEndYear(v.value))}
                </div>
            </div>
            }
            else return <div className = "advancedSearch-Dropdowns">
                {generateInputDataListDropdown(Object.keys(months),"Month",smallDropdownClass,smallDropdownClosedArrow,smallDropdownOpenArrow, (v)=>selectStartMonth(months[v.value]))}
                {generateInputDataListDropdown(populateyears(),"Year",smallDropdownClass,smallDropdownClosedArrow,smallDropdownOpenArrow, (v)=>selectStartYear(v.value))}
                <div className="advancedSearchToText">to</div>
                {generateInputDataListDropdown(Object.keys(months),"Month",smallDropdownClass,smallDropdownClosedArrow,smallDropdownOpenArrow, (v)=>selectEndMonth(months[v.value]))}
                {generateInputDataListDropdown(populateyears(),"Year",smallDropdownClass,smallDropdownClosedArrow,smallDropdownOpenArrow, (v)=>selectEndYear(v.value))}
            </div>
        }
        return <div className = "advancedSearch">
            <div className = "advancedSearchHeader">ADVANCED SEARCH</div>
            <div>
                <div className = "advancedSearchSubHeader">Find search results with…</div>
                <div className = "advancedSearchInput">
                    <div className = "advancedSearchInputHeader">All these words:</div>
                    <input
                        placeholder={ismobile() && "Type the important words: 2021 Camry AWD"}
                        className="advancedSearchInputText"
                        autoFocus={false}
                        onInput={(e)=>setAllWords(e.target.value)}
                    />
                    {!ismobile() && <div className="advancedSearchMobileplaceHolder" >Example: 2021 Camry AWD</div>}
                </div>
                <div className = "advancedSearchInput">
                    <div className = "advancedSearchInputHeader">This exact word or phrase:</div>
                    <input
                        placeholder={ismobile() && "Put exact words in quotes: “Camry AWD”"}
                        className="advancedSearchInputText"
                        autoFocus={false}
                        onInput={(e)=>setExactWords(e.target.value)}
                    />
                    {!ismobile() && <div className="advancedSearchMobileplaceHolder" >Example: 2021 Camry AWD</div>}
                </div>
                <div className = "advancedSearchInput">
                    <div className = "advancedSearchInputHeader">Any of these words:</div>
                    <input
                        placeholder={ismobile() && "Type OR between all the words you want: Sedan OR Spoiler"}
                        className="advancedSearchInputText"
                        autoFocus={false}
                        onInput={(e)=>setAnyWords(e.target.value)}
                    />
                    {!ismobile() && <div className="advancedSearchMobileplaceHolder" >Example: Sedan OR Spoiler</div>}
                </div>
                <div className = "advancedSearchInput">
                    <div className = "advancedSearchInputHeader">None of these words:</div>
                    <input
                        placeholder={ismobile() && "Put a minus sign just before words you don’t want: -torque"}
                        className="advancedSearchInputText"
                        autoFocus={false}
                        onInput={(e)=>setNoneWords(e.target.value)}
                    />
                    {!ismobile() && <div className="advancedSearchMobileplaceHolder" >Example: -torque</div>}
                </div>
            </div>
            <div className = "advancedSearchHorizontalLine"></div>
            <div>
                <div className ="advancedSearchSubHeader">Then narrow your results by…</div>
                <div >
                    {ismobile() && <div className = "advancedSearchInputHeader">Date range:</div>}
                    {monthYearDropdown()}
                </div>
                <div>
                    <div className = "advancedSearchInputHeader">File type:</div>
                    {generateInputDataListDropdown(fileType,!ismobile()?"File type:":"Find pages in the format you prefer.",largeDropdownClass,largeDropdownClosedArrow,largeDropdownOpenArrow, (v)=>selectFileType(v.value))}
                </div>
                <div>
                    <div className = "advancedSearchInputHeader">Usage right:</div>
                    {generateInputDataListDropdown(usageRights,!ismobile()?"Usage right:":"Find content for both dealers and consumers",largeDropdownClass,largeDropdownClosedArrow,largeDropdownOpenArrow,(v)=>selectUsageRight(v.value))}
                </div>
                <div>
                    <div className = "advancedSearchInputHeader">Order of results:</div>
                    {generateInputDataListDropdown(Object.keys(orderOfResult),!ismobile()?"Order:":"Choose whether results are listed in ascending or descending order",largeDropdownClass,largeDropdownClosedArrow,largeDropdownOpenArrow, (v)=>selectOrderResults(orderOfResult[v.value]))}

                </div>
            </div>
            <div className = "advancedSearchButton-alignment"><button className ="advancedSearchButton" onClick={()=>showResults()}>Show Results</button></div>
        </div>
    }
    return <ScrollingModal
        disableAutoScrolling
        visible={props.open}
        closeModal={()=>closeModal()}
        modalClassName="advanced-search-modal"
        openModalPageLoad={()=>props.pageLoadAnalytics && openMenuModal(props.pageLoadAnalytics, props.vehicleSelection, props.trimSelection, props.modalType)}
        closeModalClick={()=>props.clickAnalytics && menuCloseClick(props.clickAnalytics, props.vehicleSelection, props.trimSelection, props.modalType, props.page)}>
            {generateAllTheseWords()}
    </ScrollingModal>
}