import React, { useEffect, useState } from 'react';
import AppSettings from '../../appsettings';
import DynamicLanding from '../../components/pages/dynamicLanding';
import getAnalytics from '../../services/analyticsService';
import getMisc from '../../services/miscService';
import '../../styles/css/safetyLanding.css';

const safetyLanding = (props) => {
  const [landingPageAnalytics, setLandingPageAnalytics] = useState(null);
  const [landingPageData, setLandingPageData] = useState(null);

  const getLandingPageData = () => {
    getMisc('safetyLanding', 'en')
      .then((data) => {
        setLandingPageData(data);
      })
      .catch((error) => {
        console.log(`ERROR: failed to load Audio Multimedia Landing page data. ${JSON.stringify(error.statusText)}`);
      });
  };

  const getLandingPageAnalytics = () => new Promise((resolve, reject) => {
    getAnalytics('safetyLanding')
      .then((safetyLandingAnalytics) => {
        resolve(safetyLandingAnalytics);
        setLandingPageAnalytics({...safetyLandingAnalytics});
      }).catch((error) => {
        reject(error);
      });
  });

  useEffect(() => {
    getLandingPageData();
    getLandingPageAnalytics();
  }, []);

  const getSafetyLandingImagePath = () => AppSettings.AWSImgRepo.safetyLanding;

  return (
    landingPageData
    && <div>
          <DynamicLanding
          analyticsLocation={landingPageAnalytics && landingPageAnalytics.location}
          clickAnalytics={landingPageAnalytics && landingPageAnalytics.clickAnalytics}
          externalResources={landingPageData.items.externalResources}
          heroVideoId={landingPageData.items.heroVideoId}
          description={landingPageData.items.description}
          imagePath={getSafetyLandingImagePath()}
          pageLoadAnalytics={landingPageAnalytics && landingPageAnalytics.pageLoad}
          pageTitle={landingPageData.items.title}
          tileSections={landingPageData.items.tileSections}
        />
    </div>
    
  );
};

export default safetyLanding;
