import React, { useEffect } from 'react';
import ArticleListComponent from '../../articleList/articleList.component';
import '../../../styles/css/resources-page.css';
import SectionHeader from '../../../components/atoms/SectionHeader';
import { resourceAnalytics } from '../star-safety-system-analytics';

export const StarSafetySystemResources = () => {
  useEffect(() => {
    resourceAnalytics.pageLoadAnalytics('Resources');
  }, []);

  return (
    <div className="resources-page">
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <SectionHeader id={'global.resources'} />
      <ArticleListComponent
        recordType={'tag-star safety system-en'}
        resourceClick={resourceAnalytics.resourceClick}
        filterMenuClick={resourceAnalytics.filterClick}
        sortClick={resourceAnalytics.sortClick}
      />
    </div>
  );
};
