import 'core-js';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { GlobalAnalytics } from '../../../analytics';
import { getVehiclesAndCompetitorsListV2 } from '../../../services/caEdge.service';
import { getLocale } from '../../../services/languageTranslationService.js';
import { postCompetitiveAdvantagesAction } from '../../../services/nitroService';
import '../../../styles/css/headToHead.css';
import { getS3PathName, getUrlModelName } from '../../utils/vehicleName';
import { HeadToHeadDetailedComparisonV2 } from './headToHeadComparisonPage/headToHeadDetailedComparisonV2';
import { HeadToHeadLandingBox } from './headToHeadLandingPage/components/headToHeadLandingBox';
import queryString from 'query-string';
import * as userHistory from '../../../services/userActivityService';

const history = createBrowserHistory();
const parsed = queryString.parse(location.search);
const analytics = new GlobalAnalytics();
class NewHeadToHeadComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      s3Path: '',
      fullData: [],
      competitorsList: [],
      selectedToyotaTrim: '',
      selectedToyotaModel: '',
      selectedCompetitorTrim: '',
      selectedCompetitorModel: '',
      selectedCompetitorYear: '',
    };
    this.myRef = React.createRef();
    this.getS3Path = this.getS3Path.bind(this);
  }
  /* Logic block causes clearing of div height, might need to test that Edge works correctly
  // Logic to call polyfill for IE image
  componentDidUpdate = () => {
    if (window.objectFitPolyfill) {
      window.objectFitPolyfill();
    }
    if (this.state.selectedCompetitorModel === "" && this.myRef.current) {
      const childElements = this.myRef.current.children;
      let maxHeight = 0;
      for (const ce of childElements) {
        if (ce.children[0].children[0].children[1].clientHeight > maxHeight) {
          maxHeight = ce.children[0].children[0].children[1].clientHeight;
        }
      }
      for (const ceUpdate of childElements) {
        ceUpdate.children[0].children[0].children[1].style.height = maxHeight + "px";
      }
    }
  }
  */
  componentDidMount = () => {
    this.getS3Path()
    this.getVehiclesAndCompetitorsList();
    userHistory.postUserActivityRecord();
    analytics.firePageLoad({
      tag: '29.1',
      modelName: this.props.model,
      modelYear: this.props.year,
      section: 'Product',
      page: 'Competitive Advantages:Head to Head',
    });
    
    window.addEventListener('popstate', () => {
      this.setState({
        selectedToyotaModel: '',
        selectedToyotaTrim: '',
        selectedCompetitorModel: '',
        selectedCompetitorTrim: '',
        selectedComparisonUrl: '',
      });
    });
  }

  async getS3Path () {
    const s3PathName = await getS3PathName(this.props.model);
    const language = getLocale();
    const year = this.props.year;
    this.setState({
      s3Path: `${year}/${s3PathName}/${language}/`,
    });
  }

  // These should not be named the same value as the imported function.
  getVehiclesAndCompetitorsList = () => {
    const modelYear = `${this.props.year} ${this.props.model}`;
    getVehiclesAndCompetitorsListV2(modelYear)
      .then((vehiclesCompetitorList) => {
        if (vehiclesCompetitorList.length > 0) {
          const competitorVehicles = vehiclesCompetitorList[0].competitors.map(competitor => competitor.competitorVehicle);
          const sortedCompetitorsInVehicleCompetitorList = vehiclesCompetitorList.map((vcl) => {
            const sortedCompetitors = this.sortCompetitorVehicles(vcl);
            return {
              ...vcl,
              competitors: sortedCompetitors,
            };
          });
          const sortedToyotaVehicles = sortedCompetitorsInVehicleCompetitorList.sort((a, b) => {
            const trimA = a.gradeAndTrim;
            const trimB = b.gradeAndTrim;
            if (trimA < trimB) {
              return -1;
            }
            if (trimA === trimB) {
              return 0;
            }
            return 1;
          });
          this.setState({
            competitorsList: competitorVehicles,
            fullData: sortedToyotaVehicles,
          });
        } else {
          this.setState({
            modelYear,
            noResponse: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  sortCompetitorVehicles = (competitorVehicleList) => {
    const { competitors } = competitorVehicleList;
    const sortedByMake = competitors.sort((a, b) => {
      const makeA = a.competitorVehicle.model.split(' ')[0];
      const makeB = b.competitorVehicle.model.split(' ')[0];
      if (makeA < makeB) {
        return -1;
      }
      if (makeA === makeB) {
        return 0;
      }
      return 1;
    });
    const sortByModel = sortedByMake.sort((a, b) => {
      const makeA = a.competitorVehicle.model.split(' ')[0];
      const makeB = b.competitorVehicle.model.split(' ')[0];
      if (makeA === makeB) {
        const modelA = a.competitorVehicle.model.split(' ')[1];
        const modelB = b.competitorVehicle.model.split(' ')[1];
        if (modelA < modelB) {
          return -1;
        }
        if (modelA === modelB) {
          return -1;
        }
        return 1;
      }
      return -1;
    });
    return sortByModel.sort((a, b) => {
      const modelA = a.competitorVehicle.model.split(' ')[1];
      const modelB = b.competitorVehicle.model.split(' ')[1];
      if (modelA === modelB) {
        const trimA = a.competitorVehicle.trim;
        const trimB = b.competitorVehicle.trim;
        if (trimA < trimB) {
          return -1;
        }
        if (trimA === trimB) {
          return -1;
        }
        return 1;
      }
      return -1;
    });
  }
  selectCompetitorComparison = (toyotaVehicle, competitorVehicle) => {
    const selectedComparisonUrl = `/product/${getUrlModelName(toyotaVehicle.model)}/${this.props.year}/competitiveAdvantages/headToHead?trim=${toyotaVehicle.trim}&competitor=${competitorVehicle.model} ${competitorVehicle.trim}`;
    history.push(selectedComparisonUrl);
    this.setState({
      selectedToyotaModel: toyotaVehicle.model,
      selectedToyotaTrim: toyotaVehicle.trim,
      selectedCompetitorModel: competitorVehicle.model,
      selectedCompetitorTrim: competitorVehicle.trim,
      selectedCompetitorYear: competitorVehicle.year,
      selectedComparisonUrl,
    });

    const category = 'Competitive Trim';
    const label = `${competitorVehicle.year} ${competitorVehicle.model} ${competitorVehicle.trim}`;
    postCompetitiveAdvantagesAction();
    analytics.fireOnClick({
      tag: '29.2',
      section: 'Product',
      page: 'Competitive Advantages:Head to Head', //TODO: Decide if we want 's' and if we want a space or not
      category,
      label,
    });
  }
  clearCompetitorComparison = () => {
    const baseComparisonUrl = `/product/${getUrlModelName(this.state.selectedToyotaModel)}/${this.props.year}/competitiveAdvantages/headToHead`;
    history.push(baseComparisonUrl);
    this.setState({
      selectedToyotaModel: '',
      selectedToyotaTrim: '',
      selectedCompetitorModel: '',
      selectedCompetitorTrim: '',
      selectedComparisonUrl: '',
    });
    const category = 'CTA';
    const label = 'Browse Comparisons';
    postCompetitiveAdvantagesAction();
    analytics.fireOnClick({
      tag: '29.2',
      section: 'Product',
      page: 'Competitive Advantages:Head to Head Details', //TODO: Decide if we want 's' and if we want a space or not
      category,
      label,
    });
    window.scrollTo(0, 0);
  }

  mapDataToLandingPage = () => {
    return this.state.fullData.length > 0 ? this.state.fullData.map((cv, index) => <HeadToHeadLandingBox headToHeadComparisonObject={cv} key={`${index}-${cv.yearAndModel}-${cv.gradeAndTrim}`} s3Path={this.state.s3Path} handleCompetitorComparisonSelection={this.selectCompetitorComparison} />) : null
  };
  render() {
    const headToHeadLandingPageContainers = this.mapDataToLandingPage();
    if (this.state.noResponse && !headToHeadLandingPageContainers) {
      return <div className="ca-edge"><div className="noResponse">{<FormattedMessage id='caHeadToHead.noComparisonAvailable' />}</div></div>
    }
    if (this.state.selectedCompetitorModel === '') {
      return <div ref={this.myRef} className="head-to-head-container-holder">
        {headToHeadLandingPageContainers}
      </div>
    } else {
      // This is kind of odd but its just a way to fire an analytic for when the detailed comparison subsection is rendered
      analytics.firePageLoad({
        tag: '29.1',
        modelName: this.props.model,
        modelYear: this.props.year,
        section: 'Product',
        page: 'Competitive Advantages:Head to Head Details',
      });
      return <HeadToHeadDetailedComparisonV2
        fullData={this.state.fullData}
        selectedCompetitorModel={this.state.selectedCompetitorModel}
        selectedToyotaModel={this.state.selectedToyotaModel}
        selectedCompetitorTrim={this.state.selectedCompetitorTrim}
        selectedToyotaTrim={this.state.selectedToyotaTrim}
        selectedCompetitorYear={this.state.selectedCompetitorYear}
        s3Path={this.state.s3Path}
        backButtonOnClick={this.clearCompetitorComparison}
      />
    }
  }
}
NewHeadToHeadComponent.propTypespropTypes = {
  intl: PropTypes.object.isRequired,
};
export default injectIntl(NewHeadToHeadComponent);