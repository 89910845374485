import React from 'react';
import { FormattedMessage } from 'react-intl';
import AppSettings from '../../appsettings.js';
import { getLocale } from '../../services/languageTranslationService.js';
import CarouselComponent from '../carousel/carousel.component';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import TopResourcesComponent from '../topResources/topResources.component';
import { translateText } from '../utils/translateText';

export function ResourcesTemplate(component) {
  const baseImagePath = `${AppSettings.AWSImgRepo.resources + getLocale()}/`;
  function NextArrow(props) {
    const {
      customClassName, style, onClick, margin, category, mod,
    } = props;
    return (
            <div className={`${customClassName} slick-next`} style={{ ...style, marginRight: margin }} onClick={() => { component.resourcesClick(mod, category, 'next'); onClick(); }}></div>
    );
  }
  function PrevArrow(props) {
    const {
      customClassName, style, onClick, margin, category, mod,
    } = props;
    return (
            <div className={`${customClassName} slick-prev`} style={{ ...style, marginLeft: margin }} onClick={() => { component.resourcesClick(mod, category, 'previous'); onClick(); }}></div>
    );
  }

  const resourceList = [
    {
      name: 'awards',
      image: 'Tile_Awards.jpg',
      tag: 'tag-Awards',
    },
    {
      name: 'internalArticles',
      image: 'Tile_Internal_Articles.jpg',
      tag: 'category-Internal Articles',
    },
    {
      name: 'cfa',
      image: 'Tile_Customer_Share.jpg',
      tag: 'tag-Customer Sharing',
    },
    {
      name: 'competitiveComparison',
      image: 'engage_Resources_Tile_Competitive_Comparison.jpg',
      tag: 'tag-Competitive Comparison',
    },
    {
      name: 'howTo',
      image: 'Engage_Resources_Tile_How_To.jpg',
      tag: 'tag-How-To',
    },
    {
      name: 'walkaround',
      image: 'Engage_Resources_Tile_Walkaround.jpg',
      tag: 'tag-Walkarounds and Presentations',
    },
    {
      name: 'quickReference',
      image: 'engage_Resources_Tile_Quick_Reference.jpg',
      tag: 'tag-Quick Reference Video',
    },
  ];

  const topResources = resourceList.map((resource, index) => (
            <div>
                <TopResourcesComponent categoryName={resource.name} categoryImage={baseImagePath + resource.image} key={index} index={index} tag={resource.tag}></TopResourcesComponent>
                {((index + 1) % 4 === 0 && (index + 1) !== resourceList.length)
                  ? <div className='large-12 columns lineBreak'></div>
                  : null}
                {((index + 1) % 2 === 0 && (index + 1) !== resourceList.length)
                  ? <div className='medium-12 columns lineBreak-medium'></div>
                  : null}
            </div>

  ));

  return (
        <div className="resources">
            <div className="title">
                <FormattedMessage id="global.resources" />
            </div>
            <div className="popular-resources">
                <div className="popular-content">
                    <div className="popular-title">
                        See What's Popular on Toyota Engage
                        </div>
                    <div className="content-box">
                        <div className="popular-list">
                            <ul>
                                <li>Top Articles</li>
                                <li>Top Searches</li>
                                <li>Top Videos</li>
                            </ul>
                        </div>
                        <div className="results">
                            <div className="row">
                                <div className="large-2 medium-4 small-6 columns">
                                    <div className="count">1</div>
                                    Walkaround
                                    </div>
                                <div className="large-2 medium-4 small-6 columns">
                                    <div className="count">2</div>
                                    Competitor
                                    </div>
                                <div className="large-2 medium-4 small-6 columns">
                                    <div className="count">3</div>
                                    2018 Camry Walkaround
                                    </div>
                                <div className="large-2 medium-4 small-6 columns">
                                    <div className="count">4</div>
                                    Its time to learn 4wd
                                    </div>
                                <div className="large-2 medium-4 small-6 columns end">
                                    <div className="count">5</div>
                                    How to steering
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="resources-content">
                <div className="row top-resources">
                    {topResources}
                </div>
            </div>
        </div>
  );
}
