import React, { Component } from 'react';
import { PrimaryVehicleTemplate } from './primaryVehicle.tpl';
import { getModelTrims } from '../../../services/comparisonService.js';
import { translateText } from '../../utils/translateText';
import { noCustomImageAvailable } from './noCustomImageAvailable';
import AppSettings from '../../../appsettings';

export default class VehicleComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedModelId: this.props.vehicle.modelId,
      selectedTrim: this.props.vehicle.trimId,
      availableTrims: null,
    };

    this.noCustomImageAvailable = noCustomImageAvailable.bind(this);
  }

  getMake() {
    return this.props.vehicle.make ? this.props.vehicle.make.toUpperCase() : '';
  }

  getModels() {
    if (this.props.availableVehicles) {
      const make = this.props.availableVehicles.find(make => make.makeName === this.props.vehicle.make);
      const models = make.modelNameList.map((model, index) => model.modelYearList.map((year, index) => <option key={index + 1} value={year.modelId.modelId}>{`${model.modelName.toUpperCase()} (${year.modelYear})`}</option>));
      return models;
    }
    return <option>{translateText('ca.thirdPartyComparison.competitorVehicleSelectModelPlaceholder')}</option>;
  }

  getTrims() {
    if (this.state.availableTrims) {
      const trims = this.state.availableTrims.map((trim, index) => <option key={index + 1} value={trim.trimId}>{trim.trimName.toUpperCase()}</option>);
      trims.unshift(<option key={0} value=''>{translateText('ca.thirdPartyComparison.competitorVehicleSelectTrimPlaceholder')}</option>);
      return trims;
    }
    return <option>{translateText('ca.thirdPartyComparison.competitorVehicleSelectTrimPlaceholder')}</option>;
  }

  getImage() {
    if (this.state.availableTrims && this.state.selectedTrim) {
      const trim = this.state.availableTrims.find(trim => trim.trimId.toString() === this.state.selectedTrim.toString());
      if (this.noCustomImageAvailable(trim.imageUrl)) {
        return AppSettings.AWSImgRepo.resources + 'no_image_available_tall.png';
      }
      return trim.imageUrl;
    }
    return null;
  }

  setAvailableTrims() {
    if (this.state.selectedModelId) {
      return getModelTrims(this.state.selectedModelId).then((trims) => {
        this.setState({
          availableTrims: trims,
        });
      });
    }
  }

  handleModelChange(event) {
    this.setState(
      {
        selectedModelId: event.target.value,
        selectedTrim: '',
        availableTrims: null,
      },
      () => {
        this.setAvailableTrims();
        const make = this.props.availableVehicles.find(make => make.makeName === this.props.vehicle.make);
        const modelsList = [];
        for (const model of make.modelNameList) {
          for (const year of model.modelYearList) {
            const curMod = {
              id: year.modelId.modelId,
              modelName: `${model.modelName.toUpperCase()} (${year.modelYear})`,
            };
            modelsList.push(curMod);
          }
        }
        const currentModel = this.state.selectedModelId;
        const foundModel = modelsList.find(model => model.id === currentModel);
        this.props.onVehicleChange(this, true, 'Toyota Model', foundModel.modelName);
      },
    );
  }

  handleTrimChange(event) {
    this.setState(
      {
        selectedTrim: event.target.value,
      },
      () => {
        const currentTrim = this.state.selectedTrim;
        const foundTrim = this.state.availableTrims.find(trim => trim.trimId.toString() === currentTrim);
        this.props.onVehicleChange(this, true, 'Toyota Trim', foundTrim.trimName);
      },
    );
  }

  componentDidMount() {
    this.setAvailableTrims();
  }

  render() {
    return PrimaryVehicleTemplate(this);
  }
}
