import React, { useState } from 'react';
import '../../styles/css/glossarySearchInput.css';
import { searchLinkClick } from './glossary.analytics';


const GlossarySearchBar = (props) => {
    const [searchText, setSearchText] = useState('');

    const clickCloseSearchIcon = () => {
      document.getElementById("search-bar").value = "";
      document.getElementById("search-bar").focus();
      setSearchText('');
      props.setSearchText('');
      props.analytics && searchLinkClick(props.analytics.clickAnalytics, props.category, props.analytics.clickAnalytics.searchBar.positionTitle, props.analytics.clickAnalytics.searchBar.clearLabel, searchText);
    };

    const clickRunSearch = () => {
      props.setSearchText(searchText);
      props.analytics && searchLinkClick(props.analytics.clickAnalytics, props.category, props.analytics.clickAnalytics.searchBar.positionTitle, props.analytics.clickAnalytics.searchBar.searchLabel, searchText);
    };

    const InputField = () => {
      const handleEnterKey = (e) => {
        if (e.key === 'Enter') {
          clickRunSearch();
        }
      }
      const handleChange = (e) => {
        setSearchText(e.target.value);
      }
      const getPlaceholder = () => {
        switch (props.category) {
          case undefined: return "Search Glossary";
          case "all": return "Search Glossary";
          default: return `Search ${props.category}`
        }
      }
      return (
        <input
          id="search-bar"
          className="search-results-input"
          placeholder={getPlaceholder()}
          autoFocus={false}
          onChange={handleChange}
          onKeyDown={handleEnterKey}
        />
      )
    };

  return (
      <div className="desktop-tablet-search-results-input">
        {InputField()}
        <div className="search-results-icons">
          <img alt="" src={props.imagePath + "red_search.png"} className="search-results-icon" onClick={() => { clickRunSearch(); }} />
          <img alt="" src={props.imagePath + "icon_close_black.svg"} className="search-results-icon-close" onClick={() => { clickCloseSearchIcon(); }} />
        </div>
      </div>
  );
};

export default GlossarySearchBar;