/* eslint-disable no-nested-ternary */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import getVehicleAccessories from '../../services/accessoriesService';
import { AccessoriesTemplate } from './accessories.tpl';
import '../../styles/css/accessories.css';
import { GlobalAnalytics } from '../../analytics';

const userHistory = require('../../services/userActivityService.js');

const analytics = new GlobalAnalytics();

class AccessoriesComponent extends Component {
  constructor(props) {
    super(props);


    this.getVehicleAccessories = this.getVehicleAccessories.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.handleSearchTextPress = this.handleSearchTextPress.bind(this);
    this.state = {
      accessories: {
        interior: [],
        exterior: [],
        performance: [],
      },
      accessoriesToDisplay: [],
      allAccessories: {},
      showInterior: true,
      showExterior: true,
      showPerformance: true,
      totalCount: 0,
      searchText: '',
      visibleCount: 0,
    };
  }

  handleFilterChange(event) {
    const { name } = event.target;
    const { checked } = event.target;
    this.setState(() => ({
      [name]: checked,
    }), () => {
      this.updateAccessories();
      if (checked) {
        this.handleSearch();
      }
    });
    analytics.fireOnClick({
      tag: '29.2',
      modelName: this.props.route.model,
      page: 'Accessories',
      modelYear: this.props.params.year,
      section: 'Product',
      subsection: 'Featured Technology',
      container: 'Module',
      module: 'Filter',
      category: 'Accessories Filter',
      label: event.target.name,
    });
  }

  updateAccessories() {
    let accessoriesToDisplay = [];
    if (this.state.showInterior && this.state.accessories.interior) {
      accessoriesToDisplay = accessoriesToDisplay.concat(this.state.accessories.interior);
    }
    if (this.state.showExterior && this.state.accessories.exterior) {
      accessoriesToDisplay = accessoriesToDisplay.concat(this.state.accessories.exterior);
    }
    if (this.state.showPerformance && this.state.accessories.performance) {
      accessoriesToDisplay = accessoriesToDisplay.concat(this.state.accessories.performance);
    }
    this.setState({
      visibleCount: accessoriesToDisplay.length,
      accessoriesToDisplay: this.sortAccessories(accessoriesToDisplay),
    });
  }

  handleSearch() {
    const matchingAccessory = (accessory) => {
      const matchingTitle = accessory.title ? accessory.title.toLowerCase() : '';
      const matchingDescription = accessory.description ? accessory.description.toLowerCase() : '';
      return matchingTitle.indexOf(this.state.searchText) >= 0 || matchingDescription.indexOf(this.state.searchText) >= 0;
    };

    const filteredInteriorAccessories = this.state.showInterior ? this.state.allAccessories.interior ? this.state.allAccessories.interior.filter(matchingAccessory) : [] : [];
    const filteredExteriorAccessories = this.state.showExterior ? this.state.allAccessories.exterior ? this.state.allAccessories.exterior.filter(matchingAccessory) : [] : [];
    const filteredPerformanceAccessories = this.state.showPerformance ? this.state.allAccessories.performance ? this.state.allAccessories.performance.filter(matchingAccessory) : [] : [];

    let accessoriesToDisplay = [];
    if (this.state.showInterior) {
      accessoriesToDisplay = accessoriesToDisplay.concat(filteredInteriorAccessories);
    }
    if (this.state.showExterior) {
      accessoriesToDisplay = accessoriesToDisplay.concat(filteredExteriorAccessories);
    }
    if (this.state.showPerformance) {
      accessoriesToDisplay = accessoriesToDisplay.concat(filteredPerformanceAccessories);
    }
    this.setState({
      accessories: {
        interior: filteredInteriorAccessories,
        exterior: filteredExteriorAccessories,
        performance: filteredPerformanceAccessories,
      },
      accessoriesToDisplay: this.sortAccessories(accessoriesToDisplay),
      visibleCount: accessoriesToDisplay.length,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  sortAccessories(accessories) {
    return accessories.sort((a, b) => (a.title.localeCompare(b.title)));
  }

  handleSearchTextPress() {
    this.handleSearch();
  }

  handleSearchTextChange(event) {
    if (event.charCode === 13) {
      // Handle search on 'enter' or 'return' key
      event.preventDefault();
      this.handleSearch();
    } else {
      this.setState({
        searchText: event.target.value.trim().toLowerCase(),
      });
    }
    analytics.fireOnClick({
      tag: '29.2',
      modelName: this.props.route.model,
      page: 'Accessories',
      modelYear: this.props.params.year,
      section: 'Product',
      subsection: 'Featured Technology',
      container: 'Module',
      module: 'Filter',
      category: 'Accessories filter',
      label: ' ',
    });
  }

  getVehicleAccessories(modelName, modelYear, mockedResponse) {
    return new Promise(
      (resolve, reject) => {
        getVehicleAccessories(modelName, modelYear, mockedResponse)
          .then((payload) => {
            let accessoriesToDisplay = [];
            if (payload.interior) {
              accessoriesToDisplay = accessoriesToDisplay.concat(payload.interior);
            }
            if (payload.exterior) {
              accessoriesToDisplay = accessoriesToDisplay.concat(payload.exterior);
            }
            if (payload.performance) {
              accessoriesToDisplay = accessoriesToDisplay.concat(payload.performance);
            }
            this.setState({
              accessories: payload,
              allAccessories: payload,
              totalCount: accessoriesToDisplay.length,
              visibleCount: accessoriesToDisplay.length,
              accessoriesToDisplay: this.sortAccessories(accessoriesToDisplay),
            });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      },
    );
  }

  componentDidMount() {
    this.getVehicleAccessories(this.props.route.model, parseInt(this.props.params.year, 10));
    userHistory.postUserActivityRecord();
    analytics.firePageLoad({
      tag: '29.1',
      modelName: this.props.route.model,
      modelYear: this.props.params.year,
      section: 'Product',
      page: 'Accessories',
    });
  }

  render() {
    return AccessoriesTemplate(this);
  }
}

AccessoriesComponent.propTypes = {
  intl: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

export default injectIntl(AccessoriesComponent);
