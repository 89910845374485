import { GlobalAnalytics } from "../../../analytics";

const analytics = new GlobalAnalytics();

// Page Load to start a quiz
export const triviaStartPageLoad = (pageLoad, quizName) => {
  pageLoad &&
    analytics.firePageLoad({
      app: pageLoad.appId,
      tag: pageLoad.tagId,
      section: pageLoad.section,
      page: quizName,
      subsection: pageLoad.subsection,
      technologyName: pageLoad.technology_name,
    });
};

// Page Load for a correct answer
export const triviaCorrectAnswerPageLoad = (pageLoad, pageNameCorrect) => {
  pageLoad &&
    analytics.firePageLoad({
      app: pageLoad.appId,
      tag: pageLoad.tagId,
      section: pageLoad.section,
      page: pageNameCorrect,
      subsection: pageLoad.subsection,
      technologyName: pageLoad.technology_name,
    });
};

// Page Load for incorrect answer
export const triviaIncorrectAnswerPageLoad = (pageLoad, pageNameIncorrect) => {
  pageLoad &&
    analytics.firePageLoad({
      app: pageLoad.appId,
      tag: pageLoad.tagId,
      section: pageLoad.section,
      page: pageNameIncorrect,
      subsection: pageLoad.subsection,
      technologyName: pageLoad.technology_name,
    });
};

// Page Load for failing the quiz
export const triviaFailedPageLoad = (pageLoad, failedResultName) => {
  pageLoad &&
    analytics.firePageLoad({
      app: pageLoad.appId,
      tag: pageLoad.tagId,
      section: pageLoad.section,
      page: failedResultName,
      subsection: pageLoad.subsection,
      technologyName: pageLoad.technology_name,
    });
};

// Page Load for passing the quiz
export const triviaPassedPageLoad = (pageLoad, passedResultName) => {
  pageLoad &&
    analytics.firePageLoad({
      app: pageLoad.appId,
      tag: pageLoad.tagId,
      section: pageLoad.section,
      page: passedResultName,
      subsection: pageLoad.subsection,
      technologyName: pageLoad.technology_name,
    });
};

export const triviaQuestionPageLoad = (pageLoad, questionPageName) => {
  pageLoad &&
    analytics.firePageLoad({
      app: pageLoad.appId,
      tag: pageLoad.tagId,
      section: pageLoad.section,
      page: questionPageName,
      subsection: pageLoad.subsection,
      technologyName: pageLoad.technology_name,
    });
};

// Tracking click for a the play now click
export const playNowClick = (clickAnalytics, label) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: "Engage XP",
      category: "Trivia",
      label: label
    });
  }
};

// Tracking click for a the next question click
export const nextClick = (clickAnalytics, analyticsPage, analyticsCategory, title) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: analyticsPage,
      category: analyticsCategory,
      label: title
    });
  }
};

// Tracking click for a the answer submission click
export const submitAnswersClick = (clickAnalytics, analyticsPage, analyticsCategory, title) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: analyticsPage,
      category: analyticsCategory,
      label: title
    });
  }
};

// Tracking click for a the answer selection click
export const answerSelectionClick = (clickAnalytics, optionText) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: "Engage XP",
      category: "Trivia",
      label: "Answer Select/Deselect Click",
      contentTitle: optionText
    });
  }
};

// Tracking click for a the submitting the quiz click
export const submitQuizClick = (clickAnalytics, analyticsPage, analyticsCategory, title) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: analyticsPage,
      category: analyticsCategory,
      label: title
    });
  }
};

// Tracking click for a the submitting the quiz click
export const passedQuizClick = (clickAnalytics, analyticsPageName, analyticsCategory) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: analyticsPageName,
      category: analyticsCategory,
      label: "Done Button"
    });
  }
};


// Tracking click for a the submitting the quiz click
export const failedQuizClick = (clickAnalytics, analyticsPageName, analyticsCategory) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: analyticsPageName,
      category: analyticsCategory,
      label: "Play Again"
    });
  }
};


// Tracking click for a the submitting the quiz click
export const finishedClick = (clickAnalytics, analyticsPageName, analyticsCategory) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: analyticsPageName,
      category: analyticsCategory,
      label: "Done"
    });
  }
};

// Tracking click for a the submitting the quiz click
export const closeModalClick = (clickAnalytics) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: "Engage XP",
      category: "Trivia",
      label: "Close Button"
    });
  }
};

