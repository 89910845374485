import React, { useMemo } from "react";
import "../../../../styles/scss/beyondZero/cardLayout.scss";
import RoundedButton from '../../../../components/molecules/roundedButton';
import applyMarkdown from "../../../utils/applyMarkdown";

const CardImageOne = ({ images, isFlipped }) => {
    return <div 
        className={`card-one ${isFlipped && 'flipped'}`}
        style={{
            "--desktop-banner": `url(${images.first.desktop})`,
            "--tablet-banner": `url(${images.first.tablet})`,
            "--mobile-banner": `url(${images.first.mobile})`,
        }}
    ></div>
}

const CardImageTwo = ({ images, isFlipped }) => {
    return <div
        className={`card-two ${isFlipped && 'flipped'}`}
        style={{
            "--desktop-banner": `url(${images.second.desktop})`,
            "--tablet-banner": `url(${images.second.tablet})`,
            "--mobile-banner": `url(${images.second.mobile})`,
        }}
    ></div>
}

const CardText = ({ textCard, isFlipped }) => {
    return <div className={`card-text ${isFlipped && 'flipped'}`}>
        <p>{applyMarkdown(textCard?.text)}</p>
        <RoundedButton 
            title={textCard?.buttonText}
            height="40px"
            className="learn-more-btn"
            onClick={textCard?.buttonOnClick}
        />
    </div>
}


const CardLayout = ({ isFlipped, textCard, images }) => {

    const isMobile = useMemo(() => {
        return window.innerWidth <= 640 // To match $xl-mobile scss variable
    }, [window.innerWidth])

    return <div className={`beyond-zero-card-layout-wrapper ${isFlipped && 'flipped'}`}>
        <div className={`card-layout-column ${isFlipped && 'flipped'}`}>
            {!isMobile && <CardImageOne images={images} isFlipped={isFlipped} />}
            {isMobile && <CardText textCard={textCard} isFlipped={isFlipped} />}
        </div>
        <div className={`card-layout-column ${isFlipped && 'flipped'}`}>
            {!isMobile && <>
                <CardText textCard={textCard} isFlipped={isFlipped} />
                <CardImageTwo images={images} isFlipped={isFlipped} />
            </>}

            {isMobile && <>
                <CardImageOne images={images} isFlipped={isFlipped} />
                <CardImageTwo images={images} isFlipped={isFlipped} />
            </>}
        </div>
    </div>
}

export default CardLayout;
