import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getLocale } from '../../../services/languageTranslationService.js';
import FeaturesComponent from '../../features/features.component';

import '../../../styles/css/tcuv.css';

import AppSettings from '../../../appsettings';

export function TCUVGeneralTemplate(component) {
  return (
        <div className="tcuv tcuv-general">
            {component.state.languageOverride
              ? <div className="language-unavailable">
                    <FormattedMessage id="dataUnavailable" default="Language is unavailable" />
                </div>
              : null
            }
            <div className="title">
                <div className="print-tcuv-title"><FormattedMessage id="dropdown.tcuv" /></div>
                <FormattedMessage id={component.props.tab} />
                <div className="print-dividing-line" />
            </div>
            <FeaturesComponent
                featuresData={component.props.pageData}
                clickLink={component.linkClick}
                readMoreClick={component.readMoreClick}
                disclaimer={'TCUV'}
                imagePath={`${AppSettings.AWSImgRepo.tcuv + getLocale()}/`}
            />
        </div>
  );
}
