import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { certificationPageLoaded, overviewLinkClick, scrollButtonClick, scrollToTopClick, whyCertTileClick } from './certification.analytics';
import applyMarkdown from '../utils/applyMarkdown';
import CardGroupSection from '../../components/molecules/marketingPage/cardGroupSection';
import FiveCategory from '../../components/molecules/marketingPage/fiveCategory';
import PageHeader from '../../components/molecules/marketingPage/pageHeader';
import SectionHeader from '../../components/molecules/marketingPage/sectionHeader';
import TestimonialSection from '../../components/molecules/marketingPage/testimonialSection';
import '../../styles/css/certificationOverview.css';


const certificationOverview = (props) => {
  const [device, setDevice] = useState('mobile');
  const [disclaimer, setDisclaimer] = useState(null);
  const disclaimerRef = useRef();
  const quickLinkRefs = [useRef(), useRef(), useRef(), useRef()];

  const pageHeader = props.overviewData?.pageHeader;
  const quickLinkButtonData = props.overviewData?.pageHeader?.quickLinks;
  const sections = props.overviewData?.sections;
  
  sections.sort((a, b) => (parseInt(a.sectionId) - parseInt(b.sectionId)));

  useEffect(() => {
    setDisclaimer(disclaimerRef.current);
  }, [disclaimerRef]);
  
  useEffect(() => {
    certificationPageLoaded(props.pageLoadAnalytics, props.pageName);
    updateDevice();
  }, []);

  const formatTitle = (buttonTitle) => {
    // on desktop, remove line breaks
    if (device === 'desktop') {
      return applyMarkdown(buttonTitle.replace('<br/>', '').replace('<br>', '').replace('<br />', ''));
    }
    // on tablet & mobile, render line breaks as marked
    return applyMarkdown(buttonTitle);
  };

  const handleScrollClick = (index) => {
    quickLinkRefs[index].current.scrollIntoView({ behavior: "smooth" });
  };

  const renderButtons = (buttonData) => {
    const buttons = buttonData?.map((item, index) => {
      // if the button points to a section on the page, return a button with handleScrollClick to the sectionRef
      if (item.samePageNavigation) {
        return (
          <button
            className={`marketing-scroll-button ${device}`}
            key={item.title}
            onClick={() => {handleScrollClick(index); scrollButtonClick(props.clickAnalytics, item.title) }}
          >
            {formatTitle(item.title)}
          </button>
        );
      }
      // otherwise, return a button within a link pointing to the href
      return (
        <a
          onClick={() => {scrollButtonClick(props.clickAnalytics, item.title) }}
          href={item.href}
          target={item.href.includes('.com') && !item.href.includes('engage.toyota.com') ? '_blank' : ''}
        >
          <button className={`marketing-scroll-button ${device}`} key={item.title}>
            {formatTitle(item.title)}
          </button>
        </a>
      );
    });

    return buttons;
  };

  const updateDevice = () => {
    if (window.innerWidth >= 1320) {
      setDevice('desktop')
    } else if (window.innerWidth>=768) {
      setDevice('tablet');
    } else if (window.innerWidth<768) {
      setDevice('mobile');
    }
  };

  const renderComponent = (content) => {
    const refIndex = quickLinkButtonData?.findIndex(item => item.sectionRef === content.sectionId);
    const sectionRef = quickLinkRefs[refIndex];
    let sectionComponent;

    switch (content.type) {
      case "CardGroupSection":
        sectionComponent = (
          <CardGroupSection
            cardGroups={content.cardGroups}
            clickAnalytics={props.clickAnalytics}
            device={device}
            footnote={content.footnote}
            imagePath={props.imagePath}
            sectionId={content.sectionId}
            title={content.title}
            titleType="leftSemiBold"
            videoAnalytics={props.videoAnalytics}
          />
        );
        break;
      case "SectionHeader":
        sectionComponent = (
          <SectionHeader
            {...content}
            device={device}
            imagePath={props.imagePath}
          />
        );
        break;
      case "FiveCategory":
        sectionComponent = (
          <FiveCategory 
            clickAnalytics={props.clickAnalytics}
            clickFunction={whyCertTileClick}
            device={device}
            imagePath={props.imagePath}
            sectionId={content.sectionId}
            tabs={content.details}
          />
        );
        break;
      case "Testimonial":
        sectionComponent = (
          <TestimonialSection
            bigQuote={content.bigQuote}
            clickAnalytics={props.clickAnalytics}
            imagePath={props.imagePath}
            image={content.image}
            gif={content.gif}
            testimonials={content.testimonials}
            title={content.title}
            videoAnalytics={props.videoAnalytics && props.videoAnalytics.testimonialVideo}
            videoID={content.videoId}
          />
        );
        break;
    }

    return (
      <div key={content.sectionId} className={`scroll-target-${refIndex}`} ref={sectionRef}>
        {sectionComponent}
      </div>
    );
  }

  return (
    <div className="certification-overview">
      <PageHeader 
        device={device}
        heroGif={pageHeader.heroGif}
        heroVideoId={pageHeader.heroVideoId}
        image={pageHeader.image}
        imagePath={props.imagePath}
        title={pageHeader.title}
        scrollButtons={renderButtons(quickLinkButtonData)}
        videoAnalytics={props.videoAnalytics && props.videoAnalytics.intro}
      />
      {sections.map(item => renderComponent(item))}
    </div> 
  );
}

certificationOverview.propTypes = {
  clickAnalytics: PropTypes.shape({

  }),
  disclaimer: PropTypes.string,
  imagePath: PropTypes.string,
  overviewData: PropTypes.shape({

  }),
  pageLoadAnalytics: PropTypes.shape({

  }),
  pageName: PropTypes.string,
  videoAnalytics: PropTypes.shape({
    intro: PropTypes.shape({
      section: PropTypes.string,
      page: PropTypes.string,
      module: PropTypes.string,
    }),

  }),
}

export default certificationOverview;
