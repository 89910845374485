import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ImageScaleComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {

      naturalWidth: -1,
      naturalHeight: 0,
      currentWidth: -1,
      currentHeight: -1,
      newWidth: -1,
      newHeight: -1,
      heightRatio: -1,
      widthRatio: -1,
      naturalRatio: -1,
      widthPercent: -1,

    };

    this.measureDimensions = this.measureDimensions.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.addURL = this.addURL.bind(this);
    this.removeURL = this.removeURL.bind(this);
    this.setImage = this.setImage.bind(this);
  }


  addURL() {
    let isValid = true;
    const img = new Image();
    img.src = encodeURI(this.props.url);
    img.onerror = function () {
      isValid = false;
    };

    if (isValid === true) {
      let z = encodeURI(this.props.url);
      if (this.props.url.substring(0, 3) !== 'url') {
        z = `url('${encodeURI(this.props.url)}')`;
      }
      return z;
    }
    return undefined;
  }

  removeURL() {
    let x; let y; let
      z;

    z = encodeURI(this.props.url);
    if (z.substring(0, 3) === 'url') {
      x = z;
      y = x.split('(')[1];
      z = y.split(')')[0];
    }
    return z;
  }


  componentWillReceiveProps(nextProps) {
    this.setImage();
  }

  componentDidReceiveProps(nextProps) {
    this.setImage();
  }

  componentDidMount() {
    this.setImage();
    window.addEventListener('resize', this.updateDimensions);
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions() {
    if (!this.props.widthPercent) {
      this.setState({ widthPercent: 100 });
    } else if (this.props.widthPercent.includes('large-12')) {
      this.setState({ widthPercent: 100 });
    } else if (this.props.widthPercent.includes('large-8')) {
      this.setState({ widthPercent: 66.66 });
    } else if (this.props.widthPercent.includes('large-4')) {
      this.setState({ widthPercent: 33.33 });
    } else if (window.innerWidth < 640) {
      this.setState({ widthPercent: 100 });
    } else {
      const x = parseInt(this.props.widthPercent, 10);
      this.setState({ widthPercent: x });
    }
  }

  setImage() {
    return new Promise(
      (resolve, reject) => {
        try {
          this.measureDimensions()
            .then((imageObject) => {
              this.setState({
                naturalWidth: imageObject.naturalWidth,
                naturalHeight: imageObject.naturalHeight,
                widthPercent: imageObject.widthPercent,
                paddingTop: `${(imageObject.naturalHeight / imageObject.naturalWidth) * imageObject.widthPercent}%`,
              });
              resolve();
            });
        } catch (error) {
          reject(error);
        }
      },
    );
  }

  // this method is getting the height and width of the new screen size, setting old screen size
  // setting the images natural size and the size it needs to fit into
  measureDimensions() {
    return new Promise(
      (resolve, reject) => {
        try {
          const imageObject = {};
          if (this.props.url !== '') {
            let w; let h;
            const pic = new Image();
            pic.src = this.removeURL();
            pic.onload = function () {
              w = pic.width;
              h = pic.height;
              imageObject.naturalWidth = w;
              imageObject.naturalHeight = h;
              if (!this.props.widthPercent) {
                imageObject.widthPercent = 100;
                return resolve(imageObject);
              }
              if (this.props.widthPercent.includes('large-12')) {
                imageObject.widthPercent = 100;
                return resolve(imageObject);
              }
              if (this.props.widthPercent.includes('large-8')) {
                imageObject.widthPercent = 66.66;
                return resolve(imageObject);
              }
              if (this.props.widthPercent.includes('large-4')) {
                imageObject.widthPercent = 33.33;
                return resolve(imageObject);
              }
              if (window.innerWidth < 640) {
                imageObject.widthPercent = 100;
                return resolve(imageObject);
              }

              const x = parseInt(this.props.widthPercent, 10);
              imageObject.widthPercent = x;
              return resolve(imageObject);
            }.bind(this);
            pic.onerror = function () {
              imageObject.naturalWidth = 1;
              imageObject.naturalHeight = 0;
              imageObject.widthPercent = 0;
            };
          }
        } catch (error) {
          return reject(error);
        }
      },
    );
  }


  render() {
    const style = {
      wrapper: {
        position: 'relative',
        width: `${this.state.widthPercent}%`,
        backgroundRepeat: 'no-repeat',
        backgroundImage: this.addURL(),
        backgroundSize: '100% 100%',
        paddingTop: this.state.paddingTop,
      },
    };

    return (
        <div className={this.props.cName} style={style.wrapper}>{this.props.children}</div>
    );
  }
}

ImageScaleComponent.propTypes = {
  cName: PropTypes.string.isRequired,
  url: PropTypes.string,
  children: PropTypes.oneOf([PropTypes.object, PropTypes.array]),
  widthPercent: PropTypes.string,

};

ImageScaleComponent.defaultProps = {
  url: '',
};

export default ImageScaleComponent;
