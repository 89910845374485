import { GlobalAnalytics } from "../../../analytics";

const analytics = new GlobalAnalytics();

// Page Load
export const bzElectrifiedDiversifiedPageload = (pageLoad) => {
  pageLoad &&
    analytics.firePageLoad({
      section: 'Product',
      subsection: 'Featured Technology',
      technologyName: 'Beyond Zero',
      page: pageLoad.page,
    });
};

// tabs click
export const bzElectrifiedDiversifiedTabsClick = (clickItem) => {
  clickItem &&
    analytics.fireOnClick({
      tag: '29.2',
      section: 'Product',
      page: 'Beyond Zero',
      category: 'Electrified Diversified',
      label: clickItem.label,
      contentTitle: clickItem.content_title
    });
};

// general click
export const bzElectrifiedDiversifiedClick = (clickItem) => {
  clickItem &&
    analytics.fireOnClick({
      tag: '29.2',
      section: 'Product',
      page: 'Beyond Zero',
      category: 'Electrified Diversified',
      label: clickItem.label,
      modelName: clickItem.model_name,
      modelYear: clickItem.model_year,
      contentTitle: clickItem.content_title
    });
};