import { GlobalAnalytics } from '../../../analytics';

const analytics = new GlobalAnalytics();


export function sendFilterAnalytics(event) {
  analytics.fireOnClick({
    tag: '35.2',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'Toyota Safety Sense™',
    page: 'Features',
    category: 'Nav Link',
    contentTitle: event.target.textContent,
    mediaTitle: event.target.textContent,
  });
}

export function clickLink(event) {
  analytics.fireOnClick({
    tag: '35.2',
    mediaTitle: 'Show',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'Toyota Safety Sense™',
    page: 'Features',
    category: event.target.text,
    contentTitle: 'Show',
  });
}

export function sendPageLoadAnalytics() {
  analytics.firePageLoad({
    tag: '35.1',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'Toyota Safety Sense™',
    page: 'Features',
  });
}

export function sendVideoEventAnalytics(item) {
  analytics.fireOnClick({
    tag: '40.5',
    section: 'Product',
    subsection: 'Featured Technology',
    page: 'Toyota Safety Sense™',
    category: item.category,
    contentTitle: 'Features',
    mediaTitle: item.title.replace(/(<([^>]+)>)/g, ''),
    modelName: ' ',
    module: 'video player',
    label: item.title.replace(/(<([^>]+)>)/g, ''),
    events: item.event,
  });
}

export function readMoreClick(category, title) {
  analytics.fireOnClick({
    tag: '35.2',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'Toyota Safety Sense™',
    page: 'Features',
    category,
    contentTitle: title.replace(/(<([^>]+)>)/g, ''),
    mediaTitle: title.replace(/(<([^>]+)>)/g, ''),
  });
}
