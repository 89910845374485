import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import AppSettings from "../../../appsettings";
import { generalNavButtonClicked } from "../topNav.analytics";
import "../../../styles/css/topNav/headerComponents/engageLogo.css";

const engageLogo = (props) => (
  <div className="engage-logo-container">
    <Link onClick={() => props.clearMenuSelections()} to="/home">
      <img
        className="engage-logo"
        alt=""
        src={`${AppSettings.AWSImgRepo.resources}en/topNav_engage_logo.png`}
        onClick={() => generalNavButtonClicked(props.analytics)}
      />
    </Link>
  </div>
);

engageLogo.propTypes = {
  clearMenuSelections: PropTypes.func,
};

export default engageLogo;
