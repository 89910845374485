import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { ProfileListTemplate } from './profileList.tpl';

class ProfileListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profileList: this.props.profiles,
      filteredUsers: this.props.profiles,
    };

    this.filterUsers = this.filterUsers.bind(this);
  }

  filterUsers(event) {
    console.log('check');
    const filteredUsers = [];
    for (const user in this.state.profileList) {
      const userName = (`${this.state.profileList[user].firstName} ${this.state.profileList[user].lastName}`).toLowerCase();
      if (userName.includes(event.currentTarget.value.toLowerCase())) {
        filteredUsers.push(this.state.profileList[user]);
      }
    }
    this.setState({
      filteredUsers,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.profiles !== this.props.profiles) {
      this.setState({
        profileList: this.props.profiles,
        filteredUsers: this.props.profiles,
      });
    }
  }


  render() {
    return ProfileListTemplate(this);
  }
}

export default injectIntl(ProfileListComponent);
