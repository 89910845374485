import PropTypes from 'prop-types';
import React from 'react';
import RedLine from '../../atoms/RedLine';
import applyMarkdown from '../../../screens/utils/applyMarkdown.js';
import '../../../styles/css/smallPhotoCard.css';

const smallPhotoCard = props => (
  <div data-testid="small-photo-card" className="small-photo-card" onClick={() => props.clickFunction && props.clickFunction(props.title)}>
    <img className="small-photo-card-image" src={props.imagePath + props.image} />
    <div className="small-photo-card-content">
      <div className="small-photo-card-title">
        {props.title}
      </div>
      <RedLine/>
      <div className="small-photo-card-description">
        {applyMarkdown(props.description)}
      </div>
    </div>
  </div>
);

smallPhotoCard.propTypes = {
  description: PropTypes.string,
  imagePath: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string
};

export default smallPhotoCard;