import React from 'react';
import { FormattedMessage } from 'react-intl';
import { capFirst } from '../utils/capitalize';
import ArticleListComponent from '../articleList/articleList.component';
import '../../styles/css/resources-page.css';

const formatResultDisplayText = (recordType) => {
  const spacedText = recordType.replace(/\+/g, ' ');
  const capitalized = capFirst(spacedText.replace(/[A-Z]/g, ' \$&'));

  return capitalized;
};


const formatRecordTypeProp = recordType => recordType.toLowerCase();

const shouldDisplayOldCategories = (recordType) => {
  if (recordType.toLowerCase().indexOf('previousmodelyearresources') > -1
    || recordType.indexOf('2020') > -1
    || recordType.indexOf('2019') > -1
    || recordType.indexOf('2018') > -1
    || recordType.indexOf('2017') > -1) {
    return true;
  }
  return false;
};

// TODO add logic HERE for 'my20 and older' crap for passing shouldDisplayNewCategories prop in
export default function SkillsTemplate(component) {
  return (
    <div className="resources-page">
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <div className="row">
        <div className="small-12 columns">
          <hr />
          <h3><FormattedMessage id="skills.showResults" defaultMessage="Showing results for: " />: {formatResultDisplayText(component.state.recordType)}</h3>
          <hr />
        </div>
      </div>
      <ArticleListComponent
        recordType={`${component.state.resourceType}-${formatRecordTypeProp(component.state.recordType)}-en`}
        resourceClick={component.resourceClick.bind(component)}
        filterMenuClick={component.filterClick.bind(component)}
        shouldDisplayOldCategories={shouldDisplayOldCategories(component.state.recordType)}
        sortClick={component.sortClick.bind(component)}
      />
    </div>
  );
}
