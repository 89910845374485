import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { translateText } from '../utils/translateText';
import { postUserActivityRecord } from '../../services/userActivityService';
import EngageLiveStreamTemplate from './engageLiveStream.tpl';
import { GlobalAnalytics } from '../../analytics';
import AppSettings from '../../appsettings';

const analytics = new GlobalAnalytics();

export class EngageLiveStreamComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      languageOverride: this.props.languageOverride,
      guid: AppSettings.EngageLiveGuid,
      type: 'thumbnail',
      numberOfVideos: 1,
      countDownDate: 'June 25, 2019 05:15:00 PM UTC',
      secondquid: AppSettings.EngageLiveArchiveGuid,
      secondType: 'vertical',
      secondNumberOfVideos: 2,
      broadcastState: AppSettings.EngageLiveState,
    };

    this.setStateBefore = this.setStateBefore.bind(this);
    this.setStateDuring = this.setStateDuring.bind(this);
    this.setStateAfter = this.setStateAfter.bind(this);
  }

  componentDidMount() {
    postUserActivityRecord();
    analytics.firePageLoad({
      tag: '35.1',
      section: 'Product',
      subsection: 'Featured Technology',
      technologyName: 'Engage Live',
      page: translateText(this.props.tab),
    });
  }

  setStateBefore() {
    this.setState({ broadcastState: 'before' });
  }

  setStateDuring() {
    this.setState({ broadcastState: 'during' });
  }

  setStateAfter() {
    this.setState({ broadcastState: 'after' });
  }


  render() {
    return EngageLiveStreamTemplate(this);
  }
}

EngageLiveStreamComponent.propTypes = {
  languageOverride: PropTypes.bool,
  tab: PropTypes.string,
};

export default injectIntl(EngageLiveStreamComponent);
