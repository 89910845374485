import React, { useEffect, useState } from 'react';
import { TowingPayloadCalculator } from './towingPayload-calculator';
import * as analytics from './towingPayload-analytics';

export const ExpertCalculatorContainer = (props) => {
    const [payload, setPayload] = useState(0);
    const [towing, setTowing] = useState(0);

    const truckIcons = ["passengerWeight", "cabWeight", "bedWeight", "tongueWeight", "trailerTowWeight", "trailerWeight"];
    const suvIcons = ["passengerWeight", "cabinWeight", "trunkWeight", "roofRackWeight", "tongueWeight", "trailerTowWeight", "trailerWeight"];

    useEffect(() => {
        caculatorLoadedAnalytics();
    }, []);

    const caculatorLoadedAnalytics = () => {
        const page = _.replace(props.pageLoadAnalytics.calculator.pageNeedsReplace, props.pageLoadAnalytics.calculator.pageToReplace, props.vehicleSelection.modelName);
        analytics.calculatorLoaded(
            props.pageLoadAnalytics,
            page,
            props.vehicleSelection.modelYear,
            props.vehicleSelection.modelName,
            props.trimSelection.modelCode,
            props.trimSelection.trimName,
            props.trimSelection.engine,
            props.trimSelection.cab,
            props.trimSelection.bedLength,
            props.trimSelection.driveType,
        );
    };

    return (
        <TowingPayloadCalculator 
            mode="expert"
            iconsToDisplay={props.vehicleType == "truck" ? truckIcons : suvIcons}
            setPayload={setPayload}
            payload={payload}
            setTowing={setTowing}
            towing={towing}
            {...props}
        />
    );
};