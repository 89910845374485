import React from 'react';
import dompurify from 'dompurify';
import { FormattedMessage } from 'react-intl';
import '../../styles/css/accessories.css';
import formatMSRP from '../utils/formatMSRP.js';
import { translateText } from '../utils/translateText';
import AppSettings from '../../appsettings.js';

export function AccessoriesTemplate(component) {
  function isDash(string) {
    return ((string === '-' || string === '–') || string === '—');
  }

  const renderAccessoryFields = (accessory) => {
    return (
      <div className="columns large-4 medium-4">
        <div className="accessory-title">{accessory.title}</div>
        <div className="accessory-detail">Part Number: <span className="accessory-detail-bold">{accessory.partNumber}</span></div>
        <div className="accessory-detail">Installation Point: <span className="accessory-detail-bold">{accessory.installationPoint}</span></div>
        <div className="accessory-detail">Parts Only MSRP: <span className="accessory-detail-bold">{isDash(accessory.partsOnlyMsrp) ? accessory.partsOnlyMsrp : (accessory.partsOnlyMsrp ? formatMSRP(accessory.partsOnlyMsrp.toString()) : '-')}</span></div>
        <div className="accessory-detail">PPO Code: <span className="accessory-detail-bold">{accessory.ppoCode || '-'}</span></div>
        <div className="accessory-detail">Installed MSRP: <span className="accessory-detail-bold">{isDash(accessory.installed) ? accessory.installed : (accessory.installed ? formatMSRP(accessory.installed.toString()) : '-')}</span></div>
      </div>
    );
  }

  const createAccessory = (accessory, index) => (
    <div key={index} className="row large-12 columns accessory-container">
      <div className="columns large-4 medium-4 accessory-image">
        <img alt="" src={accessory.imageUrl} />
      </div>
      {renderAccessoryFields(accessory)}
      <div className="columns large-4 medium-4">
        <div dangerouslySetInnerHTML={{ __html: dompurify.sanitize(accessory.description) }} />
        <div className='acc-disclaimer' dangerouslySetInnerHTML={{ __html: dompurify.sanitize(isDash(accessory.disclaimer) ? '' : accessory.disclaimer) }} />
      </div>
    </div>
  );

  const accessoriesToDisplay =
    component.state.accessoriesToDisplay
      ? component.state.accessoriesToDisplay.map((accessory, index) => createAccessory(accessory, index))
      : [];

  return (
    <div className="accessories">
      <div className="row columns large-12 accessories-header">
        <div className="columns large-4 title">
          <FormattedMessage id="accessories.pageTitle" defaultMessage="Accessories" />
        </div>
        <div className="columns large-8 disclaimer">
          <img alt="" src={AppSettings.AWSImgRepo.resources + 'en/hazardSymbol.png'} />
          <FormattedMessage id="accessories.disclaimer" defaultMessage="IMPORTANT: Always confirm accessory part compatibility for possible conflicts before reviewing with the customer, especially when selecting new accessories to add to a vehicle with certain accessories previously installed." />
        </div>
      </div>
      <div className="row large-12 columns filter-and-search">
        <fieldset className="large-5 columns filters">
          <div className="filterBy">
            <FormattedMessage id="accessories.filterBy" defaultMessage="Filter by" />
          </div>
          <input name="showInterior" id="accessories.interiorCheckbox" type="checkbox" defaultChecked onChange={component.handleFilterChange} />
          <label htmlFor="accessories.interiorCheckbox">
            <FormattedMessage id="accessories.interior" defaultMessage="INTERIOR" />
          </label>
          <input name="showExterior" id="accessories.exteriorCheckbox" type="checkbox" defaultChecked onChange={component.handleFilterChange} />
          <label htmlFor="accessories.exteriorCheckbox">
            <FormattedMessage id="accessories.exterior" defaultMessage="EXTERIOR" />
          </label>
          <input name="showPerformance" id="accessories.performanceCheckbox" type="checkbox" defaultChecked onChange={component.handleFilterChange} />
          <label htmlFor="accessories.performanceCheckbox">
            <FormattedMessage id="accessories.performance" defaultMessage="PERFORMANCE" />
          </label>
        </fieldset>
        <div className="large-4 columns end search">
          <div className="row collapse">
            <div className="small-9 medium-10 columns">
              <input name="searchText" type="text" placeholder={translateText('accessories.search')} onChange={component.handleSearchTextChange} onKeyUp={component.handleSearchTextPress} />
            </div>
            <div className="small-3 medium-2 columns search-column">
              <input type="submit" value={translateText('accessories.search')} className="button postfix search-button" onClick={component.handleSearch} />
            </div>
          </div>
        </div>
      </div>
      <div className="visible-count">
        {component.state.visibleCount === 0 ? <FormattedMessage id="accessories.noneAvailable" defaultMessage={'No accessories available for selection criteria'} />
          : <FormattedMessage id="accessories.showingCount"
            defaultMessage={'Showing {visibleCount} of {totalCount}'}
            values={{
              visibleCount: component.state.visibleCount,
              totalCount: component.state.totalCount,
            }}
          />
        }
      </div>
      {accessoriesToDisplay}
    </div>
  );
}
