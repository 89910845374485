import { postCompetitiveAdvantagesAction } from '../../../services/nitroService'
import { GlobalAnalytics } from '../../../analytics';

const analytics = new GlobalAnalytics();

export function dropdownSelect(event) {
    analytics.fireOnClick({
      tag: "29.2",
      section: "Product",
      page: event?.page,
      category : "Comparison Card",
      label:  `${event?.typeSelect} Select`,
      contentTitle: event?.contentTitle,
      modelName: event?.modelName,
      modelYear: event?.modelYear,
    })
  }


export function updateMakeAnalytics() {
    postCompetitiveAdvantagesAction(undefined, undefined, undefined, undefined, true);
} 
export function updateModelAnalytics () {
    postCompetitiveAdvantagesAction(undefined, undefined, undefined, undefined, true);
} 
export function updateTrimAnalytics () {
    postCompetitiveAdvantagesAction(undefined, undefined, undefined, undefined, true);
} 
