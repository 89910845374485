import React from 'react';
import { FormattedMessage } from 'react-intl';
import applyMarkdown from '../utils/applyMarkdown';
import DisclaimersComponent from '../disclaimers/disclaimers.component';
import ImageScale from '../imageScale/imageScale.component';
import '../../styles/css/sourcebooks.css';
import AppSettings from '../../appsettings.js';
import { getLocale } from '../../services/languageTranslationService';
import { translateText } from '../utils/translateText';
import BookmarkPageComponent from '../bookmarks/bookmarkPage.component';
import MediaQuery from 'react-responsive';
import MobileBackButton from '../../components/molecules/MobileBackButton';

export function SourcebooksTemplate(component) {
  const breakpoints = {
    desktop: '(min-width: 1024px)',
    mobile: '(max-width: 1023px)',
  };
  const locale = getLocale();
  const header = (
        <div>
            <div className={`sourcebooks-header ${window.ReactNativeWebView ? 'padding-left' : ''}`}>
                <div className="header-title">
                    <div className="sb-content">
                        <FormattedMessage id='resources.sourcebooks' defaultMessage="Source Books" />
                        <BookmarkPageComponent title={translateText('resources.sourcebooks')} url={component.props.location.pathname} />
                    </div>
                </div>
            </div>
            <div className="sb-content">
                <MediaQuery query={breakpoints.mobile}>
                    <ImageScale cName={'header-image'} url={AppSettings.AWSImgRepo.sourcebooks + component.state.header.mobileImage}> </ImageScale>
                </MediaQuery>
                <MediaQuery query={breakpoints.desktop}>
                    <ImageScale cName={'header-image'} url={AppSettings.AWSImgRepo.sourcebooks + component.state.header.image}> </ImageScale>
                </MediaQuery>
                <div className="header-description">{applyMarkdown(component.state.header.description, component.disclaimer)}</div>
            </div>
        </div>
  );

  const sourcebookItems = component.state.sourcebookItems ? component.state.sourcebookItems.map((item, index) => {
    const vehicleList = item.vehicles ? item.vehicles.map((vehicle, vehicleIndex) => {
      const url = `/static/sourcebooks/${item.year}_${vehicle.name.toLowerCase().replace(' ', '_')}_${getLocale()}_SourceBook.pdf`;
      return (
                <div className="vehicle-item"><a target='_blank' href={encodeURI(url)} onClick={component.sourceBookClick.bind(null, vehicle.name, item.year)}>{`${item.year} ${vehicle.displayName || vehicle.name}`}</a>{(vehicleIndex + 1) === item.vehicles.length ? '' : <span className='break'>  |  </span>}</div>
      );
    }) : null;

    return (
            <div className="section-item">
                <div className="redline" />
                <div className="section-title">{`${item.year} `}<FormattedMessage id='resources.sourcebooks' defaultMessage="Source Books" /></div>
                <div className="vehicle-list">
                    {vehicleList}
                </div>
            </div>
    );
  }) : null;

  const legacySourcebookItems = component.state.legacySourcebookItems ? component.state.legacySourcebookItems.map((item, index) => {
    const language = (item.isSpanish && (locale === 'es') ? '_es' : '');
    const vehicleList = item.vehicles ? item.vehicles.map((vehicle, vehicleIndex) => (
                <div className="vehicle-item"><a target='_blank' href={`${AppSettings.AWSFileRepo.sourceBooks + item.year}_${vehicle.name.toLowerCase().replace(/ /g, '_')}${language}.pdf`} onClick={component.sourceBookClick.bind(null, vehicle.name, item.year)}>{`${item.year} ${vehicle.name}`}</a>{(vehicleIndex + 1) === item.vehicles.length ? '' : <span className='break'>  |  </span>}</div>
    )) : null;

    return (
            <div>
                {(item.year === '2015' && component.state.header.contentMessage) ? <div className="content-message">{component.state.header.contentMessage}</div> : ''}
                <div className="section-item">
                    <div className="redline" />
                    <div className="section-title">{`${item.year} `}<FormattedMessage id='resources.sourcebooks' defaultMessage="Source Books" /></div>
                    <div className="vehicle-list">
                        {vehicleList}
                    </div>
                </div>
            </div>

    );
  }) : null;


  const sourcebooksItemsList = (
        <div className="sb-content section-items-list">
            {sourcebookItems}
            {legacySourcebookItems}
        </div>
  );

  return (
        <div className="sourcebooks-component">
            {
                window.ReactNativeWebView &&
                <MobileBackButton style={{top: '8px', left:'18px'}}/>
            }
            {header}
            {sourcebooksItemsList}

            <DisclaimersComponent template={component.props.disclaimer} ref={(instance) => { component.disclaimer = instance; }} />
        </div>
  );
}
