import React, { useEffect, useState } from 'react';
import '../../styles/css/chipGroup.css'
import applyMarkdownRemoveDisclaimers from '../../screens/utils/applyMarkdownRemoveDisclaimers';
import AppSettings from '../../appsettings.js';

/*
List of chip components displayed in a row and wrap into a column if restricted by outer container width

required props:
    activeChips
        list of strings
        if empty and totalPlaceholderChips provided, displays totalPlaceholderChips # placeholder chips
        if empty and no totalPlaceholderChips provided, displays nothing
        if not empty and totalPlaceholderChips provided, displays active chips and (totalPlaceholderChips - activeChips.length) # placeholder chips
        if not empty and no totalPlaceholderChips provided, only displays active chips

recommended props:
    deleteChip
        function
        if not provided, no delete action on active chips
        passes feature name and index to allow specific deleting in case of duplicate named chips
    totalPlaceholderChips
        number
        if provided, will display (totalPlaceholderChips - activeChips.length) placeholder chips
        if not provided, will not display placeholder chips and only display active chips
        if you have more activeChips list than totalPlaceholderChips, they will display without placeholders
optional props:
    placeholderText
        string
        will only be displayed if totalPlaceholderChips is also provided
        if totalPlaceholderChips provided and placeholderText is not provided, will display default placeholderText "Add an Item"
        if you don't want to display placeholder chips, don't provide totalPlaceholderChips
    placeholderClick
        function
        if provided, placeholder chips will be clickable
    groupClassName
    chipClassName
        string
        optional class names for custom styling based on parent component

analytics props:
    deleteChipAnalyticsClick
        delete click function
    placeholderClickAnalytics
        placeholder click function
*/

const ChipGroup = (props) => {
    const placeholderText = props.placeholderText ? props.placeholderText : "Add an Item";

    const Chip = (props) => (
        <div className={`chipComponent ${props.className} ${props.type} ${props.onDelete && "deletableChip"} ${props.onClick && "clickableChip"}`} onClick={()=>props.onClick && props.onClick()}>
            <div className="chipContent">
                <div className="chipLabel">{props.text}</div>
                {props.onDelete && <div className="chipIcon" onClick={() => props.onDelete && props.onDelete()}>
                    <img className="deleteIcon" src={AppSettings.AWSImgRepo.resources + 'en/' + 'icon_close_white.png'} />
                </div>}
            </div>
        </div>
    )

    const deleteChip = (chip, index) => {
        props.deleteChipAnalyticsClick && props.deleteChipAnalyticsClick(chip);
        props.deleteChip(chip, index);
    }

    const placeholderChipClick = (chip, index) => {
        props.placeholderClickAnalytics && props.placeholderClickAnalytics();
        props.placeholderClick && props.placeholderClick(chip, index);
    }

    const generateChips = () => {
        let chips = props.activeChips.map((v, i) => (
            <Chip
                type="active"
                text={applyMarkdownRemoveDisclaimers(v)}
                onDelete={props.deleteChip ? () => deleteChip(v, i) : null}
                className={props.chipClassName}
                key={v}
            />
        ));

        while (chips.length < props.totalPlaceholderChips) {
            chips.push(
                <Chip
                    type="placeholder"
                    text={placeholderText}
                    className={props.chipClassName}
                    key={chips.length}
                    onClick={props.placeholderClick && placeholderChipClick}
                />
            )
        }

        return chips;
    };

    return (
        <div className={`chipGroupComponent ${props.groupClassName}`}>
            {generateChips()}
        </div>
    )
}

export default ChipGroup