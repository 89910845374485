import React, { useContext, useEffect, useState } from "react";
import "../../styles/css/nitroStats.css";
import { NitroContext } from "../contexts/nitroContext";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

function NitroStats() {
  const { nitroUserXPLevel } = useContext(NitroContext);
  const [currentLevelNumber, setCurrentLevelNumber] = useState('0');

  useEffect(() => {
    if (nitroUserXPLevel && nitroUserXPLevel.nitroCurrentLevel) {
      const level = nitroUserXPLevel.nitroCurrentLevel.replace('Level ', '');
      if (isNaN(level)) {
        setCurrentLevelNumber('0');
      } else {
        setCurrentLevelNumber(level);
      }
    }
  }, [nitroUserXPLevel]);

  return (
    <div className="nitro-stats">
      {nitroUserXPLevel ?
        <>
          <div className="level-container">
            {"Level "}
            <span className="nitro-stats-level">
              {currentLevelNumber}
            </span>
          </div>
          <div className="points-container">
            {"Total Points: "}
            <span className="nitro-stats-points">
              {nitroUserXPLevel.nitroUserPoints?.toLocaleString()} EXP
            </span>
          </div>
        </>
      :
          <Skeleton width={161}/>
      }
    </div>
  );
}

export default NitroStats;
