import httpOption from './httpOption';
import AppSettings from '../appsettings';

/* eslint-disable  */

export function getSearchResults(searchField, searchString, startOffset, size) {

    const url = AppSettings.hostUrl + ":" + AppSettings.apiPort + AppSettings.apiPath + '/search/' + encodeURI(searchField) + '/' + encodeURI(searchString) + '/' + startOffset + '/' + size;
    let options = httpOption.GET();

    return new Promise((resolve, reject) => {
        fetch(url, options)
            .then(response => {
                if(response.status >= 200 && response.status < 300){
                    return resolve(response.json());
                }
                else{
                    return reject(response);
                }
            });
    });
} 

export function getFeatureSearchResults(searchField, searchString, startOffset, size, flag){

    const url = AppSettings.hostUrl + ":" + AppSettings.apiPort + AppSettings.apiPath + '/search/' + 'featureAndSpecSearch/' + encodeURI(searchField) + '/' + encodeURI(searchString) + '/' + startOffset + '/' + size;
    let options = httpOption.POST();
    if(flag){
        let body = searchString
        body.flag = flag
        options.body = JSON.stringify(body)
    }

    return new Promise((resolve, reject) => {
        fetch(url, options)
            .then(response => {
                if(response.status >= 200 && response.status < 300){
                    return resolve(response.json());
                }
                else{
                    return reject(response);
                }
            });
    });
} 
