import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AppSettings from "../../appsettings";
import MobileBackButton from "../../components/molecules/MobileBackButton";
import "../../styles/css/sidebarMenu.css";
import { isTabletView } from "../utils/getScreenSize";

const SideBarMenu = (props) => {
  const [expandedNestedMenu, setExpandedNestedMenu] = useState(null);
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const history = useHistory();
  const [badges, setBadges] = useState(null);

  useEffect(() => {
    // Calculate badges when menuItems prop changes
    const badges = props.menuItems?.map(item => ({ id: item.id, title: item.title, badge: item.badge })) || [];
    setBadges(badges);
  }, [props.menuItems]);

  useEffect(() => {
    if (props.menuItems) {
      if (props.selectedPage) {
        setActiveMenuItem(props.selectedPage.key);
        const selectedHeaderKey = props.menuItems
          .find(m => m.nestedItems?.find(n => n.key === props.selectedPage.key))
          ?.key;
        if (selectedHeaderKey) {
          setExpandedNestedMenu(selectedHeaderKey);
        }
      } else {
        setActiveMenuItem(props.menuItems[0].key);
      }
    }
  }, [props.menuItems, props.selectedPage])

  const clickPageItem = (item, parentTitle) => {
    history.push(`${props.urlPrefix ? props.urlPrefix : ''}${item.urlPath}`)
    setActiveMenuItem(item.key)
    props.setSelectedPage({ ...item, parentTitle: parentTitle })

    // ANALYTICS
    if(props?.sideBarPageClickAnalytics) {
      props.sideBarPageClickAnalytics(item, parentTitle);
    }
  }

  const toggleNestedMenu = (item) => {
    setActiveMenuItem(item?.key);
    if (expandedNestedMenu === item?.key) {
      setExpandedNestedMenu('');
    } else {
      setExpandedNestedMenu(item?.key);

      // ANALYTICS
      if(props?.sideBarNestedMenuClickAnalytics) {
        props.sideBarNestedMenuClickAnalytics(item);
      }
    }

    // 1ms delay to allow menu to collapse before scrolling to new position
    setTimeout(() => {
      const clickedElement = document.getElementById(item?.key);
      const yPositionAfterMenuChange = clickedElement.getBoundingClientRect().top;
      const currentScrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
      if(clickedElement && !isElementInViewport(clickedElement)) {
        window.scrollTo ({
          top: yPositionAfterMenuChange + currentScrollPosition - 100,
          behavior: 'smooth'
        })
      }
    }, 1);
  };

  // Helper function to check if an element is in the viewport
  const isElementInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= window.innerHeight &&
      rect.right <= window.innerWidth
    );
  };


  const linkMenuItem = (item) => {
    const internal = "internalLink" in item;
    const button = <>
      <img className={`${internal ? "internal" : "external"}Icon`}
        src={`${AppSettings.AWSImgRepo.resources}en/${internal ? "internal" : "external"}-link.svg`} />
      {item.title}
    </>

    const linkAnalytics = () => {
      if(props?.sideBarLinkClickAnalytics) {
        props.sideBarLinkClickAnalytics(item);
      }
    }

    if (item.internalLink || item.externalLink) {
      return (
        <a className={`menuItem ${internal ? "internal" : "external"}Link`}
          href={item.internalLink || item.externalLink}
          target={!internal ? '_blank' : null}
          onClick={linkAnalytics} rel="noreferrer"
        >
          {button}
        </a>
      )
    } else if (item.externalLink === "") {
      return (
        <div className={`menuItem ${internal ? "internal" : "external"}Link disabled`} onClick={linkAnalytics}>
          {button}
        </div>
      )
    }
  };

  const getMenuItems = (item, parentTitle) => {
    const itemId = item.urlPath || item.key; // Use the urlPath as the id if available, otherwise use the key

    if (item.urlPath) {
      let hasBadge = badges?.find(e => e.id === item.id && e.badge > 0);

      // Check if hasBadge is greater than or equal to 10 and show 9+ to be more compact for ux
      if (hasBadge && hasBadge.badge >= 10) {
        hasBadge.badge = '9+';
      }
      return (
        <div
          key={itemId}
          id={itemId}
          className={`menuItem page ${activeMenuItem === item.key && "active"}`}
          onClick={() => clickPageItem(item, parentTitle)}
        >
          {item.title}
          {hasBadge && <div className='menu-item-badge'>{hasBadge.badge}</div>}
        </div>
      );
    } else if (item.nestedItems) {
      return (
        <div key={itemId} className="nestedMenuItems">
          <div
            id={itemId}
            className={`nestedItemsHeader menuItem
                  ${expandedNestedMenu === item.key ? 'expanded' : 'collapsed'}
                  ${activeMenuItem === item.key && "active"} `}
            onClick={() => {
              toggleNestedMenu(item);
            }}
          >
            <img
              className="dropdownArrow"
              src={`${AppSettings.AWSImgRepo.resources}en/mobile_back_arrow.svg`}
            />
            {item.title}
          </div>
          <div className={`nestedItems ${expandedNestedMenu !== item.key && "hidden"}`}>
            {item.nestedItems.map((n) => getMenuItems(n, item?.title))}
          </div>
        </div>
      );
    } else if ("externalLink" in item || "internalLink" in item) {
      return linkMenuItem(item);
    } else return null; // Return null for cases where no element is rendered
  };

  const handleTabletBackButton = (e) => {
    history.goBack();
    e.stopPropagation();
  }

  return (
    <div className="sidebar-container">
      <div className="sidebarMenu">
        {isTabletView(window.innerWidth) && (
          <div className="tablet-back-button-outer-container" onClick={handleTabletBackButton}>
            <MobileBackButton
              containerClassName="tablet-back-button-container"
              className="tablet-back-button-img"
              onClick={() => { }}
            />
            <span>Back</span>
          </div>
        )}
        {props.menuItems?.map((i) => (
          getMenuItems(i)
        ))}
      </div>
    </div>
  );
};

export default SideBarMenu;
