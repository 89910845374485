import { GlobalAnalytics } from '../../../analytics';

const analytics = new GlobalAnalytics();

const hybridBaseObject = {
  tag: '35.1',
  section: 'Product',
  subsection: 'Featured Technology',
  technologyName: 'Electrified',
};

const pageLoadAnalytics = (page) => {
  analytics.firePageLoad({
    ...hybridBaseObject,
    page,
  });
};

const sendVideoEventAnalytics = (item) => {
  analytics.fireOnClick({
    tag: '40.5',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'Electrified',
    page: 'Videos',
    category: item.category,
    contentTitle: 'Overview',
    mediaTitle: item.videoTitle.replace(/(<([^>]+)>)/g, ''),
    modelName: ' ',
    module: 'video player',
    label: item.videoTitle.replace(/(<([^>]+)>)/g, ''),
    events: item.event,
  });
};

export const videoAnalytics = {
  pageLoadAnalytics,
  sendVideoEventAnalytics,
};