import React, { useEffect, useState } from 'react';
import { getOutageAlerts, dismissOutageAlert } from '../../services/notificationService';
import AlertBanner from './alertBanner';
import AlertModal from './alertModal';
import { GlobalAnalytics } from '../../analytics';
import '../../styles/css/outageAlert.css';

const analytics = new GlobalAnalytics();

function OutageAlerts(props) {
    const [alerts, setAlerts] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        getOutageAlerts().then(data => setAlerts(data));
    }, []);

    useEffect(() => {
        if (isMobile && alerts.legnth) {
            setModalOpen(true);
            props.setPadding(0);
        } else {
            setModalOpen(false);
            props.setPadding(alerts.length);
        }
    }, [isMobile, alerts]);

    function fireDismissAnalytics(alert) {
        analytics.fireOnClick({
            tag: '36.3',
            modelName: ' ',
            section: 'Outage Alerts',
            category: 'Dismiss Outage Alert',
            contentTitle: alert.title,
            mediaTitle: alert.title,
            module: 'Outage Alerts',
            label: 'Dismiss Outage Alert',
        });
    }

    function dismissAlert(alert) {
        dismissOutageAlert(alert.id);
        const updatedAlerts = alerts.filter(item => alert.id !== item.id);
        setAlerts(updatedAlerts);
        fireDismissAnalytics(alert);
    }

    function closeModal() {
        setModalOpen(false);
        alerts.forEach(alert => dismissAlert(alert));
    }

    return (alerts.length ? (
        isMobile ? (
            <AlertModal alerts={alerts} modalOpen={modalOpen} closeModal={closeModal} />
        ) : (
            alerts.map((alert, index) => <AlertBanner key={index} alert={alert} dismissAlert={dismissAlert} />)
        )
    ) : (
        <></>
    ));
}

export default OutageAlerts;
