import 'whatwg-fetch';
import httpOption from './httpOption';
import { getAccessLevelBasedOnJobCode } from '../screens/utils/authentication.js';
import { postLoginAction } from './nitroService';
import { UserManager, WebStorageStateStore } from 'oidc-client';
import AppSettings from '../appsettings';
import { loginSucceced } from '../components/auth/login.analytics.js';
import { postMessageToApp } from '../screens/utils/hybridAppHelpers.js';

export function getAccessToken() {
  try {
    const localStorageKeys = Array.from({length: localStorage.length}, (_, i) => localStorage.key(i));
    const userDataKey = localStorageKeys.find(key => key.startsWith('oidc.user:https://ep.fram.')) || null;
    const userData = localStorage.getItem(userDataKey);
    const accessToken = JSON.parse(userData).access_token;
    return accessToken;
  } catch (error) {
    console.error('Error getting access token from browser storage.', error);
  }
}

export function setAuthenticatedUserInformation(user) {
  localStorage.setItem('dealer_code', user.dealerCode ?? '90010');
  localStorage.setItem('spin_id', user.spinId ?? '');
  localStorage.setItem('region_code', user.regionCode ?? '');
  localStorage.setItem('full_name', `${user.firstName ?? ''} ${user.lastName ?? ''}`);
  localStorage.setItem('job_code', user.primaryJobCode ?? '');
  localStorage.setItem('name_id', user.nameID ?? '');
  localStorage.setItem('legacyProfileID', user.legacyProfileID ?? '');
  localStorage.setItem('isAdmin', user.isAdmin ?? '');
  localStorage.setItem('isGuest', user.isGuest ?? '');
  localStorage.setItem('disableCommenting', user.disableCommenting ?? '');
  localStorage.setItem('firstName', user.firstName ?? '');
  localStorage.setItem('lastName', user.lastName ?? '');
  localStorage.setItem('email', user.email ?? '');
  localStorage.setItem('isCorporate', user.isCorporate);
  localStorage.setItem('personType', user.TMSPersonType ?? '');
  localStorage.setItem('accessLevelRole', setUserAccessRole(user)); // default to lowest permission level
}

export function removeAuthInfo() {
  localStorage.removeItem("access_token");
  localStorage.removeItem("id_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("expires_at");
}

function setUserAccessRole(user) {
  return getAccessLevelBasedOnJobCode(user.isAdmin, user.primaryJobCode, localStorage.isGuest);
}

export async function getInitializedUserManager() {
  const response = await fetch(AppSettings.forgeRockWellKnown);
  const config = await response.json();
  const userManager = new UserManager({
      authority: config.issuer,
      client_id: window.ReactNativeWebView ? 'EngageMobile' : 'Engage',
      redirect_uri: AppSettings.redirect_uri,
      post_logout_redirect_uri: AppSettings.logout_uri,
      response_type: 'code',
      scope: AppSettings.oidcScope,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      response_mode: 'query',
      accessTokenExpiringNotificationTime: 60,
  });
  return userManager;
}

export async function getUserData(userManager) {
  const user = await userManager.getUser();
  const sessionUserData = await handleUserData(user.profile, user.access_token);
  setAuthenticatedUserInformation(sessionUserData);

  postLoginAction();
}

export async function getUserDataApp(forgeRockUserData) {
  const accessToken = getAccessToken();
  const sessionUserData = await handleUserData(forgeRockUserData, accessToken);
  setAuthenticatedUserInformation(sessionUserData);

  postLoginAction();
  loginSucceced();
}

const handleUserData = async (forgeRockUserData, accessToken) => {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/handleUserData`;
  const options = httpOption.POST();
  options.headers.Authorization = `Bearer ${accessToken}`;
  options.body = JSON.stringify({
    forgeRockUserData,
  });

  const response = await fetch(url, options);
  if (response.ok) {
    const sessionUserData = await response.json();
    return sessionUserData;
  } else {
    const errorData = await response.json();
    throw new Error(errorData.error);
  }
};

export async function fetchUserInfo(userManager, accessToken) {
  const response = await fetch(`${userManager.settings.authority}/userinfo`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }); 
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    console.error('Failed to fetch user info');
  }
}

export async function renewToken(userManager, lastActivity) {
  try {
    if (!userManager) {
      return;
    }
    const timeSinceLastActivity = Date.now() - lastActivity;
    const ACTIVITY_TIMEOUT = AppSettings.authActivityTimeout;
    if (timeSinceLastActivity < ACTIVITY_TIMEOUT) {
      await userManager.signinSilent();
      const accessToken = getAccessToken();
      postMessageToApp('AccessToken', accessToken);
    }
  } catch (error) {
    console.error('Error during silent renew on user activity:', error);
  }
}
