import { GlobalAnalytics } from '../../analytics/index';

const modelOverviewAnalytics = new GlobalAnalytics();
const pageName = 'Overview';
const sectionName = 'Product';

export function fireModelOverviewPageLoad(modelName, modelYear) {
  modelOverviewAnalytics.fireOnClick({
    modelName,
    modelYear,
    page: pageName,
    section: sectionName,
    tag: '29.1',
  });
};

export function fireModelOverviewClick(category, label, module) {
  modelOverviewAnalytics.fireOnClick({
    category,
    label,
    module,
    page: pageName,
    section: sectionName,
    tag: '28.3',
  });
};

// module.exports.fireModelOverviewPageLoad = fireModelOverviewPageLoad;
// module.exports.fireModelOverviewClick = fireModelOverviewClick;
