import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/css/engageLive.css';
import { postUserActivityRecord } from '../../services/userActivityService';
import { GlobalAnalytics } from '../../analytics';
import { translateText } from '../utils/translateText';

const analytics = new GlobalAnalytics();

export const videoAnalytics = (videoBeingPlayed, event, category, sendPlayClick) => {
  if (sendPlayClick) {
    const videoPath = `${window.location.pathname}#${videoBeingPlayed.guid}`;
    postUserActivityRecord(videoPath, true, videoBeingPlayed.title);
  }
  let module = 'POST_EVENT';
  if (videoBeingPlayed && videoBeingPlayed.live) {
    module = videoBeingPlayed.live.state;
  }
  analytics.fireOnClick({
    tag: '40.5',
    section: 'Engage Live',
    page: translateText(this.props.tab),
    category,
    contentTitle: videoBeingPlayed.title,
    mediaTitle: videoBeingPlayed.title,
    modelName: ' ',
    module,
    label: videoBeingPlayed.title,
    events: event,
  });
};

const EngageVideoWidget = (props) => {
  const widgetId = (props.carousel ? 'qumu-widget-carousel' : 'qumu-widget');
  let sentAnalyticsForTwentyFive;
  let sentAnalyticsForFifty;
  let sentAnalyticsForSeventyFive;
  // eslint-disable-next-line no-undef
  const widgetApi = KV.widget({
    selector: `#${widgetId}`,
    ...props,
    width: props.width || 300,
    height: props.height || 300,
    sort: !props.carousel ? [
      {
        field: 'created',
        direction: 'desc',
      },
    ] : [],
    packshot: props.carousel ? {
      width: 400,
      height: 200,
      fit: 'full',
      anchor: 'top-left',
    } : false,
    overlayPosition: 'middle-center',
    playIcon: {
      hide: true,
    },
  }).api;

  widgetApi.init((err) => {
    if (err) {
      console.log(err);
    } else {
      widgetApi.bind('play', () => {
        widgetApi.get('kulu', (kulu) => {
          videoAnalytics(kulu, 'event71', 'Video Play Click', true);
        });
      });

      widgetApi.bind('ended', () => {
        widgetApi.get('kulu', (kulu) => {
          videoAnalytics(kulu, 'event72', 'Video Complete');
        });
      });
      if (!props.carousel) {
        widgetApi.bind('timeupdate', () => {
          widgetApi.get('kulu', (kulu) => {
            widgetApi.get('currentTime', (currentTime) => {
              const percentWatched = Math.floor((currentTime / kulu.duration) * 100);
              if (!sentAnalyticsForTwentyFive && percentWatched > 25) {
                videoAnalytics(kulu, 'event73', 'Video 25%');
                sentAnalyticsForTwentyFive = true;
              } else if (!sentAnalyticsForFifty && percentWatched > 50) {
                videoAnalytics(kulu, 'event74', 'Video 50%');
                sentAnalyticsForFifty = true;
              } else if (!sentAnalyticsForSeventyFive && percentWatched > 75) {
                videoAnalytics(kulu, 'event75', 'Video 75%');
                sentAnalyticsForSeventyFive = true;
              }
            });
          });
        });
      }
    }
  });

  return <div id={widgetId} className={props.className}/>;
};

EngageVideoWidget.propTypes = {
  guid: PropTypes.string,
  alias: PropTypes.string,
  type: PropTypes.string,
  numberOfVideos: PropTypes.number,
  hideEmbedButton: PropTypes.bool,
  hideDownloadButton: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  carousel: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default EngageVideoWidget;
