import AppSettings from "../../appsettings";
import { postMessageToApp } from "../../screens/utils/hybridAppHelpers";
import '../../styles/css/BackButton.css'

const s3Path = AppSettings.AWSImgRepo.resources;
const BlackLeftArrow = 'en/back-left-arrow.png';

const BackButton = (props) => {
    const handleBack = () => {
        // isGoBack prop prevent to dispatch "Go Back" message when we've a speficic page to navigate 
        // i.e: when user is able to acces the URL directly and we haven't the previous navigation context to go back
        if (window.ReactNativeWebView && props.isGoBack) postMessageToApp("Go Back");
        if (props.onClick) return props.onClick();
    };

    return <button className="global-back-button" onClick={handleBack}>
        <img
            className="left-arrow"
            src={s3Path + BlackLeftArrow}
        />
        <span>Back</span>
    </button>
}

export default BackButton;