import S from "../../../../styles/scss/engageXP/tournaments/ring.module.scss";

const CountdownRing = ({ number, label, shouldContinueCountdown }) => {
  const currentNumber = number;

  // If the number is 23 and the label is "Hours", the next number should be "00"
  // If the number is 59 and the label is not "Days", the next number should be "00"
  const nextNumber = shouldContinueCountdown ? number === 23 && label === "Hours" ?
    "0" :
    number === 59 && label !== "Days" ?
      "0" :
      number + 1 : "0";

  return (
    <div className={S["ring-container"]}>
      <div className={S["ring-number"]}>
        <div
          className={S["ring-number-1"]}
          key={`${label}-next-${nextNumber}`}
          style={{ animationPlayState: shouldContinueCountdown ? "running" : "paused" }}
        >{nextNumber}</div>
        <div
          className={S["ring-number-2"]}
          key={`${label}-current-${currentNumber}`}
          style={{ animationPlayState: shouldContinueCountdown ? "running" : "paused" }}
        >{currentNumber}</div>
      </div>
      <div className={S["ring-label"]}>{label}</div>
    </div>
  );
}

export default CountdownRing;
