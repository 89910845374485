import { GlobalAnalytics } from '../../../analytics';

const analytics = new GlobalAnalytics();

const contentLinkClick = (tag, section, subsection, technologyName, page, category, positionTitle, contentTitle) => {
  analytics.fireOnClick({
    tag,
    section,
    subsection,
    technologyName,
    page,
    category,
    positionTitle,
    contentTitle,
  });
};

export const tileClick = (clickAnalytics, contentTitle, isVisible) => {
  const roadMapCategory = isVisible ? clickAnalytics.roadMapTileClick.categoryTileExpand : clickAnalytics.roadMapTileClick.categoryTileCollapse; 

  clickAnalytics && clickAnalytics.roadMapTileClick && contentTitle && contentLinkClick(
    clickAnalytics.roadMapTileClick.tagId,
    clickAnalytics.roadMapTileClick.section,
    clickAnalytics.roadMapTileClick.subsection,
    clickAnalytics.roadMapTileClick.technologyName,
    clickAnalytics.roadMapTileClick.page,
    roadMapCategory,
    clickAnalytics.roadMapTileClick.positionTitle,
    contentTitle,
  );
};

export const roadMapSubLoad = (pageLoadAnalytics) => {
  pageLoadAnalytics && pageLoadAnalytics.roadMapSubLoad && contentLinkClick(
    pageLoadAnalytics.roadMapSubLoad.tagId,
    pageLoadAnalytics.roadMapSubLoad.section,
    pageLoadAnalytics.roadMapSubLoad.subsection,
    pageLoadAnalytics.roadMapSubLoad.technologyName,
    pageLoadAnalytics.roadMapSubLoad.page,
    pageLoadAnalytics.roadMapSubLoad.category,
    pageLoadAnalytics.roadMapSubLoad.positionTitle,
    pageLoadAnalytics.roadMapSubLoad.contentTitle,
  );
};
