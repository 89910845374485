import appsettings from "../../appsettings";

export  const getJobTitle = (code) => {
  const jobTitle = appsettings.JobCode[code];

  if(!jobTitle){
      return 'Team Member';
  }

  return jobTitle;
}