import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { isDesktopView } from '../../screens/utils/getScreenSize.js';
import imageToggleOnHover from '../../screens/utils/imageToggleOnHover';
import '../../styles/css/iconLinks.css';

const iconLinks = (props) => {

  const topDividerLine = !props.homepage && <div className="divider-line-landing"></div>
  const bottomDividerLine = !props.homepage ? <div className="divider-line-landing"></div> : <div className="divider-line-homepage"></div>
  const iconImageClassName = 'icon-link-image';
  const hoverIconImageClassName = 'icon-link-image-hover';

  const handleMouseOver = imageContainer => {
    if (isDesktopView(window.innerWidth)) {
      imageToggleOnHover(imageContainer, iconImageClassName, hoverIconImageClassName);
    }
  };
  const handleMouseOut = imageContainer => {
    if (isDesktopView(window.innerWidth)) {
      imageToggleOnHover(imageContainer, hoverIconImageClassName, iconImageClassName);
    }
  };

  const linkIcons = props.links && props.links.map(link => (
    <div key={link.url} className="icon-link">
      <div className="icon-link-small-border-div">
        <a
          className="icon-link-anchor"
          href={link.url}
          target={isDesktopView(window.innerWidth) && link.target}
          onClick={() => props.fireIconLinkClick(_.toUpper(link.title))}
          onMouseOver={imageContainer => handleMouseOver(imageContainer)}
          onMouseOut={imageContainer => handleMouseOut(imageContainer)}
        >
          <img className={iconImageClassName} alt={link.alt} src={props.imagePath + link.imgSrc}/>
          <img className={hoverIconImageClassName} alt={link.alt} src={props.imagePath + link.hoverImgSrc}/>
          <div className="icon-link-title">{_.toUpper(link.title)}</div>
        </a>
      </div>
    </div>
  ));

  return (
    <React.Fragment>
      {topDividerLine}
      <div className="icon-links-container">
        {linkIcons}
      </div>
      {bottomDividerLine}
    </React.Fragment>
  );
};

iconLinks.propTypes = {
  fireIconLinkClick: PropTypes.func,
  homepage: PropTypes.bool,
  imagePath: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({
    alt: PropTypes.string,
    hoverImgSrc: PropTypes.string,
    imgSrc: PropTypes.string,
    target: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
  })),
};

export default iconLinks;
