function isDesktopView(screenWidth, minWidth = 1024) {
  return screenWidth >= minWidth;
}

function isTabletView(screenWidth, maxWidth = 1024, minWidth = 640) {
  return screenWidth < maxWidth && screenWidth >= minWidth;
}

function isMobileView(screenWidth, maxWidth = 640) {
  return screenWidth < maxWidth;
}

module.exports.isDesktopView = isDesktopView;
module.exports.isTabletView = isTabletView;
module.exports.isMobileView = isMobileView;
