import 'whatwg-fetch';
import httpOption from './httpOption';
import AppSettings from '../appsettings';


export function postEmail(email, language) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/email`;
  const options = httpOption.POST();
  options.body = JSON.stringify({
    email,
    language
  });

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return resolve(response.json());
        }
        return reject(response);
      });
  });
}

export function postSmartPathEmail(email) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/email/smartPath`;
  const options = httpOption.POST();
  options.body = JSON.stringify({
    email,
  });

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return resolve(response.json());
        }
        return reject(response);
      });
  });
}
