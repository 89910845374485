import React from "react";
import "../../../styles/css/resources-v2/trim-walk-header.css";

const TrimWalkHeader = (props) => {

    const tabComponent = (index, selected) => {
        return (
            <div className={`header-tab ${selected ? 'selected' : ''}`}>
                {`Vehicle ${index + 1}`}
            </div>
        );
    }

    const headerComponent = (vehicle, index) => {
        let label;
        if (index === 0) {
            label = (
                <div className="trim-walk-header-section">
                    {vehicle?.name}
                </div>
            )
        } else if (index === 1) {
            label = (
                <div className="trim-walk-header-section">
                    <div className="header-small-text">{`${vehicle?.name} (${vehicle?.modelCode})`}</div>
                    <div>{`Adds to ${props.vehicles[index-1]?.name}`}</div>
                </div>
            )
        } else {
            label = (
                <div className="trim-walk-header-section">
                    <div className="header-small-text">{`${vehicle?.name} (${vehicle?.modelCode})`}</div>
                    <div>{`Adds to ${props.vehicles[index-2]?.name} & ${props.vehicles[index-1]?.name}`}</div>
                </div>
            )
        }

        return (
            <div className="trim-walk-header-section-container">
                {label}
            </div>
        )
    }

    return (
        <div className="trim-walk-header">
            {props?.vehicles.map((vehicle, index) => {
                return (
                    <div className='header-column' onClick={() => props?.setSelectedVehicle(index)}>
                        {tabComponent(index, props?.selectedVehicle === index)}
                        {headerComponent(vehicle, index)}
                    </div>
                )
            })}
        </div>
    );
}

export default TrimWalkHeader;
