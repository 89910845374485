import PropTypes from 'prop-types';
import React from 'react';
import "../../../styles/css/marketingPage/testimonial.css";
import applyMarkdown from '../../../screens/utils/applyMarkdown.js';

const testimonial = props => {

  return ( 
  <div className="testimonialContainer">
      <div className="testimonialQuote">{applyMarkdown(props.quote)}</div>
      <div className="testimonialAuthor">
          <div>{props.author && ("- " + props.author)}</div>
          <div>{props.authorTitle || ""}</div>
      </div>
  </div>
  )};

testimonial.propTypes = {
  quote: PropTypes.string,
  author: PropTypes.string,
  authorTitle: PropTypes.string
};

export default testimonial;