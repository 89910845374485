import 'whatwg-fetch';
import httpOption from './httpOption';
import AppSettings from '../appsettings';

export default function getVehicleAccessories(modelName, modelYear, mockResponse) {
  const mockedAccessories = {

  };

  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/vehicle/accessories/${modelName}/${modelYear}`;
  const options = httpOption.GET_NO_LANGUAGE();

  return fetch(url, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return mockResponse ? Promise.resolve(mockedAccessories) : Promise.resolve(response.json());
      }
      return Promise.reject(response);
    });
}
