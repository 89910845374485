export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

export function createCookie(name, value, days, shareDomain) {
  let expires = '';
  if (value) {
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = `; expires=${date.toUTCString()}`;
    } else {
      expires = '';
    }
    const domain = shareDomain ? ';domain=.toyota.com' : '';
    document.cookie = `${name}=${value}${expires}${domain}; path=/`;
  }
}

export function deleteCookie(name, shareDomain) {
  const domain = shareDomain ? ';domain=.toyota.com' : '';
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC${domain}; path=/`;
}
