import RoundedButton from "../../../components/molecules/roundedButton";
import getTarget from "../../utils/getTarget";
import '../../../styles/css/homepage/quickLinks.css';
import { generalButtonClicked } from "../homepage.analytics";

/**
 * required props:
    quickLinkButtons: array of objects
        title
            string
        link
            string
 */

const QuickLinks = (props) => {
  return (
    <div className="quicklinks-container">
      {props.quickLinkButtons.map((button) => (
        <RoundedButton
          title={button.text.toUpperCase()}
          active={false}
          onClick={(e) => {
            e.target.blur();
            generalButtonClicked(props.analytics, button.text.toUpperCase());
            window.open(button.link, getTarget(button.link))
          }}
        />
      ))}
    </div>
  );
};

export default QuickLinks;
