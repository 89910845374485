import { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  getNitroRecentActivities
} from "../../services/nitroService";
import '../../styles/css/engageXP/recentAcitivityList.css';
import { getRelativeTime } from "./utils";

const MAX_RECENT_ACTIVITY_ITEMS = 5;

const RecentActivityList = ({ missions }) => {
  const [activities, setActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fixedHeight, setFixedHeight] = useState(0);
  const ulRef = useRef(null);

  useEffect(() => {
    const updateFixedHeight = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth >= 1281) {
        setFixedHeight(340);
      } else if (screenWidth >= 400 && screenWidth <= 1280) {
        setFixedHeight(430);
      } else if (screenWidth < 400) {
        setFixedHeight(425);
      }
    };

    updateFixedHeight();

    window.addEventListener('resize', updateFixedHeight);

    return () => {
      window.removeEventListener('resize', updateFixedHeight);
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (!!missions.length) {
      getActivities();
      setIsLoading(false);
    }
  }, [missions]);

  function getActivities() {
    const completedMissions = missions.filter(mission => mission.completionCount > 0);
  
    completedMissions.sort((a, b) => b.lastCompletedTime - a.lastCompletedTime);
  
    const recentCompletedMissions = completedMissions.slice(0, 5);
  
    const activities = [];
    recentCompletedMissions.forEach(mission => {
      const displayText = `You completed ${(mission.displayName || mission.name).trim()}!`;
      const eventTime = mission.lastCompletedTime;
      activities.push({ displayText, eventTime });
  
      if (mission.rewards) {
        const badgeReward = mission.rewards.find(reward => reward.type === 'badge');
        if (badgeReward) {
          const badgeUrl = badgeReward.thumbUrl;
          activities.pop();
          activities.push({ displayText: `You recently earned a badge for completing ${(mission.displayName || mission.name).trim()}!`, eventTime, badgeUrl });
        }
      }
    });
  
    setActivities(activities);
  }

  const checkAndRemoveListItem = () => {
    const ulElement = ulRef.current;
    if (ulElement) {
      const ulHeight = ulElement.clientHeight;
      if (ulHeight > fixedHeight && activities.length > 1) {
        setActivities(prevActivities => prevActivities.slice(0, -1));
      }
    }
  }

  useEffect(() => {
    checkAndRemoveListItem();
  }, [isLoading, activities.length, fixedHeight]);

  return (
    <div className="recent-activity-list-wrapper">
      <h1>Recent Activity</h1>
      {isLoading && <Skeleton className="list-item-loading" count={4} height ={75} />}

      {(!isLoading && Boolean(activities?.length)) && (
        <ul ref={ulRef}>
          {activities?.map((activity, index) => (
            <li key={index}>
              <div>
                <h4>{activity?.displayText}</h4>
                <span>{getRelativeTime(new Date(+activity?.eventTime * 1000))}</span>
              </div>
              {activity?.badgeUrl && <img src={activity?.badgeUrl} />}
            </li>
          ))}
        </ul>
      )}

      {(!isLoading && !Boolean(activities?.length)) && <span className='list-no-items'>No recent activity.</span>}
    </div>
  );
}

export default RecentActivityList;
