import React from 'react';
import { FormattedMessage } from 'react-intl';
import defaultImage from '../../../resources/images/safety_connect_logo_four_color.png';
import ArticleListComponent from '../../articleList/articleList.component';
import { getLocale } from '../../../services/languageTranslationService.js';

export function TCUVResourcesTemplate(component) {
  return (
        <div className="resources-page">
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <div className="row">
                <div className="small-12 large-12 columns">
                    <div className="title">
                        <FormattedMessage id={component.props.tab} />
                    </div>
                </div>
            </div>
            <ArticleListComponent
                recordType={`tag-tcuv-${getLocale()}`}
                resourceClick={component.resourceListClick}
                filterMenuClick={component.filterLinksClick}
                sortClick={component.sortAnalytics}
                defaultImage={defaultImage}
            />
        </div>
  );
}
