import React, { useEffect, useState } from "react";
import "../../../styles/scss/engageXPDashboardTriviaComponents/engageXPTournamentRounds.scss";
import { getRoundStatus } from "../utils";
import { formatDateTime } from "../../utils/formatDate";
import { getTournamentTransactionsByMissions } from "../../../services/nitroService";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Round = ({ round, isTall, tournamentsData, deviceType, imgPath, handleRoundClick, currentRound }) => {
    const status = getRoundStatus(round);
    const [roundStatsFetched, setRoundStatsFetched] = useState(false);
    const [averageScore, setAverageScore] = useState();
    const [pointsEarnedInRound, setPointsEarnedInRound] = useState();
    const roundImage = round[`${deviceType}Image`];
    const imageStyle = deviceType ? { backgroundImage: `url(${imgPath + roundImage})` } : {};

    const getCTA = (round, status) => {
        if (status === "locked") {
            return <img className="cta-area locked" src={imgPath + "roundLock.svg"} />;
        }
        const text = status === "open" ? "Play Now" : "Closed";
        const clickHandler = status === "open" ? () => handleRoundClick(round) : null;
        return (
            <div className={`cta-area ${status}`} onClick={clickHandler}>
                {text}
            </div>
        );
    };

    const getFooter = (round, status, averageScore, pointsEarnedInRound) => {
        const averageScoreToDisplay = averageScore !== undefined ? `${Math.round(averageScore * 100)}%` : <Skeleton />;
        const pointsEarnedToDisplay = pointsEarnedInRound !== undefined ? pointsEarnedInRound : <Skeleton />;
        if (status === "locked") {
            const text = `Opens ${formatDateTime(round.startDate)} - ${formatDateTime(round.endDate)}`;
            return <div className="footer-area locked">{text}</div>;
        }
        if (status === "open" && round.pointsRequiredToMoveOn !== undefined) {
            return (
                <div className="footer-area open">
                    <div>Achieve to move to next round</div>
                    <div className="point-value">{`${round.pointsRequiredToMoveOn} Pro Points`}</div>
                </div>
            );
        }
        if (status === "closed") {
            return (
                <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <div className="footer-area closed">
                        <div className="round-stat">
                            <div>Average Score</div>
                            <div className="stat-value">{averageScoreToDisplay}</div>
                        </div>
                        <div className="round-stat">
                            <div>Pro Points earned</div>
                            <div className="stat-value">{pointsEarnedToDisplay}</div>
                        </div>
                    </div>
                </SkeletonTheme>
            );
        }
    };

    useEffect(() => {
        const updateRoundStats = async () => {
            if (status !== "closed" || roundStatsFetched) {
                return;
            }

            const uniqueMissionIDs = [...new Set(round.missions.map((mission) => mission.id))];
            try {
                const updatedStats = await getTournamentTransactionsByMissions(uniqueMissionIDs, tournamentsData);
                setAverageScore(updatedStats?.averageScore ?? 0);
                setPointsEarnedInRound(updatedStats?.tournamentPoints ?? 0);
                setRoundStatsFetched(true); // Functional update
            } catch (error) {
                console.error("Error updating round stats:", error);
            }
        };

        updateRoundStats();
    }, [currentRound]);

    return (
        <div className={`round-tile ${isTall ? "tall" : ""}`} style={imageStyle}>
            {status === "locked" && <div className={`round-overlay ${isTall ? "tall" : ""}`} />}
            <div className="top-section">
                <div className="title-area">
                    <div className="title">{round.title}</div>
                    <div className="description">{round.description}</div>
                </div>
                {getCTA(round, status)}
            </div>
            <div className="bottom-section">{getFooter(round, status, averageScore, pointsEarnedInRound)}</div>
        </div>
    );
};

export default Round;
