import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GlobalAnalytics } from '../../analytics';
import { isDesktopView, isTabletView, isMobileView } from '../../screens/utils/getScreenSize';
import '../../styles/css/scrollButton.css';

const ScrollButton = (props) => {
    const analytics = new GlobalAnalytics();
    const [visible, setVisible] = useState(false);
    const [device, setDevice] = useState('desktop');

    const buttonClicked = (section, action) => {
        analytics.fireOnClick({
            section,
            action
        });
    };

    useEffect(() => {
        updateDevice();
        window.addEventListener("resize", updateDevice);
        return () =>
          window.removeEventListener("resize", updateDevice);
      }, []);

    const updateDevice = () => {
        if (isDesktopView(window.innerWidth)) {
          setDevice('desktop');
        } else if (isTabletView(window.innerWidth)) {
          setDevice('tablet');
        } else if (isMobileView(window.innerWidth)) {
          setDevice('mobile');
        }
      }

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        const isVisible = !!(scrolled > 300) && sessionStorage.getItem('showBackToMission') !== 'true';
        setVisible(isVisible);
    };
    
    const scrollToTop = () => {
        window.scrollTo({
            top: 0, 
            behavior: 'smooth',
        });
        buttonClicked(props.url, 'Back to Top');
        
    };
      
    window.addEventListener('scroll', toggleVisible);

    return (
        <React.Fragment>
            <img
                className='scroll-button' 
                onClick={scrollToTop}
                onMouseEnter={e => (device === 'desktop' ? e.currentTarget.src=props.imagePath + 'scrollButtonHover.svg' : null)}
                onMouseOut={e => (device === 'desktop' ? e.currentTarget.src=props.imagePath + 'scrollButton.svg' : null)}
                src={props.imagePath + 'scrollButton.svg'}
                style={{visibility: visible ? 'visible' : 'hidden'}}
            />
        </React.Fragment>
    );
};

ScrollButton.propTypes = {
    analytics: PropTypes.func,
    imagePath: PropTypes.string,
};

export default ScrollButton;
