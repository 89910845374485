import { GlobalAnalytics } from "../../../analytics";

const analytics = new GlobalAnalytics();

// Page Load
export const bzOverviewPageload = (pageLoad) => {
  pageLoad &&
    analytics.firePageLoad({
      section: 'Product',
      subsection: 'Featured Technology',
      technologyName: 'Beyond Zero',
      page: pageLoad.page,
    });
};

// tabs click
export const bzOverviewTabsClick = (clickItem) => {
  clickItem &&
    analytics.fireOnClick({
      tag: '29.2',
      section: 'Product',
      subsection: 'Featured Technology',
      page: 'Beyond Zero',
      category: 'Overview',
      label: clickItem.label,
      contentTitle: clickItem.content_title
    });
};

// general click
export const bzOverviewClick = (clickItem) => {
  clickItem &&
    analytics.fireOnClick({
      tag: '29.2',
      section: 'Product',
      page: 'Beyond Zero',
      category: 'Overview',
      label: clickItem.label,
      contentTitle: clickItem.content_title
    });
};