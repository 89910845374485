import * as _ from 'lodash';
import React, { Component } from 'react';
import VehicleSelectionTemplate from './vehicleSelection.tpl';

export default class VehicleSelection extends Component {
  constructor(props) {
    super(props);
    let engines = [];
    const selection = {};
    let yearTrims = [];
    let trimDropdownIndex = props.index || 0;
    let trimOptions = [];
    let curYearTrims = [];
    let modelYearOptions = props.modelYears;

    selection.year = VehicleSelection.getYear(props);
    if (props.trims) {
      yearTrims = props.trims[selection.year];
      curYearTrims = !this.props.lowerTier && this.props.trimWalkTier ? yearTrims.filter(trim => !trim.trimWalkTier || Number(trim.trimWalkTier) >= this.props.trimWalkTier) : yearTrims;
      const selectedTrimIndex = props.selectedTrimIndex ? props.selectedTrimIndex : props.indexOf;
      selection.trim = this.getTrimByIndex(selectedTrimIndex, curYearTrims);
      selection.engine = selection.trim.engine;
      engines = this.getEngines(curYearTrims);
      trimDropdownIndex = this.getIndexForTrimDropdown(curYearTrims, selection.engine, selection.trim.fullName);
      trimOptions = this.getTrimOptions(curYearTrims, selection.engine)

      modelYearOptions = _.keys(props.trims).sort((a, b) => b - a); // Remove years that have no trims from the dropdown options
    }
    this.state = {
      modelYearOptions: modelYearOptions || [],
      engineOptions: engines,
      trims: curYearTrims,
      trimSelection: trimDropdownIndex || 0, // Need to hold this separately so that the dropdown will update
      selection,
      trimOptions,
    };
    if (selection.year && selection.trim && selection.engine) {
      this.props.onSelectionChange(selection);
    }
  }

  componentWillReceiveProps(nextProp) {
    if (JSON.stringify(nextProp) !== JSON.stringify(this.props)) {
      let trimValueForDropdown = '';
      const selection = this.state.selection || {}; // The first time this function is called, this.state.selection is empty
      if (nextProp.useCurrentYear) {
        selection.year = +nextProp.year;
      } else if ((!this.state.selection || !this.state.selection.year) && nextProp.modelYears) {
        selection.year = nextProp.modelYears[0];
      }
      let engines = this.state.engineOptions;
      let yearTrims = this.state.trims;

      // If selection & trim aren't set, set that here
      if ((!this.state.selection || !this.state.selection.trim) && nextProp.trims) {
        yearTrims = nextProp.trims[selection.year];
        if (this.props.trim && this.props.engine && !this.state.selection.trim) {
          // Using the trim from the URL, find the corresponding index in trims only for this engine
          const availableTrimsForEngine = yearTrims.filter(t => t.engine === this.props.engine);
          for (let i = 0; i < availableTrimsForEngine.length; i++) {
            if (availableTrimsForEngine[i].fullName === this.props.trim) {
              selection.trim = availableTrimsForEngine[i];
              trimValueForDropdown = i;
              break;
            }
          }
        } else if (nextProp.index < yearTrims.length) {
          selection.trim = yearTrims[nextProp.index];
        } else {
          selection.trim = yearTrims[0];
        }
        engines = yearTrims.map(t => t.engine).filter((val, index, self) => self.indexOf(val) === index);
      }
      if ((!this.state.selection || !this.state.selection.engine) && nextProp.trims) {
        if (this.props.engine) {
          selection.engine = this.props.engine;
        } else {
          selection.engine = yearTrims.map(t => t.engine)[0];
        }
      }
      this.setState({
        modelYearOptions: nextProp.modelYears,
        engineOptions: engines,
        trimSelection: trimValueForDropdown,
        selection,
        trims: yearTrims,
      });
    }
  }

  static getYear(props) {
    const year = (props.useCurrentYear || props.trimWalk) ? +props.year : props.modelYears[0];
    return year;
  }

  getTrimOptions = (yearTrims, selectedEngine) => {
    let trimOptions = [];
    const filteredTrims = yearTrims.filter(trim => trim.engine === selectedEngine);
    trimOptions = filteredTrims.map((trim, index) => <option key={index} value={index}>{trim.fullName}</option>);
    return trimOptions;
  }

  getTrimByIndex = (index, yearTrims) => {
    let trim;
    if (index < yearTrims.length) {
      trim = yearTrims[index];
    } else {
      trim = yearTrims[0];
    }
    return trim;
  }

  getEngines = (yearTrims) => {
    return yearTrims.map(t => t.engine).filter((val, index, self) => self.indexOf(val) === index);
  }

  getIndexForTrimDropdown(trims, selectedEngine, selectedTrim) {
    let index;
    const availableTrimsForEngine = trims.filter(t => t.engine === selectedEngine);
    for (let i = 0; i < availableTrimsForEngine.length; i++) {
      if (availableTrimsForEngine[i].fullName === selectedTrim) {
        index = i;
      }
    }
    return index;
  }


  componentDidUpdate(prevProps, prevState) {
    // Comparison has to be done with JSON because using === with two objects checks to see if they are the same reference, not if they
    // have the same contents
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props) || JSON.stringify(this.state) !== JSON.stringify(prevState)) {
      this.props.onSelectionChange(this.state.selection);
    }
    if (prevProps.trimWalkTier !== this.props.trimWalkTier || prevProps.selectedTrimIndex !== this.props.selectedTrimIndex) {
      let engines = [];
      const selection = {};
      let yearTrims = [];
      let trimDropdownIndex = this.props.index || 0;
      let trimOptions = [];
      let curYearTrims = [];

      selection.year = VehicleSelection.getYear(this.props);
      if (this.props.trims) {
        yearTrims = this.props.trims[selection.year];
        curYearTrims = !this.props.lowerTier && this.props.trimWalkTier ? yearTrims.filter(trim => !trim.trimWalkTier || Number(trim.trimWalkTier) >= this.props.trimWalkTier) : yearTrims;
        console.log(curYearTrims)
        const selectedTrimIndex = this.props.selectedTrimIndex ? this.props.selectedTrimIndex : props.indexOf;
        selection.trim = this.getTrimByIndex(selectedTrimIndex, curYearTrims);
        selection.engine = selection.trim.engine;
        engines = this.getEngines(curYearTrims);
        trimDropdownIndex = selectedTrimIndex;
        trimOptions = this.getTrimOptions(curYearTrims, selection.engine)
      }
      this.setState({
        modelYearOptions: this.props.modelYears || [],
        engineOptions: engines,
        trims: curYearTrims,
        trimSelection: trimDropdownIndex || 0, // Need to hold this separately so that the dropdown will update
        selection,
        trimOptions,
      });
    }
  }

  updateModelYear(event) {
    const year = +event.target.value;
    const trims = this.props.trims[year];
    const engines = trims.map(t => t.engine).filter((val, index, self) => self.indexOf(val) === index);
    const newSelection = {
      year,
      trim: trims[0],
      engine: trims[0].engine,
    };
    this.setState({
      selection: newSelection,
      trims,
      trimOptions: this.getTrimOptions(trims, newSelection.engine),
      engineOptions: engines,
      trimSelection: 0,
    });
    this.props.onSelectionChange(newSelection);
    if (this.props.analyticsOnChange) {
      newSelection.selectName = 'vehicleYear';
      this.props.analyticsOnChange(newSelection);
    }
  }

  updateEngineSelection(event) {
    const newSelection = this.state.selection;
    newSelection.engine = event.target.value;
    // Select the default trim for this engine
    const engineTrims = this.state.trims.filter(t => t.engine === newSelection.engine);
    newSelection.trim = engineTrims[0];
    this.props.onSelectionChange(newSelection);
    this.setState({
      trimOptions: this.getTrimOptions(this.state.trims, newSelection.engine)
    })
    if (this.props.analyticsOnChange) {
      newSelection.selectName = 'engine';
      this.props.analyticsOnChange(newSelection);
    }
  }

  updateTrimSelection(event) {
    const newSelection = this.state.selection;
    const currentTrims = this.state.trims.filter(t => t.engine === newSelection.engine); // make sure we are only looking at trims for current engine
    const selectedTrim = currentTrims[event.target.value];
    newSelection.trim = selectedTrim;
    this.setState({
      selection: newSelection,
      trimSelection: event.target.value,
    });
    this.props.onSelectionChange(newSelection);
    if (this.props.analyticsOnChange) {
      newSelection.selectName = 'trim';
      this.props.analyticsOnChange(newSelection);
    }
  }

  render() {
    return VehicleSelectionTemplate(this);
  }
}
