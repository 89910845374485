import React from 'react';
import PropTypes from 'prop-types';
import TitleText from '../../../components/atoms/TitleText';
import applyMarkdown from '../../utils/applyMarkdown';


const certificationAnnouncements = (props) => {
  const Announcement = (announcement) => (
    <div key={announcement.description} className="announcement">
      <div className="announcement-date">
        {announcement.date}
      </div>
      <div className="vertical-divider"></div>
      <div className="announcement-description">
        {announcement.description}
      </div>
    </div>
  );

  return (
    <div>
      <div className="system-status-announcements">
        <TitleText className="announcement-title">{applyMarkdown(props.data.announcements.headline)}</TitleText>
        {props.data.announcements.content.map(announcement => Announcement(announcement))}
      </div>
    </div>
  );
};

certificationAnnouncements.propTypes = {
  data: PropTypes.object,
};

export default certificationAnnouncements;
