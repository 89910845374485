import React, { useEffect, useState, useContext, useMemo } from 'react';
import CommentCard from './CommentCard';
import { FormattedMessage } from 'react-intl';
import { myAccountHideRepliesClick, myAccountViewRepliesClick } from './commentCard.analytics';
import { NotificationContext } from '../../components/contexts/notificationContext';
import { isDesktopView, isTabletView, isMobileView } from '../../screens/utils/getScreenSize';

import '../../styles/css/activityCommentThread.css';
import CommentTextArea from './CommentTextArea';

function CommentThread(props) {
    const [repliesVisible, setRepliesVisible] = useState();
    const [unreadCount, setUnreadCount] = useState(0);
    const [device, setDevice] = useState('desktop');
    
    const { notificationCount, setNotificationCount } = useContext(NotificationContext);
    const isAdmin = localStorage.isAdmin ? localStorage.isAdmin : false;

    useEffect(() => {
      updateDevice();
      window.addEventListener("resize", updateDevice);
      return () =>
        window.removeEventListener("resize", updateDevice);
    }, []);

  const updateDevice = () => {
    if (isDesktopView(window.innerWidth)) {
      setDevice('desktop');
    } else if (isTabletView(window.innerWidth)) {
      setDevice('tablet');
    } else if (isMobileView(window.innerWidth)) {
      setDevice('mobile');
    }
  }
      
    function viewReplies(articleTitle) {
        myAccountViewRepliesClick(props.analyticsData?.clickAnalytics, articleTitle)
        setRepliesVisible(true);
        if(unreadCount > 0 && props.markRepliesRead) {
            //update state counts
            setNotificationCount({ ...notificationCount, unreadReplies: notificationCount.unreadReplies - unreadCount });
            setUnreadCount(0);

            //mark replies read and refresh data from dynamo
            props.markRepliesRead();
        }
    };

    const hideReplies = (articleTitle) => {
        myAccountHideRepliesClick(props.analyticsData?.clickAnalytics, articleTitle)
        setRepliesVisible(false);
    }

    const shouldDisplayDeleteButton = (admin, comment) => {
      if ((comment.activeReply || comment.activePromote) || comment.activeUnpromote) {
        return false;
      }
      if (admin) {
        return true;
      } if (localStorage.spin_id) {
        if (comment.userId === localStorage.spin_id || comment.userId === localStorage.name_id || comment.userId === localStorage.legacyProfileID) {
          return true;
        }
      } else {
        return false;
      }
    };

    const shouldDisplayReplyButton = (admin, comment) => {
      if ((comment.activeDelete || comment.activePromote) || comment.activeUnpromote) {
        return false;
      }
  
      return admin;
    };

    const shouldDisplayPromoteButton = (admin, comment) => {
      if (comment.activeReply || comment.activeDelete) {
          return false;
      }
      if (comment.isPromoted === true) {
          return false;
      }
      return admin;
    };

    const shouldDisplayShowHideReplyButton = (comment) => {
      if ((comment.activeReply || comment.activePromote|| comment.activeDelete) || comment.activeUnpromote) {
        return false;
      }
      
      return true;
    };

    const shouldRenderReplyComponent = (admin, comment) => {
      return shouldDisplayReplyButton(admin, comment) && comment.activeReply;
    }

    const renderAdminReply = (admin, comment) => {
        if (shouldDisplayReplyButton(admin, comment)) {
          if (comment.activeReply) {
            return (
              <CommentTextArea 
                value={props.component.state.replyTextBoxInput}
                onSubmit={() => {
                  props.component.submitReplyClick(comment)
                }} 
                onChange={(evt) => props.component.updateReplyTextBoxInputValue(evt.target.value)}
                onCancel={() => {
                  props.component.addReplyClick(comment)
                }}
                //hasError={}
                component={props.component} 
              />
            );
          }
          return (
            <div className='view-button text-upper' onClick={props.component.addReplyClick.bind(props.component, comment)}>
                <FormattedMessage id="global.button.reply" />
            </div>
          );
        }
    };

    const renderDeleteComment = (admin, comment) => {
        if (shouldDisplayDeleteButton(admin, comment)) {
          if (comment.activeDelete) {
            return (
              <div className="active-comment-button-container">
                <div className="active-button-prompt"><FormattedMessage id="article.deleteComment" /></div>
                <div className="active-button-options">
                <div className='view-button text-upper' onClick={props.deleteCommentClick.bind(props.component, comment)}><FormattedMessage id="global.button.delete" /></div>
                <div className='view-button text-upper' onClick={props.activeDeleteClick.bind(props.component, comment)}><FormattedMessage id="global.button.cancel" /></div>
                </div>
              </div>
            );
          }
          return (
            <div className='view-button text-upper' onClick={props.activeDeleteClick.bind(props.component, comment)}>
                <FormattedMessage id="global.button.delete" />
            </div>
          );
        }
    };

    const renderShowHideReplyButtons = (comment) => {
      if (shouldDisplayShowHideReplyButton(comment)) {
        return <div>
            {!repliesVisible && props.comment.reply &&
              <div className='view-button' onClick={() => viewReplies(props.comment.articleTitle)}>
                  VIEW REPLIES
                  {unreadCount > 0 && <div className='unread-badge'>{unreadCount}</div>}
              </div>
            }

            {repliesVisible && props.comment.reply &&
              <div className='hide-button' onClick={() => hideReplies(props.comment.articleTitle)}>
                  HIDE REPLIES
              </div>
            }
        </div>
      } else {
        return <div></div>
      }
    }

    const renderPromoteComment = (admin, comment) => {
        if (shouldDisplayPromoteButton(admin, comment)) {
          if (comment.activePromote) {
            return (
              <div className="active-comment-button-container">
                <div className="active-button-prompt"><FormattedMessage id="article.promoteComment" /></div>
                <div className="active-button-options">
                    <div className='view-button text-upper' onClick={props.component.promoteCommentClick.bind(props.component, comment)}><FormattedMessage id="article.promote" /></div>
                    <div className='view-button text-upper' onClick={props.component.activePromoteClick.bind(props.component, comment)}><FormattedMessage id="global.button.cancel" /></div>
                </div>
              </div>
            );
          }
          return (
            <div className='view-button text-upper' onClick={props.component.activePromoteClick.bind(props.component, comment)}>
                <FormattedMessage id="article.promote" />
            </div>
          );
        }
      };
    
    useEffect(() => {
        // update unreadCount when props.comment updates in useEffect
        setUnreadCount(props.comment.reply?.filter(reply => reply.isUnread).length)
    }, [props.comment]);

    return (
        <div className='comment-thread'>                      
            <CommentCard comment={props.comment} device={device} key={props.comment.createdTimestamp} analyticsData={props.analyticsData}/>
            
            <div className='buttons-container'>
                {renderShowHideReplyButtons(props.comment)}
                
                {props.showAdminButtons && <div className={!shouldRenderReplyComponent(isAdmin, props.comment) ? 'buttons-container-right' : 'reply-container'}>
                    {renderAdminReply(isAdmin, props.comment)}
                    {renderDeleteComment(isAdmin, props.comment)}
                    {renderPromoteComment(isAdmin, props.comment)}
                </div>}
            </div>

            {repliesVisible && props.comment.reply && props.comment.reply.map(reply => 
                <CommentCard
                    comment={reply}
                    isReply={true}
                    device={device}
                    key={reply.createdTimestamp}
                    analyticsData={props.analyticsData}
                    positionTitle={props.comment.articleTitle}
                />
            )}
        </div>
    );
}

export default CommentThread;