import 'whatwg-fetch';
import httpOption from './httpOption';
import AppSettings from '../appsettings';

export async function getFeatures() {
    const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/featureLookup/features`;
    const options = httpOption.GET();

    return fetch(url, options)
        .then((response) => {
            if (response.status >= 200 && response.status < 300) {
                return Promise.resolve(response.json());
            }
            return Promise.reject(response);
        });
}
