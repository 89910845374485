import React from 'react';
import '../../styles/css/roundedButton.css'

/*
required props:
    title
        string
    onClick
        function
optional props:
    className
        string
        use to apply custom component styles with .roundedButton.<className> {...}
    width (can also override in custom component style)
        defaults:
            small: 145px
            medium: 210px
            large: 190px
    height (can also override in custom component style)
        default 32px
    active
        bool
        use to keep button active after click/state change
    disabled
        bool
        uses class to set "disabled" color, add "not-allowed" cursor, and block triggering onClick function
    colorStyle
        string
        options: 
            "default" (white inactive, black active, grey disabled)
            "red" (red inactive, dark red active, light red disabled)
            "grey-red" (grey inactive, red active, ligh grey disabled)
        default "default" if no colorStyle or color/activeColor passed
        
    color
    activeColor (hover/active states)
        valid color code
        must pass color, activeColor, borderColor, and textColor to be applied
        otherwise uses "default" color style
*/

const RoundedButton = (props) => {
    let colorStyles = ["default", "red", "grey-red"];

    let colorStyleClass = () => {
        if (colorStyles.includes(props.colorStyle)) {
            return props.colorStyle;
        } else if (props.color && props.activeColor && props.borderColor && props.textColor) {
            return "customColor customActiveColor borderColor textColor";
        } else return "default";
    };

    return (
        <div
            className={`roundedButton ${props.className} ${colorStyleClass()} ${props.active && "active"} ${props.disabled && "disabled"}`}
            onClick={props.disabled ? undefined : props.onClick}
            style={{
                width: props.width,
                minWidth: props.width,
                height: props.height,
                "--custom-color": props.color,
                "--custom-active-color": props.activeColor,
                "--custom-border-color": props.borderColor,
                "--custom-text-color":props.textColor,
            }}
            id={props.id}
        >
            <div className="pseudoTextWrapper">
                <div className="buttonText">{props.title}</div>
            </div>
        </div>
    );
};

export default RoundedButton;