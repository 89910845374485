import { GlobalAnalytics } from '../../../analytics';

const analytics = new GlobalAnalytics();

// use a general function
export function clickEvent(category, title) {
  analytics.fireOnClick({
    tag: '35.2',
    mediaTitle: ' ',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'Audio Multimedia',
    page: 'Registration',
    category,
    contentTitle: title,
  });
}

export function videoPlayClick(item) {
  analytics.fireOnClick({
    tag: '40.5',
    section: 'Entune',
    subsection: 'Entune 3.0',
    page: 'Registration',
    category: item.category,
    contentTitle: item.title,
    mediaTitle: item.title,
    modelName: ' ',
    module: 'video player',
    label: item.linkUrl,
    events: item.event,
  });
}

export function clickLink(event) {
  clickEvent(event.target.text, 'Show');
}

export function pageLoad() {
  analytics.firePageLoad({
    tag: '35.1',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'Audio Multimedia',
    page: 'Registration',
  });
}
