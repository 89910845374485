import { Fragment, useContext, useEffect, useState } from "react";
import { NotificationContext } from "../../../components/contexts/notificationContext";
import CommentCard from "../../../components/molecules/cards/commentCard";
import { markRepliesRead } from "../../../services/commentsService";
import "../../../styles/scss/profilePage/profile-page-recent-comments.scss";
import { getProfileImageUrl } from "../../utils/profileImageUrl";
import { isDesktopView } from "../../utils/getScreenSize";
import { replyCardClick, repliesToggleClick } from "./recentActivities.analytics";
import Skeleton from "react-loading-skeleton";

const RecentComments = (props) => {
  const { notificationCount, getNotificationCount } = useContext(NotificationContext);
  const [comments, setComments] = useState([]);
  const [pictureUrl, setPictureUrl] = useState(null);
  const [showReplies, setShowReplies] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      const imageUrl = await getProfileImageUrl();
      setPictureUrl(imageUrl);
    };
    fetchImages();
  }, []);

  const loadComments = async () => {
    const commentsList = props?.topComments?.map((comment) => {
      const article = props?.articlesTitlesAndIds?.find(article => article.id+"_en" === comment.articleId);
      return {
        ...comment,
        articleId: comment.articleId,
        trimmedArticleId: article.id,
        articleTitle: article.title,
      };
    })
    const newShowReplies = [];
    commentsList?.forEach((comment, index) => {
      if (comment.reply) {
        newShowReplies[index] = true;
      }
    })
    setShowReplies(newShowReplies);
    setComments(commentsList);
  }

  useEffect(() => {
    loadComments();
  },[props.topComments, props.articlesTitlesAndIds])

  const markRepliesReadRefreshComments = async (comment) => {
    await markRepliesRead(comment);
    loadComments();
    getNotificationCount();
    replyCardClick(props?.analyticsData?.clickAnalytics);
  }

  return (
    <div className="profile-page-recent-comments" style={{  }}>
      {isDesktopView(window.innerWidth) &&
        <span className="title">Most Recent Comments
          {notificationCount.unreadReplies > 0 &&
            <span className='unread-count'>
              ({notificationCount.unreadReplies} Unread Repl{notificationCount.unreadReplies == 1 ? 'y' : 'ies'})
            </span>
          }
        </span>
      }
      {!props?.commentsLoading ?
        comments.length > 0 ?
          comments?.map((comment, index) => comment && (
            <Fragment key={comment.createdTimestamp}>
              <CommentCard
                picture={comment.userId === localStorage.getItem('spin_id') ? `data:image;base64, ${pictureUrl}` : comment.userImage}
                name={`${comment.userFirstName} ${comment.userLastName}`}
                date={new Date(comment.createdDate)}
                comment={comment.comment}
                title={comment.articleTitle}
                trimmedArticleId={comment.trimmedArticleId}
                analyticsData={props.analyticsData}
              />
              {showReplies[index] && comment.reply?.map(reply => (
                <CommentCard
                  elementKey={reply.createdTimestamp}
                  picture={reply.userId === localStorage.getItem('spin_id') ? `data:image;base64, ${pictureUrl}` : reply.userImage}
                  name={`${reply.userFirstName} ${reply.userLastName}`}
                  date={new Date(reply.createdDate)}
                  comment={reply.comment}
                  title={comment.articleTitle}
                  trimmedArticleId={comment.trimmedArticleId}
                  reply
                  isUnread={reply.isUnread}
                  onClick={() => markRepliesReadRefreshComments(comment)}
                  analyticsData={props.analyticsData}
                />
              ))}
              {comment.reply?.length > 0 && <button className="show-hide-replies" onClick={() => {
                const newShowReplies = [...showReplies];
                newShowReplies[index] = !newShowReplies[index];
                setShowReplies(newShowReplies);
                repliesToggleClick(props?.analyticsData?.clickAnalytics, showReplies[index] ? "Hide Replies":"Show Replies");
              }}>{showReplies[index] ? "Hide Replies":"Show Replies"}</button>}
            </Fragment>
          ))
        :
         <div className="no-results">
          No Comments Found
         </div>
      :
        <div className="comments-skeleton-loading">
          <Skeleton count={3} height={178} />
        </div>
      }
    </div>
  )
}

export default RecentComments;