import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { SPTestimonialsTemplate } from './spTestimonials.tpl';
import { pageLoad, filterLinksClick, sendVideoEventAnalytics } from './smartpath-analytics.js';
import { postUserActivityRecord } from '../../services/userActivityService.js';

export class SPTestimonialsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeFilter: 'All',
      languageOverride: this.props.languageOverride,
      testimonials: this.props.testimonials,
    };

    this.filterMenuClick = this.filterMenuClick.bind(this);
    this.sendFilterAnalytics = filterLinksClick.bind(this);
    this.sendPageLoadAnalytics = pageLoad.bind(this);
    this.sendVideoEventAnalytics = this.sendVideoEventAnalytics.bind(this);
  }

  sendVideoEventAnalytics(videoObject) {
    sendVideoEventAnalytics('Video Click', videoObject.videoTitle, videoObject.videoId, 'Play Click', 'Testimonials', videoObject.category);
  }

  filterMenuClick(event, filter) {
    const testimonials = this.mapFilterClickToFeatureType(filter.id);

    this.setState({
      activeFilter: filter.id,
      testimonials,
    });

    this.sendFilterAnalytics(event);
  }

  mapFilterClickToFeatureType(filter) {
    let features;

    switch (filter) {
      case 'Dealer':
        features = this.filterFeaturesByType('Dealer');
        break;
      case 'Consumer':
        features = this.filterFeaturesByType('Consumer');
        break;
      case 'All':
      default:
        features = this.props.testimonials;
        break;
    }
    return features;
  }

  filterFeaturesByType(type) {
    const features = this.props.testimonials;
    const filteredFeatures = [];
    for (let i = 0; i < features.length; i++) {
      if (features[i].type === type) {
        filteredFeatures.push(features[i]);
      }
    }
    return filteredFeatures;
  }

  componentDidMount() {
    this.setState({ languageOverride: this.props.languageOverride });
    this.sendPageLoadAnalytics('Testimonials');
    postUserActivityRecord();
  }

  render() {
    return SPTestimonialsTemplate(this);
  }
}

export default injectIntl(SPTestimonialsComponent);
