import * as _ from 'lodash';
import React from 'react';
import '../../../styles/css/homepage/datesCarousel.css';
import getTarget from '../../utils/getTarget';

const DatesCarouselItem = (props) => {

    const getConvertedDate = () => {
        const date = new Date(Date.parse(props.monthDay));
        const splitDate = date.toDateString().split(' ');

        const dateObject = {
            month: splitDate[1],
            day: splitDate[2]
        }

        return dateObject;
    }

    return (
        <div className='datesCarouselItem'>
            <div className='datesCarouselDateSection'>
                <div className='dateSectionDay'>{getConvertedDate().day}</div>
                <div className='dateSectionMonth'>{getConvertedDate().month}</div>
            </div>
            <div className='datesCarouselDescriptionSection'>
                <div className={`datesCarouselDescription ${props.ctaText ? 'notifyMeIncluded' : 'notifyMeNotIncluded'}`}>
                    {props.description}
                </div>
                {props.ctaText ? <div className='datesCarouselNotifyMe'>
                    <a href={props.ctaLink} target={getTarget(props.ctaLink)} >{props.ctaText}</a>
                </div> : <></>}
            </div>
        </div>
    );
}

export default DatesCarouselItem;