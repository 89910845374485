import React from 'react';
import VideoPlayerComponent from '../../videoPlayer/videoPlayer.component';

export default function overviewVideoPlayer(videoId, qumuId, videoTitle) {
  const videoItem = {
    alias: videoId,
    qumuId,
    videoTitle: videoTitle || 'Overview Video',
  };
  return (
    <div className="title-section-video">
      <VideoPlayerComponent
      item={videoItem}
        sendVideoEventAnalytics={null}
      />
    </div>
  );
}
