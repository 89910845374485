import React, { useEffect, useState, useContext } from 'react';
import { getUserData } from '../../services/authenticationService';
import { UserManagerContext } from '../contexts/userManagerContext';

const Callback = () => {
  const [message, setMessage] = useState('Completing sign-in...');
  const { userManager } = useContext(UserManagerContext);

  useEffect(() => {
    const completeSignIn = async () => {
      if (!userManager) {
        return;
      }
      try {
        await userManager.signinRedirectCallback();
        await getUserData(userManager);

        window.location.replace('/');
      } catch (error) {
        setMessage(`Login error. Please try again. ${error}`);
        localStorage.clear();
        sessionStorage.clear();
      }
    };

    completeSignIn();
  }, [userManager]);

  return <div>{message}</div>;
};

export default Callback;
