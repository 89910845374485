import AppSettings from '../../appsettings';

export const renderRewardText = (rewards) => {
  const combinedRewards = rewards.map((reward) => {
    if (reward?.type === "point" && !reward?.outOfStock) {
      let rewardText = `${reward?.pointAward} ${reward?.pointCategory?.name}`;
      if (reward.pointAward === 1 && reward.pointCategory?.name.endsWith("s")) {
        rewardText = `${reward.pointAward} ${reward.pointCategory.name.slice(0,-1)}`;
      }
      if (reward.limit && Object.keys(reward.limit).length > 0) {
        rewardText += " (Limited Availability)";
      }
      return rewardText;
    } else if (reward?.type === "badge") {
      return "1 Badge";
    }
    return null;
  }).filter(Boolean);

  if (combinedRewards.length > 0) {
    return combinedRewards.join(", ");
  }

  return null;
};

export const renderTournamentRewards = (rewards, proPoints) => {
  return (
    <>
      <div className='tournament-eligible-pro-points'>
        {`${proPoints} Pro Points`}
      </div>
      <div className='tournament-eligible-xp-points'> 
        {`+ ${renderRewardText(rewards)}`}
      </div>
    </>
  );
};

export const getRelativeTime = (d1, d2 = new Date()) => {
  var rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

  var units = {
    year  : 24 * 60 * 60 * 1000 * 365,
    month : 24 * 60 * 60 * 1000 * 365/12,
    day   : 24 * 60 * 60 * 1000,
    hour  : 60 * 60 * 1000,
    minute: 60 * 1000,
    second: 1000
  };

  var elapsed = d1 - d2;

  // "Math.abs" accounts for both "past" & "future" scenarios
  for (var u in units) 
    if (Math.abs(elapsed) > units[u] || u == 'second') 
      return rtf.format(Math.round(elapsed/units[u]), u);
};

export const getMissionImage = (mission) => {
  const placeholderImageUrl = AppSettings.AWSImgRepo.engageXP + 'EngageXP_Missions_Placeholder.png';
  const dateCutoff = new Date();
  dateCutoff.setFullYear(2023, 10, 12); // November 12, 2023

  if (mission.startTime * 1000 > dateCutoff.getTime()) {
    return `url(${mission.iconUrl || placeholderImageUrl})`;
  } else {
    return `url(${placeholderImageUrl})`;
  }
};

// This function will return an array of numDivisions RGB color values that are equally spaced 
// between startingColor and endingColor inclusive. This function uses RGB color values instead 
// of our normal HEX to simplify the calculations.
export const colorHelper = (startingColor, endingColor, numDivisions) => {

  // Helper to break RGB color strings down into their individual R, G, and B values
  const parseRGBValues = (rgbString) => {
      return rgbString.substring(4, rgbString.length-1)
      .replace(/ /g, '')
      .split(',')
      .map(x => parseInt(x));
  };

  const startingRGBValues = parseRGBValues(startingColor);
  const endingRGBValues = parseRGBValues(endingColor);

  const stepValues = startingRGBValues.map((startingValue, index) => {
      return (startingValue - endingRGBValues[index]) / (numDivisions - 1)
  });

  let gradientValues = [startingRGBValues];
  for (let i = 0; i < numDivisions - 2; i++) {
      gradientValues.push(
          [
              gradientValues[i][0] - stepValues[0],
              gradientValues[i][1] - stepValues[1],
              gradientValues[i][2] - stepValues[2],
          ]
      )
  }
  gradientValues.push(endingRGBValues);

  const finalRGBs = gradientValues.map(colorArray => {
      return `rgb(${Math.round(colorArray[0])}, ${Math.round(colorArray[1])}, ${Math.round(colorArray[2])})`
  });

  return finalRGBs;
}

export const getRoundStatus = (round) => {
  const currentDate = new Date();
  const startDate = new Date(round.startDate);
  const endDate = new Date(round.endDate);

  if (currentDate < startDate) {
      return "locked";
  } else if (currentDate >= startDate && currentDate <= endDate) {
      return "open";
  } else {
      return "closed";
  }
};

export const getCurrentRound = (rounds) => {
  try {
    const currentDate = new Date();

    const currentRound = rounds.find(round => {
      const startDate = new Date(round.startDate);
      const endDate = new Date(round.endDate);
      return startDate <= currentDate && currentDate <= endDate;
    });
  
    return currentRound;
  } catch (error) {
    return false;
  }
};

export const checkIfTournamentIsActive = (tournamentData) => {
  try {
    const currentDate = new Date();
    const startDate = new Date(tournamentData.startDate);
    const endDate = new Date(tournamentData.endDate);
  
    return startDate <= currentDate && currentDate <= endDate;
  } catch (error) {
    return false;
  }
};

export const extractTournamentMissionIds = (tournamentData) => {
  const tournamentMissionIds = new Set();
  try {
    tournamentData.tournamentSection.rounds.forEach(round => {
      round.missions.forEach(mission => {
        tournamentMissionIds.add(mission.id);
      });
    });
    return tournamentMissionIds;
  } catch (error) {
    console.error(error);
    return new Set();
  }
};

export const getTournamentPointsForMission = (tournamentData, missionId) => {
  try {
    for (const round of tournamentData.tournamentSection.rounds) {
      for (const mission of round.missions) {
        if (mission.id == missionId) {
          return mission.tournamentPoints;
        }
      }
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const checkIfUserIsEligible = (tournamentsData, missionScores) => {
  try {
    const rounds = tournamentsData.tournamentSection.rounds;
    for (const round of rounds) {
      const roundStatus = getRoundStatus(round);
      if (roundStatus === "closed") {
        const totalPointsEarned = calculateTotalPointsEarned(round.missions, missionScores);
        if (totalPointsEarned < round.pointsRequiredToMoveOn) {
          return false;
        }
      }
    }
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const calculateTotalPointsEarned = (missions, missionScores) => {
  let totalPointsEarned = 0;
  for (const mission of missions) {
    const missionId = mission.id;
    if (missionId in missionScores) {
      totalPointsEarned += missionScores[missionId];
    }
  }
  return totalPointsEarned;
};
