import React from 'react';
import '../../styles/css/features-page.css';
import '../../styles/css/smartpath-legacy.css';
import { FormattedMessage } from 'react-intl';
import ThumbnailMediaText from '../mediaText/thumbnailMediaText.component';
// import { FilterMenu } from '../../filterMenu/filterMenu.component';

export function SPTestimonialsTemplate(component) {
  return (
        <div className="features-page smartpath-body">
            {component.state.languageOverride
              ? <div className="language-unavailable" >
                    <FormattedMessage id="smartPath.languageOverride" default="Language is unavailable" />
                </div>
              : null
            }
            <div className="title">
                <div><FormattedMessage id="smartPath.about.testimonials" defaultMessage="Testimonials" /></div>
                <div className="print-dividing-line" />
            </div>
            {/* hiding dealer/consumer filters per business request - April 2020 */}
            {/* <FilterMenu
                active={component.state.activeFilter}
                filters={[{ id: 'All', label: 'smartPath.filterMenu.all' }, { id: 'Dealer', label: 'smartPath.filterMenu.dealer' }, { id: 'Consumer', label: 'smartPath.filterMenu.consumer' }]}
                filterClick={component.filterMenuClick}
                displayArrow={true}
            /> */}
            <div>
                {component.state.testimonials ? <ThumbnailMediaText mediaTextItems={component.state.testimonials} imagePath={'/static/images/smartpath'} disclaimer={component.disclaimer} sendVideoEventAnalytics={component.sendVideoEventAnalytics} /> : null}
            </div>
        </div>
  );
}
