import React from 'react';
import VehicleSpec from './vehicleSpec.component';
import DisclaimersComponent from '../../disclaimers/disclaimers.component';

export function SpecDataTemplate(component) {
  const { spec } = component.props;
  let vehicleSpecs = null;
  const specTitle = (spec && spec.specName) ? spec.specName : null;
    const dataTitle = <div className="spec-data-title">{specTitle === 'MSRP' ? <span> Base MSRP <sup className="disclaimer-link" onClick={() => component.disclaimer.onOpenModal()}>&dagger;</sup></span>: specTitle}</div>;


  if (spec && spec.specData && spec.specData.length) {
    vehicleSpecs = spec.specData.map((vehicleSpec, index) => <VehicleSpec key={index} index={index} totalVehicles={spec.specData.length} vehicleSpec={vehicleSpec} advantage={component.hasAdvantage(index)} best={component.isBest(index)} />);
  } else {
    vehicleSpecs = 'No Spec Data';
  }

  return (
    <div className="spec-data">
      {dataTitle}
      <div>{vehicleSpecs}</div>
      <DisclaimersComponent
        ref={(instance) => {
          component.disclaimer = instance;
        }}
        addBaseMSRPDisclaimer={true}
      />
    </div>
  );
}
