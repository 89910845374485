/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import { compiler } from 'markdown-to-jsx';
import AppSettings from '../../appsettings';

function openModal(event, dc, num) {
  event.preventDefault();
  dc.onOpenModal(num);
}

function traverse(obj, dc, indexOfDisclaimerSetWhenMultipleDisclaimers, applyTooltip, tooltipData) {
  for (const child in obj.props.children) {
    if (!!obj.props.children[child] && typeof (obj.props.children[child]) === 'object' && (obj.props.children[child].type !== 'sup' && obj.props.children[child].type !== 'tooltip')) {
      traverse(obj.props.children[child], dc);
    } else if (!!obj.props.children[child] && typeof (obj.props.children[child]) === 'object' && obj.props.children[child].type === 'sup') {
      if (obj.props.children[child].props.children[0].match) {
        if (obj.props.children[child].props.children[0].match(/^\d+$/)) {
          const num = obj.props.children[child].props.children;
          // Features and specs needs to pass a callback here so that it can dynamically create disclaimers
          // All of the other places using disclaimers pass the actual disclaimers component because it is
          // statically created
          if (typeof (dc) === 'function') {
            if (indexOfDisclaimerSetWhenMultipleDisclaimers !== undefined && indexOfDisclaimerSetWhenMultipleDisclaimers !== null){
              obj.props.children[child] = <sup key={`${num}.${child}`} className="disclaimer-link" onClick={(event) => { dc(num, indexOfDisclaimerSetWhenMultipleDisclaimers); event.stopPropagation();}}>{num}</sup>;
            } else {
              obj.props.children[child] = <sup key={`${num}.${child}`} className="disclaimer-link" onClick={(event) => { dc(num); event.stopPropagation();}}>{num}</sup>;
            }
          } else {
            // this is the one i need to add to the bookmark div on the menu
            obj.props.children[child] = <sup key={`${num}.${child}`} className="disclaimer-link" onClick={(event) => { openModal(event, dc, num); event.stopPropagation();}}>{num}</sup>;
          }
        }
      }
    } else if (AppSettings.FeatureFlags.showGlossary && !!obj.props.children[child] && typeof (obj.props.children[child]) === 'object' && obj.props.children[child].type === 'tooltip' && !!obj.props.children[child].props.children) {
      // find and replace temporary child tooltip element with applyTooltip return
      const inlineTooltipText = obj.props.children[child].props.children[0];
      const surroundingInlineText = obj?.props?.children.map((child) => typeof child == 'string' ? child : '').join('');
      if (typeof (applyTooltip) === 'function' && tooltipData && typeof (inlineTooltipText) === 'string') {
        // find correct tooltip data for this inlinetooltiptext
        const specificTooltipData = tooltipData.find(data => data.term.toLowerCase() == inlineTooltipText.toLowerCase());
                obj.props.children[child] = specificTooltipData && applyTooltip(inlineTooltipText, specificTooltipData, surroundingInlineText)
      }
    } else if (obj.type === 'tooltip') {
      // Special case for a glossary word by itself
      const inlineTooltipText = obj.props.children[0];
      const surroundingInlineText = obj?.props?.children.map((child) => typeof child == 'string' ? child : '').join('');
      if (typeof (applyTooltip) === 'function' && tooltipData && typeof (inlineTooltipText) === 'string') {
        // find correct tooltip data for this inlinetooltiptext
        const specificTooltipData = tooltipData.find(data => data.term.toLowerCase() == inlineTooltipText.toLowerCase());
        obj.props.children[child] = specificTooltipData && applyTooltip(inlineTooltipText, specificTooltipData, surroundingInlineText)
      }
    }
  }
}

function applyMarkdown(originalText, dc, indexOfDisclaimerSetWhenMultipleDisclaimers, enableTextSpecificHyperLinks, applyTooltip, tooltipData) {
  // console.log(dc, indexOfDisclaimerSetWhenMultipleDisclaimers);
  let updatedTooltipData;

  if (tooltipData) {
    updatedTooltipData = tooltipData.map(function (data) {
      data.term = applySymbols(data.term);
      return data;
    });
  }

  if (originalText) {

    let text = String(originalText)
    .replace(/\\\\n/gm, "\n")
    .replace(/\\n/gm, "\n")
    .replace(/\\r/gm, "\n");

    text = text.replace(/\^(\d+)\^/g, '<sup>$1</sup>');
    text = text.replace(/\^\\(\*\d+)\^/g, '<sup>$1</sup>');
    text = text.replace(/\^\(?SM\)?\^/g, '℠');
    text = text.replace(/\^\(?TM\)?\^/g, '™');
    text = text.replace(/\^\(?R\)?\^/g, '®');
    text = enableTextSpecificHyperLinks ? embedTextSpecificHyperLinks(text) : text;
    const textJSX = compiler(text);
    dc && traverse(textJSX, dc, indexOfDisclaimerSetWhenMultipleDisclaimers, applyTooltip, updatedTooltipData);
    if (textJSX.type === 'ul') {
      return textJSX;
    }
    return <span>{textJSX.props.children}</span>;
  }
  return null;
}

function applySymbols(originalText) {
  if (!originalText) return null;

  let text = String(originalText);
  text = text.replace(/\^(\d+)\^/g, '');
  text = text.replace(/\^\\(\*\d+)\^/g, '');
  text = text.replace(/\^\(?SM\)?\^/g, '℠');
  text = text.replace(/\^\(?TM\)?\^/g, '™');
  text = text.replace(/\^\(?R\)?\^/g, '®');

  return text;
}

function embedTextSpecificHyperLinks(string) {
  // Need to know how many instances need replacing and which ones
  let updatedString;

  // Safety Page & Feature Tabs
  updatedString = string.replace('Star Safety System', "<a href='/starSafetySystem/overview'>Star Safety System</a>");
  updatedString = updatedString.replace('TSS-P', "<a href='/toyotaSafetySense/features?tab=TSS-P'>TSS-P</a>");
  updatedString = updatedString.replace('TSS-C', "<a href='/toyotaSafetySense/features?tab=TSS-C'>TSS-C</a>");
  updatedString = updatedString.replace('TSS 2.0', "<a href='/toyotaSafetySense/features?tab=TSS%202.0'>TSS 2.0</a>");
  updatedString = updatedString.replace('Toyota Safety Sense™ 2.0', "<a href='/toyotaSafetySense/features?tab=TSS%202.0'>Toyota Safety Sense™ 2.0</a>");

  const repeatedCases = [
    ["Wireless Android Auto™", "Android Auto™", "Android Auto", "Wireless Android Auto"],
    ["Wireless Apple CarPlay®", "Wireless Apple CarPlay", "Apple CarPlay®", "Apple CarPlay"],
    ["Connected Services (21MM)", "Connected Services"],
    ["Premium JBL® Audio", "JBL® Audio", "JBL Audio"],
    ["Scout® GPS Link Compatible", "Scout® GPS Link"],
  ];
  repeatedCases.forEach((phraseArray) => {
    const foundValue = phraseArray.find((phrase) =>
      updatedString.includes(phrase)
    );
    updatedString = updatedString.replace(
      foundValue,
      `<a href='/audioMultimedia/overview?target=${encodeURIComponent(
        foundValue
      )}'>${foundValue}</a>`
    );
  });

  // AMM Page Modal Direct Links
  const ammLinks = [
    // System Information
    'Toyota Audio Multimedia (21MM)',
    'Audio Multimedia (CY17+)',
    'Entune 3.0 (CY17)',
    'Entune (CY11)',
    'Supra Audio Multimedia',
    'GR86 Audio',
    'Other Platforms',
    // Feature Details
    'Toyota+Alexa App',
    'JBL® with Clari-Fi™',
    'SiriusXM',
    'App Suite Connect',
    'Toyota Supra Applications',
    'Toyota Supra Automatic Emergency Call',
    'Toyota Supra Concierge Services',
    'Toyota Supra Map Update',
    'Toyota Supra Real Time Traffic Information',
    'Toyota Supra Remote Maintenance',
    'Toyota Supra Remote Services',
    'Toyota Supra Roadside Assistance',
    'Toyota Supra Stolen Vehicle Recovery',
    'Toyota Supra Connect Services',
    'Toyota GR86 Safety Connect®',
    'Toyota GR86 Service Connect',
    'Toyota GR86 Remote Connect through the Toyota App',
    'Toyota Destination Assist',
    'Toyota Dynamic Navigation',
    'Toyota Wi-Fi Connect Powered by Verizon',
    'Toyota Insure Connect',
    'Toyota Drive Connect',
    'Toyota Safety Connect®',
    'Toyota Service Connect',
    'Toyota Remote Connect through the Toyota App',
    'Toyota Wi-Fi Connect Connected by AT&T']
  ammLinks.forEach(phrase => updatedString = updatedString.replace(phrase, `<a href='/audioMultimedia/overview?target=${encodeURIComponent(phrase)}'>${phrase}</a>`));

  // Common toyota.com links
  updatedString = updatedString.replace('toyota.com/audio-multimedia', "<a href='https://toyota.com/audio-multimedia' target='_blank'>toyota.com/audio-multimedia</a>");
  updatedString = updatedString.replace('toyota.com/connected-services', "<a href='https://toyota.com/connected-services' target='_blank'>toyota.com/connected-services</a>");

  return updatedString;
}

export default applyMarkdown;