import React from 'react';
import PropTypes from 'prop-types';

import '../../styles/css/redLine.css';

const RedLine = props => (
    <div className={`red-line-component ${props.className}`} style={{ ...props.style }}/>
);

export default RedLine;

RedLine.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
