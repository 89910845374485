import { GlobalAnalytics } from '../../../analytics';

const analytics = new GlobalAnalytics();

export const smartPathLoaded = (pageLoadAnalytics, filterCategory) => {
  analytics.firePageLoad({
    tag: pageLoadAnalytics.tagId,
    section: pageLoadAnalytics.section,
    subsection: pageLoadAnalytics.subsection,
    page: filterCategory,
  });
};

const contentLinkClick = (tag, section, subsection, technologyName, page, category, positionTitle, contentTitle) => {
  analytics.fireOnClick({
    tag,
    section,
    subsection,
    technologyName,
    page,
    category,
    positionTitle,
    contentTitle,
  });
};

export const advantagesSubLoad = (pageLoadAnalytics) => {
  pageLoadAnalytics && pageLoadAnalytics.advantagesSubLoad && contentLinkClick(
    pageLoadAnalytics.advantagesSubLoad.tagId,
    pageLoadAnalytics.advantagesSubLoad.section,
    pageLoadAnalytics.advantagesSubLoad.subsection,
    pageLoadAnalytics.advantagesSubLoad.technologyName,
    pageLoadAnalytics.advantagesSubLoad.page,
    pageLoadAnalytics.advantagesSubLoad.category,
    pageLoadAnalytics.advantagesSubLoad.positionTitle,
    pageLoadAnalytics.advantagesSubLoad.contentTitle,
  );
};
export const tileClick = (clickAnalytics, contentTitle) => {
  clickAnalytics && clickAnalytics.whySmartPathTileClick && contentTitle && contentLinkClick(
    clickAnalytics.whySmartPathTileClick.tagId,
    clickAnalytics.whySmartPathTileClick.section,
    clickAnalytics.whySmartPathTileClick.subsection,
    clickAnalytics.whySmartPathTileClick.technologyName,
    clickAnalytics.whySmartPathTileClick.page,
    clickAnalytics.whySmartPathTileClick.category,
    clickAnalytics.whySmartPathTileClick.positionTitle,
    contentTitle,
  );
};

