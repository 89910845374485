import React, { useCallback, useEffect, useState } from "react";
import QuizQuestion from "./components/quizQuestion";
import ResultsCarousel from "./components/resultsCarousel";
import EvMatchMakerLandingPage from "./components/landingPage";
import EVStepTracker from "./components/stepTracker";
import vehicleResults from "./vehicle-results.json"
import ResultsHeader from "./components/resultsHeader";
import ElectrifiedComparison from "../sharedComponents/electrifiedComparison";
import { bzEVMatchMakerClick, bzEVMatchMakerPageload } from "./evMatchmaker.analytics";
import { isMobileView } from "../../utils/getScreenSize";
import { postUserActivityRecord } from "../../../services/userActivityService";

const EVMatchmaker = ({ evMatchmakerData, electrifiedComparisonData }) => {
    const questions = [
        {
            questionL1: "Select all trips you take",
            questionL2: "On a regular basis",
            subText: "Select all that apply",
            choices: evMatchmakerData.questionCards.slice(0, 3),
            numChoicesAllowed: 3,
            variableNumChoices: true,
            isLastQuestion: false,
            oneWord: "Trips"
        }, 
        {
            questionL1: isMobileView(window.innerWidth) ? "Do you have a place" : "Do you have a place to",
            questionL2: isMobileView(window.innerWidth) ? "to plug in your" : "plug in your vehicle overnight?",
            questionL3: isMobileView(window.innerWidth) ? "vehicle overnight?" : "",
            choices: evMatchmakerData.questionCards.slice(3, 5),
            numChoicesAllowed: 1,
            variableNumChoices: false,
            isLastQuestion: false,
            oneWord: "Charger"
        }, 
        {
            questionL1: "What is your preferred",
            questionL2: "vehicle body type?",
            subText: "Select only one",
            choices: evMatchmakerData.questionCards.slice(5, 10),
            numChoicesAllowed: 1,
            variableNumChoices: false,
            isLastQuestion: false,
            oneWord: "Passenger"
        }, 
        {
            questionL1: isMobileView(window.innerWidth) ? "Please select your three" : "Please select your three most important",
            questionL2: isMobileView(window.innerWidth) ? "most important aspects you" :"aspects you look for in a vehicle",
            questionL3: isMobileView(window.innerWidth) ? "look for in a vehicle" :"",
            subText: "Must select three options to continue",
            choices: evMatchmakerData.questionCards.slice(10),
            numChoicesAllowed: 3,
            variableNumChoices: false,
            isLastQuestion: true,
            oneWord: "Features"
        }, 
    ];

    const [pageState, setPageStage] = useState(0);
    const [selected, setSelected] = useState([]);
    const [questionNum, setQuestionNum] = useState(0);
    const [resultCards, setResultCards] = useState([]);
    const [isCarOrTruck, setIsCarOrTruck] = useState(false);

    useEffect(() => {
        if(selected.length === 4) {
            const choicesString = selected.map((item) => item.choices.map((choice) => choice + 1).sort()).flat().join("");
            const filteredModelNames = vehicleResults.filter((item) => item.possibleResults.includes(choicesString)).map((item) => item.model);
            const filteredResults = evMatchmakerData.resultCards.filter((item) => filteredModelNames.includes(item.vehicle));
            setResultCards(filteredResults)
        }
        const newIsCarOrTruck = selected[2]?.choices[0] === 0 || selected[2]?.choices[0] === 3;
        setIsCarOrTruck(newIsCarOrTruck);
    }, [selected])

    useEffect(() => {
        bzEVMatchMakerPageload({
            page: 'EV Matchmaker',
        });
        postUserActivityRecord();
    }, [])

    const incrementState = () => {
        setPageStage(value => value + 1);
    };

    const decrementState = () => {
        setPageStage(value => value - 1);
    };

    const onNext = useCallback((choices, lastQuestion) => {
        window.scrollTo(0, 0);
        const questionIndex = selected.findIndex((item) => item.questionL1 === questions[questionNum].questionL1);
        if(questionIndex !== -1) {
            const newAnswer = {
                questionL1: questions[questionNum].questionL1,
                choices: choices
            }
            const newSelected = [...selected]
            newSelected.splice(questionIndex, 1, newAnswer);
            setSelected(newSelected);
        }
        else {
            setSelected([...selected, {
                questionL1: questions[questionNum].questionL1,
                choices: choices
            }])
        }
        if (lastQuestion) return;
        setQuestionNum(questionNum + 1);
        bzEVMatchMakerClick({
            label: 'Next'
        })
    }, [selected, questionNum])

    const onBack = useCallback(() => {
        if(questionNum === 0) {
            decrementState();
            return;
        }
        setQuestionNum(questionNum - 1);
        bzEVMatchMakerClick({
            label: 'Back'
        })
    }, [questionNum])

    const submit = useCallback((choices) => {
        onNext(choices, true)
        incrementState();
        bzEVMatchMakerClick({
            label: 'See Results'
        })
    }, [selected, questions, questionNum])

    const renderPage = () => {
        if(pageState === 0) {
            return (
              <EvMatchMakerLandingPage
                landingData={evMatchmakerData?.headers?.landing}
                onClick={() => {
                    bzEVMatchMakerClick({
                        label: 'Find Match'
                    })
                    incrementState()
                }}
              />
            );
        } else if(pageState === 1) {
            return (
                <>
                    <EVStepTracker questionNum={questionNum} questions={questions}></EVStepTracker>
                    <QuizQuestion
                        questionL1={questions[questionNum].questionL1}
                        questionL2={questions[questionNum].questionL2}
                        questionL3={questions[questionNum].questionL3}
                        subText={questions[questionNum].subText}
                        choices={questions[questionNum].choices}
                        numChoicesAllowed={questions[questionNum].numChoicesAllowed}
                        variableNumChoices={questions[questionNum].variableNumChoices}
                        isLastQuestion={questions[questionNum].isLastQuestion}
                        previousAnswers={selected[questionNum] || {choices:[]}}
                        shouldHide6Plus={isCarOrTruck}
                        onNext={onNext}
                        onBack={onBack}
                        onSubmit={submit}
                        analyticsActions={{
                            onClick: bzEVMatchMakerClick
                        }}
                    />
                </>
            );
        } else {
            return (
                <>
                    <div className="ev-matchmaker-results">
                        <ResultsHeader
                            buttonText={evMatchmakerData.headers.results.buttonText}
                            onButtonClick={() => {
                                bzEVMatchMakerClick({
                                    label: 'Edit Answers'
                                })
                                decrementState();
                            }}
                            title={evMatchmakerData.headers.results.title}
                        />
                        <ResultsCarousel 
                          cards={resultCards} 
                          analyticsActions={{
                            onClick: bzEVMatchMakerClick
                          }}
                        />
                    </div>
                    
                    <ElectrifiedComparison
                        electrifiedComparisonData={electrifiedComparisonData}
                        analyticsAction={{
                            onClick: bzEVMatchMakerClick
                        }}
                    />
                </>
            );
        }
    }

    return (
        <div className="ev-matchmaker-container">
            {renderPage()}
        </div>
    );
};

export default EVMatchmaker;