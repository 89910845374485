import { GlobalAnalytics } from '../../../analytics';

const analytics = new GlobalAnalytics();

const contentLinkClick = (tag, section, subsection, technologyName, page, category, positionTitle, contentTitle) => {
  analytics.fireOnClick({
    tag,
    section,
    subsection,
    technologyName,
    page,
    category,
    positionTitle,
    contentTitle,
  });
};

export const linkClick = (clickAnalytics, contentTitle) => {
  clickAnalytics && clickAnalytics.testimonialsLinkClick && contentTitle && contentLinkClick(
    clickAnalytics.testimonialsLinkClick.tagId,
    clickAnalytics.testimonialsLinkClick.section,
    clickAnalytics.testimonialsLinkClick.subsection,
    clickAnalytics.testimonialsLinkClick.technologyName,
    clickAnalytics.testimonialsLinkClick.page,
    clickAnalytics.testimonialsLinkClick.category,
    clickAnalytics.testimonialsLinkClick.positionTitle,
    contentTitle,
  );
};

export const testimonialsSubLoad = (pageLoadAnalytics) => {
  pageLoadAnalytics && pageLoadAnalytics.testimonialsSubLoad && contentLinkClick(
    pageLoadAnalytics.testimonialsSubLoad.tagId,
    pageLoadAnalytics.testimonialsSubLoad.section,
    pageLoadAnalytics.testimonialsSubLoad.subsection,
    pageLoadAnalytics.testimonialsSubLoad.technologyName,
    pageLoadAnalytics.testimonialsSubLoad.page,
    pageLoadAnalytics.testimonialsSubLoad.category,
    pageLoadAnalytics.testimonialsSubLoad.positionTitle,
    pageLoadAnalytics.testimonialsSubLoad.contentTitle,
  );
};