import React from 'react';

export function VehicleSpecTemplate(component) {
  let className = 'spec-data-vehicle';
  let contentClassName = 'spec-data-vehicle-content';
  let vehicleSpec = null;

  if (component.props.totalVehicles) {
    className += ` vehicle-span-${component.props.totalVehicles}`;
  }
  if (component.props.index > 1) {
    className += ' hide-for-small-only';
  }

  if (component.props.best) {
    contentClassName += ' best flag';
  }

  if (component.props.vehicleSpec) {
    vehicleSpec = component.props.vehicleSpec.specText;
  } else {
    vehicleSpec = 'No Vehicle Spec Data';
  }

  return <div className={className}><div className={contentClassName}>{vehicleSpec}</div></div>;
}
