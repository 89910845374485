import { GlobalAnalytics } from '../../../analytics';

const analytics = new GlobalAnalytics();

export function contentLinksClick(event) {
  analytics.fireOnClick({
    tag: '35.2',
    section: 'Product',
    subsection: 'Featured Technology',
    mediaTitle: event.target.name,
    technologyName: 'Audio Multimedia',
    page: 'Resources',
    category: 'Resource Articles',
    contentTitle: event.target.name,
  });
}

export function filterMenuClick(event) {
  analytics.fireOnClick({
    tag: '35.2',
    section: 'Product',
    subsection: 'Featured Technology',
    mediaTitle: event.target.name,
    technologyName: 'Audio Multimedia',
    page: 'Resources',
    category: 'Content Filter',
    contentTitle: event.target.name,
  });
}

export function sortAnalytics() {
  analytics.fireOnClick({
    tag: '35.2',
    section: 'Product',
    subsection: 'Featured Technology',
    mediaTitle: 'Change Vehicle',
    technologyName: 'Audio Multimedia',
    page: 'Resources',
    category: 'Content Filter',
    contentTitle: 'Change Vehicle',
  });
}

export function resourceListClick(resource) {
  analytics.fireOnClick({
    tag: '35.2',
    section: 'Product',
    subsection: 'Featured Technology',
    mediaTitle: 'Resources Article',
    technologyName: 'Audio Multimedia',
    page: 'Resources',
    category: 'Resources Article',
    contentTitle: resource.title,
  });
}

export function pageLoad() {
  analytics.firePageLoad({
    tag: '35.1',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'Audio Multimedia',
    page: 'Resources',
  });
}
