import React, { useEffect, useState } from "react";
import RecentComments from "./recentComments";
import RecentlyVisitedPages from "./recentlyVisitedPages";
import ProfilePageNavigationBar from "./profilePageNavigationBar";
import "../../../styles/scss/profilePage/profile-page-recent-activities.scss";
import { isDesktopView } from "../../utils/getScreenSize";
import { recentActivityPageLoad, recentActivityTabClick } from "./recentActivities.analytics";
import { postViewPageAction } from "../../../services/nitroService";

const RecentActivities = (props) => {
  const [recentlyVisitedPagesItems, setRecentlyVisitedPagesItems] = useState([]);
  const [activeTab, setActiveTab] = useState("Most Recent Comments");

  useEffect(() => {
    processBrowsingData();
  }, [props.browsingHistory]);

  useEffect(() => {
    if (props.analyticsData) {
      postViewPageAction(window.location.href);
      recentActivityPageLoad(props?.analyticsData?.pageLoad?.recentActivity);
    }
}, [props.analyticsData]);

  const processBrowsingData = () => {
    if (props.browsingHistory && props.browsingHistory.length > 0) {
      const processedItems = props.browsingHistory.map((navItem) => ({
        pageTitle: navItem.pageTitle,
        pageCategory: navItem.pageCategory,
        pageDate: navItem.dateViewed,
        pageUrl: navItem.activityId
      }));

      const top10Items = processedItems.slice(0, 10);
      setRecentlyVisitedPagesItems(top10Items);
    }
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    recentActivityTabClick(props?.analyticsData?.clickAnalytics, tabName);
  };

  return (
    <div className="recent-activities-container">
      {isDesktopView(window.innerWidth) ?
        <div className="desktop-content">
          <RecentComments
            topComments={props?.topComments}
            articlesTitlesAndIds={props?.articlesTitlesAndIds}
            commentsLoading={props?.commentsLoading}
            analyticsData={props.analyticsData}
          />
          <RecentlyVisitedPages 
            items={recentlyVisitedPagesItems}
            historyLoading={props?.historyLoading}
          />
        </div>
      :
        <>
          <ProfilePageNavigationBar activeTab={activeTab} onTabClick={handleTabClick} />
          {activeTab === "Most Recent Comments" &&
            <RecentComments
              topComments={props?.topComments}
              articlesTitlesAndIds={props?.articlesTitlesAndIds}
              commentsLoading={props?.commentsLoading}
              analyticsData={props.analyticsData}
            />
          }
          {activeTab === "Recently Visited Pages" &&
            <RecentlyVisitedPages 
              items={recentlyVisitedPagesItems}
              historyLoading={props?.historyLoading}
            />
          }
        </>
      }
    </div>
  );
};

export default RecentActivities;
