import { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import TrimWalkTemplate from './trimWalk.tpl';
import { getFeaturesAndSpecs } from '../../services/featuresAndSpecsService.js';
import { getTrimOptionsByModelNameOnly } from '../../services/trimOptionsService.js';
import { getAllTrims } from '../../services/trimsService.js';
import { getLocale } from '../../services/languageTranslationService.js';
import { areStringsEqual, cloneObject } from '../utils/helpers';
import { postUserActivityRecord } from '../../services/userActivityService';
import { getDynamoName, getS3PathName } from '../utils/vehicleName.js';
import { getVehicleYears } from '../../services/vehicleService.js';
import formatMSRP from '../utils/formatMSRP.js';
import AppSettings from '../../appsettings';

const analyticsFunctions = require('./trimWalk.analytics.js');

class TrimWalkComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            s3Path: '',
            languageOverride: false,
            displayOverrideText: false,
            vehicleYears: [],
            secondYear: this.props.params.year,
            selectedVehicles: [],
            featuresYearMap: null,
            features: [],
            lowerTrimSelection: 0,
            lowerTrimFeatures: {},
            lowerTrimModelCode: null,
            lowerTrimName: null,
            lowerTrimYear: this.props.params.year,
            higherTrimYear: this.props.params.year,
            higherTrimSelection: 0,
            higherTrimModelCode: null,
            higherTrimName: null,
            allFeatures: {},
            higherTrimFeatures: {},
            lowerTrimMsrp: null,
            lowerTrimNumericMsrp: null,
            lowerTrimFullName: null,
            higherTrimFullName: null,
            higherTrimNumericMsrp: null,
            higherTrimMsrp: null,
            msrpDiff: null,
            lowerTrimPackageSelectedValue: null,
            higherTrimPackageSelectedValue: null,
            trimWalkTier: undefined,
        };

        this.disclaimers = [];
        this.loadFeaturesAndSpecs = this.loadFeaturesAndSpecs.bind(this);
        this.combineFeaturesSpecs = this.combineFeaturesSpecs.bind(this);
        this.disclaimerCallback = this.disclaimerCallback.bind(this);
        this.noSpanishRecordAvailable = this.noSpanishRecordAvailable.bind(this);
        this.checkLocaleAgainstLanguageOverride = this.checkLocaleAgainstLanguageOverride.bind(this);
        this.getS3Path = this.getS3Path.bind(this);

        // Bind scope of 'this' for analytics functions
        this.handleCoachScreenClick = this.handleCoachScreenClick.bind(this);
        this.sendClickAnalytics = analyticsFunctions.sendClickAnalytics.bind(this);
        this.sendPageLoadAnalytics = analyticsFunctions.sendPageLoadAnalytics.bind(this);
        this.sendTrimSelectionAnalytics = analyticsFunctions.sendTrimSelectionAnalytics.bind(this);
    }

    disclaimerCallback(index, disclaimer) {
        this.disclaimers[index].onOpenModal(disclaimer);
    }

    updatelowerTrimSelectedVehicles(selection) {
        if (selection) {
            const lowerTrimFeatures = this.filterFeatures(selection);
            const lowerTrimPackages = this.filterPackages(selection);
            const lowerTrimPackArr = TrimWalkComponent.getPackageAsArray(lowerTrimPackages);
            const lowerTrimYearsOptions = this.getLowerTrimYearsOptions();

            let lowerPackageSelect = null;
            if (lowerTrimPackArr.length > 0) {
                lowerPackageSelect = lowerTrimPackArr[0];
            }
            const higherTrimSelection = this.state.higherTrimSelection === 0 ? this.state.trim = 1 : this.state.higherTrimSelection;
            let msrpDiff = 0;
            if (this.state.higherTrimNumericMsrp) {
                msrpDiff = parseInt(this.state.higherTrimNumericMsrp) - parseInt(selection[0].trim.msrp);
            }
            this.setState({
                selectedVehicles: selection,
                higherTrimSelection,
                lowerTrimMsrp: formatMSRP(selection[0].trim.msrp),
                lowerTrimNumericMsrp: selection[0].trim.msrp,
                lowerTrimPackages,
                lowerTrimModelCode: selection[0].trim.modelCode,
                lowerTrimFeatures,
                lowerTrimName: selection[0].trim.name,
                lowerTrimFullName: selection[0].trim.fullName,
                lowerTrimPackageSelectedValue: lowerPackageSelect,
                lowerTrimYearsOptions: lowerTrimYearsOptions,
                lowerTrimYear: selection && selection.length > 0 ? selection[0].year : null,
                msrpDiff: formatMSRP(msrpDiff.toString()),
                trimWalkTier: selection[0].trim.trimWalkTier ? parseInt(selection[0].trim.trimWalkTier) : undefined,
            });
            if (this.state.higherTrimModelCode) {
                const higherTrimFeatures = this.filterByYearAndModelCode(this.props.params.year, this.state.higherTrimModelCode);
                const higherTrimPackages = this.filterPackagesByYearAndModelCode(parseInt(this.props.params.year), this.state.higherTrimModelCode);
                const higherTrimPackArr = TrimWalkComponent.getPackageAsArray(higherTrimPackages);
                let higherPackageSelect = null;
                if (higherTrimPackArr.length > 0) {
                    higherPackageSelect = higherTrimPackArr[0];
                }
                const uniqueHigherTrimFeatures = this.separateFeatures(higherTrimFeatures);
                this.setState({
                    higherTrimFeatures: uniqueHigherTrimFeatures,
                    higherTrimPackages,
                    higherTrimPackageSelectedValue: higherPackageSelect,

                });
            }
        }
    }

    updatehigherTrimSelectedVehicles(selection) {
        if (selection) {
            const higherTrimFeatures = this.filterFeatures(selection);
            const higherTrimPackages = this.filterPackages(selection);
            const higherTrimPackArr = TrimWalkComponent.getPackageAsArray(higherTrimPackages);
            let higherPackageSelect = null;
            if (higherTrimPackArr.length > 0) {
                higherPackageSelect = higherTrimPackArr[0];
            }
            const uniqueHigherTrimFeatures = this.separateFeatures(higherTrimFeatures);
            let msrpDiff = 0;
            if (this.state.lowerTrimNumericMsrp) {
                msrpDiff = parseInt(selection[0].trim.msrp) - parseInt(this.state.lowerTrimNumericMsrp);
            }
            this.setState({
                higherTrimModelCode: selection[0].trim.modelCode,
                higherTrimYear: this.props.params.year,
                higherTrimFeatures: uniqueHigherTrimFeatures,
                higherTrimMsrp: formatMSRP(selection[0].trim.msrp),
                higherTrimNumericMsrp: selection[0].trim.msrp,
                higherTrimPackages,
                higherTrimName: selection[0].trim.name,
                higherTrimFullName: selection[0].trim.fullName,
                higherTrimPackageSelectedValue: higherPackageSelect,
                msrpDiff: formatMSRP(msrpDiff.toString()),
            });
        }
    }

    filterFeatures(selection) {
        const trimModelCode = selection[0].trim.modelCode;
        const year = (selection && selection.length > 0) ? selection[0].year : this.state.secondYear;
        return this.filterByYearAndModelCode(year, trimModelCode);
    }

    filterByYearAndModelCode(year, trimModelCode) {
        // Get features by year
        const { featuresYearMap } = this.state;
        const featuresByYear = featuresYearMap.get(parseInt(year));
        const features = featuresByYear.featuresAndSpecs;

        // categorize features
        const allTrimExterior = features.Exterior;
        const allTrimInterior = features.Interior;
        const allTrimSafety = features['Safety/Convenience'];

        // the final returned features
        const extFeatures = new Map();
        const interiorFeatures = new Map();
        const safetyFeatures = new Map();


        if (allTrimExterior && allTrimExterior.length > 0) {
            for (const exIndex in allTrimExterior) {
                var obj = allTrimExterior[exIndex];
                var featuresAndSpecs = obj.featureSpecs;
                if (featuresAndSpecs && featuresAndSpecs.length > 0) {
                    for (const key in featuresAndSpecs) {
                        var featureObj = featuresAndSpecs[key];
                        var object = featureObj[year];
                        if (object && object[trimModelCode]
                            && ((object[trimModelCode]).toLowerCase() === 'p' || (object[trimModelCode]).toLowerCase() === 's' || (object[trimModelCode]).toLowerCase() === 'o')
                            && !extFeatures.has(object.Description)) {
                            extFeatures.set(object.Description, object[trimModelCode]);
                        }
                    }
                }
            }
        }

        if (allTrimInterior && allTrimInterior.length > 0) {
            for (const intIndex in allTrimInterior) {
                var obj = allTrimInterior[intIndex];
                var featuresAndSpecs = obj.featureSpecs;
                if (featuresAndSpecs && featuresAndSpecs.length > 0) {
                    for (const key in featuresAndSpecs) {
                        var featureObj = featuresAndSpecs[key];
                        var object = featureObj[year];
                        if (object && object[trimModelCode]
                            && ((object[trimModelCode]).toLowerCase() === 'p' || (object[trimModelCode]).toLowerCase() === 's')
                            && !interiorFeatures.has(object.Description)) {
                            interiorFeatures.set(object.Description, object[trimModelCode]);
                        }
                    }
                }
            }
        }

        if (allTrimSafety && allTrimSafety.length > 0) {
            for (const safetyIndex in allTrimSafety) {
                var obj = allTrimSafety[safetyIndex];
                var featuresAndSpecs = obj.featureSpecs;
                if (featuresAndSpecs && featuresAndSpecs.length > 0) {
                    for (const key in featuresAndSpecs) {
                        var featureObj = featuresAndSpecs[key];
                        var object = featureObj[year];
                        if (object && object[trimModelCode]
                            && ((object[trimModelCode]).toLowerCase() === 'p' || (object[trimModelCode]).toLowerCase() === 's')
                            && !safetyFeatures.has(object.Description)) {
                            safetyFeatures.set(object.Description, object[trimModelCode]);
                        }
                    }
                }
            }
        }

        const featuresObj = {
            'Exterior': extFeatures,
            'Interior': interiorFeatures,
            'Safety': safetyFeatures,
        };

        return featuresObj;
    }

    getLowerTrimYearsOptions() {
        var lowerTrimYearsOptions = [];
        // Get features by year
        const { featuresYearMap } = this.state;
        featuresYearMap.forEach((value, key) => {
            if (key && parseInt(key) >= 2019) {
                lowerTrimYearsOptions.push(parseInt(key));
            }
        });
        return lowerTrimYearsOptions;
    }

    separateFeatures(higherTrimFeatures) {
        if (this.state.lowerTrimFeatures && higherTrimFeatures) {
            const lowerTrimExterior = this.state.lowerTrimFeatures.Exterior;
            const lowerTrimInterior = this.state.lowerTrimFeatures.Interior;
            const lowerTrimSafety = this.state.lowerTrimFeatures.Safety;
            const higherTrimExterior = higherTrimFeatures.Exterior;
            const higherTrimInterior = higherTrimFeatures.Interior;
            const higherTrimSafety = higherTrimFeatures.Safety;
            var newhigherTrimExterior = new Map();
            var newHigherInterior = new Map();
            var newhigherTrimSafety = new Map();

            if (lowerTrimExterior && higherTrimExterior) {
                higherTrimExterior.forEach((value, key) => {
                    const legendEx = lowerTrimExterior.get(key);
                    if (!lowerTrimExterior.has(key) || (lowerTrimExterior.has(key) && legendEx !== value)) {
                        newhigherTrimExterior.set(key, value);
                    }
                });
            }

            if (lowerTrimInterior && higherTrimInterior) {
                higherTrimInterior.forEach((value, key) => {
                    const legendInt = lowerTrimInterior.get(key);
                    if (!lowerTrimInterior.has(key) || (lowerTrimInterior.has(key) && legendInt !== value)) {
                        newHigherInterior.set(key, value);
                    }
                });
            }

            if (lowerTrimSafety && higherTrimSafety) {
                higherTrimSafety.forEach((value, key) => {
                    const legendSaf = lowerTrimSafety.get(key);
                    if (!lowerTrimSafety.has(key) || (lowerTrimSafety.has(key) && legendSaf !== value)) {
                        newhigherTrimSafety.set(key, value);
                    }
                });
            }
        }

        const uniqueFeatures = {
            'Exterior': newhigherTrimExterior,
            'Interior': newHigherInterior,
            'Safety': newhigherTrimSafety,
        };

        return uniqueFeatures;
    }

    filterPackages(selection) {
        const trimModelCode = selection[0].trim.modelCode;
        const year = (selection && selection.length > 0) ? selection[0].year : this.state.secondYear;
        return this.filterPackagesByYearAndModelCode(year, trimModelCode);
    }

    filterPackagesByYearAndModelCode(year, modelCode) {
        const trimPackages = new Map();
        if (this.state.trimOptions) {
            const options = this.state.trimOptions;
            for (const index in options) {
                const optionsItem = options[index];
                if (optionsItem.modelYear === year) {
                    if (optionsItem.trimOptions) {
                        const { packages } = optionsItem.trimOptions;
                        if (packages) {
                            for (const item in packages) {
                                const packageElemnet = packages[item];
                                const { availability } = packageElemnet;
                                const modelCodeInt = parseInt(modelCode);
                                if (availability.includes(modelCodeInt) || availability.includes(modelCode)) {
                                    let msrp = null;
                                    if (packageElemnet.msrp) {
                                        msrp = formatMSRP(packageElemnet.msrp.toString());
                                    } else {
                                        msrp = formatMSRP('0');
                                    }
                                    const packageItem = {
                                        description: packageElemnet.description,
                                        msrp,
                                        code: packageElemnet.code,
                                    };
                                    trimPackages.set(packageElemnet.title, packageItem);
                                }
                            }
                        }
                    }
                }
            }
        }
        return trimPackages;
    }

    static getPackageAsArray(trimPackages) {
        let arr = [];
        if (trimPackages && trimPackages.size !== 0) {
            arr = Array.from(trimPackages.keys());
        }
        return arr;
    }

    static convertFeaturesToArray(selectedFeatures) {
        const featuresArray = [];
        if (selectedFeatures.Exterior) {
            featuresArray.push({
                name: 'Exterior',
                items: selectedFeatures.Exterior,
            });
        }
        if (selectedFeatures.Interior) {
            featuresArray.push({
                name: 'Interior',
                items: selectedFeatures.Interior,
            });
        }
        if (selectedFeatures['Safety/Convenience']) {
            featuresArray.push({
                name: 'Safety/Convenience',
                items: selectedFeatures['Safety/Convenience'],
            });
        } else if (selectedFeatures['Seguridad/Conveniencia']) {
            featuresArray.push({
                name: 'Seguridad/Conveniencia',
                items: selectedFeatures['Seguridad/Conveniencia'],
            });
        }
        return featuresArray;
    }

    loadFeaturesAndSpecs(year, primaryDataLanguageOverride) {
        return new Promise((resolve, reject) => {
            getFeaturesAndSpecs(this.props.route.model, year, this.state.languageOverride || primaryDataLanguageOverride ? 'en' : undefined)
                .then((payload) => {
                    resolve(payload);
                }).catch((error) => {
                    console.log(`ERROR loading feat/specs: ${error}`);
                    reject(error);
                });
        });
    }

    loadInitialFeaturesAndSpecs() {
        return new Promise((resolve, reject) => {
            const dynamoName = getDynamoName(this.props.route.model);
            getVehicleYears(dynamoName)
                .then(years => {
                    const featsAndSpecsRequests = years.map(year => getFeaturesAndSpecs(dynamoName, year, 'en'));
                    Promise.all(featsAndSpecsRequests)
                        .then(values => {
                            resolve(values);
                        })
                }).catch((error) => {
                    console.log(`ERROR loading features/specs: ${error}`);
                    reject(error);
                });
        });
    }

    loadTrims() {
        return new Promise((resolve, reject) => {
            getAllTrims(this.props.route.model, this.state.languageOverride ? 'en' : undefined)
                .then((payload) => {
                    resolve(payload);
                }).catch((error) => {
                    console.log(`ERROR loading trims: ${error}`);
                    reject(error);
                });
        });
    }

    loadTrimOptions() {
        return new Promise((resolve, reject) => {
            getTrimOptionsByModelNameOnly(this.props.route.model, this.state.languageOverride ? 'en' : undefined)
                .then((payload) => {
                    resolve(payload);
                }).catch((error) => {
                    console.log(`ERROR loading trims: ${error}`);
                    reject(error);
                });
        });
    }

    componentWillReceiveProps() {
        this.getPageData();
    }

    componentDidMount() {
        this.getPageData();
        this.sendPageLoadAnalytics();
        const path = window.location.pathname;
        postUserActivityRecord(path);
    }

    static matchURLYear(model) {
        const splitUrl = window.location.pathname.split('/');
        return model.modelYear.toString() === splitUrl[3];
    }

    noSpanishRecordAvailable(record) {
        return (!record && getLocale() === 'es' && !this.state.languageOverride);
    }

    checkLocaleAgainstLanguageOverride() {
        if (getLocale() === 'es' && !this.state.languageOverride) {
            return 'es';
        }
        return 'en';
    }

    async getS3Path () {
      const localeWithOverride = this.checkLocaleAgainstLanguageOverride();
      const s3PathName = await getS3PathName(this.props.route.model);
      this.setState({
        s3Path: `${s3PathName}/${localeWithOverride}/`,
      });
    }

    getPageData() {
        Promise.all([this.loadInitialFeaturesAndSpecs(), this.loadTrims(), this.loadTrimOptions()]).then((values) => {
            const allModelFeatSpecRecords = values[0];
            const currentYearFeatSpecRecord = allModelFeatSpecRecords.find(TrimWalkComponent.matchURLYear);
            const trimOptions = values[2];
            if (this.noSpanishRecordAvailable(currentYearFeatSpecRecord)) {
                this.setState({
                    languageOverride: true,
                    displayOverrideText: true,
                });
            } else {
                const trims = {};
                values[1].forEach((yearTrim) => {
                    trims[yearTrim.modelYear] = yearTrim.trims;
                });
                const features = allModelFeatSpecRecords.sort((a, b) => b.modelYear - a.modelYear);
                const lowTrim = trims[this.state.lowerTrimYear][this.state.lowerTrimSelection];
                this.setState({
                    inComparisonMode: false,
                    firstVehicleIndex: 0,
                    vehicleYears: features.map(feat => feat.modelYear),
                    trims,
                    trimOptions,
                    features: features.map(TrimWalkComponent.mapModelCodesToYear),
                    specs: features.map(TrimWalkComponent.mapSpecs),
                    selectedFeatures: TrimWalkComponent.convertFeaturesToArray(features[0].featuresAndSpecs),
                    selectedSpecs: TrimWalkComponent.mapSpecs(features[0]),
                    featuresYearMap: this.mapYearToFeaturesAndSpecs(allModelFeatSpecRecords),
                    trimWalkTier: lowTrim.trimWalkTier ? Number(lowTrim.trimWalkTier) : undefined
                });
            }
            this.getS3Path();
        }).catch((error) => {
            console.log(`ERROR getting page data: ${error}`);
        });
    }

    static mapModelCodesToYear(yearFeatures) {
        const { modelYear } = yearFeatures;
        // Loop through the outer feature sets
        const mainGroups = Object.keys(yearFeatures.featuresAndSpecs);
        mainGroups.forEach((mainGroup) => {
            const groupFeatures = yearFeatures.featuresAndSpecs[mainGroup];
            // Loop through the elements in each outer feature set
            groupFeatures.forEach((groupFeature) => {
                // Map each feature (description and model codes) to it's model year
                // eslint-disable-next-line no-param-reassign
                groupFeature.featureSpecs = groupFeature.featureSpecs.map((feat) => {
                    const obj = {};
                    obj[modelYear] = feat;
                    return obj;
                });
            });
        });
        return yearFeatures;
    }


    static mapSpecs(vehicleFeatureSpecs) {
        const specs = {};
        vehicleFeatureSpecs.featuresAndSpecs.Specs.forEach((spec) => {
            specs[spec.name] = [{ featureSpecs: spec.featureSpecs }];
        });
        return specs;
    }

    mapVehicleSelectionToFeaturesAndSpecs(selectedVehicles) {
        // Grab the feature set for each car based on it model year
        const yearFeatureSets =
            selectedVehicles.map(vehicle => this.state.features.find(feat => feat.modelYear === vehicle.year));
        if (yearFeatureSets.length > 1) {
            return this.combineFeaturesSpecs(yearFeatureSets[0], yearFeatureSets[1]);
        }
        return yearFeatureSets[0].featuresAndSpecs;
    }

    mapYearToFeaturesAndSpecs(allModelFeatSpecRecords) {
        const featuresYearMap = new Map();
        for (const key in allModelFeatSpecRecords) {
            const year = allModelFeatSpecRecords[key].modelYear;
            featuresYearMap.set(year, allModelFeatSpecRecords[key]);
        }
        return featuresYearMap;
    }

    combineFeaturesSpecs(primaryFeatSpecs, secondaryFeatSpecs) {
        const object = cloneObject(primaryFeatSpecs.featuresAndSpecs); // Cloning the primary features object because otherwise it is passed as a refrence here and is modified.
        const secondaryFeatureSpecs = secondaryFeatSpecs.featuresAndSpecs;
        let featSpec = {};
        if (getLocale() === 'en' || this.state.languageOverride) { // this is so dumb. We need to refactor.
            featSpec = {
                Exterior: TrimWalkComponent.combineFeature(object.Exterior,
                    secondaryFeatureSpecs.Exterior,
                    primaryFeatSpecs.modelYear,
                    secondaryFeatSpecs.modelYear),
                Interior: TrimWalkComponent.combineFeature(object.Interior,
                    secondaryFeatureSpecs.Interior,
                    primaryFeatSpecs.modelYear,
                    secondaryFeatSpecs.modelYear),
                'Safety/Convenience': TrimWalkComponent.combineFeature(object['Safety/Convenience'],
                    secondaryFeatureSpecs['Safety/Convenience'],
                    primaryFeatSpecs.modelYear,
                    secondaryFeatSpecs.modelYear),
                Specs: TrimWalkComponent.combineFeature(object.Specs,
                    secondaryFeatureSpecs.Specs,
                    primaryFeatSpecs.modelYear,
                    secondaryFeatSpecs.modelYear),
            };
        } else {
            featSpec = {
                Exterior: TrimWalkComponent.combineFeature(object.Exterior,
                    secondaryFeatureSpecs.Exterior,
                    primaryFeatSpecs.modelYear,
                    secondaryFeatSpecs.modelYear),
                Interior: TrimWalkComponent.combineFeature(object.Interior,
                    secondaryFeatureSpecs.Interior,
                    primaryFeatSpecs.modelYear,
                    secondaryFeatSpecs.modelYear),
                'Seguridad/Conveniencia': TrimWalkComponent.combineFeature(object['Seguridad/Conveniencia'],
                    secondaryFeatureSpecs['Seguridad/Conveniencia'],
                    primaryFeatSpecs.modelYear,
                    secondaryFeatSpecs.modelYear),
                Specs: TrimWalkComponent.combineFeature(object.Specs,
                    secondaryFeatureSpecs.Specs,
                    primaryFeatSpecs.modelYear,
                    secondaryFeatSpecs.modelYear),
            };
        }

        return featSpec;
    }

    static combineFeature(primaryFeatSpec, secondaryFeatSpecs, primaryModelYear, secondaryModelYear) {
        const finalSection = primaryFeatSpec;
        secondaryFeatSpecs.forEach((featureGroup) => {
            // Check if the feature group exists in the finalSection
            const finalIndex = finalSection.findIndex(feat => areStringsEqual(feat.name, featureGroup.name));
            // If the section exists
            if (finalIndex !== -1) {
                const matchingSection = finalSection[finalIndex];
                // Find the match for each secondary item and update, or append it
                featureGroup.featureSpecs.forEach((secondaryItem) => {
                    // Initially there is not a known match
                    let hasMatch = false;
                    // Check if a match exists in any of the primary feature groups
                    const matchIndex = matchingSection.featureSpecs.findIndex((matchFeat) => {
                        // If this feature doesn't exist for the primary year return false
                        if (!matchFeat || !matchFeat[primaryModelYear]) {
                            return false;
                        }
                        return areStringsEqual(matchFeat[primaryModelYear].Description, secondaryItem[secondaryModelYear].Description);
                    });
                    // If there is a match the index will be >= 0
                    if (matchIndex !== -1) {
                        // Set the flag
                        hasMatch = true;
                        // Assign the values to the model year in the final features
                        matchingSection.featureSpecs[matchIndex][secondaryModelYear] = secondaryItem[secondaryModelYear];
                    }
                    // If there was not a match found we need to add the feature to the final output
                    if (!hasMatch) {
                        // If the feature group that the feature is in already exists in the output add it there
                        // otherwise add the feature group
                        if (finalIndex !== -1) {
                            finalSection[finalIndex].featureSpecs.push(secondaryItem);
                        }
                    }
                });
            } else {
                finalSection.push(featureGroup);
            }
        });
        return finalSection;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.languageOverride && !prevState.languageOverride) {
            this.getPageData();
        } else if (this.state.displayOverrideText && getLocale() === 'en') {
            this.setState({ displayOverrideText: false });
        }
    }

    async generateImage(selectedColor) {
        let imageName = selectedColor.image ? selectedColor.image : 'no_image_available_700x300.png';
        if (imageName[0] === '/') {
            const startIndex = imageName.lastIndexOf('/') + 1;
            imageName = imageName.slice(startIndex);
        }
        const formattedModelName = await getS3PathName(this.state.model);
        return encodeURI(`${AppSettings.AWSImgRepo.vehicle}${this.state.year}/${formattedModelName}/en/${imageName}`);
    }

    // Trim Walk Analytics calls..
    handleCoachScreenClick() {
        const label = `${this.props.route.year} ${this.props.route.model}`;
        this.sendClickAnalytics('Coach Screen', label);
    }

    sendDisclaimersAnalytics = (selectedDisclaimer) => {
        this.sendClickAnalytics('Disclaimer', selectedDisclaimer);
    }

    sendLowerTrimAnalytics = (selection) => {
        this.sendSelectionAnalytics('Lower Trim', selection);
    }

    sendHigherTrimAnalytics = (selection) => {
        this.sendSelectionAnalytics('Higher Trim', selection);
    }

    sendSelectionAnalytics(category, selection) {
        const label = `${selection.year} ${selection.trim.engine} ${selection.trim.fullName}`;
        this.sendTrimSelectionAnalytics(selection.selectName, category, label, this.props.route.model, selection.year, selection.trim.fullName);
    }

    render() {
        return TrimWalkTemplate(this);
    }
}

TrimWalkComponent.propTypes = {
    intl: PropTypes.object.isRequired,
};

export default injectIntl(TrimWalkComponent);
