import React, { useState, useEffect, useRef } from "react";
import AccordionLegend from "../../../components/molecules/accordionLegend.js";
import { Accordion } from "../../../components/molecules/Accordion.js";
import applyMarkdown from "../../utils/applyMarkdown";
import TrimSelector from "../../../components/molecules/VPheader/trimSelector";
import "../../../styles/scss/details-v2/optionsPackages.scss";
import OptionsPackagesAccordionContent from "../featuresAndSpecs/optionsPackagesAccordionContent";
import AppSettings from "../../../appsettings.js";
import { accordionClick } from "../vehicleDetails.analytics.js";
import Skeleton from "react-loading-skeleton";
import { toCamelCase, getVPTooltips } from "../../VPRouter/VPUtils.js";


const OptionsPackages = (props) => {
    const collapseAccordionRef = useRef(props.currentOptionsPackagesPage?.title?.toLowerCase());

    const [tooltips, setTooltips] = useState({});

    useEffect(() => {
        let terms = []
        collapseAccordionRef.current = props.currentOptionsPackagesPage?.title?.toLowerCase();
        if (!props?.trimOptionsLoading) {
            const data = !props?.trimOptionsLoading && props?.optionsAndPackagesData[toCamelCase(`${props.currentOptionsPackagesPage?.title}`)];
            const termsSet = new Set(data?.map((accordionContent) => accordionContent?.title).flat());
            termsSet?.forEach((term) => terms.push(term));
        }
        props?.glossaryTerms?.length > 0 && getVPTooltips(terms, props?.glossaryTerms, setTooltips);
    }, [props.currentOptionsPackagesPage?.title?.toLowerCase(), props?.glossaryTerms]);


    const getAccordion = () => {
        switch (props.currentOptionsPackagesPage?.title?.toLowerCase()) {
            case 'factory options':
                return buildSimpleAccordion(props.optionsAndPackagesData?.factoryOptions);

            case 'packages':
                return buildSimpleAccordion(props.optionsAndPackagesData?.packages);

            default:
                return null;
        }
    };
    

    const buildSimpleAccordion = (data) => {
        const modelCodes = props.selectedTrim?.trimModels?.map(model => model.modelCode);
        const filteredItems = data?.filter(option => {
            return option.availability.some(code => modelCodes?.includes(code.toString()));
        });
        const unavailableItems = [...new Set(data
            ?.map(option => option.title)
            ?.filter(option =>
                !filteredItems?.map(i => i.title.toLowerCase()).includes(option.toLowerCase())
            ))];

        const accordionData = filteredItems?.map((item, index) => {
            const matchingModels = props.selectedTrim?.trimModels?.filter(model =>
                item.availability?.includes(parseInt(model.modelCode))
            );
            return ({
                title: tooltips[item.title] ? applyMarkdown(tooltips[item.title], props.disclaimerComponentRef.current, null, true, props?.applyTooltip, props.glossaryData)
                : applyMarkdown(item.title, props.disclaimerComponentRef.current, null, true, props?.applyTooltip, props.glossaryData),
                body: (
                    <OptionsPackagesAccordionContent
                        key={index}
                        item={item}
                        year={props.year}
                        model={props.model}
                        s3ImagePath={`${AppSettings.AWSImgRepo.vehicle}${props.year}/${props.s3PathName}/en/`}
                        matchingModels={matchingModels}
                        disclaimerComponentRef={props?.disclaimerComponentRef}
                    />
                ),
                key: index
            });
        });

        return (
            <div className="options-packages-v2-container">
                {props.device === "mobile" && <div className="horizontal-divider" />}
                {!props.trimOptionsLoading ? (
                    accordionData?.length ? (
                        <Accordion
                            expandCollapseAll
                            rows={accordionData}
                            className="vehicle-page-accordion optionsPackages-accordion"
                            ref={collapseAccordionRef}
                            termAnalyticsClick={(term, isExpand) => {
                                accordionClick({
                                    page: props?.title,
                                    term: term,
                                    isExpand: isExpand,
                                    modelName: props?.model,
                                    modelYear: props?.year,
                                    isNested: false,
                                    trim: props?.selectedTrim?.name,
                                });
                            }}
                        />
                    ) : (
                        <div className="no-items-package-options">This Trim Doesn't Contain Any Items</div>
                    )
                ) : (
                    <div className="options-packages-skeleton-container">
                        <Skeleton count={5} height={30}/>
                    </div>
                )}
                {unavailableItems.length > 0 && (
                    <Accordion
                        termAnalyticsClick={(term, isExpand) => {
                            accordionClick({
                                page: props?.title,
                                term: term,
                                category: term,
                                label: `${term} ${isExpand ? 'Expand' : 'Collapse'}`,
                                isExpand: isExpand,
                                modelName: props?.model,
                                modelYear: props?.year,
                                isNested: false,
                                trim: props?.selectedTrim?.name
                            });
                        }}
                        rows={[
                            {
                                title: props.currentOptionsPackagesPage?.title?.toLowerCase() === 'packages' ? 'Packages On Other Trims' : 'Options On Other Trims',
                                body: (
                                    <div className="other-trim-options">
                                        {unavailableItems.map((item, index) => (
                                            <div key={index} className="other-option">
                                                {tooltips && tooltips[item] ? applyMarkdown(tooltips[item], props.disclaimerComponentRef.current, null, true, props?.applyTooltip, props.glossaryData)
                                                    : applyMarkdown(item, props.disclaimerComponentRef.current, null, true, props?.applyTooltip, props.glossaryData)}
                                            </div>
                                        ))}
                                    </div>
                                ),
                                key: 'unavailableItems',
                            },
                        ]}
                        className={`vehicle-page-accordion optionsPackagesOtherTrims-accordion ${!accordionData?.length ? "alwaysExpanded" : ""}`}
                        expandIcon={<img className='icon' src={`${AppSettings.AWSImgRepo.resources}en/nestedAccordionArrow.png`} alt='' style={{ transform: "scaleY(-1)" }} />}
                        collapseIcon={<img className='icon' src={`${AppSettings.AWSImgRepo.resources}en/nestedAccordionArrow.png`} alt='' />}
                        alwaysExpanded={!accordionData?.length}
                        ref={collapseAccordionRef}
                    />
                )}
            </div>
        );
        
    };

    return (
        <>
            {getAccordion()}
        </>
    );
}

export default OptionsPackages;
