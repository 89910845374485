import 'whatwg-fetch';
import httpOption from './httpOption';
import AppSettings from '../appsettings';

export function getVehicleYears(modelName) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/product/${modelName}/years`;
  const options = httpOption.GET();

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return resolve(response.json());
        }
        return reject(response);
      }).catch(error => {
        reject(error);
      });
  });
}

export async function getAvailableModels() {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/product/models`;
  const options = httpOption.GET();

  const response = await fetch(url, options);
  if (response.status >= 200 && response.status < 300) {
    return response.json();
  } else {
    return Error(response);
  }
}