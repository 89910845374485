import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import '../../../styles/css/searchModelCode.css';
import applyMarkdown from '../../utils/applyMarkdown';
import { getFormattedName } from '../../utils/vehicleName';
import { generateLearnMoreButton } from '../resultHelperFunctions.js';

export class ModelCodeSearchComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  // we need to update the model code search result, because it's indexed as modelName = modelCode and modelYear = modelCode
  // model code documents has to be indexed in that way, so the search result hit matches when a user searches for a model code
  updateSearchResultValues = (item) => {
    // update model name
    item.model = item.displayModelName;
    if (item.values && item.values.length > 0) {
      // pick the highest year from the returned values
      item.year = Math.max(...Object.values(item.values).map((element => parseInt(element.year))));
    }
    return item;
  }

  getModelSearchResultObject = (refinedSearchResult) => {
    const searchCardObject = {
      modelName: refinedSearchResult.displayModelName,
      year: refinedSearchResult.year,
      modelCode: refinedSearchResult.group,
      trimName: null,
      engineName: null,
      header: null,
      title: refinedSearchResult.descriptionToShow,
    };
    if (refinedSearchResult && refinedSearchResult.values && refinedSearchResult.values.length > 0) {
      const itemWithHighestYear = (refinedSearchResult.values).reduce((prev, current) => {
        return (prev.year > current.year) ? prev : current;
      });
      searchCardObject.trimName = itemWithHighestYear.fullName;
      searchCardObject.engineName = itemWithHighestYear.engine;
    }
    return searchCardObject;
  }

  createModelSearchResult = (searchCardObject, refinedSearchResult) => {
    const learnMoreButton = generateLearnMoreButton(refinedSearchResult, searchCardObject, this.props.parentComponent.state.analyticsData.clickAnalytics, this.props.parentComponent.state.searchParam, this.props.parentComponent.state.totalResults);

    return (
      <div className = "searchModelCode">
        <div className="content-header">{searchCardObject.title}</div>
        <div className = "modelCodeBox">{searchCardObject.modelCode}</div>
        <div className = "modelCodeInfo">
          <div className = "modelNameFormat">{applyMarkdown(getFormattedName(searchCardObject.modelName))}</div>
          <div className = "trimNameFormat">{searchCardObject.trimName}</div>
        </div>
        <div className="quickAnswersSeeMore">
          {learnMoreButton}
        </div>
      </div>
    );
  }

  render() {
    // refinedSearchResult: is the search hit result after updating its values (modelName and modelYear)
    const refinedSearchResult = this.updateSearchResultValues(this.props.item);
    // searchCardObject: has all the required values for the search result card (for the UI).
    const searchCardObject = this.getModelSearchResultObject(refinedSearchResult);
    return (this.createModelSearchResult(searchCardObject, refinedSearchResult));
  }
}


ModelCodeSearchComponent.propTypes = {
  item: PropTypes.object,
  parentComponent: PropTypes.object,
  disclaimerCallback: PropTypes.func,
  index: PropTypes.number,
};


export default injectIntl(ModelCodeSearchComponent);

