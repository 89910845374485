import React, { useEffect, useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import VehiclesComparisonFeature from './vehiclesFeatureComparison';
import '../../styles/css/featureComparison.css';
import {Accordion} from '../../components/molecules/Accordion';
import { toTitleCase } from '../utils/helpers';
import adjustStringForUrl from '../utils/adjustStringForUrl';

export const ComparisonSection = (props) => {
    const scrollRef = useRef();
    const [scrollTerms, setScrollTerms] = useState({});
    const [accordions, setAccordions] = useState();
    const [scrollCallback, setScrollCallback] = useState(()=>()=>null);

    useEffect(() => {
        const featSpecTerms = props.featSpec?.split('-');
        let scrollTermsObj = {};
        if (featSpecTerms?.length == 1) {
            scrollTermsObj.outer = featSpecTerms[0];
        }
        if (featSpecTerms?.length == 2) {
            scrollTermsObj.outer = featSpecTerms[0];
            scrollTermsObj.inner = featSpecTerms[1];
        }
        setScrollTerms(scrollTermsObj);
    }, []);

    useEffect(() => {
        let accordionsList = [];
        let scrollExpand = false;
        if (props.sections) {
            if (Array.isArray(props.sections)) {
                accordionsList = props.sections.map((row, index) => {
                    scrollExpand = scrollTerms.outer == adjustStringForUrl(row.name);
                    return ({
                        title: toTitleCase(row.name),
                        body: getComparisonRowContent(row.name, row.items, scrollExpand),
                        key: `${row.name}.${index}`,
                        scrollExpand: scrollExpand,
                    })
                });
            } else {
                accordionsList = Object.keys(props.sections).map((row, index) => {
                    scrollExpand = scrollTerms.outer == adjustStringForUrl(row)
                    return ({
                        title: toTitleCase(row),
                        body: getComparisonRowContent(row, props.sections[row], scrollExpand),
                        key: `${row}.${index}`,
                        scrollExpand: scrollExpand,
                    })
                });
            }
        }
        setAccordions(accordionsList);
    }, [props.sections]);
  
const getComparisonRowContent = (name, data, outerScrollExpand) => {
  let items = [];
  if (data) {
    items = data.map((item, index) => {
      const features = item.featureSpecs.map((feat, ind) => (
        <VehiclesComparisonFeature key={`feat-${ind}`}
            feature={feat}
            isFeature={props.isFeatureSection}
            selection={props.selection}
            disclaimerCallback={props.disclaimerCallback}
            glossaryTerms={props.glossaryTerms}
            glossaryData={props.glossaryData}
            analyticsData={props.glossaryAnalytics} />
      ));

    const foundTerm = scrollTerms.inner && item.name && adjustStringForUrl(scrollTerms.inner) == adjustStringForUrl(item.name) && outerScrollExpand;
    // pass scroll callback function to accordion to scroll inside of accordion row content after expanding and scrolling main accordion row via scrollExpand
    foundTerm && setScrollCallback(()=>()=>scrollRef.current.scrollIntoView({ behavior: "smooth" }));

      return (
        <div className="item" key={item.name || `${name}-item-${index}`}>
            {item.name && <div ref={foundTerm ? scrollRef : null} className="item-title">
                    <div className="medium-6 medium-offset-6">
                        {item.name}
                    </div>
                </div>}
            {features}
        </div>
      );
    });
  }
  return items;
}

  return (
    <Accordion
        headerTitle={<FormattedMessage id={props.title} defaultMessage="Features" />}
        rows={accordions}
        scrollCallback={scrollCallback}
    />
  );
}

export default ComparisonSection;