import React, { useEffect, useState } from "react";
import FAQs from "../../components/pages/FAQs";
import getMisc from "../../services/miscService";
import { postViewPageAction } from '../../services/nitroService';
import { xpFAQsPageLoad } from "./engageXPFAQs.analytics";
import { tabClick } from "./engageXPFAQs.analytics";

const EngageXPFAQs = (props, analyticsData) => {
  const [availableFilters, setAvailableFilters] = useState([]);
  const [filteredFaqs, setFilteredFaqs] = useState([]);
  const [data, setData] = useState({});
  const [isLoadingData, setIsLoadingData] = useState(true);

  useEffect(() => {
    fetchPageData();
  }, []);

  useEffect(() => {
    if (props.analyticsData) {
        postViewPageAction(window.location.href);
        xpFAQsPageLoad(props.analyticsData.pageLoad?.faqs);
    }
}, [props.analyticsData]);

  const fetchPageData = async () => {
    try {
      const data = await getMisc("nitroOverview");
      setData(data?.items);
      setIsLoadingData(false);
    } catch (error) {
      console.error(`Failed to get nitroOverview page data: ${error}`);
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    if (!isLoadingData) {
      const availableFiltersFromProps = data.faqs.filters;
      const faqsFromProps = data.faqs.questions
        ? data.faqs.questions
        : data.faqs;
      setAvailableFilters(availableFiltersFromProps);
      setFilteredFaqs(faqsFromProps);
      postViewPageAction(window.location.href);
    }
  }, [isLoadingData]);

  const faqsTabClick = (link, filterId) => {
    if (props?.analyticsData) {
      const clickAnalytics = props.analyticsData.clickAnalytics;
      const label = filterId;
      tabClick(clickAnalytics, label);
    }
  }


  return (
    <FAQs
      clickEvent={faqsTabClick}
      //disclaimer={'engageXP'}
      faqs={filteredFaqs}
      filters={availableFilters}
      languageOverride={props.languageOverride}
      urlPath={"/engage-xp/faqs"}
      analyticsData={props.analyticsData}
    />
  );
};

export default EngageXPFAQs;
