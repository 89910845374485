import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import AppSettings from '../../appsettings';
import '../../styles/css/backToMission.css';
import { backToMissionClick } from '../../screens/engageXP/engageXPMissionDetail.analytics';
import getAnalytics from '../../services/analyticsService';

const BackToMission = (props) => {
    const [isVisible, setIsVisible] = useState(false);
    const location = useLocation();
    const [analyticsData, setAnalyticsData] = useState(null);
    const [backgroundImage, setBackgroundImage] = useState('backToMissionRectangleDesktop');

    const isXPTournamentPage = useMemo(() => {
        const missionDetailsPage = sessionStorage.getItem('missionDetailsPage');
        return missionDetailsPage?.includes('engage-xp/tournaments');
    }, [sessionStorage.getItem('missionDetailsPage')])

    const handleClick = () => {
        if (analyticsData) {
            const clickAnalytics = analyticsData?.clickAnalytics;
            const contentTitle = sessionStorage.getItem('missionName');

            backToMissionClick({
                ...clickAnalytics,
                page: isXPTournamentPage ? contentTitle : clickAnalytics.page
            }, contentTitle);
        }
       window.location.href = sessionStorage.getItem('missionDetailsPage');
    };

    const handleGetButtonBackground = () => {
        // TOURNAMENT
        if (isXPTournamentPage) {
            setBackgroundImage('backToTournamentMissionRectangleDesktop');
            return false;
        }

        // default
        setBackgroundImage('backToMissionRectangleDesktop');
    }

    useEffect(() => {
        setIsVisible(sessionStorage.getItem('showBackToMission') === 'true');
    }, [sessionStorage.getItem('showBackToMission')])

    useEffect(() => {
        const shouldShowBackButton = sessionStorage.getItem('missionDestinationPage')?.includes(location?.pathname);

        if (!shouldShowBackButton) {
            sessionStorage.setItem('showBackToMission', 'false');
            setIsVisible(false);
        }

        if (shouldShowBackButton) {
            handleGetButtonBackground();
        }
    }, [location]);

    useEffect(() => {
        getAnalytics("engageXP")
          .then((data) => {
            setAnalyticsData(data);
          })
          .catch((error) => {
            console.log(`ERROR: failed to load XP Intro analytics data. ${error}`);
          });
      }, []);
    

    return (
        <div className='back-to-misison-container' 
             style={{
                visibility: isVisible ? 'visible' : 'hidden',
                backgroundImage: `url(${AppSettings.AWSImgRepo.resources}en/${backgroundImage}.png)`,
             }} 
             onClick={() => handleClick()}
        >
            <img className='back-to-mission-arrow' src={`${AppSettings.AWSImgRepo.resources}en/backToMissionArrow.png`} alt='Back Arrow'/>
            <div className='back-to-mission-text'>Back to Mission</div>
        </div>
    );
};


export default BackToMission;
