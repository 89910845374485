import { GlobalAnalytics } from '../../../analytics';

const analytics = new GlobalAnalytics();

const contentLinkClick = (tag, section, subsection, technologyName, page, category, positionTitle, contentTitle) => {
  analytics.fireOnClick({
    tag,
    section,
    subsection,
    technologyName,
    page,
    category,
    positionTitle,
    contentTitle,
  });
};

export const linkClick = (clickAnalytics, contentTitle) => {
  clickAnalytics && clickAnalytics.supportLinkClick && contentTitle && contentLinkClick(
    clickAnalytics.supportLinkClick.tagId,
    clickAnalytics.supportLinkClick.section,
    clickAnalytics.supportLinkClick.subsection,
    clickAnalytics.supportLinkClick.technologyName,
    clickAnalytics.supportLinkClick.page,
    clickAnalytics.supportLinkClick.category,
    clickAnalytics.supportLinkClick.positionTitle,
    contentTitle,
  );
};

export const supportSubLoad = (pageLoadAnalytics) => {
  pageLoadAnalytics && pageLoadAnalytics.supportSubLoad && contentLinkClick(
    pageLoadAnalytics.supportSubLoad.tagId,
    pageLoadAnalytics.supportSubLoad.section,
    pageLoadAnalytics.supportSubLoad.subsection,
    pageLoadAnalytics.supportSubLoad.technologyName,
    pageLoadAnalytics.supportSubLoad.page,
    pageLoadAnalytics.supportSubLoad.category,
    pageLoadAnalytics.supportSubLoad.positionTitle,
    pageLoadAnalytics.supportSubLoad.contentTitle,
  );
};
