function getTarget(href) {
    if (href) {
        const isLinkToPdf = href.endsWith('.pdf');
        const isInternalLink = href.startsWith('/') || href.includes('engage.toyota.com');
        if (isLinkToPdf || !isInternalLink) {
            return '_blank'
        }
    }
    return '_self'
}

module.exports = getTarget;
