import 'whatwg-fetch';
import httpOption from './httpOption';
import AppSettings from '../appsettings';

export default function getVehicleOverview(modelName, modelYear, mockResponse, languageOverride) {
  const mockedVehicleOverview = {
    header: {
      headline: 'Eye Candy That Feeds Your Desire',
      subheadline: '$00,000 STARTING MSRP',
      textPlacement: 'Top Left',
      textBackground: '',
      imgUrl: 'http://article.images.consumerreports.org/prod/content/dam/CRO%20Images%202017/Cars/January/CR-Cars-Inline-2018-Toyota-Camry-r-01-17',
    },
    sections: [{
      collapseGroup: 1,
      rowLayout: 'Full-Width',
      imgUrl: 'https://s.blogcdn.com/slideshows/images/slides/434/084/6/S4340846/slug/l/2018-toyota-camry-camry-hybrid-01-560fd77cf2e4df16d8cfb115d1-2.jpg',
      mobileImgUrl: 'https://s.blogcdn.com/slideshows/images/slides/434/084/6/S4340846/slug/l/2018-toyota-camry-camry-hybrid-01-560fd77cf2e4df16d8cfb115d1-2.jpg',
      altText: '',
      backgroundColor: '',
      textColor: 'Black',
      title: '**NEW** for 2018',
      text: 'The 2018 Camry demands to be seen and driven.',
      textPlacement: 'Top-Center',
    },
    {
      collapseGroup: 1,
      rowLayout: '1/3 Text (Right)',
      imgUrl: 'https://media.ed.edmunds-media.com/toyota/camry/2018/oem/2018_toyota_camry_sedan_base_f_oem_2_1280.jpg',
      mobileImgUrl: 'https://media.ed.edmunds-media.com/toyota/camry/2018/oem/2018_toyota_camry_sedan_base_f_oem_2_1280.jpg',
      altText: '',
      backgroundColor: 'Black',
      textColor: 'White',
      title: '2018 **Camry**',
      text: "**Prepare for the stares.** That's because it's tough to ignore the all-new 2018 Toyota Camry -- it demands to be seen, and driven, with its fearless new look and seductive performance. Years of perfecting our beloved best-seller has allowed us to create this exhilarating drive that puts you in the driver’s seat like never before. It's time to go more places in complete confidence - and always arrive fashionably great.",
      textPlacement: 'Middle-Left',
    },
    {
      collapseGroup: 1,
      rowLayout: 'Full-Width',
      imgUrl: '',
      mobileImgUrl: '',
      altText: '',
      backgroundColor: 'White',
      textColor: 'Black',
      title: 'Expressive **Design**',
      text: '',
      textPlacement: 'Top-Left',
    },
    {
      collapseGroup: 1,
      rowLayout: '3-Column',
      imgUrl: 'http://toyotacamryusa.com/wp-content/uploads/2017/01/2018-Toyota-Camry-XSE-Concept.jpg',
      mobileImgUrl: 'http://toyotacamryusa.com/wp-content/uploads/2017/01/2018-Toyota-Camry-XSE-Concept.jpg',
      altText: '',
      backgroundColor: 'White',
      textColor: 'Black',
      title: 'Ut enim ad',
      text: 'Lorem ipsum dolor sit amet, consectetur adipscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim venaim, quis nostrud exercitation ullam.',
      textPlacement: 'Bottom-Left',
    },
    {
      collapseGroup: 1,
      rowLayout: '3-Column',
      imgUrl: 'http://st.automobilemag.com/uploads/sites/11/2016/12/2018-Toyota-Camry-Teaser-660x421.jpeg',
      mobileImgUrl: 'http://st.automobilemag.com/uploads/sites/11/2016/12/2018-Toyota-Camry-Teaser-660x421.jpeg',
      altText: '',
      backgroundColor: 'White',
      textColor: 'Black',
      title: 'Lorom ipsum dolor',
      text: 'Lorem ipsum dolor sit amet, consectetur adipscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim venaim, quis nostrud exercitation ullam.',
      textPlacement: 'Bottom-Left',
    },
    {
      collapseGroup: 1,
      rowLayout: '3-Column',
      imgUrl: 'http://mihelson.tv/upload/iblock/7ca/7cab54a8fbb481566a8345d291fd78c3.jpg',
      mobileImgUrl: 'http://mihelson.tv/upload/iblock/7ca/7cab54a8fbb481566a8345d291fd78c3.jpg',
      altText: '',
      backgroundColor: 'White',
      textColor: 'Black',
      title: 'Et enim ad',
      text: 'Lorem ipsum dolor sit amet, consectetur adipscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim venaim, quis nostrud exercitation ullam.',
      textPlacement: 'Bottom-Left',
    },
    {
      collapseGroup: 1,
      rowLayout: '1/3 Text (Right)',
      imgUrl: 'https://img.4plebs.org/boards/o/image/1483/98/1483989746815.jpg',
      mobileImgUrl: 'https://img.4plebs.org/boards/o/image/1483/98/1483989746815.jpg',
      altText: '',
      backgroundColor: 'Black',
      textColor: 'White',
      title: 'Advanced **Technologies**',
      text: '**Entune 3.0** Lorem ipsom dolor sit amet, csectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna allqua. Ut nostrud execritation ullam. **Toyota Safety Sense P** The quick brown fox jumped over the lazy dog.',
      textPlacement: 'Middle-Left',
    },
    {
      collapseGroup: 1,
      rowLayout: 'Full-Width',
      imgUrl: 'http://article.images.consumerreports.org/prod/content/dam/CRO%20Images%202017/Cars/January/CR-Cars-Inline-2018-Toyota-Camry-r-01-17.jpg',
      mobileImgUrl: 'http://article.images.consumerreports.org/prod/content/dam/CRO%20Images%202017/Cars/January/CR-Cars-Inline-2018-Toyota-Camry-r-01-17.jpg',
      altText: '',
      backgroundColor: 'Black',
      textColor: 'White',
      title: 'Seductive **Performance**',
      text: 'Three new powertrains will be available on the new 2018 Camry: They include a new 3.5 liter V6 with a D-4S Fuel injection and an all new 2.5 liter inline-4 gasoline engine, which comes paired to a new 8-speed Direct Shift automatic transition (8AT), and a next generation Toyota hybrid system (THS II).',
      textPlacement: 'Top-Center',
    },
    {
      collapseGroup: 2,
      rowLayout: 'Full-Width',
      imgUrl: 'https://s-media-cache-ak0.pinimg.com/originals/09/d4/e9/09d4e9105b9b9d9accb414e37b9e41c4.jpg',
      mobileImgUrl: 'https://s-media-cache-ak0.pinimg.com/originals/09/d4/e9/09d4e9105b9b9d9accb414e37b9e41c4.jpg',
      altText: '',
      backgroundColor: 'Black',
      textColor: 'White',
      title: 'Sales Story',
      text: "**Prepare for the stares.** That's because it's tough to ignore the all-new 2018 Toyota Camry -- it demands to be seen, and driven, with its fearless new look and seductive performance. Years of perfecting our beloved best-seller has allowed us to create this exhilarating drive that puts you in the driver’s seat like never before.",
      textPlacement: 'Top-Center',
    },
    {
      collapseGroup: 2,
      rowLayout: '1/3 Text (Right)',
      imgUrl: 'http://2018carsreview.com/wp-content/uploads/2017/01/2018-Toyota-Camry-side.jpg',
      mobileImgUrl: 'http://2018carsreview.com/wp-content/uploads/2017/01/2018-Toyota-Camry-side.jpg',
      altText: '',
      backgroundColor: 'White',
      textColor: 'Black',
      title: 'More Sales and Stuff',
      text: 'Get more **sales** for the Camry 2018',
      textPlacement: 'Top-Right',
    },
    ],
    awards: [{
      subheadline: 'The 2017 Toyota Camry is an IIHS TSP+ when equipped with the original Pre-Collision System and Halogen headlights',
      imgUrl: 'https://www.toyota.com/imgix/responsive/images/awards/landing/2017_TSPplus_badge_box.png?w=475',
    },
    {
      subheadline: '2017 Toyota Camry Received the NHTSA 5-Star Overall Safety Rating4',
      imgUrl: 'https://www.toyota.com/imgix/responsive/images/awards/industry-accolade-generic.png?w=475',
    },
    {
      subheadline: 'Over 90% of all Toyota Camrys sold over the Last Ten Years Are Still on the Road Today',
      imgUrl: 'https://www.toyota.com/imgix/responsive/images/awards/industry-accolade-generic.png?w=475',
    },
    {
      subheadline: '2016 Toyota Camry Was Named a Best Buy Award Finalist by Kelly Blue Book KBB.com',
      imgUrl: 'https://www.toyota.com/imgix/responsive/images/awards/intellichoice-generic.png?w=475',
    },
    {
      subheadline: 'The 2017 Toyota Camry is an IIHS TSP+ when equipped with the original Pre-Collision System and Halogen headlights',
      imgUrl: 'https://www.toyota.com/imgix/responsive/images/awards/landing/2017_TSPplus_badge_box.png?w=475',
    },
    {
      subheadline: '2017 Toyota Camry Received the NHTSA 5-Star Overall Safety Rating4',
      imgUrl: 'https://www.toyota.com/imgix/responsive/images/awards/industry-accolade-generic.png?w=475',
    },
    {
      subheadline: 'Over 90% of all Toyota Camrys sold over the Last Ten Years Are Still on the Road Today',
      imgUrl: 'https://www.toyota.com/imgix/responsive/images/awards/industry-accolade-generic.png?w=475',
    },
    {
      subheadline: '2016 Toyota Camry Was Named a Best Buy Award Finalist by Kelly Blue Book KBB.com',
      imgUrl: 'https://www.toyota.com/imgix/responsive/images/awards/intellichoice-generic.png?w=475',
    },
    {
      subheadline: 'The 2017 Toyota Camry is an IIHS TSP+ when equipped with the original Pre-Collision System and Halogen headlights',
      imgUrl: 'https://www.toyota.com/imgix/responsive/images/awards/landing/2017_TSPplus_badge_box.png?w=475',
    },
    {
      subheadline: '2017 Toyota Camry Received the NHTSA 5-Star Overall Safety Rating4',
      imgUrl: 'https://www.toyota.com/imgix/responsive/images/awards/industry-accolade-generic.png?w=475',
    },
    {
      subheadline: 'Over 90% of all Toyota Camrys sold over the Last Ten Years Are Still on the Road Today',
      imgUrl: 'https://www.toyota.com/imgix/responsive/images/awards/industry-accolade-generic.png?w=475',
    },
    {
      subheadline: '2016 Toyota Camry Was Named a Best Buy Award Finalist by Kelly Blue Book KBB.com',
      imgUrl: 'https://www.toyota.com/imgix/responsive/images/awards/intellichoice-generic.png?w=475',
    },
    ],
  };

  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/vehicle/overview/${modelName}/${modelYear}`;
  const options = httpOption.GET();
  options.headers['accept-language'] = languageOverride || options.headers['accept-language'];

  return fetch(url, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return mockResponse ? Promise.resolve(mockedVehicleOverview) : Promise.resolve(response.json());
      }
      return Promise.reject(response);
    });
}
