import React from 'react';
import applyMarkdown from '../../../../utils/applyMarkdown.js';
import '../../../../../styles/css/headToHead.css';
import advantageIcon from '../../../../../resources/images/advantage-icon.png';
import AppSettings from '../../../../../appsettings.js';

export default function KeyFeaturesV2(props) {
  const KeyFeaturesTableRowsMobile = (props) => {
    const specsArray = props.item.keyFeatures.features && props.item.keyFeatures.features.map((feature, index) => {
      // don't map the header or footnote as a table row
      if (!feature.toyotaHeader && !feature.footnote) {
        return (
                    <tr className="cto" key={index}>
                        <td className="full-width-header columns small-12">
                          {applyMarkdown(feature.featureName, props.disclaimer)}
                        </td>
                        <td className="sm-col columns small-6">
                            {feature.toyotaAdvantage
                              && <span>
                                    <img src={advantageIcon} className="advantage-icon" />

                                </span>}
                            &nbsp;{applyMarkdown(feature.toyotaData, props.disclaimer)}</td>
                        <td className="sm-col columns small-6 col2">
                            {feature.competitorAdvantage
                             && <span>
                                    <img src={advantageIcon} className="advantage-icon" />
                                    &nbsp;
                            </span>}
                            {applyMarkdown(feature.competitorData, props.disclaimer)}</td>
                        <td className="sm-line columns small-12"></td>
                    </tr>
        );
      }

      return null;
    });
    return <tbody><tr />{specsArray}</tbody>;
  };


  const KeyFeaturesTableRowsDesktop = (props) => {
    const keyFeaturesArray = props.item.keyFeatures.features && props.item.keyFeatures.features.map((feature, index) => {
      if (!feature.toyotaHeader && !feature.footnote) {
        return (
                    <tr className="lg-row" key={index}>
                        <td className="lg-title key-feature-title">
                            <img src={AppSettings.AWSImgRepo.edge + props.s3Path + feature.featureIcon} className="key-features-image"/>
                            {applyMarkdown(feature.featureName, props.disclaimer)}
                        </td>
                        <td className="lg-col">
                            {feature.toyotaAdvantage
                              && <span>
                                    <img src={advantageIcon} className="advantage-icon" />
                                    &nbsp;
                            </span>}
                            {applyMarkdown(feature.toyotaData, props.disclaimer)}
                        </td>
                        <td className="lg-col">
                            {feature.competitorAdvantage
                              && <span>
                                    <img src={advantageIcon} className="advantage-icon"/>
                                    &nbsp;
                            </span>}
                            {applyMarkdown(feature.competitorData, props.disclaimer)}
                        </td>
                    </tr >
        );
      }

      return null;
    });
    return <tbody><tr />{keyFeaturesArray}</tbody>;
  };

  const keyFeaturesTableDesktop = [];
  // const tableHeaders = getTableHeaders(props.specsContent);
  keyFeaturesTableDesktop.push(
        <table className="cost-to-own-table-desktop">
            <tr className="lg-row">
                <td />
            </tr>
            <KeyFeaturesTableRowsDesktop disclaimer={props.disclaimer} item={props.content} s3Path={props.s3Path} />
        </table>,
  );

  const keyFeaturesTableMobile = [];
  keyFeaturesTableMobile.push(
    <table className='cost-to-own-table-mobile'>
        <KeyFeaturesTableRowsMobile disclaimer={props.disclaimer} item={props.content} s3Path={props.s3Path} />
    </table>,
  );

  return (
        <div className="new-cost-to-own">
            <div className="cto-body">
                <div className="cto">
                    <div className="row" />
                    <div className="show-for-large">{keyFeaturesTableDesktop}</div>
                    <div className="show-for-small-only">{keyFeaturesTableMobile}</div>
                    <div className="show-for-medium-only">{keyFeaturesTableMobile}</div>
                </div>
            </div>
            <div className="advantage-footnote-container">
                <img src={advantageIcon} className="advantage-icon" />
                &nbsp;
                    <span>Advantage</span>
            </div>
        </div>
  );
}
