import { useState, useContext, useEffect, useCallback } from 'react';
import CalculatorCard from './towingPayload-calculatorCard';
import GcwrGauge from './towingPayload-gcwrGauge';
import GaugeSectionModal from './towingPayload-gaugeSectionModal';
import '../../../styles/css/towingPayload-calculator.css';
import isSafari from '../../../screens/utils/browserDetectors';
import AppSettings from '../../../appsettings';
import { PopupTooltipContext } from '../../../components/contexts/popupTooltipContext';
import { getS3PathName } from '../../utils/vehicleName';

const GaugeSection = (props) => {
    const [s3ModelNamePath, setS3ModelNamePath] = useState('');
    const { visiblePopupTooltip, setVisiblePopupTooltip, setCurrentPopupTooltip, checkCurrentTooltipMatch } = useContext(PopupTooltipContext);

    const getS3ModelNameForJellies = useCallback(async () => {
        const { modelName, modelYear } = props.vehicleSelection;
        const s3PathName = await getS3PathName(modelName);
        setS3ModelNamePath(AppSettings.AWSImgRepo.vehicle + modelYear + '/' + s3PathName + '/en/');
    }, [props.vehicleSelection])

    useEffect(() => {
        getS3ModelNameForJellies();
    }, [getS3ModelNameForJellies])

    const cleanValue = (value) => {
        let cleanedValue = value;
        if(cleanedValue.includes("–")) {
            cleanedValue = cleanedValue.split(' /')[0];
        } else if (cleanedValue.includes("/")) {
            cleanedValue = cleanedValue.split('/ ')[1];
        }
        if (cleanedValue.includes(",")) {
            cleanedValue = cleanedValue.replace(/,/g, '');
        }
        return parseInt(cleanedValue);
    };

    const curbWeight = (typeof(props.vehicle.curbWeight) === 'string') ? cleanValue(props.vehicle.curbWeight) : props.vehicle.curbWeight;
    const maxPayload = (typeof(props.vehicle.payloadCapacity) === 'string') ? cleanValue(props.vehicle.payloadCapacity) : props.vehicle.payloadCapacity;
    const maxTowing = (typeof(props.vehicle.towingCapacity) === 'string') ? cleanValue(props.vehicle.towingCapacity) : props.vehicle.towingCapacity;
    const gcwr = (typeof(props.vehicle.GCWR) === 'string') ? cleanValue(props.vehicle.GCWR) : props.vehicle.GCWR;

    const totalPayload = Math.ceil(props.payload + (props.towing * 0.10));
    const segmentPayload = curbWeight + totalPayload;
    const segmentTowing = segmentPayload + props.towing;

    const checkCalcError = () => {
        return (props.towing > maxTowing || totalPayload > maxPayload || segmentTowing > gcwr);
    };

    const GaugeSectionTitle = () => {
        if (checkCalcError()) {
           return <div className="gauge-section-header gauge-section-header-error">You have exceeded the max capacity for this vehicle.</div>;
        }
        if (props.expertMode) {
            return <div className="gauge-section-header">Enter your payload and towing weights below.</div>;   
        }
        return <div className="gauge-section-header"></div>;
    };

    const BeginnerPopup = () => {
        if (!props.expertMode && visiblePopupTooltip == "beginnerMessage") {
            const data = props.miscText.filter(obj => obj.name === "BeginnerModeNote")[0];
            const safariMargin = isSafari() ? "beginner-popup-safari-margin" : "";
            return (
                <div className={`gauge-section-beginner-popup ${safariMargin}`}>
                    <span>{data.text1}</span>
                    <img className="beginner-popup-close" src={props.imagePath + "close-x.png"} onClick={() => setVisiblePopupTooltip(null)}/>
                </div>
            );
        }
        return null;
    };

    return (
        <div className="gauge-section">
            <GaugeSectionTitle />
            <div className={"gauge-section-row"}>
                <CalculatorCard
                    title={"Curb Weight"}
                    value={curbWeight}
                    termKey={"curbWeight"}
                    infoPopUpData={props.infoPopUpData.curbWeight}
                    isImg={true}
                    imagePath={props.imagePath}
                    modelName={props.modelName}
                    rotatedJelly={s3ModelNamePath + props.vehicle.filterImage}
                    setBeginnerPopupVisible={null}
                    setVisiblePopupTooltip={setVisiblePopupTooltip}
                    visiblePopupTooltip={visiblePopupTooltip}
                    setCurrentPopupTooltip={setCurrentPopupTooltip}
                    checkCurrentTooltipMatch={checkCurrentTooltipMatch}
                    clickAnalytics={props.clickAnalytics}
                    vehicleSelection={props.vehicleSelection}
                    trimSelection={props.vehicle}
                />
                <CalculatorCard
                    title={"Payload"}
                    maxValue={maxPayload}
                    value={totalPayload}
                    payload0={props.payload}
                    termKey={"payload"}
                    infoPopUpData={props.infoPopUpData.payload}
                    setValue={props.setPayload}
                    resetTowing={props.setTowing}
                    error={checkCalcError()}
                    imagePath={props.imagePath}
                    expertMode={props.expertMode}
                    setVisiblePopupTooltip={setVisiblePopupTooltip}
                    visiblePopupTooltip={visiblePopupTooltip}
                    setCurrentPopupTooltip={setCurrentPopupTooltip}
                    checkCurrentTooltipMatch={checkCurrentTooltipMatch}
                    importantNote={props.miscText.filter(obj => obj.name === "ImportantNote")[0]}
                    vehicleSelection={props.vehicleSelection}
                    clickAnalytics={props.clickAnalytics}
                    trimSelection={props.vehicle}
                />
                <CalculatorCard
                    title={"Towing"}
                    maxValue={maxTowing}
                    value={props.towing}
                    termKey={"towingCapacity"}
                    infoPopUpData={props.infoPopUpData.towingCapacity}
                    setValue={props.setTowing}
                    error={checkCalcError()}
                    imagePath={props.imagePath}
                    expertMode={props.expertMode}
                    setVisiblePopupTooltip={setVisiblePopupTooltip}
                    visiblePopupTooltip={visiblePopupTooltip}
                    setCurrentPopupTooltip={setCurrentPopupTooltip}
                    checkCurrentTooltipMatch={checkCurrentTooltipMatch}
                    vehicleSelection={props.vehicleSelection}
                    clickAnalytics={props.clickAnalytics}
                    trimSelection={props.vehicle}
                />
                <GcwrGauge
                    curbWeight={curbWeight}
                    gcwr={gcwr}
                    payload={props.payload}
                    segmentPayload={segmentPayload}
                    towing={props.towing}
                    segmentTowing={segmentTowing}
                    error={checkCalcError()}
                    imagePath={props.imagePath}
                    infoPopUpData={props.infoPopUpData.gcwr}
                    setVisiblePopupTooltip={setVisiblePopupTooltip}
                    visiblePopupTooltip={visiblePopupTooltip}
                    setCurrentPopupTooltip={setCurrentPopupTooltip}
                    checkCurrentTooltipMatch={checkCurrentTooltipMatch}
                    vehicleSelection={props.vehicleSelection}
                    clickAnalytics={props.clickAnalytics}
                    trimSelection={props.vehicle}
                />
                <BeginnerPopup />
            </div>
            <GaugeSectionModal 
                error={checkCalcError()}
                imagePath={props.imagePath}
                modalData={props.miscText.filter(obj => obj.name === "CapacityExceeded")[0]}
                vehicleSelection={props.vehicleSelection}
                trimSelection={props.vehicle}
                pageLoadAnalytics={props.pageLoadAnalytics}
                clickAnalytics={props.clickAnalytics}
                vehicles={props.vehicles}
                payloadValue={props.payload}
                towingValue={props.towing}
                totalPayload={totalPayload}
                handleNavClick = {props.handleNavClick}
                infoPopUpData={props.infoPopUpData}
            />
        </div>
    );
};

export default GaugeSection;