import { GlobalAnalytics } from '../../../analytics';

const analytics = new GlobalAnalytics();

export function sendPageLoadAnalytics() {
  analytics.firePageLoad({
    tag: '35.1',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'Toyota Safety Sense™',
    page: 'TSS Lineup',
  });
}

export function sendYearSelectionAnalytics(year) {
  analytics.fireOnClick({
    tag: '35.2',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'Toyota Safety Sense',
    page: 'TSS Lineup',
    category: 'Year Dropdown',
    mediaTitle: ' ',
    contentTitle: year,
  });
}


export function sendModelSelectionAnalytics(model) {
  analytics.fireOnClick({
    tag: '35.2',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'Toyota Safety Sense',
    page: 'TSS Lineup',
    category: 'Model Dropdown',
    mediaTitle: ' ',
    contentTitle: model,
  });
}


export function sendTrimSelectionAnalytics(trim) {
  analytics.fireOnClick({
    tag: '35.2',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'Toyota Safety Sense',
    page: 'TSS Lineup',
    category: 'Trim Dropdown',
    mediaTitle: ' ',
    contentTitle: trim,
  });
}

export function sendSubmitClickAnalytics(buttonText) {
  analytics.fireOnClick({
    tag: '35.2',
    section: 'Product',
    subsection: 'Featured Technology',
    technologyName: 'Toyota Safety Sense',
    page: 'TSS Lineup',
    category: 'Submit',
    mediaTitle: ' ',
    contentTitle: buttonText,
  });
}
