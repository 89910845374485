import React from 'react';

import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import '../../../styles/css/searchBar.css';
import '../../../styles/css/searchColorAvailability.css'
import AppSettings from '../../../appsettings.js';
import { getModelNameForSearchResult, getS3PathName } from '../../utils/vehicleName';
import {
  filterColors,
  getEngineOptions,
  generateOptionsDropdown,
  getTrimOptions,
  filterTrims,
  optionsDropdownAnalytics,
  colorPickerAnalytics,
  generateLearnMoreButton,
} from '../resultHelperFunctions.js';

export class ColorByTrimSearchItemComponent extends React.Component {
  constructor(props) {
    super(props);

    const engineSet = [...new Set(props.item.values.map(x => x.engine))];
    const trimsSet = filterTrims(props.item.values, engineSet[0]);
    const colorMap = new Map();
    if (props.item && props.item.values) {
      for (const key in props.item.values) {
        const item = props.item.values[key];
        colorMap.set(item.trim, item.values);
      }
    }
    const colorSet = colorMap.get(trimsSet[0].trim);

    this.state = {
      year: props.item.year,
      model: props.item.model,
      trims: trimsSet,
      engines: engineSet,
      colors: colorSet,
      colorMap,
      imageToDisplay: null,
      textToDisplay: null,
      selectedTrimValue: 0,
      selectedEngineValue: engineSet[0],
      selectedColor: null,
    };

    this.generateImage = this.generateImage.bind(this);
    this.updateSelectedColorEventHandler = this.updateSelectedColorEventHandler.bind(this);
    this.updateSelectedTrimValue = this.updateSelectedTrimValue.bind(this);
    this.updateSelectedEngineValue = this.updateSelectedEngineValue.bind(this);
  }

  createColorByTrimResult() {
    const engineOptions = getEngineOptions(this.props.item.values);
    const trimOptions = getTrimOptions(this.state.trims);
    const colorsContainer = this.generateColors(this.updateSelectedColorEventHandler);
    const engineDropdown = generateOptionsDropdown(engineOptions, this.updateSelectedEngineValue, 'Engine');
    const trimDropdown = generateOptionsDropdown(trimOptions, this.updateSelectedTrimValue, 'Trim');
    const learnMoreButton = generateLearnMoreButton(this.props.item, this.state, this.props.parentComponent.state.analyticsData.clickAnalytics, this.props.parentComponent.state.searchParam, this.props.parentComponent.state.totalResults);

    return (
      <div className="item-content">
        <div className="content-header">{this.props.item.descriptionToShow}</div>
        <div className="content-dropdowns">
          {engineDropdown}
          {trimDropdown}
        </div>
        <div className="content-output">
          <img className="color-output-image" src={this.state.imageToDisplay ? this.state.imageToDisplay : null} />
            <div className="color-output-text">{this.state.trims[this.state.selectedTrimValue].trim}</div>
            <fieldset className="swatch-picker">
              {colorsContainer}
            </fieldset>
        </div>
        <div className="quickAnswersSeeMore">
          {learnMoreButton}
        </div>
      </div>
    );
  }

  updateSelectedTrimValue(value) {
    const selectedTrimName = this.state.trims[value].trim;
    const filteredColors = filterColors(this.state.colorMap, selectedTrimName);
    optionsDropdownAnalytics(selectedTrimName, 'Trim', this.props.item, this.props.parentComponent.state.analyticsData.clickAnalytics, this.props.parentComponent.state.searchParam, this.props.parentComponent.state.totalResults);
    this.setState({
      selectedTrimValue: value,
      colors: filteredColors,
    }, this.props.parentComponent.reRenderHeight);
    this.updateColorOnParameterChange(filteredColors);
  }

  updateSelectedEngineValue(value) {
    const filteredTrims = filterTrims(this.props.item.values, value);
    const filteredColors = filterColors(this.state.colorMap, filteredTrims[0].trim);
    optionsDropdownAnalytics(value, 'Engine', this.props.item, this.props.parentComponent.state.analyticsData.clickAnalytics, this.props.parentComponent.state.searchParam, this.props.parentComponent.state.totalResults);
    this.setState({
      selectedEngineValue: value,
      trims: filteredTrims,
      colors: filteredColors,
      selectedTrimValue: 0,
    }, this.props.parentComponent.reRenderHeight);
    this.updateColorOnParameterChange(filteredColors);
  }

  updateSelectedColorEventHandler(event) {
    const selectedColor = this.state.colors.find(item => item && item.paintCode === event.target.value);
    this.updateColorState(selectedColor);
    if (selectedColor !== this.state.selectedColor) {
      colorPickerAnalytics(selectedColor, this.props.item, this.props.parentComponent.state.analyticsData.clickAnalytics, this.props.parentComponent.state.searchParam, this.props.parentComponent.state.totalResults);
    }
  }

  updateColorOnParameterChange(filteredColors) {
    const selectedColor = filteredColors.find(color => color.paintCodeName === this.state.selectedColor.paintCodeName) || filteredColors[0];
    this.updateColorState(selectedColor);
  }

  async updateColorState(selectedColor) {
    if (selectedColor) {
      const imageToDisplay = await this.generateImage(selectedColor);
      let textToDisplay = `${selectedColor.paintCodeName} ${selectedColor.paintCode}`;
      if (selectedColor.paintCodeCharge) {
        textToDisplay = `${textToDisplay} - ${selectedColor.paintCodeCharge}`;
      }
      this.setState({
        selectedColor,
        imageToDisplay,
        textToDisplay,
      }, this.props.parentComponent.reRenderHeight);
    }
  }

  generateColors(updateSelectedColorEventHandler) {
    const colorsContainer = [];
    const colors = this.state.colors.sort((color1, color2) => ((color1.paintCodeName > color2.paintCodeName) ? 1 : -1));
    const { selectedColor } = this.state;

    Object.keys(colors).forEach((keyName, keyIndex) => {
      const { paintCode } = colors[keyName];
      const hexCode = colors[keyName].hexCode || AppSettings.PaintHexCodes[paintCode];
      const style = hexCode === '#FFFFFF' ? { backgroundColor: hexCode, border: 'solid black 1px' } : { backgroundColor: hexCode };
      const color = <label key={keyName}>
          <input type="radio" name={paintCode} value={paintCode} onClick={updateSelectedColorEventHandler} />
          <span style={style} />
        </label>;
      colorsContainer.push(color);
    });
    return colorsContainer;
  }

  async generateImage(selectedColor) {
    let imageName = selectedColor.image ? selectedColor.image : 'no_image_available_700x300.png';
    if (imageName[0] === '/') {
      const startIndex = imageName.lastIndexOf('/') + 1;
      imageName = imageName.slice(startIndex);
    }
    const modelName = getModelNameForSearchResult(this.state.model);
    const formattedModelName = await getS3PathName(modelName);
    return encodeURI(`${AppSettings.AWSImgRepo.vehicle}${this.state.year}/${formattedModelName}/en/${imageName}`);
  }

  componentDidMount() {
    const defaultColor = this.state.colors[0];
    this.updateColorState(defaultColor);
  }

  render() {
    return (this.createColorByTrimResult());
  }
}

ColorByTrimSearchItemComponent.propTypes = {
  item: PropTypes.object,
  parentComponent: PropTypes.object,
  disclaimerCallback: PropTypes.func,
  index: PropTypes.number,
};


export default injectIntl(ColorByTrimSearchItemComponent);
