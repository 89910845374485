import { GlobalAnalytics } from "../../analytics";

const analytics = new GlobalAnalytics();

// Page Load for My Missions
export const xpMissionsPageLoad = (pageLoad) => {
  pageLoad &&
    analytics.firePageLoad({
      app: pageLoad.appId,
      tag: pageLoad.tagId,
      section: pageLoad.section,
      page: pageLoad.page,
      subsection: pageLoad.subsection,
      technologyName: pageLoad.technology_name,
    });
};

// Page Load for Mission Details
export const xpMissionDetailsPageLoad = (pageLoad, name) => {
  pageLoad &&
    analytics.firePageLoad({
      app: pageLoad.appId,
      tag: pageLoad.tagId,
      section: pageLoad.section,
      page: name,
      subsection: pageLoad.subsection,
      technologyName: pageLoad.technology_name,
    });
};

// Mission Category dropdown (or first one on the left if that changes)
export const categoryDropdown = (clickAnalytics, pageName, contentTitle, analyticsCategory) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      app: clickAnalytics.appId,
      tag: clickAnalytics.tagId,
      section: clickAnalytics.section,
      page: pageName,
      category: analyticsCategory,
      label: "Missions Category Dropdown",
      contentTitle: contentTitle
    });
  }
};

// Missions status dropdown (or first one on the left if that changes)
export const statusDropdown = (clickAnalytics, pageName, contentTitleStatus, analyticsCategory) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: pageName,
      category: analyticsCategory,
      label: "Mission Status Dropdown",
      contentTitle: contentTitleStatus
    });
  }
};

// Click action to get to the stats page
export const statsPageButtonClick = (clickAnalytics, pageName, analyticsDashboardCategory) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: pageName,
      category: analyticsDashboardCategory,
      label: "View Full Stats Button",
      contentTitle: "View Full Stats"
    });
  }
};

// Click action to get to the how do points work page
export const howDoClick = (clickAnalytics, pageName, analyticsDashboardCategory) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: pageName,
      category: analyticsDashboardCategory,
      label: "How Do Points Work Link",
      contentTitle: "How Do Points Work"
    });
  }
};

// Click action for the check balance button click
export const dashboardImageLinkClick = (clickAnalytics, analyticsCategory, dashboardLabel) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: "Engage XP",
      category: analyticsCategory,
      label: dashboardLabel,
    });
  }
};

// Click action for leaderboard dropdown selection
export const leaderboardDropdownClick = (clickAnalytics, analyticsCategory, contentTitle) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: "Engage XP",
      category: analyticsCategory,
      label: "Leaderboard Dropdown",
      contentTitle: contentTitle
    });
  }
};