import * as _ from 'lodash';

export function isDash(string) {
  return (string === '-' || string === '–' || string === '—');
}

export function areStringsEqual(stringA, stringB) {
  const valuesToIngore = /\^\d+\^|\^TM\^|™|\^SM\^|℠|\^R\^|®|-|–/g;
  const cleanedStringA = stringA.replace(valuesToIngore, '').trim().toLowerCase();
  const cleanedStringB = stringB.replace(valuesToIngore, '').trim().toLowerCase();
  return cleanedStringA === cleanedStringB;
}

export function isVin (searchString) {
  if(/(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9]{17})$/.test(searchString)){
      return true;
  }

  return false;
}

export function removeProperty(obj, prop) {
  const object = cloneObject(obj);
  if (object) {
    delete object[prop];
  }
  return object;
}

export function cloneObject(obj) {
  if (obj === null || typeof (obj) !== 'object') return obj;

  const temp = new obj.constructor();
  for (const key in obj) temp[key] = cloneObject(obj[key]);

  return temp;
}

export function hasVideoId(brightcoveId, qumuId) {
  return !!brightcoveId || !!qumuId;
}

export function isSpecBubble(val) {
  const trimmedVal = val.trim();
  return trimmedVal === 'S' || trimmedVal === 'O' || trimmedVal === 'P' || trimmedVal === 'O/P';
}

export function toTitleCase(str) {
  const matchingRegex = /([a-zA-Z0-9_À-ú])([a-zA-Z0-9_À-ú]*(\s|$))/g;
  return str.replace(matchingRegex, (word, first, rest) => first.toUpperCase() + rest.toLowerCase());
}

export function isValidEmail(email) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}

export function isExternalHref(href) {
  const externalHrefRegex = /^(https?:\/\/)?.*(.com|.org|.gov|.net)/g;
  const engageHrefRegex = /^(engage.toyota.com)/g
  return externalHrefRegex.test(href) && !engageHrefRegex.test(href);
}

export function cleanString (string) {
  return string
    .replace(/[^a-zA-Z0-9\s-]/gi, "")
    .replace(/ and/i, "")
    .replace(/ or/i, "")
    .trim();
}
