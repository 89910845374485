import React, {useEffect, useState} from 'react';
import "../../../../styles/css/beyondZero/vehicleCarousel.css"
import CarouselV2 from '../../../../components/molecules/CarouselV2';
import VehicleCard from './VehicleCard';
import AppSettings from '../../../../appsettings';
import { useHistory } from 'react-router-dom'
import BZTabs from '../../sharedComponents/bzTabs';

const VehicleCarousel = ({electrifiedCarousel, analyticsAction}) => {
    
    const history = useHistory();
    const [tabSelected, setTabSelected] = useState(0);
    const [categories, setCategories] =  useState([]);
    const [isBeginningDisabled, setBegginingDisabled] = useState(false);
    const [isEndDisabled, setEndDisabled] = useState(false);

    const getSlidesCount = () => {
        if (window.innerWidth > 1250) {
            return 3;
        } else if (window.innerWidth > 800) {
            return 2;
        } else {
            return 1;
        }
    }

    const handleOnTabChange = (tabIndex) => {
        analyticsAction.onTabClick({
            label: 'Vehicle Carousel Tab',
            content_title: categories[tabIndex].label
        })

        setTabSelected(tabIndex);
    }

    const handlePagigationClick = (direction) => {
        setBegginingDisabled(false);
        setEndDisabled(false);
        analyticsAction.onClick({
            label: `Vehicle Carousel ${direction}`
        })
    }

    const handleVehicleClick = (vehicle) => {
        analyticsAction.onClick({
            label: `Vehicle Carousel Card Click`,
            model_name: vehicle.year,
            model_year: vehicle.model
        })

        window.location.href = vehicle.overviewLink;
    }

    useEffect(()=>{
        const tabItems = electrifiedCarousel?.map((e, index) => {
            return {
                value: index,
                label: e.category
            }
        }
        );
        setCategories(tabItems);
    }, [electrifiedCarousel])

    return (
        <div className="bz-vc-page">
            <div className="bz-vc-container">
                <div className="bz-vc-tab-container">
                    <BZTabs
                    variant='light'
                        primaryColor={'#fff'}
                        active={tabSelected}
                        onClick={handleOnTabChange}
                        items={categories}
                        showDropDownOnMobile={false}
                    />
                </div>
                <div className="bz-vc-carousel-container">
                    <CarouselV2
                        onReachEnd={() => setEndDisabled(true)}
                        onReachBeginning={() => setBegginingDisabled(true)}
                        spaceBetween={21}
                        slidesCount={getSlidesCount()}
                        navigation={true}
                        prevArrow={<div className={`circleArrow prev ${isBeginningDisabled ? 'disabled' : ''}`} onClick={() => handlePagigationClick('Prev')}> <img src={AppSettings.AWSImgRepo.beyondZero + 'bz-arrow.png'} alt='Previous'/></div>}
                        nextArrow={<div className={`circleArrow next ${isEndDisabled ? 'disabled': ''}`} onClick={() => handlePagigationClick('Next')}> <img src={AppSettings.AWSImgRepo.beyondZero + 'bz-arrow.png'} alt='Next'/></div>}
                        items={
                            electrifiedCarousel?.[tabSelected]?.cards?.map((e, index) =>
                                <VehicleCard
                                    key={index}
                                    year={e.year}
                                    model={e.model}
                                    imageUrl={e.image}
                                    onClick={() => handleVehicleClick(e)}
                                />
                            ) || []
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default VehicleCarousel;