import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FlatIconCard from '../../../components/molecules/cards/flatIconCard';
import applyMarkdown from '../../utils/applyMarkdown';

const certificationSupportContacts = (props) => (
  <div className="support-contacts">
    {props.data.map(supportContact => (
      <div key={supportContact.title} className="support-contact">
        <FlatIconCard
          contact={supportContact.contact}
          description={applyMarkdown(supportContact.description, props.disclaimer)}
          iconFile={supportContact.image}
          imagePath={props.imagePath}
          title={supportContact.title}
        />
      </div>
    ))}
  </div>
);

certificationSupportContacts.propTypes = {
  data: PropTypes.object,
  disclaimer: PropTypes.string,
  imagePath: PropTypes.string,
  pageName: PropTypes.string,
};

export default certificationSupportContacts;
