import React from 'react';
import PropTypes from 'prop-types';
import { DynamicColumnItemList } from '../../../components/molecules/dynamic-column-item-list';
import { cornerstoneLinkClick } from '../certification.analytics';
import applyMarkdown from '../../utils/applyMarkdown';


const certificationResourcesCornerstone = (props) => {
  const handleCornerstoneLinkClick = (title, buttonText) => {
    cornerstoneLinkClick(props.clickAnalytics, props.subheaderPageName, props.pageName, title, buttonText);
  };

  return (
    <div>
      <DynamicColumnItemList
        {...props.data}
        disclaimerName={props.disclaimer}
        hideRedLine={true}
        imagePath={props.imagePath}
        internalFilePath={props.internalFilePath}
        pageName={props.pageName}
        parentPageName={props.parentPageName}
        sections={props.data.content}
        sendButtonClickAnalytics={(_, title, buttonText) => handleCornerstoneLinkClick(title, buttonText)}
      />
      <div className="divider-line"></div>
      <span className="red-text" >{applyMarkdown(props.data.footer.title)}</span> {applyMarkdown(props.data.footer.message)}
    </div>
  );
};

certificationResourcesCornerstone.propTypes = {
  clickAnalytics: PropTypes.shape({
    section: PropTypes.string,
    tagId: PropTypes.string,
  }),
  data: PropTypes.object,
  disclaimer: PropTypes.string,
  pageName: PropTypes.string,
  parentPageName: PropTypes.string,
};

export default certificationResourcesCornerstone;
