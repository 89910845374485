import { useEffect, useRef, useState } from "react";
import appsettings from "../../../appsettings";
import ArrowDown from "../../../resources/images/arrowDown_DarkGray.png";
import "../../../styles/scss/commentCard.scss";
import { getFormattedMonthDayOrMonthDayYear } from "../../../utils/date";
import { useHistory } from "react-router";
import { expandCollapseRepliesClick } from "../../../screens/profilePage/recentActivity/recentActivities.analytics";

/**
 * Atention: This component required the direct parent to have a flex display, column direction
 * @param {*} picture Optional string - Profile picture URL, if not provided, it will show the defalt profile picture
 * @param {*} name Required string - Name of user
 * @param {*} date Required Date - JS Date Object of comment
 * @param {*} comment Required string - Comment text
 * @param {*} title Required string - Title of comment
 * @param {*} reply Optional Boolean - Boolean indicating if this is a reply to a comment
 * @returns JSX element
 */
const CommentCard = ({
  elementKey,
  picture,
  name,
  date,
  comment,
  title,
  trimmedArticleId,
  reply,
  isUnread,
  onClick,
  analyticsData
}) => {
  const componentRef = useRef(null);
  const commentCardCommentRef = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const [showExpandIcon, setShowExpandIcon] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (componentRef.current) {
      if (!commentCardCommentRef.current) {
        const currentComment = componentRef.current.querySelector(
          ".comment-card-comment"
        );
        commentCardCommentRef.current = currentComment;
      }
      setShowExpandIcon(isTextTruncated(commentCardCommentRef.current));
      setExpanded(false);
    }
  }, [
    componentRef.current,
    commentCardCommentRef.current?.scrollHeight,
    commentCardCommentRef.current?.clientHeight,
  ]);

  const isTextTruncated = (element) => {
    return element.scrollHeight > element.clientHeight;
  };

  const toggleExpand = () => {
    const newExpanded = !expanded;
    setExpanded(newExpanded);
    const label = newExpanded ? 'Expand comment button click' : 'Collapse comment button click';
    expandCollapseRepliesClick(analyticsData?.clickAnalytics, label);
  };

  const handleLinkClick = () => {
    history.push(`/articles/${trimmedArticleId}`);
    window.scrollTo({ top: 0 });
  };

  return (
    <section
      {...(elementKey
        ? {
            key: elementKey,
          }
        : {})}
      ref={componentRef}
      className={`comment-card ${reply ? "comment-card-reply" : ""}${isUnread ? " comment-card-reply-unread" : ""}`}
      onClick={onClick}
    >
      <div className="comment-card-photo-name">
        {picture ? (
          <img
            src={picture}
            alt="profile picture"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src =
                appsettings.AWSImgRepo.resources + "en/default.svg";
            }}
          />
        ) : (
          <img
            src={appsettings.AWSImgRepo.resources + "en/default.svg"}
            alt="Default profile picture"
          />
        )}
        <span>{name}</span>
      </div>
      <span className="comment-card-date">
        {getFormattedMonthDayOrMonthDayYear(date)}
      </span>
      <span className="comment-card-title" onClick={() => handleLinkClick()}>{title}</span>
      <span className={`comment-card-comment ${expanded ? "" : "collapsed"}`}>
        {decodeURI(comment)}
      </span>
      {showExpandIcon && (
        <img
          onClick={toggleExpand}
          className={`comment-card-expand-icon ${expanded ? "expanded" : ""}`}
          src={ArrowDown}
          alt="expand"
        />
      )}
    </section>
  );
};

export default CommentCard;
