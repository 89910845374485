import "../../../styles/css/engageXP/tournaments/howItWorks.css";
import applyMarkdown from "../../utils/applyMarkdown";
import RoundedButton from "../../../components/molecules/roundedButton";
import { useHistory } from "react-router";

const HowItWorks = ({ tournamentsData, analytics, imgPath }) => {
    const { howItWorksSection } = tournamentsData;
    const history = useHistory();

    const HowItWorksCard = ({ step }) => (
        <div className="card-container">
            <img className="icon" src={`${imgPath}${step.icon}`} />
            <div className="card-title">{`${step.title}`}</div>
            <div className="description">{applyMarkdown(`${step.description}`)}</div>
        </div>
    );

    const navigateToFaqs = () => {
        analytics({
            category: "How It Works​",
            label: "View Full Rules",
        });
        history.push(`${howItWorksSection.buttonLink}`);
    };

    return (
        <div className="how-it-works">
            <div className="how-it-works-content">
                <div className="how-it-works-title">{`${howItWorksSection?.title}`}</div>
                <div className="how-it-works-cards">
                    {howItWorksSection?.steps?.map((step, index) => (
                        <HowItWorksCard step={step} key={index} />
                    ))}
                </div>
                <div className="button-container">
                    <RoundedButton
                        className="customColor bold"
                        color="#000"
                        textColor="#fff"
                        height="44px"
                        width="196px"
                        onClick={navigateToFaqs}
                        title={howItWorksSection?.buttonText}
                    />
                </div>
            </div>
        </div>
    );
};

export default HowItWorks;
