import React from 'react';
import PropTypes from 'prop-types';
import applyMarkdown from '../../utils/applyMarkdown';
import TitleText from '../../../components/atoms/TitleText';
import BodyText from '../../../components/atoms/BodyText';
import RedLine from '../../../components/atoms/RedLine';
import '../../../styles/css/model-overview/quick-facts.css';

const quickFactsItem = (item, index, styles, disclaimer) => {
  const shouldDisplayDivider = index === 1;
  const textColor = (styles && styles.textColor ? styles.textColor : '#000000');
  const bottomBorderColor = styles && styles.backgroundColor ? styles.backgroundColor : '#E6E6E6';
  return (
    <React.Fragment>
      <div key={index} className="quick-facts-section" style={{ color: textColor, borderColor: bottomBorderColor }}>
        <TitleText >{applyMarkdown(item.title, disclaimer)}</TitleText>
        <RedLine className={'model-overview-web-content redline'} />
        <RedLine className={'model-overview-print-content redline'} />
        <BodyText className={'quick-facts-text'}>{applyMarkdown(item.text, disclaimer)}</BodyText>
      </div>
      {shouldDisplayDivider ? <div className="model-overview-print-divider" /> : null}
    </React.Fragment>
  );
};

const awardsPrintView = children => (
  <div key={'awards'} className="quick-facts-section">
    <TitleText>Awards</TitleText>
    <RedLine className={'model-overview-print-content redline'} />
    <BodyText className={'quick-facts-text'}><ul>{children}</ul></BodyText>
  </div>
);

const sortQuickFacts = (quickFacts) => {
  const sortedQuickFacts = quickFacts;
  let orderedSections;

  if (sortedQuickFacts.length > 1) {
    if (sortedQuickFacts[1].alignment && sortedQuickFacts[1].alignment === 'Left') {
      orderedSections = [sortedQuickFacts[1], sortedQuickFacts[0]];
    } else if (sortedQuickFacts[0].alignment && sortedQuickFacts[0].alignment === 'Left') {
      orderedSections = [sortedQuickFacts[0], sortedQuickFacts[1]];
    } else {
      orderedSections = [...sortedQuickFacts];
    }
  }
  return orderedSections;
};

export default function QuickFactsSection(props) {
  const styles = props.styles ? props.styles : null;
  const sortedQuickFacts = sortQuickFacts(props.quickFacts);
  const expandedQuickFacts = props.headerData ? [props.headerData, ...sortedQuickFacts] : [...sortedQuickFacts];
  const printViewQuickFacts = props.featureData ? [...expandedQuickFacts, props.featureData] : [...expandedQuickFacts];

  return (
    <React.Fragment>
      <div className="model-overview-web-content">
        <div className="quick-facts-container" style={{ backgroundColor: (styles.backgroundColor ? styles.backgroundColor : '#FFFFFF') }}>
          {sortedQuickFacts.map((item, index) => quickFactsItem(item, index, styles, props.disclaimer))}
        </div>
      </div>
      <div className="model-overview-print-content">
        <div className="quick-facts-container">
          {printViewQuickFacts.map((item, index) => quickFactsItem(item, index, null, props.disclaimer))}
          {props.children ? awardsPrintView(props.children) : null}
        </div>
        <div className="model-overview-print-divider" />
      </div>
    </React.Fragment>
  );
}

QuickFactsSection.propTypes = {
  quickFacts: PropTypes.array.isRequired,
  imagePath: PropTypes.string,
  disclaimer: PropTypes.node,
  styles: PropTypes.object,
  headerData: PropTypes.object,
  featureData: PropTypes.object,
  children: PropTypes.node,
};
