import { Fragment } from "react";
import "../../../styles/scss/profilePage/profile-page-recently-visited-pages.scss";
import applyMarkdown from "../../utils/applyMarkdown";
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import { isDesktopView } from "../../utils/getScreenSize";
import Skeleton from "react-loading-skeleton";

const RecentlyVisitedPages = (props) => {
  const history = useHistory();

  function getPageTitle(pageTitle, pageCategory) {
    if (pageCategory === 'Video' || pageCategory === 'Article') {
        return applyMarkdown(pageTitle, '');
    }
    const pageTitleArray = [];
    const titleArray = pageTitle.trim().split(' ');
    titleArray.forEach((title, index) => {
        let component;
        if (title.indexOf('.') > -1) {
            component = (<FormattedMessage id={title} key={index} />);
        } else {
            component = <span key={index}>{applyMarkdown(title, '')}</span>;
        }
        pageTitleArray.push(component);
        if (index !== (titleArray.length - 1)) {
            pageTitleArray.push(' - ');
        }
    })
    return pageTitleArray;
  }

  const formatDate = (date) => {
    const currentDate = new Date();
    const visitDate = new Date(date);

    if (
      visitDate.getDate() === currentDate.getDate() &&
      visitDate.getMonth() === currentDate.getMonth() &&
      visitDate.getFullYear() === currentDate.getFullYear()
    ) {
      return "Today";
    }

    currentDate.setDate(currentDate.getDate() - 1);

    if (
      visitDate.getDate() === currentDate.getDate() &&
      visitDate.getMonth() === currentDate.getMonth() &&
      visitDate.getFullYear() === currentDate.getFullYear()
    ) {
      return "Yesterday";
    }

    return visitDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const groupedPages = props.items.reduce((acc, page) => {
    const formattedDate = formatDate(page.pageDate);
    acc[formattedDate] = acc[formattedDate] || [];
    acc[formattedDate].push(page);
    return acc;
  }, {});

  const handleLinkClick = (link) => {
    history.push(link);
    window.scrollTo({ top: 0 });
  };

  return (
    <div className="profile-page-recently-visited-pages-container">
      {isDesktopView(window.innerWidth) &&
        <span className="recently-visited-pages-title">Recently Visited Pages</span>
      }
      {!props?.historyLoading ?
        Object.entries(groupedPages).map(([date, pages], index) => (
          <Fragment key={index}>
            <div className="day-section">
              <div className="day-section-title">{date}</div>
              <div className="day-page-section">
                {pages.map((page, pageIndex) => (
                  <div key={pageIndex} className="day-page-visited-section">
                    <div className="day-page-section-title">{page.pageCategory}</div>
                    <div className="day-page-section-detail" onClick={() => handleLinkClick(page.pageUrl)}>
                      {getPageTitle(page.pageTitle, page.pageCategory)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {index < Object.entries(groupedPages).length - 1 && <hr className="day-page-entry-divider" />}
          </Fragment>
        ))
      :
        <div className="history-skeleton-loading">
          <Skeleton className={'skeleton-date'} height={30} width={'33%'}/>
          <Skeleton className={'skeleton-page'} height={20} width={'75%'} count={3}/>
          <hr className="day-page-entry-divider" />
          <Skeleton className={'skeleton-date'} height={30} width={'33%'}/>
          <Skeleton className={'skeleton-page'} height={20} width={'75%'} count={3}/>
          <hr className="day-page-entry-divider" />
          <Skeleton className={'skeleton-date'} height={30} width={'33%'}/>
          <Skeleton className={'skeleton-page'} height={20} width={'75%'} count={3}/>
        </div>
      }
    </div>
  );
};

export default RecentlyVisitedPages;
