import 'whatwg-fetch';
import httpOption from './httpOption';
import AppSettings from '../appsettings';

export function getCompetitiveAdvantagesEdge(yearAndModel, trim, competitor) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/edge/getAll/${yearAndModel}/${trim}/${competitor}`;
  const options = httpOption.GET();

  return fetch(url, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(response);
    });
}

export function getVehiclesAndCompetitorsList(yearAndModel) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/edge/competitorsList/${yearAndModel}`;
  const options = httpOption.GET();
  options.headers['accept-language'] = 'en';

  return fetch(url, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(response);
    });
}

export function getVehiclesAndCompetitorsListV2(yearAndModel) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/edge/competitorsList/v2/${yearAndModel}`;
  const options = httpOption.GET();
  options.headers['accept-language'] = 'en';

  return fetch(url, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(response);
    });
}

export function getLegacyEdgeSheets(yearAndModel) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/edge/competitorsList/legacy/${yearAndModel}`;
  const options = httpOption.GET();

  return fetch(url, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(response);
    });
}
