import React from 'react';
import AppSettings from '../../../../../appsettings';
import { getFormattedName } from '../../../../utils/vehicleName';

export const ImageTitleComponent = ({ toyotaVehicle, s3Path, ...props }) => {
  return (
    <div className="image-title-component">
      <img src={AppSettings.AWSImgRepo.edge + s3Path + toyotaVehicle.image} className="headToHeadImage" />
      <div className="toyota-model-trim-text">{getFormattedName(toyotaVehicle.model)} <b>{toyotaVehicle.trim}</b></div>
    </div>
  )
}