import { Component } from 'react';
import { multipleVehicleSelectionTemplate } from './multipleVehicleSelection.tpl';

export default class MultipleVehicleSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numSelections: 1,
      selectedVehicles: [],
    };
    this.addSelection = this.addSelection.bind(this);
  }

  addSelection() {
    this.setState({
      numSelections: this.state.numSelections + 1,
    });
  }

  removeSelection(index) {
    const selectedVehicles = this.state.selectedVehicles.filter((veh, ind) => ind !== index);
    this.setState({
      numSelections: this.state.numSelections - 1,
      selectedVehicles,
    });
    this.props.onSelectionChanged(selectedVehicles);
  }

  updateSelected(index, selection) {
    const newSelectedVehicles = this.state.selectedVehicles;
    newSelectedVehicles[index] = selection;
    this.setState({
      selectedVehicles: newSelectedVehicles,
    });
    this.props.onSelectionChanged(newSelectedVehicles);
  }

  render() {
    return multipleVehicleSelectionTemplate(this);
  }
}
