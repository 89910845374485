import { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import queryString from 'query-string';
import history from '../../app/history';
import { TSSFeaturesTemplate } from './tssFeatures.tpl';

const analyticsFunctions = require('./tssFeatures.analytics.js');

const formatFilterID = (filter) => {
  const id = filter.replace('%20', ' ');
  return id;
};

export class TSSFeaturesComponent extends Component {
  constructor(props) {
    super(props);

    const query = queryString.parse(encodeURIComponent(location.search));
    const setActiveFilter = query.tab ? query.tab : formatFilterID(props.filters[0].id);

    this.state = {
      activeFilter: setActiveFilter,
      featuresData: this.getPackageFeatures(props.filters.find(filter => filter.id === setActiveFilter).title),
      languageOverride: this.props.languageOverride,
    };

    this.switchFilter = this.switchFilter.bind(this);
    this.getFeaturesData = this.getFeaturesData.bind(this);

    this.sendFilterAnalytics = analyticsFunctions.sendFilterAnalytics.bind(this);
    this.sendPageLoadAnalytics = analyticsFunctions.sendPageLoadAnalytics.bind(this);
    this.sendVideoEventAnalytics = analyticsFunctions.sendVideoEventAnalytics.bind(this);
    this.readMoreClick = analyticsFunctions.readMoreClick.bind(this);
    this.clickLink = analyticsFunctions.clickLink.bind(this);
  }

  switchFilter(event, filter) {
    const selectedFilter = filter.id;
    this.sendFilterAnalytics(event);
    this.setState({
      activeFilter: selectedFilter,
      featuresData: this.getPackageFeatures(filter.title),
    });
    history.replace(`/toyotaSafetySense/features?tab=${filter.id}`);
  }

  getPackageFeatures(packageName) {
    const packages = this.props.packagesData;
    const featuresMap = this.props.featuresData;

    const helperFeatureaMap = new Map();
    const features = [];

    for (let i = 0; i < packages.length; i++) {
      if (packages[i].title === packageName) {
        const packageFeatures = packages[i].features.split(',');
        for (let j = 0; j < packageFeatures.length; j++) {
          if (!helperFeatureaMap.get(packageFeatures[j])) {
            features.push(featuresMap[packageFeatures[j]]);
            helperFeatureaMap.set(packageFeatures[j], packageFeatures[j]);
          }
        }
      }
    }

    return features;
  }

  mapToArray(map) {
    const features = Object.values(map);
    return features;
  }

  getFeaturesData() {
    return this.state.featuresData ? this.state.featuresData : this.switchFilter(this.state.activeFilter);
  }

  componentDidMount() {
    this.setState({ languageOverride: this.props.languageOverride });
    this.sendPageLoadAnalytics();
  }

  render() {
    return TSSFeaturesTemplate(this);
  }
}

TSSFeaturesComponent.propTypes = {
  featuresData: PropTypes.object,
  filters: PropTypes.array,
  languageOverride: PropTypes.string,
  packagesData: PropTypes.array,
};

export default injectIntl(TSSFeaturesComponent);
