import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();

const pageLoaded = (tag, section, page, keyword) => {
    analytics.firePageLoad({
        tag,
        section,
        page,
        keyword,
    });
};

const contentClicked = (tag, container, category, label, positionTitle, contentTitle, keyword) => {
    analytics.fireOnClick({
      tag,
      container,
      category,
      label,
      positionTitle,
      contentTitle,
      keyword
    });
};

export const openAdvancedSearchModal = (pageLoadAnalytics, keyword) => {
    pageLoadAnalytics && pageLoaded(
        pageLoadAnalytics.tagId,
        pageLoadAnalytics.section,
        pageLoadAnalytics.page,
        keyword,
    );
};

export const allWordsInput = (clickAnalytics, contentTitle, keyword) => {
    clickAnalytics && contentClicked(
        clickAnalytics.tagId,
        clickAnalytics.container,
        clickAnalytics.allWordsInput.category,
        clickAnalytics.allWordsInput.label,
        clickAnalytics.allWordsInput.position_title,
        contentTitle,
        keyword
    );
}

export const exactWordsInput = (clickAnalytics, contentTitle, keyword) => {
    clickAnalytics && contentClicked(
        clickAnalytics.tagId,
        clickAnalytics.container,
        clickAnalytics.exactWordsInput.category,
        clickAnalytics.exactWordsInput.label,
        clickAnalytics.exactWordsInput.position_title,
        contentTitle,
        keyword
    );
}

export const anyWordsInput = (clickAnalytics, contentTitle, keyword) => {
    clickAnalytics && contentClicked(
        clickAnalytics.tagId,
        clickAnalytics.container,
        clickAnalytics.anyWordsInput.category,
        clickAnalytics.anyWordsInput.label,
        clickAnalytics.anyWordsInput.position_title,
        contentTitle,
        keyword
    );
}

export const noneWordsInput = (clickAnalytics, contentTitle, keyword) => {
    clickAnalytics && contentClicked(
        clickAnalytics.tagId,
        clickAnalytics.container,
        clickAnalytics.noneWordsInput.category,
        clickAnalytics.noneWordsInput.label,
        clickAnalytics.noneWordsInput.position_title,
        contentTitle,
        keyword
    );
}

export const startMonthSelect = (clickAnalytics, contentTitle, keyword) => {
    clickAnalytics && contentClicked(
        clickAnalytics.tagId,
        clickAnalytics.container,
        clickAnalytics.startMonthSelect.category,
        clickAnalytics.startMonthSelect.label,
        clickAnalytics.startMonthSelect.position_title,
        contentTitle,
        keyword
    );
}

export const startYearSelect = (clickAnalytics, contentTitle, keyword) => {
    clickAnalytics && contentClicked(
        clickAnalytics.tagId,
        clickAnalytics.container,
        clickAnalytics.startYearSelect.category,
        clickAnalytics.startYearSelect.label,
        clickAnalytics.startYearSelect.position_title,
        contentTitle,
        keyword
    );
}

export const endMonthSelect = (clickAnalytics, contentTitle, keyword) => {
    clickAnalytics && contentClicked(
        clickAnalytics.tagId,
        clickAnalytics.container,
        clickAnalytics.endMonthSelect.category,
        clickAnalytics.endMonthSelect.label,
        clickAnalytics.endMonthSelect.position_title,
        contentTitle,
        keyword
    );
}

export const endYearSelect = (clickAnalytics, contentTitle, keyword) => {
    clickAnalytics && contentClicked(
        clickAnalytics.tagId,
        clickAnalytics.container,
        clickAnalytics.endYearSelect.category,
        clickAnalytics.endYearSelect.label,
        clickAnalytics.endYearSelect.position_title,
        contentTitle,
        keyword
    );
}

export const fileTypeSelect = (clickAnalytics, contentTitle, keyword) => {
    clickAnalytics && contentClicked(
        clickAnalytics.tagId,
        clickAnalytics.container,
        clickAnalytics.fileTypeSelect.category,
        clickAnalytics.fileTypeSelect.label,
        clickAnalytics.fileTypeSelect.position_title,
        contentTitle,
        keyword
    );
}

export const usageRightSelect = (clickAnalytics, contentTitle, keyword) => {
    clickAnalytics && contentClicked(
        clickAnalytics.tagId,
        clickAnalytics.container,
        clickAnalytics.usageRightSelect.category,
        clickAnalytics.usageRightSelect.label,
        clickAnalytics.usageRightSelect.position_title,
        contentTitle,
        keyword
    );
}

export const orderResultsSelect = (clickAnalytics, contentTitle, keyword) => {
    clickAnalytics && contentClicked(
        clickAnalytics.tagId,
        clickAnalytics.container,
        clickAnalytics.orderResultsSelect.category,
        clickAnalytics.orderResultsSelect.label,
        clickAnalytics.orderResultsSelect.position_title,
        contentTitle,
        keyword
    );
}

export const showAdvancedSearchResults = (clickAnalytics, keyword) => {
    clickAnalytics && contentClicked(
        clickAnalytics.tagId,
        clickAnalytics.container,
        clickAnalytics.showResultsClick.category,
        clickAnalytics.showResultsClick.label,
        null,
        null,
        keyword
    );
}

export const closeAdvancedSearchModal = (clickAnalytics, keyword) => {
    clickAnalytics && contentClicked(
        clickAnalytics.tagId,
        clickAnalytics.container,
        clickAnalytics.closeAdvancedSearchClick.category,
        clickAnalytics.closeAdvancedSearchClick.label,
        null,
        null,
        keyword
    );
} 