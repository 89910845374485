import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getVideoIdFromAlias } from '../../services/videoLookupService';
import '../../styles/css/video-player.css';
import QumuVideoWidget from './qumu-video-widget';

// The `item` prop needs to have a pageTitle or a videoTitle property
const VideoPlayerComponent = (props) => {
  const [guid, setGuid] = useState('');

  useEffect(() => {
    setVideoId();
  }, [props.item]);

  const translateAliasToQumuId = (alias) => new Promise((resolve, reject) => {
    getVideoIdFromAlias(alias)
      .then((payload) => {
        resolve(payload);
      }).catch((error) => {
        console.log(`ERROR loading video id from dynamo with ALIAS ${alias}: ${JSON.stringify(error)}`);
        reject(error);
      });
  });

  // eslint-disable-next-line consistent-return
  const setVideoId = () => {
    if (props.item.qumuId) {
      setGuid(props.item.qumuId);
    } else if (props.item.alias) {
      translateAliasToQumuId(props.item.alias).then((response) => {
        if (response.length >= 1) {
          setGuid(response[0].qumuId);
        }
      });
    }
  };

  return guid ? (
    <QumuVideoWidget
      analyticsModule={props.analyticsModule}
      analyticsPage={props.analyticsPage}
      analyticsSection={props.analyticsSection}
      analyticsTag={props.analyticsTag}
      analyticsVehicleSpecs={props.analyticsVehicleSpecs}
      guid={guid}
      hideCTA={props.item.hideCTA}
      pageTitle={props.item.pageTitle}
      width={props.item.width}
      height={props.item.height}
      type={props.playlist ? "playlist" : "playback"}
    />
  ) : null;
};

VideoPlayerComponent.propTypes = {
  analyticsModule: PropTypes.string,
  analyticsSection: PropTypes.string,
  analyticsTag: PropTypes.string,
  item: PropTypes.object,
  sendVideoEventAnalytics: PropTypes.func,
};

export default React.memo(VideoPlayerComponent);
