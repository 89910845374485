import React, {useEffect, useState} from 'react';
import AppSettings from '../../appsettings';
import applyMarkdown from '../utils/applyMarkdown';
import { isDesktopView } from '../utils/getScreenSize.js';
import Tooltip from '../../components/molecules/Tooltip';
import '../../styles/css/nonVehicleSearchResultPane.css';
import * as analytics from './nonVehicleSearchResultPane.analytics';
import getAnalytics from '../../services/analyticsService';
import MobileBackButton from '../../components/molecules/MobileBackButton';

const NonVehicleSearchResultPane = (props) => {
    const [visible, setVisible] = useState(false);
    const [analyticsData, setAnalyticsData] = useState(null);

    useEffect(async () => {
        const data = await getAnalytics('nonVehicleSearch');
        setAnalyticsData(data);
    }, []);

    const tooltipMessages = {
        Overview: 'overview',
        'TSS Lineup': 'lineup',
        'Feature Details': 'featureDetails',
        Videos: 'videos',
        Resources: 'resources',
        Registration: 'registration',
        'Audio Lineup': 'lineup',
        'Beyond Zero Comparison': 'comparison',
        Testimonials: 'testimonials',
        Training: 'training',
        FAQs: 'faqs',
        Requirements: 'requirements',
        'Vehicle Applicability' : 'vehicleApplicability',
        'System Information': 'systemInformation'
    };
    const tooltipSections = {
        'Toyota Safety Sense': 'safety',
        'Beyond Zero': 'electrified',
        'Audio Multimedia': 'audio',
        'Star Safety System': 'starSafety',
        'Certification': 'certification',
        'eBrochures': 'ebrochures',
        'News': 'news',
        'Towing & Payload Calculator': 'towingPayload',
        'Delivery Checklists': 'deliveryChecklists',
        'SmartPath': 'smartpath',
        'Source Books': 'sourcebooks',
        'Engage Promo Materials': 'promoMaterials'
    };

    const generateTooltipMessage = (pageName, tabName) => {
        let tooltipMessage, displayTooltip;
        if (pageName in tooltipSections) {
            displayTooltip = true;
            if (tabName in tooltipMessages){
                tooltipMessage = `tooltip.${tooltipSections[pageName]}.${tooltipMessages[tabName]}`;
            } else {
                tooltipMessage = `tooltip.${tooltipSections[pageName]}`;
            }
        } else displayTooltip = false;
    
        return [displayTooltip, tooltipMessage];
    }

    const HeaderContent = () => {
        return (
            <div className="header-content">
                <MobileBackButton />
                <span className="header-text">
                    {props.title}
                </span>
            </div>
        );
    };
    
    const BodyContent = () => {  
        return (
            <div className="body-content">
                {props.pageData.tabs.length > 0 ? <NonVehicleTabs /> : <SinglePageButton />}   
            </div>
        );
    };

    const NonVehicleTabs = () => {
        let tabsList = (props.pageData && props.pageData.tabs.map(tab => {
            const [displayTooltip, tooltipMessage] = generateTooltipMessage(props.pageData.page.textToDisplay, tab.textToDisplay);
            return (
                <li className="nonVehicle-tab">
                    <div className="tabName">
                        <a href={tab.link} data-tip data-for={tab.textToDisplay} onClick= {() => analytics.handleAClick(analyticsData?.clickAnalytics, props.title, tab.textToDisplay)}>
                            {tab.textToDisplay}
                        </a>
                    </div>
                    {displayTooltip && <Tooltip id={tab.textToDisplay} message={ tooltipMessage } />}
                    {tab.filters.length > 0 && <div className="tab-filters-list">
                        {tab.filters.map(filter=>(<div key={filter} className="tab-filter">{filter}</div>))}
                    </div>}
                </li>
            );
        }));

        return (
            <>
                <div className="red-border" />
                <ul className="nonVehicle-tabs-list">{tabsList}</ul>
            </>
        );
    };

    const SinglePageButton = () => {
        const [displayTooltip, tooltipMessage] = generateTooltipMessage(props.pageData.page.textToDisplay);
        return (
            <>
                <div className="red-border" />
                <div className='single-page-button' data-tip data-for={props.pageData.page.textToDisplay}>
                    <a href={props.pageData.page.link} onClick= {() => analytics.handleButtonClick(analyticsData?.clickAnalytics, props.pageData.page.textToDisplay)}>
                        See More
                    </a>
                </div>
                {displayTooltip && <Tooltip id={props.pageData.page.textToDisplay} message={ tooltipMessage } />}
            </>
        )
    }

    const NonVehicleSearchDesktop = () => {
        return (
            <>
                <HeaderContent />
                <BodyContent /> 
            </>
        );
    };

    const NonVehicleSearchTabletMobile = () => {
        return (
            <div onClick={() => (!visible && props.pageData.tabs.length > 0) && setVisible(true)}>
                <HeaderContent /> 
                {(visible || props.pageData.tabs.length == 0) && <BodyContent />}
                {(!isDesktopView(window.innerWidth) && props.pageData.tabs.length > 0) && 
                    <div className = "arrowButton" onClick={() => setVisible(false)}>
                        <img className="arrow-image" src={props.imagePath + (visible ? "Up_Arrow_Black.png" : "Down_Arrow_Black.png")} />
                    </div>}
            </div>
        );
    };

    return (
        <div className="non-vehicle-search-result-pane">
            {isDesktopView(window.innerWidth) ? <NonVehicleSearchDesktop/> : <NonVehicleSearchTabletMobile/>}
        </div>
    );
}

export default NonVehicleSearchResultPane;