import React from 'react';
import { FormattedMessage } from 'react-intl';
import AppSettings from '../../../appsettings.js';
import { FilterMenu } from '../../filterMenu/filterMenu.component';
import { MediaTextComponent } from '../../mediaText/mediaText.component';
import { getLocale } from '../../../services/languageTranslationService.js';
import DisclaimersComponent from '../../disclaimers/disclaimers.component';

export default function AudioMultimediaRegistrationTemplate(component) {
  const features = component.state.versions[component.state.activeVersionTitle] ? component.state.versions[component.state.activeVersionTitle].features.map((feature, index) => {
    if (feature.linkUrl.match(/^\d+$/)) {
      return (
                <MediaTextComponent
                    item={feature}
                    index={index}
                    key={index}
                    title={feature.title}
                    subtitle={feature.subtitle}
                    description={feature.description}
                    fileName={feature.fileName}
                    disclaimer={component.disclaimer}
                    imageArrow={true}
                    alternate={true}
                    videoId={feature.linkUrl}
                    sendVideoEventAnalytics={component.videoPlayClick}
                />
      );
    }
    return (
            <MediaTextComponent
                index={index}
                key={index}
                title={feature.title}
                subtitle={feature.subtitle}
                description={feature.description}
                images={[feature.image]}
                imagePath={encodeURI(`${AppSettings.AWSImgRepo.entune + getLocale()}/`)}
                link={feature.linkUrl}
                buttonText={feature.link}
                fileName={feature.fileName}
                disclaimer={component.disclaimer}
                imageArrow={true}
                alternate={true}
            />
    );
  }) : null;

  const filterDictionary = {
    'Audio Multimedia': { label: 'audioMultimedia.registration.audioMultimedia', sortOrder: 1 },
    'Entune^(TM)^ 3.0': { label: 'audioMultimedia.registration.entune3.0', sortOrder: 2 },
    'Entune^(TM)^': { label: 'audioMultimedia.registration.entune', sortOrder: 3 },
    'Connected Services Registration': { label: 'audioMultimedia.registration.csr', sortOrder: 4 },
    'Supra Connect': { label: 'audioMultimedia.registration.sc', sortOrder: 5 },
  };

  const dynamicFilters = () => {
    const availableFilters = [];

    for (const key in component.state.versions) {
      availableFilters.push({ title: key, ...filterDictionary[key] });
    }

    const sortedFilters = availableFilters.sort((a, b) => ((a.sortOrder > b.sortOrder) ? 1 : -1));

    const filters = sortedFilters.map(sf => ({ id: sf.title, label: sf.label }));

    return filters;
  };

  return (
        <div className="audioMultimediaRegistration">
            <div className="title">
                <FormattedMessage id="entuneRegistration.pageTitle" defaultMessage="Registration" />
            </div>
            <FilterMenu
                active={component.state.activeVersionTitle}
                filters={dynamicFilters()}
                filterClick={component.switchVersion}
                displayArrow={true}
            />
            <div className="features">
                {features}
            </div>
            <DisclaimersComponent template={'entune'} ref={(instance) => { component.disclaimer = instance; }} />
        </div>
  );
}
