import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import applyMarkdown from '../../utils/applyMarkdown';
import VideoPlayerComponent from '../../videoPlayer/videoPlayer.component';
import '../../../styles/css/smartpath-overview-intro.css';
import ReactFreezeframe from 'react-freezeframe';

export const SmartPathOverviewIntro = (props) => {
   const isVideoOrGif = () =>
   {
       let overviewIntroVideo
       if(props.introData.qumuId){
        overviewIntroVideo = (
            <VideoPlayerComponent
                item={{
                    qumuId: props.introData.qumuId,
                    videoTitle: 'SmartPath Overview Video',
                    contentTitle: 'Overview',
                    hideCTA: true,
                    pageTitle: props.clickAnalytics && props.clickAnalytics.introVideo.page,
                }}
                analyticsSection={props.clickAnalytics && props.clickAnalytics.introVideo.section}
                analyticsModule={props.clickAnalytics && props.clickAnalytics.introVideo.module}
            />
        );
       }
       else if(props.introData.gif){
        overviewIntroVideo = <div className><ReactFreezeframe src={props.imagePath + props.introData.gif} /></div> 
       }
       return overviewIntroVideo
   }
    
    const overviewIntroCards = props.introData.introCards.map(introCards => (
        <div className = "smartpath-overview-cards">
            <div className = "smartpath-overview-cardIcon">
                <img src={props.imagePath + introCards.image}/>
            </div>
            <div className = "smartpath-overview-cardTitle">
                {applyMarkdown(introCards.title)}
            </div>
            <div className = "smartpath-overview-cardContent">
                {applyMarkdown(introCards.description)}
            </div>
        </div>
    ));

    return <div className='smartpath-overview-intro'>
        <div className = "smartpath-overview-sectionHeader">
            {applyMarkdown(props.introData.header)}
        </div>
        <div className = "smartpath-overview-quick-links">
            {props.buttons}
        </div>
        <div className="smartpath-overview-hero-video">
            {isVideoOrGif()}
        </div>
        <div className="smartpath-overview-card-section">
            {overviewIntroCards}
        </div>
    </div>;
};