import React from "react";
import CarouselV2 from "../../../../components/molecules/CarouselV2";
import ResultCard from "./resultCard";
import appsettings from "../../../../appsettings";
import { isMobileView, isTabletView } from "../../../utils/getScreenSize";
import "../../../../styles/scss/beyondZero/resultsCarousel.scss";

const ResultsCarousel = ({ cards, analyticsActions }) => {

  const handleOnVehicleClick = (card) => {
    analyticsActions.onClick({
      label: 'Result Card Click',
      model_name: card.vehicle
    })
    
    window.location.href = card.overviewLink;
  }

  function getResultCardCarouselItems() {
    const vehicles = cards.map(card => (
      <ResultCard
        key={card.vehicle}
        imagePath={appsettings.AWSImgRepo.beyondZero + card.image}
        title={card.vehicle}
        content={card.description}
        onClick={() => handleOnVehicleClick(card)}
      />
    )
    );
    return vehicles;
  }

  const handlePagigationClick = (direction) => {
    analyticsActions.onClick({
        label: `Results ${direction}`
    })
  }

  const resultCardCarouselItemList = getResultCardCarouselItems();

  let slideCount = 3;
  let gapBetweenSlides = 38;
  if (isTabletView(window.innerWidth)) {
    slideCount = 2;
    gapBetweenSlides = 24;
  } else if (isMobileView(window.innerWidth)) {
    slideCount = 1;
  }
  return (
    <div className={"result-carousel-container"}>
      <CarouselV2
        navigation
        width={1}
        spaceBetween={gapBetweenSlides}
        prevArrow={
          <div className="datesArrow prev" onClick={() => handlePagigationClick('Prev')}>
            <img src={appsettings.AWSImgRepo.beyondZero + 'bz-arrow-circle.png'} alt="Previous"/>
          </div>
        }
        nextArrow={
          <div className="datesArrow next" onClick={() => handlePagigationClick('Next')}>
            <img src={appsettings.AWSImgRepo.beyondZero + 'bz-arrow-circle.png'} alt="Next"/>
          </div>
        }
        slidesCount={slideCount}
        items={resultCardCarouselItemList}
      />
    </div>
  )
}

export default ResultsCarousel;
