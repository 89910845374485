import { useEffect, useRef, useState } from "react";
import S from "../../../../styles/scss/engageXP/tournaments/countdown.module.scss";
import CountdownRing from "./countdown-ring";

const Countdown = ({ countdownTo, round }) => {
    const [countdown, setCountdown] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });
    const isReady = countdown.days + countdown.hours + countdown.minutes + countdown.seconds > 0;
    const shouldContinueCountdown = useRef(countdownTo && new Date(countdownTo) > new Date());

    function calculateTimeDifference(countdownDate) {
        const now = new Date();
        const endTime = new Date(countdownDate);
        const timeDifference = endTime - now;
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        if (days < 0) {
            shouldContinueCountdown.current = false;
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
        return { days, hours, minutes, seconds };
    }

    const updateCountdown = (countdownDate) => {
        const { days, hours, minutes, seconds } = calculateTimeDifference(countdownDate);
        setCountdown({ days, hours, minutes, seconds });
    };

    useEffect(() => {
        shouldContinueCountdown.current = true;
        let interval = null;
        if (shouldContinueCountdown.current) {
            interval = setInterval(() => {
                updateCountdown(countdownTo);
            }, 1000);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [countdownTo, shouldContinueCountdown.current]);

    return (
        <div className={S["countdown-container"]}>
            <p>{round}</p>
            <div className={S["rings-section"]}>
                {isReady && (
                    <>
                        <CountdownRing
                            label={"Days"}
                            shouldContinueCountdown={shouldContinueCountdown.current}
                            number={countdown.days}
                        />
                        <CountdownRing
                            label={"Hours"}
                            shouldContinueCountdown={shouldContinueCountdown.current}
                            number={countdown.hours}
                        />
                        <CountdownRing
                            label={"Minutes"}
                            shouldContinueCountdown={shouldContinueCountdown.current}
                            number={countdown.minutes}
                        />
                        <CountdownRing
                            label={"Seconds"}
                            shouldContinueCountdown={shouldContinueCountdown.current}
                            number={countdown.seconds}
                        />
                    </>
                )} 
            </div>
        </div>
    );
};

export default Countdown;
