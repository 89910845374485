import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import DisclaimersComponent from '../../screens/disclaimers/disclaimers.component';
import applyMarkdown from '../../screens/utils/applyMarkdown';
import { postViewPageAction } from '../../services/nitroService';
import '../../styles/css/dynamic-column-item-list.css';
import SectionHeader from '../atoms/SectionHeader';
import { DynamicColumnItemListPrint } from './dynamic-column-item-list-print';
import VerticalMediaTextTile from './vertical-media-text-tile';

export const DynamicColumnItemList = (props) => {
  const [disclaimer, setDisclaimer] = useState(null);
  const disclaimerRef = useRef();

  useEffect(() => {
    if (props.pageLoadAnalytics) {
      postViewPageAction(window.location.href);
      props.pageLoadAnalytics(props.pageName || 'Overview');
    }
    setDisclaimer(disclaimerRef.current);
  }, [props.pageName, disclaimerRef]);


  const calculateNumberOfColumns = (num) => {
    try {
      const parsedNum = parseInt(num, 10);
      if (parsedNum > 4) {
        return 5;
      }
      return parsedNum;
    } catch (ex) {
      console.log('Invalid number of columns provided for layout.');
    }
  };

  const numberOfColumns = calculateNumberOfColumns(props.sections.length);
  const renderOverviewTiles = () => props.sections && props.sections.map((item, i) => {
    let { link } = item;
    let { buttonText } = item;
    if (item.links && item.links.length === 1) {
      link = item.links[0].url;
      buttonText = item.links[0].link;
    }
    // TODO Rename properties on Fixed/Variable Ops in Certification in order to not need this data transformation
    let { title } = item;
    let contentType;
    if (item.subDescription) {
      contentType = title;
      title = '';
    }
    return <VerticalMediaTextTile
      key={`${item.image}-${i}`}
      {...props}
      {...item}
      buttonText={buttonText}
      title={title}
      contentType={contentType}
      disclaimer={disclaimer}
      numberOfColumns={numberOfColumns}
      rowWrap={numberOfColumns === 5}
      tabPageName={props.pageName || 'Overview'}
      link={link}
      hideRedLine={props.hideRedLine}
      sendVideoEventAnalytics={props.sendVideoEventAnalytics}
      sendButtonClickAnalytics={props.sendButtonClickAnalytics}
    />;
  });

  return <React.Fragment>
    <div className="dynamic-column-item-list">
      <SectionHeader>{applyMarkdown(props.headline, disclaimer)}</SectionHeader>
      {props.description
        && <React.Fragment>
          <div className="dynamic-column-list-headline-description">{applyMarkdown(props.description, disclaimer)}</div>
          <div className="dynamic-column-list-grey-line header show-for-small-only" />
        </React.Fragment>
      }
      <div className="vertical-media-text-row-container">
        {renderOverviewTiles()}
      </div>
      {props.bottomText
        && <React.Fragment>
          <div className="dynamic-column-list-grey-line" />
          <div className="dynamic-column-list-footer-text">{applyMarkdown(props.bottomText, disclaimer)}</div>
        </React.Fragment>
      }
      <DisclaimersComponent template={props.disclaimerName} ref={(instance) => { disclaimerRef.current = instance; }} />
    </div>
    <DynamicColumnItemListPrint
      printHeadline={props.printHeadline}
      imagePath={props.imagePath}
      printHeaderIcon={props.printHeaderIcon}
      description={props.description}
      sections={props.sections}
    />
  </React.Fragment>;
};

DynamicColumnItemList.propTypes = {
  bottomText: PropTypes.string,
  description: PropTypes.string,
  disclaimerName: PropTypes.string,
  headline: PropTypes.string,
  hideRedLine: PropTypes.bool,
  internalFilePath: PropTypes.string,
  imagePath: PropTypes.string,
  pageLoadAnalytics: PropTypes.func,
  pageName: PropTypes.string,
  parentPageName: PropTypes.string,
  printHeaderIcon: PropTypes.string,
  printHeadline: PropTypes.string,
  sections: PropTypes.array,
  sendButtonClickAnalytics: PropTypes.func,
  sendVideoEventAnalytics: PropTypes.func,
};
