import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DropdownV2 from '../../components/molecules/DropdownV2';
import AppSettings from '../../appsettings';
import imageToggleOnHover from '../../screens/utils/imageToggleOnHover';
import { isDesktopView } from '../../screens/utils/getScreenSize.js';
import * as analytics from './eBrochures.analytics';
import '../../styles/css/eBrochures.css';
import * as _ from 'lodash';


const eBrochuresVehicle = (props) => {
  const [eBrochureYears, setEBrochureYears] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const modelName = props.vehicle.displayName ? props.vehicle.displayName : props.vehicle.model;

  const iconImageClassName = 'ebrochures-vehicle-download-icon';
  const hoverIconImageClassName = 'ebrochures-vehicle-download-icon-red';
  const jellyImgSrc = props.vehicle.rotatedImgSrc ? AppSettings.AWSImgRepo.jellybeans + props.vehicle.rotatedImgSrc : AppSettings.AWSImgRepo.jellybeans + props.vehicle.imgSrc;
  const downloadIcon = AppSettings.AWSImgRepo.resources + 'en/eBrochures_download.png';
  const redDownloadIcon = AppSettings.AWSImgRepo.resources + 'en/eBrochures_download_red.png';

  const downloadEBrochure = () => {
    if (props.clickAnalytics && props.clickAnalytics.downloadAction) {
      const cleanActionName = _.replace(props.clickAnalytics.downloadAction.actionNeedsReplace, props.clickAnalytics.downloadAction.toReplace, selectedYear);
      const cleanCategoryTitle = _.replace(props.filterCategoryName, '&', 'and');
      analytics.eBrochureDownloadClicked(
        props.clickAnalytics,
        props.pageLoadAnalytics,
        cleanCategoryTitle,
        props.vehicle.analyticsLabel,
        cleanActionName,
      );
    }

    const eBrochureLink = _.find(props.eBrochures, eBrochure => eBrochure.year === selectedYear).eBrochure;
    const eBrochureUrl = AppSettings.AWSFileRepo.brochures + eBrochureLink;
    window.open(eBrochureUrl, '_blank');
  };

  const handleMouseOver = imageContainer => {
    if (isDesktopView(window.innerWidth)) {
      imageToggleOnHover(imageContainer, iconImageClassName, hoverIconImageClassName);
    }
  };

  const handleMouseOut = imageContainer => {
    if (isDesktopView(window.innerWidth)) {
      imageToggleOnHover(imageContainer, hoverIconImageClassName, iconImageClassName);
    }
  };

  const handleYearDropdownValueChange = dropdownValue => {
    if (props.clickAnalytics && props.clickAnalytics.yearSelection) {
      const cleanActionName = _.replace(props.clickAnalytics.yearSelection.actionNeedsReplace, props.clickAnalytics.downloadAction.toReplace, dropdownValue.value);
      const cleanCategoryTitle = _.replace(props.filterCategoryName, '&', 'and');
      analytics.yearSelectionClicked(
        props.clickAnalytics,
        props.pageLoadAnalytics,
        cleanCategoryTitle,
        props.vehicle.analyticsLabel,
        cleanActionName,
      );
    }
    setSelectedYear(dropdownValue.value);
  }

  useEffect(() => {
    if (props.eBrochures?.length > 0) {
      const sortedYears = _.orderBy(props.eBrochures, 'year', 'desc');
      const years = _.flatMap(sortedYears, eBrochureYear => eBrochureYear.year);
      setEBrochureYears(years);
      setSelectedYear(years[0]);
    } else if (props.eBrochures?.length == 0) {
      setEBrochureYears([]);
      setDisabled(true);
    }
  }, [props.eBrochures]);

  return (
    <div className="ebrochures-vehicle">
      <img className="ebrochures-vehicle-jelly" src={jellyImgSrc} alt={props.vehicle.alt}></img>
      <div className="ebrochures-vehicle-title">{modelName}</div>
        <DropdownV2
          style={disabled ? "disabled" : "black-red"}
          options={eBrochureYears}
          value={selectedYear}
          onValueChange={handleYearDropdownValueChange}
          rootClassName={"ebrochures-vehicle-dropdown"}
          placeholder={disabled && "N/A"}
        />
      <a
        className={`ebrochures-vehicle-download ${disabled && 'action-disabled'}`}
        onClick={() => !disabled && downloadEBrochure()}
        onMouseOver={imageContainer => handleMouseOver(imageContainer)}
        onMouseOut={imageContainer => handleMouseOut(imageContainer)}
      >
        <img className="ebrochures-vehicle-download-icon" src={downloadIcon} alt="download" />
        <img className="ebrochures-vehicle-download-icon-red" src={disabled ? downloadIcon : redDownloadIcon} alt="download" />
      </a>
    </div>
  );
}

eBrochuresVehicle.propTypes = {
  clickAnalytics: PropTypes.shape({
    categorySelection: PropTypes.shape({
      category: PropTypes.string,
    }),
    downloadAction: PropTypes.shape({
      actionNeedsReplace: PropTypes.string,
      toReplace: PropTypes.string,
    }),
    tagId: PropTypes.string,
    yearSelection: PropTypes.shape({
      actionNeedsReplace: PropTypes.string,
      toReplace: PropTypes.string,
    }),
  }),
  eBrochures: PropTypes.arrayOf(PropTypes.shape({
    year: PropTypes.string,
    eBrochure: PropTypes.string,
  })),
  filterCategoryName: PropTypes.string,
  pageLoadAnalytics: PropTypes.shape({
    section: PropTypes.string,
    subsection: PropTypes.string,
    tagId: PropTypes.string
  }),
  vehicle: PropTypes.shape({
    alt: PropTypes.string,
    analyticsLabel: PropTypes.string,
    imgSrc: PropTypes.string,
    model: PropTypes.string,
    rotatedImgSrc: PropTypes.string,
  }),
}

export default eBrochuresVehicle;
