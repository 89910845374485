import { GlobalAnalytics } from "../../../analytics";

const analytics = new GlobalAnalytics();

export function gradeHighlightTabClick(event) {
  analytics.fireOnClick({
    tag: "29.2",
    section: "Product",
    page: event?.modelName,
    category: "Overview",
    label: "Tab Click",
    modelName: event?.modelName,
    modelYear: event?.modelYear,
    contentTitle: event?.trimName,
    positionTitle: "Grade Highlights",
  });
}

export function technologyLinkClick(event) {
  analytics.fireOnClick({
    tag: "29.2",
    section: "Product",
    page: event?.modelName,
    category: "Overview",
    label: event?.linkName,
    modelName: event?.modelName,
    modelYear: event?.modelYear,
    positionTitle: event?.sectionName,
  });
}

export function modelCodePricingAccordionClick(event) {
  console.log("modelCodePricingAccordionClick", event);
  analytics.fireOnClick({
    tag: "29.2",
    section: "Product",
    page: event?.modelName,
    category: "Overview",
    label: `Trim ${event?.isOpen ? "Expand" : "Collapse"}`,
    modelName: event?.modelName,
    modelYear: event?.modelYear,
    contentTitle: event?.trimName,
  });
}
