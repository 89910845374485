import { useEffect, useMemo, useRef, useState } from "react";
import * as _ from "lodash";

import TitleText from "../../../components/atoms/TitleText";
import DisclaimersComponent from "../../disclaimers/disclaimers.component";

import { getS3PathName, getDynamoName } from "../../utils/vehicleName";
import applyMarkdown from "../../utils/applyMarkdown";
import Skeleton from "react-loading-skeleton";

import AppSettings from "../../../appsettings.js";
import * as analyticFunctions from "./modelOverviewV2Sections.analytics";

import "../../../styles/css/model-overview-v2/grade-highlights.css";

const GradeHighlightsSection = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [s3Path, setS3Path] = useState("");
  const [selectedTrimImage, setSelectedTrimImage] = useState(null);

  const disclaimerComponentRef = useRef();
  const activeRef = useRef();

  useEffect(() => {
    selectedIndex &&
      activeRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
  }, [selectedIndex]);

  const uniqueTrims = useMemo(() => {
    return _.uniqBy(props.trims, "name");
  }, [props.trims]);

  const selectedTrim = useMemo(() => {
    return uniqueTrims[selectedIndex];
  }, [uniqueTrims, selectedIndex]);

  const getS3Path = async () => {
    const s3PathName = await getS3PathName(getDynamoName(props.model));
    const year = props.year;
    setS3Path(`${year}/${s3PathName}/en/`);
  };

  useEffect(() => {
    setSelectedTrimImage(
      encodeURI(
        AppSettings.AWSImgRepo.vehicle +
          s3Path +
          selectedTrim?.trimImageFilename
      ).toString()
    );
  }, [s3Path, selectedTrim]);

  useEffect(() => {
    getS3Path();
  }, []);

  return (
    <div className="grade-highlights-wrapper">
      <div className="grade-highlights-section">
        <TitleText className="section-title">Grade Highlights</TitleText>
        <ul className="grade-highlights-tabs">
          {props.trims ? (
            uniqueTrims.map((trim, index) => (
              <li
                className={index === selectedIndex ? "active" : ""}
                onClick={() => {
                  setSelectedIndex(index);
                  // ANALYTICS
                  analyticFunctions.gradeHighlightTabClick({
                    modelName: props.model,
                    modelYear: props.year,
                    trimName: trim.name,
                  });
                }}
                ref={index === selectedIndex ? activeRef : null}
              >
                {trim.name}
              </li>
            ))
          ) : (
            <div className="skeleton-section">
              <Skeleton width={100} height={28} count={4} inline />
            </div>
          )}
        </ul>
        <div className="grade-highlights-container">
          <div className="image-container">
            {props.trims ? (
              <img
                src={selectedTrimImage}
                onError={() =>
                  setSelectedTrimImage(
                    encodeURI(
                      AppSettings.AWSImgRepo.vehicle +
                        s3Path +
                        "image_coming_soon_700x300.png"
                    ).toString()
                  )
                }
              />
            ) : (
              <Skeleton height={200} />
            )}
          </div>
          <div className="info-container">
            {props.trims ? (
              <>
                <div className="trim-story">
                  {applyMarkdown(
                    selectedTrim?.trimStory,
                    disclaimerComponentRef.current
                  )}
                </div>
                <TitleText className="section-subtitle">Key Features</TitleText>
                <div className="key-features-list">
                  {selectedTrim?.keyFeatures?.map((keyFeature) => (
                    <div className="key-feature">
                      {applyMarkdown(
                        keyFeature,
                        disclaimerComponentRef.current
                      )}
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <Skeleton count={6.5} />
            )}
          </div>
        </div>

        <DisclaimersComponent
          template={`${props.year}_${getDynamoName(props.model)}`}
          ref={disclaimerComponentRef}
          addBaseMSRPDisclaimer={true}
        />
      </div>
    </div>
  );
};

export default GradeHighlightsSection;
