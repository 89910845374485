import { GlobalAnalytics } from "../../analytics";

const analytics = new GlobalAnalytics();

// Page Load
export const engageStatsPageLoad = (pageLoad) => {
  pageLoad &&
    analytics.firePageLoad({
      app: pageLoad.appId,
      tag: pageLoad.tagId,
      section: pageLoad.section,
      page: pageLoad.page,
      subsection: pageLoad.subsection,
      technologyName: pageLoad.technology_name,
    });
};


// Tracking click for a link click in your progress
export const myStatsLinkClick = (clickAnalytics, analyticsPage, analyticsCategory, moreInfoText) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: analyticsPage,
      category: analyticsCategory,
      label: "How Do Points Work Link",
      contentTitle: moreInfoText
    });
  }
};

// Tracking click for a back button click
export const backButtonClick = (clickAnalytics, analyticsPage, analyticsCategory) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: analyticsPage,
      category: analyticsCategory,
      label: "Back Button Click"
    });
  }
};


// Tracking click for a pagination button click
export const paginationButtonClick = (clickAnalytics, label) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: clickAnalytics.page || "My Stats",
      category: clickAnalytics.category || "Engage XP",
      label: label
    });
  }
};


// Tracking click for a the badges dropdown click
export const badgesDropdownClick = (clickAnalytics, dropdownValue) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: "My Stats",
      category: "Engage XP",
      label: "Badges Dropdown",
      contentTitle: dropdownValue
    });
  }
};


// Tracking click for a click on Check My Balance
export const checkBalanceClick = (clickAnalytics, analyticsPage, analyticsCategory) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: analyticsPage,
      category: analyticsCategory,
      label: "Check My Balance Button",
      contentTitle: "Check My Balance"
    });
  }
};

// Tracking click for a click on Check My Balance
export const checkBalanceClickStats = (clickAnalytics, analyticsPage, analyticsCategory) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: analyticsPage,
      category: analyticsCategory,
      label: "Check My Balance Button",
      contentTitle: "Check My Balance"
    });
  }
};