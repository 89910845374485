import AppSettings from "../../appsettings";
import "../../styles/scss/model-overview-v2/incentives-button.scss";
import { isMobileView } from "../utils/getScreenSize";

export const IncentivesButton = (props) => {
  const incentivesIcon =
    AppSettings.AWSImgRepo.resources + "en/incentivesIcon.png";

  return (
    <div className="incentives-button" onClick={props?.incentivesFunction}>
      <img className="location-icon" src={incentivesIcon} />
      <div className="incentives-text">
        {isMobileView(window.innerWidth)
          ? "Check Local Incentives"
          : "Local Incentives"}
      </div>
    </div>
  );
};
