import 'whatwg-fetch';
import httpOption from './httpOption';
import AppSettings from '../appsettings';

export default async function authLogger(spinId, message) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/authLogs`;
  const options = httpOption.POST();
  options.body = JSON.stringify({message: `Log for ${spinId}: ${message}`});

  const logsStatus = await fetch(url, options);

  if(!logsStatus.ok){
    console.log('Auth Log failed');
  }
}
