import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from "react-router";
import AppSettings from "../../../appsettings";
import EmptyBox from '../../../screens/engageXP/components/emptyBox';
import XPProgressBar from '../../../screens/engageXP/components/progressBar';
import { renderRewardText, renderTournamentRewards } from '../../../screens/engageXP/utils';
import '../../../styles/css/missionCard.css';
import Badge from '../Badge';
import RoundedButton from '../roundedButton';
import { missionCardButtonClick } from '../../../screens/engageXP/missionList.analytics';
import { getRelativeTime, getMissionImage } from '../../../screens/engageXP/utils';

const s3Path = AppSettings.AWSImgRepo.resources;
const repeatableIcon = "en/repeatable_icon.svg";
const lockIcon = "en/lock_icon.svg";

const MissionCard = ({ mission, isLoadingMissions, getBlurredBadgeText, getColoredBadgeInfo, waitForMissionsLabels, analyticsData, proPointReward, isUserEligible, proPointsEarned, xpEarned, tournament }) => {
    const history = useHistory();
    const [coloredBadgeInfo, setColoredBadgeInfo] = useState();
    const missionDate = mission?.endTime ? new Date(mission.endTime * 1000) : null;
    const currentDate = new Date();
    const isMissionExpired = missionDate && missionDate < currentDate;
    const shouldDisplayExpiration = !!missionDate;

    useEffect(() => {
        if (mission?.labels) {
            const match = getColoredBadgeInfo(mission?.labels);
            setColoredBadgeInfo(match);
        }
    }, [mission]);

    const renderLockedText = (reason) => {
        if (!!reason?.levels?.length) {
            return `Must be ${reason?.levels?.[0]?.name} to unlock`;
        }
        if (!!reason?.challenges?.length) {
            return `Complete ${reason?.challenges?.[0]?.name} to unlock`;
        }
        if (reason?.type.toLowerCase() === 'starttime') {
            const relativeTime = getRelativeTime(new Date(+mission?.startTime * 1000));
            return `Opens ${relativeTime}`;
        }
        return '';
    };

    const renderActionButtonText = (mission) => {
        if (mission?.inProgress) return 'Resume Mission';
        if (mission?.repeatable && mission?.completionCount >= 1) return 'Play Again';
        if (!mission?.completionCount) return 'Play Now';
    };

    const navigateToMissionPage = () => {
        if(analyticsData) {
            const clickAnalytics = analyticsData.clickAnalytics;
            const contentTitle = (mission?.displayName || mission?.name) + " - " + renderActionButtonText(mission);
            missionCardButtonClick(clickAnalytics, contentTitle);
        }

        if (!tournament) {
            history.push(`/engage-xp/missions/${mission.id}`);
        } else {
            history.push(`/engage-xp/tournaments/${mission.id}`)
        }
        
    };

    const renderCardFooter = () => {
        if (tournament && mission?.completionCount >=1) {
            return (
                <div className='tournament-footer-container'>
                    <div className='tournament-footer-column'>
                        <div className='tournament-footer-label'>Pro Points earned</div>
                        <div className='tournament-footer-value'>{proPointsEarned}</div>
                    </div>
    
                    <div className='tournament-footer-column'>
                        <div className='tournament-footer-label'>XP Points earned</div>
                        <div className='tournament-footer-value'>{xpEarned}</div>
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    {mission?.inProgress && <div className='mission-progress'>
                        <XPProgressBar leftLabel="In Progress" variant={tournament ? 'tournamentCard' : 'missionCard'} percent={mission?.percentComplete} />
                    </div>}
    
                    {!mission?.locked?.status && !!renderActionButtonText(mission) && <RoundedButton
                        className="customColor bold"
                        color="#000"
                        textColor="#fff"
                        height="44px"
                        onClick={navigateToMissionPage} title={renderActionButtonText(mission)}
                    />}
                    {mission?.locked?.status && <div className='mission-locked'>
                        <img src={s3Path + lockIcon} />
                        <span>{renderLockedText(mission?.locked?.reasons?.[0])}</span>
                    </div>}
                </>
            );
        }
    };

    return <div className="mission-card-container">
        {isLoadingMissions ? (
            <Skeleton style={{ height: 370 }} />
        ) : (
            <>
                <div className='mission-card-body'>
                    <div className='mission-card-hero' style={{ backgroundImage: getMissionImage(mission) }}>
                        {getBlurredBadgeText(mission?.labels, mission?.completionCount) && <Badge type="blurred">{getBlurredBadgeText(mission?.labels, mission?.completionCount)}</Badge>}
                    </div>
                    <div className='mission-infos'>
                        {coloredBadgeInfo ? (
                            <Badge type="colored" color={coloredBadgeInfo.color}>
                                {coloredBadgeInfo.name}
                            </Badge>
                        ) : (
                            waitForMissionsLabels ? <Skeleton width={64} height={33} /> : <EmptyBox width={64} height={33} />
                        )}
                        <p className='mission-expiration'>
                            {shouldDisplayExpiration ? (
                                isMissionExpired ? "Expired" : `Exp. ${missionDate.toLocaleString('default', { month: 'short' })} ${missionDate.getDate()}`
                            ) : null}
                        </p>
                    </div>
                    <h2>{mission?.displayName || mission?.name}</h2>
                    {tournament && isUserEligible ? 
                        renderTournamentRewards(mission?.rewards, proPointReward)
                    :
                        <h1>{renderRewardText(mission?.rewards)}</h1>
                    }
                    {mission?.repeatable && <div className='mission-repeatable'>
                        <img src={s3Path + repeatableIcon} />
                        <span><b>Repeatable</b> (Completed {mission?.completionCount} {mission?.completionCount !== 1 ? "times" : "time"})</span>
                    </div>}
                </div>
                <div className='mission-card-footer'>
                    {renderCardFooter()}
                </div>
            </>
        )}
    </div>
}

export default MissionCard;