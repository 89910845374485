import { GlobalAnalytics } from "../../analytics";

const analytics = new GlobalAnalytics();

// Page Load
export const xpFAQsPageLoad = (pageLoad) => {
  pageLoad &&
    analytics.firePageLoad({
      app: pageLoad.appId,
      tag: pageLoad.tagId,
      section: pageLoad.section,
      page: pageLoad.page,
      subsection: pageLoad.subsection,
      technologyName: pageLoad.technology_name,
    });
};

// Tracking click for a tab click
export const tabClick = (clickAnalytics, label) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: clickAnalytics.tagId,
      app: clickAnalytics.appId,
      section: clickAnalytics.section,
      page: "Engage XP",
      category: "FAQs",
      label: label
    });
  }
};