export function getAccessLevelBasedOnJobCode(isAdmin, primaryJobCode, isGuest) {
  let accessLevel;

  // if flagged as admin, job code doesn't need to be checked
  if (isAdmin) {
    accessLevel = 4;
  } else if (primaryJobCode === ('96' || '123' || '142' || '143')) {
    accessLevel = 3;
  } else if (primaryJobCode === ('72' || '73' || '74' || '75' || '79' || '81' || '88' || '96' || '106' || '107' || '152')) {
    accessLevel = 2;
  } else if (isGuest) {
    accessLevel = 0;
  } else {
    accessLevel = 1;
  }
  return accessLevel;
}
