import { GlobalAnalytics } from "../../analytics";

const analytics = new GlobalAnalytics();

// Page Load
export const xpTournamentPageLoad = (pageLoad) => {
    pageLoad &&
        analytics.firePageLoad({
            tag: "35.1",
            page: "XP Tournament​",
            section: "Product",
            subsection: pageLoad.subsection,
            technologyName: pageLoad.technology_name,
        });
};

// Clicks
export const xpTournamentClick = (pageLoad) => {
    pageLoad &&
        analytics.fireOnClick({
            tag: "29.2",
            page: "XP Tournament​",
            section: "Product",
            category: pageLoad.category,
            label: pageLoad.label,
            contentTitle: pageLoad.content_title,
        });
};
