import React, { useEffect, useState } from "react";
import SelectionCard from "./SelectionCard";
import RoundedButton from "../../../../components/molecules/roundedButton";
import S from "../../../../styles/scss/beyondZero/quizQuestion.module.scss";

const QuizQuestion = ({
  questionL1,
  questionL2,
  questionL3,
  subText,
  choices,
  numChoicesAllowed,
  variableNumChoices,
  isLastQuestion,
  onNext,
  onBack,
  onSubmit,
  previousAnswers,
  shouldHide6Plus,
  analyticsActions
}) => {
  const [selected, setSelected] = useState(previousAnswers.choices);

  useEffect(() => {
    setSelected(previousAnswers.choices.filter((choice) => shouldHide6Plus ? choice !== 5 : true));
  }, [previousAnswers, shouldHide6Plus])

  const verifyAnswers = () => {
    if (variableNumChoices && selected.length > 0) {
      return true;
    } else if (!variableNumChoices && selected.length === numChoicesAllowed) {
      return true;
    } else if (variableNumChoices && selected.length === 0) {
      return false;
    }
    return false;
  }

  const handleCardToggleAnalytics = (items, index) => {
    const isSelected = items.find(item => item === index);
      analyticsActions.onClick({
        label: `Card ${isSelected ? 'Deselect' : 'Select'}`,
        content_title: choices[index].title
      })
  }

  const selectChoiceHandler = (index) => {
    setSelected((prev) => {
      handleCardToggleAnalytics(prev, index);

      if (numChoicesAllowed === 1) {
        return [index];
      } else {
        if (prev.includes(index)) {
          return prev.filter((item) => item !== index);
        } else {
          return [...prev, index];
        }
      }
    })
  }

  const filter6PlusIfNecessary = (choice) => {
    if (!isLastQuestion) {
      return true;
    }
    if (shouldHide6Plus) {
      return choice.title !== "Seats 6+";
    }
    return true;
  }

  return (
    <div className={S.container}>
      <div className={S["title-section"]}>
        <h1>{questionL1}</h1>
        <h1>{questionL2}</h1>
        {questionL3 &&<h1>{questionL3}</h1>}
      </div>
      <span>{subText}</span>
      <div className={S["choices-container"]}>
        {choices.filter(filter6PlusIfNecessary)
          .map((choice, index) => (
            <SelectionCard
              key={choice.title}
              onClick={() => selectChoiceHandler(index)}
              title={choice.title}
              image={choice.image}
              isSelected={selected.includes(index)}
            />
          ))}
      </div>
      <div className={S["button-area"]}>
        <RoundedButton className={S["button-style"]} title="Back" onClick={() => onBack()} active={false} />
        {isLastQuestion ?
          <RoundedButton disabled={!verifyAnswers()} className={`${S["button-style"]} ${S["red-button"]} ${!verifyAnswers() ? S["disabled"] : ""}`} title="See Results" onClick={() => onSubmit(selected)} active={true} /> :
          <RoundedButton disabled={!verifyAnswers()} className={`${S["button-style"]} ${!verifyAnswers() ? S["disabled"] : S["active"]}`} title="Next" onClick={() => onNext(selected)} active={true} />
        }
      </div>
    </div>
  );
}

export default React.memo(QuizQuestion, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});
