import React, { useEffect, useState } from 'react';
import AppSettings from '../../appsettings.js';
import RedLine from '../../components/atoms/RedLine';
import MobileBackButton from '../../components/molecules/MobileBackButton.js';
import { injectIntl } from 'react-intl';
import { VehicleSelection } from './towingPayload-vehicleSelection';
import { VehicleFilter } from './towingPayload-vehicleFilter';
import getMisc from '../../services/miscService.js';
import {getFeaturesAndSpecs} from '../../services/featuresAndSpecsService';
import {getTrims} from '../../services/trimsService';
import { TowingPayloadCalculator } from './towingPayload-calculator';
import { ExpertCalculatorContainer } from './towingPayload-expertContainer';
import { BeginnerCalculatorContainer } from './towingPayload-beginnerContainer';
import { CalculatorMenu } from './towingPayload-calculatorMenu';
import getAnalytics from '../../services/analyticsService';
import '../../styles/css/towingPayload.css';
import { isDesktopView, isTabletView, isMobileView } from '../utils/getScreenSize.js';
import { backButtonClick } from './towingPayload-analytics';
import { postMessageToApp } from '../utils/hybridAppHelpers.js';
import { useHistory, useParams } from 'react-router-dom';

export const TowingPayloadComponent = (props) => {
  const [page, setPage] = useState('Home');
  const [vehicleList, setVehicleListData] = useState(null);
  const [vehicleSelection, setVehicleSelection] = useState(null); //current model name/year including suggestion for calculator page
  const [initialVehicleSelection, setInitialVehicleSelection] = useState(null); //initial model name/year for filter page
  const [trimSelection, setTrimSelection] = useState(null);
  const [towingInfo, setTowingInfo] = useState(null);
  const [driveInfo, setDriveInfo] = useState(null);
  const [weightInfo, setWeightInfo] = useState(null);
  const [trimInfo, setTrimInfo] = useState(null);
  const [topModelCodes, setTopModelCodes] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [prevPage, setPrevPage] = useState(null);
  const [vehicleType, setVehicleType] = useState("");
  const [infoPopUpData,setinfoPopUpData] = useState(null);
  const [analyticsData, setAnalyticsData] = useState(null);
  const [device, setDevice] = useState('desktop');
  const [modelCodeVehicleData, setModelCodeVehicleData] = useState();
  const [calculatorType, setCalculatorType] = useState();
  const filePath = AppSettings.AWSImgRepo.resources + 'en/';

  const params = useParams();
  const history = useHistory();

  useEffect( async () => {
    getTowingPayloadAnalytics();
    await getTowingData()
  },[]);
  
  const validModelName = (modelName, vehicles) => {
    return vehicles?.find(v => v?.modelName?.toLowerCase() === modelName.toLowerCase()
    ) 
  }

  const validModelYear = (modelYear, modelData) => {
    return parseInt(modelYear) >= parseInt(modelData.earliestYear) && parseInt(modelYear) <= parseInt(modelData.latestYear)
  }

  const validModelCode = (modelCode, codeData) => {
    return codeData?.find(c => c === modelCode)
  }

  const getVehicleDisplayName = (urlModelName, vehicles) => {
    return vehicles?.find(v => v?.modelName?.toLowerCase() === urlModelName.toLowerCase())?.modelName
  }

  useEffect(async () => {
    // URL validation
    if(((!!params.model || !!params.year) && !params.code) && vehicleList) {
      const modelParamData = params.model && validModelName(params.model, vehicleList.vehicles);
      if (modelParamData && params.year) {
        if (!validModelYear(params.year, modelParamData)) {
          window.location.replace(`/towingPayload/${params.model}/${modelParamData.latestYear}`)
        } else {
          setPage("Filter")
        }
      } else {
        history.push('/towingPayload');
        setPage("Home")
      }
    } else if (params.model && params.year && params.code) {
      if(modelCodeVehicleData) {
        if(validModelCode(params.code, Object.keys(modelCodeVehicleData))) {
          setTrimSelection(modelCodeVehicleData[params.code]);
          setPage('Calculator')
          setCalculatorType("Expert")
        } else if (trimSelection) {
          setPage('Calculator')
          setCalculatorType("Expert")
        } else {
          history.push(`/towingPayload/${params.model}/${params.year}`)
        }
      } else {
        setPage("Filter")
      }
    }

    switch (page) {
      case 'Home':
        setPrevPage(null)
        break;
      case 'Filter':
        setPrevPage('Home')
        setVehicleType(getVehicleType(params.model));
        setVehicleSelection({
          modelName: getVehicleDisplayName(params.model, vehicleList.vehicles),
          modelYear: params.year
        });
        //only load filter data once
        if(!dataLoaded && vehicleList){
          //only load data if not already loaded
          //avoid re-load data when navigating back
          await allVehicleFilterData(params.model.toLowerCase(), params.year);
        }
        break;
      case 'Calculator':
        if (calculatorType === "Expert") {
          setPrevPage('Filter')
        }
        window.scrollTo(window.top);
        break;
      default:
        setPrevPage(null);
        break;
    }
  },[vehicleList, modelCodeVehicleData, page, params.model, params.year, params.code]);

  useEffect(() => {
    setVehicleSelection(initialVehicleSelection);
  },[initialVehicleSelection]);

  useEffect(() => {
    vehicleSelection && setVehicleType(getVehicleType(vehicleSelection.modelName));
  },[vehicleSelection]);

  useEffect( () => {
    if (towingInfo && driveInfo && weightInfo && trimInfo && topModelCodes){
      setDataLoaded(true)
    }
  },[towingInfo,driveInfo,weightInfo,trimInfo,topModelCodes]);

  const getTowingPayloadAnalytics = async () => {
    const data = await getAnalytics("towingPayload");
    setAnalyticsData(data);
  };
  
  useEffect(() => {
    updateDevice();
    window.addEventListener("resize", updateDevice);
    return () => window.removeEventListener("resize", updateDevice);
  }, []);

  const updateDevice = () => {
    if (isDesktopView(window.innerWidth)) {
      setDevice('desktop');
    } else if (isTabletView(window.innerWidth)) {
      setDevice('tablet');
    } else if (isMobileView(window.innerWidth)) {
      setDevice('mobile');
    }
  }

  const createInfoPopUpData = ()=>{
    let infoPopUpData = {}
    // let stripsSpace
    vehicleList.towingDefinitions[0].content.forEach(element => {  
      // stripsSpace = element.term.replace(/\s+/g, '')
      infoPopUpData[element.popUpKey]  = {
        title: element.term,
        subtitle: element.subHeader,
        definition: element.definition
      }
    });
    setinfoPopUpData(infoPopUpData)
  }
  const getVehicleType = (modelName) => {
    const model = modelName ? modelName.toLowerCase() : '';
    if(model === "tacoma" || model === "tundra") return "truck"
    else return "suv";
  }

  const getTowingData = async()=>{
    try{
      const data = await getMisc('towingPayload', 'en')
      setVehicleListData(data.items);
    }catch(e){
      console.log(e)
    }
  }
  const getSelectedVehicleData = async(modelName,modelYear)=>{
    try{
      const data = await getFeaturesAndSpecs(modelName,modelYear, 'en')
      setTowingInfo(getVehicleIndex(data.featuresAndSpecs.Specs,"Towing").featureSpecs[0]);
      setWeightInfo(getVehicleIndex(data.featuresAndSpecs.Specs,"Weights").featureSpecs);
      setDriveInfo(getVehicleIndex(data.featuresAndSpecs.Specs,"Drive Systems").featureSpecs[0]);
    }catch(e){
      console.log(e)
    }
  }
  const getVehicleIndex = (featureData,name) => {
    var content
    featureData.forEach(function(element,index){
      if (element.name.includes(name)){
        content = featureData[index]
      }
    })
    return content
  }
  const getSelectedVehicleTrimData = async(modelName,modelYear)=>{
    try{
      const data = await getTrims(modelName,modelYear, 'en')
      data && setTrimInfo(data.trims);
    }catch(e){
      console.log(e)
    }
  }
  const getTopModelCodes =async (modelName) =>{
    let topModelCodes = []
    let filteredModelCodes = vehicleList.modelCodes.filter(e => e.modelName.toLowerCase() == modelName.toLowerCase())[0]
    let keys = Object.keys(filteredModelCodes).filter(e => e != 'modelName')
    topModelCodes = keys.map(function(key) {
      return filteredModelCodes[key]
    });
    setTopModelCodes(topModelCodes)
  }

  const allVehicleFilterData = async (modelName,modelYear)=>{
    await getSelectedVehicleData(modelName,modelYear)
    await getSelectedVehicleTrimData(modelName,modelYear)
    await getTopModelCodes(modelName)
    createInfoPopUpData()
  }

  const getPage = () => {
    switch (page) {
      case 'Home':
        window.scrollTo(window.top);
        return (
          <div>
            <div className={`towingPayload-HomeMenu`}>
              <CalculatorMenu 
                  faqData={vehicleList.faq}
                  videosData={vehicleList.videos}
                  towingDefinitionsData={vehicleList.towingDefinitions}
                  commonWeightsData={vehicleList.commonWeights}
                  desktopOrientation = "horizontal"
                  clickAnalytics={analyticsData && analyticsData.clickAnalytics}
                  pageLoadAnalytics={analyticsData && analyticsData.pageLoad}
                  imagePath={AppSettings.AWSImgRepo.towingPayload}  
                  page={page}
              />
            </div>
            <VehicleSelection
              vehicleData={vehicleList.vehicles}
              setPage={setPage}
              setInitialVehicleSelection={setInitialVehicleSelection}
              imagePath={AppSettings.AWSImgRepo.resources + 'en/'}
              jellyImagePath={AppSettings.AWSImgRepo.jellybeans + 'profile/'}
              clickAnalytics={analyticsData && analyticsData.clickAnalytics}
              pageLoadAnalytics={analyticsData && analyticsData.pageLoad}
              device={device}
              miscText={vehicleList.miscText}
            />
          </div>);
      case 'Filter':
        return dataLoaded && (
          <div>
            <div className={`towingPayload-FilterMenu`}>
              <CalculatorMenu 
                  faqData={vehicleList.faq}
                  videosData={vehicleList.videos}
                  towingDefinitionsData={vehicleList.towingDefinitions}
                  commonWeightsData={vehicleList.commonWeights}
                  desktopOrientation = "horizontal"
                  clickAnalytics={analyticsData && analyticsData.clickAnalytics}
                  pageLoadAnalytics={analyticsData && analyticsData.pageLoad}
                  imagePath={AppSettings.AWSImgRepo.towingPayload}
                  vehicleSelection={vehicleSelection}
                  page={page}
              />
            </div>
            <VehicleFilter
              setPage={setPage}
              towingInfo={towingInfo}
              cabList={vehicleList.towingInfo && vehicleList.towingInfo.cabs}
              bedLengths={vehicleList.towingInfo && vehicleList.towingInfo.bedLength}
              driveTypes={vehicleList.towingInfo && vehicleList.towingInfo.driveType}
              weightInfo={weightInfo}
              driveInfo={driveInfo}
              trimInfo={trimInfo}
              topModelCodes={topModelCodes}
              imagePath={AppSettings.AWSImgRepo.resources + 'en/'}
              vehicleSelection={{
                modelName: getVehicleDisplayName(params.model, vehicleList.vehicles),
                modelYear: params.year
              }}
              setTrimSelection={setTrimSelection}
              infoPopUpData={infoPopUpData}
              clickAnalytics={analyticsData && analyticsData.clickAnalytics}
              pageLoadAnalytics={analyticsData && analyticsData.pageLoad}
              device={device}
              setModelCodeVehicleData={setModelCodeVehicleData}
              setCalculatorType={setCalculatorType}
            />
          </div>);
      case 'Calculator':
        if (calculatorType === "Expert") {
          return vehicleList && (
            <div>
              <ExpertCalculatorContainer 
                setPage={setPage}
                imagePath={AppSettings.AWSImgRepo.towingPayload}
                vehicleSelection={vehicleSelection}
                towingInfo={towingInfo}
                trimSelection={trimSelection}
                infoPopUpData={infoPopUpData}
                vehicleType={vehicleType}
                miscText={vehicleList.miscText}
                clickAnalytics={analyticsData && analyticsData.clickAnalytics}
                pageLoadAnalytics={analyticsData && analyticsData.pageLoad}
                device={device}
                vehicles={vehicleList.vehicles}
                setTrimSelection={setTrimSelection}
                setVehicleSelection={setVehicleSelection}
                cabList={vehicleList.towingInfo && vehicleList.towingInfo.cabs}
                bedLengths={vehicleList.towingInfo && vehicleList.towingInfo.bedLength}
                driveTypes={vehicleList.towingInfo && vehicleList.towingInfo.driveType}
                setCalculatorType={setCalculatorType}
                setModelCodeVehicleData={setModelCodeVehicleData}
              />
              <div className={`towingPayload-CalculatorMenu`}>
                <CalculatorMenu 
                    faqData={vehicleList.faq}
                    videosData={vehicleList.videos}
                    towingDefinitionsData={vehicleList.towingDefinitions}
                    commonWeightsData={vehicleList.commonWeights}
                    desktopOrientation = "vertical"
                    clickAnalytics={analyticsData && analyticsData.clickAnalytics}
                    pageLoadAnalytics={analyticsData && analyticsData.pageLoad}
                    imagePath={AppSettings.AWSImgRepo.towingPayload}
                    vehicleSelection={vehicleSelection}
                    trimSelection={trimSelection}
                    page={page}
                />
              </div>
            </div>);
        } else if (calculatorType === "Beginner") {
          return vehicleList && (
            <div>
              <BeginnerCalculatorContainer 
                setPage={setPage}
                imagePath={AppSettings.AWSImgRepo.towingPayload}
                vehicleSelection={vehicleSelection}
                towingInfo={towingInfo}
                trimSelection={trimSelection}
                infoPopUpData={infoPopUpData}
                vehicleType={vehicleType}
                miscText={vehicleList.miscText}
                clickAnalytics={analyticsData && analyticsData.clickAnalytics}
                pageLoadAnalytics={analyticsData && analyticsData.pageLoad}
                device={device}
                vehicles={vehicleList.vehicles}
                setTrimSelection={setTrimSelection}
                setVehicleSelection={setVehicleSelection}
                cabList={vehicleList.towingInfo && vehicleList.towingInfo.cabs}
                bedLengths={vehicleList.towingInfo && vehicleList.towingInfo.bedLength}
                driveTypes={vehicleList.towingInfo && vehicleList.towingInfo.driveType}
                setCalculatorType={setCalculatorType}
                setModelCodeVehicleData={setModelCodeVehicleData}
              />
              <div className={`towingPayload-CalculatorMenu`}>
                <CalculatorMenu 
                    faqData={vehicleList.faq}
                    videosData={vehicleList.videos}
                    towingDefinitionsData={vehicleList.towingDefinitions}
                    commonWeightsData={vehicleList.commonWeights}
                    desktopOrientation = "vertical"
                    clickAnalytics={analyticsData && analyticsData.clickAnalytics}
                    pageLoadAnalytics={analyticsData && analyticsData.pageLoad}
                    imagePath={AppSettings.AWSImgRepo.towingPayload}
                    vehicleSelection={vehicleSelection}
                    trimSelection={trimSelection}
                    page={page}
                />
              </div>
            </div>
          );
        }
        break;
      default:
        setPage('Home');
        break;
    }
  };

  const handleBackClick = () => {
    if (page == "Calculator" && calculatorType == "Beginner") {
      setCalculatorType("Expert");
    } else {
      setPage(prevPage);
      if(prevPage == "Home") {
        setTrimInfo(null);
        setWeightInfo(null);
        setDriveInfo(null);
        setTowingInfo(null);
        setTopModelCodes(null);
        setDataLoaded(false);
        history.push(`/towingPayload`)
      } else if (prevPage == "Filter") {
        setTrimSelection(null);
        history.push(`/towingPayload/${initialVehicleSelection?.modelName || vehicleSelection.modelName}/${initialVehicleSelection?.modelYear || vehicleSelection.modelYear}`)
      }
    }
    analyticsData && backButtonClick(analyticsData.clickAnalytics, vehicleSelection.modelYear, vehicleSelection.modelName, trimSelection, page);
  };

  const renderBackButton = () => {
    if (window.ReactNativeWebView) {
      return (
        <MobileBackButton />
      );
    }
    if (prevPage) {
      return (
        <button className="towingPayload-backButton" onClick={handleBackClick}>
          <img
            className="leftArrow"
            src={`${AppSettings.AWSImgRepo.resources}en/${device=="desktop"?"black_left_arrow.png":"red_left_arrow.png"}`}
          />
          <div>Back</div>
        </button>
      );
    }
    return null
  };

  return (
    <div className="towingPayload">
      {renderBackButton()}
      <div className="towingPayload-header">
        <div className="towingPayload-title">
          {device=="mobile" ? <div><div>Towing &</div><div>Payload Calculator</div></div> : <div>Towing & Payload Calculator</div>}
        </div>
        <RedLine style={{
          margin: 'auto',
          width: (device=="desktop" ? "71px" : (device=="tablet" ? "57px" : "45px")),
          paddingBottom: "0px"
        }} />
      </div>
      <div className="towingPayload-body">
        {vehicleList && getPage()}
      </div>
    </div>
    );
};

export default injectIntl(TowingPayloadComponent);
