import { useEffect, useMemo, useState } from "react";
import DropdownV2 from "../../../../components/molecules/DropdownV2";

import applyMarkdown from "../../../utils/applyMarkdown";
import { isMobileView } from "../../../utils/getScreenSize";
import { isImageFilename } from "./utils";
import appsettings from "../../../../appsettings";

import "../../../../styles/scss/beyondZero/electrifiedChart.scss";

const renderRows = (electrifiedChartData, selectedCategoryId = null) => {
  const { rowLabels } = electrifiedChartData;

  return rowLabels?.map((label, index) => (
    <div className="bz-chart-row">
      <div className="bz-chart-row-title">{applyMarkdown(label)}</div>
      {renderRowValues(index, electrifiedChartData, selectedCategoryId)}
    </div>
  ));
};

// type can be TABLE for tablet up, and linear for mobile
const renderRowValues = (
  rowIndex,
  electrifiedChartData,
  selectedCategoryId = null
) => {
  const { columns } = electrifiedChartData;

  const rowValues = !selectedCategoryId
    ? columns.map(({ data }) => data[rowIndex])
    : [columns.find((column) => column.headerTitle == selectedCategoryId).data[rowIndex]];

  return rowValues.map((item) => (
    <div className="bz-chart-row-value">
      {isImageFilename(item) ? (
        <img src={`${appsettings.AWSImgRepo.beyondZero}${item}`} />
      ) : (
        applyMarkdown(item)
      )}
    </div>
  ));
};

const DesktopChart = ({ electrifiedChartData }) => {
  const renderHeaders = ({ columns }) => {
    return columns?.map(({ headerImage, headerTitle }) => (
      <div className="bz-chart-header-item">
        <img src={`${appsettings.AWSImgRepo.beyondZero}${headerImage}`} />
        <div className="bz-chart-header-title">{headerTitle}</div>
      </div>
    ));
  };

  return (
    <div className="bz-chart-page">
      <div className="bz-chart-container">
        <div className="bz-chart-header-wrapper">
          <div className="bz-chart-header-item-title"></div>
          {renderHeaders(electrifiedChartData)}
        </div>
        <div className="bz-chart-data-wrapper">
          {renderRows(electrifiedChartData)}
        </div>
      </div>
    </div>
  );
};

const MobileChart = ({ electrifiedChartData }) => {
  const [dropdownSelectedOption, setDropdownSelectedOption] = useState();

  const items = useMemo(() => {
    return electrifiedChartData.columns?.map(
      ({ headerImage, headerTitle }) => ({
        value: headerTitle,
        label: (
          <div style={{ display: 'flex' }}>
            <img
              src={appsettings.AWSImgRepo.beyondZero + headerImage}
              style={{ width: 23, marginLeft: 0, marginRight: 10 }}
            />
            <span>{headerTitle}</span>
          </div>
        ),
      })
    );
  }, [electrifiedChartData]);

  useMemo(() => {
    if (electrifiedChartData) {
      setDropdownSelectedOption(electrifiedChartData.columns[0].headerTitle)
    }
  }, [electrifiedChartData])

  return (
    <div className="bz-chart-page">
      <div className="bz-chart-container">
        <div className="bz-chart-header-wrapper">
          <DropdownV2
            style={"white-translucent"}
            options={items}
            value={dropdownSelectedOption}
            onValueChange={(item) => setDropdownSelectedOption(item?.value)}
          />
        </div>
        <div className="bz-chart-data-wrapper">
          {renderRows(electrifiedChartData, dropdownSelectedOption)}
        </div>
      </div>
    </div>
  );
};

const ElectrifiedChart = ({ electrifiedChartData }) => {
  return isMobileView(window.innerWidth) ? (
    <MobileChart electrifiedChartData={electrifiedChartData} />
  ) : (
    <DesktopChart electrifiedChartData={electrifiedChartData} />
  );
};

export default ElectrifiedChart;
