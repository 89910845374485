import React from 'react';
import { FormattedMessage } from 'react-intl';

export function TCUVComparisonChart(props) {
  const getColorFromIndex = function (index) {
    if (index === 1) return 'dark-gray';
    if (index === 0 || index % 2) return 'white';
    if (index % 2) return 'white';
    return 'light-gray';
  };

  const convertToArray = function (items) {
    const row = [];
    for (let i = 1; i < 7; i++) {
      const competitor = `Competitor ${i.toString()}`;
      if (items[competitor]) {
        row.push(items[competitor]);
      } else row.push('');
    }
    return row;
  };

  const chartColumns = function (items, colorClass, rowHeight) {
    items = convertToArray(items);
    if (items) {
      const columns = items.map((item, index) => {
        const columnClassName = `small-2 medium-2 large-2 columns chart-cell ${colorClass}${index === 0 ? ' sticky-toyota-column' : ''}`;
        return (
          <div key={index} className={columnClassName} data-equalizer-watch={rowHeight}>
            {item}
          </div>
        );
      });
      return columns;
    }
  };

  const imageRow = function (items) {
    items = convertToArray(items);
    if (items) {
      const columns = items.map((item, index) => {
        const columnClassName = `small-2 medium-2 large-2 columns chart-image ${index === 0 ? 'sticky-toyota-column' : ''}`;
        return (
          <div key={index} className={columnClassName}>
            <img key={index} src={encodeURI(props.imagePath + item)} alt="" />
          </div>
        );
      });
      return columns;
    }
  };

  const chartRows = props.chartData ? props.chartData.map((item, index) => {
    const colorClass = getColorFromIndex(index);
    const rowHeight = `rowHeight${index}`;
    return (
      <div key={index} className="row" data-equalizer={rowHeight}>
        <div className={`small-2 medium-2 large-2 columns bold-text sticky-column ${colorClass}${index === 0 ? ' image-header' : ''}`} data-equalizer-watch={rowHeight}>
          {item.rowHeader !== 'Images' ? item.rowHeader : null}
        </div>
        <div className="small-10 medium-10 large-10 columns">
          <div className="row">
            {index === 0 ? imageRow(item.rowContent) : chartColumns(item.rowContent, colorClass, rowHeight)}
          </div>
        </div>
      </div>
    );
  }) : null;

  return (
    <div className="scroll-container">
      <div className="chart-container">
        {chartRows}
      </div>
      <FormattedMessage id="tcuv.comparisonChart.dataSource" default="Source: Edmunds.com" />
    </div>
  );
}
