import { Component } from 'react';
import { injectIntl } from 'react-intl';
import queryString from 'query-string';
import history from '../../app/history';
import { TSSResourcesTemplate } from './tssResources.tpl';

const analyticsFunctions = require('./tssResources.analytics.js');

const formatFilterID = (filter) => {
  const id = filter.replace('%20', ' ');
  return id;
};
export class TSSResourcesComponent extends Component {
  constructor(props) {
    super(props);

    const query = queryString.parse(location.search);
    const setActiveFilter = query.tab ? query.tab : formatFilterID(props.filters[0].id);

    this.state = {
      articleTag: props.filters.find(filter => filter.id === setActiveFilter).articleTag,
    };

    // Provide scope of 'this' to analytics functions
    this.switchFilter = this.switchFilter.bind(this);
    this.sendArticleTagClick = analyticsFunctions.sendArticleTagClick.bind(this);
    this.resourceClick = analyticsFunctions.resourceClick.bind(this);
    this.filterClick = analyticsFunctions.filterClick.bind(this);
    this.sortClick = analyticsFunctions.sortClick.bind(this);
    this.sendPageLoadAnalytics = analyticsFunctions.sendPageLoadAnalytics.bind(this);
  }

  switchFilter(event) {
    this.setState({
      articleTag: event.target.name,
    });
    this.sendArticleTagClick(event.target.name);
  }

  componentDidMount() {
    this.sendPageLoadAnalytics();
  }

  render() {
    return TSSResourcesTemplate(this);
  }
}

export default injectIntl(TSSResourcesComponent);
