import { GlobalAnalytics } from "../../../analytics";

const analytics = new GlobalAnalytics();

export function selectDropdown(event){
    analytics.fireOnClick({
        tag: "29.2",
        section: "Product",
        page: event?.page,
        category : 'Second Trim Selector',
        label: event?.label || 'Model Year Select',
        contentTitle: event?.contentTitle,
        positionTitle: "Second Trim Selector",
        modelName: event?.modelName,
        modelYear: event?.modelYear,
        trim: event?.trim
      })
}

export function selectComparison(event){
    const label = event?.isOpen ? "Add Second Trim" : "Remove Second Trim";
    analytics.fireOnClick({
        tag: "29.2",
        section: "Product",
        page: event?.page,
        category: 'Second Trim Selector',
        label: label,
        modelName: event?.modelName,
        modelYear: event?.modelYear,
        trim: event?.trim
    })
}