import react, { useEffect, useState } from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import '../../../styles/css/towingPayload-gauge.css';
import { InfoPopUp } from '../towingPayload-infoPopUp';
import { isMobileView } from '../../../screens/utils/getScreenSize';
import { TooltipV2 } from '../../../components/molecules/tooltipV2';
import { iIconClick, popupOpenClick, popupCloseClick, popupMoreClick } from '../towingPayload-analytics';

const GcwrGauge = (props) => {
    const headerText = "GCWR";
    const footerText = `Max ${props.gcwr.toLocaleString()} lbs.`;

    const SvgPattern = () => {
        return (
            <svg className={"svg-pattern"}>
                <pattern id="blue" width="1" height="1">
                    <image width="200" height="200" xlinkHref={`${props.imagePath}blueWithStripesPattern.png`} />
                </pattern>
            </svg>
        );
    };

    const infoIconClick = (i, text) => {
        props.setCurrentPopupTooltip(i);
        props.clickAnalytics && iIconClick(props.clickAnalytics, props.vehicleSelection, props.trimSelection, text);
    };

    const getInfoPopup = () => (
        <TooltipV2
            closeTooltip={()=>props.setVisiblePopupTooltip(null)}
            title={props.infoPopUpData.title}
            subtitle={props.infoPopUpData.subtitle}
            description={props.infoPopUpData.definition}
            analyticsOpenTooltip={()=>popupOpenClick(props.clickAnalytics, props.vehicleSelection.modelYear, props.vehicleSelection.modelName, props.trimSelection, props.infoPopUpData.title, true)}
            analyticsCloseTooltip={()=>popupCloseClick(props.clickAnalytics, props.vehicleSelection.modelYear, props.vehicleSelection.modelName, props.trimSelection, props.infoPopUpData.title, true)}
            analyticsMoreClick={()=>popupMoreClick(props.clickAnalytics, props.vehicleSelection.modelYear, props.vehicleSelection.modelName, props.trimSelection, props.infoPopUpData.title, "MORE", true)}
        />
    );

    const Header = () => {
        return (
            <div className={"gauge-header"}>
                <div>{headerText}</div>
                <div onClick={()=>infoIconClick("gcwr", headerText)}><img className={"i-icon"} src={`${props.imagePath}infoIcon.png`} /></div>
                <div className="gaugeInfoPopUp">
                    {!isMobileView(window.innerWidth) && props.checkCurrentTooltipMatch("gcwr") && getInfoPopup()}
                </div>
                {isMobileView(window.innerWidth) && props.checkCurrentTooltipMatch("gcwr")  && getInfoPopup()}
            </div>
        );
    };

    const ReactSpeedometerElement = () => {

        const getSegmentsAndColors = () => {
            const darkGray = '#818386', blue = '#57C2D7', green = '#72B85F', lightGray = '#E9EAEA', red = '#EA0C1E';
            if (props.error) {
                return [[0, props.curbWeight, props.gcwr], [darkGray, red]];
            } else {
                return [[0, props.curbWeight, _.clamp(props.segmentPayload, 0, props.gcwr), _.clamp(props.segmentTowing, 0, props.gcwr), props.gcwr], [darkGray, blue, green, lightGray]];
            }
        };

        const className = props.error ? "react-speedometer-element" : "react-speedometer-element blue-segment-visible";

        return (
            <div className={className}>
                <ReactSpeedometer
                    customSegmentStops={getSegmentsAndColors()[0]}
                    segmentColors={getSegmentsAndColors()[1]}
                    maxSegmentLabels={0}
                    minValue={0}
                    maxValue={props.gcwr}
                    value={_.clamp(props.segmentTowing, 0, props.gcwr)}
                    needleColor={'black'}
                    width={212}
                    ringWidth={35}
                    needleHeightRatio={0.7}
                />
            </div>
        );
    };

    const NeedleDot = () => {
        return (
            <div className={"needle-dot"}>
                <svg><circle cx={10} cy={10} r={10} fill={"black"} /></svg>
            </div>
        );
    };

    const Footer = () => {
        return (
            <div className={"gauge-footer"}>
                <div>{footerText}</div>
                <div onClick={()=>infoIconClick("maxWeight", footerText)}><img className={"i-icon"} src={`${props.imagePath}infoIcon.png`} /></div>
                <div className="gaugeInfoPopUp">
                    {!isMobileView(window.innerWidth) && props.checkCurrentTooltipMatch("maxWeight")  && getInfoPopup()}
                </div>
                {isMobileView(window.innerWidth) && props.checkCurrentTooltipMatch("maxWeight") && getInfoPopup()}
            </div>
        );
    };

    return (
        <div className={"towingPayload-gauge"}>
            <SvgPattern />
            <Header />
            <ReactSpeedometerElement />
            <NeedleDot />
            <Footer />
        </div>
    );
};

export default GcwrGauge;