import { Component } from 'react';
import { ResourcesTemplate } from './resources.tpl';
import { postUserActivityRecord } from '../../services/userActivityService.js';
import '../../styles/css/resources.css';
import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();

export default class ResourcesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sampleImage: '',
    };
    this.resourcesClick = this.resourcesClick.bind(this);
  }

  resourcesClick(mod, category, label, mediaTitle) {
    analytics.fireOnClick({
      tag: '30.2',
      page: 'Insights',
      section: 'Insights',
      container: 'Resources',
      module: mod,
      category,
      label, // make the article type
      mediaTitle,
      contentTitle: mediaTitle,
    });
  }

  componentDidMount() {
    postUserActivityRecord();
    analytics.firePageLoad({
      tag: '30.1',
      section: 'Insights',
      page: 'Insights',
      subsection: ' ',
    });
  }

  render() {
    return ResourcesTemplate(this);
  }
}
