import React, { useEffect, useState } from 'react';
import EBrochuresVehicle from './eBrochuresVehicle';
import RedLine from '../../components/atoms/RedLine';
import getAnalytics from '../../services/analyticsService';
import getNavigation from '../../services/navigationService';
import { getAllValidEBrochures } from '../../services/spotlightService';
import { isMobileView } from '../utils/getScreenSize';
import * as analytics from './eBrochures.analytics';
import ChevronLeftRed from '../../resources/images/chevron-left-red.png';
import '../../styles/css/eBrochures.css';
import * as _ from 'lodash';
import AppSettings from '../../appsettings';
import '../../styles/css/mobileBackButton.css';
import { postMessageToApp } from '../utils/hybridAppHelpers';

const eBrochures = (props) => {
  const [analyticsData, setAnalyticsData] = useState(null);
  const [categoryLoading, setCategoryLoading] = useState(true);
  const [eBrochures, setEBrochures] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [vehicles, setVehicles] = useState(null);

  const getAllEBrochures = async () => {
    const validEBrochures = await getAllValidEBrochures();
    setEBrochures(validEBrochures);
  };

  const getEBrochuresAnalytics = async () => {
    const data = await getAnalytics('eBrochures');
    setAnalyticsData(data);
  };

  const getVehicleData = async () => {
    const mainMenuNavigationData = await getNavigation('mainMenu');
    const vehicleMenu = _.find(mainMenuNavigationData.dropDownItems, dropdown => dropdown.isVehicleDropdown);
    setVehicles(vehicleMenu.menuItems);
    if (!isMobileView(window.innerWidth)) {
      setSelectedCategory(vehicleMenu.menuItems[0]);
    }
    getEBrochuresAnalytics();
  };

  const handleCategoryClick = vehicleCategory => {
    if (analyticsData) {
      let cleanPrevCategoryTitle = 'No Category Selected';
      if (!isMobileView(window.innerWidth)) {
        cleanPrevCategoryTitle = _.replace(selectedCategory.title, '&', 'and');
      }
      const cleanNextCategoryTitle = _.replace(vehicleCategory.title, '&', 'and');
      analytics.filterCategoryClicked(analyticsData.clickAnalytics, analyticsData.pageLoad, cleanPrevCategoryTitle, cleanNextCategoryTitle);
    }
    if (vehicleCategory !== selectedCategory) {
      setCategoryLoading(true);
      setSelectedCategory(vehicleCategory);
    }
  };

  useEffect(() => {
    getAllEBrochures();
    getVehicleData();
  }, []);

  useEffect(() => {
    if (!!selectedCategory) {
      setCategoryLoading(false);
    } else {
      setCategoryLoading(true);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (analyticsData && selectedCategory) {
      const cleanCategoryTitle = _.replace(selectedCategory.title, '&', 'and');
      analytics.eBrochuresLoaded(analyticsData.pageLoad, cleanCategoryTitle);
    }
  }, [analyticsData]);

  const CategoryName = (
    <React.Fragment>
      {selectedCategory &&
        <div className="ebrochures-mobile-category">
          <div className="ebrochures-mobile-category-name">{selectedCategory.title}</div>
        </div>
      }
    </React.Fragment>
  );

  const EBrochuresFilters = (
    <div className="ebrochures-filters">
      {vehicles && vehicles.map(vehicleCategory => {
        const filterClassNames = vehicleCategory === selectedCategory ? 'ebrochures-filter ebrochures-filter-active' : 'ebrochures-filter';
        return (
          <div key={vehicleCategory.title} className={filterClassNames} onClick={() => handleCategoryClick(vehicleCategory)}>
            {vehicleCategory.title}
          </div>
        );
      })}
    </div>
  );

  const EBrochuresVehicles = (
    <div className={`ebrochures-vehicles ${categoryLoading ? 'visibility-hidden' : ''}`}>
      {selectedCategory && selectedCategory.menuItems.map(vehicle => {
        const urlModelName = vehicle.href.replaceAll("/product/","");
        let uniqueVehicleEBrochures = null;
        if (eBrochures) {
          const vehicleEBrochures = eBrochures && eBrochures[urlModelName];
          uniqueVehicleEBrochures = _.uniqBy(vehicleEBrochures, eBrochure => eBrochure.year);
        }

        return (
          <EBrochuresVehicle
            key={vehicle.model}
            clickAnalytics={analyticsData && analyticsData.clickAnalytics}
            eBrochures={uniqueVehicleEBrochures}
            filterCategoryName={selectedCategory.title}
            pageLoadAnalytics={analyticsData && analyticsData.pageLoad}
            vehicle={vehicle}
          />
        );
      })}
    </div>
  );

  const showEBrochuresCategoryName = () => {
    if (isMobileView(window.innerWidth)) {
      return !!selectedCategory;
    } else {
      return false;
    }
  };

  const showEBrochuresFilters = () => {
    if (isMobileView(window.innerWidth)) {
      return !(!!selectedCategory);
    } else {
      return true;
    }
  };

  const showEBrochuresVehicles = () => {
    if (isMobileView(window.innerWidth)) {
      return !!selectedCategory;
    } else {
      return true;
    }
  };
  const backButtonIcon = window.ReactNativeWebView ? AppSettings.AWSImgRepo.resources + 'en/mobile_back_arrow.svg' : ChevronLeftRed;

  return (
    <div className="ebrochures-archive">
      <div className="ebrochures-header">
          {(showEBrochuresCategoryName() || window.ReactNativeWebView)  && (
            <a onClick={() => 
              showEBrochuresCategoryName() ?
              setSelectedCategory(null)
              : window.ReactNativeWebView && postMessageToApp("Go Back")}
            >
              <img src={backButtonIcon} className="ebrochures-chevron" />
            </a>
          )}
        <div className="ebrochures-title">eBrochure Archive</div>
      </div>
      <RedLine style={{
        margin: 'auto',
        width: '57px',
      }} />
      {showEBrochuresFilters() &&EBrochuresFilters}
      {showEBrochuresCategoryName() && CategoryName}
      {showEBrochuresVehicles() && EBrochuresVehicles}
    </div>
  );
};

export default eBrochures;
