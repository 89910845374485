import React from 'react';
import { FormattedMessage } from 'react-intl';
import blueCheck from '../../../resources/images/key-advantages.png';
import blueQuote from '../../../resources/images/third-party-reviews.png';
import blueTen from '../../../resources/images/top-ten.png';
import Clipboard from '../../../resources/images/Clipboard.png';
import costToOwn from '../../../resources/images/cost-to-own.png';
import relatedIcon from '../../../resources/images/related-links.png';
import redRight from '../../../resources/images/red_right.png';
import '../../../styles/css/headToHead.css';
import SectionHeader from './sectionHeader.component';
import applyMarkdown from '../../utils/applyMarkdown';
import DisclaimersComponent from '../../disclaimers/disclaimers.component';
import { TopTenFeatures } from './headToHeadSections/topTenFeatures.component';
import { CostToOwn } from './headToHeadSections/costToOwn.component';
import { ThirdPartyReviews } from './headToHeadSections/thirdPartyReviews.component';
import VehicleHeader from './headToHeadSections/vehicleHeader.component';
import KeyAdvantages from './headToHeadSections/keyAdvantages.component';
import ValueStory from './headToHeadSections/valueStory.component';
import HeadToHeadVideo from './headToHeadSections/headToHeadVideo.component';
import QuickSpecs from './headToHeadSections/quickSpecs.component';

export function HeadToHeadTemplate(component) {
  const relatedLinks = component.state.currentEdge.edgeContent.relatedLinks ? component.state.currentEdge.edgeContent.relatedLinks.map((resource, index) => (
    <div key={index} className="rl-link-box columns large-4 medium-4 small-12">
      <div className="inner-container">
        <img alt={component.state.sampleImage} className="link-icon" src={redRight} />
        <a target="_blank" className="rl-link" href={resource.link}>{applyMarkdown(resource.title, component.disclaimer)}</a>
      </div>
    </div>
  )) : null;

  // TODO during MY21 redesign -- move this legacy function into functional component and import
  function LegacyHeadToHeadBody(props) {
    if (props.display) {
      return (
        <div className="ca-edge">
          <div className="noResponse" style={{ display: component.state.noResponse ? 'block' : 'none' }}>{component.state.noResponse ? <FormattedMessage id='caHeadToHead.noComparisonAvailable' /> : ''}</div>
          {/* Make sure that the dropdown selection value matches the competitor data loaded in the state */}
          {component.state.currentEdge && component.state.selectedValueCompetitor === component.state.currentEdge.name
            ? <div>
              <VehicleHeader
                item={component.state}
                updateDropdown={component.updateDropdown}
                disclaimer={component.disclaimer}
              />
              {component.state.currentEdge.edgeContent.keyAdvantages
                ? <div className="key-advantages">
                  <SectionHeader boldName={'caHeadToHead.keyAdvantagesBoldHeader'} nonBoldName={'caHeadToHead.keyAdvantagesHeader'} image={blueCheck} />
                  <div className="ka-body">
                    <KeyAdvantages
                      currentEdge={component.state.currentEdge}
                      sampleImage={component.state.sampleImage}
                      s3Path={component.state.s3Path}
                      disclaimer={component.disclaimer}
                    />
                  </div>
                </div> : null}
              {component.state.currentEdge.edgeContent.valueStory
                ? <div className="value-story">
                  <ValueStory
                    currentEdge={component.state.currentEdge}
                    sampleImage={component.state.sampleImage}
                    s3Path={component.state.s3Path}
                    disclaimer={component.disclaimer}
                  />
                </div> : null}
              {component.state.currentEdge.edgeContent.trueCostToOwn
                ? <div className="cost-to-own">
                  <SectionHeader boldName={'caHeadToHead.costToOwnBoldHeader'} nonBoldName={'caHeadToHead.costToOwnHeader'} image={costToOwn} />
                  <div className="cto-body">
                    <CostToOwn
                      disclaimer={component.disclaimer}
                      costsArray={component.state.costsArray}
                      currentEdge={component.state.currentEdge}
                      vehicleModel={component.state.vehicleModel}
                      selectedValueToyota={component.state.selectedValueToyota}
                      selectedValueCompetitor={component.state.selectedValueCompetitor}
                    />
                  </div>
                </div> : null}
              {component.state.currentEdge.edgeContent.thirdPartyReviews
                ? <div className="third-party-reviews">
                  <SectionHeader boldName={'caHeadToHead.thirdPartyReviewsBoldHeader'} nonBoldName={'caHeadToHead.thirdPartyReviewsHeader'} image={blueQuote} />
                  <ThirdPartyReviews
                    currentEdge={component.state.currentEdge}
                    disclaimer={component.disclaimer}
                  />
                </div> : null}
              {component.state.currentEdge.edgeContent.topFeatures
                ? <div className="top-ten-features">
                  <SectionHeader boldName={'caHeadToHead.topTenBoldHeader'} nonBoldName={'caHeadToHead.topTenHeader'} image={blueTen} />
                  <div className="ttf-body">
                    <TopTenFeatures
                      edgeContent={component.state.currentEdge.edgeContent}
                      vehicleModel={component.state.vehicleModel}
                      selectedValueToyota={component.state.selectedValueToyota}
                      selectedValueCompetitor={component.state.selectedValueCompetitor}
                      disclaimer={component.disclaimer}
                    />
                  </div>
                </div> : null}
              {component.state.currentEdge.edgeContent.relatedLinks
                ? <div className="related-links">
                  <SectionHeader boldName={'caHeadToHead.relatedLinksBoldHeader'} nonBoldName={'caHeadToHead.relatedLinksHeader'} image={relatedIcon} />
                  <div className="rl-body">
                    {relatedLinks}
                  </div>
                </div> : null}
            </div> : null}
        </div >
      );
    }
    return null;
  }

  function HeadToHeadBody(props) {
    if (props.display) {
      return (
        <div className="ca-edge">
          <div className="noResponse" style={{ display: component.state.noResponse ? 'block' : 'none' }}>{component.state.noResponse ? <FormattedMessage id='caHeadToHead.noComparisonAvailable' /> : ''}</div>
          {/* Make sure that the dropdown selection value matches the competitor data loaded in the state */}
          {component.state.currentEdge && component.state.selectedValueCompetitor === component.state.currentEdge.name
            ? <div>
              <VehicleHeader
                item={component.state}
                updateDropdown={component.updateDropdown}
                disclaimer={component.disclaimer}
              />
              {component.state.currentEdge.edgeContent.keyAdvantages
                ? <div className="key-advantages">
                  <SectionHeader boldName={'caHeadToHead.keyAdvantagesBoldHeader'} nonBoldName={'caHeadToHead.keyAdvantagesHeader'} image={blueCheck} />
                  <div className="ka-body">
                    <KeyAdvantages
                      currentEdge={component.state.currentEdge}
                      sampleImage={component.state.sampleImage}
                      s3Path={component.state.s3Path}
                      disclaimer={component.disclaimer}
                    />
                  </div>
                </div> : null}
              {component.state.currentEdge.edgeContent.headToHeadVideo
                ? <div className="value-story">
                  <HeadToHeadVideo
                    currentEdge={component.state.currentEdge}
                    sampleImage={component.state.sampleImage}
                    s3Path={component.state.s3Path}
                    disclaimer={component.disclaimer}
                  />
                </div> : <div className="value-story placeholder"/>}
              {component.state.currentEdge.edgeContent.trueCostToOwn
                ? <div className="cost-to-own">
                  <SectionHeader boldName={'caHeadToHead.costToOwnBoldHeader'} nonBoldName={'caHeadToHead.costToOwnHeader'} image={costToOwn} />
                  <div className="cto-body">
                    <CostToOwn
                      disclaimer={component.disclaimer}
                      costsArray={component.state.costsArray}
                      currentEdge={component.state.currentEdge}
                      vehicleModel={component.state.vehicleModel}
                      selectedValueToyota={component.state.selectedValueToyota}
                      selectedValueCompetitor={component.state.selectedValueCompetitor}
                    />
                  </div>
                </div> : null}
              {component.state.currentEdge.edgeContent.quickSpecs
                ? <div className="quick-specs">
                  <SectionHeader boldName={'caHeadToHead.quickSpecsBoldHeader'} nonBoldName={'caHeadToHead.quickSpecsHeader'} image={Clipboard} />
                  <QuickSpecs
                    edgeContent={component.state.currentEdge.edgeContent}
                    vehicleModel={component.state.vehicleModel}
                    vehicleYear={component.state.vehicleYear}
                    selectedValueToyota={component.state.selectedValueToyota}
                    selectedValueCompetitor={component.state.selectedValueCompetitor}
                    disclaimer={component.disclaimer}
                  />
                </div> : null}
            </div> : null}
        </div >
      );
    }
    return null;
  }

  return (
    <div>
      {component.state.vehicleYear < 2020
        ? <div><LegacyHeadToHeadBody display={component.state.displayContent} /></div>
        : <div><HeadToHeadBody display={component.state.displayContent} /></div>
      }
      <DisclaimersComponent template={`${encodeURIComponent(component.state.vehicleYear)}_${encodeURIComponent(component.state.vehicleModel?.toLowerCase().replace(/\//g, '_'))}_${encodeURIComponent(component.state.selectedValueCompetitor?.toLowerCase().replace(/\//g, '_'))}`} ref={(instance) => { component.disclaimer = instance; }} />
    </div>
  );
}
