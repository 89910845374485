function searchProducts(searchString) {
  const products = [
    {
      productName: '86',
      productLink: '/product/86/',
    },
    {
      productName: 'avalon',
      productLink: '/product/AVALON/',
    },
    {
      productName: 'bz4x',
      productLink: '/product/BZ4X/',
    },
    {
      productName: 'camry',
      productLink: '/product/CAMRY/',
    },
    {
      productName: 'corolla cross',
      productLink: '/product/COROLLACROSS/',
    },
    {
      productName: 'corolla hatchback',
      productLink: '/product/COROLLAHATCHBACK/',
    },
    {
      productName: 'gr corolla',
      productLink: '/product/GRCOROLLA/',
    },
    {
      productName: 'corolla im',
      productLink: '/product/COROLLAIM/',
    },
    {
      productName: 'corolla',
      productLink: '/product/COROLLA/',
    },
    {
      productName: 'prius v',
      productLink: '/product/PRIUSV/',
    },
    {
      productName: 'prius c',
      productLink: '/product/PRIUSC/',
    },
    {
      productName: 'prius prime',
      productLink: '/product/PRIME/',
    },
    {
      productName: 'prius plug-in hybrid',
      productLink: '/product/PRIME/',
    },
    {
      productName: 'prius plugin hybrid',
      productLink: '/product/PRIME/',
    },
    {
      productName: 'plugin',
      productLink: '/product/PRIME/',
    },
    {
      productName: 'plug-in',
      productLink: '/product/PRIME/',
    },
    {
      productName: 'prius',
      productLink: '/product/PRIUS/',
    },
    {
      productName: 'sienna',
      productLink: '/product/SIENNA/',
    },
    {
      productName: 'toyota crown',
      productLink: '/product/TOYOTACROWN/',
    },
    {
      productName: 'yaris sedan',
      productLink: '/product/YARISSEDAN/',
    },
    {
      productName: 'yaris ia',
      productLink: '/product/YARISIA/',
    },
    {
      productName: 'yaris',
      productLink: '/product/YARIS/',
    },
    {
      productName: '4runner',
      productLink: '/product/4RUNNER/',
    },
    {
      productName: 'c-hr',
      productLink: '/product/CHR/',
    },
    {
      productName: 'chr',
      productLink: '/product/CHR/',
    },
    {
      productName: 'highlander',
      productLink: '/product/HIGHLANDER/',
    },
    {
      productName: 'land cruiser',
      productLink: '/product/LANDCRUISER/',
    },
    {
      productName: 'rav4',
      productLink: '/product/RAV4/',
    },
    {
      productName: 'rav4 prime',
      productLink: '/product/RAV4PRIME/',
    },
    {
      productName: 'sequoia',
      productLink: '/product/SEQUOIA/',
    },
    {
      productName: 'tacoma',
      productLink: '/product/TACOMA/',
    },
    {
      productName: 'tundra',
      productLink: '/product/TUNDRA/',
    },
    {
      productName: 'mirai',
      productLink: '/product/MIRAI/',
    },
    {
      productName: 'venza',
      productLink: '/product/VENZA/2015/',
    },
    {
      productName: 'scion fr-s',
      productLink: '/product/FR-S/2016/',
    },
    {
      productName: 'scion frs',
      productLink: '/product/FR-S/2016/',
    },
    {
      productName: 'scion ia',
      productLink: '/product/IA/2016/',
    },
    {
      productName: 'scion im',
      productLink: '/product/IM/2016/',
    },
    {
      productName: 'scion iq',
      productLink: '/product/IQ/2015/',
    },
    {
      productName: 'scion tc',
      productLink: '/product/TC/2016/',
    },
    {
      productName: 'scion xb',
      productLink: '/product/XB/2015/',
    },
    {
      productName: 'scion xd',
      productLink: '/product/XD/2014/',
    },
  ];

  let results;
  const strongMatches = [];
  const weakMatches = [];
  const formattedSearchString = searchString.toLowerCase();

  for (const product in products) {
    if (isExactMatch(formattedSearchString, products[product].productName)) {
      strongMatches.unshift(products[product]);
    } else if (formattedSearchString.includes(products[product].productName)) {
      strongMatches.push(products[product]);
    } else if (products[product].productName.includes(formattedSearchString)) {
      strongMatches.push(products[product]);
    } else if (foundSearchStringWord(formattedSearchString, products[product].productName)) {
      weakMatches.push(products[product]);
    }
  }

  strongMatches.length > 0 ? results = strongMatches : results = weakMatches;

  return results;
}

function isExactMatch(searchString, productName) {
  return searchString === productName;
}

function foundSearchStringWord(searchString, productName) {
  const searchWords = searchString.split(' ');
  let matchFound = false;
  const ignoreWords = ['and', 'a', 'in', 'at', 'of', 'us', 'i'];

  for (let i = 0; i < searchWords.length && !matchFound; i++) {
    const word = searchWords[i];
    if (productName.includes(word) && !ignoreWords.includes(word)) {
      matchFound = true;
    }
  }

  return matchFound;
}

module.exports.searchProducts = searchProducts;
