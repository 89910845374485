import 'whatwg-fetch';
import httpOption from './httpOption';
import AppSettings from '../appsettings';

export function getTowingCalculatorSuggestions(towingWeight, payloadWeight, models) {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/towingPayload/suggestions`;
  const options = httpOption.POST();
  const requestBody = {
    models: models,
    towingWeight: towingWeight,
    payloadWeight: payloadWeight
  }
  options.body = JSON.stringify(requestBody)
  return fetch(url, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(response);
    })
}

export function getTowingCalculatorData() {
  const url = `${AppSettings.hostUrl}:${AppSettings.apiPort}${AppSettings.apiPath}/towingPayload`;
  const options = httpOption.GET();
  return fetch(url, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(response);
    });
}
