import React from 'react';

import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Portal from '../atoms/Portal';

import '../../styles/css/tooltip.css';

const Tooltip = (props) => {
  const { id, message } = props;
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
        <Portal>
            <ReactTooltip id={id} effect='solid' className='tip' place='top' disable ={isMobile}
            overridePosition={(
              { left, top },
              currentEvent, currentTarget, node,
            ) => {
              const d = document.documentElement;
              left = Math.min(d.clientWidth - node.clientWidth, left);
              top = Math.min(d.clientHeight - node.clientHeight, top);
              left = Math.max(0, left);
              top = Math.max(0, top);
              return { top, left };
            }} >
                <FormattedMessage id={ message } defaultMessage={ message } />
            </ReactTooltip>
        </Portal>
  );
};

export default Tooltip;

Tooltip.propTypes = {
  id: PropTypes.string,
  message: PropTypes.string,
};
