import React, { useEffect, useState, useRef } from 'react';
import '../../styles/css/towingPayload-calculator.css'
import { CalculatorMenu } from './towingPayload-calculatorMenu';
import { TrailerPanel } from './towingPayload-trailerPanel';
import formatMSRP from '../utils/formatMSRP.js';
import { InfoPopUp } from './towingPayload-infoPopUp';
import GaugeSection from './components/towingPayload-gaugeSection';
import { isMobileView } from '../../screens/utils/getScreenSize.js';
import * as analytics from './towingPayload-analytics';
import DisclaimersComponent from '../disclaimers/disclaimers.component';
import { useHistory } from 'react-router-dom';

export const TowingPayloadCalculator = (props) => {
    const resetText = "Reset";
    const selectNewText = "Select a New Vehicle";
    const disclaimerComponentRef = useRef();

    const history = useHistory();

    const beginnerMobileTrailerPopUpVisible = () => {
        if (props.mode == "beginner" && isMobileView(window.innerWidth)){
            return (props.iconsToDisplay.payload.includes(props.visiblePopUp) || props.iconsToDisplay.towing.includes(props.visiblePopUp))
        } else {
            return false
        }
    }

    const clearCalculatorValues = () =>{
        if (props.mode == "beginner") {
            props.setResetTowingPayloadValues(true);
        }
        else {
            props.setTowing(0);
            props.setPayload(0);
        }
    }

    const buttonAnalytics = (text) => {
        props.clickAnalytics && analytics.calculatorButtonClick(props.clickAnalytics, props.vehicleSelection, props.trimSelection, text);
    };

    const handleNavClick = (item) => {
        props.driveTypes.forEach((drive)=> {
            if(item.driveType.includes(drive.driveType)){
                item.driveType = drive.driveType;
            }
        });
        props.bedLengths.forEach((bed)=> {
            if(item.fullName.includes(bed.bedLength)){
                item.bedLength = bed.bedLength;
            }
        });
        props.cabList.forEach((cab)=> {
            if(item.fullName.includes(cab.cab)){
                item.cab = cab.cab;
            }
        });
        props.setTrimSelection(item);
        console.log(item);
        props.setVehicleSelection({
            modelName: item.modelName,
            modelYear: item.modelYear.toString()
        })
        history.push(`/towingPayload/${item.modelName}/${item.modelYear.toString()}/${item.modelCode}`);
        props.clickAnalytics && analytics.beginCalculatingClick(props.clickAnalytics, props.vehicleSelection.modelYear, props.vehicleSelection.modelName, item);
    };

    return (
        <div className="calculatorContainer">
            <div className="calculatorHeader">
                <div>
                    <div className="trimTitle">{props.vehicleSelection.modelYear} {props.vehicleSelection.modelName} {props.trimSelection.driveType} {props.trimSelection.cab} {props.trimSelection.trimName}</div>
                    <div className="trimSubtitle">
                        <div className="trimModelCode">#{props.trimSelection.modelCode}</div>
                        {props.trimSelection.MSRP && isMobileView(window.innerWidth) && <div className = "trimSubtitleVLine"></div>}
                        {props.trimSelection.MSRP && <div className="trimMSRP">Base MSRP: {formatMSRP(props.trimSelection.MSRP.toString())}<sup className="disclaimer-link" onClick={() => disclaimerComponentRef.current.onOpenModal()}>&dagger;</sup></div>}
                    </div>
                </div>

            </div>
            <div className="calculatorBody">
                <TrailerPanel 
                    modelName={props.vehicleSelection.modelName}
                    modelYear={props.vehicleSelection.modelYear}
                    modelCode={props.trimSelection.modelCode}
                    cab={props.trimSelection.cab}
                    bedLength={props.trimSelection.bedLength}
                    imagePath={props.imagePath}
                    mode={props.mode}
                    setCalculatorType={props.setCalculatorType}
                    towingDefinitionsData={props.towingDefinitionsData}
                    infoPopUpData={props.infoPopUpData}
                    vehicleType={props.vehicleType}
                    iconsToDisplay={props.iconsToDisplay}
                    setPayloadValues={props.mode == "beginner" ? props.setPayloadValues : null}
                    payloadValues={props.mode == "beginner" ? props.payloadValues : null}
                    setTowingValues={props.mode == "beginner" ? props.setTowingValues : null}
                    towingValues={props.mode == "beginner" ? props.towingValues : null}
                    beginnerMobileTrailerPopUpVisible={beginnerMobileTrailerPopUpVisible}
                    clickAnalytics={props.clickAnalytics}
                    pageLoadAnalytics={props.pageLoadAnalytics}
                    vehicleSelection={props.vehicleSelection}
                    trimSelection={props.trimSelection}
                />
                <GaugeSection 
                    expertMode={props.mode === "expert" ? true : false} 
                    imagePath={props.imagePath}
                    modelName={props.vehicleSelection.modelName}
                    vehicle={props.trimSelection}
                    towingDefinitionsData={props.towingDefinitionsData}
                    infoPopUpData={props.infoPopUpData}
                    setPayload={props.setPayload}
                    payload={props.payload}
                    setTowing={props.setTowing}
                    towing={props.towing}
                    miscText={props.miscText}
                    clickAnalytics={props.clickAnalytics}
                    pageLoadAnalytics={props.pageLoadAnalytics}
                    vehicleSelection={props.vehicleSelection}
                    vehicles={props.vehicles}
                    handleNavClick = {handleNavClick}
                />
            </div>
            <div className="tp-calculatorReset">
                <div className="resetCalculatorPageButton" onClick={()=>{ clearCalculatorValues(); buttonAnalytics(resetText) }}>{resetText}</div>
                <div className="selectvehicleCalculatorbutton" onClick={()=>{ 
                    props.setPage("Home");
                    history.push(`/towingPayload`);
                    props.setCalculatorType(null);
                    buttonAnalytics(selectNewText);
                }}>{selectNewText}</div>
            </div>
            <div className = "calculatorHLine"></div>
            <DisclaimersComponent
                ref={disclaimerComponentRef}
                addBaseMSRPDisclaimer={true}
            />
        </div>
    );
};