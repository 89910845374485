import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import SubheaderComponent from '../subheader/subheader.component';
import { TSSFeaturesComponent } from './features/tssFeatures.component';
import { TSSVideosComponent } from './videos/tssVideos.component';
import { TSSLineupComponent } from './lineup/tssLineup.component';
import { TSSResourcesComponent } from './resources/tssResources.component';
import { DynamicColumnItemList } from '../../components/molecules/dynamic-column-item-list';
import AppSettings from '../../appsettings';
import getMisc from '../../services/miscService';
import { overviewAnalytics } from './tss-analytics';
import Display404NotFound from '../../components/atoms/404NotFound';

const TSSRouterComponent = (props) => {
  const [pageData, setPageData] = useState(null);
  const history = useHistory();

  useEffect(() => {
    getMisc('TSS', 'en')
      .then((data) => {
        if (!pageData) { setPageData(data); }
      })
      .catch((error) => {
        console.log(`ERROR: failed to load TSS page data. ${error}`);
      });
  }, []);

  const overviewPageProps = {
    disclaimerName: 'TSS',
    internalFilePath: AppSettings.AWSImgRepo.tss,
    imagePath: AppSettings.AWSImgRepo.tss,
    parentPageName: 'TSS',
  };

  const getSubheaderChildComponent = (path) => {
    const page = path ? path.toLowerCase() : '';
    switch (page) {
      case 'overview':
        return pageData && <DynamicColumnItemList
          {...overviewPageProps}
          {...pageData.items.overview}
          printHeadline="overviewpage.printHeadline.toyotaSafetySense"
          pageLoadAnalytics={overviewAnalytics.pageLoadAnalytics}
        />;
      case 'lineup':
        return pageData && <TSSLineupComponent lineupData={pageData.items.lineup} packagesData={pageData.items.packages} featuresData={pageData.items.features} />;
      case 'features':
        return pageData && <TSSFeaturesComponent featuresData={pageData.items.features} packagesData={pageData.items.packages} filters={pageData.items.filters} />;
      case 'videos':
        return pageData && <TSSVideosComponent videoData={pageData.items.videos} packagesData={pageData.items.packages} filters={pageData.items.filters} />;
      case 'resources':
        return pageData && <TSSResourcesComponent filters={pageData.items.filters} />;
      default:
        history.push('/toyotaSafetySense/overview');
        break;
    }
  };
  const params = useParams();
  const route = {
    engine: '',
    featSpec: undefined,
    model: '',
    path: params.page || 'overview',
    trim: '',
    year: params.year,
  };
  const splat = params && params.page;
  const isValidTabName = () => ['overview', 'lineup', 'features', 'videos', 'resources'].filter(x => x === splat).length > 0;
  const getPageDisplay = () => {
    if (isValidTabName()) {
      return (
        <div>
        {pageData
          && <SubheaderComponent {...props} route={route} params={params}>
            {getSubheaderChildComponent(splat)}
          </SubheaderComponent>
        }
      </div>
      );
    }
    return <Display404NotFound display />;
  };
  return (
    <div>{getPageDisplay()}</div>
  );
};

export default injectIntl(TSSRouterComponent);
