import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { TCUVResourcesTemplate } from './tcuvResources.tpl';
import { GlobalAnalytics } from '../../../analytics';
import { translateText } from '../../utils/translateText';

const analytics = new GlobalAnalytics();

class TCUVResourcesComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.resourceListClick = this.resourceListClick.bind(this);
    this.sortAnalytics = this.sortAnalytics.bind(this);
    this.filterLinksClick = this.filterLinksClick.bind(this);
  }

  resourceListClick(resource) {
    analytics.fireOnClick({
      tag: '3.52',
      mediaTitle: resource.title,
      section: 'Product',
      subsection: 'Featured Technology',
      technologyName: 'TCUV',
      page: translateText(this.props.tab),
      category: resource.recordType,
      contentTitle: resource.title,
    });
  }

  sortAnalytics(event) {
    analytics.fireOnClick({
      tag: '3.52',
      mediaTitle: event.target.value,
      section: 'Product',
      subsection: 'Featured Technology',
      technologyName: 'TCUV',
      page: translateText(this.props.tab),
      category: event.target.value,
      contentTitle: 'Show',
    });
  }

  filterLinksClick(event) {
    analytics.fireOnClick({
      tag: '3.52',
      mediaTitle: event.target.innerText,
      section: 'Product',
      subsection: 'Featured Technology',
      technologyName: 'TCUV',
      page: translateText(this.props.tab),
      category: event.target.innerText,
      contentTitle: 'Show',
    });
  }

  componentDidMount() {
    analytics.firePageLoad({
      tag: '3.52',
      section: 'Product',
      subsection: 'Featured Technology',
      technologyName: 'TCUV',
      page: translateText(this.props.tab),
    });
  }

  render() {
    return TCUVResourcesTemplate(this);
  }
}

export default injectIntl(TCUVResourcesComponent);
