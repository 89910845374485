import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();

export const featureLookupOverviewPageLoad = (pageLoadAnalytics, keyword) => {
    pageLoadAnalytics && analytics.firePageLoad({
        tag: pageLoadAnalytics.tagId,
        section: pageLoadAnalytics.section,
        page: pageLoadAnalytics.overview.page,
        keyword: keyword,
    });
};

export const featureLookupOverviewNoResultsPageLoad = (pageLoadAnalytics, keyword) => {
    pageLoadAnalytics && analytics.firePageLoad({
        tag: pageLoadAnalytics.tagId,
        section: pageLoadAnalytics.section,
        page: pageLoadAnalytics.noResults.page,
        keyword: keyword,
    });
};

export const featureLookupResultsPageLoad = (pageLoadAnalytics, keyword) => {
    pageLoadAnalytics && analytics.firePageLoad({
        tag: pageLoadAnalytics.tagId,
        section: pageLoadAnalytics.section,
        page: pageLoadAnalytics.results.page,
        keyword: keyword,
    });
};

export const featureLookupOverviewFilterClick = (clickAnalytics, filter) => {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        container: clickAnalytics.container,
        category: clickAnalytics.overview.category,
        label: filter
    });
};

export const featureLookupOverviewResetSearchClick = (clickAnalytics, filter, searchTerm) => {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        container: clickAnalytics.container,
        category: clickAnalytics.overview.category,
        label: clickAnalytics.overview.resetButtonLabel,
        positionTitle: filter,
        keyword: searchTerm
    });
};

export const featureLookupOverviewAddFeatureClick = (clickAnalytics, filter, feature) => {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        container: clickAnalytics.container,
        category: clickAnalytics.overview.category,
        label: clickAnalytics.overview.addFeatureLabel,
        positionTitle: filter,
        contentTitle: feature
    });
};

export const featureLookupRemoveFeatureClick = (clickAnalytics, category, feature, filter) => {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        container: clickAnalytics.container,
        category: category,
        label: clickAnalytics.removeFeatureLabel,
        contentTitle: feature,
        positionTitle: filter,
    });
};

export const featureLookupOverviewGoLick = (clickAnalytics, filter, features) => {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        container: clickAnalytics.container,
        category: clickAnalytics.overview.category,
        label: clickAnalytics.overview.goLabel,
        positionTitle: filter,
        keyword: features
    });
};

export const featureLookupOverviewCategorySelect = (clickAnalytics, filter, category) => {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        container: clickAnalytics.container,
        category: clickAnalytics.overview.category,
        label: clickAnalytics.overview.categorySelectLabel,
        positionTitle: filter,
        contentTitle: category
    });
};

export const featureLookupResultsCategorySelect = (clickAnalytics, category) => {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        container: clickAnalytics.container,
        category: clickAnalytics.results.category,
        label: clickAnalytics.results.categorySelectLabel,
        contentTitle: category
    });
};

export const featureLookupResultsVehicleExpandCollapseClick = (clickAnalytics, vehicle, label) => {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        container: clickAnalytics.container,
        category: clickAnalytics.results.category,
        label: label,
        positionTitle: vehicle
    });
};

export const featureLookupResultsAddFilterFeatureClick = (clickAnalytics) => {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        container: clickAnalytics.container,
        category: clickAnalytics.results.category,
        label: clickAnalytics.results.addFilterFeatureLabel,
    });
};

export const featureLookupResultsBackClick = (clickAnalytics) => {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        container: clickAnalytics.container,
        category: clickAnalytics.results.category,
        label: clickAnalytics.results.backClickLabel,
    });
};

export const featureLookupResultCardSeeMoreLessClick = (clickAnalytics, vehicle, label) => {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        container: clickAnalytics.container,
        category: clickAnalytics.results.category,
        label: label,
        positionTitle: vehicle
    });
}

export const featureLookupResultCardYearSelect = (clickAnalytics, yearSelection, vehicle) => {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        container: clickAnalytics.container,
        category: clickAnalytics.results.category,
        label: clickAnalytics.results.yearSelectLabel,
        contentTitle: yearSelection,
        positionTitle: vehicle
    });
}

export const featureLookupResultCardMsrpTooltipShowHide = (clickAnalytics, label, msrp, vehicle, trim, modelName, modelYear, modelCode) => {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        container: clickAnalytics.container,
        category: clickAnalytics.results.category,
        label: label,
        contentTitle: msrp,
        positionTitle: vehicle,
        trim: trim,
        modelName: modelName,
        modelYear: modelYear,
        modelCode: modelCode
    });
}

export const featureLookupResultCardFeatureTooltipShowHide = (clickAnalytics, label, feature, vehicle) => {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        container: clickAnalytics.container,
        category: clickAnalytics.results.category,
        label: label,
        positionTitle: vehicle,
        contentTitle: feature,
    });
}

export const featureLookupResultsAddVehicleFeatureClick = (clickAnalytics, vehicle) => {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        container: clickAnalytics.container,
        category: clickAnalytics.results.category,
        label: clickAnalytics.results.addVehicleFeatureLabel,
        positionTitle: vehicle
    });
};

export const featureLookupResultCardTrimExpandCollapseClick = (clickAnalytics, label, vehicle, trim, modelName, modelYear, modelCode) => {
    clickAnalytics && analytics.fireOnClick({
        tag: clickAnalytics.tagId,
        container: clickAnalytics.container,
        category: clickAnalytics.results.category,
        label: label,
        positionTitle: vehicle,
        trim: trim,
        modelName: modelName,
        modelYear: modelYear,
        modelCode: modelCode
    });
};

