import PropTypes from 'prop-types';
import React from 'react';
import { isTabletView } from '../../screens/utils/getScreenSize';
import getTarget from '../../screens/utils/getTarget';
import '../../styles/css/dynamicLandingTile.css';
import RedLine from '../atoms/RedLine';

function handleClick(){
  props.fireTileClickAnalytics(props.title);
  props.onClickFunction()
}

const dynamicLandingTile = props => {
  function handleClick(){
    props.fireTileClickAnalytics(props.title);
    props.onClickFunction()
}

  return ( props.smallTiles ? 
    <a className="dynamic-landing-tile-small" href={props.link} target={getTarget(props.link)} onClick={() => handleClick()}>
      <img className="dynamic-landing-tile-image-small" src={props.awsImagePath + props.image} />
      <div className="dynamic-landing-tile-content-small">
        <div className="dynamic-landing-tile-title-small">
          {props.title}
        </div>
        <div className="dynamic-landing-tile-description-small">
          {props.description}
        </div>
      </div>
    </a> : <a className="dynamic-landing-tile" href={props.link} target={getTarget(props.link)} onClick={() => props.fireTileClickAnalytics(props.title)}>
      <img className="dynamic-landing-tile-image" src={props.awsImagePath + props.image} />
      <div className="dynamic-landing-tile-content">
        <div className="dynamic-landing-tile-title">
          {props.title}
        </div>
        <RedLine style={{
          marginTop: '5px',
          width: isTabletView(window.innerWidth) ? '35px' : '42px',
        }} />
        <div className="dynamic-landing-tile-description">
          {props.description}
        </div>
      </div>
    </a>);
};

dynamicLandingTile.propTypes = {
  awsImagePath: PropTypes.string,
  description: PropTypes.string,
  fireTileClickAnalytics: PropTypes.func,
  image: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};

export default dynamicLandingTile;
