import React from 'react';
import Countdown from 'react-countdown-now';
import { FormattedMessage } from 'react-intl';
import '../../styles/css/engageLive.css';
import EngageLiveCountdown from './countdown.function';
import AppSettings from '../../appsettings';
import EngageVideoWidget from './widgetVideoComponent';

export default function EngageLiveStreamTemplate(component) {
  let countdown = null;
  // const beforeImage = `${AppSettings.AWSImgRepo.engage_live}Engage_LandingPage.jpg`;
  const broadcastTitle = <div className="broadcast-title">Toyota Special Announcements</div>;
  let beforeLink;
  let duringLink;
  let template;
  if (AppSettings.FeatureFlags.showEngageLiveState === true) {
    beforeLink = <a onClick={component.setStateBefore}>Before</a>;
    duringLink = <a onClick={component.setStateDuring}>During</a>;
  }

  if (component.state.countDownDate) {
    const countDownDateToShow = new Date(component.state.countDownDate);
    countdown = <Countdown date={countDownDateToShow}
      renderer={EngageLiveCountdown}
      onComplete={console.log('onComplete called')} />;
  }

  const renderSupraLiveStream = () => (
    <div className="video-block">
      <iframe id="ls_embed_1589216025" src="https://livestream.com/accounts/1607005/events/9122935/player?width=640&height=360&enableInfoAndActivity=true&defaultDrawer=&autoPlay=true&mute=false" width="640" height="360" frameBorder="0" scrolling="no" allowFullScreen> </iframe>
      <script type="text/javascript" data-embed_id="ls_embed_1581342447" src="https://livestream.com/assets/plugins/referrer_tracking.js"></script>
    </div>);

  if (component.state.broadcastState === 'before') {
    template = <div className="engage-live">
      {beforeLink} {duringLink}
      {broadcastTitle}
      {countdown}
      {component.state.languageOverride
        ? <div className="language-unavailable" >
          <FormattedMessage id="engageLive.languageOverride" default="Language is unavailable" />
        </div>
        : null}
      {/* <img className="before-image" src={beforeImage} alt=""/> */}
      <div className="message-text">Come back on mm/dd/yyyy <br /> to watch a special announcement by Toyota</div>
    </div>;
  } else if (component.state.broadcastState === 'during') {
    template = <div className="engage-live">
      {beforeLink} {duringLink}
      {broadcastTitle}
      {component.state.languageOverride
        ? <div className="language-unavailable" >
          <FormattedMessage id="engageLive.languageOverride" default="Language is unavailable" />
        </div>
        : null}
      {renderSupraLiveStream()}
    </div>;
  }

  return (
    template
  );
}
