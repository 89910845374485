import PropTypes from 'prop-types';
import React from 'react';
import { ClipLoader } from 'react-spinners';
import '../../../styles/css/contactUs/contactUsForm/contactUsFormButtons.css';
import { buttonClick } from '../contactUsAnalytics';

const ContactUsFormButtons = (props) => {
    const handleReset = () => {
        props.clickAnalytics && buttonClick(props.clickAnalytics, props.clickAnalytics.resetForm);
        props.handleReset()
    }

    return (
        <div className='contactUsFormButtonGroup'>
            {props.sending ?
                <ClipLoader color={'#EB0A1E'} loading={props.sending} size={50} />
                :
                <React.Fragment>
                    <div
                        className={`contactUsFormButton submit ${props.complete ? '' : 'disabled'}`}
                        onClick={props.handleSave}
                    >
                        Submit
                    </div>
                    {!props.hideReset && <div
                        className='contactUsFormButton reset'
                        onClick={handleReset}
                    >
                        Reset
                    </div>}
                </React.Fragment>
            }
        </div>
    )
};

ContactUsFormButtons.propTypes = {
    clickAnalytics: PropTypes.object,
    complete: PropTypes.bool,
    sending: PropTypes.bool,
    handleSave: PropTypes.func,
    handleReset: PropTypes.func,
    hideReset: PropTypes.bool,
};

export default ContactUsFormButtons;