import React, { useState, useRef, cloneElement } from 'react';
import Modal from 'react-modal';
import { ReactComponent as MailIcon } from '../../resources/images/mail-icon.svg';
import { ReactComponent as XIcon } from '../../resources/images/x-icon.svg';
import { ReactComponent as FacebookIcon } from '../../resources/images/facebook-icon.svg';
import { ReactComponent as LinkedInIcon } from '../../resources/images/linkedin-icon.svg';
import { ReactComponent as CloseIcon } from '../../resources/images/close-icon.svg';
import { GlobalAnalytics } from '../../analytics';
import '../../styles/css/shareModal.css';

const analytics = new GlobalAnalytics();

const ShareModalButtonWrapper = ({ children, linkToBeShared, analyticsFunction }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const inputRef = useRef(null);

  const closeModal = () => setIsOpen(false);
  const openModal = () => {
    analyticsFunction();
    setIsOpen(true);
    setIsCopied(false);
  };

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(linkToBeShared);
    setIsCopied(true);
    inputRef.current.focus();
    inputRef.current.select();
    clickAnalytics('clipboard');
  };

  const subject = "Check this out!";
  const body = "I thought you might find this interesting:";
  const encodedLink = encodeURIComponent(linkToBeShared);
  const encodedSubject = encodeURIComponent(subject);
  const encodedBody = encodeURIComponent(`${body}\n\n${linkToBeShared}`);

  const clickAnalytics = (label) => {
    analytics.fireOnClick({
      tag: '28.2',
      container: 'Share Modal',
      category: 'Share Link',
      label: label,
    });
  };

  return (
    <>
      {cloneElement(children, {
        onClick: openModal,
        className: `${children.props.className || ""} open-share-modal-button`.trim()
      })}
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Share Modal"
        overlayClassName="share-modal-overlay"
        className="share-modal-content"
        closeTimeoutMS={300} // This needs to match the transition time in the css
      >
        <h2 className='share-modal-title'>Share</h2>
        <div className='share-option-list'>
          <a className='share-option'
            href={`mailto:?subject=${encodedSubject}&body=${encodedBody}`}
            onClick={() => clickAnalytics('email')}
          >
            <div className='share-option-circle'>
              <MailIcon />
            </div>
            <div className='share-option-label'>
              Email
            </div>
          </a>
          <a className='share-option'
            href={`https://twitter.com/intent/tweet?url=${encodedLink}&text=${encodedSubject}`}
            onClick={() => clickAnalytics('x')}
            target='_blank'
            rel='noopener noreferrer'
          >
            <div className='share-option-circle'>
              <XIcon />
            </div>
            <div className='share-option-label'>
              X
            </div>
          </a>
          <a className='share-option'
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodedLink}`}
            onClick={() => clickAnalytics('linkedin')}
            target='_blank'
            rel='noopener noreferrer'
          >
            <div className='share-option-circle'>
              <LinkedInIcon />
            </div>
            <div className='share-option-label'>
              LinkedIn
            </div>
          </a>
          <a className='share-option'
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`}
            onClick={() => clickAnalytics('facebook')}
            target='_blank'
            rel='noopener noreferrer'
          >
            <div className='share-option-circle'>
              <FacebookIcon />
            </div>
            <div className='share-option-label'>
              Facebook
            </div>
          </a>
        </div>
        <div className="copy-link">
          <input ref={inputRef} type="text" value={linkToBeShared} readOnly />
          <button className='copy-link-button' onClick={copyToClipboard}>
            {isCopied ? "COPIED TO CLIPBOARD" : "COPY LINK"}
          </button>
        </div>

        <CloseButton closeModal={closeModal} />
      </Modal>
    </>
  );
};
export default ShareModalButtonWrapper;

const CloseButton = ({ closeModal }) => (
  <button className="share-modal-close-button" onClick={() => closeModal()}>
    <CloseIcon className="styles_closeIcon__1QwbI" />
  </button>
);