import React, { useContext } from "react";
import '../../../styles/css/profilePage/profile-page-header.css';
import XPProgressBar from '../../engageXP/components/progressBar';
import Skeleton from 'react-loading-skeleton';
import { NitroContext } from "../../../components/contexts/nitroContext";
import ProfilePicture from "./profilePicture";
import { isMobileView } from "../../utils/getScreenSize";
import { checkMyBalanceClick, editProfilePicClick } from "./sharedComponentsAnalytics";


const ProfilePageHeader = (props) => {
    const { nitroUserXPLevel } = useContext(NitroContext);

    const getPercentToNextLevel = () => {
        const totalPointsInCurrentLevel = nitroUserXPLevel?.nitroCurrentLevelPoints + nitroUserXPLevel?.nitroPointsNeeded;
        const currentLevelPercentComplete = (nitroUserXPLevel?.nitroCurrentLevelPoints / totalPointsInCurrentLevel) * 100;
        return currentLevelPercentComplete;
    };

    const checkBalanceClick = () => {
        window.open('https://www.toyotaperformancecenter.com/', '_blank').focus();
        checkMyBalanceClick(props?.analyticsData?.clickAnalytics);
    };

    return (
        <div className="profile-page-header">
            <ProfilePicture
                showEditPictureButton={props?.showEditPictureButton}
                editProfilePicClickAnalytics={() => editProfilePicClick(props?.analyticsData?.clickAnalytics)}
            />

            <div className="info-container">
                <div className="name-dealership-info-container">
                    <div className="name">{`${props.userData.firstName} ${props.userData.lastName}`}</div>
                    <div className="dealership">
                        {props.userData.dealerName ? `${props.userData.dealerName},` : <Skeleton count={isMobileView(window.innerWidth) ? 1 : 0.15} inline/>}
                        {` ${props.userData.region} ${props.userData.zipCode ? props.userData.zipCode : ''}`}
                    </div>
                </div>

                <div className="xp-container">
                    <div className="level-container">
                        <XPProgressBar
                            percent={getPercentToNextLevel()}
                            leftLabel={nitroUserXPLevel?.nitroCurrentLevel ?? 'Level 0'}
                            rightLabel={nitroUserXPLevel?.nitroPointsNeeded && nitroUserXPLevel?.nitroNextLevel ? `${nitroUserXPLevel?.nitroPointsNeeded}XP to ${nitroUserXPLevel?.nitroNextLevel}` : '0XP to Level 0'}
                            variant={'profilePage'}
                        />
                    </div>
                    <div className="points-container">
                        <div className="points-wrapper">
                            <div className="points">
                                {nitroUserXPLevel?.nitroUserPoints ? `${nitroUserXPLevel?.nitroUserPoints} XP Points` : '0 XP Points'}
                            </div>
                            
                            <div className="check-balance-button" onClick={checkBalanceClick}>Check My Balance</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfilePageHeader;
