import appsettings from "../../../appsettings.js";
import BackButton from "../../../components/molecules/BackButton.js";
import S from "../../../styles/scss/engageXP/tournaments/tournamentRoundDetails.module.scss";
import MissionCard from "../../../components/molecules/cards/missionCard.js";
import { getTournamentMissionsDetails, getTournamentPointsEarned } from "../../../services/nitroService";
import { useEffect, useState } from "react";

const TournamentRoundDetails = ({
    deviceType,
    selectedRound,
    imgPath,
    handleBackClick,
    getColoredBadgeInfo,
    getBlurredBadgeText,
    missions,
    isUserEligible,
    userEligibilityUpdated,
}) => {
    const [selectedRoundDetails, setSelectedRoundDetails] = useState();
    const [missionsAreReady, setMissionsAreReady] = useState(false);
    const getBackgroundImage = (platform, userEligibilityUpdated, isUserEligible, selectedRound, imgPath) => {
        const eligibleImage = imgPath + selectedRound[`${platform}BannerImage`];
        const ineligibleImage = imgPath + selectedRound[`ineligibleUser${platform.charAt(0).toUpperCase() + platform.slice(1)}Image`];

        if (userEligibilityUpdated && !isUserEligible && !!ineligibleImage) {
            return `url(${ineligibleImage})`;
        } else {
            return `url(${eligibleImage})`;
        }
    };

    useEffect(() => {
        const getRoundDetails = async () => {
            if (!selectedRound) return;

            if (!window.location.href.endsWith("/tournaments")) return;

            setMissionsAreReady(false);
            const roundDetails = await getTournamentMissionsDetails(selectedRound.missions); // We may be able to replace this with a filter on the existing missions array
            const updatedRoundDetails = roundDetails.map((mission) => ({
                ...mission,
                tournamentPointsEarned: null,
            }));

            const completedMissions = roundDetails.filter((mission) => !!mission.details.completionCount);
            const promises = completedMissions.map(async (mission) => {
                const pointsEarned = await getTournamentPointsEarned([mission.id]);
                const matchingMission = pointsEarned.find((m) => m.missionId === mission.id);
                if (matchingMission) {
                    updatedRoundDetails.forEach((prevMission, index) => {
                        if (prevMission.id === mission.id) {
                            updatedRoundDetails[index] = {
                                ...prevMission,
                                tournamentPointsEarned: matchingMission.points,
                            };
                        }
                    });
                }
            });

            await Promise.all(promises);
            setSelectedRoundDetails(updatedRoundDetails);
            setMissionsAreReady(true);
        };

        getRoundDetails();
    }, [selectedRound, window.location.href]);

    const MissionGrid = () => {
        const numToShow = deviceType === "desktop" ? 5 : 3;
        if (!selectedRoundDetails) {
            return Array.from({ length: numToShow }, (_, index) => (
                <MissionCard isLoadingMissions={true} key={index} />
            ));
        }
        return selectedRoundDetails.map((item, index) => {
            const { details: mission, tournamentPoints } = item;
            const matchingMission = missions?.find((m) => m.id === mission.id);
            if (matchingMission) {
                mission.labels = matchingMission.labels;
            }
            const xpReward = matchingMission?.rewards?.find((reward) => reward.pointCategory.name === "XP");
            const xpRewardValue = xpReward ? xpReward.pointAward : 0;

            return (
                <MissionCard
                    isLoadingMissions={!missionsAreReady}
                    key={index}
                    mission={mission}
                    getBlurredBadgeText={getBlurredBadgeText}
                    getColoredBadgeInfo={getColoredBadgeInfo}
                    proPointReward={tournamentPoints ?? 0}
                    isUserEligible={isUserEligible}
                    proPointsEarned={item.tournamentPointsEarned ?? 0}
                    xpEarned={xpRewardValue ?? 0}
                    tournament
                    analyticsData={{
                        clickAnalytics: {
                            tagId: "29.2",
                            appId: "eShow",
                            section: "Product",
                            page: "XP Tournament​",
                            category: "Tournament​",
                            label: matchingMission?.displayName || matchingMission?.name,
                            contentTitle: selectedRound.title,
                        },
                    }}
                />
            );
        });
    };

    return (
        <div className={S["round-details-container"]}>
            <div className={S["round-details-content"]}>
                <span className={S["back"]}>
                    <BackButton onClick={handleBackClick} />
                </span>
                <img
                    className={S["banner"]}
                    style={{
                        "--desktop-banner": getBackgroundImage(
                            "desktop",
                            userEligibilityUpdated,
                            isUserEligible,
                            selectedRound,
                            imgPath
                        ),
                        "--tablet-banner": getBackgroundImage(
                            "tablet",
                            userEligibilityUpdated,
                            isUserEligible,
                            selectedRound,
                            imgPath
                        ),
                        "--mobile-banner": getBackgroundImage(
                            "mobile",
                            userEligibilityUpdated,
                            isUserEligible,
                            selectedRound,
                            imgPath
                        ),
                    }}
                />
                <div className={S["missions-grid"]}>
                    <MissionGrid />
                </div>
            </div>
        </div>
    );
};

export default TournamentRoundDetails;
