import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-responsive-modal';
import '../../../styles/css/contactUs/contactUsForm/contactUsFormModal.css';

const ContactUsFormModal = (props) => {
    const modalContainerStyling = {
        'display': 'flex',
        'align-items': 'center',
        'justify-content': 'center',
    };
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            styles={{ modalContainer: modalContainerStyling }}
        >
            <div className='contactUsFormModal'>
                {props.message}
            </div>
        </Modal>
    )
};

ContactUsFormModal.propTypes = {
    open: PropTypes.bool,
    message: PropTypes.string,
    onClose: PropTypes.func
};

export default ContactUsFormModal;