import { GlobalAnalytics } from '../../../analytics';

const analytics = new GlobalAnalytics();

const contentLinkClick = (tag, section, subsection, technologyName, page, category, positionTitle, contentTitle) => {
  analytics.fireOnClick({
    tag,
    section,
    subsection,
    technologyName,
    page,
    category,
    positionTitle,
    contentTitle,
  });
};

export const qualifySubLoad = (pageLoadAnalytics) => {
  pageLoadAnalytics && pageLoadAnalytics.qualifySubLoad && contentLinkClick(
    pageLoadAnalytics.qualifySubLoad.tagId,
    pageLoadAnalytics.qualifySubLoad.section,
    pageLoadAnalytics.qualifySubLoad.subsection,
    pageLoadAnalytics.qualifySubLoad.technologyName,
    pageLoadAnalytics.qualifySubLoad.page,
    pageLoadAnalytics.qualifySubLoad.category,
    pageLoadAnalytics.qualifySubLoad.positionTitle,
    pageLoadAnalytics.qualifySubLoad.contentTitle,
  );
};
