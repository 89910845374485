import * as _ from 'lodash';
import React from 'react';
import '../../../styles/css/homepage/featuredCarousel.css';
import AppSettings from '../../../appsettings';
import getTarget from '../../utils/getTarget';
import { carouselContentClicked } from '../homepage.analytics';

const FeaturedCarouselItem = (props) => {

    return (
        <div className='featuredCarouselItem' onClick={() => carouselContentClicked(props.analytics, props.title)}>
            <a href={props.link} target={getTarget(props.link)} >
                <div className='featuredCarouselImageContainer' style={{backgroundImage : `linear-gradient(to top, rgba(0, 0, 0, 0.75) 10%, rgba(255, 255, 255, 0) 40%), url(${AppSettings.AWSImgRepo.homepage_v3 + props.image})`}} >
                    <div className='featuredCarouselTextContainer'>
                        <div className='featuredCarouselItemTitle'>{props.title}</div>
                        <div className='featuredCarouselItemDescription'>{props.description}</div>
                    </div>
                </div>
            </a>
        </div>
    );
}

export default FeaturedCarouselItem;