import { GlobalAnalytics } from "../../analytics";
import { getCookie } from "../../screens/utils/cookie";

const analytics = new GlobalAnalytics();

export const loginSucceced = () => {
    const accountType = getCookie("engageCorpUser");
    analytics.fireOnClick({
        container: 'ForgeRock Login',
        label: 'Login Succeced',
        contentTitle: accountType
    });
};