import { GlobalAnalytics } from "../../../analytics";

const analytics = new GlobalAnalytics();

// Page Load
export const bzResourcesPageload = (pageLoad) => {
  pageLoad &&
    analytics.firePageLoad({
      section: 'Product',
      subsection: 'Featured Technology',
      technologyName: 'Beyond Zero',
      page: pageLoad.page,
    });
};

// general click
export const bzResourcesClick = (clickItem) => {
  clickItem &&
    analytics.fireOnClick({
      tag: '29.2',
      section: 'Product',
      page: 'Beyond Zero',
      category: 'Resources',
      label: clickItem.label,
      contentTitle: clickItem.content_title,
    });
};