function formatMSRP(msrp) {
  try {
    const string = msrp.toString();
    const unformattedString = string.replace(/[^\d.-]/g, '');
    if (unformattedString) {
      const formattedString = parseFloat(unformattedString).toLocaleString('en');
      if (formattedString.toLowerCase() === 'not available') {
        return 'Not Available';
      } return `$${formattedString}`;
    } if (string === 'Included') {
      return `$${string}`;
    }
    return 'TBD';
  } catch (ex) {
    return 'TBD';
  }
}

module.exports = formatMSRP;
