import PropTypes from 'prop-types';
import React from 'react';
import '../../../styles/css/news/newsArchive/newsArchiveCardContent.css';
import getTarget from '../../utils/getTarget';
import * as analytics from "../news.analytics.js";

const NewsArchiveCardContent = (props) => {
    const href = `/articles/${props.article.id}`;
    return (
        <div className='NACContent'>
            <div className='NACContentBody'>
                {props.article.description}
            </div>
            <div onClick={() => { analytics.learnMoreClick(props.analyticsData.clickAnalytics.learnMore, props.pageDate, props.article.title); }}>
                <a className='NACContentLink' target={getTarget(href)} href={href}>LEARN MORE</a>
            </div>
        </div>
    )
};

NewsArchiveCardContent.propTypes = {
    article: PropTypes.object.isRequired,
};

export default NewsArchiveCardContent;