import React, { useEffect } from 'react';
import SectionHeader from '../../../components/atoms/SectionHeader';
import ArticleListComponent from '../../../screens/articleList/articleList.component';
import '../../../styles/css/marketingPage/resourcesPage.css';
import { appPageLoad } from '../appPage-analytics';

function AppPageResources(props) {
    const pageLoadAnalytics = props.analyticsData && props.analyticsData.pageLoad;
    useEffect(()=>{
        appPageLoad(pageLoadAnalytics, "Resources");
    }, []);
    return (
        <div className={`app-page-resources ${props.device}`}>
            <SectionHeader id={'global.resources'} />
            <ArticleListComponent
                key={props.filter}
                recordType={`tag-${props.filter}-en`}
            />
        </div>
    );
}

export default AppPageResources;
