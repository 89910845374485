import React from 'react';
import PropTypes from 'prop-types';
import TitleText from '../../../components/atoms/TitleText';
import RedLine from '../../../components/atoms/RedLine';
import applyMarkdown from '../../utils/applyMarkdown';
import '../../../styles/css/model-overview/powertrain-comparison.css';

const imageRow = (imageLinks, imagePath) => imageLinks.map((fileName, index) => {
  if (fileName && fileName !== '') {
    return (
      <div key={`${fileName.toString()} ${index}`} className="row-value">
        <div key={index} className='row-content powertrain-header-image'>
          <img alt="Image Not Available" src={`${imagePath}${fileName}`} />
        </div>
      </div>
    );
  }
  return null;
});

const modelNameHeader = (modelNames, disclaimer) => modelNames.map((name, index) => (
  <div key={`${name.toString()} ${index}`} className="row-value">
    <div key={index} className="row-content model-name">
      <p>{applyMarkdown(name.toString(), disclaimer)}</p>
    </div>
  </div>
));

const trimNameHeader = (trimNames, disclaimer) => trimNames.map((name, index) => (
  <div key={`${name.toString()} ${index}`} className="row-value">
    <div key={index} className="row-content trim-name">
      <span className="semibold">{applyMarkdown(name.toString(), disclaimer)}</span>
    </div>
  </div>
));

const rowValues = (row, disclaimer) => (
  row.map((value, index) => (
    <div key={`${value.toString()} ${index}`} className="row-value">
      <div key={index} className="row-content">
        <div className="body-item">
          <p>{applyMarkdown(value.toString(), disclaimer)}</p>
        </div>
      </div>
    </div>
  ))
);

const tableBodyRow = (row, disclaimer) => {
  const values = [...row];
  values.shift();
  return (
    <div className="powertrain-chart-row-wrapper">
      <div className="powertrain-row-title">
        <p className="body-item"><span className="semibold">{applyMarkdown(row[0].toString(), disclaimer)}</span></p>
      </div>
      {rowValues(values, disclaimer)}
    </div>
  );
};

const tableBodyRows = (rowData, disclaimer) => (
  rowData.map((row, index) => (
    <React.Fragment key={index}>
      {tableBodyRow(row, disclaimer)}
    </React.Fragment>
  ))
);

const isBodyRow = attributeName => (attributeName !== 'imageLink' && attributeName !== 'modelName' && attributeName !== 'trimName');

const transformObjectToRowArray = (attributeName, trimComparisons) => {
  const rowArray = trimComparisons.map((trimObject) => {
    if (Object.keys(trimObject).includes(attributeName)) {
      return trimObject[attributeName];
    }
    return '';
  });
  const formattedRow = isBodyRow(attributeName) ? [attributeName, ...rowArray] : [...rowArray];
  return formattedRow;
};

const formatTableBody = (rowNames, trimObjectArray) => rowNames.map(name => transformObjectToRowArray(name, trimObjectArray));

const formatComparisonRowObjects = trimComparisons => trimComparisons.map((comparison) => {
  const comparisonRowArrays = comparison.rowValues.reduce((prev, curr) => ({
    ...prev,
    [curr.rowName]: curr.value,
  }), {});
  const { rowValues, ...competitor } = comparison;
  return { ...competitor, ...comparisonRowArrays };
});

export default function PowertrainComparisonSection(props) {
  const s3Path = props.imagePath;
  const trimComparisonTableValues = formatComparisonRowObjects(props.powertrainComparisonData.competitors);
  const imageLinks = transformObjectToRowArray('imageLink', trimComparisonTableValues);
  const modelNames = transformObjectToRowArray('modelName', trimComparisonTableValues);
  const trimNames = transformObjectToRowArray('trimName', trimComparisonTableValues);
  const tableBodyData = formatTableBody(props.powertrainComparisonData.rowNames, trimComparisonTableValues);

  const flexBoxTable = () => (
    <div>
      <div className="model-overview-web-content">
        <div className="comparison-image-row">
          <div className="powertrain-chart-row-wrapper">
            <div className="powertrain-row-title"></div>
            {imageRow(imageLinks, s3Path)}
          </div>
        </div>
      </div>
      <div className="powertrain-chart-row-wrapper">
        <div className="powertrain-row-title"></div>
        {modelNameHeader(modelNames, props.disclaimer)}
      </div>
      <div className="powertrain-chart-row-wrapper">
        <div className="powertrain-row-title"></div>
        {trimNameHeader(trimNames, props.disclaimer)}
      </div>
      <div className="body-section">
        {tableBodyRows(tableBodyData, props.disclaimer)}
      </div>
    </div>
  );

  return (
    <div className="powertrain-comparison-container" style={{ color: (props.styles.textColor ? props.styles.textColor : '#000000'), backgroundColor: (props.styles.backgroundColor ? props.styles.backgroundColor : '#FFFFFF') }}>
      <TitleText className="powertrain-section-title">Powertrain Comparison</TitleText>
      <RedLine className="model-overview-web-content redline" />
      <RedLine className={'model-overview-print-content redline'} />
      <div className="chart-container">
        {flexBoxTable()}
      </div>
    </div>
  );
}

PowertrainComparisonSection.propTypes = {
  powertrainComparisonData: PropTypes.object.isRequired,
  imagePath: PropTypes.string,
  disclaimer: PropTypes.component,
  styles: PropTypes.object,
};
