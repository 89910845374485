import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppSettings from '../appsettings';

const environment = AppSettings.environment;
class RetailSolutionsRedirectComponent extends Component {
  constructor(props){
    super(props);
  }
  componentDidMount() {
    this.refs.redirectPortal.click();
  }

  render() {
    return (
      <div><a ref="redirectPortal" href={this.getRedirectLink()} /></div>
    );
  }

  getRedirectLink = () => {
    return environment === 'prod' ? `https://${this.props.redirectPath}.engage.toyota.com` : `https://${this.props.redirectPath}.${environment}.engage.toyota.com`;
  }
}

export default RetailSolutionsRedirectComponent;

RetailSolutionsRedirectComponent.propTypes = {
  redirectPath: PropTypes.string,
};
