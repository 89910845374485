import { Component } from 'react';
import { GlobalAnalytics } from '../../../analytics';
import { postCompetitiveAdvantagesAction } from '../../../services/nitroService';
import { VehiclesTemplate } from './vehicles.tpl';

const analytics = new GlobalAnalytics();

export default class VehiclesComponent extends Component {
  constructor(props) {
    super(props);
    this.onVehicleChange = this.onVehicleChange.bind(this);
  }

  onVehicleChange(changed, isPrimary, category, label) {
    this.props.onVehicleChange(changed, isPrimary, category, label);
    let curCategory = category;
    let modelName;
    let modelYear;
    if (isPrimary) {
      modelName = this.props.vehicleModelName;
      modelYear = this.props.vehicleModelYear;
      analytics.fireOnClick({
        tag: '29.2',
        section: 'Product',
        subsection: 'Competitive Advantage',
        page: '3rd Party Spec Comparison',
        module: 'Compare',
        category: curCategory,
        modelName: this.props.vehicleModelName,
        modelYear: this.props.vehicleModelYear,
        label,
        container: ' ',
      });
    } else {
      curCategory = `Comparison_${changed.props.index}_${category}`;
      analytics.fireOnClick({
        modelName: this.props.vehicleModelName,
        modelYear: this.props.vehicleModelYear,
        tag: '29.2',
        section: 'Product',
        subsection: 'Competitive Advantage',
        page: 'Third Party Comparison',
        module: 'Compare',
        category: curCategory,
        label,
        container: ' ',
      });
    }
    postCompetitiveAdvantagesAction()
  }

  render() {
    return VehiclesTemplate(this);
  }
}
